/* eslint-disable max-len */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducers';
import {
  CREATE_PRESIGNED_URL,
  DIGITAL_SIGNATURE,
  PUT_DERIVATION,
  PUT_EMERGENCY,
  PUT_EXPERTISE,
  PUT_FORM,
  PUT_HOSPITALIZATION,
  PUT_INDICATION,
  PUT_OBJECT_S3,
  PUT_ONCOLOGY,
  PUT_RECIPE,
  PUT_REPRODUCTIVE,
  PUT_SURGERY,
  SEND_EMAIL,
} from 'src/app/store/actions';

import { Folders } from '@core/types/folders.type';
import { Pdf } from '@shared/helpers/pdf-wrapper.helper';
import { FileService } from '@shared/helpers/file.helper';
import { MAIL_TEMPLATE_GENERIC } from '@shared/templates/template-generic';

import { PDF, PDFWrapper } from '@shared/interfaces/pdf.interface';
import { PresignedUrlRequest, PresignedUrlResponse } from '@shared/interfaces/storage.interface';
import { Email, ShortPathFile } from '@shared/interfaces/notification.interface';
import { MAIL_TEMPLATE_ADMISSIONS } from '@shared/templates/mail-template-admissions';
import { KEY_STORAGE_STORE } from '@store/store-keys';

@Injectable({
  providedIn: 'root',
})
export class SubmitService {
  isLoading: boolean;
  isLoaded: boolean;
  error: HttpErrorResponse;
  currentDate = new Date().toISOString();

  constructor(private pdf: Pdf, private store: Store<AppState>, private fileService: FileService) {
  }

  async signDoc(pdfDataUrl: string, pdfWrapper: PDFWrapper, path: Folders) {
    const {title}  = pdfWrapper.metadata;
    const docFile  = await this.fileService.dataUrlToFile(pdfDataUrl);
    const formData = this.pdf.formDataToDocSign(pdfWrapper, pdfDataUrl, 'simple', path);
    this.store.dispatch(DIGITAL_SIGNATURE({formData, title}));
  }

  s3PresignedUrl(path: Folders) {
    const params: PresignedUrlRequest = this.pdf.getParamsToPresignedUrl('pdf', path);
    this.store.dispatch(CREATE_PRESIGNED_URL({params}));
  }

  s3StorageDoc(pdfWrapper: PDFWrapper, docDataUrl: string) {
    this.store.select(KEY_STORAGE_STORE).subscribe((storage) => {
      const {isLoaded, progress, authorizer} = storage;
      if (isLoaded && progress === 0) this.saveToS3(authorizer, pdfWrapper, docDataUrl);

      // if (isLoaded && rateOfProgress === 100) this.saveToDatabase(signedUrl, pdfWrapper);
    });
  }

  saveToS3(authorizer: PresignedUrlResponse, pdfWrapper: PDFWrapper, docDataUrl: string) {
    const {key, url} = authorizer;
    this.pdf.getFormDataToS3Upload(pdfWrapper, key, docDataUrl).then((docFile) => {
      this.store.dispatch(PUT_OBJECT_S3({url, docFile}));
    });
  }

  addImagesAttachment(valuesToSave: any, attachments: any[], files: any[]) {
    if (!files.length) {
      const listAtt            = attachments.map((att) => ({
        uploadDate: this.currentDate,
        category  : att.path,
        bucket    : att.bucket,
        path      : att.path,
        key       : att.key,
        ext       : att.key.split('.').pop(),
      }));
      valuesToSave.attachments = [...listAtt];
    } else {
      const listAtt            = attachments
        .filter((att) => att.key.split('.').pop() !== 'pdf')
        .map((att) => ({
          uploadDate: this.currentDate,
          category  : att.path,
          bucket    : att.bucket,
          path      : att.path,
          key       : att.key,
          ext       : att.key.split('.').pop(),
        }));
      valuesToSave.attachments = [...files, ...listAtt];
    }

    return valuesToSave;
  }

  saveToDatabase(pdfs: PDF[], domain: any, path: Folders, attachments: any[] = [], files: any[] = []) {
    let valuesToSave = this.pdf.getValuesToSave(pdfs, domain, path);
    const title      = pdfs[0].pdfWrapper.metadata.title;

    if (title === 'informe médico legal') valuesToSave = this.addImagesAttachment(valuesToSave, attachments, files);

    const CLINICAL_ACTIONS = [
      {name: 'certificado médico', selected: PUT_FORM},
      {name: 'informe médico legal', selected: PUT_EXPERTISE},
      {
        name    : 'formulario universal para consentimento informado en procedimientos invasivos, diagnósticos, médicos, odontológicos y quirúrgicos',
        selected: PUT_FORM,
      },
      {name: 'notificación de patología ges', selected: PUT_FORM},
      {name: 'formulario de ingreso médico hospitalario', selected: PUT_FORM},
      {name: 'formulario interno inscripción pad fonasa', selected: PUT_FORM},
      {name: 'consentimiento informado para uso de imágenes', selected: PUT_FORM},
      {
        name    : 'formulario de clasificación de riesgo de enfermedad tromboembólica (ete) para pacientes quirúrgicos',
        selected: PUT_FORM,
      },
      {
        name    : 'formulario de evaluación nutricional para beneficiarios de instituciones de salud previsional (niños con estado nutricional normal)',
        selected: PUT_FORM,
      },

      {name: 'imagenes', selected: PUT_INDICATION},
      {name: 'laboratorio', selected: PUT_INDICATION},
      {name: 'procedimientos', selected: PUT_INDICATION},
      {name: 'receta de lentes', selected: PUT_INDICATION},
      {name: 'orden de vacunas', selected: PUT_INDICATION},
      {name: 'ayudas técnicas y órtesis', selected: PUT_INDICATION},
      {name: 'orden de examen oftalmología', selected: PUT_INDICATION},
      {name: 'orden procedimientos dermatología', selected: PUT_INDICATION},
      {name: 'solicitud de exámenes y comité de otorrinolaringología', selected: PUT_INDICATION},

      {name: 'solicitud de hospitalización', selected: PUT_SURGERY},

      {name: 'interconsulta o derivación', selected: PUT_DERIVATION},
      {name: 'fonoaudiología', selected: PUT_DERIVATION},
      {name: 'kinesiología', selected: PUT_DERIVATION},
      {name: 'terapia ocupacional', selected: PUT_DERIVATION},

      {name: 'orden de hospitalización', selected: PUT_HOSPITALIZATION},

      {name: 'cartilla ciclo actividad sexual', selected: PUT_REPRODUCTIVE},
      {name: 'cartilla inseminación intrauterina', selected: PUT_REPRODUCTIVE},
      {name: 'pad para tratamiento de fertilización asistida de baja complejidad hombre', selected: PUT_REPRODUCTIVE},
      {name: 'pad para tratamiento de fertilización asistida de baja complejidad mujer', selected: PUT_REPRODUCTIVE},
      {name: 'antecedentes para confección programa atencion de salud', selected: PUT_REPRODUCTIVE},

      {name: 'receta médica', selected: PUT_RECIPE},
      {name: 'receta magistral', selected: PUT_RECIPE},
      {name: 'receta diabetes', selected: PUT_RECIPE},

      {name: 'detalle urgencia', selected: PUT_EMERGENCY},

      {name: 'orden medica tac de simulación', selected: PUT_ONCOLOGY},
      {name: 'indicación de radioterapia para presupuesto', selected: PUT_ONCOLOGY},
    ];
    const clinicalAction   = CLINICAL_ACTIONS.find((ca) => ca.name === title.toLocaleLowerCase());

    this.store.dispatch(clinicalAction.selected({valuesToSave}));
  }

  getMailAttachments(indication: any): ShortPathFile[] {
    const attachments = indication.attachments.map((attch) => ({
      path: `${attch.bucket}/${attch.path}`,
      key : `${attch.key}`,
    }));

    return attachments;
  }

  sendMail(rawMail) {
    if(!rawMail) return;
    const content                        = this.buildHtml(rawMail);
    const {to, subject, attachments, cc} = rawMail;
    const paramsToSendMail: Email        = {to, cc, subject, content, attachments};

    this.store.dispatch(SEND_EMAIL({paramsToSendMail}));
  }

  buildHtml(rawMail) {
    const {greeting, message, buttons, template} = rawMail;

    let html = MAIL_TEMPLATE_GENERIC;

    html = html.replace('{{greeting}}', greeting);
    html = html.replace('{{message}}', message);
    html = html.replace('{{buttons}}', buttons || '');

    if (template === 'admissions') {
      html = MAIL_TEMPLATE_ADMISSIONS;
      html = html.replace('__content__', message);
    }

    return html;
  }
}
