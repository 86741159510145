import { ExamIndication } from '@clinical/indications/interfaces/indication.interface';
import { Action, createReducer, on } from '@ngrx/store';
import { Paperwork } from '@shared/interfaces/paperwork.interface';

import {
  CLEAR_INDICATION,
  DELETE_INDICATION,
  DELETE_INDICATION_ERR,
  DELETE_INDICATION_OK,
  LOAD_INDICATIONS_BY_PATIENT,
  LOAD_INDICATIONS_BY_PATIENT_ERR,
  LOAD_INDICATIONS_BY_PATIENT_OK,
  LOAD_INDICATIONS_BY_PROFESSIONAL,
  LOAD_INDICATIONS_BY_PROFESSIONAL_ERR,
  LOAD_INDICATIONS_BY_PROFESSIONAL_OK,
  PUT_INDICATION,
  PUT_INDICATION_END,
  PUT_INDICATION_ERR,
  PUT_INDICATION_OK,
  PUT_MARKS,
  PUT_MARKS_ERR,
  PUT_MARKS_OK,
  SELECT_INDICATION,
  SELECTED_INDICATION,
  UNSELECT_INDICATION,
} from '@store/actions';

export interface IndicationState {
  error: any;
  status: string;
  isLoaded: boolean;
  isLoading: boolean;
  indications: Paperwork<ExamIndication>[];
  historical: Paperwork<ExamIndication>[];
  selected: Paperwork<ExamIndication>;
  lastIndication: Paperwork<ExamIndication>;
  current: Paperwork;
  isSaving: boolean;
}

export const indicationState: IndicationState = {
  error: null,
  status: '',
  isLoaded: false,
  isLoading: false,
  indications: [],
  historical: [],
  selected: null,
  lastIndication: null,
  current: null,
  isSaving: false,
};

const createReducerIndication = createReducer(
  indicationState,

  // Obtener las indicaciones de un paciente
  on(LOAD_INDICATIONS_BY_PATIENT, (state) => ({ ...state, isLoading: true })),
  on(LOAD_INDICATIONS_BY_PATIENT_OK, (state, { indications: indications }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    indications: [...indications],
  })),
  on(LOAD_INDICATIONS_BY_PATIENT_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  // Obtener las indicaciones de un profesional
  on(LOAD_INDICATIONS_BY_PROFESSIONAL, (state) => ({ ...state, isLoading: true })),
  on(LOAD_INDICATIONS_BY_PROFESSIONAL_OK, (state, { indications: indications }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    historical: [...indications],
  })),
  on(LOAD_INDICATIONS_BY_PROFESSIONAL_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(PUT_INDICATION_END, (state) => ({ ...state, isSaving: false })),
  on(PUT_INDICATION, (state) => ({ ...state, isLoading: true, status: 'Guardando el registro.' })),
  on(PUT_INDICATION_OK, (state, { indication }) => {
    let indications = state.indications.map((ans) => {
      if (ans.id === indication.id) return indication;
      else return ans;
    });
    let history = state.historical.map((ans) => {
      if (ans.id === indication.id) return indication;
      else return ans;
    });

    const index = indications.findIndex((draft) => draft.id === indication.id);
    if (index === -1) indications = [indication, ...indications];
    const indexHistory = history.findIndex((draft) => draft.id === indication.id);
    if (indexHistory === -1) history = [indication, ...history];

    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      isSaving: true,
      status: 'Registro guardado con éxito',
      indications: [...indications],
      historical: [...history],
      lastIndication: { ...indication },
      current: { ...indication },
    };
  }),
  on(PUT_INDICATION_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    isSaving: false,
    status: 'Ocurrio un error al intentar guardar el registro',
    error: { ...payload },
  })),

  on(SELECT_INDICATION, (state, { selected, action }) => ({
    ...state,
    selected: { ...selected },
    isLoading: true,
    isLoaded: false,
    status: action,
  })),
  on(SELECTED_INDICATION, (state, { selected, action }) => ({
    ...state,
    selected: { ...selected },
    isLoading: false,
    isLoaded: true,
    status: action,
  })),
  on(UNSELECT_INDICATION, (state) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    status: '',
    selected: null,
  })),

  on(DELETE_INDICATION, (state) => ({ ...state, isLoading: true })),
  on(DELETE_INDICATION_OK, (state, { indication }) => {
    let indications = [...state.indications];
    let history = [...state.historical];
    const index = indications.findIndex((draft) => draft.id === indication.id);
    const indexHistory = history.findIndex((draft) => draft.id === indication.id);
    if (index !== -1) indications = [...indications.filter((draft) => draft.id !== indication.id)];
    if (indexHistory !== -1) history = [...history.filter((draft) => draft.id !== indication.id)];

    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      status: 'Indicacion eliminada con éxito',
      indications: [...indications],
      historical: [...history],
    };
  }),
  on(DELETE_INDICATION_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    status: 'Ocurrio un error al intentar eliminar la indicacion',
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(CLEAR_INDICATION, (state) => ({
    indications: [...state.indications],
    historical: [...state.historical],
    isLoading: false,
    isLoaded: false,
    error: null,
    status: '',
    selected: null,
    lastIndication: null,
    current: null,
    isSaving: false,
  })),
  on(PUT_MARKS, (state) => ({ ...state })),
  on(PUT_MARKS_OK, (state) => ({ ...state })),
  on(PUT_MARKS_ERR, (state, { payload }) => ({ ...state })),
);

export const indicationReducer = (state: IndicationState, action: Action) => createReducerIndication(state, action);
