import { Action, createReducer, on } from '@ngrx/store';
import { LOAD_SCHEDULE, LOAD_SCHEDULE_ERR, LOAD_SCHEDULE_OK } from '@store/actions';

export interface ScheduleState {
  offers: any[];
  isLoaded: boolean;
  isLoading: boolean;
  status: string;
  error: any;
}

export const scheduleState: ScheduleState = {
  offers: [],
  isLoaded: false,
  isLoading: false,
  error: null,
  status: '',
};

const createReducerAppointment = createReducer(
  scheduleState,

  on(LOAD_SCHEDULE, (state) => ({ ...state, isLoading: true })),

  on(LOAD_SCHEDULE_OK, (state, { offers }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    offers: [...offers],
  })),

  on(LOAD_SCHEDULE_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    offers: null,
    error: { ...payload },
  })),
);

export const scheduleReducer = (state: ScheduleState, action: Action) => createReducerAppointment(state, action);
