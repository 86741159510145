import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-modals',
  templateUrl: './header-modals.component.html',
  styleUrls: ['./header-modals.component.scss'],
})
export class HeaderModalsComponent {
  @Input() title: string;

  constructor() {}
}
