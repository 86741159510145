import {patientTopCard} from '@shared/helpers/pdf-patient-card.helper';
import {lightFormat} from 'date-fns';
import intervalToDuration from 'date-fns/intervalToDuration';

export const buildContentPdfPnac = (paperwork, group) => {
  const {rows}       = group;
  const professional = paperwork.professional;
  const patient      = paperwork.patient;
  const buildPdfPnac = rows.map((item) => {
    let center                  = item?.center.name;
    const lenderAddress         = item.center.address.street + ' ' + item.center.address.streetNumber + ', ' + item.center.address.locality;
    const professionalRut       = professional.rut;
    const profession            = professional?.specialty?.name;
    const patientNames          = patient.names + ' ' + patient.surnames;
    const patientRut            = patient.documentNumber;
    const professionalNames     = professional.names + ' ' + professional.surnames;
    const patientBirth          = lightFormat(new Date(patient.birth), 'dd-MM-yyyy');
    const {years, months, days} = intervalToDuration({start: patient.birth, end: new Date()});
    const age                   = `${years} años ${months} meses ${days} días `;
    let breastfeeding           = item.breastfeeding;
    let representativeName      = item.representative.names;
    let representativeRut       = item.representative.documentNumber;
    let locality                = item.representative.address.locality;
    let street                  = item.representative.address.street;
    let growthCurvePNAC         = item.growthCurve?.description;
    let pe                      = item.growthCurve.pe;
    let te                      = item.growthCurve.te;
    let pt                      = item.growthCurve.pt;
    let isapre                  = item.isapre;
    let weight                  = item.weight;
    let size                    = item.size;
    let namesProfessional;
    let rutProfessional;
    let professionPro;
    let namesPatient;
    let birthPatient;
    let agePatient;
    let lenderAddressCenter;
    let rutPatient;
    let integratedNutritional   = item.integratedNutritional;
    const title                 = [
      {
        fontSize: 12,
        margin  : [0, 10, 0, 10],
        text    : [{text: `DATOS DEL PROFESIONAL `, bold: true}],
      },
    ];
    const titlePatient          = [
      {
        fontSize: 12,
        margin  : [0, 10, 0, 10],
        text    : [{text: `DATOS DEL PACIENTE `, bold: true}],
      },
    ];
    const titleBeneficiary      = [
      {
        fontSize: 12,
        margin  : [0, 10, 0, 10],
        text    : [{text: `DATOS DEL BENEFICIARIO `, bold: true}],
      },
    ];
    const titleContent          = [
      {
        fontSize: 12,
        margin  : [0, 10, 0, 10],
        text    : [{text: `SITUACIÓN NUTRICIONAL `, bold: true}],
      },
    ];
    const nota                  = [
      {
        fontSize: 12,
        margin  : [0, 10, 0, 10],
        text    : [
          {
            text: `IMPORTANTE: Si no tiene definición de condición de Lactancia, recibirá beneficio como Lactancia Materna Predominante`,
            bold: true,
          },
        ],
      },
    ];

    if (professionalNames)
      namesProfessional = [
        {fontSize: 12, text: ` Nombre: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${professionalNames}`,
          bold      : true,
        },
      ];
    if (professionalRut)
      rutProfessional = [
        {fontSize: 12, text: ` Rut: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${professionalRut}`,
          bold      : true,
        },
      ];
    if (profession)
      professionPro = [
        {fontSize: 12, text: ` Profesión: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${profession}`,
          bold      : true,
        },
      ];
    if (center)
      center = [
        {fontSize: 12, text: ` Centro médico: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${center}`,
          bold      : true,
        },
      ];
    if (lenderAddress)
      lenderAddressCenter = [
        {fontSize: 12, text: ` Dirección de la consulta: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${lenderAddress}`,
          bold      : true,
        },
      ];

    if (patientNames)
      namesPatient = [
        {fontSize: 12, text: ` Nombre del niño (niña): `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${patientNames}`,
          bold      : true,
        },
      ];
    if (patientRut)
      rutPatient = [
        {fontSize: 12, text: ` Rut: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${patientRut}`,
          bold      : true,
        },
      ];

    if (patientBirth)
      birthPatient = [
        {fontSize: 12, text: ` Fecha de nacimiento: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${patientBirth}`,
          bold      : true,
        },
      ];
    if (age)
      agePatient = [
        {fontSize: 12, text: ` Edad niño (niña): `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${age}`,
          bold      : true,
        },
      ];
    if (isapre)
      isapre = [
        {fontSize: 12, text: ` Isapre: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${isapre}`,
          bold      : true,
        },
      ];

    if (representativeName)
      representativeName = [
        {fontSize: 12, text: ` Nombre: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${representativeName}`,
          bold      : true,
        },
      ];
    if (representativeRut)
      representativeRut = [
        {fontSize: 12, text: ` Rut: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${representativeRut}`,
          bold      : true,
        },
      ];
    if (street)
      street = [
        {fontSize: 12, text: ` Domicilio: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${street}`,
          bold      : true,
        },
      ];
    if (locality)
      locality = [
        {fontSize: 12, text: ` Comuna: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${locality}`,
          bold      : true,
        },
      ];
    if (weight)
      weight = [
        {fontSize: 12, text: ` Peso: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${weight}`,
          bold      : true,
        },
      ];
    if (size)
      size = [
        {fontSize: 12, text: ` Talla: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${size}`,
          bold      : true,
        },
      ];
    if (growthCurvePNAC)
      growthCurvePNAC = [
        {fontSize: 12, text: `Apreciación de curva de crecimiento`},
        {fontSize: 12, text: ` y Calificación Nutricional: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${growthCurvePNAC}`,
          bold      : true,
        },
      ];
    if (pe)
      pe = [
        {fontSize: 12, text: `P/E: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${pe}`,
          bold      : true,
        },
      ];
    if (te)
      te = [
        {fontSize: 12, text: `T/E: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${te}`,
          bold      : true,
        },
      ];
    if (pt)
      pt = [
        {
          fontSize: 12,
          text    : `P/T: `,
        },
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${pt}`,
          bold      : true,
        },
      ];
    if (integratedNutritional)
      integratedNutritional = [
        {
          fontSize: 12,
          text    : `Dg Integrado Nutricional: `,
        },
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${integratedNutritional}`,
          bold      : true,
        },
      ];
    if (breastfeeding)
      breastfeeding = [
        {
          fontSize: 12,
          text    : `En el menor de 6 meses agregar `,
        },
        {
          fontSize: 12,
          text    : `situación de lactancia: `,
        },
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${breastfeeding}`,
          bold      : true,
        },
      ];

    const PNAC_FORM = {
      fontSize : 15,
      alignment: 'justify',
      stack    : [
        title,
        namesProfessional,
        rutProfessional,
        professionPro,
        center,
        lenderAddressCenter,
        titlePatient,
        namesPatient,
        rutPatient,
        birthPatient,
        agePatient,
        isapre,
        titleBeneficiary,
        representativeName,
        representativeRut,
        street,
        locality,
        titleContent,
        weight,
        size,
        growthCurvePNAC,
        pe,
        te,
        pt,
        integratedNutritional,
        breastfeeding,
        nota,
      ],
    };

    return [PNAC_FORM];
  });
  return [...patientTopCard(paperwork), buildPdfPnac];
};
