import {Action, createReducer, on} from '@ngrx/store';
import {Paperwork} from '@shared/interfaces/paperwork.interface';


import {
CLEAR_DISCHARGE_MEDICAL,
  DELETE_DISCHARGE_MEDICAL,
  DELETE_DISCHARGE_MEDICAL_ERR,
  DELETE_DISCHARGE_MEDICAL_OK,
  LOAD_DISCHARGE_MEDICAL_BY_PATIENT,
  LOAD_DISCHARGE_MEDICAL_BY_PATIENT_ERR,
  LOAD_DISCHARGE_MEDICAL_BY_PATIENT_OK,
  LOAD_DISCHARGE_MEDICAL_BY_PROFESSIONAL,
  LOAD_DISCHARGE_MEDICAL_BY_PROFESSIONAL_ERR,
  LOAD_DISCHARGE_MEDICAL_BY_PROFESSIONAL_OK,
  PUT_DISCHARGE_MEDICAL,
  PUT_DISCHARGE_MEDICAL_END,
  PUT_DISCHARGE_MEDICAL_ERR,
  PUT_DISCHARGE_MEDICAL_OK,
  SELECTED_DISCHARGE_MEDICAL,
  UNSELECT_DISCHARGE_MEDICAL,
} from './clinical-discharge.action';

export interface DischargeState {
    discharge: Paperwork<any>[];
  historical: Paperwork<any>[];
  isLoaded: boolean;
  isLoading: boolean;
  status: string;
  error: any;
  selected: Paperwork<any>;
  lastDicharge: Paperwork<any>;
  current: Paperwork;
  isSaving: boolean;
}

export const dischargeState: DischargeState = {
    discharge   : [],
  historical: [],
  isLoaded  : false,
  isLoading : false,
  status    : '',
  error     : null,
  selected  : null,
  lastDicharge: null,
  current   : null,
  isSaving: false,
};

const createReducerDischarge = createReducer(
    dischargeState,

    on(LOAD_DISCHARGE_MEDICAL_BY_PROFESSIONAL, (state) => ({...state, isLoading: true})),
    on(LOAD_DISCHARGE_MEDICAL_BY_PROFESSIONAL_OK, (state, {discharge: discharge}) => ({
      ...state,
      isLoading : false,
      isLoaded  : true,
      error     : null,
      historical: [...discharge],
    })),
    on(LOAD_DISCHARGE_MEDICAL_BY_PROFESSIONAL_ERR, (state, {payload}) => ({
      ...state,
      isLoading: false,
      isLoaded : false,
      error    : {
        url    : payload.url,
        name   : payload.name,
        message: payload.message,
      },
    })),

    // Obtener data de un paciente
    on(LOAD_DISCHARGE_MEDICAL_BY_PATIENT, (state) => ({...state, isLoading: true})),
    on(LOAD_DISCHARGE_MEDICAL_BY_PATIENT_OK, (state, {discharge: discharge}) => ({
      ...state,
      isLoading: false,
      isLoaded : true,
      error    : null,
      discharge  : [...discharge],
    })),
    on(LOAD_DISCHARGE_MEDICAL_BY_PATIENT_ERR, (state, {payload}) => ({
      ...state,
      isLoading: false,
      isLoaded : false,
      error    : {
        url    : payload.url,
        name   : payload.name,
        message: payload.message,
      },
    })),

    on(CLEAR_DISCHARGE_MEDICAL, (state) => ({
        discharge   : [...state.discharge],
      historical: [...state.historical],
      isLoading : false,
      isLoaded  : false,
      error     : null,
      status    : '',
      selected  : null,
      lastDicharge: null,
      current   : null,
      isSaving: false,
    })),

    on(PUT_DISCHARGE_MEDICAL_END, (state) => ({ ...state, isSaving: false })),
    on(PUT_DISCHARGE_MEDICAL, (state) => ({...state, isLoading: true})),
    on(PUT_DISCHARGE_MEDICAL_OK, (state, {discharge}) => {
      const discharges = [];
      const history = [];
      state.discharge.map((ans) => {
        if (ans.id !== discharge.id) discharges.push(ans);
      });
      state.historical.map((ans) => {
        if (ans.id !== discharge.id) history.push(ans);
      });

      return {
        ...state,
        isLoading : false,
        isLoaded  : true,
        isSaving: true,
        status    : 'Registro guardado con éxito',
        discharge   : [discharge, ...discharges],
        historical: [discharge, ...history],
        lastDicharge: {...discharge},
        current   : {...discharge},
      };
    }),
    on(PUT_DISCHARGE_MEDICAL_ERR, (state, {payload}) => ({
      ...state,
      isLoading: false,
      isLoaded : false,
      current  : null,
      isSaving: false,
      status   : 'Ocurrio un error al intentar guardar el Registro',
      error    : {...payload},
    })),

    on(SELECTED_DISCHARGE_MEDICAL, (state, {selected, action}) => ({...state, selected: {...selected}, status: action})),
    on(UNSELECT_DISCHARGE_MEDICAL, (state) => ({...state, selected: null, isLoaded: false, isLoading: false, status: ''})),

    on(DELETE_DISCHARGE_MEDICAL, (state) => ({...state, isLoading: true})),
    on(DELETE_DISCHARGE_MEDICAL_OK, (state, {discharge}) => {
      let discharges = [...state.discharge];
      let history = [...state.historical];
      const index = discharges.findIndex((draft) => draft.id === discharge.id);
      if (index !== -1) discharges = [...discharges.filter((draft) => draft.id !== discharge.id)];

      const indexHistory = history.findIndex((draft) => draft.id === discharge.id);
      if (indexHistory !== -1) history = [...history.filter((draft) => draft.id !== discharge.id)];

      return {
        ...state,
        isLoading : false,
        isLoaded  : true,
        status    : 'Registro eliminado con éxito',
        discharge   : [...discharges],
        historical: [...history],
      };
    }),
    on(DELETE_DISCHARGE_MEDICAL_ERR, (state, {payload}) => ({
      ...state,
      isLoading: false,
      isLoaded : false,
      status   : 'Ocurrio un error al intentar eliminar el Registro',
      error    : {
        url    : payload.url,
        name   : payload.name,
        message: payload.message,
      },
    })),
);

export const dischargeReducer = (state: DischargeState, action: Action) => createReducerDischarge(state, action);
