import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {API_URIS} from '@shared/constants/api-uris.const';
import {Derivation} from '@shared/interfaces/derivation.interface';
import {Paperwork} from '@shared/interfaces/paperwork.interface';
import {PCV2Request} from '@shared/interfaces/request.interface';
import {Observable} from 'rxjs';
import {map, retry} from 'rxjs/operators';

const BASE_API_PCV2: string           = environment.baseApiPCv2;
const URI_CLINICAL_DERIVATION: string = API_URIS.clinical.derivations;

@Injectable({
  providedIn: 'root',
})
export class DerivationService {

  constructor(private httpClient: HttpClient) {
  }

  save(body: Paperwork<Derivation>): Observable<Paperwork<Derivation>> {
    return this.httpClient.post<PCV2Request>(`${BASE_API_PCV2}/${URI_CLINICAL_DERIVATION}`, body).pipe(
        retry(3),
        map(({data}: { data: Paperwork<Derivation> }) => data),
    );
  }
}
