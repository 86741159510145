import {Admission} from '@clinical/admissions/interfaces/admission.interface';
import {Action, createReducer, on} from '@ngrx/store';

import {
  DELETE_ADMISSION,
  DELETE_ADMISSION_ERR,
  DELETE_ADMISSION_OK,
  PUT_ADMISSION,
  PUT_ADMISSION_ERR,
  PUT_ADMISSION_OK,
  SELECTED_ADMISSION,
  UNSELECT_ADMISSION,
} from '@store/actions';

import {CLEAR_ADMISSION, LOAD_ADMISSIONS, LOAD_ADMISSIONS_ERR, LOAD_ADMISSIONS_OK} from './clinical-admission.actions';

export interface AdmissionState {
  error: any;
  status: string;
  isLoaded: boolean;
  isLoading: boolean;
  admissions: Admission[];
  selected: Admission;
  lastAdmission: Admission;
}

export const admissionState: AdmissionState = {
  status: '',

  error    : null,
  isLoaded : false,
  isLoading: false,

  selected     : null,
  admissions   : [],
  lastAdmission: null,
};

const createReducerAdmission = createReducer(
    admissionState,

    on(LOAD_ADMISSIONS, (state) => ({...state, isLoading: true})),
    on(LOAD_ADMISSIONS_OK, (state, {admissions: admissions}) => ({
      ...state,
      isLoading : false,
      isLoaded  : true,
      error     : null,
      admissions: [...admissions],
    })),
    on(LOAD_ADMISSIONS_ERR, (state, {payload}) => ({
      ...state,
      isLoading: false,
      isLoaded : false,
      error    : {
        url    : payload.url,
        name   : payload.name,
        message: payload.message,
      },
    })),

    on(PUT_ADMISSION, (state) => ({...state, isLoading: true, status: 'Guardando el registro.'})),
    on(PUT_ADMISSION_OK, (state, {admission}) => {
      let forms = state.admissions.map((ans) => {
        if (ans.id === admission.id) return admission;
        else return ans;
      });

      const index = forms.findIndex((draft) => draft.id === admission.id);
      if (index === -1) forms = [admission, ...forms];

      return {
        ...state,
        isLoading    : false,
        isLoaded     : true,
        status       : 'Registro guardado con éxito',
        admissions   : [...forms],
        lastAdmission: {...admission},
      };
    }),
    on(PUT_ADMISSION_ERR, (state, {payload}) => ({
      ...state,
      isLoading: false,
      isLoaded : false,
      status   : 'Ocurrio un error al intentar guardar el registro',
      error    : {
        url    : payload.url,
        name   : payload.name,
        message: payload.message,
      },
    })),

    on(SELECTED_ADMISSION, (state, {selected, action}) => ({...state, selected, status: action})),
    on(UNSELECT_ADMISSION, (state) => ({
      ...state,
      isLoaded : false,
      isLoading: false,
      status   : '',
      selected : null,
    })),

    on(DELETE_ADMISSION, (state) => ({...state, isLoading: true})),
    on(DELETE_ADMISSION_OK, (state, {admission}) => {
      let admissions = [...state.admissions];
      const index    = admissions.findIndex((draft) => draft.id === admission.id);
      if (index !== -1) admissions = [...admissions.filter((draft) => draft.id !== admission.id)];

      return {
        ...state,
        isLoading : false,
        isLoaded  : true,
        status    : 'Admission eliminada con éxito',
        admissions: [...admissions],
      };
    }),
    on(DELETE_ADMISSION_ERR, (state, {payload}) => ({
      ...state,
      isLoading: false,
      isLoaded : false,
      status   : 'Ocurrio un error al intentar eliminar el sobre con los formularios de admisión',
      error    : {
        url    : payload.url,
        name   : payload.name,
        message: payload.message,
      },
    })),
    on(CLEAR_ADMISSION, (state) => ({
      admissions   : [...state.admissions],
      isLoading    : false,
      isLoaded     : false,
      error        : null,
      status       : '',
      selected     : null,
      lastAdmission: null,
    })),
);

export const admissionReducer = (state: AdmissionState, action: Action) => createReducerAdmission(state, action);
