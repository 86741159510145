import { DEFAULT_PDF_POSITION_SIGNATURE } from '@shared/constants/app.const';
import { PrintedOnPage, PrintMode, Workflow } from '@shared/interfaces/paperwork.interface';
import { DELETE_REPRODUCTIVE, PUT_REPRODUCTIVE } from '../store/reproductive.actions';
import { buildContentPdfInseminations } from '../utils/buil-pdf-pad-insemination';
import { buildContentPdfPadMan } from '../utils/build-pdf-pad-man';
import { buildContentPdfReproductive } from '../utils/build-pdf-pad-reproductive';
import { buildContentPdfPadWoman } from '../utils/build-pdf-pad-woman';
import { buildContentPdfPadFonasa } from '../utils/build-pdf-pad-fonasa';
import { REPRODUCTIVE_ROUTES } from '@clinical/reproductive/reproductive-routes.module';


const workflow: Workflow = {
  isSigned     : true,
  isStored     : true,
  isSaving      : true,
  hasNextControl      : true,
  notifications: [
    {
      type    : 'email',
      entity  : 'patient',
      subject : 'Notificaciones UC CHRISTUS | Medicina Reproductiva',
      template: 'recipe',
    },
  ],
};

export const PAPERWORK_REPRODUCTIVE_DEFINITION = {
  actions: {
    put   : PUT_REPRODUCTIVE,
    delete: DELETE_REPRODUCTIVE
  },
  prefix : 'MRE',
  module : 'reproductiva',
  woman: {
    suffix           : 'MRM',
    group            : 'pad para tratamiento de fertilización asistida de baja complejidad mujer',
    docTitle         : 'pad para tratamiento de fertilización asistida de baja complejidad mujer',
    submodule: REPRODUCTIVE_ROUTES.padWoman,
    getBodyDefinition: buildContentPdfPadWoman,
    workflow,
    config           : {
      isPreview: true,
      header   : {logo: PrintedOnPage.all, title: PrintedOnPage.all, professional: PrintedOnPage.none},
      patient  : {type: PrintMode.simple, printed: PrintedOnPage.none},
      footer   : {
        signature: {printed: PrintedOnPage.all, position: DEFAULT_PDF_POSITION_SIGNATURE},
        qr       : {right: {url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web'}},
      },
    },
  },

  man: {
    suffix           : 'MRH',
    group            : 'pad para tratamiento de fertilización asistida de baja complejidad hombre',
    docTitle         : 'pad para tratamiento de fertilización asistida de baja complejidad hombre',
    submodule: REPRODUCTIVE_ROUTES.padMan,
    getBodyDefinition: buildContentPdfPadMan,
    workflow,
    config           : {
      isPreview: true,
      header   : {logo: PrintedOnPage.all, title: PrintedOnPage.all, professional: PrintedOnPage.none},
      patient  : {type: PrintMode.simple, printed: PrintedOnPage.none},
      footer   : {
        signature: {printed: PrintedOnPage.all, position: DEFAULT_PDF_POSITION_SIGNATURE},
        qr       : {right: {url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web'}},
      },
    },
  },

  cycleLifeSexual: {
    suffix           : 'ASD',
    group            : 'cartilla ciclo actividad sexual',
    docTitle         : 'cartilla ciclo actividad sexual',
    submodule: REPRODUCTIVE_ROUTES.reproductive,
    getBodyDefinition: buildContentPdfReproductive,
    workflow,
    config           : {
      isPreview: true,
      header   : {logo: PrintedOnPage.all, title: PrintedOnPage.all, professional: PrintedOnPage.all},
      patient  : {type: PrintMode.full, printed: PrintedOnPage.all},
      footer   : {
        signature: {printed: PrintedOnPage.all, position: DEFAULT_PDF_POSITION_SIGNATURE},
        qr       : {right: {url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web'}},
      },
    },
  },

  insemination: {
    suffix           : 'IIU',
    group            : 'cartilla inseminación intrauterina',
    docTitle         : 'cartilla inseminación intrauterina',
    submodule: REPRODUCTIVE_ROUTES.insemination,
    getBodyDefinition: buildContentPdfInseminations,
    workflow,
    config           : {
      isPreview: true,
      header   : {logo: PrintedOnPage.all, title: PrintedOnPage.all, professional: PrintedOnPage.all},
      patient  : {type: PrintMode.full, printed: PrintedOnPage.all},
      footer   : {
        signature: {printed: PrintedOnPage.all, position: DEFAULT_PDF_POSITION_SIGNATURE},
        qr       : {right: {url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web'}},
      },
    },
  },
  fonasa: {
    suffix           : 'PAD',
    group            : 'antecedentes para confección programa atencion de salud',
    docTitle         : 'antecedentes para confección programa atencion de salud',
    submodule: REPRODUCTIVE_ROUTES.fonasa,
    getBodyDefinition: buildContentPdfPadFonasa,
    workflow,
    config           : {
      isPreview: true,
      header   : {logo: PrintedOnPage.specialty, title: PrintedOnPage.none, professional: PrintedOnPage.none},
      patient  : {type: PrintMode.full, printed: PrintedOnPage.none},
      footer   : {
        signature: {printed: PrintedOnPage.last, position: DEFAULT_PDF_POSITION_SIGNATURE},
        qr       : {right: {url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web'}},
      },
    },
  },
};
