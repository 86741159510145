import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { PCV2Request } from '@shared/interfaces/request.interface';
import { AddressPatientPharma } from '../interfaces/address.interface';

const BASE_API_PCV2: string = environment.baseApiPCv2;

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  public address$ = new BehaviorSubject<any>(null);
  public address = this.address$.asObservable();

  constructor(private http: HttpClient) {}

  getAddress(idPat: string) {
    this.http.get(`${BASE_API_PCV2}/farmacia/v1/direcciones/pacientes/${idPat}`).subscribe((res: any) => {
      if (res.metadata.statusCode === 200) this.address$.next(res.data);
    });
  }

  getPatientAddress(idPat: string): Observable<AddressPatientPharma[]> {
    return this.http
      .get<PCV2Request>(`${BASE_API_PCV2}/farmacia/v1/direcciones/pacientes/${idPat}`)
      .pipe(map(({ data }: { data: AddressPatientPharma[] }) => data));
  }

  editAddress(val) {
    const data = val;
    return this.http.post(`${BASE_API_PCV2}/farmacia/v1/direcciones`, data).pipe(map((res: any) => res));
  }

  deleteAddress(idPat, idDirection) {
    return this.http
      .delete(`${BASE_API_PCV2}/farmacia/v1/direcciones/${idDirection}/pacientes/${idPat}`)
      .pipe(map((res: any) => res));
  }

  newAddress(addressData, patient) {
    const data = {
      patient,
      ['formatted_address']: `${addressData.street}, ${addressData.comune.name}, ${addressData.comune.region}`,
      address: {
        region: { name: addressData.comune.region },
        comune: { name: addressData.comune.name, id: addressData.comune.id },
        street: addressData.street,
        observation: addressData.observation,
      },
    };
    return this.http.post(`${BASE_API_PCV2}/farmacia/v1/direcciones`, data).pipe(map((res: any) => res));
  }
}
