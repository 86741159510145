import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '@shared/services/ui.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-change',
  templateUrl: './change.component.html',
  styleUrls: ['./change.component.scss'],
})
export class ChangeComponent implements OnInit {
  frmChangePass: UntypedFormGroup;
  message: string;
  isLoading = false;
  error: HttpErrorResponse;
  gsiOne: string;

  strength = 0;
  typeInput = 'password';
  iconInput = 'eye';
  lowUpperCase = { icon: 'ellipse', color: 'nocheck' };
  numbers = { icon: 'ellipse', color: 'nocheck' };
  specialChar = { icon: 'ellipse', color: 'nocheck' };
  totalCharacters = { icon: 'ellipse', color: 'nocheck' };
  confirmPasswords = { icon: 'ellipse', color: 'nocheck' };
  constructor(
    private formBuilder: UntypedFormBuilder,
    public authService: AuthService,
    private router: Router,
    public uiService: UiService,
    private route: ActivatedRoute,
  ) {
    this.buildForm();
  }

  ngOnInit() {
    const { code, gsiOne } = this.route.snapshot.params;

    this.frmChangePass.controls.code.setValue(code);
    this.frmChangePass.controls.rut.setValue(gsiOne);
    this.gsiOne = gsiOne;
    this.listenForm();
  }

  listenForm() {
    this.frmChangePass.valueChanges.subscribe((frm) => {
      this.checkStrength(frm.pass1, frm.pass2);
    });
  }

  checkStrength(password, confirm) {
    const HAS_UPPERCASE_LOWERCASE_LETTERS = /([a-z].*[A-Z])|([A-Z].*[a-z])/;
    const HAS_NUMBERS = /([0-9])/;
    const LENGTH_PASS = 12;

    this.strength = 0;
    if (password.match(HAS_UPPERCASE_LOWERCASE_LETTERS)) {
      this.strength += 1;
      this.lowUpperCase.icon = 'checkmark-circle';
      this.lowUpperCase.color = 'check';
    } else {
      this.lowUpperCase.icon = 'ellipse';
      this.lowUpperCase.color = 'nocheck';
    }

    if (password.match(HAS_NUMBERS)) {
      this.strength += 1;
      this.numbers.icon = 'checkmark-circle';
      this.numbers.color = 'check';
    } else {
      this.numbers.icon = 'ellipse';
      this.numbers.color = 'nocheck';
    }
    //If it has one special character
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
      this.strength += 1;
      this.specialChar.icon = 'checkmark-circle';
      this.specialChar.color = 'check';
    } else {
      this.specialChar.icon = 'ellipse';
      this.specialChar.color = 'nocheck';
    }
    //If password is greater than 7
    if (password.length >= LENGTH_PASS) {
      this.strength += 1;
      this.totalCharacters.icon = 'checkmark-circle';
      this.totalCharacters.color = 'check';
    } else {
      this.totalCharacters.icon = 'ellipse';
      this.totalCharacters.color = 'nocheck';
    }

    if (password === confirm) {
      this.strength += 1;
      this.confirmPasswords.icon = 'checkmark-circle';
      this.confirmPasswords.color = 'check';
    } else {
      this.confirmPasswords.icon = 'ellipse';
      this.confirmPasswords.color = 'nocheck';
    }
  }

  changePass() {
    if (this.frmChangePass.valid) {
      const pass1 = this.frmChangePass.controls.pass1.value;
      const pass2 = this.frmChangePass.controls.pass2.value;
      if (pass1 === pass2) {
        this.isLoading = true;
        const body = {
          gsiOne: this.frmChangePass.controls.rut.value,
          password: pass1,
          recoveryCode: this.frmChangePass.controls.code.value,
        };
        this.authService.changePassword(body).subscribe(
          (resp) => {
            const message = `<p>Contraseña cambiada con éxito.</p>`;
            this.uiService.presentAlert('success', message);
            this.isLoading = false;
            this.router.navigateByUrl(`/login`);
          },
          (error) => {
            const statusCodeError = error.status;

            const HTTP_ERROR_401 = 401;
            const HTTP_ERROR_401_MSG = 'No se encontraron los datos del profesional con el RUT indicado.';

            const HTTP_ERROR_400 = 400;
            const HTTP_ERROR_400_MSG = 'El código de verificación ingresado expiró o no es válido.';

            const HTTP_ERROR_500 = 500;
            const HTTP_ERROR_500_MSG = 'Ha ocurrido un error validando sus datos.';

            if (statusCodeError === HTTP_ERROR_401) this.message = HTTP_ERROR_401_MSG;
            if (statusCodeError === HTTP_ERROR_400) this.message = HTTP_ERROR_400_MSG;
            if (statusCodeError === HTTP_ERROR_500) this.message = HTTP_ERROR_500_MSG;

            this.isLoading = false;
          },
        );
      }
    }
  }

  viewPass() {
    this.typeInput = this.typeInput === 'password' ? 'text' : 'password';
    this.iconInput = this.iconInput === 'eye' ? 'eye-off' : 'eye';
  }

  buildForm(): void {
    this.frmChangePass = this.formBuilder.group({
      rut: '',
      code: '',
      pass1: '',
      pass2: '',
    });
  }
}
