import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExamIndication } from '@clinical/indications/interfaces/indication.interface';
import { Vaccines } from '@shared/interfaces/vaccines.interface';
import { environment } from '@environments/environment';
import { API_URIS } from '@shared/constants/api-uris.const';
import { Paperwork } from '@shared/interfaces/paperwork.interface';
import { PCV2Request } from '@shared/interfaces/request.interface';

import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { StatusService } from '@shared/services/status.service';
import { Procedure } from '@shared/interfaces/dermatology.interface';
import camelcaseKeys from 'camelcase-keys';

const BASE_API: string = environment.baseApiRME;
const BASE_API_PCV2: string = environment.baseApiPCv2;
const URI_CLINICAL_RECIPES: string = API_URIS.clinical.indications;
const URI_PROXY_PATIENTUCC: string = API_URIS.proxy.patientUcc;

@Injectable({
    providedIn: 'root',
})
export class ClinicalIndicationService {
    constructor(private httpClient: HttpClient, private statusService: StatusService) {
    }

    findVaccine(): Observable<Vaccines[]> {
        return this.httpClient
            .get<PCV2Request>(`${BASE_API_PCV2}/mantenedores/v1/vacunas`)
            .pipe(map(({data}: { data: Vaccines[] }) => data));
    }

    getDermatology(text: string): Observable<{ status: string; request: any }> {
        if (text === ' ') return of({status: 'noSearch', request: []});
        return this.httpClient.get<Procedure[]>(`${BASE_API}/v1/indicaciones/dermatologia?text=${text}`).pipe(
            retry(3),
            map(this.requestProcedure),
            catchError(this.statusService.catchError));
    }

    requestProcedure(proc: Procedure[]): { status; request } {
        if (proc.length === 0) return {status: 'void', request: []};
        if (proc.length > 0) return {status: 'ok', request: proc};
    }

    findExamsByTypeAndName(type: string, searchText: string): Observable<{ status: string; request: any }> {
        const ENDPOINT = `v1/indicaciones/${type}?text=${searchText}`;
        const url = `${BASE_API}/${ENDPOINT}`;
        return this.httpClient.get<ExamIndication[]>(url).pipe(
            retry(3),
            map(response => camelcaseKeys(response)),
            map(this.convertExamsToPCv2Exam),
            catchError(this.statusService.catchError));
    }

    getExamByGroupAndId(group: string, id: string): Observable<ExamIndication> {
        const type = this.convertGroupToType(group);
        const ENDPOINT = `v1/indicaciones/${type}?id=${id}`;
        const url = `${BASE_API}/${ENDPOINT}`;
        return this.httpClient.get<ExamIndication[]>(url)
          .pipe(
            retry(3),
            map((exams) => {
                const result = this.convertExamsToPCv2Exam(exams);
                return result?.status === 'ok' ? result.request[0] : null;
            }),
            catchError(this.statusService.catchError),
          );
    }

    getUpdatedExams(indications: ExamIndication[]): Observable<ExamIndication[]> {
        const updatedIndicationsRequests = indications.map(({ group, id }) => this.getExamByGroupAndId(group, id));
        return combineLatest(updatedIndicationsRequests).pipe(
          map((updatedIndications) =>
            indications
              .map((exam, i) => updatedIndications[i] ? {
                  ...exam, ...updatedIndications[i],
                  observations: exam.observations,
              } : null)
              .filter(exam => exam !== null),
          ),
          catchError(() => of(indications)),
        );
    };

    putIndication(body: Paperwork<ExamIndication>): Observable<Paperwork<ExamIndication>> {
        return this.httpClient.post<PCV2Request>(`${BASE_API_PCV2}/${URI_CLINICAL_RECIPES}`, body).pipe(
          retry(3), map(({ data }: { data: Paperwork<ExamIndication> }) => data),
        );
    }

    addMarksByPatient(patientId: string, marksId: string[]): Observable<any> {
        const apiPatientStageByEnviroment = { dev: '-test', qa: '-pre', prd: '-prod' };
        const suffixUrlByStage = `pacientes${apiPatientStageByEnviroment[environment.stage]}/Marcas`;
        const endpoint = `${BASE_API_PCV2}/${URI_PROXY_PATIENTUCC}${suffixUrlByStage}&domain=actualizarMarcasPaciente&client=planesSalud`;
        const body = { idPaciente: patientId, idMarca: marksId };
        return this.httpClient.post<PCV2Request>(endpoint, body);
    }

    convertExamsToPCv2Exam(exam): { status: string; request: any[] } {
        if (exam.length === 0) return {status: 'void', request: []};
        const exams = exam.map(ex => {
            const { id, csu, codfon, glosa, grupo, obs, prmId, booking } = ex;
            return {
                id,
                glosa,
                group       : grupo,
                sapId       : csu,
                imedId: ex?.imed || ex?.imedId,
                fonasaId: codfon ? codfon.toString() : undefined,
                observations: obs,
                prmId: prmId ? prmId.toUpperCase() : undefined,
                booking: booking ? booking.toLowerCase() : undefined,
            };
        });
        return {status: 'ok', request: exams};
    }

    convertGroupToType(group: string) {
        if (group.toUpperCase() === 'LABORATORIO') return 'laboratorios';
        return group.toLowerCase();
    }
}
