import { derivationsTemplate } from './derivation';
import { indicationsTemplate } from './indications';
import { educationalTemplate } from '@shared/templates/emails/educational';
import {
  examButtonCallPartial,
  examButtonDirectPartial,
  examButtonHeaderPartial,
  examButtonLinkPartial,
  examsImagesTeamTemplate,
  examsTemplate,
} from './exams';
import { pharmaciesSelectionPartial, pharmacyQuotePartial, recipeTemplate } from './recipe';
import { genericTemplate, genericTemplateCEM, genericTemplateCSC } from '@shared/templates/emails/generic';

export const mailTemplates = {
  generic: genericTemplate,
  genericCSC: genericTemplateCSC,
  genericCEM: genericTemplateCEM,
  recipe: recipeTemplate,
  derivations: derivationsTemplate,
  indications: indicationsTemplate,
  exams: examsTemplate,
  examsImagesTeam: examsImagesTeamTemplate,
  educational: educationalTemplate,
};

export const mailPartialTemplates = {
  pharmacyQuote: pharmacyQuotePartial,
  pharmaciesSelection: pharmaciesSelectionPartial,
  examButtonDirect: examButtonDirectPartial,
  examButtonLink: examButtonLinkPartial,
  examButtonCall: examButtonCallPartial,
  examButtonHeader: examButtonHeaderPartial,
};
