export const EMAIL_DERIVATE_BY_PROFESIONAL = {
  '16121447-7': ['cirugiadiges@ucchristus.cl', 'cperedo@ucchristus.cl', 'accesodedicado@ucchristus.cl'],
  '15641783-1': ['bneira@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  ['17607475-2']: ['enfermeracirugiaplastica@gmail.com'],
  '15383585-3': ['bneira@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '15581106-4': ['bneira@ucchristus.cl', 'cirugiadiges@ucchristus.cl', 'accesodedicado@ucchristus.cl'],
  '12404406-5': ['bneira@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '7819434-0': ['bneira@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '7023699-0': ['bneira@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '12246272-2': ['pmgonza@ucchristus.cl', 'sabustamante@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '10783950-K': ['pmgonza@ucchristus.cl', 'sabustamante@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '6368652-2': ['pmgonza@ucchristus.cl', 'sabustamante@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '7182715-1': ['magomezp@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '5329312-3': ['acarod@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '17082969-7': ['magomezp@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '15550437-4': ['magomezp@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '10867718-K': ['magomezp@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '14541871-2': ['culloap@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '14145512-5': ['acarod@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '10677734-9': ['culloap@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '14588347-4': ['culloap@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '16212619-9': ['bneira@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '15513877-7': ['acarod@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl', 'sabustamante@ucchristus.cl'],
  '15635979-3': ['cperedo@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '13035581-1': ['cperedo@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '11782184-6': ['cperedo@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '10285161-7': ['cperedo@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '9029566-7': ['cperedo@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '12263824-3': ['enfermeracirugiaplastica@gmail.com'],
  '10012643-5': ['enfermeracirugiaplastica@gmail.com'],
  '15366635-0': ['enfermeracirugiaplastica@gmail.com'],
  '16611700-3': ['enfermeracirugiaplastica@gmail.com'],
  '9352133-1': ['enfermeracirugiaplastica@gmail.com'],
  '16142070-0': ['constanzar.vergara@gmail.com'],
  '10034290-1': ['mbaeza@ucchristus.cl', 'ldiazar@ucchristus.cl'],
  '8791897-1': ['mbaeza@ucchristus.cl', 'ldiazar@ucchristus.cl'],
  '12181554-0': ['mbaeza@ucchristus.cl', 'ldiazar@ucchristus.cl'],
  '17958709-2': ['magomezp@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '17405885-7': ['pmgonza@ucchristus.cl', 'sabustamante@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
  '17680147-6': ['cperedo@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],

  /* médico de pruebas */
  '9999999-3': ['dmartinezb@ucchristus.cl', 'fllanquipi@ucchristus.cl'],
};
