export const getMailTeamAccessText = (nextControl) => {
  const POSITION_MAIL_TEAM_ACCESS = 0;
  const {teamAccess, ccTeamAccessMails} = nextControl;
  const {wsp, phone} = teamAccess;
  const email = ccTeamAccessMails[POSITION_MAIL_TEAM_ACCESS];

  return `
       <br/>
       <p>
         Para mejorar tu atención, tenemos a tu disposición la Unidad de Acceso Dedicado.
         Contáctanos en los siguientes canales:
       </p>
       
       <br/>
       <ul>
         <li>Correo: ${email} <mailto:${email}></li>
         <li>Whatsapp: ${wsp}</li>
         <li>Número para llamadas: ${phone}</li>
       </ul>
    `;
};
