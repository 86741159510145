export const EXAMS = [
  {
    name: 'laboratorios',
    id: 'laboratorios',
  },
  {
    name: 'imágenes',
    id: 'imagenes',
  },
  {
    name: 'procedimientos',
    id: 'procedimientos',
  },
];

export const PDF_HIDDEN_FONASA_IDS = {
  procedimientos: {
    1003: ['0801005', '0801008'],
    1004: ['0801005', '0801008'],
    1018: ['0801005', '0801008'],
    1019: ['0801005', '0801008'],
    1020: ['0801005', '0801008'],
    1021: ['0801005', '0801008'],
    1050: ['0801005', '0801008'],
    1053: ['0801005', '0801008'],
    1054: ['0801005', '0801008'],
    1057: ['0801005', '0801008', '1801037'],
    1064: ['0801005', '0801008'],
    1065: ['0801005', '0801008'],
    1066: ['0801005', '0801008'],
    1098: ['0801005', '0801008'],
    1378: ['0801005', '0801008'],
    1402: ['0801005', '0801008'],
    1403: ['0801005', '0801008'],
    1404: ['0801005', '0801008'],
    1405: ['0801005', '0801008'],
    1406: ['0801005', '0801008'],
    2007: ['0801005', '0801008'],
    2009: ['0801005', '0801008'],
    2010: ['0801005', '0801008'],
    2018: ['0801005', '0801008', '1801037'],
  },
};
