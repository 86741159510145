import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  DELETE_FORM,
  DELETE_FORM_ERR,
  DELETE_FORM_OK,
  LOAD_FORMS_BY_PATIENT,
  LOAD_FORMS_BY_PATIENT_ERR,
  LOAD_FORMS_BY_PATIENT_OK,
  LOAD_FORMS_BY_PROFESSIONAL,
  LOAD_FORMS_BY_PROFESSIONAL_ERR,
  LOAD_FORMS_BY_PROFESSIONAL_OK,
  PUT_FORM,
  PUT_FORM_END,
  PUT_FORM_ERR,
  PUT_FORM_OK,
} from '@store/actions';
import { ClinicalFormService } from '@clinical/forms/services/clinical-form.service';
import { ReportService } from '@components/records/services/report.service';

@Injectable()
export class FormEffects {
  putForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PUT_FORM),
      mergeMap(({ valuesToSave }) =>
        this.clinicalFormService.save({ ...valuesToSave }).pipe(
          map((form) => PUT_FORM_OK({ form })),
          catchError((err) => of(PUT_FORM_ERR({ payload: err }))),
        ),
      ),
    ),
  );
  clear$ = createEffect(() => this.actions$.pipe(ofType(PUT_FORM_OK), map(() => PUT_FORM_END())));
  loadFormsByPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_FORMS_BY_PATIENT),
      mergeMap(({ params }) =>
        this.reportService.findByType(params.patientId, params.type).pipe(
          map((formItem) => LOAD_FORMS_BY_PATIENT_OK({ forms: formItem })),
          catchError((err) => of(LOAD_FORMS_BY_PATIENT_ERR({ payload: err }))),
        ),
      ),
    ),
  );
  loadFormsByProfessional$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_FORMS_BY_PROFESSIONAL),
      mergeMap(({ gsiMultiCondition1St }) =>
        this.reportService.findByGsi(gsiMultiCondition1St).pipe(
          map((formItem) => LOAD_FORMS_BY_PROFESSIONAL_OK({ forms: formItem })),
          catchError((err) => of(LOAD_FORMS_BY_PROFESSIONAL_ERR({ payload: err }))),
        ),
      ),
    ),
  );
  deleteForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DELETE_FORM),
      mergeMap((form) =>
        this.reportService.deleteByIds('formularios', form).pipe(
          map((formItem) => DELETE_FORM_OK({ form: formItem })),
          catchError((err) => of(DELETE_FORM_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private clinicalFormService: ClinicalFormService, private reportService: ReportService) {
  }
}
