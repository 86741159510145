import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse}   from '@angular/common/http';
import {Emergency}           from '@clinical/emergencies/interfaces/emergency.interface';

export const GET_EMERGENCIES_BY_PATIENT = createAction(
  '[emergencias] Cargar Emergencias por paciente', props<{ patientId: string }>(),);
export const GET_EMERGENCIES_BY_PATIENT_OK = createAction(
  '[emergencias] OK Emergencias por paciente', props<{ emergencies: Emergency[] }>(),);
export const GET_EMERGENCIES_BY_PATIENT_ERR = createAction(
  '[emergencias] ERR Emergencias por paciente', props<{ payload: any }>(),);

export const GET_EMERGENCIES_BY_PROFESSIONAL = createAction(
  '[emergencias] Cargar Emergencias por profesional', props<{ gsiMultiCondition1St: string }>(),);
export const GET_EMERGENCIES_BY_PROFESSIONAL_OK = createAction(
  '[emergencias] OK Emergencias por profesional', props<{ emergencies: Emergency[] }>(),);
export const GET_EMERGENCIES_BY_PROFESSIONAL_ERR = createAction(
  '[emergencias] ERR Emergencias por profesional', props<{ payload: any }>(),);

export const PUT_EMERGENCY = createAction('[emergencias] PUT Emergencias', props<{ valuesToSave: Emergency }>());
export const PUT_EMERGENCY_OK = createAction('[emergencias] OK PUT Emergencias', props<{ emergency: Emergency }>());
export const PUT_EMERGENCY_ERR = createAction('[emergencias] ERR PUT Emergencias', props<{
  payload: HttpErrorResponse;
}>());

export const SELECTED_EMERGENCY = createAction('[emergencias] SELECTED Emergencias', props<{ selected: Emergency }>(),);
export const UNSELECT_EMERGENCY = createAction('[emergencias] UNSELECT Emergencias');
export const CLEAR_LAST_EMERGENCY = createAction('[emergencias] CLEAR LAST Emergencias');
