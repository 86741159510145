export const BARIATRIC_SURGERY_PAD_CRITERIA = [
  {
    category: 'Criterios mayores',
    id: 'major',
    criteria: [
      {
        name: 'SAHOS (Apnea del sueño)',
        id: 'dream',
        isChecked: false,
        category: 'Criterios mayores',
      },
      {
        name: 'Diabetes Mellitus tipo II',
        id: 'diabetes',
        isChecked: false,
        category: 'Criterios mayores',
      },
      {
        name: 'Hipertensión arterial',
        id: 'hypertension',
        isChecked: false,
        category: 'Criterios mayores',
      },
      {
        name: 'Trastorno musculoesquelético',
        id: 'painDisorder',
        isChecked: false,
        category: 'Criterios mayores',
      },
      {
        name: 'Enfermedad renal crónica',
        id: 'renalDisease',
        isChecked: false,
        category: 'Criterios mayores',
      },
      {
        name: 'Síndrome Metabólico según la clasificación de la International Diabetes Federation',
        id: 'metabolicSyndrome',
        isChecked: false,
        category: 'Criterios mayores',
      },
    ],
  },
  {
    category: 'Criterios menores',
    id: 'minor',
    criteria: [
      {
        name: 'Dislipidemia',
        id: 'dyslipidemia',
        isChecked: false,
        category: 'Criterios menores',
      },
      {
        name: 'Hígado graso',
        id: 'fattyLiver',
        isChecked: false,
        category: 'Criterios menores',
      },
      {
        name: 'Hiperuricemia',
        id: 'hyperuricemia',
        isChecked: false,
        category: 'Criterios menores',
      },
      {
        name: 'Hipotiroidismo',
        id: 'hypothyroidism',
        isChecked: false,
        category: 'Criterios menores',
      },
      {
        name: 'Reflujo gastroesofágico',
        id: 'reflux',
        isChecked: false,
        category: 'Criterios menores',
      },
      {
        name: 'Trastornos del Metabolismo de los Carbohidratos',
        id: 'metabolism',
        isChecked: false,
        category: 'Criterios menores',
      },
    ],
  },
];
