import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormFieldsValidate } from '@core/reactive-forms/form-fields-validate.helper';

@Component({
  selector: 'app-field-validation-msg',
  templateUrl: './field-validation-msg.component.html',
  styleUrls: ['./field-validation-msg.component.scss'],
})
export class FieldValidationMsgComponent implements OnInit {
  @Input() field: AbstractControl;
  @Input() compareField: AbstractControl;

  vf = FormFieldsValidate;

  constructor() {}

  ngOnInit() {}
}
