import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import {
  DEL_FAVORITE,
  DEL_FAVORITE_ERR,
  DEL_FAVORITE_OK,
  GET_FAVORITES,
  GET_FAVORITES_ERR,
  GET_FAVORITES_OK,
  PUT_FAVORITE,
  PUT_FAVORITE_ERR,
  PUT_FAVORITE_OK,
  SELECT_FAVORITE,
  SELECTED_FAVORITE,
} from '../../../store/actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { FavoritesService } from '@components/favorites/services/favorites.service';

@Injectable()
export class FavotireEffects {
  putFavorite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PUT_FAVORITE),
      concatLatestFrom(({ valuesToSave }) =>
        this.favoriteService.findFavoriteInStoreByName(valuesToSave.name, valuesToSave.professional),
      ),
      map(([{ valuesToSave }, favoriteInStore]) => {
        if (!favoriteInStore) return valuesToSave;
        return { ...favoriteInStore, ...valuesToSave };
      }),
      mergeMap((valuesToSave) =>
        this.favoriteService.putFavorites({ ...valuesToSave }).pipe(
          map((favorite) => PUT_FAVORITE_OK({ favorite })),
          catchError((err) => of(PUT_FAVORITE_ERR({ payload: err }))),
        ),
      ),
    ),
  );
  loadFavotires$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_FAVORITES),
      mergeMap(({ gsiMultiCondition1St }) =>
        this.favoriteService.getFavoritesByProfessional(gsiMultiCondition1St).pipe(
          map((favorites) => GET_FAVORITES_OK({ favorites })),
          catchError((err) => of(GET_FAVORITES_ERR({ payload: err }))),
        ),
      ),
    ),
  );
  deleteFavorite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DEL_FAVORITE),
      mergeMap(({ sk }) =>
        this.favoriteService.deleteFavorite(sk).pipe(
          map(() => DEL_FAVORITE_OK({ sk })),
          catchError((err) => of(DEL_FAVORITE_ERR({ payload: err }))),
        ),
      ),
    ),
  );
  selectFavorite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SELECT_FAVORITE),
      mergeMap(({ favorite }) =>
        this.favoriteService.getUpdatedFavoritesContent(favorite).pipe(
          map((updatedFavorite) => SELECTED_FAVORITE(
            { favorite: { favorite: updatedFavorite.favorite, group: updatedFavorite.group } }),
          ),
          catchError((err) => of(GET_FAVORITES_ERR({ payload: err }))),
        ),
      ),
    ),
  );
  constructor(private actions$: Actions, private favoriteService: FavoritesService) {}
}
