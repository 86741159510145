
import { Action, createReducer, on } from '@ngrx/store';

import { CLEAR_LAST_EMERGENCY, SELECTED_EMERGENCY, UNSELECT_EMERGENCY } from '@store/actions';
import { PUT_EMERGENCY, PUT_EMERGENCY_ERR, PUT_EMERGENCY_OK } from '@store/actions';
import { GET_EMERGENCIES_BY_PATIENT, GET_EMERGENCIES_BY_PATIENT_OK, GET_EMERGENCIES_BY_PATIENT_ERR } from '@store/actions';
import { GET_EMERGENCIES_BY_PROFESSIONAL, GET_EMERGENCIES_BY_PROFESSIONAL_OK, GET_EMERGENCIES_BY_PROFESSIONAL_ERR } from '@store/actions';
import {Emergency} from '@clinical/emergencies/interfaces/emergency.interface';

export interface EmergencyState {
  error: any;
  status: string;
  isLoaded: boolean;
  isLoading: boolean;
  emergencies: Emergency[];
  selected: Emergency;
  lastEmergency: Emergency;
}

export const urgencyState: EmergencyState = {
  error: null,
  status: '',
  isLoaded: false,
  isLoading: false,
  emergencies: [],
  selected: null,
  lastEmergency: null,
};

const createReducerUrgency = createReducer(
  urgencyState,

  // Obtener las urgencias de un paciente
  on(GET_EMERGENCIES_BY_PATIENT, (state) => ({ ...state, isLoading: true })),
  on(GET_EMERGENCIES_BY_PATIENT_OK, (state, { emergencies: urgencies }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    emergencies: [...urgencies],
  })),
  on(GET_EMERGENCIES_BY_PATIENT_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  // Obtener las urgencias de un profesional
  on(GET_EMERGENCIES_BY_PROFESSIONAL, (state) => ({ ...state, isLoading: true })),
  on(GET_EMERGENCIES_BY_PROFESSIONAL_OK, (state, { emergencies: urgencies }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    emergencies: [...urgencies],
  })),
  on(GET_EMERGENCIES_BY_PROFESSIONAL_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(PUT_EMERGENCY, (state) => ({ ...state, isLoading: true, status: 'Guardando el registro.' })),
  on(PUT_EMERGENCY_OK, (state, { emergency: urgency }) => {
    let urgencies = state.emergencies.map((ans) => {
      if (ans.id === urgency.id) return urgency;
      else return ans;
    });

    const index = urgencies.findIndex((draft) => draft.id === urgency.id);
    if (index === -1) urgencies = [urgency, ...urgencies];

    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      status: 'Registro guardado con éxito',
      emergencies: [...urgencies],
      lastEmergency: {...urgency},
    };
  }),
  on(PUT_EMERGENCY_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    status: 'Ocurrio un error al intentar guardar el registro',
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(SELECTED_EMERGENCY, (state, { selected }) => ({ ...state, selected: {...selected}, })),
  on(UNSELECT_EMERGENCY, (state) => ({ ...state, selected: null, })),
  on(CLEAR_LAST_EMERGENCY, (state) => ({
    ...state,
    lastEmergency: null,
  })),
);

export const urgencyReducer = (state: EmergencyState, action: Action) => createReducerUrgency(state, action);
