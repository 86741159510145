import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys',
})
export class KeyValuePipe implements PipeTransform {
  transform(value, args?: string[]): any {
    const keys = [];

    for (const key in value) if (key) keys.push(key);

    return keys;
  }
}
