import {Injectable} from '@angular/core';
import {ClinicalHospitalizationService} from '@clinical/hospital/services/clinical-hospitalization.service';
import {ReportService} from '@components/records/services/report.service';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  DELETE_HOSPITALIZATION,
  DELETE_HOSPITALIZATION_ERR,
  DELETE_HOSPITALIZATION_OK,
  LOAD_HOSPITALIZATIONS_BY_PATIENT,
  LOAD_HOSPITALIZATIONS_BY_PATIENT_ERR,
  LOAD_HOSPITALIZATIONS_BY_PATIENT_OK,
  LOAD_HOSPITALIZATIONS_BY_PROFESSIONAL,
  LOAD_HOSPITALIZATIONS_BY_PROFESSIONAL_ERR,
  LOAD_HOSPITALIZATIONS_BY_PROFESSIONAL_OK,
  PUT_HOSPITALIZATION,
  PUT_HOSPITALIZATION_ERR,
  PUT_HOSPITALIZATION_OK,
} from '@store/actions';

import {of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';

@Injectable()
export class HospitalizationEffects {
  putHospitalization$                 = createEffect(() =>
      this.actions$.pipe(
          ofType(PUT_HOSPITALIZATION),
          mergeMap(({valuesToSave}) =>
              this.clinicalHospitalizationService.save({...valuesToSave}).pipe(
                  map((hospitalization) => PUT_HOSPITALIZATION_OK({hospitalization})),
                  catchError((err) => of(PUT_HOSPITALIZATION_ERR({payload: err}))),
              ),
          ),
      ),
  );
  loadHospitalizations$               = createEffect(() =>
      this.actions$.pipe(
          ofType(LOAD_HOSPITALIZATIONS_BY_PATIENT),
          mergeMap(({params}) =>
              this.reportService.findByType(params.patientId, params.type).pipe(
                  map((order) => LOAD_HOSPITALIZATIONS_BY_PATIENT_OK({orders: order})),
                  catchError((err) => of(LOAD_HOSPITALIZATIONS_BY_PATIENT_ERR({payload: err}))),
              ),
          ),
      ),
  );
  deleteHospitalizationByPatient$     = createEffect(() =>
      this.actions$.pipe(
          ofType(DELETE_HOSPITALIZATION),
          mergeMap((hospitalization) =>
              this.reportService.deleteByIds('hospitalizaciones', hospitalization).pipe(
                  map((hospitalizationItem) => DELETE_HOSPITALIZATION_OK({hospitalization: hospitalizationItem})),
                  catchError((err) => of(DELETE_HOSPITALIZATION_ERR({payload: err}))),
              ),
          ),
      ),
  );
  loadHospitalizationsByProfessional$ = createEffect(() =>
      this.actions$.pipe(
          ofType(LOAD_HOSPITALIZATIONS_BY_PROFESSIONAL),
          mergeMap(({gsiMultiCondition1St}) =>
              this.reportService.findByGsi(gsiMultiCondition1St).pipe(
                  map((order) => LOAD_HOSPITALIZATIONS_BY_PROFESSIONAL_OK({orders: order})),
                  catchError((err) => of(LOAD_HOSPITALIZATIONS_BY_PROFESSIONAL_ERR({payload: err}))),
              ),
          ),
      ),
  );

  constructor(
      private actions$: Actions,
      private clinicalHospitalizationService: ClinicalHospitalizationService,
      private reportService: ReportService) {
  }
}
