import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';

import { Favorite } from '@shared/interfaces/favorite.interface';
import { AddfavoritesComponent } from '@components/favorites/components/add-favorites/add-favorites.component';
import { AuthService } from '@auth/services/auth.service';
import { FormArray } from '@core/reactive-forms/form-array';
import { FormGroup } from '@core/reactive-forms/form-group';

@Component({
  selector   : 'app-btn-favorite-save',
  templateUrl: './btn-favorite-save.component.html',
  styleUrls  : ['./btn-favorite-save.component.scss'],
})
export class BtnFavoriteSaveComponent implements OnInit {
  @Input() favorite: Favorite;
  @Input() form: FormArray<any> | FormGroup<any>;

  constructor(
    private modalController: ModalController,
    private authService: AuthService,
    ) { }

  ngOnInit() { }

  async save() {
    const forms = this.form.value;
    const modal = await this.modalController.create({
      component: AddfavoritesComponent,
      cssClass: 'modal-md',
      componentProps: {
        favorite: {
          ...this.favorite,
          gsiMultiCondition1St: this.favorite.gsiMultiCondition1St.replace(/PROFESSIONAL_ID/, this.authService.professionalRef.id),
          professional: this.authService.professionalRef
        },
        content: forms
      },
    });
    await modal.present();
  }

}
