import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NotificationService } from '@shared/services/notification.service';
import { PaperworkService } from '@shared/services/paperwork.service';

import { StorageService } from '@shared/services/storage.service';
import { AppState } from '@store/app.reducers';
import { KEY_PAPERWORK_STORE } from '@store/store-keys';

import { combineLatest, mergeMap, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, tap } from 'rxjs/operators';

import {
  GET_PRESIGNED_URL_ERR,
  GET_PRESIGNED_URL_OK,
  NEXT_CONTROL_OK,
  PPW_SEND_EMAIL_ERR,
  PPW_SEND_EMAIL_EMPTY,
  PPW_SEND_EMAIL_OK,
  SAVE_RECORD_ERR,
  SAVE_RECORD_OK,
  SEND_FORM,
  SIGNATURE_OK,
  SKIP_NEXT_CONTROL,
  SKIP_SAVE_RECORD,
  SKIP_SEND_EMAIL,
  SKIP_SIGNATURE,
  SKIP_STORAGE,
  STORAGE_ERR,
  STORAGE_OK,
  UPDATE_PAPERWORK_PATIENT,
  UPDATE_PATIENT,
} from '../actions';
import { SubmitService } from '@shared/services/submit.service';
import { BuildMailNextControlService } from '@components/control/services/build-mail-next-control.service';

const CONCAT_RESULT_ACTION_INDEX = 1;

@Injectable()
export class PaperWorkEffects {

  updatePaperworkPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UPDATE_PATIENT),
      concatLatestFrom(() => this.store.select(KEY_PAPERWORK_STORE)),
      map((contactResult) => UPDATE_PAPERWORK_PATIENT({
        patient: contactResult[0].patient,
        paperwork: contactResult[CONCAT_RESULT_ACTION_INDEX].paperwork,
      })),
    ),
  );

  docSign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SEND_FORM),
      concatLatestFrom(() => this.store.select(KEY_PAPERWORK_STORE)),
      map((contactResult) => contactResult[CONCAT_RESULT_ACTION_INDEX]),
      map((paperworkState) => this.paperworkService.isRequiredNextControl(paperworkState)),
      map((hasNextControl) => (hasNextControl ? NEXT_CONTROL_OK() : SKIP_NEXT_CONTROL())),
    ),
  );

  nextControl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NEXT_CONTROL_OK),
      concatLatestFrom(() => this.store.select(KEY_PAPERWORK_STORE)),
      map((contactResult) => contactResult[CONCAT_RESULT_ACTION_INDEX]),
      exhaustMap((paperworkState) => {
        if (paperworkState.isLoading && paperworkState.paperwork.nextControl?.nextControl?.area?.id !== '')
          this.submitService.sendMail(this.buildMailNextControlService.nextControlRawMail(paperworkState.paperwork));

        const isSignatureRequired = this.paperworkService.isSignatureRequired(paperworkState);
        return of((isSignatureRequired ? SIGNATURE_OK() : SKIP_SIGNATURE()));
      }),
    ),
  );

  skipNextControl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SKIP_NEXT_CONTROL),
      concatLatestFrom(() => this.store.select(KEY_PAPERWORK_STORE)),
      map((contactResult) => contactResult[CONCAT_RESULT_ACTION_INDEX]),
      map((paperworkState) => this.paperworkService.isPutRequired(paperworkState)),
      map((isPutRequired) => !isPutRequired ? SKIP_SIGNATURE() : SIGNATURE_OK()),
    ),
  );

  skipSignature$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SKIP_SIGNATURE),
      concatLatestFrom(() => this.store.select(KEY_PAPERWORK_STORE)),
      map((contactResult) => contactResult[CONCAT_RESULT_ACTION_INDEX]),
      map((paperworkState) => this.paperworkService.isStorageRequired(paperworkState)),
      map((isStorageRequired) => !isStorageRequired ? SKIP_STORAGE() : GET_PRESIGNED_URL_OK),
    ),
  );

  presignedUrls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SIGNATURE_OK),
      concatLatestFrom(() => this.store.select(KEY_PAPERWORK_STORE)),
      map((contactResult) => contactResult[CONCAT_RESULT_ACTION_INDEX]),
      map((paperworkState) => this.paperworkService.getFilesToStore(paperworkState)),
      // todo: ¿que hacer si no hay archivos? el flujo se corta si no existen archivos
      filter((files) => files.length > 0),
      map((files) => ({requests: this.storageService.getParamsToRequestSignedUrl(files), files})),
      mergeMap(({requests, files}) =>
        combineLatest(requests).pipe(
          map((urls) => urls.map((url, index) => ({...url, ...files[index]}))),
          map((documents) => GET_PRESIGNED_URL_OK({documents})),
          catchError((error) => of(GET_PRESIGNED_URL_ERR({error}))),
        )
      ),
    ),
  );

  skipStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SKIP_STORAGE),
      concatLatestFrom(() => this.store.select(KEY_PAPERWORK_STORE)),
      map((contactResult) => contactResult[CONCAT_RESULT_ACTION_INDEX]),
      map((paperworkState) => this.paperworkService.isPutRequired(paperworkState)),
      map((isPutRequired) => isPutRequired ? STORAGE_OK : SKIP_SAVE_RECORD()),
    ),
  );

  uploadFilesToS3$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_PRESIGNED_URL_OK),
      concatLatestFrom(() => this.store.select(KEY_PAPERWORK_STORE)),
      map((paperworkStore) => paperworkStore.slice(CONCAT_RESULT_ACTION_INDEX)),
      map(([paperworkState]: any) => ({
        requests: this.storageService.uploadObjectToS3(paperworkState.files),
        files   : paperworkState.files,
      })),
      switchMap(({requests, files}) =>
        combineLatest(requests).pipe(
          exhaustMap(() => this.storageService.presignedUrlToAttachments(files)),
          map((attachments) => STORAGE_OK({attachments})),
          catchError((error) => of(STORAGE_ERR({error}))),
        ),
      ),
    ),
  );

  saveRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(STORAGE_OK),
      concatLatestFrom(() => this.store.select(KEY_PAPERWORK_STORE)),
      map((contactResult) => contactResult[CONCAT_RESULT_ACTION_INDEX]),
      filter(
        (paperworkState) =>
          this.paperworkService.isPutRequired(paperworkState) === true && paperworkState.progress === 0.6,
      ),
      map((paperworkState) => this.paperworkService.clearRecordForSave(paperworkState)),
      tap(({paperwork, definition}) => this.store.dispatch(definition.action({valuesToSave: paperwork}))),
      switchMap((paperwork) =>
        this.store.select(paperwork.definition.key).pipe(
          filter((key: any) => key.isSaving === true || key.error !== null),
          map((componentKey: any) => {
            if (componentKey.isSaving) return SAVE_RECORD_OK({paperwork: componentKey.current});
            return SAVE_RECORD_ERR({error: componentKey.error});
          }),
        ),
      ),
    ),
  );

  skipSaveRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SKIP_SAVE_RECORD),
      concatLatestFrom(() => this.store.select(KEY_PAPERWORK_STORE)),
      map((contactResult) => contactResult[CONCAT_RESULT_ACTION_INDEX]),
      map((paperworkState) => SAVE_RECORD_OK({ paperwork: paperworkState.paperwork })),
    ),
  );

  sendEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SAVE_RECORD_OK),
      concatLatestFrom(() => this.store.select(KEY_PAPERWORK_STORE)),
      map((contactResult) => contactResult[CONCAT_RESULT_ACTION_INDEX]),
      tap((state) => !this.paperworkService.isRequiredSendEmail(state) && this.store.dispatch(SKIP_SEND_EMAIL())),
      filter((state) => this.paperworkService.isRequiredSendEmail(state) === true && state.progress === 0.8),
      switchMap((paperwork) => this.notificationService.getRequestsToSendMail(paperwork)),
      tap((requests) => { if(!requests || requests.length === 0) this.store.dispatch(PPW_SEND_EMAIL_EMPTY());}),
      switchMap((requests) =>
        combineLatest(requests).pipe(
          map((a) => PPW_SEND_EMAIL_OK()),
          catchError((error) => of(PPW_SEND_EMAIL_ERR({error}))),
        ),
      ),
    ),
  );

  skipSendEmail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SKIP_SEND_EMAIL),
        concatLatestFrom(() => this.store.select(KEY_PAPERWORK_STORE)),
        map((contactResult) => contactResult[CONCAT_RESULT_ACTION_INDEX]),
        map((paperworkState) => this.paperworkService.isRequiredSendEmail(paperworkState)),
      ),
    {dispatch: false},
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private submitService: SubmitService,
    private storageService: StorageService,
    private paperworkService: PaperworkService,
    private notificationService: NotificationService,
    private buildMailNextControlService: BuildMailNextControlService,
  ) {
  }
}
