import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector   : 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls  : ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  @Input() base64: string;
  @Input() isPreview: boolean;

  theme = 'dark';
  disabled = false;
  activated = true;
  zoomLevels = 'auto';
  background = '#474747';
  sidebarOpen: false;

  public handTool = true;

  constructor() {

  }

  ngOnInit(): void {

  }


    print()  {
      const byteCharacters = atob(this.base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++)
        byteNumbers[i] = byteCharacters.charCodeAt(i);

      const byteArray = new Uint8Array(byteNumbers);
      const file = new Blob([byteArray], {type: 'application/pdf;base64'});
      // Para descargar
      // const fileURL = URL.createObjectURL(file);
      // window.open(fileURL);

      const blobUrl = window.URL.createObjectURL((file));
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.contentWindow.focus();
      setTimeout(() => iframe.contentWindow.print(), 1000);
  }
}
