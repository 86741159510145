import {PresignedUrlResponse} from '@shared/interfaces/storage.interface';
import {Action, createReducer, on} from '@ngrx/store';

import {PUT_OBJECT_S3, PUT_OBJECT_S3_ERR, PUT_OBJECT_S3_OK} from '../actions';
import {CREATE_PRESIGNED_URL, CREATE_PRESIGNED_URL_ERR, CREATE_PRESIGNED_URL_OK} from '../actions';
import {CREATE_LIST_PRESIGNED_URL, CREATE_LIST_PRESIGNED_URL_ERR, CREATE_LIST_PRESIGNED_URL_OK} from '../actions';

export interface StorageState {
  authorizer: PresignedUrlResponse;
  listAuth: PresignedUrlResponse[];
  progress: number;
  isLoaded: boolean;
  isLoading: boolean;
  status: string;
  error: any;
}

export const storageState: StorageState = {
  authorizer: null,
  listAuth  : null,
  progress  : 0,
  status    : '',
  isLoaded  : false,
  isLoading : false,
  error     : null,
};

const createReducerStorage = createReducer(
  storageState,

  on(CREATE_PRESIGNED_URL, (state) => ({
    ...state,
    authorizer: null,
    progress  : 0,
    isLoading : true,
    isLoaded  : false,
    status    : 'Solicitando permisos para cargar documentos.',
  })),

  on(CREATE_PRESIGNED_URL_OK, (state, {signedUrl}) => ({
    ...state,
    isLoading : false,
    status    : 'Se obtuvo el permiso para cargar documentos',
    isLoaded  : true,
    authorizer: {...signedUrl},
  })),

  on(CREATE_PRESIGNED_URL_ERR, (state, {payload}) => ({
    ...state,
    isLoading : false,
    isLoaded  : false,
    authorizer: null,
    status    : 'No se obtuvo el permiso para cargar documentos',
    error     : {
      url    : payload.url,
      name   : payload.name,
      message: payload.message,
    },
  })),

  on(CREATE_LIST_PRESIGNED_URL, (state) => ({
    ...state,
    authorizer: null,
    listAuth  : null,
    progress  : 0,
    isLoading : true,
    isLoaded  : false,
    status    : 'Solicitando permisos para cargar lista de documentos.',
  })),

  on(CREATE_LIST_PRESIGNED_URL_OK, (state, {signedUrls}) => ({
    ...state,
    isLoading: false,
    status   : 'Se obtuvo el permiso para cargar lista de documentos',
    isLoaded : true,
    listAuth : [...signedUrls],
  })),

  on(CREATE_LIST_PRESIGNED_URL_ERR, (state, {payload}) => ({
    ...state,
    isLoading : false,
    isLoaded  : false,
    authorizer: null,
    status    : 'No se obtuvo el permiso para cargar documentos',
    error     : {
      url    : payload.url,
      name   : payload.name,
      message: payload.message,
    },
  })),

  on(PUT_OBJECT_S3, (state) => ({...state, isLoading: true, isLoaded: false})),

  on(PUT_OBJECT_S3_OK, (state, {progress}) => ({
    ...state,
    isLoading: false,
    status   : 'El documento fue respaldado.',
    isLoaded : true,
    progress,
  })),

  on(PUT_OBJECT_S3_ERR, (state, {payload}) => ({
    ...state,
    isLoading: false,
    isLoaded : false,
    status   : 'Ocurrio un error al subir el respaldo el documento.',
    error    : {
      url    : payload.url,
      name   : payload.name,
      message: payload.message,
    },
  })),
);

export const storageReducer = (state: StorageState, action: Action) => createReducerStorage(state, action);
