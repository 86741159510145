import { ROOT_ROUTES } from '../../../app-routing.module';
import { CLINICAL_ROUTES } from '@clinical/clinical-routes.module';
import { DERIVATIONS_ROUTES } from '@clinical/derivations/derivations-routes.module';
import { PAPERWORK_DERIVATIONS_DEFINITION } from '@clinical/derivations/constants/paperwork-derivations.const';

export const DERIVATIONS = {
  kinesiología: {
    section: 'Kinesiología',
    path: '/derivaciones/interconsultas',
  },
  fonoaudiología: {
    section: 'Fonoaudiología',
    path: '/derivaciones/interconsultas',
  },
  'terapia ocupacional': {
    section: 'Terapia Ocupacional',
    path: '/derivaciones/interconsultas',
  },
  [PAPERWORK_DERIVATIONS_DEFINITION.interconsultas.group]: {
    section: 'Interconsulta o Derivación',
  },
};

export const SPECIALTIES = {
  programa: 'Programa de insuficiencia Cardiaca',
  programa_ud: 'Programa de insuficiencia Cardiaca UC',
  unidad: 'Unidad Salud Mental Integral y Recepcion (USMI-R)',
  seguimiento: 'Seguimiento Orientacion Quirurgica',
  cefaleas: 'Programa Cefaleas UC'
}

export const MAIL_DERIVATIONS = {
  programa:    ['insuficienciacardiaca@ucchristus.cl'],
  unidad:      ['contacto.providencia@ucchristus.cl'],
  seguimiento: ['teayudotelemedicina@ucchristus.cl'],
  cefaleas: ['cefaleas@ucchristus.cl']
}

export const HISTORY_PROFESSIONAL_ROUTE
  = `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.indications}/${DERIVATIONS_ROUTES.historyProfessionalInterconsultations}`;
