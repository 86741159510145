/* eslint-disable max-len */
import { getUnit } from '@clinical/medicines/constants/posologies.const';
import { patientTopCard } from '@shared/helpers/pdf-patient-card.helper';

export const buildContentPdfNurseEpicrisis = (paperwork, group) => {
  const {rows}                          = group;
  const generalCare                     = rows[0].medicationPrecautions.generalCare.map(item => ({
    text: `${item}`,
    bold: false,
  }));
  const curesGeneralCare                = rows[0].cures.generalCare.map(item => ({text: `${item}`, bold: false}));
  const invasivePatientsDrainage        = rows[0].invasivePatients.drainage.map(item => ({
    text: `${item}`,
    bold: false,
  }));
  const invasivePatientsUrinaryCatheter = rows[0].invasivePatients.urinaryCatheter.map(item => ({
    text: `${item}`,
    bold: false,
  }));
  const invasivePatientGastricTube      = rows[0].invasivePatients.gastricTube.map(item => ({
    text: `${item}`,
    bold: false,
  }));
  const invasivePatientVenousCatheter   = rows[0].invasivePatients.venousCatheter.map(item => ({
    text: `${item}`,
    bold: false,
  }));
  const invasivePatientTracheostomy     = rows[0].invasivePatients.tracheostomy.map(item => ({
    text: `${item}`,
    bold: false,
  }));
  const invasivePatientHomeOxygen       = rows[0].invasivePatients.homeOxygen.map(item => ({
    text: `${item}`,
    bold: false,
  }));
  const invasivePatientBipap            = rows[0].invasivePatients.bipap.map(item => ({text: `${item}`, bold: false}));
  const nuerseEpicrisisDelivery         = rows[0].delivery.options.map(item => ({text: `${item}`, bold: false}));

  ////// ******* Propiedades de los Medicamentos ******* \\\\\\
const MEDICINES = rows[0].medications.map(itemForm => {
  const { observations, start } = itemForm;
  const {duration, each, posology} = itemForm;
  const {csp, compounds} = itemForm;

  const COMPOSITION = {text: `${compounds}`, bold: true, fontSize: 12, alignment: 'justify'};
  if (!compounds) COMPOSITION.text = '';
  const CSP = {
    text     : `C.S.P.: ${getUnit(csp?.unit, csp?.quantity)}`,
    bold     : true,
    fontSize : 12,
    alignment: 'justify',
};

if (!csp) CSP.text = '';
const DURATION = {
  text: `Aplicación: ${posology.quantity} ${getUnit(posology.unit, posology.quantity)} 
  cada ${each.quantity} ${getUnit(each.unit, each.quantity)} ${duration.unit === 'permanente' || 'sos' ? 'por' : 'por'} 
  ${duration.unit !== 'permanente' || 'sos' ? duration.quantity : ''} ${getUnit(duration.unit, duration.quantity)}`,
};
const START_TREATMENT = {
  text: `Inicio del Tratamiento: ${start}`,
};

const OBSERVATIONS = {text: `Observaciones: ${observations}`, margin: [0, 0, 0, 10], alignment: 'justify'};
if (!observations) OBSERVATIONS.text = '';

return [
  {
      ul    : [COMPOSITION],
      margin: [0, 10, 0, 0],
  },
  {
      fontSize: 10,
      type    : 'none',
      ol      : [CSP, DURATION, START_TREATMENT, OBSERVATIONS],
  },
];

});

  return rows.map((itemForm) => [
    ...patientTopCard(paperwork),
    {text: `MEDICAMENTOS:`, bold: true, fontSize: 14, margin: [0, 0, 0, 0]},
    ...MEDICINES,
    {text: `PRECAUSIONES CON LOS MEDICAMENTOS`, bold: true, fontSize: 14, margin: [0, 0, 0, 10]},
    ...generalCare,

    {text: `OTROS CUIDADOS: ${itemForm.medicationPrecautions?.others}`, fontSize: 12, margin: [0, 0, 0, 0]},

    {text: `CONSULTE AL SERVICIO DE URGENCIAS EN CASO DE`, bold: true, fontSize: 14, margin: [0, 20, 0, 0]},

    {
      text    : `Pacientes adultos/pediátricos MQ: ${itemForm.emergencyService.adultPatients}`,
      bold    : false,
      fontSize: 12,
      margin  : [0, 10, 0, 0],
    },
    {
      text    : `Pacientes recién nacido: ${itemForm.emergencyService.newbornPatients}`,
      bold    : false,
      fontSize: 12,
      margin  : [0, 5, 0, 0],
    },
    {
      text    : `Pacientes puérpera: ${itemForm.emergencyService.puerperaPatients}`,
      bold    : false,
      fontSize: 12,
      margin  : [0, 5, 0, 0],
    },
    {text: `OTROS: ${itemForm.emergencyService.others}`, bold: false, fontSize: 12, margin: [0, 5, 0, 0]},

    {text: `ESTADO DE LA PIEL`, bold: true, fontSize: 14, margin: [0, 0, 0, 0], },
    {text: `${itemForm.skinCondition.skin}`, bold: false, fontSize: 12, margin: [0, 10, 0, 0]},
    {text: `DETALLES: ${itemForm.skinCondition.descriptionSkin}`, bold: false, fontSize: 12, margin: [0, 5, 0, 0],pageBreak: 'before',},

    {text: `CURACIONES`, bold: true, fontSize: 14, margin: [0, 20, 0, 10]},
    ...curesGeneralCare,
    {text: `FECHA ÚLTIMA CURACIÓN: ${itemForm.cures.dateOfLastCure}`, bold: false, fontSize: 12, margin: [0, 5, 0, 0]},
    {text: `FECHA PRÓXIMA CURACIÓN: ${itemForm.cures.nextCureDate}`, bold: false, fontSize: 12, margin: [0, 5, 0, 0]},

    {text: `LUGAR PROXIMA CURACIÓN`, bold: true, fontSize: 14, margin: [0, 20, 0, 0]},
    {text: `CENTRO: ${itemForm.nextCure?.center?.name}`, bold: false, fontSize: 12, margin: [0, 10, 0, 0]},
    {text: `DIRECCIÓN: ${itemForm.nextCure.direction}`, bold: false, fontSize: 12, margin: [0, 5, 0, 0]},
    {text: `COMUNA: ${itemForm.nextCure.commune}`, bold: false, fontSize: 12, margin: [0, 5, 0, 0]},

    {text: `PACIENTE DE ALTA CON INTENSIVOS`, bold: true, fontSize: 14, margin: [0, 20, 0, 10]},
    {text: `DRENAJE:`, bold: false, fontSize: 12, margin: [0, 5, 0, 0]},
    ...invasivePatientsDrainage,
    {text: `CATETÉR URINARIO:`, bold: false, fontSize: 12, margin: [0, 5, 0, 0]},
    ...invasivePatientsUrinaryCatheter,
    {text: `SONDA GASTRICA:`, bold: false, fontSize: 12, margin: [0, 5, 0, 0]},
    ...invasivePatientGastricTube,
    {text: `CATETÉR VENOSO:`, bold: false, fontSize: 12, margin: [0, 5, 0, 0]},
    ...invasivePatientVenousCatheter,
    {text: `TRAQUEOSTOMÍA:`, bold: false, fontSize: 12, margin: [0, 5, 0, 0],pageBreak: 'before'},
    ...invasivePatientTracheostomy,
    {text: `OXÍGENO DOMICILIARIO:`, bold: false, fontSize: 12, margin: [0, 5, 0, 0]},
    ...invasivePatientHomeOxygen,
    {text: `BIPAP:`, bold: false, fontSize: 12, margin: [0, 5, 0, 0]},
    ...invasivePatientBipap,
    {text: `OBSERVACIONES: ${itemForm.invasivePatients.others}`, bold: false, fontSize: 12, margin: [0, 5, 0, 0]},

    {text: `EXÁMENES PENDIENTES POR RETIRAR`, bold: false, fontSize: 14, margin: [0, 0, 0, 10]},
    {text: `CUÁL: ${itemForm.pendingExams.which}`, bold: false, fontSize: 12, margin: [0, 5, 0, 0]},
    {text: `DÓNDE: ${itemForm.pendingExams.where}`, bold: false, fontSize: 12, margin: [0, 5, 0, 0]},
    {text: `CUANDO: ${itemForm.pendingExams.when}`, bold: false, fontSize: 12, margin: [0, 5, 0, 0]},

    {text: `SE ENTREGA A PACIENTE`, bold: true, fontSize: 14, margin: [0, 20, 0, 10]},
    ...nuerseEpicrisisDelivery,
    {text: `DETALLES INSUMOS: ${itemForm.delivery.inputsDetails}`, bold: false, fontSize: 12, margin: [0, 5, 0, 0]},
    {
      text    : `DETALLES ELEMENTOS DE APOYO: ${itemForm.delivery.supportElements}`,
      bold    : false,
      fontSize: 12,
      margin  : [0, 5, 0, 0],
    },
    {text: `OBSERVACIONES: ${itemForm.delivery.others}`, bold: false, fontSize: 12, margin: [0, 5, 0, 0]},

    {text: `IMPORTANTE RELACIONADO CON LA CUENTA`, bold: true, fontSize: 14, margin: [0, 20, 0, 10]},
    {
      text    : `Dentro de los siguientes ${itemForm.contact.days} días hábiles el departamento de recaudación se contactará con Ud. por teléfono`,
      bold    : false,
      fontSize: 12,
      margin  : [0, 5, 0, 0],
    },

    {
      text    : `Traslado al alta Debe realizarse en silla de ruedas hasta la puerta del centro, sin excepciones. El no cumplimiento es de responsabilidad del paciente.`,
      bold    : true,
      fontSize: 14,
      margin  : [0, 20, 0, 10],
    },
    {text: `PACIENTE SE TRASLADA EN: ${itemForm.relocation.option}`, bold: false, fontSize: 12, margin: [0, 5, 0, 0]},
    {text: `OTRO: ${itemForm.relocation.others}`, bold: false, fontSize: 12, margin: [0, 5, 0, 0]},
  ]);
};
