import { AlertController, ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { differenceInMinutes, lightFormat } from 'date-fns/esm';
import { UiService } from '@shared/services/ui.service';
import { ReportService } from '../services/report.service';
import { ActionTableDef, PageTableDef, TableDefinition } from '@shared/interfaces/table.interface';

@Component({
  selector: 'app-patient-recipe',
  templateUrl: './patient-report.component.html',
  styleUrls: ['./patient-report.component.scss'],
})
export class PatientReportComponent implements OnInit {
  @Input() data;
  @Input() pageTable: PageTableDef;

  currentDate: Date = new Date();
  newData: any;
  hideOption = false;
  isLoading = true;

  source: any[] = [];
  actions: ActionTableDef[] = [
    { name: 'Ver', color: 'primary', icon: 'eye-outline', title: 'Ver Documento', dispatch: 'openPreviewPDF' },
    { name: 'Duplicar', color: 'primary', icon: 'documents-outline', title: 'Duplicar', dispatch: 'redirectAndSet' },
    { name: 'Editar', color: 'primary', icon: 'create-outline', title: 'Editar', dispatch: 'editIndication' },
    { name: 'Eliminar', color: 'primary', icon: 'trash-outline', title: 'Eliminar', dispatch: 'deleteIndication' },
  ];
  period: boolean;
  tableDef: TableDefinition[] = [
    {
      th: 'Fecha',
      td: 'createdAt',
      tag: { name: 'chip', color: 'primary', outline: false, slot: null, cssClass: null, isHide: false },
    },
    {
      th: 'Profesional',
      td: 'professional',
      tag: { name: 'p', color: 'default', outline: false, slot: null, cssClass: null, isHide: false },
    },
    {
      th: 'Especialidad',
      td: 'specialty',
      tag: { name: 'p', color: 'default', outline: false, slot: null, cssClass: null, isHide: false },
    },
    {
      th: 'Tipo',
      td: 'type',
      tag: { name: 'p', color: 'default', outline: false, slot: null, cssClass: null, isHide: false },
    },
  ];

  constructor(
    private reportService: ReportService,
    private modalController: ModalController,
    public alertController: AlertController,
    public uiService: UiService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    const patientId = `PAT#${this.data.patient.id}`;
    const typeReport = this.pageTable.typeForm;
    this.getPatientRecipes(patientId, typeReport);
  }

  getPatientRecipes(patientId: string, typeReport: string) {
    this.reportService.findByType(patientId, typeReport).subscribe((data) => {
      const fixData = data.map((newData) => {
        const actionList = ['Ver', 'Duplicar'];
        const dif = differenceInMinutes(this.currentDate, new Date(newData.createdAt));
        if (dif < 30 && dif != null) actionList.push(...['Editar', 'Eliminar']);
        this.newData = newData;
        let formatDate = '';
        let specialtyName = '';
        if (newData.professional.speciality) specialtyName = newData.professional.speciality.name;
        if (newData.professional.specialty) specialtyName = newData.professional.specialty.name;
        //TODO: borrar este if de fecha
        if (newData.createdAt) formatDate = lightFormat(new Date(newData.createdAt), 'dd-MM-yyyy HH:mm');

        return {
          actions: actionList,
          data: newData,
          createdAt: formatDate,
          specialty: specialtyName,
          professional: newData.professional.names + ' ' + newData.professional.surnames,
          type: this.getType(this.newData),
        };
      });
      this.source = fixData;
      this.isLoading = false;
    });
  }

  getType(newData: any): string {
    if (newData.content === undefined || newData.content.length === 0) return '';
    let types = '';
    newData.content.map((data) => {
      if(!types.includes(data.group)) types += data.group + ' ';
    });
    return types;
  }

  routeCreateNew() {
    this.router.navigate([`${this.pageTable.router}`]);
    this.dismiss();
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
