import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Anamnesis, Diagnostic } from '@shared/interfaces/anamnesis.interface';

import { AnamnesisTemplate, DESTINATION, EXAMS } from '@components/anamnesis/constants/anamnesis.const';
import { ModalController } from '@ionic/angular';

import { FormFieldsValidate } from '@core/reactive-forms/form-fields-validate.helper';
import { format } from 'date-fns';
import { SpecialtiesService } from '@shared/services/specialties.service';

import { Patient } from '@components/patient/interfaces/patient.interface';
import { MedicalSpecialty } from '@shared/interfaces/specialties.interface';
import { AuthService } from 'src/app/auth/services/auth.service';
import { DiagnosticFinderComponent } from '../diagnostics-finder/diagnostic-finder.component';
@Component({
  selector: 'app-anamnesis-integral-frm',
  templateUrl: './anamnesis-integral-frm.component.html',
  styleUrls: ['./anamnesis-integral-frm.component.scss'],
})
export class AnamnesisIntegralFrmComponent implements OnInit {
  @Input() isWriteMode: boolean;
  @Input() anamnesis: Anamnesis; //modificar a integral
  @Input() template: AnamnesisTemplate;
  @Input() diagnostics: Diagnostic[] = [];

  frmAnamanesisIntegral: UntypedFormGroup;
  specialites: MedicalSpecialty[];
  anamnesisFrm: Anamnesis;
  patient: Patient;
  isLoadedPatient: boolean;
  isSearchPatient: boolean;
  error: string;
  currentDate = format(new Date(), 'dd-MM-yyyy');
  destination: string[] = DESTINATION.names;
  exams: string[] = EXAMS.names;
  arraySpecialites: any[] = [];
  currentSegment = 'consult';
  cigarCount: number;
  timeFrame: number;
  weight: number;
  totalCount = 0;
  sizeHigh: any;
  hidden = true;
  size: number;
  imc = 0;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public modalController: ModalController,
    private specialtiesService: SpecialtiesService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.buildForm();

    this.getSpecialties();
  }

  segmentChanged(event: any) {
    this.currentSegment = event.detail.value;
  }

  calculate(event, prop: string) {
    switch (prop) {
      case 'cigarCount':
        this.cigarCount = event.detail.value;
        break;
      case 'timeFrame':
        this.timeFrame = event.detail.value;
        break;
      case 'weight':
        this.weight = Number(event.detail.value);
        break;
      case 'size':
        this.size = event.detail.value;
        this.sizeHigh = Math.pow(this.size, 2);
        break;
    }
    const totalCount = (this.cigarCount * (this.timeFrame * 365)) / 20;
    const imc = this.weight / this.sizeHigh;
    this.totalCount = totalCount;
    this.imc = imc;
  }

  examChanged(event) {
    const names = event.detail.value;
    switch (names) {
      case 'COLONOSCOPÍA':
        this.hidden = true;
        break;
      case 'TEST DE EMORRAGIAS OCULTAS':
        this.hidden = true;
        break;
      case 'MAMOGRAFÍA':
        this.hidden = true;
        break;
      case 'PAP':
        this.hidden = true;
        break;
      case 'OTRO':
        this.hidden = false;
        this.frmAnamanesisIntegral.controls.otherExams.patchValue('');
        break;
    }
  }

  getSpecialties() {
    const idProfessional = this.authService.professionalRef.id;
    this.specialtiesService.getSpecialties(idProfessional).subscribe((specialties) => {
      this.specialites = specialties;
    });
  }
  dismiss() {
    this.modalController.dismiss();
  }

  deleteDiagnostic(index: number) {
    this.diagnostics.splice(index, 1);
  }

  async openDiagnosticFinder() {
    const modal = await this.modalController.create({
      component: DiagnosticFinderComponent,
      cssClass: 'cmodal-xl',
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    const diagnostic: Diagnostic[] = data;
    if (diagnostic) this.diagnostics = [...diagnostic];
  }

  buildForm(): void {
    this.frmAnamanesisIntegral = this.formBuilder.group({
      isDraf: true,
      imc: '',
      size: '',
      exams: '',
      weight: '',
      destiny: '',
      heartRate: '',
      timeFrame: '',
      otherExams: '',
      vaccinePni: '',
      cigarCount: '',
      totalCount: '',
      drinkPerDay: '',
      typeConsult: '',
      internalName: '',
      illicitDrugs: '',
      healthyHabits: '',
      nextDateControl: '',
      otherDestination: '',
      habitObservations: '',
      startDateAdmission: '',
      currentDateConsult: '',
      vaccineComplementary: '',
      specialtyNextControl: '',
      systolicBloodPressure: '',
      diastolicBloodPressure: '',
      reason: FormFieldsValidate.requiredMinLength(10),
      anamnesis: FormFieldsValidate.requiredMinLength(10),
      indications: FormFieldsValidate.requiredMinLength(10),
      vaccinePniObservations: FormFieldsValidate.requiredMinLength(10),
      physicalExamSpecifications: FormFieldsValidate.requiredMinLength(10),
      vaccineComplementaryObservations: FormFieldsValidate.requiredMinLength(10),
      anamnesisName: name,
    });
  }
}
