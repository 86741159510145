import {patientTopCard} from '@shared/helpers/pdf-patient-card.helper';

export const buildContentPdfResidueFreeRegimen = (paperwork, group) => {
  const {rows} = group;
  return rows.map((itemForm) => [
    ...patientTopCard(paperwork),

    {
      text    : `Estimado/a paciente: A usted se le ha indicado un régimen sin residuos, el que contiene un mínimo de fibra soluble llamada pectina, la cual favorece la diminución de la formación de heces. Además excluye aquellos alimentos que generan irritación al tracto digestivo y los alimentos que contengan nutrientes de difícil digestión como grasas saturadas, lactosa y otros. `,
      bold    : false,
      fontSize: 12,
      alignment: 'justify',
      margin: [0, 0, 0, 0],
    },

    {text: `ALIMENTOS PERMITIDOS:`, bold: true, fontSize: 14, margin: [0, 10, 0, 5]},
    {
      ul: [
        'Queso fresco o quesillo.',
        'Clara de huevo (en sopa o postres).',
        'Jamón cocido de ave o pavo.',
        'Carnes magras: de vacuno (posta negra o rosada, lomo liso, filete, punta ganso, posta paleta, abastero, etc.), de ave sin piel (pollo y pavo), pescados (merluza, reineta, corvina, congrio, lenguado, tilapia), cerdo (filete, pulpa de pierna, lomo de centro, posta rosada o negra) al vapor, a la plancha o al horno. ',
        'Cereales y Farináceos: maicena, sémola, chuño, fideos, arroz, cuscús, fideos de arroz, preparados en sopas, guisos y postres.',
        'Galletas de agua, soda, arroz o chuño.',
        'Pan blanco (pan molde, marraqueta) de preferencia tostado.',
        'Frutas y verduras: Manzana cocida, membrillo cocido, plátano sin semillas.',
        'Verduras: Zanahoria cocida, Zapallo camote cocido.',
        'Miel o dulce de membrillo.',
        'Azúcar o Endulzante.',
        'Jalea o Helados de agua (piña, limón).',
        'Aceite vegetal en crudo, agregar al momento de servir.',
        'Infusiones de té simple, agua de hierbas, agua mineral (sin gas), agua purificada, jugo de compota de manzana.',
        'Sal y especies (anís, canela, vainilla, orégano).',
      ],
    },

    {text: `AEVITAR CONSUMO:`, bold: true, fontSize: 14, margin: [0, 5, 0, 10],pageBreak: 'before'},
    {
      ul: [
        'Verduras y frutas de todo tipo, exceptuando las antes mencionadas (ricas en pectinas).',
        'Leche, yogurt, quesos maduros y quesos crema.',
        'Carnes con alto contenido de materia grasa como cordero, cerdo, pato, ganso, vísceras (riñones, guatitas, panita, etc.).',
        'Fiambres y Embutidos.',
        'Leguminosas como porotos, lentejas y garbanzos.',
        'Frituras de todo tipo, mantequilla, margarina, manteca, mayonesa, salsas a base de crema.',
        'Productos de pastelería, debido a su alto contenido de grasa saturada.',
        'Condimentos como ajo, ají, pimienta, comino, mostaza y salsas preparadas.',
        'Irritantes de la mucosa gástrica como té cargado, café, chocolates, cocoa, hierba mate, bebidas alcohólicas.',
        'Snack y productos procesados o ultra procesados.',
      ],
    },


    {text: `RECOMENDACIONES GENERALES:`, bold: true, fontSize: 14, margin: [0, 15, 0, 5]},
    {
      ul: [
        'Este régimen debe seguirlo por el tiempo indicado por su médico o Nutricionista.',
        'Cocine los alimentos en agua, al vapor, al horno, a la plancha, NUNCA FRITOS.',
        'Debe usar el aceite crudo, agregado al momento de servir.',
      ],
    },

    {text: `EJEMPLO DE MINUTA PARA 1 DÍA`, bold: true, fontSize: 14, margin: [0, 0, 0, 15],pageBreak: 'before'},
    {
      style: 'tableExample',
      table: {
        body: [
          [{text: 'TIEMPO DE COMIDAS', style: 'tableHeader', alignment: 'center', bold: true}, {
            text     : 'ALIMENTOS',
            style    : 'tableHeader',
            alignment: 'center',
            bold     : true,
          }],
          ['Desayuno', 'Te puro o chuño en agua con azúcar o endulzante. \n Pan marraqueta tostado c/quesillo y pechuga de pavo cocida.'],
          ['Colación media mañana ', 'Jalea'],
          ['Almuerzo', 'Sopa de sémola c/clara \n Pavo a la plancha con fideos blancos \n Compota de manzana o membrillo con canela'],
          ['Once', 'Te puro o agua de hierbas con azúcar o endulzante Galletas soda o agua \n c/ Miel y Quesillo sin lactosa'],
          ['Cena', 'Sopa de fideos c/clara y zanahoria \n Pechuga de pollo desmenuzada cocinada al vapor con \n arroz cocinado al vapor con zanahoria \n Jalea o maicena en agua.'],
        ],
      },
    },


    {text: `OBSERVACIONES:`, bold: true, fontSize: 14, margin: [0, 15, 0, 0]},
    {text: `${itemForm.observations}`, bold: false, fontSize: 12, margin: [0, 10, 0, 5]},


  ]);
};
