import { Briefing, Reproductive } from '@shared/interfaces/reproductive.interface';
import { Action, createReducer, on } from '@ngrx/store';
import {
  CLEAR_REPRODUCTIVE,
  DELETE_REPRODUCTIVE,
  DELETE_REPRODUCTIVE_ERR,
  DELETE_REPRODUCTIVE_OK,
  LOAD_REPRODUCTIVE_BY_PATIENT,
  LOAD_REPRODUCTIVE_BY_PATIENT_ERR,
  LOAD_REPRODUCTIVE_BY_PATIENT_OK,
  LOAD_REPRODUCTIVE_BY_PROFESSIONAL,
  LOAD_REPRODUCTIVE_BY_PROFESSIONAL_ERR,
  LOAD_REPRODUCTIVE_BY_PROFESSIONAL_OK,
  PUT_REPRODUCTIVE,
  PUT_REPRODUCTIVE_END,
  PUT_REPRODUCTIVE_ERR,
  PUT_REPRODUCTIVE_OK,
  SELECTED_REPRODUCTIVE,
  UNSELECT_REPRODUCTIVE,
} from '@store/actions';
import { Paperwork } from '@shared/interfaces/paperwork.interface';

export interface ReproductiveState {
  error: any;
  status: string;
  isLoaded: boolean;
  isLoading: boolean;
  briefing: Paperwork<Briefing>[];
  historical: Paperwork<Briefing>[];
  selected: Paperwork<Briefing>;
  lastBrief: Paperwork<Briefing>;
  current: Paperwork;
  isSaving: boolean;
}

export const reproductiveState: ReproductiveState = {
  error: null,
  status: '',
  isLoaded: false,
  isLoading: false,
  briefing: [],
  historical: [],
  selected: null,
  lastBrief: null,
  current: null,
  isSaving: false,
};

const createReducerReproductive = createReducer(
  reproductiveState,
  on(CLEAR_REPRODUCTIVE, (state) => ({
    briefing: [...state.briefing],
    historical: [...state.historical],
    isLoading: false,
    isLoaded: false,
    error: null,
    status: '',
    selected: null,
    lastBrief: null,
    current: null,
    isSaving: false,
  })),

  // Obtener las cirugias de un paciente
  on(LOAD_REPRODUCTIVE_BY_PATIENT, (state) => ({ ...state, isLoading: true })),
  on(LOAD_REPRODUCTIVE_BY_PATIENT_OK, (state, { brief: briefing }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    briefing: [...briefing],
  })),
  on(LOAD_REPRODUCTIVE_BY_PATIENT_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  // Obtener las cirugias de un profesional
  on(LOAD_REPRODUCTIVE_BY_PROFESSIONAL, (state) => ({ ...state, isLoading: true })),
  on(LOAD_REPRODUCTIVE_BY_PROFESSIONAL_OK, (state, { brief: briefing }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    historical: [...briefing],
  })),
  on(LOAD_REPRODUCTIVE_BY_PROFESSIONAL_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(PUT_REPRODUCTIVE_END, (state) => ({ ...state, isSaving: false })),
  on(PUT_REPRODUCTIVE, (state) => ({ ...state, isLoading: true, status: 'Guardando el registro.' })),
  on(PUT_REPRODUCTIVE_OK, (state, { reproductive }) => {
    let briefing = state.briefing.map((ans) => {
      if (ans.id === reproductive.id) return reproductive;
      else return ans;
    });

    let history = state.historical.map((ans) => {
      if (ans.id === reproductive.id) return reproductive;
      else return ans;
    });

    const index = briefing.findIndex((draft) => draft.id === reproductive.id);
    if (index === -1) briefing = [reproductive, ...briefing];

    const indexHistory = history.findIndex((draft) => draft.id === reproductive.id);
    if (indexHistory === -1) history = [reproductive, ...history];

    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      isSaving: true,
      status: 'Registro guardado con éxito',
      briefing: [...briefing],
      historical: [...history],
      lastBrief: { ...reproductive },
      current: { ...reproductive },
    };
  }),

  on(PUT_REPRODUCTIVE_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    isSaving: false,
    current: null,
    status: 'Ocurrio un error al intentar guardar el registro',
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(SELECTED_REPRODUCTIVE, (state, { selected, action }) => ({ ...state, selected: { ...selected }, status: action })),
  on(UNSELECT_REPRODUCTIVE, (state) => ({ ...state, selected: null, isLoaded: false, isLoading: false, status: '' })),

  on(DELETE_REPRODUCTIVE, (state) => ({ ...state, isLoading: true })),
  on(DELETE_REPRODUCTIVE_OK, (state, { brief }) => {
    let forms = [...state.briefing];
    let history = [...state.historical];
    const index = forms.findIndex((draft) => draft.id === brief.id);
    const indexHistory = history.findIndex((draft) => draft.id === brief.id);
    if (index !== -1) forms = [...forms.filter((draft) => draft.id !== brief.id)];
    if (indexHistory !== -1) history = [...history.filter((draft) => draft.id !== brief.id)];

    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      status: 'Formulario eliminado con éxito',
      briefing: [...forms],
      historical: [...history],
    };
  }),
  on(DELETE_REPRODUCTIVE_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    status: 'Ocurrio un error al intentar eliminar el formulario',
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
);

export const reproductiveReducer = (state: ReproductiveState, action: Action) =>
  createReducerReproductive(state, action);
