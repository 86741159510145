import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {AuthService} from '@auth/services/auth.service';
import {EpicrisisNursingService} from '@clinical/medical-discharge/services/epicrisis-nursing.service';
import {PatientService} from '@components/patient/services/patient.service';
import {AlertController, ModalController} from '@ionic/angular';

import {Store} from '@ngrx/store';
import {PreviewPdfComponent} from '@shared/components/pdf-preview/preview-pdf.component';

import {HEADER_INFO, MESSAGES} from '@shared/constants/notifications.const';
import {AccessPatterns, Paperwork, PaperworkDefinition, StoreKeys} from '@shared/interfaces/paperwork.interface';
import {Appointment} from '@shared/interfaces/appointment.interface';
import {AlertService} from '@shared/services/alert.service';
import {PaperworkService} from '@shared/services/paperwork.service';
import {
  CLEAR_PAPERWORK,
  PAPERWORK_OK,
  UNSELECT_DERIVATION,
  UNSELECT_DISCHARGE_MEDICAL,
  UNSELECT_EMERGENCY,
  UNSELECT_EXPERTISE,
  UNSELECT_FORM,
  UNSELECT_HOSPITALIZATION,
  UNSELECT_INDICATION,
  UNSELECT_ONCOLOGY,
  UNSELECT_RECIPE,
  UNSELECT_REPRODUCTIVE,
} from '@store/actions';
import {AppState} from '@store/app.reducers';
import {
  KEY_AGENDA_STORE,
  KEY_DERIVATIONS_STORE,
  KEY_EMERGENCIES_STORE,
  KEY_EXPERTISE_STORE,
  KEY_FORMS_STORE,
  KEY_HOSPITALIZATIONS_STORE,
  KEY_INDICATIONS_STORE,
  KEY_MEDICAL_DISCHARGE,
  KEY_ONCOLOGY_STORE,
  KEY_PAPERWORK_STORE,
  KEY_PATIENT_STORE,
  KEY_PROFESSIONAL_STORE,
  KEY_RECIPES_STORE,
  KEY_REPRODUCTIVE_STORE,
} from '@store/store-keys';

import {firstValueFrom, Observable, Subject} from 'rxjs';
import {combineLatestWith, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {PatientRef} from '@components/patient/interfaces/patient.interface';
import {AppointmentState} from '@components/agenda/store/agenda.reducer';
import {PatientState} from '@store/reducers';

@Component({
  selector: 'app-btn-pdf-preview',
  templateUrl: './btn-pdf-preview.component.html',
  styleUrls: ['./btn-pdf-preview.component.scss'],
})
export class BtnPdfPreviewComponent implements OnInit, OnDestroy {
  @Input() form: any;
  @Input() definition: PaperworkDefinition;

  patient$: Observable<PatientState>;

  currentUrl: string;
  path: string;
  destroy: Subject<string> = new Subject();
  click$: Subject<string> = new Subject<string>();
  buttonAction$ = undefined;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private authService: AuthService,
    private alertService: AlertService,
    private patientService: PatientService,
    private modalController: ModalController,
    private paperworkService: PaperworkService,
    public epicrisisService: EpicrisisNursingService,
    private alertController: AlertController,
  ) {
  }

  /**
   * Inicializar componente.
   */
  ngOnInit(): void {
    this.patient$ = this.store.select(KEY_PATIENT_STORE);

    this.listenRoute();
    this.button$();
  }

  /**
   * Destruir componente.
   * cada vez que se complete el trámite
   */
  ngOnDestroy(): void {
    this.destroy.next('next');
    this.destroy.complete();
  }

  /**
   * Notificar al observable que se disparó el evento clic del botón.
   */
  onSubmit(): void {
    this.click$.next('click');
  }

  listenRoute() {
    this.path = this.router.url.split('/')[1];
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: any) => this.currentUrl = event.url)
    ).subscribe();
  }

  /**
   * Suscripción al evento clic del botón.
   */
  private button$(): void {
    const EVENT_CLICK_POSITION = 1;
    this.buttonAction$ = this.click$
      .pipe(
        tap(() => this.store.dispatch(CLEAR_PAPERWORK())),
        takeUntil(this.destroy),
        map(() => this.getUrl()),
        filter(url => this.currentUrl === url),
        combineLatestWith(this.store.select(KEY_PATIENT_STORE)),
        map((events) => events.slice(EVENT_CLICK_POSITION)),
        filter(() => this.resetOnInvalidContent() === false),
        filter<any>(([patient]) => !patient.isLoading),
        tap<any>(([patient]) => (patient.isLoaded && Object.keys(patient.patient)?.length ? '' : this.presentAlert())),
        filter(([patient]) => patient.isLoaded && !!Object.keys(patient.patient)?.length),
        distinctUntilChanged((prev, curr) => this.resetOnSwitchPatient(prev[0]?.patient, curr[0]?.patient)),
        switchMap(([patient]) => this.getPaperwork(patient)),
        filter(({isPaperworkOK}) => isPaperworkOK === true),
        tap((paperwork) => this.store.dispatch(PAPERWORK_OK({paperwork, definition: this.definition}))),
        combineLatestWith(this.store.select(KEY_PAPERWORK_STORE)),
        map((events) => events.slice(EVENT_CLICK_POSITION)),
        filter(([state]) => state.isLoaded),
      )
      .subscribe();
  }

  /**
   * Renueva suscripción al evento clic del botón.
   */
  private resetButton$(): void {
    this.buttonAction$.unsubscribe();
    this.button$();
  }

  private getUrl(): string {
    if (this.currentUrl) return this.currentUrl;
    this.currentUrl = this.router.url;
    return this.router.url;
  }

  /**
   * Obtener los datos para crear un trámite médico a un paciente.
   *
   * @param patient$ Propiedad paciente del store
   *
   * @returns Paperwork: Trámite médico de un paciente
   */
  private async getPaperwork(patient$): Promise<Paperwork> {
    const professional$ = await firstValueFrom(this.store.select(KEY_PROFESSIONAL_STORE)
      .pipe(takeUntil(this.destroy), take(1)));
    const paperwork$ = await firstValueFrom(this.store.select(KEY_PAPERWORK_STORE)
      .pipe(takeUntil(this.destroy), take(1)));
    const {paperwork} = paperwork$;

    const patient = this.patientService.getPatientRef(patient$.patient);
    const professional = this.authService.getProfessionalRef(professional$.professional);
    const subprofessional = professional$.subprofessional ? {subprofessional: professional$.subprofessional} : {};
    const selectProfessionalRequired = this.authService.isTens(professional$.professional) && !subprofessional.subprofessional;

    if (!patient$.isLoaded) this.presentAlert();
    if (selectProfessionalRequired) this.presentAlertProfessionalRequired();

    const professionalId = professional.id;
    const {module, submodule, key} = this.definition;

    const appointmentState = await firstValueFrom(this.store.select(KEY_AGENDA_STORE)
      .pipe(takeUntil(this.destroy), take(1)));
    const appointment = this.getSelectedAppointment(appointmentState, patient);

    let accessPatterns = {};
    if (paperwork$.definition) {
      accessPatterns = await this.paperworkService.getAccessPatternsPaperwork({module, submodule, professionalId});
      const selectedPaperworkAccessPatterns = await this.getSelectedPaperworkAccessPatterns(key);
      if (selectedPaperworkAccessPatterns) accessPatterns = {...accessPatterns, ...selectedPaperworkAccessPatterns};
    }

    const content = Array.isArray(this.form.value) ? [...this.form.value] : [this.form.value];
    const attachments = [];
    const show = content.length > 0 && this.isModuleCurrentlyLoaded(submodule.submodule);

    const isOpenModal = await this.modalController.getTop();
    if (isOpenModal) await this.modalController.dismiss();

    const newPaperwork = {
      ...paperwork,
      content,
      attachments,
      patient,
      professional,
      appointment,
      ...subprofessional,
      isPaperworkOK: show,
      ...accessPatterns,
    };
    if (patient$.isLoaded && !selectProfessionalRequired && show) await this.openPDFPreview(newPaperwork);

    return newPaperwork;
  }

  /**
   * Renueva suscripción al evento clic del botón si contenido en formulario no es valido
   *
   * @returns boolean (true) si es no valido.
   */
  private resetOnInvalidContent(): boolean {
    const invalidForm = this.form.invalid;
    if (invalidForm) this.resetButton$();
    return invalidForm;
  }

  /**
   * Renueva suscripción al evento clic del botón si el paciente anterior es diferente al actual
   *
   * @returns boolean (true) si es diferente.
   */
  private resetOnSwitchPatient(previous, current): boolean {
    if (previous?.documentNumber === undefined) return false;

    const isDistinct = (current?.documentType === 'temporal')
      ? (`${previous?.names} ${previous?.surnames}`).toLocaleLowerCase() !== (`${current?.names} ${current?.surnames}`).toLocaleLowerCase()
      : previous?.documentNumber !== current?.documentNumber;

    if (isDistinct) this.resetButton$();
    return isDistinct;
  }

  private isModuleCurrentlyLoaded(module: string): boolean {
    const comparableUrlParts = this.currentUrl.split('/').slice(3);
    const moduleParts = module.split('/');
    if (moduleParts.length > comparableUrlParts.length) return false;

    const intersection = comparableUrlParts.filter(path => moduleParts.includes(path));
    return JSON.stringify(moduleParts) === JSON.stringify(intersection);
  }

  /**
   * Levantar ventana para mostrar documento PDF con el contenido del formulario.
   *
   * @returns
   */
  private async openPDFPreview(paperwork: Paperwork): Promise<void> {
    const modal = await this.modalController.create({
      backdropDismiss: false,
      cssClass: 'modal-xxl-float-left',
      component: PreviewPdfComponent,
      componentProps: {paperwork},
    });
    await modal.present();
  }

  /**
   * Notificar que no se ha seleccionado paciente.
   */
  private presentAlert(): void {
    const message = MESSAGES.info.unsetPatient;
    this.alertService.presentAlert(HEADER_INFO, message);
  }

  /**
   * Notificar que no se ha seleccionado profesional.
   */
  private presentAlertProfessionalRequired(): void {
    const message = MESSAGES.info.unsetSubprofessional;
    this.alertService.presentAlert(HEADER_INFO, message);
  }

  /**
   * Obtiene datos de trámite seleccionado (para edición o modificación) si existe
   *
   * @param key store key
   */
  private async getSelectedPaperworkAccessPatterns(key: StoreKeys): Promise<AccessPatterns> {
    const selectedPaperwork = await firstValueFrom(
      this.store.select(key).pipe(
        takeUntil(this.destroy),
        take(1),
        filter((state) => state.hasOwnProperty('selected')),
        map((state: any) => state?.selected),
      ),
    );
    if (!selectedPaperwork || !selectedPaperwork?.pk) return null;

    const {
      prefix,
      suffix,
      shortId,
      gsiMultiCondition2Nd,
      gsiMultiCondition1St,
      datetime,
      pk,
      sk,
      id,
    } = selectedPaperwork;
    this.unselectPaperwork(key);
    return {prefix, suffix, shortId, gsiMultiCondition2Nd, gsiMultiCondition1St, datetime, pk, sk, id};
  }

  private getSelectedAppointment(agenda: AppointmentState, patient: PatientRef): Appointment {
    if (!agenda?.selected) return undefined;

    const appointment: Appointment = agenda.selected;
    if (appointment?.patient?.documentNumber !== patient.documentNumber) return undefined;

    return appointment;
  }

  /**
   * Limpia selección de formulario de trámite
   *
   * @param key store key
   */
  private unselectPaperwork(key: StoreKeys): void {
    if (key === KEY_RECIPES_STORE) this.store.dispatch(UNSELECT_RECIPE());
    if (key === KEY_DERIVATIONS_STORE) this.store.dispatch(UNSELECT_DERIVATION());
    if (key === KEY_FORMS_STORE) this.store.dispatch(UNSELECT_FORM());
    if (key === KEY_INDICATIONS_STORE) this.store.dispatch(UNSELECT_INDICATION());
    if (key === KEY_HOSPITALIZATIONS_STORE) this.store.dispatch(UNSELECT_HOSPITALIZATION());
    if (key === KEY_ONCOLOGY_STORE) this.store.dispatch(UNSELECT_ONCOLOGY());
    if (key === KEY_REPRODUCTIVE_STORE) this.store.dispatch(UNSELECT_REPRODUCTIVE());
    if (key === KEY_EMERGENCIES_STORE) this.store.dispatch(UNSELECT_EMERGENCY());
    if (key === KEY_EXPERTISE_STORE) this.store.dispatch(UNSELECT_EXPERTISE());
    if (key === KEY_MEDICAL_DISCHARGE) this.store.dispatch(UNSELECT_DISCHARGE_MEDICAL());
  }
}
