export const HTML_MAIL_HEAD = `  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <style type="text/css">
      body {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin: 0 !important;
        width: 100% !important;
        -webkit-text-size-adjust: 100% !important;
        -ms-text-size-adjust: 100% !important;
        -webkit-font-smoothing: antialiased !important;
      }

      .tg {
        border-collapse: collapse;
        border-spacing: 0;
      }
      .tg td {
        border-color: white;
        border-style: solid;
        border-width: 1px;
        font-family: Arial, sans-serif;
        font-size: 14px;
        overflow: hidden;
        word-break: normal;
      }
      .tg th {
        border-color: white;
        border-style: solid;
        border-width: 1px;
        font-family: Arial, sans-serif;
        font-size: 14px;
        font-weight: normal;
        overflow: hidden;
        word-break: normal;
      }
      .tg .tg-0lax {
        text-align: left;
        vertical-align: top;
      }

      .mail-content {
        text-align: justify;
      }
      .tableContent img {
        border: 0 !important;
        display: block !important;
        outline: none !important;
      }
      a {
        color: #671e75;
        font-weight: bold;
      }

      p,
      h1 {
        color: #382f2e;
        margin: 0;
      }
      p, ul, li {
        color: #686666;
        font-size: 14px;
        font-weight: normal;
        line-height: 19px;
      }

      a.link1 {
        color: #382f2e;
      }
      a.link2 {
        font-size: 16px;
        text-decoration: none;
        color: #ffffff;
      }

      h2 {
        text-align: left;
        color: #222222;
        font-size: 16px;
        font-weight: normal;
      }
      div,
      p,
      ul,
      h1 {
        margin: 0;
      }

      .bgBody {
        background: #ffffff;
      }
      .bgItem {
        background: #ffffff;
      }

      @media only screen and (max-width: 480px) {
        table[class='MainContainer'],
        td[class='cell'] {
          width: 100% !important;
          height: auto !important;
        }
        td[class='specbundle'] {
          width: 100% !important;
          float: left !important;
          font-size: 13px !important;
          line-height: 17px !important;
          display: block !important;
          padding-bottom: 15px !important;
        }

        td[class='spechide'] {
          display: none !important;
        }
        img[class='banner'] {
          width: 100% !important;
          height: auto !important;
        }
        td[class='left_pad'] {
          padding-left: 15px !important;
          padding-right: 15px !important;
        }
      }

      @media only screen and (max-width: 540px) {
        table[class='MainContainer'],
        td[class='cell'] {
          width: 100% !important;
          height: auto !important;
        }
        td[class='specbundle'] {
          width: 100% !important;
          float: left !important;
          font-size: 13px !important;
          line-height: 17px !important;
          display: block !important;
          padding-bottom: 15px !important;
        }

        td[class='spechide'] {
          display: none !important;
        }
        img[class='banner'] {
          width: 100% !important;
          height: auto !important;
        }
        .font {
          font-size: 18px !important;
          line-height: 22px !important;
        }
        .font1 {
          font-size: 18px !important;
          line-height: 22px !important;
        }
      }
    </style>
  </head>`;
