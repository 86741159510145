import {PAPERWORK_DERIVATIONS_DEFINITION} from '@clinical/derivations/constants/paperwork-derivations.const';
import {PAPERWORK_EDUCATIONAL_DEFINITION} from '@clinical/educational/constants/paperwork-educational.const';
import {PAPERWORK_EMERGENCIES_DEFINITION} from '@clinical/emergencies/constants/paperwork-emergencies.const';
import {PAPERWORK_FORMS_DEFINITION} from '@clinical/forms/constants/paperwork-forms.const';
import {PAPERWORK_HOSPITAL_DEFINITION} from '@clinical/hospital/constants/paperwork-hospital.const';
import {PAPERWORK_INDICATIONS_DEFINITION} from '@clinical/indications/constants/paperwork-indications.const';
import {PAPERWORK_MEDICAL_DISCHARGE_DEFINITION} from '@clinical/medical-discharge/constants/paperwork-medical-discharge.const';
import {PAPERWORK_RECIPES_DEFINITION} from '@clinical/medicines/constants/paperwork-recipes.const';
import {PAPERWORK_ONCOLOGY_DEFINITION} from '@clinical/oncology/constants/paperwork-oncology.const';
import {PAPERWORK_SURGERY_DEFINITION} from '@clinical/surgeries/constants/papework-surgery.const';
import {PAPERWORK_ADMISSION_DEFINITION} from '@clinical/admissions/constants/paperwork-admission.const';
import { PAPERWORK_REPRODUCTIVE_DEFINITION } from '@clinical/reproductive/constants/papework-reproductive.const';

export const PAPERWORKS = [PAPERWORK_FORMS_DEFINITION, PAPERWORK_RECIPES_DEFINITION, PAPERWORK_INDICATIONS_DEFINITION,
                           PAPERWORK_ONCOLOGY_DEFINITION, PAPERWORK_HOSPITAL_DEFINITION,
                           PAPERWORK_EMERGENCIES_DEFINITION, PAPERWORK_SURGERY_DEFINITION,
                           PAPERWORK_DERIVATIONS_DEFINITION, PAPERWORK_MEDICAL_DISCHARGE_DEFINITION,
                           PAPERWORK_EDUCATIONAL_DEFINITION,
                           PAPERWORK_ADMISSION_DEFINITION, PAPERWORK_MEDICAL_DISCHARGE_DEFINITION, PAPERWORK_REPRODUCTIVE_DEFINITION];
