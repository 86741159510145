import {Pipe, PipeTransform} from '@angular/core';
import {Appointment} from '@shared/interfaces/appointment.interface';
import {format} from 'date-fns-tz';
import {ScheduleService} from '@components/agenda/services/agenda.service';

export type EvaluateProperty = 'color' | 'disabled' | 'icon' | 'patientURL';

@Pipe({
  name: 'appointmentAgenda'
})
export class AppointmentAgendaPipe implements PipeTransform {

  currentDatetime = this.scheduleService.getCurrentDatetimeSchedule;

  constructor(public scheduleService: ScheduleService,) {}

  getUrlToLoadPatient(quote: Appointment) {

    const shortDocumentType = quote.patient.documentType ? quote.patient.documentType.toLowerCase().slice(0, 1) : 'n';
    return `/app-pcs/historial-medico/paciente/${shortDocumentType}/${quote?.patient?.documentNumber}/${quote?.id}`;

  }

  isActivated(quote: Appointment): boolean {
    const date = format(new Date(), 'yyyy-MM-dd');
    const currentService = format(this.currentDatetime, 'yyyy-MM-dd');
    const isTelemedicine = this.scheduleService.isTelemedicine(quote.center, quote.service);

    if (currentService !== date) return false;

    if (!quote.pay.id) return false;
    if (isTelemedicine && quote.state === 'Confirmado') if (quote.pay?.amount !== '0') return true;
    if (isTelemedicine && quote.type === 'Appointment')
      if (quote.state === 'Pagado' || quote.state === 'Facturado' || quote.state === 'Recepcionado') return true;
    if ((!isTelemedicine && quote.type === 'Appointment') || quote.type === 'Overcapacity') return true;

    return false;

  }

  transform(value: Appointment, evaluateProperty: EvaluateProperty): any {

    if (evaluateProperty === 'color')
      return this.scheduleService.isTelemedicine(value.center, value?.service) ? 'secondary' : 'primary';

    if (evaluateProperty === 'disabled')
      return !this.isActivated(value);

    if (evaluateProperty === 'icon')
      return this.scheduleService.isTelemedicine(value.center, value?.service) ? 'videocam-outline' : 'notifications-outline';

    if (evaluateProperty === 'patientURL')
      return this.getUrlToLoadPatient(value);

    return null;
  }

}
