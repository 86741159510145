import { Injectable } from '@angular/core';
import { BehaviorSubject, exhaustMap, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { API_URIS } from '@shared/constants/api-uris.const';
import { PCV2Request } from '@shared/interfaces/request.interface';
import { ParamsRequest, VoucherRequest } from '../interfaces/voucher.interface';
import { Detail, Pharmacy } from '../interfaces/buying.interface';
import {
  RequestPharmacyRme,
  RequestPharmacyRmePrescriptions,
  RequestRecipe,
} from '../../pharmacyportal/interfaces/request-pharmacy';
import { ControlFlow } from '../interfaces/pharmacy-control.interface';
import { ActivatedRoute } from '@angular/router';

const BASE_API_PCV2: string = environment.baseApiPCv2;
const URI_CLINICAL_RECIPES: string = API_URIS.clinical.recipes;
const URI_PROXY_TRANSBANK: string = API_URIS.pay.pharmacy.webpay;
const PHARMACY: string = API_URIS.pharmacy.pharmacy;

@Injectable({
  providedIn: 'root',
})
export class QuotationService {
  public quotationPharmacy$ = new BehaviorSubject<Pharmacy[]>(null);
  public quotationPharmacy = this.quotationPharmacy$.asObservable();

  public prescriptions$ = new BehaviorSubject<RequestPharmacyRmePrescriptions[]>(null);
  public prescriptions = this.prescriptions$.asObservable();

  public products$ = new BehaviorSubject<Detail[]>(null);
  public products = this.products$.asObservable();

  public productsFitlered$ = new BehaviorSubject<Detail[]>(null);
  public recipe$ = new BehaviorSubject<RequestPharmacyRme>(null);

  public flow$ = new BehaviorSubject<ControlFlow>({ flow: 'portal', isLoading: false });
  public flow = this.flow$.asObservable();
  freeSale = { ['RR']: false, ['VD']: true, ['RM']: true };
  queryParams: any = null;
  constructor(private http: HttpClient, private route: ActivatedRoute) {}

  getQueryParams() {
    return this.route.queryParams.pipe(map((queryP) => (this.queryParams = queryP))).subscribe();
  }

  getQuotationByRmeId(body: any): Observable<Pharmacy[]> {
    const url = `${BASE_API_PCV2}/proxy/v1/yaap?sufixURL=client/quotation`;
    return this.http.post<PCV2Request>(url, body).pipe(map((data: PCV2Request) => this.filterPharmacy(data)));
  }

  filterPharmacy(request: PCV2Request): Pharmacy[] {
    const { data, metadata, state } = request;
    const prscrp = [];
    if (metadata.statusCode === 200) {
      const newData = data.data
        .map((pharm: Pharmacy) => {
          if (pharm.delivery.cost !== null) {
            this.prescriptions$.value.forEach((prescript) =>
              pharm.quotation_detail
                .map((detail) => {
                  if (detail.product.formula_id === prescript.medicine.id) {
                    if (!prscrp.includes(prescript)) prscrp.push(prescript);
                    detail.sold = prescript.sold;
                    detail.freeSale = prescript.freeSale;
                    detail.quantity = 0;
                    detail.total = 0;
                    return detail;
                  }
                })
                .filter((ind) => ind !== undefined),
            );
            pharm.total = String(0);
            pharm.sell = String(0);
            return pharm;
          }
          return;
        })
        .filter((ind) => ind !== undefined);
      this.prescriptions$.next(prscrp);
      this.quotationPharmacy$.next(newData);
      return newData;
    } else
      this.flow$.next({
        flow: 'noPharmacy',
        isLoading: false,
      });
  }

  getPharmByRmeId(allData): Observable<RequestRecipe> {
    const url = `${BASE_API_PCV2}/proxy/v1/yaap?sufixURL=client/quotation`;
    return this.http.post<PCV2Request>(url, allData).pipe(
      map(({ data }: { data: RequestRecipe }) => data),
    );
  }

  getRme(idRme: string): Observable<PCV2Request> {
    return this.http
      .get<PCV2Request>(`${BASE_API_PCV2}/${URI_CLINICAL_RECIPES}/${PHARMACY}/${idRme}`)
      .pipe(map((resp: PCV2Request) => this.formatRequestPcv2(resp)));
  }

  formatRequestPcv2(resp: PCV2Request): PCV2Request {
    if (resp.data !== 'no existen recetas' && resp.data.rme) {
      resp.data.rme.pcv2 = true;
      resp.data.rme.tipo = 'normal';
      resp.data.rme.content.forEach((ind) => {
        ind.medicine.checked = false;
        ind.freeSale = this.freeSale[ind.medicine.salesTermRecept];
        if (!ind.freeSale) resp.data.rme.tipo = 'retenida';

        const obj = resp.data.vendido.find((v) => v.indications[0].id === ind.medicine.id);

        ind.sold = obj !== undefined ? true : false;
      });
      this.recipe$.next(resp.data);
      return resp.data;
    }
    if (resp.data === 'no existen recetas')
      this.flow$.next({
        flow: 'noRecipe',
        isLoading: false,
      });
    return resp;
  }

  //TODO: Cambiar

  purchase(out): Observable<any> {
    if (this.queryParams !== undefined) out.portal = this.queryParams.portal;
    return this.http
      .post<PCV2Request>(`${BASE_API_PCV2}/${URI_PROXY_TRANSBANK}`, out)
      .pipe(map((resp: PCV2Request) => this.redirectTransbank(resp)));
  }

  redirectTransbank(resp: PCV2Request) {
    window.location.href = resp.data.url;
    //TODO: cambiar estado de medicamentos a vendidos y statusBuy
  }

  getComprobante(params: ParamsRequest): Observable<VoucherRequest> {
    const { id, idComp } = params;
    return this.http
      .get<VoucherRequest>(`https://8ez0p8ryh6.execute-api.us-east-1.amazonaws.com/prd/farmacia/oc/${id}/${idComp}`)
      .pipe(map((res: VoucherRequest) => res));
  }

  setIdRme(idRme: string, frm) {
    this.recipe$
      .pipe(
        exhaustMap((_) => this.getRme(idRme)),
        map((request: any): RequestRecipe => {
          if (request && request.rme && request.buyStatus !== 'ConCompraCompleta') {
            this.flow$.next({ flow: 'address', isLoading: false });
            this.prescriptions$.next(request.rme.content);
            frm.patchValue({ recipe: request });
            return request;
          }
          if (request && request.data === 'no existen recetas') return;
          if (request.buyStatus === 'ConCompraCompleta' && request.rme.tipo === 'retenida')
            this.flow$.next({
              flow: 'fullBuy.retenida',
              isLoading: false,
            });
          if (request.buyStatus === 'ConCompraCompleta') this.flow$.next({ flow: 'fullBuy', isLoading: false });
        }),
      )
      .subscribe();
  }
}
