import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';
import { Paperwork } from '@shared/interfaces/paperwork.interface';
import { ParamsToFindByPatient } from '@components/patient/interfaces/patient.interface';

/* Firma de Documentos Electronicos */
export const PUT_FORM = createAction('[formularios] PUT Formulario', props<{ valuesToSave: Paperwork }>());
export const PUT_FORM_OK = createAction('[formularios] OK PUT Formulario', props<{ form: any }>());
export const PUT_FORM_ERR = createAction('[formularios] ERR PUT Formulario', props<{ payload: HttpErrorResponse }>());
export const PUT_FORM_END = createAction('[formularios] Resetea el estado para crear una nueva solicitud');

export const LOAD_FORMS_BY_PROFESSIONAL = createAction(
  '[formularios] Cargar formularios por profesional',
  props<{ gsiMultiCondition1St: string }>(),
);
export const LOAD_FORMS_BY_PROFESSIONAL_OK = createAction(
  '[formularios] OK cargar formularios por profesional',
  props<{ forms: Paperwork[] }>(),
);
export const LOAD_FORMS_BY_PROFESSIONAL_ERR = createAction(
  '[formularios] ERR cargar formularios por profesional',
  props<{ payload: HttpErrorResponse }>(),
);

export const LOAD_FORMS_BY_PATIENT = createAction(
  '[formularios] Cargar formularios por paciente',
  props<{ params: ParamsToFindByPatient }>(),
);
export const LOAD_FORMS_BY_PATIENT_OK = createAction(
  '[formularios] OK cargar formularios por paciente',
  props<{ forms: Paperwork[] }>(),
);
export const LOAD_FORMS_BY_PATIENT_ERR = createAction(
  '[formularios] ERR cargar formularios por paciente',
  props<{ payload: HttpErrorResponse }>(),
);

export const DELETE_FORM = createAction('[formularios] DELETE Formulario', props<{ form: Paperwork }>());
export const DELETE_FORM_OK = createAction('[formularios] OK DELETE Formulario', props<{ form: Paperwork }>());
export const DELETE_FORM_ERR = createAction(
  '[formularios] ERR DELETE Formulario',
  props<{ payload: HttpErrorResponse }>(),
);

export const SELECTED_FORM = createAction(
  '[formularios] SELECTED Formulario',
  props<{ selected: Paperwork; action: string }>(),
);
export const UNSELECT_FORM = createAction('[formularios] UNSELECT Formulario');
export const CLEAR_FORM = createAction('[formularios] CLEAR Formulario');
