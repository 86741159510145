import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LOAD_AGENDA, LOAD_AGENDA_OK, LOAD_AGENDA_ERR } from './agenda.actions';

import { of } from 'rxjs';
import { map, catchError, retry, switchMap } from 'rxjs/operators';

import { ScheduleService } from '@components/agenda/services/agenda.service';

@Injectable()
export class AgendaEffects {
  loadAgenda$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_AGENDA),
      switchMap(({ params }) =>
        this.scheduleService.getSchedule(params).pipe(
          retry({count: 10, delay: 5000}),
          map((appointments) => LOAD_AGENDA_OK({ appointments })),
          catchError((err) => of(LOAD_AGENDA_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private scheduleService: ScheduleService) {}
}
