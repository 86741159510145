import {ActionReducerMap} from '@ngrx/store';
import * as reducers from './reducers';

export interface AppState {
  ui: reducers.UiState;
  forms: reducers.FormState;
  docSign: reducers.SignState;
  patient: reducers.PatientState;
  recipes: reducers.RecipesState;
  storage: reducers.StorageState;
  schedule: reducers.ScheduleState;
  professional: reducers.AuthState;
  surgeries: reducers.SurgeryState;
  delivery: reducers.DeliveryState;
  agenda: reducers.AppointmentState;
  favorites: reducers.FavoriteState;
  paperwork: reducers.PaperworkState;
  anamnesis: reducers.AnamnesisState;
  emergencies: reducers.EmergencyState;
  derivations: reducers.DerivationState;
  indications: reducers.IndicationState;
  reproductive: reducers.ReproductiveState;
  hospitalizations: reducers.HospitalizationState;
  admissions: reducers.AdmissionState;
  notification: reducers.NotificationState;
  expertise: reducers.ExpertiseState;
  oncology: reducers.OncologyState;
  educational: reducers.EducationalState;
  discharge: reducers.DischargeState;
}

export const appReducers: ActionReducerMap<AppState> = {
  ui          : reducers.uiReducer,
  professional: reducers.authReducer,
  patient     : reducers.patientReducer,
  schedule    : reducers.scheduleReducer,
  paperwork   : reducers.paperworkReducer,

  forms           : reducers.formReducer,
  surgeries       : reducers.surgeryReducer,
  recipes         : reducers.recipeReducer,
  anamnesis       : reducers.anamnesisReducer,
  indications     : reducers.indicationReducer,
  derivations     : reducers.derivationReducer,
  reproductive    : reducers.reproductiveReducer,
  emergencies     : reducers.urgencyReducer,
  hospitalizations: reducers.hospitalizationReducer,
  favorites       : reducers.favoriteReducer,
  expertise       : reducers.expertiseReducer,
  admissions      : reducers.admissionReducer,
  oncology        : reducers.oncologyReducer,
  educational     : reducers.educationalReducer,
  discharge       : reducers.dischargeReducer,

  docSign     : reducers.singReducer,
  storage     : reducers.storageReducer,
  notification: reducers.notificationReducer,
  agenda      : reducers.appointmentReducer,
  delivery    : reducers.deliveryReducer,
};
