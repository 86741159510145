import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NotificationService} from '@shared/services/notification.service';

import {of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import * as notificationActions from '../actions';

@Injectable()
export class NotificationEffects {
  sendEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationActions.SEND_EMAIL),
      mergeMap(({paramsToSendMail}) =>
        this.notificationService.sendEmail({...paramsToSendMail}).pipe(
          map((metadata) => notificationActions.SEND_EMAIL_OK({metadata})),
          catchError((err) => of(notificationActions.SEND_EMAIL_ERR({payload: err}))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private notificationService: NotificationService) {
  }
}
