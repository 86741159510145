import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';

import {API_URIS} from '@shared/constants/api-uris.const';
import {PCV2Request} from '@shared/interfaces/request.interface';

import {Observable} from 'rxjs';
import {map, retry} from 'rxjs/operators';
import {Admission} from '../interfaces/admission.interface';

const BASE_API_PCV2: string                = environment.baseApiPCv2;
const URI_CLINICAL_HOSPITALIZATION: string = API_URIS.clinical.hospitalization;

@Injectable({
  providedIn: 'root',
})
export class AdmissionService {
  constructor(private httpClient: HttpClient) {
  }


  saveAdmission(body: Admission): Observable<Admission> {
    return this.httpClient.post<PCV2Request>(`${BASE_API_PCV2}/${URI_CLINICAL_HOSPITALIZATION}`, body).pipe(
        retry(3),
        map(({data}: { data: Admission }) => data),
    );
  }
}
