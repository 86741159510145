import {Injectable} from '@angular/core';

import {DerivationService} from '@clinical/derivations/services/derivation.service';
import {ReportService} from '@components/records/services/report.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  DELETE_DERIVATION,
  DELETE_DERIVATION_ERR,
  DELETE_DERIVATION_OK,
  LOAD_DERIVATIONS_BY_PATIENT,
  LOAD_DERIVATIONS_BY_PATIENT_ERR,
  LOAD_DERIVATIONS_BY_PATIENT_OK,
  LOAD_DERIVATIONS_BY_PROFESSIONAL,
  LOAD_DERIVATIONS_BY_PROFESSIONAL_ERR,
  LOAD_DERIVATIONS_BY_PROFESSIONAL_OK,
  PUT_DERIVATION,
  PUT_DERIVATION_END,
  PUT_DERIVATION_ERR,
  PUT_DERIVATION_OK,
} from '@store/actions';

import {of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';

@Injectable()
export class DerivationEffects {
  putDerivation$                 = createEffect(() =>
      this.actions$.pipe(
          ofType(PUT_DERIVATION),
          mergeMap(({valuesToSave}) =>
              this.clinicalDerivationService.save({...valuesToSave}).pipe(
                  map((derivation) => PUT_DERIVATION_OK({derivation})),
                  catchError((err) => of(PUT_DERIVATION_ERR({payload: err}))),
              ),
          ),
      ),
  );

  clear$ = createEffect(() => this.actions$.pipe(ofType(PUT_DERIVATION_OK), map(() => PUT_DERIVATION_END())));


  loadDerivationsByPatient$      = createEffect(() =>
      this.actions$.pipe(
          ofType(LOAD_DERIVATIONS_BY_PATIENT),
          mergeMap(({params}) =>
              this.reportService.findByType(params.patientId, params.type).pipe(
                  map((derivation) => LOAD_DERIVATIONS_BY_PATIENT_OK({derivations: derivation})),
                  catchError((err) => of(LOAD_DERIVATIONS_BY_PATIENT_ERR({payload: err}))),
              ),
          ),
      ),
  );
  loadDerivationsByProfessional$ = createEffect(() =>
      this.actions$.pipe(
          ofType(LOAD_DERIVATIONS_BY_PROFESSIONAL),
          mergeMap(({gsiMultiCondition1St}) =>
              this.reportService.findByGsi(gsiMultiCondition1St).pipe(
                  map((derivation) => LOAD_DERIVATIONS_BY_PROFESSIONAL_OK({derivations: derivation})),
                  catchError((err) => of(LOAD_DERIVATIONS_BY_PROFESSIONAL_ERR({payload: err}))),
              ),
          ),
      ),
  );
  deleteDerivations$             = createEffect(() =>
      this.actions$.pipe(
          ofType(DELETE_DERIVATION),
          mergeMap((derivation) =>
              this.reportService.deleteByIds('derivaciones', derivation).pipe(
                  map((derivationsItem) => DELETE_DERIVATION_OK({derivation: derivationsItem})),
                  catchError((err) => of(DELETE_DERIVATION_ERR({payload: err}))),
              ),
          ),
      ),
  );

  constructor(
      private actions$: Actions,
      private clinicalDerivationService: DerivationService,
      private reportService: ReportService) {
  }
}
