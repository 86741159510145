import {Observable}  from 'rxjs';
import {Injectable}  from '@angular/core';
import {tap}         from 'rxjs/operators';
import {AuthService} from 'src/app/auth/services/auth.service';

import {CanActivate, CanActivateChild, CanLoad, Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private router: Router, private authService: AuthService) {}

  canLoad(): Observable<boolean> {
    return this.isStartSession();
  }

  canActivate(): Observable<boolean> {
    return this.isStartSession();
  }

  canActivateChild(): Observable<boolean> {
    return this.isStartSession();
  }

  isStartSession(): Observable<boolean> {
    return this.authService.isLogged().pipe(
      tap((isLogged) => { if (!isLogged) this.authService.logout(); })
    );
  }
}
