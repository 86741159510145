import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Doc} from '@clinical/educational/interfaces/educational.interface';
import {environment} from '@environments/environment';
import {API_URIS} from '@shared/constants/api-uris.const';
import {Paperwork} from '@shared/interfaces/paperwork.interface';
import {PCV2Request} from '@shared/interfaces/request.interface';
import {Observable} from 'rxjs';
import {map, retry} from 'rxjs/operators';

const BASE_API_PCV2: string          = environment.baseApiPCv2;
const URI_CLINICAL_DOCUMENT: string  = API_URIS.maintainer.document;
const URI_CLINICAL_DOCUMENTS: string = API_URIS.clinical.documents;

@Injectable({
  providedIn: 'root',
})
export class ClinicalEducationalService {
  constructor(private httpClient: HttpClient) {
  }

  savexxx(body: Paperwork<Doc>): Observable<Paperwork<Doc>> {
    return this.httpClient.post<PCV2Request>(`${BASE_API_PCV2}/${URI_CLINICAL_DOCUMENTS}`, body).pipe(
        retry(3),
        map(({data}: { data: Paperwork<Doc> }) => data),
    );
  }

  getEducativesMaterials(): Observable<any> {
    return this.httpClient
        .get<PCV2Request>(`${BASE_API_PCV2}/${URI_CLINICAL_DOCUMENT}/educativos`)
        .pipe(map(this.formatEducativeMaterials));
  }

  formatEducativeMaterials(request: PCV2Request) {
    const {metadata, data} = request;
    if (metadata.statusCode === 200) return data.categories.map((category) => {
      return {
        ...category, folders: category.folders.map((folder) => {
          return {
            ...folder, docs: folder.docs.map((doc) => {
              return {
                ...doc, isChecked: false,
              };
            }),
          };
        }),
      };
    });
    if (metadata.statusCode !== 200) return;
  }
}
