import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ProfessionalPRM, ProfessionalRef} from '@auth/interfaces/professional.interface';

import {environment} from '@environments/environment';

import {API_URIS} from '@shared/constants/api-uris.const';
import {REGEX_CLEAN_RUT} from '@shared/constants/regex.const';
import {PCV2Request} from '@shared/interfaces/request.interface';
import {Observable, of} from 'rxjs';
import {catchError, map, pluck, retry, switchMap, tap} from 'rxjs/operators';
import {Beneficiary, GPResponseIH, IntegralHealth} from '../interfaces/family-doctor.interface';
import {cleanString} from '@shared/helpers/clean-string.helper';

const BASE_API_IHEALT: string = environment.baseApiIntegralHealth;
const BASE_API_PCV2: string = environment.baseApiPCv2;
const URI_PROXY_PRM: string = API_URIS.proxy.prm;

@Injectable({
  providedIn: 'root',
})
export class FamilyDoctorService {
  constructor(private httpClient: HttpClient) {}

  getProfessional(rut: string): Observable<ProfessionalRef> {
    if (!rut) return of();
    rut = cleanString(rut).replace(/-/gi, '');
    const queryParams = encodeURIComponent(`Physicians?&$filter=DocumentNumber eq '${rut}'`);
    return this.httpClient
               .get<PCV2Request>(`${BASE_API_PCV2}/${URI_PROXY_PRM}${queryParams}`)
               .pipe(pluck('data'), map(this.extractProfessionalRefInfo));
  }

  extractProfessionalRefInfo(data: ProfessionalPRM[]): ProfessionalRef {
    const [professional] = data;

    if (!professional) return null;
    const lastName1 = professional.LastName1 || '';
    const lastName2 = professional.LastName2 || '';

    return {
      id      : professional.ResourceId,
      rut     : professional.DocumentNumber,
      names   : professional.FirstName1 || '' + ' ' + professional.FirstName2 || '',
      surnames: `${lastName1} ${lastName2}`,
      email   : professional.Email,
    };
  }

  getPatientIH(rut: string): Observable<IntegralHealth> {
    rut = rut.toUpperCase();
    const endpoint = `${BASE_API_IHEALT}/serviciomarcapaciente/validarenrolamientoV2?rut=${rut}&f=1`;
    return this.httpClient.get<GPResponseIH>(endpoint).pipe(retry(3), map(this.formatIntegralHealt));
  }

  formatIntegralHealt(gpResponse: GPResponseIH): IntegralHealth {
    const {beneficiario} = gpResponse;
    if (!beneficiario) return;

    const beneficiary = beneficiario.map((item) => ({
      patientId       : item.idPaciente,
      name            : item.nombrebeneficiario,
      professionalGpId: item.idMedicoCabecera,
      birth           : item.FechaNac,
      age             : item.edad,
      plan            : {
        id  : item.plan,
        name: environment.integralHealthPlansPatient.find(element => element?.id === item?.plan)?.name,
      },
      poliza          : item.poliza,
      derivation      : item.derivacion,
    }));

    return {
      state : gpResponse.estado,
      detail: gpResponse.detalle,
      beneficiary,
    };
  }
}
