import {patientTopCard} from '@shared/helpers/pdf-patient-card.helper';

export const buildContentPdfTac = (paperwork, group) => {
  const {rows}      = group;
  const tacBuildPdf = rows.map((item) => {
    const {anesthesia, hasContrast, allergy, observation,is4D} = item;


    let ANESTHESIA      = [];
    let HASCONTRAST     = [];
    let ALLERGY         = [];
    let OBSERVATION     = [];
    let FOUR_D          = [];

   
      HASCONTRAST = [
        {fontSize: 12, text: ` Contraste: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${hasContrast ? 'Si' : 'No'}`,
          bold      : true,
        },
      ];

      ANESTHESIA = [
        {fontSize: 12, text: ` Anestesia: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${anesthesia ? 'Si' : 'No'}`,
          bold      : true,
        },
      ];

      ALLERGY = [
        {fontSize: 12, text: ` Alergia: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${allergy ? 'Si' : 'No'}`,
          bold      : true,
        },
      ];

      FOUR_D = [
        {fontSize: 12, text: ` 4D: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${is4D ? 'Si' : 'No'}`,
          bold      : true,
        },
      ];
    if (observation)
      OBSERVATION     = [
        {fontSize: 12, text: ` Observaciones: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${observation}`,
          bold      : true,
        },
      ];
    const note        = [
      {
        fontSize: 12,
        margin  : [0, 40, 0, 0],
        text    : [
          {text: `NOTA: `, bold: true},
          {text: `Recuerde que para el dia de la simulación usted debe traer encuesta `},
          {text: `y orden medica examen, recuerde solicitar su presupuesto.`},
        ],
      },
    ];
    const TAC_CONTENT = {
      fontSize : 15,
      alignment: 'justify',
      stack    : [
        HASCONTRAST,
        ANESTHESIA,
        ALLERGY,
        FOUR_D,
        OBSERVATION,
      ],
    };
    return TAC_CONTENT;

  });
  return [...patientTopCard(paperwork), tacBuildPdf];

};
