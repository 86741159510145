import {AuthEffects} from '@auth/store/auth.effects';
import {AdmissionEffects} from '@clinical/admissions/store/clinical-admission.effects';
import {DerivationEffects} from '@clinical/derivations/store/clinical-derivation.effects';
import {EducationalEffects} from '@clinical/educational/store/clinical-educational.effects';
import {EmergenciesEffects} from '@clinical/emergencies/store/clinical-emergencies.effects';
import {ExpertiseEffects} from '@clinical/expertise/store/clinical-expertise.effects';
import {FormEffects} from '@clinical/forms/store/clinical-form.effects';
import {HospitalizationEffects} from '@clinical/hospital/store/clinical-hospitalization.effects';
import {IndicationEffects} from '@clinical/indications/store/indication.effects';
import { DischargeEffects } from '@clinical/medical-discharge/store/clinical-discharge.effects';
import {RecipeEffects} from '@clinical/medicines/store/clinical-recipe.effects';
import {OncologyEffects} from '@clinical/oncology/store/clinical-oncology.effects';
import {ReproductiveEffects} from '@clinical/reproductive/store/reproductive.effects';
import {ScheduleEffects} from '@clinical/schedule/store/schedule.effects';

import {SurgeryEffects} from '@clinical/surgeries/store/surgery.effects';
import {AgendaEffects} from '@components/agenda/store/agenda.effects';
import {AnamnesisEffects} from '@components/anamnesis/store/anamnesis.effects';
import {FavotireEffects} from '@components/favorites/store/favorites.effects';
import {PatientEffects} from '@components/patient/store/patient.effects';
import {DeliveryEffects} from 'src/app/pharmacyportal/store/recipe-pharm.effects';
import {NotificationEffects} from './notification.effects';
import {PaperWorkEffects} from './paperwork.effects';

import {DocSignEffects} from './sign.effects';
import {StorageEffects} from './storage.effects';

export const appEffects: any[] = [
  AuthEffects,
  FormEffects,
  RecipeEffects,
  AgendaEffects,
  SurgeryEffects,
  AnamnesisEffects,
  IndicationEffects,
  DerivationEffects,
  ReproductiveEffects,
  EmergenciesEffects,
  HospitalizationEffects,
  AdmissionEffects,
  FavotireEffects,
  ScheduleEffects,
  PatientEffects,
  DocSignEffects,
  StorageEffects,
  NotificationEffects,
  ExpertiseEffects,
  DeliveryEffects,
  OncologyEffects,
  PaperWorkEffects,
  EducationalEffects,
  DischargeEffects,
];
