import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Pdf} from '@shared/helpers/pdf-wrapper.helper';

import {ModalController} from '@ionic/angular';
import {PatientReportComponent} from '../patient-report/patient-report.component';
import {ReportService} from '../services/report.service';
import {PageTableDef} from '@shared/interfaces/table.interface';

@Component({
  selector   : 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls  : ['./report-modal.component.scss'],
})
export class ReportModalComponent implements OnInit {
  @Input() pageTable: PageTableDef;
  @Input() data;
  @Input() hideButtons;
  @Input() index;

  pdfDecode: string;
  currentGroup: string;
  isLoading = true;
  titles: string[];

  constructor(
    private reportService: ReportService,
    private modalController: ModalController,
    public router: Router,
    private pdf: Pdf,
  ) {
  }

  ngOnInit(): void {
    // Verifica si this.pageTable.array está presente y es un array
if (Array.isArray(this.data[this.pageTable.array])) {
  const result1 = this.data[this.pageTable.array].map((exam) => exam.group);
  this.titles = result1
  this.titles = this.titles.filter((item, pos) => this.titles.indexOf(item) === pos);
  this.viewPdf(this.index ? this.index : 0, 'dau');
}

// Verifica si this.data.content está presente y es un array
if (this.data.content && Array.isArray(this.data.content)) {
  const result2 = this.data.content.map((exam) => exam.group);
  this.titles = result2
  this.titles = this.titles.filter((item, pos) => this.titles.indexOf(item) === pos);
  this.viewPdf(this.index ? this.index : 0,  'content');
}

  }

  viewPdf(index: number, content) {
    if (!this.data.attachments) return;
    const {bucket, path, key} = this.data.attachments[index];
    if (content !== 'content') this.currentGroup = this.data[this.pageTable.array][0].group;
    if (content === 'content') this.currentGroup = this.data.content[0].group;
    this.reportService.getPdf(bucket, path, key).subscribe((res) => {
      const pdfEncode = `data:application/pdf;base64,${res}`;
      this.pdf.visualize(pdfEncode);
      this.isLoading = false;
    });
  }

  async pdfPreview(group: any) {
    this.isLoading = true;
    const index = this.titles.findIndex((exam) => exam === group);
    this.viewPdf(index, 'content');
  }

  async viewPatient() {
    this.dismiss();
    delete this.pageTable.title;
    const modal = await this.modalController.create({
      component     : PatientReportComponent,
      cssClass      : 'cmodal-xl',
      componentProps: {data: this.data, pageTable: this.pageTable},
    });
    await modal.present();
  }

  routeCreateNew() {
    this.router.navigate([`${this.pageTable.router}`]);
    this.modalController.dismiss();
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
