import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';
import { ParamsToFindByPatient } from '@components/patient/interfaces/patient.interface';
import { BasePCV2 } from '@shared/interfaces/base.interface';
import { Paperwork } from '@shared/interfaces/paperwork.interface';

export const PUT_ONCOLOGY_END = createAction('[oncología] Resetea el estado para crear una nueva solicitud');

export const PUT_ONCOLOGY = createAction('[oncología] PUT Oncología', props<{ valuesToSave: Paperwork }>());
export const PUT_ONCOLOGY_OK = createAction('[oncología] OK PUT Oncología', props<{ oncology: Paperwork }>());
export const PUT_ONCOLOGY_ERR = createAction('[oncología] ERR PUT Oncología', props<{ payload: HttpErrorResponse }>());

export const LOAD_ONCOLOGY_BY_PROFESSIONAL = createAction(
  '[oncología] Cargar oncología por profesional',
  props<{ gsiMultiCondition1St: string }>(),
);
export const LOAD_ONCOLOGY_BY_PROFESSIONAL_OK = createAction(
  '[oncología] OK cargar oncología por profesional',
  props<{ oncology: Paperwork[] }>(),
);
export const LOAD_ONCOLOGY_BY_PROFESSIONAL_ERR = createAction(
  '[oncología] ERR cargar oncología por profesional',
  props<{ payload: HttpErrorResponse }>(),
);

export const LOAD_ONCOLOGY_BY_PATIENT = createAction(
  '[oncología] Cargar oncología por paciente',
  props<{ params: ParamsToFindByPatient }>(),
);
export const LOAD_ONCOLOGY_BY_PATIENT_OK = createAction(
  '[oncología] OK cargar oncología por paciente',
  props<{ oncology: Paperwork[] }>(),
);
export const LOAD_ONCOLOGY_BY_PATIENT_ERR = createAction(
  '[oncología] ERR cargar oncología por paciente',
  props<{ payload: HttpErrorResponse }>(),
);

export const DELETE_ONCOLOGY = createAction('[oncología] DELETE Oncología', props<{ oncology: Paperwork<BasePCV2> }>());
export const DELETE_ONCOLOGY_OK = createAction('[oncología] OK DELETE Oncología', props<{ oncology: Paperwork }>());
export const DELETE_ONCOLOGY_ERR = createAction(
  '[oncología] ERR DELETE Oncología',
  props<{ payload: HttpErrorResponse }>(),
);

export const SELECTED_ONCOLOGY = createAction(
  '[oncología] SELECTED Oncología',
  props<{ selected: Paperwork; action: string }>(),
);
export const UNSELECT_ONCOLOGY = createAction('[oncología] UNSELECT Oncología');
export const CLEAR_ONCOLOGY = createAction('[oncología] CLEAR Oncología');
