import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PCV2Request } from '@shared/interfaces/request.interface';
import { API_URIS } from '@shared/constants/api-uris.const';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const BASE_API_PCV2: string = environment.baseApiPCv2;
const uriConfig: string = API_URIS.maintainer.config;

@Injectable({
  providedIn: 'root',
})
export class MaintainerService {
  constructor(private httpClient: HttpClient) {
  }

  getConfigByModule(sk: string): Observable<any> {
    const endpoint = `${BASE_API_PCV2}/${uriConfig}/${sk}`;
    return this.httpClient
      .get<PCV2Request>(endpoint)
      .pipe(map(({ data }: { data: any }) => data));
  }
}
