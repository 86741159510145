/* eslint-disable max-len */
import {patientTopCard} from '@shared/helpers/pdf-patient-card.helper';

export const buildContentPdfLightRegime = (paperwork, group) => {
  const {rows} = group;
  return rows.map((itemForm) => [
    ...patientTopCard(paperwork),

    {
      text    : `Estimado/a paciente: En beneficio de su salud y pronta recuperación, a usted se le ha indicadoun régimen liviano, en el que se hace una restricción y selección de las grasas y se eliminan todos aquellos alimentos meteorizantes y/o irritantes, que puedan producir molestias gástricas, tales como distensión abdominal o irritación de la mucosa digestiva.`,
      bold    : false,
      fontSize: 12,
      alignment: 'justify',
      margin: [0, 0, 0, 0],
    },

    {text: `ALIMENTOS PERMITIDOS:`, bold: true, fontSize: 14, margin: [0, 10, 0, 5]},
    {text: `Lácteos y productos lácteos con o sin Lactosa:`, bold: true, fontSize: 12, margin: [0, 0, 0, 5]},
    {
      ul: [
        'Leche descremada o semidescremada líquida o en postres (maicena, sémola, avena o flan).',
        'Bebidas vegetales (leche de soya,de avena, de almendras o arroz).',
        'Yogurt: descremado con o sin azúcar.',
        'Quesos desgrasados (quesillo, queso fresco, ricota).',
      ],
    },
    {text: `Huevos (Solo la clara; en sopas, guisos o postres).`, bold: false, fontSize: 12, margin: [0, 5, 0, 5]},
    {
      text    : `Carnes, derivados y proteína de origen vegetal: cocidos al vapor, al horno, a la cacerola, a la plancha, asados, en guisos o budines. `,
      bold    : false,
      fontSize: 12,
      margin  : [0, 5, 0, 5],
    },
    {
      ul: [
        'Vacuno desgrasado o magro (lomo liso, punta picana, punta ganso, abastero, huachalomo, posta paleta, filete, posta rosada o negra).',
        'Ave o pavo: cocinados sin piel.',
        'Pescados magros (merluza, reineta, corvina, tilapia, congrio, lenguado),',
        'Carne de cerdo extra magra (filete, lomo de centro, pulpa de pierna, posta rosada, posta negra).',
        'Jamón cocido de ave o pavo.',
        'Proteínas de origen vegetal: Tofu, Carne vegetal de soya, Tempeh.',
      ],
    },
    {
      pageBreak: 'before',
      text    : `Verduras (de preferencia cocida o al vapor): acelgas, apio, betarragas, espárragos, espinacas, porotos verdes, zanahorias, zapallitos italianos, zapallo camote, apio y tomates sin pepas. `,
      bold    : false,
      fontSize: 12,
      margin  : [0, 0, 0, 5],
    },
    {
      text    : `Papas: cocidas, en guisos, ensaladas, en puré o al horno.`,
      bold    : false,
      fontSize: 12,
      margin  : [0, 5, 0, 5],
    },
    {
      text    : `Frutas Crudas, cocidas o en conserva: Ciruelas cocidas, manzanas sin cáscara, duraznos y damascos cocidos o en conserva, pera cocida, plátano sin semillas, membrillos cocidos.`,
      bold    : false,
      fontSize: 12,
      margin  : [0, 5, 0, 5],
    },
    {
      text    : `Cereales y farináceos: Arroz, fideos, cous cous, quínoa, sémola, maicena, avena, chuño.`,
      bold    : false,
      fontSize: 12,
      margin  : [0, 5, 0, 5],
    },
    {
      text    : `Leguminosas: porotos, lentejas, garbanzos, arvejas, SIN hollejo o pasados por cedazo, en sopas, cremas o purés, siempre que sean bien tolerados.`,
      bold    : false,
      fontSize: 12,
      margin  : [0, 5, 0, 5],
    },
    {
      text    : `Pan: marraqueta, rosita o baguette, pan de molde blanco o tostado, pan pita blanco.`,
      bold    : false,
      fontSize: 12,
      margin  : [0, 5, 0, 5],
    },
    {
      text    : `Galletas: De soda, agua, chuño, maicena o las dulces pero sin crema (de limón, vino, coco, por ejemplo).`,
      bold    : false,
      fontSize: 12,
      margin  : [0, 5, 0, 5],
    },
    {
      text    : `Dulces: azúcar o endulzante, Mermelada (sin pepas), miel y dulce de membrillo, jaleas, helados de agua.`,
      bold    : false,
      fontSize: 12,
      margin  : [0, 5, 0, 5],
    },
    {
      text    : `Aceite: en crudo y adicionado a las preparaciones al momento de servir.`,
      bold    : false,
      fontSize: 12,
      margin  : [0, 5, 0, 5],
    },
    {
      text    : `Condimentos: sal, limón, perejil, romero, tomillo, cilantro, vinagre, orégano y laurel.`,
      bold    : false,
      fontSize: 12,
      margin  : [0, 5, 0, 5],
    },
    {
      text    : `Hidratación: Té, agua de hierbas, café descafeinado; Agua mineral sin gas o purificada, agua de cocción de fruta, limonada, jugos néctar.`,
      bold    : false,
      fontSize: 12,
      margin  : [0, 5, 0, 5],
    },

    {text: `EVITAR CONSUMO:`, bold: true, fontSize: 14,pageBreak: 'before', margin: [0, 0, 0, 10]},
    {
      alignment: 'justify',
      ul: [
        'Leche entera, crema de leche, helados con crema y productos de pastelería.',
        'Mantequilla, manteca y margarina.',
        'Quesos maduros (chanco, parmesano, gouda, mantecoso).',
        'Yema de huevo.',
        'Carnes altas en grasas: cordero, pato, cerdo (tocino, panceta, matambre y bandiola), cortes altos en grasa de vacuno o ternera, vísceras, embutidos y fiambres.',
        'Verduras meteorizantes: repollo, rabanitos, coles de bruselas, coliflor, cebolla, ají, pimentón, choclo, pepinos, ajos, brócoli, puerros, etc.',
        'Frutas meteorizantes: sandía, melón, pepinos, tunas, pera cruda, naranja, kiwi.',
        'Almendras, maní, nueces, avellanas, pistachos, aceitunas y paltas.',
        'Condimentos (ají, pimienta, comino, ajo y aliños “picantes”) y salsas preparadas (mayonesa, mostaza, kétchup y salsas preparadas con crema).',
        'Pan o masas preparadas con manteca como hallullas, dobladitas o pan amasado',
        'Bebidas excitantes: alcohólicas, café, yerba mate, cacao, chocolate, milo.',
        'Frituras en general.',
      ],
    },
    {text: `RECOMENDACIONES GENERALES:`, bold: true, fontSize: 14, margin: [0, 15, 0, 5]},
    {
      ul: [
        'Este régimen debe seguirlo por el tiempo indicado por su médico o Nutricionista.',
        'Cocine los alimentos en agua, al vapor, al horno, a la plancha, NUNCA FRITOS.',
        'Debe usar el aceite crudo, agregado al momento de servir.',
      ],
    },

    {text: `OBSERVACIONES:`, bold: true, fontSize: 14, margin: [0, 15, 0, 5]},
    {text: `${itemForm.observations}`, bold: false, fontSize: 12, margin: [0, 0, 0, 0]},

    {
      text    : `“Si alguno de los alimentos permitidos NO es tolerado por usted, elimínelo de su alimentación hasta su próximo control con médico”`,
      bold    : false,
      fontSize: 12,
      margin  : [0, 25, 0, 5],
      align   : 'center',
    },


  ]);
};
