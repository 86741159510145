import { patientTopCard } from "@shared/helpers/pdf-patient-card.helper";

export const buildContentPdfReproductive = (paperwork, group) => {
  const {patient, professional} = paperwork;
  const {rows}                  = group;
  return rows.map((pad) => 
    {
        const HEADER = {
            text: [
                { text: `Indicaciones Posterior\n` },
                { text: `Ciclo de Actividad Sexual Dirigida\n` },
                `\n`,
                { text: `Estimada: `, bold: true },
                {
                  text: `Usted se ha realizado un seguimiento folicular con actividad sexual dirigida, debiendo continuar con las siguientes indicaciones posteriormente a la ovulación.\n`,
                },
                `\n`,
        
                { text: `Indicaciones: \n`, bold: true },
              ]
          };
         
          const CONTENT = {
            fontSize: 12,
            margin: [0, 5],
      
            stack: [
              {
                alignment: 'justify',
                ol: [
                  [
                    'Progesterona',
                    {
                      ul: [
                        'Deberá iniciar uso de Progesterona 200 mg.',
                        '1 cápsula cada 12 horas (mañana y noche).',
                        'Se administra vía vaginal.',
                        {
                          text: [{ text: 'Día de inicio primera cápsula: ' }, `${pad.capsuleDate}`],
                          bold: true,
                        },
                        'Debe utilizar progesterona hasta el día del examen de sangre que detecta embarazo llamado Sub unidad Beta.',
                        'Por estar usando progesterona, es muy probable que no le llegue la regla',
                      ],
                    },
                    '\n',
                  ],
                  [
                    'Sub unidad Beta',
                    {
                      ul: [
                        {
                          text: [
                            { text: 'Deberá realizarse este examen de sangre el día: ' },
                            `${pad.bloodyDate}`,
                          ],
                          bold: true,
                        },
                        'No requiere ayuno.',
                        'Comuníquese con nuestra Unidad para informar que se realizó el examen.',
                      ],
                    },
                    '\n',
                  ],
                  [
                    'Si el resultado del examen es:',
                    {
                      ul: [
                        `(+) >5 mU/mL= existe embarazo. Debe mantener el uso de progesterona de la misma forma antes señalada y se le citará a una ecografía.`,
                        '(-) <5 mU/mL= no hay embarazo. Debe suspender la progesterona y con ello le llegará la regla. por orden de llegada.',
                        'En ambos casos, la contactaremos en un máximo de 48 horas informando el resultado.',
                      ],
                    },
                  ],
                  [{ text: '', pageBreak: 'before' }],
                  [
                    'Si le llega la regla:',
                    {
                      ul: [
                        `Nos debe informar para la realización de su informe, el que debe retirar para llevar a su médico y/o el inicio de un nuevo seguimiento folicular.`,
                      ],
                    },
                    '\n',
                  ],
                  [
                    'Mantenga:',
                    {
                      ul: [
                        'Ácido fólico: consuma 1 comprimido al día, vía oral.',
                        'Actividad sexual: libre, sin contraindicación.',
                      ],
                    },
                    '\n',
                  ],
                ],
              },
              {
                text: [
                  { text: `IMPORTANTE\n`, bold: true },
                  { text: `\n` },
                  {
                    text: `Considerando que desde ahora usted podría estar embarazada, debe seguir las siguientes indicaciones hasta confirmar el  embarazo mediante el examen de sub unidad beta. Si resultara negativo se suspenden las indicaciones:\n`,
                  },
                ],
              },
              '\n',
              {
                ul: [
                  'Alimentación: no debe consumir alimentos crudos (sushi, ceviche, crudo, etc.) y quesos no pasteurizados.',
                  'Hábitos: no debe consumir alcohol, tabaco ni drogas.',
                  'Otros medicamentos: consulte con su médico tratante según sea su necesidad.',
                  'Actividad física: evite ejercicios de rebote (ski, equitación, etc) y de contacto (boxeo)',
                  'Viajes: informe a su médico si viaja al extranjero.',
                  'Otros: no se recomienda el uso de jacuzzi, saunas y hot tub.\n',
                ],
              },
              '\n',
              {
                text: [
                  { text: `Ante cualquier duda, consulte con su médico tratante o matrona.\n`, bold: true },
                  { text: `22 754 8320 - 22 754 8321` },
                ],
              },
            ],
          };
          return [...patientTopCard(paperwork), HEADER, CONTENT];
      }
  );
};
