import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

import { Observable } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import { Emergency } from '@clinical/emergencies/interfaces/emergency.interface';
import { PCV2Request } from '@shared/interfaces/request.interface';
import { API_URIS } from '@shared/constants/api-uris.const';
import { StatusService } from '@shared/services/status.service';

const BASE_API_PCV2: string = environment.baseApiPCv2;
const URI_CLINICAL_FIND: string = API_URIS.clinical.search;
const URI_CLINICAL_URGENCY: string = API_URIS.clinical.urgency;

@Injectable({
  providedIn: 'root',
})
export class ClinicalEmergenciesService {
  constructor(private httpClient: HttpClient, private statusService: StatusService) {
  }

  saveUrgency(body: Emergency): Observable<Emergency> {
    return this.httpClient.post<PCV2Request>(`${BASE_API_PCV2}/${URI_CLINICAL_URGENCY}`, body).pipe(
      retry(3),
      map(({ data }: { data: Emergency }) => data),
    );
  }

  loadByPatient(patientId: any): Observable<any> {
    const URI_SEARCH = `pacientes/${patientId}`;
    const URL_SEARCH = `${BASE_API_PCV2}/${URI_CLINICAL_URGENCY}/${URI_SEARCH}`;
    return this.httpClient
      .get<PCV2Request>(URL_SEARCH)
      .pipe(map(({ data }: { data: any[] }) => data), catchError(this.statusService.catchError));
  }

  loadByProfessional(gsiMultiCondition1St: string): Observable<any> {
    const gsiEncode = encodeURIComponent(gsiMultiCondition1St);
    const URI_SEARCH = `${gsiEncode}`;
    return this.httpClient
      .get<PCV2Request>(`${BASE_API_PCV2}/${URI_CLINICAL_FIND}/${URI_SEARCH}`)
      .pipe(map(({ data }: { data: any[] }) => data));
  }
}
