import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Appointment } from '@shared/interfaces/appointment.interface';
import { RequestSchedule } from '@clinical/schedule/interfaces/schedule.interface';

export const LOAD_AGENDA = createAction('[agenda] Cargar Citas', props<{ params: RequestSchedule }>());
export const LOAD_AGENDA_OK = createAction('[agenda] OK Cargar Citas', props<{ appointments: Appointment[] }>());
export const LOAD_AGENDA_ERR = createAction('[agenda] ERR Cargar Citas', props<{ payload: HttpErrorResponse }>());

export const SELECTED_AGENDA = createAction('[agenda] SELECTED Cita', props<{ patientDocNumber: string }>());
export const UNSELECT_AGENDA = createAction('[agenda] UNSELECT Cita');
