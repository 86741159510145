import { patientTopCard } from '@shared/helpers/pdf-patient-card.helper';
import { Procedure, Study } from '@shared/interfaces/dermatology.interface';

export const buildContentPdfDermatologia = (paperwork, group) => {
  const {rows} = group;

  const dermatologyBuildPdf = rows.map((item) => {
    const {diagnosis, studies, procedures, isAnesthetized, estimatedTime} = item;
    const {center, hasHistologicalStudies, observations}                  = item;

    const HISTOLOGICAL = {text: `${hasHistologicalStudies}`};
    if (hasHistologicalStudies === false) HISTOLOGICAL.text = 'No';
    else HISTOLOGICAL.text = 'Si';

    const ANESTHESIA = {text: `${isAnesthetized}`};
    if (isAnesthetized === false) ANESTHESIA.text = 'No';
    else ANESTHESIA.text = 'Si';

    const OBS = {text: `${observations}`};
    if (!observations) OBS.text = '';

    const PROCEDURE = procedures.map((procedure: Procedure) => [{text: `${procedure.total} x ${procedure.glosa}`, bold: true}]);

    const STUDIES = studies
      .filter((study: Study) => study.quantity > 0)
      .map((study: Study) => [
        { text: `${study.quantity.toString()} x ${study.code} - ${study.name}`, bold: true },
      ]);

    return [
      {
        table : {
          widths    : [130, 400, 'auto'],
          headerRows: 1,
          body      : [
            [{text: 'Diagnóstico:'}, {text: `   ${diagnosis.join(', ')} `}],
            [{text: 'Procedimiento(s): '}, PROCEDURE],

            [{text: 'Anestesia local 10%:'}, {text: ANESTHESIA}],
            [{text: 'Tiempo estimado:'}, {text: `   ${estimatedTime} `}],
            [{text: 'Centro Médico:'}, {text: `   ${center.name} `}],
            [{text: 'Estudio histológico:'}, {text: HISTOLOGICAL}],
            [{text: ''}, STUDIES],
          ],
        },
        layout: 'noBorders',
      },

      {text: `Consideraciones especiales`, bold: true, fontSize: 14, margin: [0, 20]},
      {
        text: [OBS],
      },
    ];
  });

  return [...patientTopCard(paperwork), dermatologyBuildPdf];
};
