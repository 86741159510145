import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {API_URIS} from '@shared/constants/api-uris.const';
import {PCV2Request} from '@shared/interfaces/request.interface';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

const BASE_API_PCV2: string = environment.baseApiPCv2;
const URI_MULTY: string = API_URIS.multy;

@Injectable({
  providedIn: 'root'
})
export class MultiService {

  constructor(private httpClient: HttpClient) {
  }


  bedAvailability(idCenter): Observable<any> {
    const url = `${BASE_API_PCV2}/proxyvpc/v1/bitacora?sufixURL=HOPITALIZADOS/?CENTRO=${idCenter}&amb=pro&port=7600`;
    return this.httpClient.get<PCV2Request>(url).pipe(map(this.transformData));
  }

  transformData(request: PCV2Request): any {
    const {data} = request;
    const hospitalizados = data.Hospitalizados;
    return hospitalizados.datos.map((dato) => dato).filter((dato) => dato.episodio !== '');
  }


  save(body: any): Observable<any> {
    return this.httpClient.post<PCV2Request>(`${BASE_API_PCV2}/${URI_MULTY}`, body).pipe();
  }
}
