import {format} from 'date-fns';

export const buildContentPdfConsent = (paperwork, group) => {
  const {patient, professional} = paperwork;
  const {rows}                  = group;
  const EMPTY_LINE              = '\n\n';
  const NAME_COMPLETE           = `${patient.names} ${patient.surnames}`;
  const PROFESSIONAL_NAME       = `${professional.names} ${professional.surnames}`;
  return rows.map((itemForm) => [
    {
      fontSize: 10,
      margin: [0, 1, 0, 0],
      stack: [
        {
          text: [
            { text: `Yo, ` },
            { text: `${NAME_COMPLETE} `, bold: true },
            { text: `RUN ` },
            { text: `${patient.documentNumber} `, bold: true },

          ],
          alignment: 'justify',
          margin: [0, 5, 0, 7],
        },
        {
          text: [
        {  text: `Con mi firma declaro que,`, bold: true },

            { text: ` de acuerdo con la evaluación clínica que me ha sido realizada, he sido diagnosticado con:` },
          ],
        },
        { text: EMPTY_LINE },
        { text: itemForm.condition.toUpperCase(), bold: true, fontSize: 12 },
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },
        { text: [{ text: `(Diagnóstico debe ser escrito sin abreviaturas)` }], alignment: 'center' },
        { text: EMPTY_LINE },
        {
          text: `Y se me ha explicado que debo ser sometido al siguiente procedimiento`,
        },
        { text: itemForm.procedure.toUpperCase(), bold: true, fontSize: 12, margin: [0, 5, 0, 0] },
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },
        { text: [{ text: `(Procedimiento debe ser escrito sin abreviaturas)` }], alignment: 'center' },
        { text: EMPTY_LINE },

        { text: `Me han sido explicados`,margin: [0, 0, 0, 6] },
        {
          ul: [
            'Su objetivo y los beneficios generales.',
            'Las características generales. ',
            'La descripción de la evolución esperada posterior al procedimiento en condiciones habituales, incluyendo aspectos relacionados al dolor, su manejo y el tiempo esperado de la recuperación. ',
            'La existencia de procedimientos alternativos y las razones por las que se me ha indicado este acto en particular.',
            'Las consecuencias de no ser sometido al procedimiento propuesto.'
          ]
        },
        { text: `Además de los riesgos de sufrir dolor, hemorragia o una infección, me han sido detallados los riesgos y complicaciones más comunes para el procedimiento propuesto, entre otros:`,margin: [0, 7, 0, 20] },

        { text: itemForm.risksAndComplications.toUpperCase(), bold: true, fontSize: 12 },
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },
        { text: `En el caso de procedimientos en los que la práctica habitual no considere necesaria la presencia de anestesistas, se me ha explicado la técnica de sedación o analgesia requerida (en el caso de procedimientos que no requieren anestesia). `,margin: [0, 10, 0, 20] },


        { text: `El médico/odontólogo responsable del procedimiento es:`, margin: [0, 5, 0, 6] },
        {
          columns: [
            { text: `Dr.(a) ${itemForm.whoPerformProcedure === 'yo' ? PROFESSIONAL_NAME : ''}`, width: '*' },
            {
              text: `${itemForm.whoPerformProcedure === 'yo' ? professional.rut : ''}`,
              width: 100,
              alignment: 'center',
            },
            { text: ` `, width: 100 },
          ],
          bold: true,
          fontSize: 12,
          margin: [0, 5, 0, 0],
        },
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },
        {
          columns: [
            { text: `Nombre/apellidos (o timbre)`, width: '*' },
            { text: `RUN`, width: 100, alignment: 'center' },
            { text: `Firma`, width: 100, alignment: 'center' },
          ],
        },
        {
          text: [
            {
              text: `Se me ha informado que, durante el procedimiento, además del médico u odontólogo responsable, participarán otros miembros del `,
            },
            {
              text: `equipo de salud debidamente autorizados y considerados necesarios para el buen desarrollo del procedimiento, quienes guardará mi confidencialidad. `,
            },
            {
              text: `Siendo este un centro académico-asistencial, también he sido informado(a) que profesionales en distintas etapas de formación  `,
            },
            {
              text: `podrían observar o participar en algunas etapas del procedimiento de manera supervisada por el médico/odontólogo responsable. `,
            },
          ],
          alignment: 'justify',
          margin: [0, 20, 0, 0],

        },
        { text: EMPTY_LINE },

        { text: `Comprendo además que, en mi beneficio, se considerará:`, margin: [0, 5, 0, 6] },
        {
          type: 'lower-alpha',
          ol: [
            'La decisión por parte del médico responsable de modificar el procedimiento planificado si es que aparecen situaciones durante su ejecución que así lo aconsejen',
            'La extracción de tejidos para ser sometidos a los estudios que se consideren necesarios para mejorar mi diagnóstico, y/o tratamiento y/o pronóstico, piezas que pueden además tener como uso secundario los propósitos científicos o docentes, siempre resguardando la confidencialidad. ',
          ]
        },
        {
          text: `Respecto de mis antecedentes médicos, declaro haber transmitido la información que conozco respecto de enfermedades, alergias, uso de medicamentos o riesgos personales, y no he omitido ni alterado ninguna información, comprendiendo que, de hacerlo, pongo en riesgo mi vida y el resultado del procedimiento propuesto. `,
          margin: [0, 15, 0, 10],
        },
        { text: `Finalmente, estoy consciente que, hasta antes de iniciado el procedimiento, puedo cambiar mi opinión acerca de los aspectos señalados, lo cual deberé comunicar oportunamente al equipo médico u odontológico tratante.`, margin: [0, 10, 0,0] },

        { text: `Por todo lo anterior:  `, bold: true, margin: [0, 5, 0, 0] },

        { marginTop: 10,bold: true, text: `Consiento la realización del procedimiento ` },
        {
          alignment: 'justify',
          fontSize: 10,
          style: 'center',

          stack: [
            {
              marginTop: -12,
              marginLeft: 430,
              canvas: [
                {
                  type: 'rect',
                  x: 1,
                  y: 1,
                  w: 10,
                  h: 10,
                },
              ],
            },
          ],
        },
        { marginTop: -10, marginLeft: 220, bold: true, text: `No Consiento la realización del procedimiento ` },
        {
          alignment: 'justify',
          style: 'center',

          stack: [
            {
              marginTop: -12,
              marginLeft: 200,
              canvas: [
                {
                  type: 'rect',
                  x: 1,
                  y: 1,
                  w: 10,
                  h: 10,
                },
              ],
            },
          ],
        },



        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }], margin: [0, 20, 0, 0] },
        {
          columns: [
            { text: `Nombre Paciente`, width: '*' },
            { text: `RUN`, width: 80 },
            { text: `Firma`, width: 80 },
            { text: `Fecha `, width: 80, alignment: 'center' },
          ],
        },

        {text:'', margin: [0,0,0,20]},
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },
        { marginTop: 10, text: `Asentimiento `, bold:true },

        { marginTop: 10, text: `Teniendo yo menos de 18 años, asiento en que se me realice el procedimiento: ` },
        {
          alignment: 'justify',
          fontSize: 10,
          style: 'center',

          stack: [
            {
              marginTop: -12,
              marginLeft: 390,
              canvas: [
                {
                  type: 'rect',
                  x: 1,
                  y: 1,
                  w: 10,
                  h: 10,
                },
              ],
            },
          ],
        },
        { marginTop: -10, marginLeft: 370, bold: true, text: `Si ` },
        {
          alignment: 'justify',
          style: 'center',

          stack: [
            {
              marginTop: -12,
              marginLeft: 355,
              canvas: [
                {
                  type: 'rect',
                  x: 1,
                  y: 1,
                  w: 10,
                  h: 10,
                },
              ],
            },
          ],
        },
        {
          marginTop: -10,
          marginLeft: 405,
          bold: true,
          alignment: 'justify',

          text: `No`,
        },
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },
        {text:'', margin:[0,10,0,0]},
        { marginTop: 10, text: `Representación `, bold:true },

        { marginTop: 10,bold: true, text: `En representación del paciente: Consiento la realización del procedimiento ` },


        {
          alignment: 'justify',
          style: 'center',

          stack: [
            {
              marginTop: -12,
              marginLeft: 340,
              canvas: [
                {
                  type: 'rect',
                  x: 1,
                  y: 1,
                  w: 10,
                  h: 10,
                },
              ],
            },
          ],
        },


        { marginTop: 10,bold: true, text: `No Consiento la realización del procedimiento ` },


        {
          alignment: 'justify',
          style: 'center',

          stack: [
            {
              marginTop: -12,
              marginLeft: 340,
              canvas: [
                {
                  type: 'rect',
                  x: 1,
                  y: 1,
                  w: 10,
                  h: 10,
                },
              ],
            },
          ],
        },


        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }], margin: [0, 25, 0, 0] },
        {
          columns: [
            { text: `Nombre Representante`, width: '*' },
            { text: `RUN`, width: 80 },
            { text: `Firma`, width: 80 },
            { text: `Fecha `, width: 80, alignment: 'center' },
          ],
        },


        {
          text: `Causa de la representación: ___________________________________________________________________________________`,
          margin: [0, 10, 0, 10],
        },
        { marginTop: 10, text: `Médico/Odontólogo informante `, bold:true, pageBreak: 'before' },

        {
          text: [
            {
              text: `He explicado el procedimiento al paciente (o su representante) y he discutido con él (ella) la `,
            },
            { text: `información arriba señalada. El (la) paciente (o su representante) ` },
            {
              text: [
                { text: `SI ` },
                { text: `${itemForm.isAllow ? ' X ' : '   '}`, decoration: 'underline' },
              ],
            },
            {
              text: [
                { text: `NO ` },
                { text: `${!itemForm.isAllow ? ' X ' : '   '}`, decoration: 'underline' },
              ],
            },
            { text: ` ha consentido en que el procedimiento le sea practicado.` },
          ],
          margin: [0, 5, 0, 10],
        },
        {
          columns: [
            { text: `Dr.(a) ${professional.names} ${professional.surnames}`, width: '*' },
            { text: `${format(new Date(), 'dd/MM/yyyy')}`, width: 80, alignment: 'center' },
            { text: `${professional.rut}`, width: 80 },
          ],
          margin: [0, 5, 0, 0],
          bold: true,
          fontSize: 12,
        },
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }], margin: [0, 0, 0, 0] },
        {
          columns: [
            { text: `Nombre MÉDICO/ODONTÓLOGO INFORMANTE`, width: '*' },
            { text: `Fecha`, width: 80, alignment: 'center' },
            { text: `R.U.N.`, width: 80, alignment: 'center' },
          ],
        },
     //   { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }], margin: [0, 10, 0, 10] },
        { text: `REVOCACION: En forma voluntaria, he decidido revocar el consentimiento arriba especificado`, bold: true, margin: [0, 10, 0, 0]  },
        EMPTY_LINE,
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }], margin: [0, 10, 0, 0] },
        {
          columns: [
            { text: `Nombre PACIENTE o REPRESENTANTE`, width: '*' },
            { text: `R.U.N.`, width: 80 },
            { text: `Firma`, width: 80 },
            { text: `Fecha`, width: 80, alignment: 'center' },
          ],
        },
      ],
    },
    ,
  ]);
};
