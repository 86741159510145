import {patientTopCard} from '@shared/helpers/pdf-patient-card.helper';

export const buildContentPdfEye = (paperwork, group) => {
  const {rows}  = group;
  let eyeglases = '';

  const eyeBuildPdf = rows.map((item) => {
    let SPH = ` ${item.od.sph} `;
    if (!item.od.sph) SPH = '';

    let CYL = `${item.od.cyl}`;
    if (!item.od.cyl) CYL = '';

    let AXIS = `${item.od.axis}`;
    if (!item.od.axis) AXIS = '';

    let OD_OBS = `${item.od.observations}`;
    if (!item.od.observations) OD_OBS = '';

    let OS_SPH = ` ${item.os.sph} `;
    if (!item.os.sph) OS_SPH = '';

    let OS_CYL = `${item.os.cyl}`;
    if (!item.os.cyl) OS_CYL = '';

    let OS_AXIS = `${item.os.axis}`;
    if (!item.os.axis) OS_AXIS = '';

    let OS_OBS = `${item.os.observations}`;
    if (!item.os.observations) OS_OBS = '';

    let ADD = `${item.add}`;
    if (!item.add) ADD = '';

    let ADD_OPTION = `${item.addOption}`;
    if (!item.addOption) ADD_OPTION = '';

    let PD_NEAR = `${item.pd.near}`;
    if (!item.pd.near) PD_NEAR = '';

    let PD_DISTANCE = `${item.pd.distance}`;
    if (!item.pd.distance) PD_DISTANCE = '';

    let EYE_GLASES = `${item.lensType} `;
    if (!item.lensType) EYE_GLASES = '';

    let REMARKS = `${item.remarks}`;
    if (!item.remarks) REMARKS = '';

    const {lensType} = item;

    return [
      {text: `Lentes`, bold: true, margin: [0, 5], alignment: 'justify', fontSize: 16},
      {canvas: [{type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1}]},
      {text: '\n\n'},

      {
        ul: [
          {
            text: [
              'OD \t\t',
              {text: ` ${SPH} `, bold: true},
              ' Esf. \t\t',
              {text: ` ${CYL} `, bold: true},
              ' Cyl. \t\t a ',
              {text: ` ${AXIS} º `, bold: true},
              '\n Observación OD:',
              {text: `  ${OD_OBS} `, bold: true},
            ],
          },
        ],
      },
      {text: '\n\n'},
      {
        ul: [
          {
            text: [
              'OI \t\t',
              {text: ` ${OS_SPH} `, bold: true},
              ' Esf. \t\t',
              {text: ` ${OS_CYL} `, bold: true},
              ' Cyl. \t\t a ',
              {text: ` ${OS_AXIS} º `, bold: true},
              '\n Observación OI:',
              {text: `  ${OS_OBS} `, bold: true},
            ],
          },
        ],
      },
      {
        text: ['\n\n Cerca Add ', {text: ` ${ADD} `, bold: true}, ' Esf. ', {text: ` ${ADD_OPTION} `}],
      },
      {
        text: [
          '\n\n Distancia pupilar: \t\t Lejos',
          {text: ` ${PD_DISTANCE} `, bold: true},
          'mm \t Cerca ',
          {text: ` ${PD_NEAR} `, bold: true},
          'mm ',
        ],
      },
      {
        text: ['Tipo de lente: \t\t\t ', {text: `${lensType.join(', ')}`}],
      },
      {
        text: ['Observaciones: \t\t\t ', {text: `${REMARKS}`}],
      },
    ];
  });

  return [...patientTopCard(paperwork), eyeBuildPdf];

};
