export enum TypeRequest {
  'exclude' = 'Bloqueo',
  'include' = 'Oferta',
  'closure' = 'Cierre',
  'offer' = 'Oferta',
}

export enum StatusRequest {
  'pre-aprobado' = 'Pre-aprobado',
  'pendiente' = 'Pendiente',
  'gestionado' = 'Gestionado',
  'rechazado' = 'Rechazado',
}

export enum Status {
  'rejected' = 'rechazado',
  'rechazado' = 'rechazado',
  'approved' = 'aprobado',
  'pending' = 'pendiente',
  'pendiente' = 'pendiente',
  'managed' = 'gestionado',
  'gestionado' = 'gestionado',
  'pre-approved' = 'pre-aprobado',
}

export enum ViewStatus {
  'pre-aprobado' = 'Mas Información',
  'pendiente' = 'Mas Información',
  'gestionado' = 'Mas Información',
  'rechazado' = 'Motivo de Rechazo',
}

export enum DaysWeek {
  'Monday' = 'Lu',
  'Tuesday' = 'Ma',
  'Wednesday' = 'Mi',
  'Thursday' = 'Ju',
  'Friday' = 'Vi',
  'Saturday' = 'Sa',
  'Sunday' = 'Do',
}

export enum Exceptions {
  'Monday' = 'Monday',
  'Tuesday' = 'Tuesday',
  'Wednesday' = 'Wednesday',
  'Thursday' = 'Thursday',
  'Friday' = 'Friday',
  'Saturday' = 'Saturday',
  'Sunday' = 'Sunday',
}
