import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducers';
import { PdfService } from '@shared/helpers/pdf.helper';
import { ModalController } from '@ionic/angular';
import { CREATE_PRESIGNED_URL, PUT_OBJECT_S3, PUT_PROFESSIONAL } from 'src/app/store/actions';
import { environment } from '@environments/environment';
import { Attachment, PresignedUrlRequest } from '@shared/interfaces/storage.interface';
import { Signature } from '@auth/interfaces/professional.interface';
import { HttpErrorResponse } from '@angular/common/http';

import SignaturePad from 'signature_pad';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';
import { KEY_STORAGE_STORE } from '@store/store-keys';
import { ROOT_ROUTES } from '../../app-routing.module';
import { CLINICAL_ROUTES } from '@clinical/clinical-routes.module';

const APP_DEFAULT_BUCKET = environment.defaultBucketS3;

@Component({
  selector   : 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls  : ['./signature.component.scss'],
  exportAs   : 'modal',
})
export class SignatureComponent implements AfterViewInit, OnDestroy {
  @ViewChild('canvas') canvasEl: ElementRef;
  public context: CanvasRenderingContext2D;

  signaturePad: SignaturePad;
  signatureImg: string;
  docDataUrl: string;

  isLoading: boolean;
  status: string;
  error: HttpErrorResponse;
  disabled = false;

  subscribeToStorage$: Subscription;
  docSign$: Subscription;

  constructor(
    public modalController: ModalController,
    public pdfService: PdfService,
    private authService: AuthService,
    private store: Store<AppState>,
    private router: Router,
  ) {
  }

  ngOnDestroy(): void {
    if (this.subscribeToStorage$) this.subscribeToStorage$.unsubscribe();
    if (this.docSign$) this.docSign$.unsubscribe();
  }

  ngAfterViewInit() {
    this.context      = this.canvasEl.nativeElement.getContext('2d');
    this.signaturePad = new SignaturePad(this.context.canvas, {
      minWidth: 1,
      maxWidth: 1,
    });
  }

  uploadFiles(event: any) {
    this.clearPad();
    const file    = event.target.files[0];
    const reader  = new FileReader();
    reader.onload = (e: any) => {
      this.signaturePad.fromDataURL(e.target.result);
    };
    reader.readAsDataURL(file);
    this.signaturePad.off();
  }

  filter() {
    const canvas    = this.context.canvas;
    const ctx       = canvas.getContext('2d');
    const copy      = document.createElement('canvas').getContext('2d');
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const numPixels = canvas.width * canvas.height;
    const pixels    = imageData.data;

    for (let i = 0; i < numPixels; i++) {
      const r = pixels[i * 4];
      const g = pixels[i * 4 + 1];
      const b = pixels[i * 4 + 2];

      const grey = (r + g + b) / 3;

      pixels[i * 4]     = grey;
      pixels[i * 4 + 1] = grey;
      pixels[i * 4 + 2] = grey;
    }
  }

  /**
   * salva la firma en el profesional
   */
  async saveSignature() {
    this.disabled = true;
    this.createPresignedUrl();
    this.docSign();
  }

  /**
   * Método que convierte firma base64 en blob
   */
  async urlToBlob(): Promise<Blob> {
    const url         = await this.trimImage(this.context.canvas);
    const parts       = url.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw         = window.atob(parts[1]);
    const rawLength   = raw.length;
    const uInt8Array  = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; i++) uInt8Array[i] = raw.charCodeAt(i);

    return new Blob([uInt8Array], {type: contentType});
  }

  /**
   * obtiene el presigned url para subir el archivo
   */
  async docSign() {
    this.docSign$ = this.store
      .select(KEY_STORAGE_STORE)
      .subscribe(({ authorizer, isLoaded, isLoading, error, progress }) => {
        this.error = error;
        this.isLoading = isLoading;

        if (isLoading) this.status = 'Solicitando permisos para guardar firma';

        if (isLoaded && progress === 0) {
          this.status = 'Permisos obtenidos para guardar firma';
          this.saveToS3(authorizer);
        }
    });
  }

  /**
   * Método que guarda el archivo en el bucket de s3
   *
   * @param presignedUrl
   */
  async saveToS3(presignedUrl: any) {
    const {url, key} = presignedUrl;
    const blob       = await this.urlToBlob();
    const docFile    = new File([blob], key, {type: 'image/png'});

    this.store.dispatch(PUT_OBJECT_S3({url, docFile}));
    this.status = 'Guardando firma.';
    this.subscribeToStorage();
  }

  subscribeToStorage() {
    this.subscribeToStorage$ = this.store
      .select('storage')
      .subscribe(({authorizer, isLoaded, isLoading, error, progress}) => {
        if (isLoaded && progress === 100) {
          this.status            = 'Actualizando registro de profesional.';
          const date             = new Date().toISOString();
          const att: Attachment  = {
            key       : authorizer.key,
            path      : authorizer.path,
            bucket    : authorizer.bucket,
            category  : 'firma digital',
            uploadDate: date,
            isAttached: true,
          };
          const sgtr: Signature  = {
            isActive  : true,
            createdAt : date,
            attachment: att,
            terms     : {
              isAccepted: true,
              acceptedAt: date,
            },
          };
          const valuesToSave     = {...this.authService.professional};
          valuesToSave.signature = sgtr;
          this.store.dispatch(PUT_PROFESSIONAL({valuesToSave}));
          this.status = 'Firma guardada.';
          this.modalController.dismiss();
        }
      });
  }

  /**
   * Método que crea el presigned url para subir el archivo
   */
  createPresignedUrl() {
    const params: PresignedUrlRequest = {
      ext   : 'png',
      path  : 'firmas',
      bucket: APP_DEFAULT_BUCKET,
    };
    this.store.dispatch(CREATE_PRESIGNED_URL({params}));
  }

  /**
   * funciona en dispositivos no en el browser
   *
   * @param event
   */
  startDrawing(event: Event) {
  }

  /**
   * funciona en dispositivos no en el browser
   *
   * @param event
   */
  moved(event: Event) {
  }

  /**
   * limpia el canvas de la firma
   *
   */
  clearPad() {
    this.signaturePad.clear();
    this.signatureImg = null;
    this.signaturePad.on();
  }

  /**
   * cierra el modal al rechazar la firma
   *
   */
  closeModal() {
    this.router.navigateByUrl(`/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.agenda}`);
    this.modalController.dismiss();
  }

  async trimImage(c: HTMLCanvasElement) {
    this.bwFilter();
    this.contrastFilter();
    const ctx    = c.getContext('2d');
    const copy   = document.createElement('canvas').getContext('2d');
    const pixels = ctx.getImageData(0, 0, c.width, c.height);
    const l      = pixels.data.length;
    let i: number;
    const bound  = {
      top   : null,
      left  : null,
      right : null,
      bottom: null,
    };
    let x: number;
    let y: number;

    for (i = 0; i < l; i += 4)
      if (pixels.data[i + 3] !== 0) {
        x = (i / 4) % c.width;
        y = i / 4 / c.width;

        if (bound.top === null) bound.top = y;

        if (bound.left === null) bound.left = x;
        else if (x < bound.left) bound.left = x;

        if (bound.right === null) bound.right = x;
        else if (bound.right < x) bound.right = x;

        if (bound.bottom === null) bound.bottom = y;
        else if (bound.bottom < y) bound.bottom = y;
      }

    const trimHeight = bound.bottom - bound.top;
    const trimWidth  = bound.right - bound.left;
    const trimmed    = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

    copy.canvas.width  = trimWidth;
    copy.canvas.height = trimHeight;
    copy.putImageData(trimmed, 0, 0);

    // open new window with trimmed image:
    return copy.canvas.toDataURL('image/png');
  }

  bwFilter() {
    const ctx       = this.context.canvas.getContext('2d');
    const imageData = ctx.getImageData(0, 0, this.context.canvas.width, this.context.canvas.height);
    const pixels    = imageData.data;
    const numPixels = imageData.width * imageData.height;

    for (let i = 0; i < numPixels; i++) {
      const r = pixels[i * 4];
      const g = pixels[i * 4 + 1];
      const b = pixels[i * 4 + 2];

      const grey = (r + g + b) / 3;

      pixels[i * 4]     = grey;
      pixels[i * 4 + 1] = grey;
      pixels[i * 4 + 2] = grey;
    }

    ctx.putImageData(imageData, 0, 0);
  }

  contrastFilter() {
    const contrast  = 100;
    const ctx       = this.context.canvas.getContext('2d');
    const imageData = ctx.getImageData(0, 0, this.context.canvas.width, this.context.canvas.height);
    const pixels    = imageData.data;
    const numPixels = imageData.width * imageData.height;
    const factor    = (259 * (contrast + 255)) / (255 * (259 - contrast));

    for (let i = 0; i < numPixels; i++) {
      const r = pixels[i * 4];
      const g = pixels[i * 4 + 1];
      const b = pixels[i * 4 + 2];

      pixels[i * 4]     = factor * (r - 128) + 128;
      pixels[i * 4 + 1] = factor * (g - 128) + 128;
      pixels[i * 4 + 2] = factor * (b - 128) + 128;
    }

    ctx.putImageData(imageData, 0, 0);
  }
}
