export const API_URIS = {
  zoom         : 'zoom/v1',
  sing         : 'firma/v1',
  professional : 'usuarios/v1',
  auth         : 'usuarios/v1/sesiones',
  storage      : 'almacenamiento/v1',
  oncology     : 'oncologia/v1',
  notifications: {v1: 'notificaciones/v1'},
  clinical     : {
    search         : 'clinico/v1/indices/1/buscar',
    gsi2nd         : 'clinico/v1/indices/2/buscar',
    external       : 'autorizador-externo/v1/short-id',
    recipes        : 'clinico/v1/recetas',
    surgery        : 'clinico/v1/cirugias',
    forms          : 'clinico/v1/formularios',
    anamnesis      : 'clinico/v1/anamnesis',
    indications    : 'clinico/v1/indicaciones',
    derivations    : 'clinico/v1/derivaciones',
    reproductive   : 'clinico/v1/reproductivas',
    urgency        : 'clinico/v1/urgencias',
    records        : 'clinico/v1/registros',
    hospitalization: 'clinico/v1/hospitalizaciones',
    medicalcare    : 'clinico/v1/atenciones',
    documents      : 'clinico/v1/documentos',
    expertises     : 'clinico/v1/peritajes',
    discharge      : 'altamedica/v1',
  },
  schedule     : {
    request: 'agenda/v1/solicitudes',
    box: 'agenda/v1/box',
    specialties: 'Servicios/Rel/Especialidades',
  },
  multy        : 'rondamultidiciplinaria/v1',
  maintainer   : {
    document: 'mantenedores/v1/documentos',
    config: 'mantenedores/v1/configuracion',
  },
  surgery      : {
    request: 'v1/cirugias',
  },
  keiron: {
    surgery: 'keiron/v1/cirugia',
  },
  pharmacy     : {
    pharmacy: 'farmacia',
  },
  pay: {
    pharmacy: {
      webpay: 'pagos/v1/tbk/webpay/farmacia/iniciar-pago',
    },
  },
  proxy        : {
    prm: 'proxy/v1?sufixURL=',
    lab: 'proxy/v1/lab?sufixURL=',
    cmv: 'proxy/v1/cmv?sufixURL=',
    transbank: 'proxy/v1/transbank?sufixURL=',
    sign: 'proxy/v1/legal-sign?sufixURL=',
    patientUcc: 'proxy/v1/paciente/ucc?suffixURL=',
    intranet: {
      ucc:'proxy/v1/intranet/ucc?suffixURL='
    }
  },
};
