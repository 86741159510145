import { Injectable } from '@angular/core';
import { Professional } from '@auth/interfaces/professional.interface';
import { AuthService } from '@auth/services/auth.service';
import { Patient } from '@components/patient/interfaces/patient.interface';
import { environment } from '@environments/environment';
import { DOCUMENT_TYPE } from '@shared/constants/patient.constant';
import { FileService } from '@shared/helpers/file.helper';
import { Center } from '@shared/interfaces/center.interface';
import { StorageService } from '@shared/services/storage.service';
import { format, intervalToDuration } from 'date-fns';

const APP_DOMAIN: string = environment.appDomain;
const DOMAIN: string = environment.domain;

@Injectable({
  providedIn: 'root'
})
export class DauService {

constructor(    
  private fileService: FileService,
  private storageService: StorageService,
  private authService: AuthService,) { }

async header(professional: Professional, title: string, layoutDef: any) {
  const LOGO_IMG = await this.fileService.imageToBase64('assets/images/logo.png');
  const LOGO = { image: LOGO_IMG , width: !layoutDef.headerLogoFonasa ? 170 : 500, style: 'logo' };
  let TITLE = { style: 'title', noWrap: false, stack: [`${title.toUpperCase()}`] };
  if(layoutDef.headerLogoFonasa){TITLE = { style: 'title', noWrap: false, stack: [''] };}
  const PROFESSIONAL_INFO = {
    width: '*',
    alignment: 'right',
    style: 'headerText',
    stack: [
      { text: `${professional.names.toUpperCase()} ${professional.surnames.toUpperCase()}` },
      { text: `${professional.rut}` },
      { text: `${professional.specialty?.name.toUpperCase()}` },
      { text: `${professional.email === null ? '' : professional.email}` },
    ],
  };
  if (layoutDef.hideDataHeader) return [{ columns: [LOGO] }, TITLE];

  return [{ columns: [LOGO, PROFESSIONAL_INFO] }, TITLE];
}

cardContentTop(patient: Patient, center?: Center, breakPage = false) {
  const SIMPLE_DATE_FORMAT = 'dd-MM-yyyy';
  if (patient) {
    const { email, documentNumber, documentType, names, surnames, address, birth, phone, isTemporal } = patient;

    const fullname = names.toUpperCase() + ' ' + surnames.toUpperCase();
    // const fullname = names ? names.toUpperCase() + ' ' : names + surnames ? surnames.toUpperCase() : surnames;
    const birthDate: Date = new Date(birth);
    const { years, months, days } = intervalToDuration({ start: birthDate, end: new Date() });
    const patientAge = `${years} AÑOS ${months} MESES ${days} DÍAS`;
    const fulladdress = address ? `${address.street} ${address.streetNumber}, ${address.locality}` : '';

    let CENTER = [
      'Atención:',
      { text: `${format(new Date(), SIMPLE_DATE_FORMAT)}`, bold: true },
      { text: `Centro: ${center?.name}`, bold: true },
    ];
    if (!center) CENTER = ['Atención:', { text: `${format(new Date(), SIMPLE_DATE_FORMAT)}`, bold: true }, '', ''];

    const EMPTY_LINE = ['', '', '', ''];
    const LAYOUT_CONFIG = {
      hLineWidth: (i: any, node: any) => (i === 0 || i === node.table.body.length ? 0.5 : 0),
      vLineWidth: (i: any, node: any) => (i === 0 || i === node.table.widths.length ? 0 : 0),
      hLineColor: (i: any, node: any) => (i === 0 || i === node.table.body.length ? 'gray' : 'gray'),
      vLineColor: (i: any, node: any) => (i === 0 || i === node.table.widths.length ? 'gray' : 'gray'),
      fillColor: (rowIndex: number, node: any, columnIndex: any) => (rowIndex % 2 === 0 ? '#FCFCFC' : '#FCFCFC'),
    };

    return [
      {
        pageBreak: breakPage ? 'before' : '',
        style: 'content',
        table: {
          widths: [50, '*', 35, 'auto'],
          body: [
            EMPTY_LINE,
            CENTER,
            [
              'Paciente:',
              { text: `${fullname}`, bold: true },
              { text: isTemporal ? '' : 'Edad:', bold: true },
              { text: isTemporal ? '' : patientAge, bold: true },
            ],
            [
              `${DOCUMENT_TYPE[documentType?.toLowerCase()]}:`,
              { text: `${documentNumber}`, bold: true },
              { text: 'Correo:' },
              { text: `${email === null ? '' : email.toUpperCase()}`, bold: true },
              //TODO:verificar porque no viene el correo en algunos pacientes
            ],

            address && address.locality
              ? [
                  'Dirección:',
                  { text: `${fulladdress}`, bold: true },
                  { text: `Teléfono: `, margin: [0, 0, -10, 0] },
                  { text: `\t ${phone}`, bold: true },
                ]
              : EMPTY_LINE,
            EMPTY_LINE,
          ],
        },
        layout: LAYOUT_CONFIG,
      },
    ];
  }
}

async footer(
  valuesToSave: any,
  isSigned: boolean,
  containSigns: boolean,
  ignoreSigns: boolean,
  layoutDef: any,
) {
  const PHONES_IMG = await this.fileService.imageToBase64('assets/images/telefonos-llamanos.png');
  const UC_IMG = await this.fileService.imageToBase64('assets/images/ucchristus-cl.png');
  const SIGN_DEFAULT = await this.fileService.imageToBase64('assets/images/firma.png');
  const CREATE_AT = format(new Date(), 'dd-MM-yyyy HH:mm:ss');
  const BORDER_TOP = [false, true, false, false];
  const NO_BORDER = [false, false, false, false];

  const CELL_EMPTY = { border: [false, false, false, false], text: ' ' };
  const LINE_EMPTY = [{ border: [false, false, false, false], text: ' \n\n\n ' }, CELL_EMPTY, CELL_EMPTY];

  const listRecipesToSendPharmacyPortal = ['RMG', 'RMD'];
  const isRME = listRecipesToSendPharmacyPortal.includes(valuesToSave.suffix);
  const urlPharmacy = `https://portalfarmacias.ucchristus.cl/portal/block-portal/${valuesToSave.shortId}`;
  const QR_PHARMACY_PORTAL = [
    {
      qr: isRME ? urlPharmacy : 'https://ucchristus.cl/',
      border: BORDER_TOP,
      alignment: 'right',
      fit: 55,
      margin: [0, 5, 0, 0],
    },
    {
      text: isRME ? `Portal Farmacia` : 'Portal UC',
      alignment: 'right',
      fontSize: 6,
    },
  ];
 

  const SIGN: any[] = [];
  let SIGNATURE_IMAGE = String(SIGN_DEFAULT).split(',')[1];
  const signature = this.authService?.professional?.signature;
  if (layoutDef.notFooter) return;
  if (signature && signature.isActive && signature.attachment?.key && !ignoreSigns) {
   this.storageService.getObjectBase64ToS3(signature.attachment).subscribe(resp => SIGNATURE_IMAGE = resp)
  }

  if (valuesToSave.professional.signature) {
    const SIGN_ATT = await this.storageService.getObjectBase64ToS3DAU(valuesToSave.professional.signature.attachment);
    if (SIGN_ATT) SIGNATURE_IMAGE = SIGN_ATT;
  }

  if (!ignoreSigns){
    SIGN.push([
      { border: NO_BORDER, text: `` },
      { border: NO_BORDER, text: `` },
      {
        border: NO_BORDER,
        image: `data:image/png;base64,${SIGNATURE_IMAGE}`,
        alignment: 'center',
        maxHeight: 75,
        maxWidth: 150,
        width: 150,
        absolutePosition: { x: 350, y: -45 },
      },
    ]);

  }
   
  let PROFESSIONAL_SING: any = LINE_EMPTY;
  let QR_SHORT_ID: any = LINE_EMPTY;
  if (isSigned && !containSigns)
    PROFESSIONAL_SING = [
      layoutDef.signatureAlone
        ? CELL_EMPTY
        : { border: NO_BORDER, fontSize: 9, alignment: 'left', text: `\n Documento creado: \n ${CREATE_AT}` },
      CELL_EMPTY,
      {
        border: BORDER_TOP,
        style: 'singProfessional',
        alignment: 'right',
        margin: [0, 5, 0, 0],
        stack: [
          { text: `${valuesToSave.professional.names.toUpperCase()}`, alignment: 'center' },
          { text: `${valuesToSave.professional.surnames.toUpperCase()}`, alignment: 'center' },
          { text: `${valuesToSave.professional.rut}`, alignment: 'center' },
        ],
      },
    ];

  if (valuesToSave.shortId) {

    const portal = valuesToSave.shortId.includes('RME') ? 'farmacia/checkout' : 'validacion';
    const url = `https://${APP_DOMAIN}${DOMAIN}/${portal}/${valuesToSave.shortId}`;
    QR_SHORT_ID = [
      { qr: url, border: BORDER_TOP, alignment: 'left', fit: 55, margin: [0, 5, 0, 0] },
      { text: `Código Verificación: ${valuesToSave.shortId}`, alignment: 'left', fontSize: 6 },
    ];
  }
  if (layoutDef.signatureAlone) {
    SIGN.push(PROFESSIONAL_SING);
    return [
      {
        margin: [40, 10, 40, 0],
        table: {
          widths: ['*', 'auto', 150],
          body: [...SIGN],
        },
      },
    ];
  }
  if (!layoutDef.hideFooter) {
    SIGN.push(PROFESSIONAL_SING);

    return [
      {
        margin: [40, 10, 40, 0],
        table: {
          widths: ['*', 'auto', 150],
          body: [
            ...SIGN,
            // PROFESSIONAL_SING,
            [
              { border: BORDER_TOP, stack: [{ image: PHONES_IMG, alignment: 'left', width: 70 }, QR_SHORT_ID] },
              { border: BORDER_TOP, text: ``, alignment: 'right' },
              { border: BORDER_TOP, stack: [{ image: UC_IMG, alignment: 'right', width: 70 }, QR_PHARMACY_PORTAL] },
            ],
          ],
        },
      },
    ];
  }

  if (layoutDef.consentFooter) {
    PROFESSIONAL_SING = [
      { border: NO_BORDER, fontSize: 9, alignment: 'left', text: `\n Documento creado: \n ${CREATE_AT}` },
      CELL_EMPTY,
      {
        border: BORDER_TOP,
        style: 'singProfessional',
        alignment: 'right',
        margin: [0, 5, 0, 0],
        stack: [{ text: `Firma Médico/Odontólogo Informante`, alignment: 'center' }],
      },
    ];
    SIGN.push(PROFESSIONAL_SING);

    return [
      {
        margin: [40, 10, 40, 0],
        table: {
          widths: ['*', 'auto', 150],
          body: [
            ...SIGN,
            // PROFESSIONAL_SING,
            [
              { border: BORDER_TOP, stack: [{ image: PHONES_IMG, alignment: 'left', width: 70 }, QR_SHORT_ID] },
              { border: BORDER_TOP, text: ``, alignment: 'right' },
              { border: BORDER_TOP, stack: [{ image: UC_IMG, alignment: 'right', width: 70 }, QR_PHARMACY_PORTAL] },
            ],

            
          ],
          
        },
        
      },
    ];
  }
}

}
