import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-frm-diagnostic',
  templateUrl: './frm-diagnostic.component.html',
  styleUrls: ['./frm-diagnostic.component.scss'],
})
export class FrmDiagnosticComponent implements OnInit {
  @Input() selected;
  frmDiagnostic: UntypedFormGroup;

  tag: string;
  code: string;
  descriptor: string;
  constructor(private formBuilder: UntypedFormBuilder, private modalController: ModalController) {
    this.buildForm();
  }

  ngOnInit() {
    if (!this.selected) return;
    const tag = this.selected.tag;

    if (tag === 'CIAP-2') {
      this.tag = tag;
      this.code = this.selected.code;
      this.descriptor = this.selected.descriptor;
    }
  }

  add() {
    this.selected.observations = this.frmDiagnostic.get('observations').value;
    this.dismiss(this.selected);
  }

  buildForm() {
    this.frmDiagnostic = this.formBuilder.group({
      observations: [''],
    });
  }

  dismiss(value: any) {
    if (!value) this.modalController.dismiss();
    this.modalController.dismiss(value);
  }
}
