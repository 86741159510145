import {HttpErrorResponse} from '@angular/common/http';
import {Email} from '@shared/interfaces/notification.interface';
import {Metadata} from '@shared/interfaces/request.interface';
import {createAction, props} from '@ngrx/store';

/* Envio de correo electrónico */
export const SEND_EMAIL = createAction(
  '[notificaciones] Envio de Correo Electronico',
  props<{ paramsToSendMail: Email }>(),
);

export const SEND_EMAIL_OK = createAction(
  '[notificaciones] OK Envio de Correo Electronico',
  props<{ metadata: Metadata }>(),
);

export const SEND_EMAIL_ERR = createAction(
  '[notificaciones] ERR Envio de Correo Electronico',
  props<{ payload: HttpErrorResponse }>(),
);

export const CLEAR_EMAIL       = createAction('[notificaciones] Limpiar Correo Electronico');
export const NOT_SENDING_EMAIL = createAction(
  '[notificaciones] Informar el NO Envio de Email',
  props<{ message: string }>(),
);
