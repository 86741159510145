import { buildContentPdfRadiotherapy } from '@clinical/oncology/services/build-pdf-radioteraphy';
import { buildContentPdfTac } from '@clinical/oncology/services/build-pdf-tac';
import { buildContentPdfRoadmap } from '@clinical/oncology/services/build-pdf-roadmap';
import { DEFAULT_PDF_POSITION_SIGNATURE } from '@shared/constants/app.const';
import { PrintedOnPage, PrintMode } from '@shared/interfaces/paperwork.interface';
import { DELETE_ONCOLOGY, PUT_ONCOLOGY } from '@store/actions';
import { ONCOLOGY_ROUTES } from '@clinical/oncology/oncology.routes.module';

const workflow = {
  isSigned: true,
  isStored: true,
  isSaving: true,
  hasNextControl: true,
  notifications: [
    {
      type: 'email',
      entity: 'patient',
      subject: 'Notificaciones UC CHRISTUS | Formulario Médico',
      template: 'generic',
    },
  ],
};

const pdf = {
  isPreview: true,
  header: { logo: PrintedOnPage.last, title: PrintedOnPage.all, professional: PrintedOnPage.all },
  patient: { type: PrintMode.full, printed: PrintedOnPage.all },
  footer: {
    signature: { printed: PrintedOnPage.all, position: DEFAULT_PDF_POSITION_SIGNATURE },
    qr: { right: { url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web' } },
  },
};

export const PAPERWORK_ONCOLOGY_DEFINITION = {
  prefix: 'ONC',
  module: 'oncologia',
  actions: {
    put: PUT_ONCOLOGY,
    delete: DELETE_ONCOLOGY,
  },
  tac: {
    suffix: 'TAC',
    group: 'orden medica tac de simulación',
    docTitle: 'orden medica tac de simulación',
    submodule: ONCOLOGY_ROUTES.tac,
    getBodyDefinition: buildContentPdfTac,
    workflow: {
      ...workflow,
      notifications: [
        {
          type: 'email',
          entity: 'patient',
          subject: 'Notificaciones UC CHRISTUS | Formulario Médico',
          template: 'generic',
        },
        {
          type: 'email',
          entity: 'nurses',
          subject: 'Notificaciones UC CHRISTUS | Formulario Médico',
          template: 'generic',
        },
      ],
    },
    config: pdf,
  },

  radioterapia: {
    suffix: 'RTP',
    group: 'indicación de radioterapia para presupuesto',
    docTitle: 'indicación de radioterapia para presupuesto',
    submodule: ONCOLOGY_ROUTES.radiotherapy,
    getBodyDefinition: buildContentPdfRadiotherapy,
    workflow,
    config: pdf,
  },

  roadmap: {
    suffix: 'RPO',
    group: 'ruta para pacientes oncológicos',
    docTitle: 'ruta para pacientes oncológicos',
    submodule: ONCOLOGY_ROUTES.roadmap,
    getBodyDefinition: buildContentPdfRoadmap,
    workflow: {
      ...workflow,
      notifications: [{
        type: 'email',
        entity: 'patient',
        subject: 'Notificaciones UC CHRISTUS | Ruta para Pacientes Oncológicos',
        template: 'generic',
      }],
    },
    config: pdf,
  },
};
