import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on } from '@ngrx/store';
import { MESSAGES } from '@shared/constants/notifications.const';
import { Paperwork, PaperworkDefinition } from '@shared/interfaces/paperwork.interface';
import { FileToStorage } from '@shared/interfaces/storage.interface';

import {
  ATTACH_FILE,
  CLEAR_PAPERWORK,
  DETACH_FILE,
  GET_PRESIGNED_URL_ERR,
  GET_PRESIGNED_URL_OK,
  NEXT_CONTROL_ERR,
  NEXT_CONTROL_OK,
  PAPERWORK,
  PAPERWORK_ERR,
  PAPERWORK_OK,
  PPW_SEND_EMAIL_ERR,
  PPW_SEND_EMAIL_EMPTY,
  PPW_SEND_EMAIL_OK,
  SAVE_RECORD_ERR,
  SAVE_RECORD_OK,
  SEND_FORM,
  SET_NEXT_CONTROL,
  SIGNATURE_ERR,
  SIGNATURE_OK,
  SKIP_NEXT_CONTROL,
  SKIP_SAVE_RECORD,
  SKIP_SEND_EMAIL,
  SKIP_SIGNATURE,
  SKIP_STORAGE,
  STORAGE_ERR,
  STORAGE_OK,
  UPDATE_PAPERWORK_PATIENT,
} from '@store/actions';

export interface PaperworkState {
  progress: number;
  status: StatusState;
  isLoaded: boolean;
  isLoading: boolean;
  error: HttpErrorResponse | string;
  files: FileToStorage[];
  paperwork: Paperwork;
  definition: PaperworkDefinition;
}

interface StatusState {
  label: string;
  icon: string;
  message: string;
  color: string;
}

export const paperworkState: PaperworkState = {
  error: null,
  files: [],
  progress: 0.0,
  status: null,
  paperwork: null,
  isLoaded: false,
  isLoading: false,
  definition: null,
};

const createReducerUi = createReducer(
  paperworkState,

  on(PAPERWORK, (state, { action }) => ({ ...state, action, isLoading: true })),

  on(PAPERWORK_OK, (state, { definition, paperwork }) => ({
    ...state,
    definition: { ...definition },
    paperwork: { ...paperwork },
  })),

  on(PAPERWORK_ERR, (state, { error }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    paperwork: null,
    error,
    content: null,
  })),

  on(ATTACH_FILE, (state, { documents }) => ({
    ...state,
    files: [...state.files, ...documents],
  })),

  on(DETACH_FILE, (state, { index }) => ({
    ...state,
    files: [...state.files.splice(index, 1)],
  })),

  on(UPDATE_PAPERWORK_PATIENT, (state, { patient, paperwork }) => ({
    ...state,
    paperwork: {
      ...paperwork,
      patient: {
        ...paperwork.patient,
        ...patient,
      },
    },
    status: {
      message: MESSAGES.success.updatedPatient,
      color: MESSAGES.info.color,
      icon: MESSAGES.success.icon,
      label: MESSAGES.info.label,
    },
  })),

  on(SEND_FORM, (state) => ({ ...state })),

  on(SET_NEXT_CONTROL, (state, { nextControl }) => ({
    ...state,
    paperwork: {
      ...state.paperwork,
      nextControl
    }
   })),

  on(SKIP_NEXT_CONTROL, (state) => ({ ...state, progress: 0.1 })),

  on(NEXT_CONTROL_OK, (state) => ({
    ...state,
    progress: 0.1,
    status: {
      message: MESSAGES.success.nextControl,
      color: MESSAGES.info.color,
      icon: MESSAGES.success.icon,
      label: MESSAGES.info.label,
    },
  })),

  on(NEXT_CONTROL_ERR, (state, { error }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: { ...error },
    status: {
      message: MESSAGES.error.nextControl,
      color: MESSAGES.error.color,
      icon: MESSAGES.error.icon,
      label: MESSAGES.error.label,
    },
  })),

  on(SKIP_SIGNATURE, (state) => ({ ...state, progress: 0.2, isLoading: true })),

  on(SIGNATURE_OK, (state) => {
    const config = { ...state.definition.submodule.config, isPreview: false };
    const submodule = { ...state.definition.submodule, config };
    return {
      ...state,
      progress: 0.2,
      isLoading: true,
      status: {
        //TODO phernandezr este codigo se repite mucho
        message: MESSAGES.success.signPDF,
        color: MESSAGES.info.color,
        icon: MESSAGES.success.icon,
        label: MESSAGES.info.label,
      },
      definition: { ...state.definition, submodule },
      paperwork: { ...state.paperwork },
    };
  }),

  on(SIGNATURE_ERR, (state) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: MESSAGES.error.signPDF,
    status: {
      message: MESSAGES.error.signPDF,
      color: MESSAGES.error.color,
      icon: MESSAGES.error.icon,
      label: MESSAGES.error.label,
    },
  })),

  on(SKIP_STORAGE, (state) => ({ ...state, progress: 0.4 })),

  on(GET_PRESIGNED_URL_OK, (state, { documents }) => ({
    ...state,
    progress: 0.4,
    status: {
      message: MESSAGES.success.presignedURL,
      color: MESSAGES.info.color,
      icon: MESSAGES.success.icon,
      label: MESSAGES.info.label,
    },
    paperwork: { ...state.paperwork },
    files: [...documents],
  })),

  on(GET_PRESIGNED_URL_ERR, (state, { error }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: { ...error },
    status: {
      message: MESSAGES.error.presignedURL,
      color: MESSAGES.error.color,
      icon: MESSAGES.error.icon,
      label: MESSAGES.error.label,
    },
  })),

  on(STORAGE_OK, (state, { attachments }) => ({
    ...state,
    progress: 0.6,
    paperwork: { ...state.paperwork, attachments },
    status: {
      message: MESSAGES.success.storageS3,
      color: MESSAGES.info.color,
      icon: MESSAGES.success.icon,
      label: MESSAGES.info.label,
    },
  })),

  on(STORAGE_ERR, (state, { error }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: { ...error },
    status: {
      message: MESSAGES.error.storageS3,
      color: MESSAGES.error.color,
      icon: MESSAGES.error.icon,
      label: MESSAGES.error.label,
    },
  })),

  on(SKIP_SAVE_RECORD, (state) => ({ ...state, progress: 0.8 })),

  on(SAVE_RECORD_OK, (state, { paperwork }) => ({
    ...state,
    progress: 0.8,
    status: {
      message: MESSAGES.success.saveInDB,
      color: MESSAGES.info.color,
      icon: MESSAGES.success.icon,
      label: MESSAGES.info.label,
    },
    paperwork: { ...paperwork },
  })),

  on(SAVE_RECORD_ERR, (state, { error }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: { ...error },
    status: {
      message: MESSAGES.error.saveInDB,
      color: MESSAGES.error.color,
      icon: MESSAGES.error.icon,
      label: MESSAGES.error.label,
    },
  })),

  on(SKIP_SEND_EMAIL, (state) => ({
    ...state,
    progress: 1,
    isLoading: false,
    isLoaded: true,
    status: {
      message: MESSAGES.success.end,
      color: MESSAGES.success.color,
      icon: MESSAGES.success.icon,
      label: MESSAGES.success.label,
    },
  })),

  on(PPW_SEND_EMAIL_OK, (state) => ({
    ...state,
    progress: 1,
    isLoading: false,
    isLoaded: true,
    status: {
      message: MESSAGES.success.sendMail,
      color: MESSAGES.success.color,
      icon: MESSAGES.success.icon,
      label: MESSAGES.success.label,
    },
  })),

  on(PPW_SEND_EMAIL_EMPTY, (state) => ({
    ...state,
    progress: 1,
    isLoading: false,
    isLoaded: true,
    status: {
      message: MESSAGES.success.sendMailEmpty,
      color: MESSAGES.success.color,
      icon: MESSAGES.success.icon,
      label: MESSAGES.success.label,
    },
  })),

  on(PPW_SEND_EMAIL_ERR, (state, { error }) => ({
    ...state,
    error: { ...error },
    isLoading: false,
    isLoaded: false,
    status: {
      message: MESSAGES.error.sendMail,
      color: MESSAGES.error.color,
      icon: MESSAGES.error.icon,
      label: MESSAGES.error.label,
    },
  })),

  on(CLEAR_PAPERWORK, (state) => ({
    error: null,
    files: [],
    progress: 0.0,
    status: null,
    paperwork: null,
    isLoaded: false,
    isLoading: false,
    definition: null,
  })),
);

export const paperworkReducer = (state: PaperworkState, action: Action) => createReducerUi(state, action);
