import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {AlertController, ModalController, ToastController} from '@ionic/angular';

import {Store} from '@ngrx/store';
import {AppState} from 'src/app/store/app.reducers';
import {KEY_PROFESSIONAL_STORE} from '@store/store-keys';
import {
  DELETE_DERIVATION,
  DELETE_DISCHARGE_MEDICAL,
  DELETE_FORM,
  DELETE_HOSPITALIZATION,
  DELETE_INDICATION,
  DELETE_ONCOLOGY,
  DELETE_RECIPE,
  DELETE_REPRODUCTIVE,
  PAPERWORK_OK,
  SELECT_INDICATION,
  SELECTED_DERIVATION,
  SELECTED_DISCHARGE_MEDICAL,
  SELECTED_EMERGENCY,
  SELECTED_EXPERTISE,
  SELECTED_FORM,
  SELECTED_HOSPITALIZATION,
  SELECTED_ONCOLOGY,
  SELECTED_RECIPE,
  SELECTED_REPRODUCTIVE,
} from '@store/actions';

import {PageTableDef} from '@shared/interfaces/table.interface';
import {ReportModalComponent} from '../report-modal/report-modal.component';
import {PatientReportComponent} from '../patient-report/patient-report.component';
import {AuthService} from '@auth/services/auth.service';
import {ScheduleService} from '@components/agenda/services/agenda.service';
import {UiService} from '@shared/services/ui.service';
import {ActionsResolutionsService} from './actions-resolutions.service';
import {ModalReasonRejectComponent} from '../modal-reason-reject/modal-reason-reject.component';
import {ModalViewsImagesComponent} from '../modal-views-images/modal-views-images.component';
import {ReportModalMultyComponent} from '@components/records/report-modal-multy/report-modal-multy.component';
import {ROOT_ROUTES} from '../../../app-routing.module';
import {CLINICAL_ROUTES} from '@clinical/clinical-routes.module';
import {PAPERWORK_RECIPES_DEFINITION} from '@clinical/medicines/constants/paperwork-recipes.const';
import {PAPERWORK_DERIVATIONS_DEFINITION} from '@clinical/derivations/constants/paperwork-derivations.const';
import {PAPERWORK_FORMS_DEFINITION} from '@clinical/forms/constants/paperwork-forms.const';
import {PAPERWORK_HOSPITAL_DEFINITION} from '@clinical/hospital/constants/paperwork-hospital.const';
import {PAPERWORK_INDICATIONS_DEFINITION} from '@clinical/indications/constants/paperwork-indications.const';
import {PAPERWORK_ONCOLOGY_DEFINITION} from '@clinical/oncology/constants/paperwork-oncology.const';
import {PAPERWORK_REPRODUCTIVE_DEFINITION} from '@clinical/reproductive/constants/papework-reproductive.const';
import {PAPERWORK_EMERGENCIES_DEFINITION} from '@clinical/emergencies/constants/paperwork-emergencies.const';
import {PAPERWORK_EXPERTISE_DEFINITION} from '@clinical/expertise/constants/papework-expertise.const';

@Injectable({
  providedIn: 'root',
})
export class ActionsService {
  isLoading = false;
  professionalId;
  sk;
  isLoadedProfessional: boolean;
  isSearchProfessional: boolean;
  error;
  routesByGroup: { group: string; path: string }[] = [];

  constructor(
    public router: Router,
    public uiService: UiService,
    public store: Store<AppState>,
    public authService: AuthService,
    public modalController: ModalController,
    public scheduleService: ScheduleService,
    public toastController: ToastController,
    public alertController: AlertController,
    public actionsResolutions: ActionsResolutionsService,
  ) {
    this.readStoreProfessional();
  }

  readStoreProfessional(): void {
    this.store.select(KEY_PROFESSIONAL_STORE).subscribe(({professional, isLoading, isLoaded, error}) => {
      this.professionalId = professional?.id;
      this.isLoadedProfessional = isLoading;
      this.isSearchProfessional = isLoaded;
      this.error = error;
    });
  }

  redirecTo(row: any, pageTable: PageTableDef) {
    this.router.navigateByUrl(pageTable.router);
  }

  async openPreviewIMG(row: any, pageTable: PageTableDef) {
    const modal = await this.modalController.create({
      component: ModalViewsImagesComponent,
      cssClass: 'modal-view-img',
      componentProps: {data: row.data, pageTable},
    });
    await modal.present();
  }

  async openPreviewPDF(row: any, pageTable: PageTableDef) {
    const modal = await this.modalController.create({
      component: ReportModalComponent,
      cssClass: 'modal-xxl-float-left',
      componentProps: {data: row.data, pageTable},
    });
    await modal.present();
  }

  async editForm(row: any, pageTable: PageTableDef) {
    const modal = await this.modalController.create({
      component: ReportModalMultyComponent,
      cssClass: 'modal-xxl-float-right',
      componentProps: {data: row, pageTable},
    });
    await modal.present();
  }

  async openModalReasonReject(row: any, pageTable: PageTableDef) {
    const {title} = pageTable;
    const modal = await this.modalController.create({
      component: ModalReasonRejectComponent,
      cssClass: 'modal-md',
      componentProps: {data: row, title},
    });
    await modal.present();
  }

  async viewPatient(row: any, pageTable: PageTableDef) {
    delete pageTable.title;
    const modal = await this.modalController.create({
      component: PatientReportComponent,
      cssClass: 'cmodal-xl',
      componentProps: {data: row.data, pageTable},
    });
    await modal.present();
  }

  redirectAndSet(row: any, pageTable: PageTableDef) {

    const content = row.data.content || this.getDeprecateContent(row);
    const [firstContentItem] = content;
    if (firstContentItem.group === 'detalle urgencia') {
      this.store.dispatch(SELECTED_EMERGENCY({selected: row.emergency}));
      this.router.navigateByUrl('app-pcs/dau/detalles/atencion/urgencias');
    }

    const route = this.getRouteByGroup(firstContentItem?.group);
    if (!route) return;

    const paperwork: any = {content};
    this.store.dispatch(PAPERWORK_OK({paperwork, definition: null}));
    this.router.navigateByUrl(route);
  }

  editIndication(row: any, pageTable: PageTableDef) {
    const action = 'edit';
    const {type} = pageTable;
    const content = row.data.content || this.getDeprecateContent(row.data);
    const [firstContentItem] = content;
    const route = this.getRouteByGroup(firstContentItem?.group);
    if (!route || !type) return;
    if (type === 'receta') this.store.dispatch(SELECTED_RECIPE({selected: row.data, action}));
    if (type === 'derivación') this.store.dispatch(SELECTED_DERIVATION({selected: row.data, action}));
    if (type === 'formulario') this.store.dispatch(SELECTED_FORM({selected: row.data, action}));
    if (type === 'indicacion') this.store.dispatch(SELECT_INDICATION({selected: row.data, action}));
    if (type === 'hospitalizacion') this.store.dispatch(SELECTED_HOSPITALIZATION({selected: row.data, action}));
    if (type === 'oncologia') this.store.dispatch(SELECTED_ONCOLOGY({selected: row.data, action}));
    if (type === 'medicina') this.store.dispatch(SELECTED_REPRODUCTIVE({selected: row.data, action}));
    if (type === 'urgencia') this.store.dispatch(SELECTED_EMERGENCY({selected: row.emergency}));
    if (type === 'peritaje') this.store.dispatch(SELECTED_EXPERTISE({selected: row.data, action}));
    if (type === 'altamedica') this.store.dispatch(SELECTED_DISCHARGE_MEDICAL({selected: row.data, action}));

    this.store.dispatch(PAPERWORK_OK({paperwork: row.data, definition: null}));
    this.router.navigateByUrl(route);
  }

  async deleteIndication(row: any, pageTable: PageTableDef) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Eliminar',
      message: '¿Esta seguro de eliminar este registro?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
        },
        {
          text: 'Eliminar',
          id: 'confirm-button',
          handler: () => {
            if (pageTable.type === 'receta') this.store.dispatch(DELETE_RECIPE(row.data));
            if (pageTable.type === 'derivación') this.store.dispatch(DELETE_DERIVATION(row.data));
            if (pageTable.type === 'formulario') this.store.dispatch(DELETE_FORM(row.data));
            if (pageTable.type === 'indicacion') this.store.dispatch(DELETE_INDICATION(row.data));
            if (pageTable.type === 'hospitalizacion') this.store.dispatch(DELETE_HOSPITALIZATION(row.data));
            if (pageTable.type === 'oncologia') this.store.dispatch(DELETE_ONCOLOGY(row.data));
            if (pageTable.type === 'medicina') this.store.dispatch(DELETE_REPRODUCTIVE(row.data));
            if (pageTable.type === 'altamedica') this.store.dispatch(DELETE_DISCHARGE_MEDICAL(row.data));
          },
        },
      ],
    });

    await alert.present();
  }

  //Funciones para el portal ejecutivas - Resoluciones
  changeState(row) {
    this.actionsResolutions.changeState(row);
  }

  reject(row) {
    this.actionsResolutions.reject(row);
  }

  reserveRequest(row) {
    this.actionsResolutions.reserveRequest(row);
  }

  /**
   * Obtiene nodo deprecado de contenido.
   * Usar en caso de no existir `data.content`
   *
   * @param data
   */
  private getDeprecateContent(row: any) {
    return row.prescriptions?.data || row.exams?.data || row.forms?.data || row.emergency.content;
  }

  /**
   * Identifica grupos de tramitación y su ruta
   */
  private setRoutesByGroup(): { group: string; path: string }[] {
    const paperworkDefinitions = [
      {definition: PAPERWORK_DERIVATIONS_DEFINITION, path: `${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.derivations}`},
      {definition: PAPERWORK_FORMS_DEFINITION, path: `${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.forms}`},
      {definition: PAPERWORK_HOSPITAL_DEFINITION, path: `${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.hospitalizations}`},
      {definition: PAPERWORK_INDICATIONS_DEFINITION, path: `${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.indications}`},
      {definition: PAPERWORK_RECIPES_DEFINITION, path: `${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.medicines}`},
      {definition: PAPERWORK_EXPERTISE_DEFINITION, path: `${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.expertise}`},
      {definition: PAPERWORK_ONCOLOGY_DEFINITION, path: `${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.oncology}`},
      {definition: PAPERWORK_REPRODUCTIVE_DEFINITION, path: `${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.reproductive}`},
      {definition: PAPERWORK_EMERGENCIES_DEFINITION, path: `${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.dau}`},
    ];
    this.routesByGroup = paperworkDefinitions.map(module => {
      const definedGroups = [];
      for (const submodule in module.definition)
        if (module.definition?.[submodule]?.group)
          definedGroups.push({
            group: module.definition[submodule].group,
            path: `${module.path}/${module.definition[submodule].submodule}`,
          });

      return definedGroups;
    }).flat();

    return this.routesByGroup;
  }

  private getRouteByGroup(group: string): string {

    let comparableGroupName = group.toLowerCase();
    if (group === group.toUpperCase() && group.includes('PROCEDIMIENTOS')) {
      comparableGroupName = 'PROCEDIMIENTOS IMAGENES LABORATORIO'.toLowerCase();
    }

    if (!this.routesByGroup.length) this.setRoutesByGroup();

    const submodule = this.routesByGroup.find(sm =>
      sm.group.toLowerCase() === comparableGroupName || sm.group.toLowerCase().includes(comparableGroupName)
    );

    return submodule?.path;
  }
}
