import {HttpErrorResponse} from '@angular/common/http';
import {PresignedUrlResponse, PresignedUrlRequest} from '@shared/interfaces/storage.interface';
import {createAction, props} from '@ngrx/store';

/* Crear url prefirmada con permisos para subir documentos a S3 */
export const CREATE_PRESIGNED_URL     = createAction(
  '[almacenamiento] Prefirmar URL',
  props<{ params: PresignedUrlRequest }>(),
);
export const CREATE_PRESIGNED_URL_OK  = createAction(
  '[almacenamiento] OK Prefirmar URL',
  props<{ signedUrl: PresignedUrlResponse }>(),
);
export const CREATE_PRESIGNED_URL_ERR = createAction(
  '[almacenamiento] ERR Prefirmar URL',
  props<{ payload: HttpErrorResponse }>(),
);

/* Crear url prefirmada con permisos para subir documentos a S3 */
export const CREATE_LIST_PRESIGNED_URL     = createAction('[almacenamiento] Lista URLs Prefirmadas');
export const CREATE_LIST_PRESIGNED_URL_OK  = createAction(
  '[almacenamiento] OK Lista URLs Prefirmadas',
  props<{ signedUrls: PresignedUrlResponse[] }>(),
);
export const CREATE_LIST_PRESIGNED_URL_ERR = createAction(
  '[almacenamiento] ERR Lista URLs Prefirmadas',
  props<{ payload: HttpErrorResponse }>(),
);

/* Subir documentos a S3 */
export const PUT_OBJECT_S3     = createAction('[almacenamiento] Subir Objeto S3', props<{ url: string; docFile: any }>());
export const PUT_OBJECT_S3_OK  = createAction('[almacenamiento] OK Subir Objeto S3', props<{ progress: number }>());
export const PUT_OBJECT_S3_ERR = createAction(
  '[almacenamiento] ERR Subir Objeto S3',
  props<{ payload: HttpErrorResponse }>(),
);
