import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { CLEAR_PAPERWORK } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { PaperworkState } from '@store/reducers/paperwork.reducer';
import { KEY_PAPERWORK_STORE } from '@store/store-keys';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-btn-modal-close',
  templateUrl: './btn-modal-close.component.html',
  styleUrls: ['./btn-modal-close.component.scss'],
})
export class BtnModalCloseComponent implements OnInit {

  paperwork$: Observable<PaperworkState>;

  constructor( private store: Store<AppState>, private modalController: ModalController ) {}

  ngOnInit(): void {
      this.paperwork$ = this.store.select(KEY_PAPERWORK_STORE);
  }

  close(state: PaperworkState){
    if(state.isLoaded) this.store.dispatch(CLEAR_PAPERWORK());
    this.modalController.dismiss();
  }
}
