import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataUrlToBase64',
})
export class DataUrlToBase64Pipe implements PipeTransform {
  transform(dataUrl: string): any {
    const START_PDF_DATA_URL = 'data:application/pdf;base64,';

    if (dataUrl && dataUrl.includes(START_PDF_DATA_URL)) return dataUrl.replace(START_PDF_DATA_URL, '');
    return '';
  }
}
