export const TAG = [
    {
      name     : 'Anestesiología',
      id       : '70b34339-9831-434c-9424-ae9a015c31ea',
      isChecked: false,
    },
    {
      name     : 'Broncopulmonar Adultos',
      id       : 'bd9e8c4b-77fc-4e85-96b0-ae9a015c501b',
      isChecked: false,
    },
    {
      name     : 'Broncopulmonar Pediátrico',
      id       : 'de52c7e1-cc4f-40d8-87d5-ae9a015eb278',
      isChecked: false,
    },
    {
      name     : 'Cardiología Adultos',
      id       : '1bc866b1-60f5-4c71-8488-ac7600debde7',
      isChecked: false,
    },
    {
      name     : 'Cardiología Pediátrica',
      id       : 'd8793673-f51a-4a82-8d20-ae9a015ec15e',
      isChecked: false,
    },
    {
      name     : 'Cirugía Cardiaca',
      id       : 'c2f65527-96ea-4d71-a106-ae9a015c6dfb',
      isChecked: false,
    },
    {
      name     : 'Cirugía Coloproctológica',
      id       : '0be299cd-8987-4854-a9e7-ae9a015c7eca',
      isChecked: false,
    },
    {
      name     : 'Cirugía Digestiva',
      id       : '172e8dc1-ba07-4cd3-919f-ae9a015c8985',
      isChecked: false,
    },
    {
      name     : 'Cirugía Máxilo Facial',
      id       : '776c9f26-9e37-4067-89bf-ae9a015ca0b3',
      isChecked: false,
    },
    {
      name     : 'Cirugía Oncológica',
      id       : '74ad0ee1-083d-427b-8c16-ae9a015cc3c0',
      isChecked: false,
    },
    {
      name     : 'Cirugía Pediátrica',
      id       : '1e37ac3d-2471-48c1-8558-ae9a015ce606',
      isChecked: false,
    },
    {
      name     : 'Cirugía Plástica Y Reparadora',
      id       : '0cb87fb8-3725-4fc5-91e7-ae9a015d0eb1',
      isChecked: false,
    },
    {
      name     : 'Cirugía Tórax',
      id       : '5900c4a7-5c90-4c59-b91f-ae9a015d2a16',
      isChecked: false,
    },
    {
      name     : 'Cirugía Vascular Y Endovascular',
      id       : '3bb0500e-79d5-45c7-8896-ae9a015d3cde',
      isChecked: false,
    },
    {
      name     : 'Dermatología',
      id       : '461dd850-639e-4de5-9396-ac920127471c',
      isChecked: false,
    },
    {
      name     : 'Endocrinología Adultos',
      id       : 'b0ed4f68-4e42-495e-80f8-ae9a015d5153',
      isChecked: false,
    },
    {
      name     : 'Endocrinología Pediátrica',
      id       : '0021994c-c0e2-4614-8c3d-ae9a015ece9d',
      isChecked: false,
    },
    {
      name     : 'Enfermería',
      id       : '46286dc5-f3a4-40c1-9dbe-ae9a015d61d7',
      isChecked: false,
    },
    {
      name     : 'Fonoaudiología',
      id       : 'f1e2180e-04f7-4fef-ae11-ae9a015d6d49',
      isChecked: false,
    },
    {
      name     : 'Gastroenterología Adultos',
      id       : 'd922ef6e-fcc3-4529-b6ec-ae9a015d7a40',
      isChecked: false,
    },
    {
      name     : 'Gastroenterología Pediátrica',
      id       : '548d02cc-431d-4258-b6b4-ae9a015ee03b',
      isChecked: false,
    },
    {
      name     : 'Genética Clínica',
      id       : '3e140b41-4209-42a9-9958-ae9a015d910c',
      isChecked: false,
    },
    {
      name     : 'Geriatría',
      id       : 'e8cf74ee-0a00-4a3e-98d4-ac08016a2bc6',
      isChecked: false,
    },
    {
      name     : 'Ginecología Y Obstetricia',
      id       : '15b9d6f3-91fd-4f76-991d-ac0001273b20',
      isChecked: false,
    },
    {
      name     : 'Hematología Adultos',
      id       : '34dadfc4-1737-4080-b0b2-ae9a015db12b',
      isChecked: false,
    },
    {
      name     : 'Hematología Pediátrica',
      id       : '1e0dde80-7ed4-40f6-b194-ae9a017b2e6c',
      isChecked: false,
    },
    {
      name     : 'Infectología Adultos',
      id       : 'd766d5f7-5a80-432b-9cd5-ac9301386b28',
      isChecked: false,
    },
    {
      name     : 'Infectología Pediátrica',
      id       : '2786862e-a445-48b7-9074-ae9a015eee7c',
      isChecked: false,
    },
    {
      name     : 'Inmunología Y Alergia Adultos',
      id       : 'fa977e35-c2b1-4876-b1ed-ae9a015dbca1',
      isChecked: false,
    },
    {
      name     : 'Inmunología Y Alergia Pediátrica',
      id       : '4f5bb301-1848-4d2c-a6d8-ae9a015f0050',
      isChecked: false,
    },
    {
      name     : 'Kinesiología',
      id       : '47d67cc3-e664-4d4e-9e05-afb201559bc2',
      isChecked: false,
    },
    {
      name     : 'Matrona',
      id       : 'cbaf65d7-5f69-49f7-bec5-ae9a015de2ed',
      isChecked: false,
    },
    {
      name     : 'Medicina Complementaria',
      id       : '309c6970-fd89-4f1f-990b-ae9a015def69',
      isChecked: false,
    },
    {
      name     : 'Medicina Deportiva',
      id       : 'b679881d-8ca3-405d-b55b-ae9a017b8779',
      isChecked: false,
    },
    {
      name     : 'Medicina Familiar Adultos',
      id       : 'b9c45b6e-2b0a-413a-961d-ac94017d92f4',
      isChecked: false,
    },
    {
      name     : 'Medicina Familiar Del Niño',
      id       : 'ac6c697d-eb40-4186-9aa3-ae9a015e0ff7',
      isChecked: false,
    },
    {
      name     : 'Fisiatría',
      id       : '93b18190-98d4-4537-b7f7-ae9a015e2104',
      isChecked: false,
    },
    {
      name     : 'Medicina General',
      id       : '1bdda952-b786-4850-adcc-ae9a015e2bf4',
      isChecked: false,
    },
    {
      name     : 'Medicina Interna',
      id       : '6fcf0b09-1d53-48eb-be7d-ac920125b1e2',
      isChecked: false,
    },
    {
      name     : 'Nefrología Adultos',
      id       : '3fc8beb5-f0f9-4990-a799-ae9a015e36f9',
      isChecked: false,
    },
    {
      name     : 'Nefrología Pediátrica',
      id       : 'b3dfcd33-db2e-4be7-8b04-ae9a015f0b30',
      isChecked: false,
    },
    {
      name     : 'Neurocirugía',
      id       : '13240cdf-cc7d-4ad2-877f-ae9a015e3ff0',
      isChecked: false,
    },
    {
      name     : 'Neurología Adultos',
      id       : '7524ca5b-76d0-4a34-a124-ae9a015e4e98',
      isChecked: false,
    },
    {
      name     : 'Neurología Pediátrica',
      id       : '61e8276b-4b44-4618-952e-ae9a015f1aaa',
      isChecked: false,
    },
    {
      name     : 'Nutrición Médica Y Diabetología Adultos',
      id       : '7339c363-7b92-4b6f-aaf5-acbf011e9658',
      isChecked: false,
    },
    {
      name     : 'Nutrición Médica Pediátrica',
      id       : 'dc824066-69af-404d-babe-ae9a015f33b0',
      isChecked: false,
    },
    {
      name     : 'Nutricionista',
      id       : '4852bdd0-fced-4915-8a91-ae9a015e6981',
      isChecked: false,
    },
    {
      name     : 'Odontología',
      id       : '115fb9b0-0d05-4045-acbe-ac94017d7956',
      isChecked: false,
    },
    {
      name     : 'Oftalmología',
      id       : '2b1ae582-6983-4b71-928a-ac00013af0c7',
      isChecked: false,
    },
    {
      name     : 'Oncología Adultos',
      id       : 'd2b3c933-b700-449a-b694-ae9a015e885a',
      isChecked: false,
    },
    {
      name     : 'Oncología Pediátrica',
      id       : '13e567e2-6438-4bc0-aad8-ae9a015e91f5',
      isChecked: false,
    },
    {
      name     : 'Otorrinolaringología',
      id       : '3bd5c2ef-dd6b-4b00-b8ef-ac00013b0529',
      isChecked: false,
    },
    {
      name     : 'Pediatría',
      id       : 'bb7b694d-e4fa-470b-a0f8-ac00013ad501',
      isChecked: false,
    },
    {
      name     : 'Psicología',
      id       : '55e3c916-3026-41f0-aa4a-ae9a015f4b30',
      isChecked: false,
    },
    {
      name     : 'Psiquiatría Adultos',
      id       : '328a0f85-973c-4af7-9a69-ae9a015f556e',
      isChecked: false,
    },
    {
      name     : 'Psiquiatría Pediátrica y Adolescente',
      id       : '67267e3f-edc1-4f55-9af4-ae9a015f6351',
      isChecked: false,
    },
    {
      name     : 'Radioterapia',
      id       : 'fe97b88a-c128-4fd3-b559-ae9a015f6c54',
      isChecked: false,
    },
    {
      name     : 'Reumatología Adultos',
      id       : '532ccfbe-02f2-42d5-ae61-ae9a015f7d01',
      isChecked: false,
    },
    {
      name     : 'Reumatología Pediátrica',
      id       : 'e652411f-5f5c-4d59-8e4e-ae9a015f41b0',
      isChecked: false,
    },
    {
      name     : 'Terapia Ocupacional',
      id       : 'ecb4b885-c19b-4987-b56a-ae9a015f88f6',
      isChecked: false,
    },
    {
      name     : 'Traumatología',
      id       : '5650a378-f5b5-4b62-b02b-ac9301370d85',
      isChecked: false,
    },
    {
      name     : 'Urología Adultos',
      id       : '457d3b69-fb8d-4e0c-acfa-ac72012b2d01',
      isChecked: false,
    },
    {
      name     : 'Urología Pediátrica',
      id       : '1d3a0cbf-7410-4367-85d4-ae9a015fa443',
      isChecked: false,
    },
    {
      name     : 'Endodoncia',
      id       : 'bd87b34c-f709-41f8-a212-b1cc014418a5',
      isChecked: false,
    },
    {
      name     : 'Odontopediatria',
      id       : '55f94dcc-c3de-4c4e-8736-b1cc01449a71',
      isChecked: false,
    },
    {
      name     : 'Rehabilitación Oral',
      id       : 'c4ce368c-6109-4bbf-ab5d-b1cc01457edf',
      isChecked: false,
    },
    {
      name     : 'Periodoncia',
      id       : '72997fd2-958a-40d0-8660-b1cc01455e87',
      isChecked: false,
    },
    {
      name     : 'Odontología Pabellón',
      id       : '477caa99-48a6-4859-9517-b1cc014470ce',
      isChecked: false,
    },
    {
      name     : 'Cirugía Maxilo Facial',
      id       : '5d6d8d89-1129-4f17-9360-b1cc0143d103',
      isChecked: false,
    },
    {
      name     : 'Odontología General',
      id       : '115fb9b0-0d05-4045-acbe-ac94017d7956',
      isChecked: false,
    },
    {
      name     : 'Trastornos Temporo Mandibular y dolor orofacial',
      id       : '0b76d373-150d-4311-9e14-b1cc014535e2',
      isChecked: false,
    },
    {
      name     : 'Implantología',
      id       : '4d59c261-27f5-4a7a-8536-b1cc01416fbf',
      isChecked: false,
    },
    {
      name     : 'Ortodoncia',
      id       : 'cfdc35aa-56bd-46c3-9bc7-b1cc0140ec50',
      isChecked: false,
    },
];

export const TAG_SAN_JOAQUIN = [
{
    name     : 'Ortodoncia',
    id       : 'cfdc35aa-56bd-46c3-9bc7-b1cc0140ec50',
    isChecked: false,
},
{
    name     : 'Endodoncia',
    id       : 'bd87b34c-f709-41f8-a212-b1cc014418a5',
    isChecked: false,
},
{
    name     : 'Odontopediatria',
    id       : '55f94dcc-c3de-4c4e-8736-b1cc01449a71',
    isChecked: false,
},
{
    name     : 'Rehabilitación Oral',
    id       : 'c4ce368c-6109-4bbf-ab5d-b1cc01457edf',
    isChecked: false,
},
{
    name     : 'Periodoncia',
    id       : '72997fd2-958a-40d0-8660-b1cc01455e87',
    isChecked: false,
},
{
    name     : 'Odontología Pabellón',
    id       : '477caa99-48a6-4859-9517-b1cc014470ce',
    isChecked: false,
},
{
    name     : 'Cirugía Maxilo Facial',
    id       : '5d6d8d89-1129-4f17-9360-b1cc0143d103',
    isChecked: false,
},
{
    name     : 'Odontología General',
    id       : '115fb9b0-0d05-4045-acbe-ac94017d7956',
    isChecked: false,
},
{
    name     : 'Trastornos Temporo Mandibular y dolor orofacial',
    id       : '0b76d373-150d-4311-9e14-b1cc014535e2',
    isChecked: false,
},
{
    name     : 'Implantología',
    id       : '4d59c261-27f5-4a7a-8536-b1cc01416fbf',
    isChecked: false,
},
{
    name     : 'Oftalmología',
    id       : '2b1ae582-6983-4b71-928a-ac00013af0c7',
    isChecked: false,
},

];

export const BOX_CENTERS = [
'Alcántara',
'Bilbao',
'Clínica San Carlos',
'Clínica San Carlos de Apoquindo',
'Escuela Militar',
'Irarrázaval',
'Lira Piso 2',
'Lira Piso 4',
'Lira Piso 6',
'San Joaquín',
'San Jorge',
'Santa Lucía',
'Santa Lucía (Lira 21)',
];