import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {MarkOnAgenda, Patient, PatientPRM, PatientRef} from '@components/patient/interfaces/patient.interface';
import {PCV2Request} from '@shared/interfaces/request.interface';
import {environment} from '@environments/environment';
import {Store} from '@ngrx/store';
import {Observable, of, Subject, tap} from 'rxjs';
import {catchError, distinctUntilChanged, map, pluck, retry, takeUntil} from 'rxjs/operators';
import {AppState} from 'src/app/store/app.reducers';
import {REGEX_CLEAN_RUT} from '@shared/constants/regex.const';
import {cleanString} from '@shared/helpers/clean-string.helper';
import {GET_PATIENT_OK} from '../store/patient.actions';
import {DateService} from '@shared/services/date.service';
import {UiService} from '@shared/services/ui.service';

const BASE_API: string = environment.baseApiPCv2;

@Injectable({
  providedIn: 'root',
})
export class PatientService implements OnDestroy {
  destroy = new Subject();
  patient$ = new Subject();

  private _isLoading: boolean;
  private _isLoaded: boolean;
  private _error: HttpErrorResponse;
  private _patient: Patient;
  private _reference: PatientRef;

  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>,
    private ui: UiService
  ) {
    this.subscribeToPatientStore();
  }

  ngOnDestroy() {
    this.destroy.next('next');
    this.destroy.complete();
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public get isLoaded(): boolean {
    return this._isLoaded;
  }

  public get error(): HttpErrorResponse {
    return this._error;
  }

  public get patient(): Patient {
    return this._patient;
  }

  public get reference(): PatientRef {
    return this._reference;
  }

  subscribeToPatientStore(): void {
    this.store
        .select('patient')
        .pipe(
          takeUntil(this.destroy),
          tap(({patient, isLoading, isLoaded, error}) => {
            this._patient = patient;
            this._isLoading = isLoading;
            this._isLoaded = isLoaded;
            this._error = error;
            this.patient$.next(patient);
          }),

          distinctUntilChanged((prev, curr) => prev?.patient?.email === curr?.patient?.email),
          tap(({patient}) => {
            const message = ` <p>A partir de ahora, los documentos que se generen estarán asociados a: </br>
                            <b>${patient?.names} ${patient?.surnames}</b> correo electrónico <b>${patient?.email}</b>.
                            </p>`;
            if (patient?.id)
              this.ui.presentToast(message);
          })
        )
        .subscribe();
  }

  updateEmailPhonePatient(email: string, phone: string) {
    const patient = {...this._patient};
    patient.email = email;
    patient.phone = phone;
    this.store.dispatch(GET_PATIENT_OK({patient}));
  }

  getPatientPRM(rutWithoutdash: string, docType: string): Observable<PatientPRM[]> {
    if (!rutWithoutdash) return of(null);
    if (docType === 'n') rutWithoutdash = rutWithoutdash.replace(REGEX_CLEAN_RUT, '');
    const url = encodeURIComponent(`Patients?&$filter=DocumentNumber eq '${rutWithoutdash}'`);
    return this.httpClient.get<PCV2Request>(`${BASE_API}/proxy/v1?sufixURL=${url}`).pipe(
      retry(3),
      pluck('data'),
      map((response) => {
        if (docType === 'none') return response;
        const type = docType === 'n' ? 'NationalId' : 'Passport';
        return response.filter(pat => pat.DocumentType === type);
      }),
    );
  }

  getPatient(rutWithoutdash: string, docType: string): Observable<Patient> {
    return this.getPatientPRM(rutWithoutdash, docType).pipe(
      map(([patient]) => this.formatPatient(patient))
    );
  }

  getPatients(rutWithoutdash: string, docType: string): Observable<Patient[]> {
    return this.getPatientPRM(rutWithoutdash, docType).pipe(
      map((response) => response.map(patient => this.formatPatient(patient)))
    );
  }

  getPatientById(id: string): Observable<Patient> {
    return this.httpClient
               .get<PCV2Request>(`${BASE_API}/proxy/v1?sufixURL=Patients%3F%26%24filter%3DId%20eq%20${id}`)
               .pipe(
                 retry(3),
                 pluck('data'),
                 map(([response]) => response),
                 map(this.formatPatient),
               );
  }

  getPatientByName(name: string): Observable<Patient[]> {
    name = cleanString(name);
    return this.httpClient
               .get<PCV2Request>(`${BASE_API}/proxy/v1?sufixURL=Patients%2FSearchByFullName(Name%3D%40n)%3F%40n%3D'${name}'`)
               .pipe(
                 retry(3),
                 pluck('data'),
                 map(this.patientMap),
                 catchError((_) => of([])),
               );
  }

  getHealthPlans(rut: string) {
    return this.httpClient.get<PCV2Request>(`${BASE_API}/proxy/v1/paciente/plan-salud?rut=${rut}`).pipe(
      retry(3),
      map(response => response.data),
      catchError((_) => of([])),
    );
  }

  getMarkOnAgenda(patientId: string): Observable<MarkOnAgenda[]> {
    return this.httpClient.get<PCV2Request>(`${BASE_API}/proxy/v1/ucc/pacientes/${patientId}/marcas`).pipe(
      retry(3),
      map(response => response.data as MarkOnAgenda[]),
      catchError((_) => of([])),
    );
  }

  patientMap(patients: PatientPRM[]): Patient[] {
    return patients.map((patientPRM) => ({
        id            : patientPRM?.Id,
        names         : patientPRM.FullName.trim(),
        documentNumber: patientPRM?.DocumentNumber,
        documentType  : patientPRM?.DocumentType,
      })
    );
  }

  formatPatient(patientPRM: PatientPRM): Patient {
    if (!patientPRM) return;
    const firstName = patientPRM?.FirstName1 || '';
    const secondName = patientPRM?.FirstName2 || '';
    const names = firstName + ' ' + secondName;
    const lastName1 = patientPRM?.LastName1 || '';
    const lastName2 = patientPRM?.LastName2 || '';
    const surnames = lastName1 + ' ' + lastName2;
    const documentNumber =
            patientPRM?.DocumentType === 'NationalId'
              ? patientPRM?.DocumentNumberFormatted.replace(/\./g, '')
              : patientPRM?.DocumentNumberFormatted;

    return {
      id             : patientPRM?.Id,
      names          : names.trim(),
      surnames       : surnames.trim(),
      documentNumber,
      birth          : new Date(patientPRM?.BirthDate),
      age            : DateService.yearsOld(patientPRM?.BirthDate),
      gender         : patientPRM?.Gender,
      phone          : patientPRM?.MainPhoneNumber,
      email          : patientPRM?.Email,
      address        : {
        locality       : patientPRM?.Address.Locality,
        street         : patientPRM?.Address.Street,
        streetNumber   : patientPRM?.Address.StreetNumber,
        floor          : patientPRM?.Address.Floor,
        apartmentNumber: patientPRM?.Address.ApartmentNumber,
      },
      documentCountry: patientPRM?.DocumentCountry,
      documentType   : patientPRM?.DocumentType
    };
  }

  getPatientRef(patient: Patient): PatientRef {
    const {
            id,
            names,
            surnames,
            documentNumber,
            documentType,
            documentCountry,
            email,
            phone,
            gender,
            address,
            birth
          } = patient;

    return {
      id,
      names,
      surnames,
      documentNumber,
      documentType,
      documentCountry,
      email,
      phone,
      gender,
      address,
      birth,
      age: '', //TODO completa el age con el dateservice
    };
  }
}
