export const CLINICAL_ROUTES = {
  dau                  : 'dau',
  agenda               : 'agenda',
  agendar              : 'agendar',
  temp                 : 'temporal',
  expertise            : 'peritaje',
  surgeries            : 'cirugias',
  oncology             : 'oncologia',
  admissions           : 'admisiones',
  forms                : 'formulario',
  metrics              : 'indicadores',
  medicines            : 'medicamentos',
  indications          : 'indicaciones',
  derivations          : 'derivaciones',
  admin                : 'administracion',
  agendaManagement     : 'gestionar-agenda',
  hospitalizations     : 'hospitalizaciones',
  educationalResources : 'material-educativo',
  reproductive         : 'medicina-reproductiva',
  dischargeDocs        : 'documentos/alta-medica',
  careRounds           : 'ronda-multidisciplinaria',
  patientMedicalHistory: 'historial-medico/paciente',
};
