import { Paperwork } from '@shared/interfaces/paperwork.interface';
import { BARIATRIC_SURGERY_PAD_CRITERIA } from '@clinical/forms/constants/pad-criteria.constant';
import { DateService } from '@shared/services/date.service';
import { BariatricSurgeryBonusPad } from '@shared/interfaces/bariatric-surgery.interface';
import { patientTopCard } from '@shared/helpers/pdf-patient-card.helper';

export const buildContentPdfBariatricSurgeryBonus = (paperwork: Paperwork, group) => {
  const { rows } = group;
  const { patient } = paperwork;
  const listCriteria: any[] = Object.assign([], BARIATRIC_SURGERY_PAD_CRITERIA);

  const bariatricSurgeryBonusBuildPdf = rows.map((item: BariatricSurgeryBonusPad) => {
    const patientWeightValue = item.weight;
    const patientHeightValue = item.height;
    const patientBmiValue = item.bmi;

    const majorCriteria = listCriteria[0].criteria.map(criterion => {
      if (item.criteria.major[criterion.id]) return { text: criterion.name, bold: false };
    });

    const minorCriteria = listCriteria[1].criteria.map(criterion => {
      if (item.criteria.minor[criterion.id]) return { text: criterion.name, bold: false };
    });

    const indicatedReports = [];
    if (item.nutritionalReport)
      indicatedReports.push([{ text: `Informe nutricional`, bold: false }]);
    if (item.mentalHealthReport)
      indicatedReports.push([{ text: `Informe salud mental`, bold: false }]);

    const indicatedSurgery = [];
    if (item.indicatedSurgery.sleeveGastrectomy)
      indicatedSurgery.push([{ text: `Gastrectomía en Manga`, bold: false }]);
    if (item.indicatedSurgery.gastricBypass)
      indicatedSurgery.push([{ text: `Bypass gástrico`, bold: false }]);

    return [
    ...patientTopCard(paperwork),
      {
        alignment: 'justify',
        stack: [
          [
            { text: `Peso:` },
            { marginTop: -14, marginLeft: 220, text: `${patientWeightValue} kg.`, bold: true },
          ],
          [
            { text: `Estatura:` },
            { marginTop: -14, marginLeft: 220, text: `${patientHeightValue} mts.`, bold: true },
          ],
          [
            { text: `IMC:` },
            { marginTop: -14, marginLeft: 220, text: patientBmiValue, bold: true },
          ],
        ],
      },

      {
        margin: [50, 8, 50, 8],
        alignment: 'justify',
        fontSize: 10,
        stack: [
          { margin: [0, 5], text: `Criterios de cirugía bariátrica por PAD:`, bold: true },
          {
            ul: [
              'Edad entre 18 a 65 años.',
              'IMC mayor a 40 con o sin morbilidad asociada.',
              'IMC entre 35 y 40 con morbilidad asociada (1 criterio mayor + 1 menor o 3 menores).',
              'IMC Entre 30 - 35 y DM2 de difícil manejo (o 2 criterios mayores + 1 menor).',
            ],
          },
        ],
      },

      majorCriteria && majorCriteria.some(item => item && item.text !== null && item.text.trim() !== '')
        ? [
          { margin: [0, 5], text: `Criterios mayores:`, alignment: 'justify', bold: true },
          { fontSize: 11, alignment: 'justify', ul: majorCriteria },
        ]
        : '',
        minorCriteria && minorCriteria.some(item => item && item.text !== null && item.text.trim() !== '')
        ? [
          { margin: [0, 5], text: `Criterios menores:`, alignment: 'justify', bold: true },
          { fontSize: 11, alignment: 'justify', ul: minorCriteria },
        ]
        : '',

      indicatedReports.length > 0 ? [
        { margin: [0, 5], text: `Informes:`, alignment: 'justify', bold: true },
        { fontSize: 11, alignment: 'justify', ul: indicatedReports },
      ] : null,

      { margin: [0, 5], text: `Cirugía indicada:`, alignment: 'justify', bold: true },
      { fontSize: 11, alignment: 'justify', ul: indicatedSurgery },
    ];


  });

  return [bariatricSurgeryBonusBuildPdf];
};
