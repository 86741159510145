export interface TableTemplate {
  displayData?: string;
  otherField?: string;
  router?: string;
  array?: string;
  dataPatient?: string;
  typeForm?: string;
  code?: string;
  colum?: string;
  name: string;
  path: string;
}

export const TABLES_TEMPLATES: TableTemplate[] = [
  {
    name: 'Mis Recetas',
    path: 'mis-recetas',
    code: 'RME',
    colum: 'columRec',
    typeForm: 'recetas',
    array: 'prescriptions',
    displayData: 'medicine',
    router: 'medicamentos/receta',
  },
  {
    name: 'Mis Indicaciones',
    path: 'indicaciones-paciente',
    code: 'IND',
    colum: 'columInd',
    typeForm: 'examenes',
    dataPatient: 'indicaciones',
    array: 'exams',
    displayData: 'group',
  },
  {
    name: 'Mis Interconsultas',
    path: 'interconsultas-paciente',
    code: 'DER',
    colum: 'columDer',
    typeForm: 'derivaciones',
    dataPatient: 'derivaciones',
    array: 'requests',
    displayData: 'service',
    otherField: 'specialtyName',
    router: 'derivaciones/interconsultas',
  },
  {
    name: 'Mis Formularios',
    path: 'formularios-paciente',
    code: 'FRM',
    typeForm: 'formularios',
    array: 'forms',
    colum: 'columFor',
  },
  {
    name: 'Mis Cirugias',
    path: 'mis-cirugias',
    code: 'SUR',
    colum: 'columCir',
    typeForm: 'cirugias',
    array: 'orders',
    displayData: 'diagnosis',
    router: 'cirugias/orden',
  },
  {
    name: 'Mis Ordenes',
    path: 'ordenes-paciente',
    code: 'HOS',
    colum: 'columOrd',
    typeForm: 'ordenes',
    array: 'orders',
    displayData: 'diagnostics',
    router: 'hospitalizacion/orden',
  },
  {
    name: 'Mi Oferta Futura',
    path: 'mi-oferta-futura',
    code: 'ADM',
    colum: 'columAd',
    typeForm: 'oferta',
  },
  {
    name: 'Adopción',
    path: 'adopcion',
    code: 'ADM',
    colum: 'columAd',
    typeForm: 'oferta',
  },
];

export const MONTH = [
  { id: '01', name: 'Enero' },
  { id: '02', name: 'Febrero' },
  { id: '03', name: 'Marzo' },
  { id: '04', name: 'Abril' },
  { id: '05', name: 'Mayo' },
  { id: '06', name: 'Junio' },
  { id: '07', name: 'Julio' },
  { id: '08', name: 'Agosto' },
  { id: '09', name: 'Septiembre' },
  { id: '10', name: 'Octubre' },
  { id: '11', name: 'Noviembre' },
  { id: '12', name: 'Diciembre' },
];
