import { Component, Input, OnInit } from '@angular/core';
import { Control } from '@clinical/indications/interfaces/indication.interface';
import { NextControlComponent } from '@components/control/components/next-control/next-control.component';
import { UPDATE_PATIENT } from '@components/patient/store/patient.actions';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { SEND_FORM, SET_NEXT_CONTROL } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { PaperworkState } from '@store/reducers';
import { KEY_PAPERWORK_STORE, KEY_PROFESSIONAL_STORE } from '@store/store-keys';
import { filter, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { INDICATIONS_ROUTES } from '@clinical/indications/indications-routes.module';
import { MEDICINES_ROUTES } from '@clinical/medicines/medicines-routes.module';
import { TENS_PROFILES_NO_PATIENT_MAIL } from '@auth/constants/profiles.const';
import { takeUntil, tap } from 'rxjs/operators';
import { Professional } from '@auth/interfaces/professional.interface';

@Component({
  selector   : 'app-btn-pdf-sign-save',
  templateUrl: './btn-paperwork-fagn.component.html',
  styleUrls  : ['./btn-paperwork-fagn.component.scss'],
})
export class BtnPaperworkFagnComponent implements OnInit {
  @Input() title: string;
  destroy = new Subject();
  frmControl: Control;
  paperwork$: Observable<PaperworkState>;

  requiresPatientValidate = true;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private modalController: ModalController) {
  }

  ngOnInit() {
    this.paperwork$ = this.store.select(KEY_PAPERWORK_STORE);
    this.store
      .select(KEY_PROFESSIONAL_STORE)
      .pipe(
        takeUntil(this.destroy),
        filter(({isLoaded, professional}) => isLoaded && professional !== undefined),
        tap((professional) => this.isShowPatientValidate(professional.subprofessional))
      )
      .subscribe();
  }

  isShowPatientValidate(subprofessional: Professional): void {
    const profiles = subprofessional?.profiles;
    if(!profiles) return;
    if(profiles.some((profile: string) => TENS_PROFILES_NO_PATIENT_MAIL.includes(profile)))
      this.requiresPatientValidate = false;
  }

  isShowNextControl(): boolean {
    const indicationsRoutes: string[] = Object.values(INDICATIONS_ROUTES);
    const medicinesRoutes: string[] = Object.values(MEDICINES_ROUTES);
    const allowedRoutes = [...indicationsRoutes, ...medicinesRoutes];
    const currentRoute: string = this.router.url.split('/').slice(3).join('/');
    return allowedRoutes.includes(currentRoute);
  }

  async openModalPatientValidate(): Promise<void> {

    const modal = await this.modalController.create({
      component      : NextControlComponent,
      cssClass       : 'modal-height-lg',
      componentProps : {
        isConfirmed    : true,
        showNextControl: true,
        showSectionNextControl: this.isShowNextControl()
      },
      backdropDismiss: false
    });

    await modal.present();

    const {data} = await modal.onWillDismiss();

    this.frmControl = data;
    if (data && data.form) {
      await this.modalController.dismiss(); // Cierra el Formulario de Next Control

      const hasNextControl = data.form.value.nextControl.area.id !== '' || !!data.form.value.ccTeamAccess;
      if (hasNextControl) this.store.dispatch(SET_NEXT_CONTROL({ nextControl: data.form.value }));

      this.sendForm(data.form.value.patient);
    }
  }

  sendForm(patient?: any): void {
    // siempre se debe actualizar los datos del paciente
    this.store.dispatch(UPDATE_PATIENT({patient: patient ? patient : {}}));
    this.store.dispatch(SEND_FORM());
  }
}
