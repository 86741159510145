import { Action, createReducer, on } from '@ngrx/store';
import { UI_STATE, UI_STATE_ERR, UI_STATE_OK } from '@store/actions';

import { HttpErrorResponse } from '@angular/common/http';

export interface UiState {
  message: string;
  isLoaded: boolean;
  isLoading: boolean;
  error: HttpErrorResponse;
}

export const uiState: UiState = {
  message  : null,
  isLoaded : false,
  isLoading: false,
  error    : null,
};

const createReducerUi = createReducer(
  uiState,

  on(UI_STATE, (state, { message }) => (
    { ...state, isLoading: true, message }
  )),

  on(UI_STATE_OK, (state, { message }) => (
    { ...state, isLoading: false, isLoaded: true, message }
  )),

  on(UI_STATE_ERR, (state, { message, error }) => (
    { ...state, isLoading: false, isLoaded: false, message, error }
  )),
);

export const uiReducer = (state: UiState, action: Action) =>
  createReducerUi(state, action);
