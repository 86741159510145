import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';

export const TOKEN_KEY = 'token';
export const MESSAGE_POPUP_KEY = 'message_popup_set_at';
export const ACTIVE_SESSION_KEY = 'is_active_session';

@Injectable({
  providedIn: 'root'
})
export class IonicStorageService {

  private isReadyToStore$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private _storage: Storage) {
    this.init();
  }


  public getData(key: string): Observable<any> {

    return this.isReadyToStore$.pipe(
      filter(isReady => isReady),
      switchMap(() => from(this._storage.get(key)) || of([]))
    );
  }

  public addData(key: string, item: any): Observable<any> {
    return this.isReadyToStore$.pipe(
      filter(isReady => isReady),
      switchMap(() => from(this._storage.set(key, item)) || of([])),
    );
  }

  public async pushData(key: string, item: any) {
    const storedData = await this._storage.get(key) || [];
    storedData.push(item);
    return this._storage.set(key, storedData);
  }

  public removeItem(key: string): Observable<any> {
    return this.isReadyToStore$.pipe(
      filter(isReady => isReady),
      switchMap(() => from(this._storage.remove(key)) || of([])),
    );
  }

  public clear(): Observable<void> {
    return this.isReadyToStore$.pipe(
      filter(isReady => isReady),
      switchMap(() => from(this._storage.clear())),
    );
  }

  private async init() {
    await this._storage.defineDriver(CordovaSQLiteDriver);
    await this._storage.create();
    this.isReadyToStore$.next(true);
  }

}
