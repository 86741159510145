import { qrs } from './pdf-qr.helper';
import { makeSignature } from './pdf-sign.helper';

const LOCALTIME_CL = () => new Date().toLocaleString('es-CL', { timeZone: 'America/Santiago' });

const phonesAndWeb = () => ({
  margin: [0, 0, 0, 5],
  columns: [
    {
      width: '16%',
      alignment: 'left',
      fontSize: 11,
      bold: true,
      stack: [
        { text: 'Llámanos', color: '#656565', fontSize: 7, italics: true, lineHeight: 0.75 },
        {
          bold: true,
          text: [
            { text: '22676  ', color: '#0768d0' },
            { text: '7000', color: '#671e75' },
          ],
        },
      ],
    },
    {
      width: '16%',
      alignment: 'left',
      fontSize: 11,
      bold: true,
      stack: [
        { text: 'Clínica San Carlos', color: '#656565', fontSize: 7, italics: true, lineHeight: 0.75 },
        {
          bold: true,
          text: [
            { text: '22754 ', color: '#0768d0' },
            { text: '8800', color: '#671e75' },
          ],
        },
      ],
    },
    {
      width: '68%',
      alignment: 'right',
      fontSize: 12,
      bold: true,
      stack: [
        { text: 'visítanos en ', color: '#656565', fontSize: 7, italics: true, lineHeight: 0.75 },
        {
          text: [
            { text: 'uc', color: '#0768d0' },
            { text: 'christus.cl', color: '#671e75' },
          ],
        },
      ],
    },
  ],
});

export const pageCountControl = (info) => {
  const { currentPage, pageCount, paperwork, config } = info;
  if (config.isPreview) return;
  return {
    columns: [
      { width: '40%', text: paperwork.shortId },
      { width: '20%', alignment: 'center', text: LOCALTIME_CL() },
      { width: '40%', alignment: 'right', text: 'Página ' + currentPage.toString() + ' de ' + pageCount },
    ],
  };
};

const makeFooterDivider = () => ({
  margin: [0, 0, 0, 5],
  canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.5, lineColor: '#656565' }],
});

export const makeFooter = (pdfParams) => {
  const { paperwork, config, title } = pdfParams;  

  return (currentPage: number, pageCount: number) => {
    const info = { currentPage, pageCount, paperwork, config };
    return [
      {
        fontSize: 8,
        margin: [40, 40, 40, 0],
        stack: [
          title === 'formulario de solicitud de vacunación especial' ? '' : makeFooterDivider(),
          title === 'formulario de solicitud de vacunación especial' ? '' : makeSignature(info),
          title === 'formulario de solicitud de vacunación especial' ? '' : phonesAndWeb(),
          title === 'formulario de solicitud de vacunación especial' ? '' : qrs(info),
          title === 'formulario de solicitud de vacunación especial' ? '' : pageCountControl(info)
        ],
      },
    ];
  };
};
