import {Injectable} from '@angular/core';

import {ClinicalEducationalService} from '@clinical/educational/services/clinical-educational.service';
import {
  PUT_EDUCATIONAL,
  PUT_EDUCATIONAL_ERR,
  PUT_EDUCATIONAL_OK,
} from '@clinical/educational/store/clinical-educational.actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';

@Injectable()
export class EducationalEffects {
  putEducational$ = createEffect(() =>
      this.actions$.pipe(
          ofType(PUT_EDUCATIONAL),
          mergeMap(({valuesToSave}) =>
              this.clinicalEducationalService.savexxx({...valuesToSave}).pipe(
                  map((doc) => PUT_EDUCATIONAL_OK({doc})),
                  catchError((err) => of(PUT_EDUCATIONAL_ERR({payload: err}))),
              ),
          ),
      ),
  );

  constructor(
      private actions$: Actions,
      private clinicalEducationalService: ClinicalEducationalService,
  ) {
  }
}
