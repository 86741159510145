import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import { API_URIS } from '@shared/constants/api-uris.const';
import { PCV2Request } from '@shared/interfaces/request.interface';
import { Briefing, Reproductive } from '@shared/interfaces/reproductive.interface';
import { Paperwork } from '@shared/interfaces/paperwork.interface';

const BASE_API_PCV2: string = environment.baseApiPCv2;
const URI_CLINICAL_REPRODUCTIVE: string = API_URIS.clinical.reproductive;

@Injectable({
  providedIn: 'root',
})
export class ReproductiveService {
  constructor(private httpClient: HttpClient) {}

  save(body: Paperwork<Briefing>): Observable<Paperwork<Briefing>> {
    return this.httpClient.post<PCV2Request>(`${BASE_API_PCV2}/${URI_CLINICAL_REPRODUCTIVE}`, body).pipe(
      retry(3),
      map(({ data }: { data: Paperwork<Briefing> }) => data),
    );
  }
}
