import { DOCUMENT_TYPE } from '@shared/constants/patient.constant';
import { patientTopCard } from '@shared/helpers/pdf-patient-card.helper';
import { Certificate } from '@clinical/forms/interfaces/certificate.interface';

export const buildContentPdfCertificate = (paperwork, group) => {
  const {patient, professional} = paperwork;
  const {rows} = group;
  return rows.map((itemForm: Certificate) => [
    ...patientTopCard(paperwork),
    {
      fontSize : 10,
      alignment: 'justify',
      stack    : [
        {
          text         : [
            {text: `Yo, Dr(a). ${professional.names} ${professional.surnames}, RUN ${professional.rut}, `},
            {text: `certifico que el paciente ${patient.names} ${patient.surnames}, `},
            {text: `${DOCUMENT_TYPE[patient.documentType.toLowerCase()]} ${patient.documentNumber} `},
            { text: `${itemForm.certificate}`, bold: true },
          ],
          headlineLevel: 1,
        },
        {text: ` `, headlineLevel: 1},
        {
          text: `Se emite el presente certificado a solicitud del paciente, familia y/o cuidador, para los fines que estime conveniente.`,
          headlineLevel: 2,
        },
      ],
    },
  ]);
};
