export const KEY_ADMISSION_STORE = 'admissions';
export const KEY_AGENDA_STORE       = 'agenda';
export const KEY_ANAMNESIS_STORE = 'anamnesis';
export const KEY_DERIVATIONS_STORE  = 'derivations';
export const KEY_EDUCATIONAL_STORE  = 'educational';
export const KEY_EMERGENCIES_STORE = 'emergencies';
export const KEY_EXPERTISE_STORE = 'expertise';
export const KEY_FAVORITES_STORE    = 'favorites';
export const KEY_FORMS_STORE = 'forms';
export const KEY_HOSPITALIZATIONS_STORE = 'hospitalizations';
export const KEY_INDICATIONS_STORE = 'indications';
export const KEY_MEDICAL_DISCHARGE = 'discharge';
export const KEY_NOTIFICATION_STORE = 'notification';
export const KEY_ONCOLOGY_STORE = 'oncology';
export const KEY_PAPERWORK_STORE = 'paperwork';
export const KEY_PATIENT_STORE = 'patient';
export const KEY_PROFESSIONAL_STORE = 'professional';
export const KEY_RECIPES_STORE = 'recipes';
export const KEY_REPRODUCTIVE_STORE = 'reproductive';
export const KEY_STORAGE_STORE = 'storage';
export const KEY_SURGERIES_STORE    = 'surgeries';
