import { HttpErrorResponse } from '@angular/common/http';
import { ExamIndication } from '@clinical/indications/interfaces/indication.interface';
import { ParamsToFindByPatient } from '@components/patient/interfaces/patient.interface';
import { createAction, props } from '@ngrx/store';
import { Paperwork } from '@shared/interfaces/paperwork.interface';

/* Firma de documentos electrónicos */
export const PUT_INDICATION = createAction(
  '[indicaciones] PUT Indicación Médica',
  props<{ valuesToSave: Paperwork<ExamIndication> }>(),
);
export const PUT_INDICATION_OK = createAction(
  '[indicaciones] OK PUT Indicación Médica',
  props<{ indication: Paperwork }>(),
);
export const PUT_INDICATION_ERR = createAction(
  '[indicaciones] ERR PUT Indicación Médica',
  props<{ payload: HttpErrorResponse }>(),
);

export const LOAD_INDICATIONS_BY_PATIENT = createAction(
  '[indicaciones] Cargar Indicación por paciente',
  props<{ params: ParamsToFindByPatient }>(),
);
export const LOAD_INDICATIONS_BY_PATIENT_OK = createAction(
  '[indicaciones] OK Indicación por paciente',
  props<{ indications: Paperwork<ExamIndication>[] }>(),
);
export const LOAD_INDICATIONS_BY_PATIENT_ERR = createAction(
  '[indicaciones] ERR Indicación por paciente',
  props<{ payload: HttpErrorResponse }>(),
);

export const LOAD_INDICATIONS_BY_PROFESSIONAL = createAction(
  '[indicaciones] Cargar Indicación por profesional',
  props<{ gsiMultiCondition1St: string }>(),
);
export const LOAD_INDICATIONS_BY_PROFESSIONAL_OK = createAction(
  '[indicaciones] OK Indicación por profesional',
  props<{ indications: Paperwork<ExamIndication>[] }>(),
);
export const LOAD_INDICATIONS_BY_PROFESSIONAL_ERR = createAction(
  '[indicaciones] ERR Indicación por profesional',
  props<{ payload: HttpErrorResponse }>(),
);

export const DELETE_INDICATION = createAction(
  '[indicaciones] DELETE Indicación',
  props<{ indication: Paperwork<ExamIndication> }>(),
);
export const DELETE_INDICATION_OK = createAction(
  '[indicaciones] OK DELETE Indicación',
  props<{ indication: Paperwork<ExamIndication> }>(),
);
export const DELETE_INDICATION_ERR = createAction(
  '[indicaciones] ERR DELETE Indicación',
  props<{ payload: HttpErrorResponse }>(),
);

export const SELECT_INDICATION = createAction(
  '[indicaciones] SELECT Indicación Médica',
  props<{ selected: Paperwork<ExamIndication>; action: string }>(),
);
export const SELECTED_INDICATION = createAction(
  '[indicaciones] SELECTED Indicación Médica',
  props<{ selected: Paperwork<ExamIndication>; action: string }>(),
);
export const PUT_INDICATION_END = createAction('[indicaciones] Resetea el estado para crear una nueva solicitud');
export const UNSELECT_INDICATION = createAction('[indicaciones] UNSELECT Indicación Médica');
export const CLEAR_INDICATION = createAction('[indicaciones] CLEAR Indicación Médica');

export const PUT_MARKS = createAction('[indicaciones] PUT Marcas Indicación Médica', props<{ indication: Paperwork<any> }>());
export const PUT_MARKS_OK = createAction('[indicaciones] OK Marcas Indicación Médica');
export const PUT_MARKS_ERR = createAction('[indicaciones] ERR Marcas Indicación Médica', props<{ payload: HttpErrorResponse }>());
