import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PUT_ONCOLOGY, PUT_ONCOLOGY_OK, PUT_ONCOLOGY_ERR, PUT_ONCOLOGY_END } from '@store/actions';
import { DELETE_ONCOLOGY, DELETE_ONCOLOGY_OK, DELETE_ONCOLOGY_ERR } from '@store/actions';
import { LOAD_ONCOLOGY_BY_PATIENT, LOAD_ONCOLOGY_BY_PATIENT_OK, LOAD_ONCOLOGY_BY_PATIENT_ERR } from '@store/actions';
import { LOAD_ONCOLOGY_BY_PROFESSIONAL, LOAD_ONCOLOGY_BY_PROFESSIONAL_OK, LOAD_ONCOLOGY_BY_PROFESSIONAL_ERR } from '@store/actions';
import { ReportService } from '@components/records/services/report.service';
import { ClinicalOncologyService } from '@clinical/oncology/services/clinical-oncology.service';

@Injectable()
export class OncologyEffects {
  putForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PUT_ONCOLOGY),
      mergeMap(({ valuesToSave }) =>
        this.clinicalOncologyService.save({ ...valuesToSave }).pipe(
          map((oncology) => PUT_ONCOLOGY_OK({ oncology })),
          catchError((err) => of(PUT_ONCOLOGY_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  clear$ = createEffect(() => this.actions$.pipe(ofType(PUT_ONCOLOGY_OK), map(() => PUT_ONCOLOGY_END())));


  loadFormsByPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_ONCOLOGY_BY_PATIENT),
      mergeMap(({ params }) =>
        this.reportService.findByType2( params.patientId, params.type).pipe(
          map((oncology) => LOAD_ONCOLOGY_BY_PATIENT_OK({ oncology })),
          catchError((err) => of(LOAD_ONCOLOGY_BY_PATIENT_ERR({ payload: err }))),
        ),
      ),
    ),
  );
  loadFormsByProfessional$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_ONCOLOGY_BY_PROFESSIONAL),
      mergeMap(({ gsiMultiCondition1St }) =>
        this.reportService.findByGsi1(gsiMultiCondition1St).pipe(
          map((oncology) => LOAD_ONCOLOGY_BY_PROFESSIONAL_OK({ oncology })),
          catchError((err) => of(LOAD_ONCOLOGY_BY_PROFESSIONAL_ERR({ payload: err }))),
        ),
      ),
    ),
  );
  deleteForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DELETE_ONCOLOGY),
      mergeMap(( form ) =>
        this.reportService.deleteByIds2('oncologia', form).pipe(
          map((oncology) => DELETE_ONCOLOGY_OK({ oncology })),
          catchError((err) => of(DELETE_ONCOLOGY_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private clinicalOncologyService: ClinicalOncologyService, private reportService: ReportService) {}
}
