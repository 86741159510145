import {HttpClient} from '@angular/common/http';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormNextControl, NextControl} from '@components/control/interfaces/next-control.interface';
import {Patient} from '@components/patient/interfaces/patient.interface';

import {FormFieldsValidate} from '@core/reactive-forms/form-fields-validate.helper';
import {FormGroup} from '@core/reactive-forms/form-group';
import {ModelForm} from '@core/reactive-forms/types';
import {ModalController} from '@ionic/angular';

import {Store} from '@ngrx/store';
import {FIELD_REQUIRED} from '@shared/helpers/form-fields-validate.helper';
import {Address} from '@shared/interfaces/address.interface';

import {Area} from '@shared/interfaces/area.interface';
import {Specialty} from '@shared/interfaces/specialties.interface';
import {AreaService} from '@shared/services/area.service';

import {DateService} from '@shared/services/date.service';
import {SpecialtiesService} from '@shared/services/specialties.service';
import {KEY_PATIENT_STORE, KEY_PROFESSIONAL_STORE} from '@store/store-keys';
import {addDays, format, isSunday, nextMonday} from 'date-fns';
import {Observable, of, Subject} from 'rxjs';
import {catchError, filter, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {AuthService} from 'src/app/auth/services/auth.service';
import {AppState} from 'src/app/store/app.reducers';
import {MaintainerService} from '@shared/services/maintainer.service';

import {INDICATIONS_ROUTES} from '@clinical/indications/indications-routes.module';
import {SURGERIES_ROUTES} from '@clinical/surgeries/surgeries-routes.module';
import {DERIVATIONS_ROUTES} from '@clinical/derivations/derivations-routes.module';
import {FormArray} from '@core/reactive-forms/form-array';
import {FormControl} from '@core/reactive-forms/form-control';

const CC_TEAM_ACCESS_ID = `APP#00AB01#MOD#Da3Phy`;

@Component({
  selector   : 'app-next-control',
  templateUrl: './next-control.component.html',
  styleUrls  : ['./next-control.component.scss'],
})
export class NextControlComponent implements OnInit, OnDestroy {
  @Input() isConfirmed: boolean;
  @Input() showNextControl: boolean;
  @Input() showSectionNextControl: boolean;

  vf = FormFieldsValidate;
  frmNextControl: FormGroup<ModelForm<FormNextControl>>;

  areas$: Observable<Area[]>;
  specialties$: Observable<Specialty[]>;
  status: string;
  holidays: any[] = [];

  destroy = new Subject();
  isLoading: boolean;
  isViewCcTeamAccess: boolean;
  hasErrorInAccessTeam: boolean;
  professionalSpecialties = [];
  customPopoverOptions = {
    header : 'Especialidades',
    message: 'Seleccione especialidad para notificar al equipo de acceso dedicado que corresponda.',
  };

  get ccTeamAccessMailsForm() {
    return this.frmNextControl.get('ccTeamAccessMails') as FormArray<string>;
  }

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private areaService: AreaService,
    private modalController: ModalController,
    private authService: AuthService,
    private specialtiesService: SpecialtiesService,
    private maintainerService: MaintainerService,
    private httpClient: HttpClient,
  ) {
    this.buildForm();
  }

  ngOnDestroy() {
    this.destroy.next('next');
    this.destroy.complete();
  }

  ngOnInit() {
    this.getAreasByNames();
    this.loadHolidays();
    this.professionalStore();

    if (this.isConfirmed) this.showNextControl = !this.isConfirmed;

    this.patient$();

    this.status = '';
  }

  loadHolidays() {
    const endpoint = `https://api.victorsanmartin.com/feriados/es.json`;
    this.httpClient.get(endpoint).pipe(map(({data}: any) => data)).subscribe((data: any[]) => this.holidays = data);
  }

  isBussinessDay(day: Date) {
    let nextBussinessDay;
    if (isSunday(day)) nextBussinessDay = nextMonday(day);

    const isHoliday = this.holidays.find(({date}) => date === format(nextBussinessDay || day, 'yyyy-MM-dd'));
    if (isHoliday) nextBussinessDay = this.isBussinessDay(addDays(nextBussinessDay || day, 1));

    return nextBussinessDay || day;

  }

  onSubmit(): void {
    this.calcNextDateControl();
    this.modalController.dismiss({
      form: this.frmNextControl
    });
  }

  patient$(): void {
    this.store
        .select(KEY_PATIENT_STORE)
        .pipe(
          takeUntil(this.destroy),
          filter(({isLoaded}) => isLoaded !== false),
          tap(({patient}) => this.frmNextControl.controls.patient.patchValue(patient)),
        )
        .subscribe();
  }

  // TODO: tal vez esto no es necesario tenerlo aqui, validar si se puede trae desde this.authService
  professionalStore() {
    this.store.select(KEY_PROFESSIONAL_STORE).pipe(
      takeUntil(this.destroy),
      filter((professional) => professional !== undefined),
      map(({professional}) => {
        const access = professional.access.find((acc) => acc.option === CC_TEAM_ACCESS_ID);
        if (access && access.allow) this.setCheckboxCcTeamAccess();
        return professional.id;
      }),
      switchMap((id) => this.specialtiesService.getSpecialitiesForList(id)),
      map((data) => {
        if (data && data.length > 0) this.professionalSpecialties = [...data];
      }),
    ).subscribe();
  }

  getAccessTeam(event) {
    if (!event.detail.value) return;
    const sk = `${CC_TEAM_ACCESS_ID}#UCC#EAD#SPE#${event.detail.value}`;
    const params = encodeURIComponent(sk);
    this.isLoading = true;
    this.hasErrorInAccessTeam = false;
    this.maintainerService.getConfigByModule(params)
        .pipe(
          takeUntil(this.destroy),
          tap(() => (this.isLoading = false)),
          map(([data]) => {
            if (!data.value || data.value?.length === 0)
              throw new Error('No hay equipo de acceso dedicado asociado a esta especialidad');

            this.ccTeamAccessMailsForm.reset();
            data.value.map(email => this.ccTeamAccessMailsForm.push(new FormControl<string>(email)));

            const {wsp, nurse, contact, phone} = data;
            this.frmNextControl.controls.teamAccess.patchValue({wsp, nurse, contact, phone});
            return;
          }),
          catchError((err) => {
            this.frmNextControl.controls.ccTeamAccess.setValue('');
            this.frmNextControl.controls.teamAccess.patchValue({});
            this.hasErrorInAccessTeam = true;
            return of((this.isLoading = false));
          }),
        )
        .subscribe();
  }

  setCheckboxCcTeamAccess() {
    const currentUrl = this.router.url;

    const isSurgery = currentUrl.includes(SURGERIES_ROUTES.order);
    const isIndication = currentUrl.includes(INDICATIONS_ROUTES.exams);
    const isInterconsultation = currentUrl.includes(DERIVATIONS_ROUTES.interconsultations);
    const isOtorhinolaryngologist = currentUrl.includes(INDICATIONS_ROUTES.otorhinolaryngology);

    if (isIndication || isSurgery || isInterconsultation || isOtorhinolaryngologist) this.isViewCcTeamAccess = true;
  }

  getAreasByNames(): void {
    const NEXT_CONTROL_NAMES_AREAS = ['consultas', 'telemedicina'];
    this.areas$ = this.areaService.findByNames(NEXT_CONTROL_NAMES_AREAS);
  }

  getSpecialties(event) {
    const area = event.detail.value;
    this.frmNextControl.controls.nextControl.controls.area.patchValue(area);
    const areaId = event.detail.value.id;
    const professionalId = this.authService.professionalRef.id;

    if (!areaId || !professionalId) return;
    const params = {areaId, professionalId, centerId: null, specialtyId: null};
    this.frmNextControl.controls.nextControl.controls.specialty.patchValue(null);

    this.specialties$ = this.specialtiesService.findByProfessionalAndArea(params);
  }

  getSpecialty(event) {
    const specialty = event.detail.value;
    this.frmNextControl.controls.nextControl.controls.specialty.patchValue(specialty);
  }

  async calcNextDateControl() {
    const rangetime = this.frmNextControl.value.nextControl.rangetime;
    const interval = this.frmNextControl.value.nextControl.interval;
    if (!rangetime || !interval) return;

    const dateFormat = 'iso';
    const newDate = await DateService.addTime(dateFormat, 'dia', rangetime);
    const date = this.isBussinessDay(new Date(newDate));
    this.frmNextControl.controls.nextControl.controls.date.setValue(date);
  }

  /**
   * Inicializa los campos del formualrio para programar un próximo control
   *
   * @param isVisible {boolean} Determina si se muestra si se ocultan los campos del formulario para un próximo control
   */
  presentFormControl(isVisible: boolean) {
    this.showNextControl = !isVisible;
    if (!this.showNextControl) return this.frmNextControl.removeControl('nextControl');

    this.frmNextControl.addControl('nextControl', this.formGroupNextControl());
  }

  /**
   * Construir el formulario para validar los datos del paciente y notificar al paciente sobre un próximo control
   */
  buildForm() {
    const MIN_LENGTH = 1;
    this.frmNextControl = new FormGroup<ModelForm<FormNextControl>>({
      ccTeamAccess     : FormFieldsValidate.requiredMinLength(MIN_LENGTH, false),
      teamAccess       : FormFieldsValidate.requiredMinLength(FIELD_REQUIRED, {}),
      ccTeamAccessMails: new FormArray<string>([]),
      patient          : new FormGroup<ModelForm<Patient>>({
        names   : FormFieldsValidate.requiredMinLength(FIELD_REQUIRED),
        surnames: FormFieldsValidate.requiredMinLength(FIELD_REQUIRED),
        phone   : FormFieldsValidate.requiredMinLength(FIELD_REQUIRED),
        email   : FormFieldsValidate.requiredMinLength(FIELD_REQUIRED),
        address : new FormGroup<ModelForm<Address>>({
          street         : FormFieldsValidate.minLength(FIELD_REQUIRED),
          locality       : FormFieldsValidate.minLength(FIELD_REQUIRED),
          streetNumber   : FormFieldsValidate.minLength(FIELD_REQUIRED),
          apartmentNumber: FormFieldsValidate.minLength(FIELD_REQUIRED),
        }),
      }),
      nextControl      : this.formGroupNextControl(),
    });
  }

  /**
   * Construye el grupo del formulario que agrega el próximo control, solo se agrega a petición del profesional
   */
  formGroupNextControl(): FormGroup<ModelForm<NextControl>> {
    const MIN_LENGTH = 1;
    return new FormGroup<ModelForm<NextControl>>({
      date     : FormFieldsValidate.minLength(FIELD_REQUIRED, ''),
      interval : FormFieldsValidate.minLength(FIELD_REQUIRED, 'dia'),
      area     : new FormGroup<ModelForm<Area>>({
        id  : FormFieldsValidate.minLength(FIELD_REQUIRED, ''),
        name: FormFieldsValidate.minLength(FIELD_REQUIRED, ''),
      }),
      rangetime: FormFieldsValidate.minLength(FIELD_REQUIRED, MIN_LENGTH),
      specialty: new FormGroup<ModelForm<Specialty>>({
        id       : FormFieldsValidate.minLength(FIELD_REQUIRED),
        name     : FormFieldsValidate.minLength(FIELD_REQUIRED),
        shortId  : FormFieldsValidate.minLength(FIELD_REQUIRED),
        cancelled: FormFieldsValidate.minLength(FIELD_REQUIRED),
        service  : FormFieldsValidate.minLength(FIELD_REQUIRED),
      }),
    });
  }
}
