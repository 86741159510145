import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector   : 'app-form-header',
  templateUrl: './form-header.component.html',
  styleUrls  : ['./form-header.component.scss'],
})
export class FormHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() buttonLink: string;
  @Input() buttonTitle: string;
  @Input() additionalButton: string;

  constructor() {
  }

  ngOnInit() {
  }

  downloadVademecumPharmacosGes() {
    window.open(`https://s3.us-west-2.amazonaws.com/recursosweb.pub.prd.usw2/documentos/vademecum-ges-cruz-blanca-rv202310-prd.xlsx`,
      '_blank',
    );

  }

  downloadPDFSpecialVaccines() {
    window.open(`https://s3.us-west-2.amazonaws.com/recursosweb.pub.prd.usw2/documentos/formulario-vacunas-especiales-rv202403.pdf`,
      '_blank',
    );

  }
}
