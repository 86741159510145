import {patientTopCard} from '@shared/helpers/pdf-patient-card.helper';

export const buildContentPdfRadiotherapy = (paperwork, group) => {
  const {rows}               = group;
  const radiotherapyBuildPdf = rows.map((itemForm) => {
    let diagnostic          = itemForm.diagnostic;
    let technique           = itemForm?.technique;
    let insuranse           = itemForm?.insurance?.name;
    let forecast            = itemForm.forecast;
    let site                = itemForm.site;
    let treatment           = itemForm?.insurance?.option?.map((name) => name.description);
    const OPTION            = itemForm?.insurance?.option?.map((item) => ({
      text    : ` ${item.sapId} - ${item.description}\n`,
      bold    : true,
      margin  : [0, 5, 0, 0],
      fontSize: 12,
    }));
    let otherDiagnoses      = itemForm?.otherDiagnoses;
    let otherTechniques     = itemForm?.otherTechniques;
    let otherTreatments     = itemForm?.otherTreatments;
    let hospitalizedPatient = itemForm.hospitalizedPatient;
    let anesthesia          = itemForm.anesthesia.isAnesthetized;
    let totalSessions       = itemForm.anesthesia.totalSessions;
    let observation         = itemForm.observation;

    if(hospitalizedPatient === false) hospitalizedPatient = 'No'
    if(hospitalizedPatient === true) hospitalizedPatient = 'Si'

    if(anesthesia === false) anesthesia = 'No'
    if(anesthesia === true) anesthesia = 'Si'


    if (forecast)
      forecast = [
        {fontSize: 12, text: ` Previsión: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${forecast}`,
          bold      : true,
        },
      ];
      diagnostic = [
        {fontSize: 12, text: ` Diagnóstico: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${diagnostic}`,
          bold      : true,
        },
      ];
    if (technique)
      technique = [
        {fontSize: 12, text: `Técnica: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${technique}`,
          bold      : true,
        },
      ];
    if (insuranse)
      insuranse = [
        {fontSize: 12, text: ` Previsión: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${insuranse}`,
          bold      : true,
        },
      ];
    if(treatment)
      treatment = [{fontSize: 12, text: ` Tratamiento: `}, {ul: OPTION}];

      anesthesia = [
        {fontSize: 12, text: ` Anestesia: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${anesthesia}`,
          bold      : true,
        },
      ];
    if (otherDiagnoses)
      otherDiagnoses = [
        {fontSize: 12, text: `Diagnóstico Otro: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${otherDiagnoses}`,
          bold      : true,
        },
      ];
    if (otherTechniques)
      otherTechniques = [
        {fontSize: 12, text: `Técnica Otro: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${otherTechniques}`,
          bold      : true,
        },
      ];
    if (otherTreatments)
      otherTreatments = [
        {fontSize: 12, text: `Tratamiento Otro: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${otherTreatments}`,
          bold      : true,
        },
      ];
    if (totalSessions)
      totalSessions = [
        {fontSize: 12, text: ` N° de sesiones: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${totalSessions}`,
          bold      : true,
        },
      ];

      hospitalizedPatient = [
        {fontSize: 12, text: ` Paciente Hospitalizado: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${hospitalizedPatient}`,
          bold      : true,
        },
      ];
    if (site)
      site = [
        {fontSize: 12, text: ` Lugar: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${site}`,
          bold      : true,
        },
      ];
    if (observation)
      observation = [
        {fontSize: 12, text: ` Observaciones: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${observation}`,
          bold      : true,
        },
      ];

    const RADIOTHERAPY_CONTENT = {
      fontSize : 15,
      alignment: 'justify',
      stack    : [
        forecast,
        diagnostic,
        technique,
        insuranse,
        treatment,
        otherDiagnoses,
        otherTechniques,
        otherTreatments,
        anesthesia,
        totalSessions,
        hospitalizedPatient,
        site,
        observation,
      ],
    };

    return [RADIOTHERAPY_CONTENT];
  });

  return [...patientTopCard(paperwork), radiotherapyBuildPdf];
};