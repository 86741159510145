import {Injectable}                    from '@angular/core';
import {Router}                        from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of}                            from 'rxjs';
import {ROOT_ROUTES}                   from '../../app-routing.module';
import {AUTH_ROUTES}                   from '@auth/auth-routing.module';
import * as authActions                from '../../store/actions';
import {AuthService}                   from 'src/app/auth/services/auth.service';
import {DEFAULT_SIGNATURE}             from '@auth/constants/default-signature.constant';
import {StorageService}                from '@shared/services/storage.service';

import {catchError, filter, map, mergeMap, switchMap, tap, distinct}  from 'rxjs/operators';
import {ACTIVE_SESSION_KEY, IonicStorageService, TOKEN_KEY} from '@shared/services/ionic-storage.service';
import {CLINICAL_ROUTES}                                    from '@clinical/clinical-routes.module';

const LOGIN_ROUTE = `/${AUTH_ROUTES.auth}/${AUTH_ROUTES.login}`;

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.AUTH_LOGIN),
      switchMap(({credentials}) =>
        this.authService.validateCredentials({...credentials}).pipe(
          map((professional) => authActions.AUTH_LOGIN_OK({professional})),
          catchError((err) => of(authActions.AUTH_LOGIN_ERR({payload: err}))),
        ),
      ),
    ),
  );

  refresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.AUTH_REFRESH),
      mergeMap(() =>
        this.authService.refreshToken().pipe(
          distinct((professional) => professional?.token),
          map((professional) => authActions.AUTH_REFRESH_OK({professional})),
          catchError((err) => of(authActions.AUTH_REFRESH_ERR({payload: err}))),
        ),
      ),
    ),
  );

  saveToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.AUTH_LOGIN_OK),
      switchMap(({professional}) =>
        this.ionicStorageService.addData(TOKEN_KEY, professional.token).pipe(
          mergeMap(() => this.ionicStorageService.addData(ACTIVE_SESSION_KEY, true)),
          map((_) => authActions.AUTH_SET_TOKEN_OK()),
          filter((_) => this.router.url === LOGIN_ROUTE || this.router.url === '/'),
          tap((_) => this.router.navigate(['/', ROOT_ROUTES.clinical, CLINICAL_ROUTES.agenda])),
          catchError((err) => of(authActions.AUTH_SET_TOKEN_ERR({payload: err}))),
        ),
      ),
    ),
  );

  saveRefreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.AUTH_REFRESH_OK),
      filter((_) => !(this.router.url === LOGIN_ROUTE || this.router.url === '/')),
      switchMap(({professional}) =>
        this.ionicStorageService.addData(TOKEN_KEY, professional.token).pipe(
          mergeMap(() => this.ionicStorageService.addData(ACTIVE_SESSION_KEY, true)),
          map((_) => authActions.AUTH_SET_TOKEN_OK()),
          catchError((err) => of(authActions.AUTH_SET_TOKEN_ERR({payload: err}))),
        ),
      ),
    ),
  );

  signature$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.LOAD_SIGNATURE),
      mergeMap(({signature}) => {
        if (!signature) return of(authActions.LOAD_SIGNATURE_OK({base64: DEFAULT_SIGNATURE}));
        return this.storageService.getObjectBase64ToS3(signature.attachment).pipe(
          map((base64) => authActions.LOAD_SIGNATURE_OK({base64})),
          catchError((err) => of(authActions.LOAD_SIGNATURE_ERR({payload: err}))),
        );
      }),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.AUTH_LOGOUT, authActions.AUTH_REFRESH_ERR),
      switchMap(() =>
        this.ionicStorageService.clear().pipe(
          map(() => authActions.AUTH_LOGOUT_OK()),
          tap(() => (location.href = LOGIN_ROUTE)),
          catchError((err) => of(authActions.AUTHL_LOGOUT_ERR({payload: err}))),
        ),
      ),
    ),
  );

  putProfessional$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.PUT_PROFESSIONAL),
      mergeMap(({valuesToSave}) =>
        this.authService.putProfessional(valuesToSave).pipe(
          map((professional) => authActions.PUT_PROFESSIONAL_OK({professional})),
          catchError((err) => of(authActions.PUT_PROFESSIONAL_ERR({payload: err}))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private storageService: StorageService,
    private ionicStorageService: IonicStorageService,
    private router: Router,
  ) {}
}
