import { Action, createReducer, on } from '@ngrx/store';
import { SurgeryOrder } from '@shared/interfaces/surgery.interface';

import {
  CLEAR_SURGERY,
  LOAD_SURGERIES_BY_PATIENT,
  LOAD_SURGERIES_BY_PATIENT_ERR,
  LOAD_SURGERIES_BY_PATIENT_OK,
  LOAD_SURGERIES_BY_PROFESSIONAL,
  LOAD_SURGERIES_BY_PROFESSIONAL_ERR,
  LOAD_SURGERIES_BY_PROFESSIONAL_OK,
  NOTIFY_KEIRON,
  NOTIFY_KEIRON_ERR,
  NOTIFY_KEIRON_OK,
  PUT_SURGERY,
  PUT_SURGERY_END,
  PUT_SURGERY_ERR,
  PUT_SURGERY_OK,
  SELECTED_SURGERY,
  UNSELECT_SURGERY,
} from '@store/actions';
import { Paperwork } from '@shared/interfaces/paperwork.interface';

export interface SurgeryState {
  error: any;
  status: string;
  isLoaded: boolean;
  isLoading: boolean;
  orders: Paperwork<SurgeryOrder>[];
  historical: Paperwork<SurgeryOrder>[];
  lastOrder: Paperwork<SurgeryOrder>;
  current: Paperwork;
  selected: Paperwork<SurgeryOrder>;
  isSaving: boolean;
}

export const surgeryState: SurgeryState = {
  error: null,
  status: '',
  isLoaded: false,
  isLoading: false,
  orders: [],
  historical: [],
  lastOrder: null,
  current: null,
  isSaving: false,
  selected: null,
};

const createReducerSurgery = createReducer(
  surgeryState,

  // Obtener las cirugias de un paciente
  on(LOAD_SURGERIES_BY_PATIENT, (state) => ({ ...state, isLoading: true })),
  on(LOAD_SURGERIES_BY_PATIENT_OK, (state, { orders: orders }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    orders: [...orders],
  })),
  on(LOAD_SURGERIES_BY_PATIENT_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  // Obtener las cirugias de un profesional
  on(LOAD_SURGERIES_BY_PROFESSIONAL, (state) => ({ ...state, isLoading: true })),
  on(LOAD_SURGERIES_BY_PROFESSIONAL_OK, (state, { orders: orders }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    historical: [...orders],
  })),
  on(LOAD_SURGERIES_BY_PROFESSIONAL_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(CLEAR_SURGERY, (state) => ({
    orders: [...state.orders],
    historical: [...state.historical],
    isLoading: false,
    isLoaded: false,
    error: null,
    status: '',
    selected: null,
    lastOrder: null,
    current: null,
    isSaving: false,
  })),

  on(NOTIFY_KEIRON, (state) => ({ ...state, isLoading: true })),
  on(NOTIFY_KEIRON_OK, (state) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    isSaving: true,
    status: 'Notificado a keiron',
  })),
  on(NOTIFY_KEIRON_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    current: null,
    isSaving: true,
    status: 'Ocurrio un error al intentar notificar a keiron',
    error: { ...payload },
  })),

  on(PUT_SURGERY_END, (state) => ({ ...state, isSaving: false })),
  on(PUT_SURGERY, (state) => ({ ...state, isLoading: true })),
  on(PUT_SURGERY_OK, (state, { surgery }) => {
    let orders = state.orders.map((ans) => {
      if (ans.id === surgery.id) return surgery;
      else return ans;
    });
    let history = state.historical.map((ans) => {
      if (ans.id === surgery.id) return surgery;
      else return ans;
    });

    const index = orders.findIndex((draft) => draft.id === surgery.id);
    if (index === -1) orders = [surgery, ...orders];
    const indexHistory = history.findIndex((draft) => draft.id === surgery.id);
    if (indexHistory === -1) history = [surgery, ...history];

    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      isSaving: true,
      status: 'Registro guardado con éxito',
      orders: [...orders],
      historical: [...history],
      lastOrder: { ...surgery },
      current: { ...surgery },
    };
  }),
  on(PUT_SURGERY_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    current: null,
    isSaving: false,
    status: 'Ocurrio un error al intentar guardar el registro',
    error: { ...payload },
  })),

  on(SELECTED_SURGERY, (state, { selected }) => ({ ...state, selected: { ...selected } })),
  on(UNSELECT_SURGERY, (state) => ({ ...state, selected: null })),

);

export const surgeryReducer = (state: SurgeryState, action: Action) => createReducerSurgery(state, action);
