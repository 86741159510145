import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { API_URIS } from '@shared/constants/api-uris.const';
import { PCV2Request } from '@shared/interfaces/request.interface';
import { StatusService } from '@shared/services/status.service';

const URI_STORAGE: string = API_URIS.storage;
const BASE_API_PCV2: string = environment.baseApiPCv2;
const URI_CLINICAL_FIND: string = API_URIS.clinical.search;

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  url: SafeResourceUrl;

  constructor(private httpClient: HttpClient, public sanitizer: DomSanitizer, private statusService: StatusService) {
  }

  findByGsi(gsiMultiCondition1St: string): Observable<any[]> {
    const gsiEncoded = encodeURIComponent(gsiMultiCondition1St);
    const endpoint = `${BASE_API_PCV2}/${URI_CLINICAL_FIND}/${gsiEncoded}`;

    return this.httpClient.get<PCV2Request>(endpoint).pipe(map(({ data }: { data: any[] }) => data));
  }

  findByGsi1(gsiMultiCondition1St: string): Observable<any[]> {
    const gsiEncoded = encodeURIComponent(gsiMultiCondition1St);
    const endpoint = `${BASE_API_PCV2}/${URI_CLINICAL_FIND}/${gsiEncoded}`;

    return this.httpClient.get<PCV2Request>(endpoint).pipe(map(({ data }: { data: any[] }) => data));
  }

  findByType(patientId: string, type: string): Observable<any[]> {
    patientId = encodeURIComponent(patientId);
    const endpoint = `clinico/v1/${type}/pacientes/${patientId}`;

    return this.httpClient
      .get<PCV2Request>(`${BASE_API_PCV2}/${endpoint}`)
      .pipe(map(({ data }: { data: any[] }) => data), catchError(this.statusService.catchError));
  }

  findByType2(patientId: string, type: string): Observable<any[]> {
    //TODO buscar el nombre que corresponda o al ajustar las uri se debe borrar este o el antiguo
    patientId = encodeURIComponent(patientId);
    const endpoint = `${type}/v1/pacientes/${patientId}`;

    return this.httpClient
      .get<PCV2Request>(`${BASE_API_PCV2}/${endpoint}`)
      .pipe(map(({ data }: { data: any[] }) => data));
  }

  deleteByIds(type: string, indication: any): Observable<any> {
    const endpoint = `clinico/v1/${type}/${indication.id}/pacientes/${indication.patient.id}`;
    return this.httpClient.delete(`${BASE_API_PCV2}/${endpoint}`).pipe(map(({ data }: { data: any[] }) => data));
  }

  deleteByIds2(type: string, indication: any): Observable<any> {
    //TODO buscar el nombre que corresponda o al ajustar las uri se debe borrar este o el antiguo
    const endpoint = `${type}/v1/${indication.id}/pacientes/${indication.patient.id}`;
    return this.httpClient.delete(`${BASE_API_PCV2}/${endpoint}`).pipe(map(({ data }: { data: any[] }) => data));
  }

  getPdf(bucket: string, path: string, key: string): Observable<any> {
    const endpoint = `${URI_STORAGE}/archivosbase/${bucket}/${path}/${key}`;
    return this.httpClient.get<PCV2Request>(`${BASE_API_PCV2}/${endpoint}`);
  }

  getUrlPresigned(bucket: string, path: string, key: string): Observable<any> {
    const endpoint = `${URI_STORAGE}/archivos/${bucket}/${path}/${key}`;
    return this.httpClient.get<PCV2Request>(`${BASE_API_PCV2}/${endpoint}`);
  }
}
