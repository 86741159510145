import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PCV2Request } from '@shared/interfaces/request.interface';
import { MedicalSpecialty, ResponseSpecilityPRM, Specialty } from '@shared/interfaces/specialties.interface';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map, pluck, retry } from 'rxjs/operators';

const BASE_API: string = environment.baseApiPCv2;

interface ServiceParams {
  areaId: string;
  professionalId: string;
}
@Injectable({
  providedIn: 'root',
})
export class SpecialtiesService {
  constructor(private httpClient: HttpClient) {}

  getSpecialties(idProfessional: string): Observable<MedicalSpecialty[]> {
    const suffixURL = `sufixURL=Resources(${idProfessional})/GetSpecialities?$count=true&$orderby=Name`;
    return this.httpClient
      .get<PCV2Request>(`${BASE_API}/proxy/v1?${suffixURL}`)
      .pipe(map(({ data }: { data: MedicalSpecialty[] }) => data));
  }

  findByProfessionalAndArea(params: ServiceParams): Observable<Specialty[]> {
    const { areaId, professionalId } = params;
    const uri = `Services?&$expand=Speciality&$select=Id,Name,AreaId,SpecialityId`;
    const filter = `&$filter=Resources/any(x: x/Id eq ${professionalId}) and AreaId eq ${areaId}`;
    const endpoint = encodeURIComponent(`${uri}${filter}`);

    return this.httpClient
      .get(`${BASE_API}/proxy/v1?sufixURL=${endpoint}`)
      .pipe(retry(3), pluck('data'), map(this.formatSpecialities));
  }

  findByEntitiesId(params: ServiceParams): Observable<Specialty[]> {
    const { areaId, professionalId } = params;
    let suffixURL = `Resources(${professionalId})/GetSpecialities?$filter=Services/any( x: x/AreaId eq ${areaId})`;
    suffixURL = encodeURIComponent(suffixURL);
    return this.httpClient
      .get<ResponseSpecilityPRM[]>(`${BASE_API}/proxy/v1?sufixURL=` + suffixURL)
      .pipe(retry(3), pluck('data'), map(this.formatSpecialities));
  }

  getSpecialitiesForList(professionalId: string) {
    const suffixURL = `Resources(${professionalId})/GetSpecialities?$count=true&$orderby=Name`;
    return this.httpClient.get(`${BASE_API}/proxy/v1?sufixURL=` + suffixURL).pipe(
      pluck('data'),
      map(this.formatSpecialitiesForList),
    );
  }

  getSpecialtiesBox(idProfessional: string, areaId: string): Observable<Specialty[]> {
    // eslint-disable-next-line max-len
    let suffixURL = `Services?&$expand=Speciality&$select=Id,Name,AreaId,SpecialityId&$filter=Resources/any(x: x/Id eq ${idProfessional}) and AreaId eq ${areaId}`;
    suffixURL = encodeURIComponent(suffixURL);
    return this.httpClient
      .get<PCV2Request>(`${BASE_API}/proxy/v1?sufixURL=${suffixURL}`)
      .pipe(retry(3), pluck('data'), map(this.formatSpecialitiesBox));
  }
  getSpecialtiesAndServices(params: ServiceParams): Observable<Specialty[]> {
    const { areaId, professionalId } = params;
    // eslint-disable-next-line max-len
    let suffixURL = `Services?&$expand=Speciality&$select=Id,Name,AreaId,SpecialityId&$filter=Resources/any(x:x/Id eq ${professionalId})and AreaId eq ${areaId}`;
    suffixURL = encodeURIComponent(suffixURL);
    return this.httpClient
      .get(`${BASE_API}/proxy/v1?sufixURL=` + suffixURL)
      .pipe(retry(3), pluck('data'), map(this.formatSpecialitiesBox));
  }

  private formatSpecialities(specialtiesPRM: ResponseSpecilityPRM[]): Specialty[] {
    return specialtiesPRM.map((response) => ({
      service: response.Name,
      id: response.Speciality?.Id,
      name: response.Speciality?.Name,
      shortId: response.Speciality?.ShortId,
      cancelled: response.Speciality?.Cancelled,
    }));
  }

  private formatSpecialitiesForList(specialtiesPRM: ResponseSpecilityPRM[]): Specialty[] {
    return specialtiesPRM.map((response) => ({
      service: '',
      id: response.Id,
      name: response.Name,
      shortId: response.ShortId,
      cancelled: response.Cancelled,
    }));
  }

  private formatSpecialitiesBox(specialtiesPRM: ResponseSpecilityPRM[]): Specialty[] {
    return specialtiesPRM.map((response) => ({
      service: response.Name,
      id: response.Speciality?.Id,
      name: response.Speciality?.Name,
      shortId: response.Speciality?.ShortId,
      cancelled: response.Speciality?.Cancelled,
    }));
  }
}
