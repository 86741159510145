export const MAIL_TEMPLATE_ADMISSIONS = `
<div class="es-wrapper-color" style="background-color: #fcfcfc">
    <!--[if gte mso 9]>
      <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
        <v:fill type="tile" color="#FCFCFC"></v:fill>
      </v:background>
    <![endif]-->
    <!-- header ########################################################-->

    <table
      cellpadding="0"
      cellspacing="0"
      class="es-header"
      align="center"
      style="
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
        border-collapse: collapse;
        border-spacing: 0px;
        table-layout: fixed !important;
        width: 100%;
        background-color: transparent;
        background-repeat: repeat;
        background-position: center top;
      "
    >
      <tr>
        <td align="center" style="padding: 0; margin: 0">
          <table
            bgcolor="#ffffff"
            class="es-header-body"
            align="center"
            cellpadding="0"
            cellspacing="0"
            style="
              mso-table-lspace: 0pt;
              mso-table-rspace: 0pt;
              border-collapse: collapse;
              border-spacing: 0px;
              background-color: transparent;
              width: 700px;
            "
          >
            <tr>
              <td
                align="left"
                style="
                  margin: 0;
                  padding-top: 10px;
                  padding-bottom: 10px;
                  padding-left: 20px;
                  padding-right: 20px;
                "
              >
                <table
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                  style="
                    mso-table-lspace: 0pt;
                    mso-table-rspace: 0pt;
                    border-collapse: collapse;
                    border-spacing: 0px;
                  "
                >
                  <tr>
                    <td
                      class="es-m-p0r"
                      valign="top"
                      align="center"
                      style="padding: 0; margin: 0; width: 560px"
                    >
                      <table
                        cellpadding="0"
                        cellspacing="0"
                        width="100%"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                        "
                      >
                        <tr>
                          <td>
                            <img
                              src="https://s3.us-west-2.amazonaws.com/recursosweb.pub.prd.usw2/imagenes/line-header.png"
                              alt=""
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            align="center"
                            style="
                              padding: 0;
                              margin: 0;
                              padding-bottom: 20px;
                              padding-top: 10px;
                            "
                          >
                          <br />
                            <img
                              src="https://www.ucchristus.cl/assets/images/logo.png"
                              alt="Logo"
                              style="border: 0; display: block"
                              width="292"
                            />
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <!-- end header ################################################################################################################-->
    __content__
    <!-- Footer --------------------------------------------------------------------------------------------------------------->
    <table align="center" cellpadding="0" cellspacing="15" width="700">
      <tr>
        <td
          align="center"
          width="500"
          style="
            font-family: Tahoma, Ginebra, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 13px;
            text-align: center;
            color: #868686;
          "
        >
          Por favor no respondas a este correo electrónico. <br />
          Si presentas algún inconveniente, comunícate al +56227604266 , de
          lunes a viernes, entre las 09:00 a 20:00 hrs.
          <br /><br />
        </td>
      </tr>
      <tr>
        <td>
          <img
            src="https://s3.us-west-2.amazonaws.com/recursosweb.pub.prd.usw2/imagenes/line-footer.png"
            alt=""
          />
        </td>
      </tr>
    </table>

    <!-- End footer-->
  </div>
`;
