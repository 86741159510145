import { buildContentPdfHospital } from '@clinical/hospital/utils/build-pdf-hospital';
import { DEFAULT_PDF_POSITION_SIGNATURE } from '@shared/constants/app.const';
import { PrintedOnPage, PrintMode } from '@shared/interfaces/paperwork.interface';
import { DELETE_HOSPITALIZATION, PUT_HOSPITALIZATION } from '@store/actions';
import { HOSPITALIZATION_ROUTES } from '@clinical/hospital/hospital.routes.module';

const workflow = {
  isSigned     : true,
  isStored     : true,
  isSaving: true,
  hasNextControl: true,
  notifications: [
    {
      type    : 'email',
      entity  : 'patient',
      subject : 'Notificaciones UC CHRISTUS | Formulario Médico',
      template: 'generic',
    },
  ],
};

const pdf = {
  isPreview: true,
  header   : {logo: PrintedOnPage.last, title: PrintedOnPage.all, professional: PrintedOnPage.all},
  patient  : {type: PrintMode.full, printed: PrintedOnPage.all},
  footer   : {
    signature: {printed: PrintedOnPage.all, position: DEFAULT_PDF_POSITION_SIGNATURE},
    qr       : {right: {url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web'}},
  },
};

export const PAPERWORK_HOSPITAL_DEFINITION = {
  prefix         : 'HOS',
  module         : 'orden',
  actions        : {
    put   : PUT_HOSPITALIZATION,
    delete: DELETE_HOSPITALIZATION,
  },
  hospitalization: {
    suffix: 'ODH',
    group            : 'orden de hospitalización',
    docTitle         : 'Orden de Hospitalización',
    submodule: HOSPITALIZATION_ROUTES.order,
    getBodyDefinition: buildContentPdfHospital,
    workflow,
    config           : pdf,
  },
};
