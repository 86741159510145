import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { format } from 'date-fns';

import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducers';
import { DEL_ANAMNESIS, PUT_ANAMNESIS } from 'src/app/store/actions';

import { PdfAnamnesisPreviewComponent } from '../anamnesis-pdf-preview/pdf-anamnesis-preview.component';
import { DiagnosticFinderComponent } from '../diagnostics-finder/diagnostic-finder.component';

import { AnamnesisTemplate } from '@components/anamnesis/constants/anamnesis.const';

import { PdfService } from '@shared/helpers/pdf.helper';
import { FormFieldsValidate } from '@core/reactive-forms/form-fields-validate.helper';

import { Patient } from '@components/patient/interfaces/patient.interface';
import { Professional } from '@auth/interfaces/professional.interface';
import { PDFDefinition, PDFWrapper } from '@shared/interfaces/pdf.interface';
import { Anamnesis, Diagnostic } from '@shared/interfaces/anamnesis.interface';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-anamnesis-oncologic-frm',
  templateUrl: './anamnesis-oncological-frm.component.html',
  styleUrls: ['./anamnesis-oncological-frm.component.scss'],
})
export class AnamnesisOncologicalComponent implements OnInit, OnDestroy {
  @Input() isWriteMode: boolean;
  @Input() anamnesis: Anamnesis;
  @Input() template: AnamnesisTemplate;
  @Input() diagnostics: Diagnostic[] = [];

  frmAnamnesisOncological: UntypedFormGroup;
  destroy = new Subject();
  error: string;
  isLoading: boolean;
  isLoaded: boolean;
  patient: Patient;
  anamnesisFrm: Anamnesis;
  professional: Professional;

  constructor(
    private store: Store<AppState>,
    private pdfService: PdfService,
    private formBuilder: UntypedFormBuilder,
    public modalController: ModalController,
    private authService: AuthService,
  ) {}

  async ngOnInit() {
    const anamnesis = { ...this.anamnesis };

    this.readStoreAnamnesis();
    this.readStorePatient();
    this.buildForm(anamnesis);
    this.saveOnChange();
  }

  ngOnDestroy() {
    this.destroy.next('next');
    this.destroy.complete();
  }

  buildForm(anamnesis: Anamnesis): void {
    const { name, selector } = this.template;
    const currentDate = format(new Date(), 'yyyy-MM-dd');

    this.frmAnamnesisOncological = this.formBuilder.group({
      isDraft: true,
      startTreatment: currentDate,
      endTreatment: currentDate,
      discharge: FormFieldsValidate.requiredMinLength(1),
      procedure: FormFieldsValidate.requiredMinLength(10),
      indications: FormFieldsValidate.requiredMinLength(10),
      template: name,
      notes: [],
      diagnostics: [],
      center: {
        id: '',
        name: 'Nombre del Centro',
      },
    });

    if (selector === 'radioterapia')
      this.frmAnamnesisOncological = this.formBuilder.group({
        ...this.frmAnamnesisOncological.controls,
        history: FormFieldsValidate.requiredMinLength(10),
        stage: FormFieldsValidate.requiredMinLength(10),
        tolerance: FormFieldsValidate.requiredMinLength(10),
        cc: FormFieldsValidate.requiredMinLength(10),
      });

    if (selector === 'quimioterapia')
      this.frmAnamnesisOncological = this.formBuilder.group({
        ...this.frmAnamnesisOncological.controls,
        anamnesis: FormFieldsValidate.requiredMinLength(10),
      });

    if (anamnesis) {
      this.diagnostics = anamnesis.diagnostics;
      this.frmAnamnesisOncological.patchValue({ ...anamnesis });
    }
  }

  readStorePatient(): void {
    this.store
      .select('patient')
      .pipe(takeUntil(this.destroy))
      .subscribe(({ patient, isLoading, isLoaded, error }) => {
        this.patient = patient;
        this.isLoading = isLoading;
        this.isLoaded = isLoaded;
        this.error = error;
      });
  }

  readStoreAnamnesis(): void {
    this.store
      .select('anamnesis')
      .pipe(takeUntil(this.destroy))
      .subscribe(async ({ anamnesis, isLoading, isLoaded, error }) => {
        this.anamnesis = anamnesis.find((ans) => ans.isDraft === true);
        this.isLoading = isLoading;
        this.isLoaded = isLoaded;
        this.error = error;
      });
  }

  deleteDiagnostic(index: number) {
    const diagnostics = [...this.diagnostics];
    diagnostics.splice(index, 1);
    this.diagnostics = diagnostics;
    this.frmAnamnesisOncological.patchValue({ diagnostics });
  }

  async openDiagnosticFinder() {
    const modal = await this.modalController.create({
      component: DiagnosticFinderComponent,
      cssClass: 'cmodal-xl',
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();
    const diagnostic: Diagnostic[] = data;
    if (diagnostic) this.diagnostics = [...diagnostic];
  }

  saveOnChange() {
    this.frmAnamnesisOncological.valueChanges
      .pipe(takeUntil(this.destroy), debounceTime(1000))
      .subscribe(async (frm: Anamnesis) => {
        if (this.anamnesis) frm = { ...this.anamnesis, ...frm };

        if (this.patient) {
          const { id, names, surnames, documentNumber, email } = this.patient;
          frm.patient = { id, names, surnames, documentNumber, email };
        }

        if (this.professional) {
          const { id, names, surnames, rut, specialty } = this.authService.professionalRef;
          frm.professional = { id, names, surnames, rut, specialty };
        }

        const profesionalId = this.professional.id;
        frm.gsiOne = `PRO#${profesionalId}#isDraft#true`;

        if (!frm.isDraft) delete frm.gsiOne;
        this.anamnesis = { ...frm };
        this.store.dispatch(PUT_ANAMNESIS({ frm }));
      });
  }

  onSubmit() {
    this.frmAnamnesisOncological.patchValue({ gsiOne: '', isDraft: false });
  }

  duplicate(anamnesis: Anamnesis) {
    this.isWriteMode = true;

    const { id, names, surnames } = this.authService.professionalRef;
    anamnesis.professional = {
      id,
      names,
      surnames,
      rut: '',
      email: '',
    };
    anamnesis.center = {
      id: '',
      name: 'Nombre del Centro',
    };

    const profesionalId = this.professional.id;
    anamnesis.gsiOne = `PRO#${profesionalId}#isDraft#true`;
    anamnesis.isDraft = true;
    anamnesis.notes = [];
    this.frmAnamnesisOncological.patchValue(anamnesis);
  }

  async presentPDF(isPreview: boolean) {
    const isFormValid = this.frmAnamnesisOncological.valid;
    if (!isFormValid) return;

    const title = this.template.name;
    const pdfWrapper = await this.pdfDefinition(isPreview, title);
    const modal = await this.modalController.create({
      component: PdfAnamnesisPreviewComponent,
      cssClass: 'modal-xxl-float-left',
      componentProps: { pdfWrapper, isPreview, anamnesisToSave: this.anamnesis },
      backdropDismiss: false,
    });
    await modal.present();

    const { data: isSaving } = await modal.onDidDismiss();
    if (isSaving) this.dismiss();
  }

  async pdfDefinition(isPreview: boolean, title: string) {
    const docDefinition: PDFDefinition = {
      title,
      isPreview,
      valuesToSave: null,
      patient: this.patient,
      professional: this.authService.professionalRef,
      content: this.buildContentPdf(),
      isSigned: false,
      layoutDef: {
        footerOnLastPage: true,
      },
    };

    const pdfWrapper: PDFWrapper = {
      metadata: docDefinition,
      docDefinition: 'await this.pdfService.buildDocDefinition(docDefinition)',
    };
    return pdfWrapper;
  }

  buildContentPdf() {
    const DIAGNOSTICS_TITLE = {
      fontSize: 12,
      margin: [0, 5],
      stack: [
        { text: `DIAGNÓSTICO:`, bold: true },
        { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1, color: 'gray' }] },
      ],
    };

    if (!this.diagnostics) {
      this.diagnostics = [];
      DIAGNOSTICS_TITLE.stack = [];
      delete DIAGNOSTICS_TITLE.margin;
    }
    const DIAGNOSTICS = this.diagnostics.map((diagnostic) => {
      const { code, descriptor, observations } = diagnostic;

      let OBSERVATIONS: any = { text: [{ text: `OBSERVACIONES: `, bold: true }, `${observations}`] };
      if (!observations) OBSERVATIONS = [];

      return [
        {
          fontSize: 9,
          alignment: 'justify',
          stack: [
            { text: [{ text: `DIAGNÓSTICO CIE-10  [${code}] - `, bold: true }, `${descriptor}`] },
            OBSERVATIONS,
            { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.5, color: 'gray' }] },
          ],
        },
      ];
    });

    let { discharge, anamnesis, indications, state, endTreatment, startTreatment, tolerance, procedure, cc } =
      this.frmAnamnesisOncological.value;
    if (state) state = [{ text: `ESTADIO:`, style: 'label' }, { text: `${state}` }];
    if (discharge) discharge = [{ text: `MOTIVO DE CONSULTA:`, style: 'label' }, { text: `${discharge}` }];
    if (anamnesis) anamnesis = [{ text: `DETALLE DEL DIAGNÓSTICO:`, style: 'label' }, { text: `${anamnesis}` }];
    if (procedure)
      procedure = [{ text: `PROCEDIMIENTO O TRATÁMIENTO REALIZADO:`, style: 'label' }, { text: `${procedure}` }];
    if (tolerance) tolerance = [{ text: `TOLERANCIA:`, style: 'label' }, { text: `${tolerance}` }];
    if (startTreatment)
      startTreatment = [{ text: `INCIO TRATAMIENTO:`, style: 'label' }, { text: `${startTreatment}` }];
    if (endTreatment) endTreatment = [{ text: `FIN TRATAMIENTO:`, style: 'label' }, { text: `${endTreatment}` }];
    if (indications) indications = [{ text: `INDICACIONES:`, style: 'label' }, { text: `${indications}` }];
    if (cc) cc = [{ text: `CON COPIA:`, style: 'label' }, { text: `${cc}` }];

    const ANAMNESIS_FIELDS = {
      fontSize: 10,
      alignment: 'justify',
      stack: [state, discharge, anamnesis, procedure, tolerance, startTreatment, endTreatment, indications, cc],
    };

    return [DIAGNOSTICS_TITLE, DIAGNOSTICS, ANAMNESIS_FIELDS];
  }

  delete() {
    const anamnesisToDelete = this.anamnesis;
    this.store.dispatch(DEL_ANAMNESIS({ anamnesisToDelete }));
    if (this.isLoaded) this.dismiss();
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
