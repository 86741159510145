import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { PCV2Request } from '@shared/interfaces/request.interface';
import { API_URIS } from '@shared/constants/api-uris.const';
import { Expertise } from '../interfaces/expertise.interface';

const BASE_API_PCV2: string = environment.baseApiPCv2;
const URI_CLINICAL_EXPERTISES: string = API_URIS.clinical.expertises;

@Injectable({
  providedIn: 'root',
})
export class ClinicalExpertiseService {
  constructor(private httpClient: HttpClient) {}

  save(body: Expertise): Observable<Expertise> {
    return this.httpClient
      .post<PCV2Request>(`${BASE_API_PCV2}/${URI_CLINICAL_EXPERTISES}`, body)
      .pipe(map(({ data }: { data: Expertise }) => data));
  }

  getListExpertise(patientId: string): Observable<any[]> {
    return this.httpClient
      .get<PCV2Request>(`${BASE_API_PCV2}/${URI_CLINICAL_EXPERTISES}/pacientes/PAT%23${patientId}`)
      .pipe(map(({ data }: { data: any[] }) => data));
  }
}
