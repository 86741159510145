import { buildContentPdfExams } from '@clinical/indications/utils/build-pdf-exams';
import { buildContentPdfOtorrinolaringologist } from '@clinical/indications/utils/build-pdf-otorhinolaryngologist';
import { buildContentPdfVaccines } from '@clinical/indications/utils/build-pdf-vaccines';
import { INDICATIONS_ROUTES } from '@clinical/indications/indications-routes.module';
import { DEFAULT_PDF_POSITION_SIGNATURE } from '@shared/constants/app.const';
import { PrintedOnPage, PrintMode } from '@shared/interfaces/paperwork.interface';

import { DELETE_INDICATION, PUT_INDICATION } from '@store/actions';
import { buildContentPdfDermatologia } from '../utils/build-pdf-dermatology';
import { buildContentPdfEye } from '../utils/build-pdf-ophthalmology';
import { buildContentPdfEyeExam } from '../utils/build-pdf-ophthalmology-exam';
import { buildContentPdfTechinicalAssist } from '../utils/build-pdf-technical-assist';

const workflow = {
  isSigned: true,
  isStored: true,
  isSaving      : true,
  hasNextControl: true,
  notifications: [
    {
      type    : 'email',
      entity  : 'patient',
      subject : 'Notificaciones UC CHRISTUS | Indicaciones Médicas',
      template: 'indications',
    },
  ],
};

const pdf                                     = {
  isPreview: true,
  header   : {logo: PrintedOnPage.all, title: PrintedOnPage.all, professional: PrintedOnPage.all},
  patient  : {type: PrintMode.full, printed: PrintedOnPage.all},
  footer   : {
    signature: {printed: PrintedOnPage.all, position: DEFAULT_PDF_POSITION_SIGNATURE},
    qr       : {right: {url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web'}},
  },
};
export const PAPERWORK_INDICATIONS_DEFINITION = {
  prefix               : 'IND',
  module               : 'indicaciones',
  actions              : {
    put   : PUT_INDICATION,
    delete: DELETE_INDICATION,
  },
  ophthalmologic       : {
    suffix           : 'OFT',
    group            : 'receta de lentes',
    docTitle         : 'receta de lentes',
    submodule: INDICATIONS_ROUTES.ophthalmology,
    getBodyDefinition: buildContentPdfEye,
    workflow,
    config           : pdf,
  },
  examOphthalmologic   : {
    suffix           : 'EOF',
    group            : 'orden de examen oftalmología',
    docTitle         : 'orden de examen oftalmología',
    submodule: INDICATIONS_ROUTES.ophthalmologyExam,
    getBodyDefinition: buildContentPdfEyeExam,
    workflow,
    config           : pdf,
  },
  otorhinolaryngologist: {
    suffix           : 'OTR',
    group            : 'solicitud de exámenes y comité de otorrinolaringología',
    docTitle         : 'solicitud de exámenes y comité de otorrinolaringología',
    submodule: INDICATIONS_ROUTES.otorhinolaryngology,
    getBodyDefinition: buildContentPdfOtorrinolaringologist,
    workflow,
    config           : pdf,
  },
  vaccines             : {
    suffix           : 'VAC',
    group            : 'orden de vacunas',
    docTitle         : 'orden de vacunas',
    submodule: INDICATIONS_ROUTES.vaccines,
    getBodyDefinition: buildContentPdfVaccines,
    workflow,
    config           : pdf,
  },
  technicalAssistance  : {
    suffix           : 'TAS',
    group            : 'ayudas técnicas y órtesis',
    docTitle         : 'ayudas técnicas y órtesis',
    submodule        : INDICATIONS_ROUTES.techHelpOrthosis,
    getBodyDefinition: buildContentPdfTechinicalAssist,
    workflow,
    config           : pdf,
  },
  dermatology          : {
    suffix           : 'DRM',
    group            : 'orden procedimientos dermatología',
    docTitle         : 'orden procedimientos dermatología',
    submodule        : INDICATIONS_ROUTES.dermatology,
    getBodyDefinition: buildContentPdfDermatologia,
    workflow,
    config           : pdf,
  },
  exams                : {
    suffix           : 'EXA',
    group            : 'PROCEDIMIENTOS IMAGENES LABORATORIO',
    docTitle: '', // usa `group` especifico (PROCEDIMIENTOS IMAGENES o LABORATORIO) como título del documento
    submodule: INDICATIONS_ROUTES.exams,
    getBodyDefinition: buildContentPdfExams,
    workflow: {
      ...workflow,
      notifications: [
        {
          type: 'email',
          entity: 'patient',
          subject: 'Notificaciones UC CHRISTUS | Indicaciones Médicas',
          template: 'exams',
        },
        {
          type: 'email',
          entity: 'indications.exams.images.team',
          subject: 'Notificaciones UC CHRISTUS | Indicaciones Médicas | $patient$',
          template: 'examsImagesTeam',
        },
      ],
    },
    config           : pdf,
  },
};
