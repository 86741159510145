import { Action, createReducer, on } from '@ngrx/store';

import { CLEAR_ONCOLOGY, PUT_ONCOLOGY_END, SELECTED_ONCOLOGY, UNSELECT_ONCOLOGY } from '@store/actions';
import { DELETE_ONCOLOGY, DELETE_ONCOLOGY_ERR, DELETE_ONCOLOGY_OK } from '@store/actions';
import { PUT_ONCOLOGY, PUT_ONCOLOGY_ERR, PUT_ONCOLOGY_OK } from '@store/actions';
import { LOAD_ONCOLOGY_BY_PATIENT, LOAD_ONCOLOGY_BY_PATIENT_ERR, LOAD_ONCOLOGY_BY_PATIENT_OK } from '@store/actions';
import {
  LOAD_ONCOLOGY_BY_PROFESSIONAL,
  LOAD_ONCOLOGY_BY_PROFESSIONAL_ERR,
  LOAD_ONCOLOGY_BY_PROFESSIONAL_OK,
} from '@store/actions';
import { BasePCV2 } from '@shared/interfaces/base.interface';
import { Paperwork } from '@shared/interfaces/paperwork.interface';

export interface OncologyState {
  error: any;
  status: string;
  isLoaded: boolean;
  isLoading: boolean;
  oncology: Paperwork[];
  historical: Paperwork[];
  selected: Paperwork;
  lastOncology: Paperwork;
  current: Paperwork;
  isSaving: boolean;
}

export const oncologyState: OncologyState = {
  error: null,
  status: '',
  isLoaded: false,
  isLoading: false,
  oncology: [],
  historical: [],
  selected: null,
  lastOncology: null,
  current: null,
  isSaving: false,
};

const createReducerOncology = createReducer(
  oncologyState,

  // Obtener los formularios por paciente
  on(LOAD_ONCOLOGY_BY_PROFESSIONAL, (state) => ({ ...state, isLoading: true })),
  on(LOAD_ONCOLOGY_BY_PROFESSIONAL_OK, (state, { oncology }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    historical: [...oncology],
  })),
  on(LOAD_ONCOLOGY_BY_PROFESSIONAL_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  // Obtener los formularios por profesional
  on(LOAD_ONCOLOGY_BY_PATIENT, (state) => ({ ...state, isLoading: true })),
  on(LOAD_ONCOLOGY_BY_PATIENT_OK, (state, { oncology }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    oncology: [...oncology],
  })),
  on(LOAD_ONCOLOGY_BY_PATIENT_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(CLEAR_ONCOLOGY, (state) => ({
    oncology: [...state.oncology],
    historical: [...state.historical],
    isLoading: false,
    isLoaded: false,
    error: null,
    status: '',
    selected: null,
    lastOncology: null,
    current: null,
    isSaving: false,
  })),

  on(PUT_ONCOLOGY_END, (state) => ({ ...state, isSaving: false })),
  on(PUT_ONCOLOGY, (state) => ({ ...state, isLoading: true })),
  on(PUT_ONCOLOGY_OK, (state, { oncology }) => {
    let oncologies = state.oncology.map((ans) => {
      if (ans.id === oncology.id) return oncology;
      else return ans;
    });
    let history = state.historical.map((ans) => {
      if (ans.id === oncology.id) return oncology;
      else return ans;
    });

    const index = oncologies.findIndex((draft) => draft.id === oncology.id);
    if (index === -1) oncologies = [oncology, ...oncologies];
    const indexHistory = history.findIndex((draft) => draft.id === oncology.id);
    if (indexHistory === -1) history = [oncology, ...history];

    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      isSaving: true,
      status: 'Registro guardado con éxito',
      oncology: [...oncologies],
      historical: [...history],
      current: { ...oncology },
      lastOncology: { ...oncology },
    };
  }),
  on(PUT_ONCOLOGY_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    current: null,
    isSaving: false,
    status: 'Ocurrió un error al intentar guardar el registro',
    error: { ...payload },
  })),

  on(SELECTED_ONCOLOGY, (state, { selected, action }) => ({ ...state, selected: { ...selected }, status: action })),
  on(UNSELECT_ONCOLOGY, (state) => ({
    ...state,
    isLoaded: false,
    isLoading: false,
    status: '',
    selected: null,
  })),

  on(DELETE_ONCOLOGY, (state) => ({ ...state, isLoading: true })),
  on(DELETE_ONCOLOGY_OK, (state, { oncology }) => {
    let forms = [...state.oncology];
    let history = [...state.historical];
    const index = forms.findIndex((draft) => draft.id === oncology.id);
    const indexHistory = history.findIndex((draft) => draft.id === oncology.id);
    if (index !== -1) forms = [...forms.filter((draft) => draft.id !== oncology.id)];
    if (indexHistory !== -1) history = [...history.filter((draft) => draft.id !== oncology.id)];

    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      status: 'Formulario eliminado con éxito',
      oncology: [...forms],
      historical: [...history],
    };
  }),
  on(DELETE_ONCOLOGY_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    status: 'Ocurrió un error al intentar eliminar el formulario',
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
);

export const oncologyReducer = (state: OncologyState, action: Action) => createReducerOncology(state, action);
