import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as reproductiveActions from '../../../store/actions';
import { ReproductiveService } from '@clinical/reproductive/services/reproductive.service';
import {
  DELETE_REPRODUCTIVE,
  DELETE_REPRODUCTIVE_ERR,
  DELETE_REPRODUCTIVE_OK,
  LOAD_REPRODUCTIVE_BY_PATIENT,
  LOAD_REPRODUCTIVE_BY_PATIENT_ERR,
  LOAD_REPRODUCTIVE_BY_PATIENT_OK,
  LOAD_REPRODUCTIVE_BY_PROFESSIONAL,
  LOAD_REPRODUCTIVE_BY_PROFESSIONAL_ERR,
  LOAD_REPRODUCTIVE_BY_PROFESSIONAL_OK,
  PUT_REPRODUCTIVE_END,
  PUT_REPRODUCTIVE_OK,
} from '@store/actions';

import { ReportService } from '@components/records/services/report.service';

@Injectable()
export class ReproductiveEffects {
  putReproductive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reproductiveActions.PUT_REPRODUCTIVE),
      mergeMap(({ valuesToSave }) =>
        this.clinicalReproductiveService.save({ ...valuesToSave }).pipe(
          map((reproductive) => reproductiveActions.PUT_REPRODUCTIVE_OK({ reproductive })),
          catchError((err) => of(reproductiveActions.PUT_REPRODUCTIVE_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  clear$ = createEffect(() => this.actions$.pipe(ofType(PUT_REPRODUCTIVE_OK), map(() => PUT_REPRODUCTIVE_END())));


  loadReproductiveByPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_REPRODUCTIVE_BY_PATIENT),
      mergeMap(({ params }) =>
        this.reportService.findByType(params.patientId, params.type).pipe(
          map((briefs) => LOAD_REPRODUCTIVE_BY_PATIENT_OK({ brief: briefs })),
          catchError((err) => of(LOAD_REPRODUCTIVE_BY_PATIENT_ERR({ payload: err }))),
        ),
      ),
    ),
  );
  loadReproductiveByProfessional$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_REPRODUCTIVE_BY_PROFESSIONAL),
      mergeMap(({ gsiMultiCondition1St }) =>
        this.reportService.findByGsi(gsiMultiCondition1St).pipe(
          map((briefs) => LOAD_REPRODUCTIVE_BY_PROFESSIONAL_OK({ brief: briefs })),
          catchError((err) => of(LOAD_REPRODUCTIVE_BY_PROFESSIONAL_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  deleteReproductive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DELETE_REPRODUCTIVE),
      mergeMap((brief) =>
        this.reportService.deleteByIds('reproductivas', brief).pipe(
          map((item) => DELETE_REPRODUCTIVE_OK({ brief: item })),
          catchError((err) => of(DELETE_REPRODUCTIVE_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private clinicalReproductiveService: ReproductiveService,
    private reportService: ReportService,
  ) {
  }
}
