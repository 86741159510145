import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Area, AreaPRM } from '@shared/interfaces/area.interface';
import { PCV2Request } from '@shared/interfaces/request.interface';
import { API_URIS } from '@shared/constants/api-uris.const';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map, pluck, retry } from 'rxjs/operators';

const BASE_API_PCV2: string = environment.baseApiPCv2;
const URI_PROXY: string = API_URIS.proxy.prm;

@Injectable({
  providedIn: 'root',
})
export class AreaService {
  constructor(private httpClient: HttpClient) {}

  find(): Observable<Area[]> {
    return this.httpClient
      .get<PCV2Request>(`${BASE_API_PCV2}/${URI_PROXY}Areas?$select=Name,Id`)
      .pipe(retry(3), pluck('data'), map(this.formatAreas));
  }

  findByNames(params: string[]): Observable<Area[]> {
    let filter = '';
    params.map((area, index) => {
      if (index === 0) filter += `Name eq '${area}'`;
      else filter += ` or Name eq '${area}'`;
    });
    const endpoint = encodeURIComponent(`Areas?&$filter=${filter}`);
    return this.httpClient
      .get<PCV2Request>(`${BASE_API_PCV2}/${URI_PROXY}${endpoint}`)
      .pipe(retry(3), pluck('data'), map(this.formatAreas));
  }

  formatAreas(areasPRM: AreaPRM[]): Area[] {
    const areas = areasPRM.map((area) => ({
      id: area.Id,
      name: area.Name,
    }));
    return areas;
  }
}
