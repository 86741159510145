export const POSOLOGY = {
  receta : [
    'mg',
    'Kit',
    'Set',
    'Unidad',
    'Cintas',
    'Agujas',
    'Capsula',
    'Jeringas',
    'Lancetas',
    'Sensores',
    'Aplicación',
    'Reservorios',
  ],
  magistral: ['g', 'ml', 'mg', 'Gel', 'Gotas', 'Unidad', 'Capsula', 'Sobres', 'Aplicación'],
  general  : ['g', 'ml', 'mg', 'Unidad'],
};

export const MEASUREMENT_UNITS = {
  cucharadita: {singular: 'Cucharadita', plural: 'Cucharaditas'},
  reservorios: {singular: 'Reservorio', plural: 'Reservorios'},
  jeringas   : {singular: 'Jeringa', plural: 'Jeringas'},
  comprimido : {singular: 'Comprimido', plural: 'Comprimidos'},
  permanente : {singular: 'Permanencia', plural: 'Permanencia'},
  cucharada  : {singular: 'Cucharada', plural: 'Cucharadas'},
  ampolla    : {singular: 'Ampolla', plural: 'Ampollas'},
  cápsula    : {singular: 'Cápsula', plural: 'Cápsulas'},
  capsula    : {singular: 'Capsula', plural: 'Capsulas'},
  lanceta    : {singular: 'Lanceta', plural: 'Lancetas'},
  sensores   : {singular: 'Sensor', plural: 'Sensores'},
  semanas    : {singular: 'Semana', plural: 'Semanas'},
  aguja      : {singular: 'Aguja', plural: 'Agujas'},
  sobre      : {singular: 'Sobre', plural: 'Sobres'},
  cinta      : {singular: 'Cinta', plural: 'Cintas'},
  horas      : {singular: 'Hora', plural: 'Horas'},
  gotas      : {singular: 'Gota', plural: 'Gotas'},
  vial       : {singular: 'Vial', plural: 'Viales'},
  días       : {singular: 'Día', plural: 'Días'},
  meses      : {singular: 'Mes', plural: 'Meses'},
  kit        : {singular: 'Kit', plural: 'Kits'},
  set        : {singular: 'Set', plural: 'Sets'},
  sos        : {singular: 'SOS', plural: 'SOS'},
  mg         : {singular: 'mg', plural: 'mg'},
  gr         : {singular: 'gr', plural: 'gr'},
  ml         : {singular: 'ml', plural: 'ml'},
  g          : {singular: 'g', plural: 'g'},
};

export const getUnit = (unit, qty: number | string) => {
  if (!unit) return;
  if (!MEASUREMENT_UNITS[unit.toLowerCase()]) return unit;
  if (qty === 1) return MEASUREMENT_UNITS[unit.toLowerCase()].singular;
  if (qty === 0) return MEASUREMENT_UNITS[unit.toLowerCase()].singular;
  return MEASUREMENT_UNITS[unit.toLowerCase()].plural;
};
