import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'specialty',
})
export class SpecialtyPipe implements PipeTransform {
    transform(value: unknown, ...args: unknown[]): unknown {
        if (!value) return false;
        if ((value === 'Otra Especialidad' && args.includes(1) && value !== null || value === 'Seguimiento Orientacion Quirurgica' && args.includes(1)) && value !== null) return true;
        if (value !== 'Kinesiología' && value !== 'Terapia Ocupacional' && value !== 'Fonoaudiología' && value !== null && args.includes('NOOK')) return true;
        if (value === 'Kinesiología' && args.includes(3) && value !== null || value === 'Terapia Ocupacional' && args.includes(3) && value !== null || value === 'Fonoaudiología' && args.includes(3) && value !== null) return true;
        return null;
    }
}
