export const INDICATIONS_ROUTES = {
  exams                         : 'examenes/img-lab-pro',
  vaccines                      : 'vacunas',
  dermatology                   : 'dermatologia',
  ophthalmology                 : 'oftalmologicas',
  techHelpOrthosis              : 'ayudas-tecnicas-ortesis',
  ophthalmologyExam             : 'examen/oftalmologia',
  otorhinolaryngology           : 'otorrinolaringologia',
  historyPatientIndications     : 'historial/paciente',
  historyProfessionalIndications: 'historial/profesional',

};
