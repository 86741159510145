import {Patient} from '@components/patient/interfaces/patient.interface';
import {Action, createReducer, on} from '@ngrx/store';
import {
  CLEAR_PATIENT, GET_FAMILY_DOCTOR, GET_FAMILY_DOCTOR_ERR, GET_FAMILY_DOCTOR_OK,
  GET_PATIENT,
  GET_PATIENT_ERR,
  GET_PATIENT_INTEGRAL_HEALTH,
  GET_PATIENT_INTEGRAL_HEALTH_ERR,
  GET_PATIENT_INTEGRAL_HEALTH_OK,
  GET_PATIENT_MARK_ON_AGENDA,
  GET_PATIENT_MARK_ON_AGENDA_ERR,
  GET_PATIENT_MARK_ON_AGENDA_OK,
  GET_PATIENT_OK,
  UPDATE_PATIENT,
} from '../../../store/actions';

export interface PatientState {
  patient: Patient;
  isLoaded: boolean;
  isLoading: boolean;
  error: any;
  status: string;
}

export const patientState: PatientState = {
  patient  : null,
  isLoaded : false, // cambiar a false
  isLoading: false,
  error    : null,
  status   : '',
};

const createReducerPatient = createReducer(
  patientState,

  on(GET_PATIENT, (state) => ({...state, isLoading: true})),

  on(GET_PATIENT_INTEGRAL_HEALTH, (state) => ({
    ...state
  })),

  on(GET_PATIENT_OK, (state, {patient}) => ({
    ...state,
    isLoading: false,
    isLoaded : true,
    error    : null,
    patient  : {...patient},
    status   : 'cargado'
  })),

  on(GET_PATIENT_ERR, (state, {payload}) => ({
    ...state,
    isLoading: false,
    isLoaded : false,
    patient  : null,
    error    : {
      url    : payload.url,
      name   : payload.name,
      message: payload.message,
    },
  })),

  on(UPDATE_PATIENT, (state, {patient}) => ({
    ...state,
    isLoading: false,
    isLoaded : true,
    error    : null,
    patient  : {...state.patient, ...patient},
    status   : 'paciente actualizado',
  })),

  on(CLEAR_PATIENT, (state) => ({
    ...state,
    isLoading: false,
    isLoaded : false,
    patient  : null,
    error    : null,
  })),

  on(GET_PATIENT_INTEGRAL_HEALTH_OK, (state, {integralHealth}) => ({
    ...state,
    patient: {...state.patient, integralHealth: {...integralHealth}},
    status : 'actualizado'
  })),

  on(GET_PATIENT_INTEGRAL_HEALTH_ERR, (state, {payload}) => ({
    ...state,
    patient: {...state.patient, integralHealth: null},
    error  : {
      url    : payload.url,
      name   : payload.name,
      message: payload.message,
    },
  })),

  on(GET_FAMILY_DOCTOR, (state) => ({...state})),

  on(GET_FAMILY_DOCTOR_OK, (state, {familyDoctor}) => ({
    ...state,
    patient: {...state.patient, integralHealth: {...state.patient.integralHealth, familyDoctor: {...familyDoctor}}},
    status : 'actualizado'
  })),

  on(GET_FAMILY_DOCTOR_ERR, (state, {payload}) => ({
    ...state,
    patient: {...state.patient, integralHealth: {...state.patient.integralHealth, familyDoctor: null}},
    error  : {
      url    : payload.url,
      name   : payload.name,
      message: payload.message,
    },
    status : 'actualizado'
  })),

  on(GET_PATIENT_INTEGRAL_HEALTH_ERR, (state, {payload}) => ({
    ...state,
    error  : {
      url    : payload.url,
      name   : payload.name,
      message: payload.message,
    },
    patient: {...state.patient, integralHealth: {...state.patient.integralHealth, familyDoctor: null}},
  })),

  on(GET_PATIENT_MARK_ON_AGENDA, (state) => ({...state, isLoading: true})),

  on(GET_PATIENT_MARK_ON_AGENDA_OK, (state, {marksOnAgenda}) => ({
    ...state,
    isLoading: false,
    isLoaded : true,
    patient  : {...state.patient, marksOnAgenda},
    status   : 'actualizado'
  })),

  on(GET_PATIENT_MARK_ON_AGENDA_ERR, (state, {payload}) => ({
    ...state,
    isLoading: false,
    isLoaded : true,
    patient  : {...state.patient, markOnAgenda: null},
    error    : {url: payload.url, name: payload.name, message: payload.message},
  })),
);

export const patientReducer = (state: PatientState, action: Action) => createReducerPatient(state, action);
