export const examsTemplate = `
<div
  id=":vc"
  class="ii gt"
  jslog="20277; u014N:xr6bB; 1:WyIjdGhyZWFkLWY6MTc3ODM5MDk4ODU3OTM2MjA1OHxtc2ctZjoxNzc4MzkwOTg4NTc5MzYyMDU4IixudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxbXV0.; 4:WyIjbXNnLWY6MTc3ODM5MDk4ODU3OTM2MjA1OCIsbnVsbCxbXSxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxbXSxbXSxbXV0."
  ><div id=":os" class="a3s aiL msg5094942471179559977"
    ><u></u>

    <div
      style="
        box-sizing: border-box;
        font-weight: 400;
        font-size: 10pt;
        font-family: 'Montserrat', sans-serif;
        background: #F5F0FF;
        margin: 0 auto;
      ">
      <table
        class="m_5094942471179559977main-area"
        style="
          border-spacing: 0;
          width: 800px;
          margin: 0px auto;
          font-size: 10pt;
          background: #F5F0FF;
          padding: 0 24px 24px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        "
        ><tbody style="box-sizing: border-box">
          <tr style="box-sizing: border-box; display: none"><th style="box-sizing: border-box"></th></tr>
          <tr style="box-sizing: border-box">
            <td style="box-sizing: border-box; width: 100%">
              <table style="width: 100%; border-spacing: 0; "
                ><tbody style="box-sizing: border-box">
                  <tr style="box-sizing: border-box; display: none"><th style="box-sizing: border-box"></th></tr>
                  <tr style="box-sizing: border-box">
                    <td style="box-sizing: border-box; padding: 24px 0px; vertical-align: top; width: 50%">
                      <a
                        href="https://r.emma.ucchristus.cl/tr/cl/pzV4r3nTy15u8O5ta8cnjtl_9agZ95ZDlB_enx2Sn1OaaZg5IOOMPGZi7vAn13UHKJ7Xdk2szaNO6Kcb_A4dVHU_WfOdYdzIPljahpZ8oe2WLUv--oBln9PU2kJiC4tDVWoNIOJkdR_-xcwE_ygvy7ub3NHu-_UfHp-7VULfFqUqeU1ZIfSD6P5VAAdEn4CYvMkO1iEvM941iZ4OMEy9thox6FX4EQ"
                        rel="noopener"
                        style="box-sizing: border-box"
                        target="_blank"
                        data-saferedirecturl="https://www.google.com/url?q=https://r.emma.ucchristus.cl/tr/cl/pzV4r3nTy15u8O5ta8cnjtl_9agZ95ZDlB_enx2Sn1OaaZg5IOOMPGZi7vAn13UHKJ7Xdk2szaNO6Kcb_A4dVHU_WfOdYdzIPljahpZ8oe2WLUv--oBln9PU2kJiC4tDVWoNIOJkdR_-xcwE_ygvy7ub3NHu-_UfHp-7VULfFqUqeU1ZIfSD6P5VAAdEn4CYvMkO1iEvM941iZ4OMEy9thox6FX4EQ&amp;source=gmail&amp;ust=1697310166219000&amp;usg=AOvVaw2CR0SsXKODMlXzJhnOKuby"
                        ><img
                          class="m_5094942471179559977top-logo CToWUd"
                          border="0"
                          width=""
                          src="https://ci5.googleusercontent.com/proxy/1Z2v_vkp_Wk2FIqUpgOwtZcXQs1F9NvQOSf6TrAWUK1SOWNPkVFJXK9GM192DXLseVTDAe6NpiI7s8tL3-KKHl7aSR5iimKjt43lgWjRxiIVsVJQ-BUZ88UYOvPQc0bcfNOhggTwODXCJleiZXMAFF3GGPQ53ajIe_niTW9-VgwcvlrYGsHs-Jr9q8271pepZkqklodmm3gqJKBGx9f6FApzEO26Bex9vyeEyJiUcjvvfC3wd-FM4UPoxtthfz1GRtaYH9GXKBvNV4zS5ljMBB06xH7JvJoaLGZyTNZz8wwSzp0LjlFWpa3lTRCnOqq7UGky-m18jSdyuongc3d3pg_lWN0R3Vwb7Om8LKmCEx-X4MnJyHkv8kd5W5W1XWhvQ7iNL7rSd11ilhBDoxhM1rgmQhuv4KbDDc3xiBGVQkhxrH0ylDGtt2-Y5ClaEp55pqKvPAsTHeY5IxPICmdHzZeWUmLKkBzQNxS_ByAUSCATtOgBLsK5PHegrotf-g_UzL8-WDY7Gck=s0-d-e1-ft#https://img.emma.ucchristus.cl/im/3619883/666cb3a9558ee1fbaea0bd9cc2c37b58ac6f6190b1490f79c5af6f2bfedeac8a.png?e=QIPNmHtW2Ai-aDcgxbcBbCy510gUJGRxxoYGe6YF4zQJ-ZQnj8uODRM3kFpdslGbYFoNRCVPUo_X31g1q6csZzyAx6HO9c8Et0rkGoc3MA0pul7yygIKvUpyz51GZc6ZrqxBtRGHLljA6QpHMtmhtFCaYjFAUHqOmWFi-1zcKbOI0z2Kb-2mM3eIOMfhI_0zDS3Okh18fm4d_EtshIA355UmavU5P2TTMsSeIUprdRQzpzsxDHo2GcH2DfB-_E9IVMQ"
                          alt="logo"
                          style="box-sizing: border-box; border: 0; mix-blend-mode: multiply"
                          data-bit="iit"
                      /></a>
                    </td>
                
                  </tr> </tbody
              ></table>
            </td>
          </tr>
          <tr style="box-sizing: border-box">
            <td
              style="
                box-sizing: border-box;
                width: 100%;
                background: #fff;
                border-radius: 16px;
                border: 1px solid #e0e3e5;
              ">
              <table
                class="m_5094942471179559977bg-props"
                style="
                  border-spacing: 0;
                  width: 100%;
                  padding: 24px;
                  border-radius: 16px 16px 0 0;
                  background: url(https://s3.us-west-2.amazonaws.com/recursosweb.pub.prd.usw2/correos/banner1.png);
                  background-repeat: no-repeat;
                  background-position: center;"
                ><tbody style="box-sizing: border-box">
                  <tr style="box-sizing: border-box; display: none"><th style="box-sizing: border-box"></th></tr>
                  <tr style="box-sizing: border-box">
                    <td
                      style="
                        box-sizing: border-box;
                        padding-bottom: 0px;
                        padding-top: 0px;
                        vertical-align: top;
                        width: 100%;
                        text-align: left;
                      ">
                     
                        <br><br><br><br><br>

                    
                    </td>
                  </tr>
                </tbody></table
              >
              <table
                class="m_5094942471179559977inerr"
                style="width: 100%; padding: 24px 24px 0; border-spacing: 0;">
                <tbody
                  ><tr style="box-sizing: border-box; display: none"><th style="box-sizing: border-box"></th></tr>
                  <tr style="box-sizing: border-box">
                    <td style="box-sizing: border-box; width: 100%">
                      <table
                        style="
                          margin: 0 auto;
                          border-spacing: 0;
                          box-sizing: border-box;
                          width: 750px;
                          padding: 24px 24px;
                          background: #e1f2ff;
                          border-radius: 16px;
                        "
                        ><tbody style="box-sizing: border-box">
                          <tr style="box-sizing: border-box; display: none"
                            ><th style="box-sizing: border-box"></th
                          ></tr>
                          <tr style="box-sizing: border-box">
                            <td
                              class="m_5094942471179559977plr2"
                              style="
                                box-sizing: border-box;
                                padding-bottom: 0px;
                                padding-top: 0px;
                                padding-left: 20px;
                                vertical-align: top;
                                width: 33%;
                                text-align: left;
                              ">
                              <p
                                style="
                                  box-sizing: border-box;
                                  font-size: 14px;
                                  font-weight: 400;
                                  color: #373737;
                                  margin-bottom: 0px;
                                  margin-top: 0;
                                  display: flex;
                                "
                                ><span
                                  class="m_5094942471179559977cl-text"
                                  style="
                                    box-sizing: border-box;
                                    font-weight: 700;
                                    font-size: 17px;
                                    line-height: 24px;
                                    color: #373737;
                                  "
                                  >  Paciente
                                  <br style="box-sizing: border-box" />
                                  <strong
                                    class="m_5094942471179559977cl-s"
                                    style="
                                      box-sizing: border-box;
                                      font-weight: 400;
                                      font-size: 16px;
                                      line-height: 20px;
                                      color: #373737;
                                    "
                                    >  $patientFullname$
                                    </strong
                                  >
                                  </span
                                ></p
                              >
                            </td>
                            <td
                              class="m_5094942471179559977plr2"
                              style="
                                box-sizing: border-box;
                                padding-bottom: 0px;
                                padding-top: 0px;
                                padding-left: 20px;
                                vertical-align: center;
                                width: 33%;
                                text-align: left;
                              ">
                              <p
                                style="
                                  box-sizing: border-box;
                                  font-size: 14px;
                                  font-weight: 400;
                                  color: #373737;
                                  margin-bottom: 0px;
                                  margin-top: 0;
                                  display: flex;
                                "
                                ><span
                                  class="m_5094942471179559977cl-text"
                                  style="
                                    box-sizing: border-box;
                                    font-weight: 700;
                                    font-size: 17px;
                                    line-height: 24px;
                                    color: #373737;
                                  "
                                  >
                                    
                                  Médico tratante
                                    <br style="box-sizing: border-box" />
                                    <strong
                                    class="m_5094942471179559977cl-s"
                                    style="
                                      box-sizing: border-box;
                                      font-weight: 400;
                                      font-size: 16px;
                                      line-height: 20px;
                                      color: #373737;
                                    "
                                    >   $professionalFullname$
                                  </strong> 
                                  </span
                                ></p
                              >
                            </td>
                            <td
                            class="m_5094942471179559977plr2"
                            style="
                              box-sizing: border-box;
                              padding-bottom: 0px;
                              padding-top: 0px;
                              padding-left: 20px;
                              vertical-align: center;
                              width: 33%;
                              text-align: left;
                            ">
                            <p
                              style="
                                box-sizing: border-box;
                                font-size: 14px;
                                font-weight: 400;
                                color: #373737;
                                margin-bottom: 0px;
                                margin-top: 0;
                                display: flex;
                              "
                              ><span
                                class="m_5094942471179559977cl-text"
                                style="
                                  box-sizing: border-box;
                                  font-weight: 700;
                                  font-size: 17px;
                                  line-height: 24px;
                                  color: #373737;
                                "
                                >
                                  
                                Área de consulta
                                  <br style="box-sizing: border-box" />
                                  <strong
                                  class="m_5094942471179559977cl-s"
                                  style="
                                    box-sizing: border-box;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 20px;
                                    color: #373737;
                                  "
                                  >  $professionalSpecialty$
                                </strong> 
                                </span
                              ></p
                            >
                          </td>
                          </tr>
                        </tbody></table
                      >
                    </td>
                  </tr>
                </tbody></table
              >
              <table class="m_5094942471179559977inerr"
                style="box-sizing: border-box; width: 100%; padding: 0 24px; border-spacing: 0;">
                <tbody>
                  <tr style="box-sizing: border-box; display: none">
                    <th style="box-sizing: border-box"></th>
                  </tr>
                  <tr style="box-sizing: border-box">
                    <td style="box-sizing: border-box; width: 100%">
                      <table
                        style="
                          margin: 0 auto;
                          border-spacing: 0;
                          width: 750px;
                          background: #F5F0FF;
                          border-radius: 16px;
                        ">
                        <tbody style="box-sizing: border-box">
                      
                          $linksButtons$


                          </tbody
                      ></table>
                    </td>
                  </tr>
                  <tr><td>
                    &nbsp;</td></tr>
                  <tr>
                  <tr style="box-sizing: border-box">
                    <td style="box-sizing: border-box; width: 100%">
                      <table
                        style="
                          margin: 0 auto;
                          border-spacing: 0;
                          box-sizing: border-box;
                          width: 750px;
                          padding: 24px 24px;
                          background: #e1f2ff;
                          border-radius: 16px;
                        "
                        ><tbody style="box-sizing: border-box">
                          <tr style="box-sizing: border-box; display: none"
                            ><th style="box-sizing: border-box"></th
                          ></tr>
                          <tr style="box-sizing: border-box">
                            <td
                              style="
                                box-sizing: border-box;
                                padding-bottom: 0px;
                                padding-top: 0px;
                                padding-left: 20px;
                                vertical-align: top;
                                width: 30%;
                                text-align: left;
                              ">
                              <img
                                src="https://ci6.googleusercontent.com/proxy/zNi_nEhiFJs-7tmrpWCLuzskAPVnG6hdydwau75K0lfLuPAJAw9oUuqrhoAVMq7y2nsCzBt3rUeNmOVzSGFZCCK2BcFEjWbEEF7p41V5FX0yxEv0H7K9SPcvUQRr2tiQgSshlN1ojP7A1B1gTgznu4MVkZinKeHbaN041YaBFG5RRYix6cTb7QsO8sdPkUhrScPXUZQIS_9fiF0tAdkpKjRQvyTuIHShQBh-BEONA54Z-S6lLXUfACHkp5TUnGNJRjkW3uIXL-oChY6hj7bGDOvWpAFaWSanuC2SNlwcrPeK908IyAVw0RwJE3NCDBUpvZhveAInJ3-BfMy8pVzPVVcMjDJrFIp5iE3CUMCsSvu8Ex3xRWGgPrSqnlCGItZ-qx8SG3U9zlDXpHkcBg8lX_wYavGA-EkuqRxmZX94CS0yHqTq8aWqQWL7sXK5TXzZ5lUt5LzG_mbVmFJ_wQs_l7rqeckHfjKMLGyY8ZZUBck-AsBGNsANPMPv_3U6SxDiwyx2I2TcMdPJ=s0-d-e1-ft#https://img.emma.ucchristus.cl/im/3619883/36649e59450c84054c7bffc9d94f79d22f2b99aeb4a4519a426bf43a943f4617.png?e=8OggoEjYNtxbPhCLV3ZL8S2fnkZhPfzEbEnDMp_cy8PDhYLWpktVeOWumpQfo1FIJAVOaVmmSQ7QJkO_MBPC3xbSh8iDmh_NFzjvtR2ts87MNeiy3tk1o5SRwnamv_unu_N-L_ZpbVNdDjW9GcpwUH82X0LhohYVdxx7deMMRsySNU4xENQm7N0ap9C1-5DZISK4DgQCdtkZxEuWImpbvGjT65b724H7MSWSiQzLFIZAEC5C2Qjzfe0T7C2HqERBJqcL"
                                alt="image"
                                style="box-sizing: border-box"
                                class="CToWUd"
                                data-bit="iit"
                                width="130" />
                            </td>
                            <td
                              style="
                                box-sizing: border-box;
                                padding-bottom: 0px;
                                padding-top: 0px;
                                padding-left: 0px;
                                vertical-align: center;
                                width: 70%;
                                text-align: left;
                              ">
                         
                              <h4
                                style="
                                  box-sizing: border-box;
                                  font-weight: 700;
                                  font-size: 16px;
                                  line-height: 20px;
                                  color: #373737;
                                  margin: 0;
                                  margin-bottom: 10px;
                                "
                                >Información</h4
                              >

                              <p style="box-sizing: border-box; margin-bottom: 8px; margin-top: 0; display: flex;text-align: justify;"
                                ><span style="box-sizing: border-box; font-weight: 400; font-size: 14px; color: #373737"
                                  >Además, con el servicio UC CHRISTUS en casa, llegamos a tu domicilio con Toma de Muestra, Kinesioterapia,
                                  Procedimientos de Enfermería y de Especialidades, entre otras prestaciones.
                                  Contáctanos al 2 2354 3005 o al mail ucencasa@ucchristus.cl, para recibir la Medicina UC en la seguridad de tu hogar.</span
                                ></p
                              >
                           

                         
                            </td>
                          </tr>
                      
                        </tbody>
                        </table >
                        
                    </td>
                  </tr>
                  <tr><td>
                    &nbsp;</td></tr>
                
                  
                  <tr><td>&nbsp;</td></tr> 
                  
                  </tbody
              >
      
            </table>
     
           
             
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>



`;

export const examButtonHeaderPartial = `
<tr>
  <th colspan='3' style='
      text-align: center;
      padding: 16px 0 12px;
      border-radius: 16px 16px 0 0;
      background: #9062F4;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      color: #ffffff;' >
    LISTADO DE INDICACIONES MÉDICAS
  </th>
</tr>
<tr>
  <th style='
      box-sizing: border-box;
      text-align: center;
      padding: 16px 0 12px;
      background: #9062F4;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      color: #ffffff;'></th>
  <th style='
      box-sizing: border-box;
      text-align: center;
      padding: 16px 0 12px;
      background: #9062F4;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      color: #ffffff;'>Presencial</th>

  $examHeaderPartial$

</tr>
$items$
`;

export const examsImagesTeamTemplate = `
<html>
<head>
  <style>
    * { font-family: Tahoma, Ginebra, sans-serif; }
    p { color: #686666; font-size: 14px; line-height: 19px; }
  </style>
</head>
<body>
<div>
  <!--[if gte mso 9]>
  <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
    <v:fill type="tile"></v:fill>
  </v:background>
  <![endif]-->
  <table style="padding-left: 20px;" width="90%">
    <tr>
      <td align="left" style="padding-bottom: 20px; padding-top: 20px">
        <img src="https://www.ucchristus.cl/assets/images/logo.png" alt="Logo" width="292"/>
      </td>
    <tr><td>&nbsp;</td></tr>
    <tr>
    <tr>
      <td style="color: #848484;">
        <p>Estimados,</p>
        <p>Encuentren adjuntas indicaciones de imágenes del paciente $patientFullname$ $patientDocumentNumber$.</p>

        $teamAccess$
      </td>
    </tr>
    </tr>
    <tr><td>&nbsp;</td></tr>
    <tr><td style="border-bottom: 1px solid #dddddd"></td></tr>
    <tr>
      <td>
        <table width="100%">
          <tr>
            <td>
              <table width="100%" border="0">
                <tr>
                  <td valign="top">
                    <p style="text-align: left;color: #5a5a5a;font-size: 11px;line-height: 20px;">
                      <span style="font-weight: bold"> Red de Salud UC CHRISTUS.</span> <br/>
                      Mesa Central Hospital Cl&iacute;nico: 22 354 3000 <br/>
                      Mesa Central Cl&iacute;nica San Carlos: 22 754 8700 <br/>
                      Rescate Ambulancia: 800 265 265 <br/>
                      Reserva de Horas: 22 676 7000
                    </p>
                  </td>
                  <td valign="top" width="30">&nbsp;</td>
                  <td valign="top">
                    <table width="100%" border="0">
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td valign="top" width="16">&nbsp;</td>
                        <td valign="top" width="1">
                          <a target="_blank" href="https://www.facebook.com/UCCHRISTUS">
                            <img src="https://www.ucchristus.cl/assets/images/rs1.png" width="20"/>
                          </a>
                        </td>
                        <td valign="top" width="1">
                          <a target="_blank"
                             href="https://www.instagram.com/ucchristus/?hl=es-la">
                            <img src="https://www.ucchristus.cl/assets/images/rs4.png" width="20"/>
                          </a>
                        </td>
                        <td valign="top" width="1">
                          <a target="_blank" href="https://twitter.com/ucchristus">
                            <img src="https://www.ucchristus.cl/assets/images/rs2.png" width="20"/>
                          </a>
                        </td>
                        <td valign="top" width="1">
                          <a target="_blank"
                             href="https://cl.linkedin.com/company/ucchristus">
                            <img src="https://www.ucchristus.cl/assets/images/rs5.png" width="20"/>
                          </a>
                        </td>
                        <td valign="top" width="1">
                          <a target="_blank" href="https://www.youtube.com/user/UCCHRISTUS">
                            <img src="https://www.ucchristus.cl/assets/images/rs3.png" width="20"/>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td height="88"></td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</div>
</body>
</html>
`;

export const examButtonLinkPartial = `
<tr>
  <td style='
      padding: 20px 10px;
      width: 33%;'>
    <p style='
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 400;
        color: #373737;
        margin-bottom: 0;
        margin-top: 0;
        display: flex;'>
      <span style='box-sizing: border-box; font-weight: 600; font-size: 16px; color: #373737'>
        $serviceName$ <br style='box-sizing:border-box'/>
        <strong style='box-sizing: border-box;font-weight: 400;font-size: 14px;line-height: 20px;color: #373737;'>
          $codFonasa$
        </strong>
      </span>
    </p>
  </td>

  <td style='text-align: center;'>
    <p>
      <span>
        <a href='$link$' 
          style='padding: 13px 30px; background-color:#fff; border-radius:10px; border:1px solid #9062F4; text-decoration:none; font-size: 14px; color:#9062F4; font-weight: 600;'>
          Agendar aquí
        </a>
      </span>
    </p>
  </td>


  $domicilio$


</tr>
`;

export const examButtonDirectPartial = `
  <tr>
    <td style='
        padding: 20px 10px;
        width: 33%;'>
    <p style='
          font-size: 14px;
          font-weight: 400;
          color: #373737;
          margin-bottom: 0;
          margin-top: 0;
          display: flex;'>
        <span style='font-weight: 600; font-size: 16px; color: #373737'>
          $serviceName$
          <br/>
          <strong style='
              box-sizing: border-box;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #373737;'>
            $codFonasa$
          </strong>
        </span>
      </p>
    </td>

    <td style='text-align: center; width: 33%;'>
      <p>
        <span style='font-weight: 600; font-size: 16px; color: #373737'>
          No necesitas agendar hora, la atención es por orden de llegada.
        </span>
        </p>
    </td>
  </tr>
`;

export const examButtonCallPartial = `
<tr>
  <td style='
      padding: 20px 10px;
      width: 33%;'>
    <p style='
        font-size: 14px;
        font-weight: 400;
        color: #373737;
        margin-bottom: 0;
        margin-top: 0;
        display: flex;'>
      <span style='font-weight: 600; font-size: 16px; color: #373737'>
        $serviceName$ <br/>
        <strong style='
            box-sizing: border-box;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #373737;'>
          $codFonasa$
        </strong>
      </span>
    </p>
  </td>

  <td style='text-align: center;'>
    <p>
      <span style='font-weight: 600; font-size: 16px; color: #373737'>
        Reserva tu hora llamando a <a href='tel:226767000'>226767000</a>
      </span>
    </p>
  </td>

</tr>
`;
