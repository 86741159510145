import {patientTopCard} from '@shared/helpers/pdf-patient-card.helper';
import {Derivation, Interconsultation} from '@shared/interfaces/derivation.interface';
import {REHABILITATION_SPECIALTIES} from '@clinical/derivations/constants/specialties.const';

export const buildContentPdfDerivation = (paperwork, group) => {
  let title;
  const {rows} = group;
  const CONTENT = [];

  const derivationBuildPdf = rows.map((item: Derivation) => {
    const group = item.interconsultation[0]?.group;
    if (item.interconsultation.length && group === 'Fonoaudiología' || group === 'Kinesiología' || group === 'Terapia Ocupacional')
      title = group.toUpperCase();

    const reason =
            item.reason === ' '
              ? item.interconsultation[item.interconsultation.length - 1]?.observations
              : item.reason;

    const specialty = (item.service.derivation?.specialty + ' ' + item.service.derivation?.service).trim();
    const especialidad = item?.interconsultation[0]?.name ? item.interconsultation[0].name : '';
    let TITTLE = [];
    let INDICATIONS = [];
    if (REHABILITATION_SPECIALTIES.includes(specialty.toLocaleLowerCase())) {
      let DIAGNOSTICS = [];
      const diagnostics = item.diagnostics;
      const indications = item.interconsultation;
      if (diagnostics)
        DIAGNOSTICS = [
          {
            columns: [
              {
                columns: [
                  {
                    stack: [
                      {
                        fontSize: 12,
                        text    : `Diagnósticos:`,
                        bold    : true,
                      },
                    ],
                    width: '20%',
                  },

                  {
                    text : [
                      {
                        fontSize: 12,
                        text    : `${diagnostics.join(', ')}`,
                      },
                    ],
                    width: '80%',
                  },
                ],
                margin : [0, 5, 0, 0],
              },
            ],
            margin : [0, 5, 0, 0],
          },
        ];

      if (indications)
        INDICATIONS = indications.map(
          (ind: Interconsultation) => {
            const indicationName = ind.name.toUpperCase();
            return [
              {
                columns: [
                  {
                    columns: [
                      {
                        stack: [
                          {
                            fontSize: 12,
                            text    : `${indicationName}`,
                            bold    : true,
                          },

                          {
                            text   : [
                              {fontSize: 12, text: 'Nº de sesiones: '},

                              {
                                fontSize: 12,
                                text    : ind.totalSessions ? ind.totalSessions : ind.totalControls,
                                bold    : true,
                              },
                            ],
                            italics: true,
                          },
                          {
                            text : [
                              {fontSize: 12, text: 'Observación: '},

                              {fontSize: 12, text: ind.observations, bold: true},
                            ],
                            width: '60%',
                          },
                        ],
                        width: '100%',
                      },
                    ],
                    margin : [0, 5, 0, 0],
                  },
                ],
                margin : [0, 5, 0, 0],
              },
            ];
          },
        );
      TITTLE = [
        {text: `${title}`, bold: true, alignment: 'center'},

        {
          marginTop: 10,
          alignment: 'left',
          stack    : [
            {fontSize: 12, text: `INFORMACIÓN DE LA SOLICITUD`, bold: true},
            {canvas: [{type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1}]},
          ],
        },
      ];
      CONTENT.push(TITTLE, DIAGNOSTICS, INDICATIONS);
    } else if (specialty === 'Otra Especialidad' || specialty === 'Seguimiento Orientacion Quirurgica') {
      TITTLE = [
        {
          marginTop: 10,
          alignment: 'left',
          stack    : [
            {fontSize: 12, text: `INFORMACIÓN DE LA SOLICITUD`, bold: true},
            {canvas: [{type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1}]},
          ],
        },
      ];

      const CONTENT_OTHER = [
        {
          columns: [
            {
              columns: [
                {
                  stack: [
                    {
                      text   : [
                        {marginTop: 10, fontSize: 12, text: 'Se deriva a: '},

                        {fontSize: 12, text: specialty, bold: true},
                      ],
                      italics: true,
                    },
                    {
                      text   : [
                        {fontSize: 12, text: 'Especialidad: '},

                        {fontSize: 12, text: `${item.service.other}`, bold: true},
                      ],
                      italics: true,
                    },
                    {
                      text : [
                        {fontSize: 12, text: 'Motivo de consulta: '},

                        {fontSize: 12, text: reason || item.interconsultation[0]?.observations, bold: true},
                      ],
                      width: '60%',
                    },
                  ],
                  width: '100%',
                },
              ],
              margin : [0, 5, 0, 0],
            },
          ],
          margin : [0, 5, 0, 0],
        },
      ];

      CONTENT.push(TITTLE, CONTENT_OTHER);
    } else {
      let CONTENT_OTHER = [];
      TITTLE = [

        {
          marginTop: 10,
          alignment: 'left',
          stack    : [
            {fontSize: 12, text: `INFORMACIÓN DE LA SOLICITUD`, bold: true},
            {canvas: [{type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1}]},
          ],
        },
      ];

      CONTENT_OTHER = [
        {
          columns: [
            {
              columns: [
                {
                  stack: [
                    {
                      text   : [
                        {marginTop: 10, fontSize: 12, text: 'Se deriva a: '},

                        {
                          fontSize: 12,
                          text    : specialty !== especialidad ? `${specialty}` : specialty,
                          bold    : true,
                        },
                      ],
                      italics: true,
                    },
                    {
                      text : [
                        {fontSize: 12, text: 'Motivo de consulta: '},

                        {fontSize: 12, text: reason || item.interconsultation[0]?.observations, bold: true},
                      ],
                      width: '100%',
                    },
                    // {
                    //   text: [
                    //     { fontSize: 12, text: item.interconsultation[0]?.totalControls ? 'Nº de controles: ' : '' },
                    //     {
                    //       fontSize: 12,
                    //       text: item.interconsultation[0]?.totalControls ? item.interconsultation[0].totalControls : '',
                    //       bold: true,
                    //     },
                    //   ],
                    //   italics: true,
                    // },
                  ],
                },
              ],
              margin : [0, 5, 0, 0],
            },
          ],
          margin : [0, 5, 0, 0],
        },
      ];
      let note1 = [];
      if (specialty === 'Programa de insuficiencia Cardiaca UC' || specialty === 'Programa de insuficiencia Cardiaca')
        note1 = [
          {
            marginTop: 100,
            fontSize : 8,
            alignment: 'left',
            text     :
              'Programa de Insuficiencia Cardiaca UC\n' +
              'Contacto:  Micaela Morales G. Enfermera de Cardiología y Programa de Insuficiencia cardiaca UC\n' +
              'Correo: insuficienciacardiaca@ucchristus.cl \n' +
              'WhatsApp +569 34005905',
          },
        ];

      let note2 = [];

      if (specialty.trim() === 'Unidad Salud Mental Integral y Recepcion (USMI-R)')
        note2 = [
          {
            marginTop: 100,
            fontSize : 8,
            alignment: 'left',
            text     :
              'Unidad Salud Mental Integral y Recepción (USMI-R)\n' +
              'Centro Médico Providencia\n' +
              'Teléfono de Contacto: 224820056\n' +
              'Correo de Contacto:  contacto.providencia@ucchristus.cl\n' +
              '',
          },
          {
            fontSize  : 8,
            alignment : 'left',
            decoration: 'underline',
            text      : 'Más información en Unidad Salud Mental Integral y Recepción',
            link      : 'https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-salud-mental-integral-y-recepcion',
          },
        ];

      CONTENT.push(TITTLE, CONTENT_OTHER, note1, note2);
    }
    return [CONTENT];
  });
  return [...patientTopCard(paperwork), derivationBuildPdf];
};
