import { format } from 'date-fns';
import { Health } from '../../../shared/interfaces/background-for-health.interface';

export const buildContentPdfPadFonasa = (paperwork, group) => {
  const {rows}                  = group;
  return rows.map((pad: Health) =>
    {
        const date =  format(new Date(), 'yyyy-MM-dd');
          const CONTENT = {
            fontSize: 12,
            margin: [0, 5],
            stack: [
                {
                  color: 'blue',
                    table: {
                      widths: [320, '*'],
                        body: [
                            [ {text: `ASEGURADO `, bold: true,} ,  {text: `FECHA DE SOLICITUD: ${date} `, bold: true,} ],
                        ]
                    },
                    layout: 'noBorders'
                },

                { marginTop: 15,bold: true, text: `MODALIDAD  LIBRE ELECCIÓN `, fontSize: 10, color: 'blue' },
                {
                  alignment: 'justify',
                  fontSize: 10,
                  style: 'center',

                  stack: [
                    {
                      marginTop: -12,
                      marginLeft: 235,
                      canvas: [
                        {
                          type: 'rect',
                          x: 1,
                          y: 1,
                          w: 10,
                          h: 10,
                        },
                      ],
                    },
                  ],
                },
                { marginTop: -12, marginLeft: 160, bold: true, text: `INSTITUCIONAL `,  fontSize: 10, color: 'blue'},
                {
                  alignment: 'justify',
                  style: 'center',

                  stack: [
                    {
                      border: 'blue',
                      marginTop: -12,
                      marginLeft: 140,
                      canvas: [
                        {
                          type: 'rect',
                          x: 1,
                          y: 1,
                          w: 10,
                          h: 10,
                        },
                      ],
                    },
                  ],
                },
                { marginTop: -12, marginLeft: 280, bold: true, text: `PROGRAMA COMPLEMENTARIO AL N° ______ `,color: 'blue' },
                {
                    margin: [0, 10,0,0],
                    style: 'tableHeader',
                    color:'blue',

                  table: {

                    widths: ['*', '*', '*', '*'],
                        body: [

                          ['RUT AFILIADO', 'APELLIDO MATERNO', 'APELLIDO PATERNO', 'NOMBRES'],
                            [`${pad.affiliate.rut}`, `${pad.affiliate.paternalLastName}`, `${pad.affiliate.maternalLastName}`, `${pad.affiliate.rut}`]
                        ]
                    }
                },


                {
                    margin: [0, 2,0,0],
                    color:'blue',
                    table: {
                        widths: ['*', '*', '*'],
                        body: [
                            ['ESTADO PREVICIONAL', 'GRUPO INGRESO', 'TELÉFONO'],
                            [`${pad.affiliate.forecastStatus}`, `${pad.affiliate.incomeGroup}`, `${pad.affiliate.phone}`]
                        ]
                    }
                },

                {
                    margin: [0, 2,0,0],
                    color:'blue',
                    table: {
                        widths: ['*', '*', '*', '*'],
                        body: [
                            ['CALLE N°', 'POBLACIÓN/VILLA', 'COMUNA', 'CIUDAD'],
                            [`${pad.affiliate.street}`, `${pad.affiliate.town}`, `${pad.affiliate.commune}`, `${pad.affiliate.city}`]
                        ]
                    }
                },

                {
                    margin: [0, 2,0,10],
                    color:'blue',
                    table: {
                        widths: ['*', '*', '*',],
                        body: [
                            ['RUT PACIENTE', 'APELLIDOS', 'NOMBRES'],
                            [`${pad.patient.rut}`, `${pad.patient.surnames}`, `${pad.patient.names}`]
                        ]
                    }
                },

                {
                    text: [
                      { text: `ESTABLECIMIENTO.\n`, bold: true , color:'blue',},
                    ],
                  },


                  {
                    margin: [0, 2,0,0],
                    color:'blue',
                    table: {
                        widths: ['*', '*', '*'],
                        body: [
                            ['RUT ESTABLECIMIENTO', 'GRUPO/NIVEL', 'NOMBRE ESTABLECIMIENTO'],
                            [`${pad.establishment.rut}`, `${pad.establishment.level}`, `${pad.establishment.name}`]
                        ]
                    }
                },

                {
                    margin: [0, 2,0,10],
                    color:'blue',
                    table: {
                        widths: ['*', '*', '*'],
                        body: [
                            ['FECHA HOPITALIZACIÓN DESDE', 'FECHA HOPITALIZACIÓN HASTA', 'N° DÍAS'],
                            [`${pad.establishment.dateFrom}`, `${pad.establishment.dateTo}`, `${pad.establishment.days}`]
                        ]
                    }
                },

                {
                    text: [
                      { text: `PROFESIONAL TRATANTE.\n`, bold: true, color:'blue', },
                    ],
                  },

                  {
                    margin: [0, 2,0,0],
                    color:'blue',
                    table: {
                        widths: ['*', '*', '*'],
                        body: [
                            ['RUT PROFESIONAL', 'GRUPO/NIVEL', 'NOMBRE'],
                            [`${pad.professional.rut}`, `${pad.professional.level}`, `${pad.professional.name}`]
                        ]
                    }
                },

                {
                    margin: [0, 10,0,10],
                    color:'blue',
                    table: {
                        widths: ['*', '*', '*'],
                        heights: [20, 40],
                        body: [
                            ['DIAGNOSTICO', 'N° HISTORIAL CLÍNICA', 'FIRMA PROFESIONAL'],
                            [`${pad.professional.diagnostic}`, `${pad.professional.historyNumber}`, '']
                        ]
                    }
                },

                {

                  pageBreak:'before',
                margin: [0, -70,0,0],
                    text: [
                      { text: `INTERVENCIÓN QUIRÚRGICA.\n`, bold: true, color:'blue', },
                    ],
                  },

                  {
                    color:'blue',
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [

                              [
                                  {
                                    alignment: 'justify',

                                    style: 'center',

                                    stack: [
                                      { marginTop: 10, text: `RECARGO` },
                                      {
                                        marginTop: -12,
                                        marginLeft: 70,
                                        canvas: [
                                          {
                                            type: 'rect',
                                            x: 1,
                                            y: 1,
                                            w: 10,
                                            h: 10,
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                { marginTop: -10, marginLeft: 85, bold: true, text: `Si ` },
                                {
                                  alignment: 'justify',
                                  style: 'center',

                                  stack: [
                                    {
                                      marginTop: -15,
                                      marginLeft: 110,
                                      canvas: [
                                        {
                                          type: 'rect',
                                          x: 1,
                                          y: 1,
                                          w: 10,
                                          h: 10,
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  marginTop: -10,
                                  marginLeft: 125,
                                  bold: true,
                                  alignment: 'justify',

                                  text: `No`,
                                },
                                ],
                                {text: [
                                        {text: `NIVEL 1ER CIRUJANO:  \n`,},
                                        {text: `NIVEL ANESTESISTA:  `,}]
                                }
                            ],


                        ],

                    },

                },

                    {
                      color:'blue',
                        table: {
                            widths: [30, '*', '*'],
                            body: [
                                ['ITEM', 'DESCRIPCIÓN', 'R.U.T.'],
                                ['1', '1ER CIRUJANO', ` `],
                                ['2', 'ANEST. (MISMO 1ER CIR.)', ` `],
                                ['3', 'ANEST. (OTROS PROF.)', ` `],
                                ['4', '2 CIRUJANO', ` `],
                                ['5', '3 CIRUJANO', ` `],
                                ['6', '4 CIRUJANO', ` `],
                                ['7', '5 CIRUJANO', ` `],
                                ['8', 'PABELLÓN', ` `]
                            ]
                        }
                    },


                {

                    margin: [0, 2,0,10],
                    color:'blue',
                    table: {
                        widths: ['*', '*', '*', '*'],
                        body: [
                            ['PRESTACIÓN', '% COBRO', '% PAB.', '% ANEST.'],
                            [` `, ` `, ` `, ` `],
                            [` `, ` `, ` `, ` `],
                            [` `, ` `, ` `, ` `],
                            [` `, ` `, ` `, ` `]
                        ]
                    }

            },
            {
                text: 'ESPECIALIDAD DEL PROGRAMA', bold: true, color: 'blue',
            },

            { marginTop: 10,bold: true, text: `1. MEDICINA `,marginLeft: 40, color: 'blue'},


            {
              alignment: 'justify',
              style: 'center',

              stack: [
                {
                  marginTop: -12,
                  marginLeft: 10,
                  canvas: [
                    {
                      type: 'rect',
                      x: 1,
                      y: 1,
                      w: 10,
                      h: 10,
                    },
                  ],
                },
              ],
            },


            { marginTop: 10,bold: true, text: `2.CIRUGÍA `,marginLeft: 40, color: 'blue'},

            {
              alignment: 'justify',
              style: 'center',

              stack: [
                {
                  marginTop: -12,
                  marginLeft: 10,
                  canvas: [
                    {
                      type: 'rect',
                      x: 1,
                      y: 1,
                      w: 10,
                      h: 10,
                    },
                  ],
                },
              ],
            },


            { marginTop: 10,bold: true, text: `3. OBST. Y GINECOLOGÍA `,marginLeft: 40, color: 'blue'},

            {
              alignment: 'justify',
              style: 'center',

              stack: [
                {
                  marginTop: -12,
                  marginLeft: 10,
                  canvas: [
                    {
                      type: 'rect',
                      x: 1,
                      y: 1,
                      w: 10,
                      h: 10,
                    },
                  ],
                },
              ],
            },


            { marginTop: 10,bold: true, text: `4. PSIQUIATRÍA `,marginLeft: 40, color: 'blue'},

            {
              alignment: 'justify',
              style: 'center',

              stack: [
                {
                  marginTop: -12,
                  marginLeft: 10,
                  canvas: [
                    {
                      type: 'rect',
                      x: 1,
                      y: 1,
                      w: 10,
                      h: 10,
                    },
                  ],
                },
              ],
            },


            { marginTop: 10,bold: true, text: `5. TRAT. RADIOLÓGICO `,marginLeft: 40, color: 'blue'},

            {
              alignment: 'justify',
              style: 'center',

              stack: [
                {
                  marginTop: -12,
                  marginLeft: 10,
                  canvas: [
                    {
                      type: 'rect',
                      x: 1,
                      y: 1,
                      w: 10,
                      h: 10,
                    },
                  ],
                },
              ],
            },


            { marginTop: 10,bold: true, text: `6. KINESITERAPIA `,marginLeft: 40, color: 'blue'},

            {
              alignment: 'justify',
              style: 'center',

              stack: [
                {
                  marginTop: -12,
                  marginLeft: 10,
                  canvas: [
                    {
                      type: 'rect',
                      x: 1,
                      y: 1,
                      w: 10,
                      h: 10,
                    },
                  ],
                },
              ],
            },


            { marginTop: 10,bold: true, text: `7. OTROS `,marginLeft: 40, color: 'blue'},


            {
              alignment: 'justify',
              style: 'center',

              stack: [
                {
                  marginTop: -12,
                  marginLeft: 10,
                  canvas: [
                    {
                      type: 'rect',
                      x: 1,
                      y: 1,
                      w: 10,
                      h: 10,
                    },
                  ],
                },
              ],
            },



            {

              pageBreak:'before',
              margin: [0, -70,0,0],
                text: [
                  { text: `OTRAS PRESTACIONES.\n`, bold: true, color:'blue' },
                ],
              },

              {
                margin: [0, 2,0,0],
                color:'blue',
                table: {
                    widths: [35, '*', 35,35, '*', '*'],
                    body: [
                        ['NIVEL', 'CÓDIGO DE PRESTACIÓN', 'CANT.', 'HOR.', 'PRESTACIÓN', 'RUT PRESTADOR'],
                        [` `, ` `, ` `, ` `,` `, ` `],
                        [` `, ` `, ` `, ` `,` `, ` `],
                        [` `, ` `, ` `, ` `,` `, ` `],
                        [` `, ` `, ` `, ` `,` `, ` `],
                        [` `, ` `, ` `, ` `,` `, ` `],
                        [` `, ` `, ` `, ` `,` `, ` `],
                        [` `, ` `, ` `, ` `,` `, ` `],
                        [` `, ` `, ` `, ` `,` `, ` `]
                    ]
                }
            },

            {

                margin: [0, 10,0,0],
                text: [
                  { text: `DIFERENCIAS AUTORIZADAS.\n`, bold: true,color: 'blue', },
                ],
              },

              {

                margin: [0, 2,0,0],
                color: 'blue',
                table: {
                    widths: ['*', '*'],
                    body: [
                        ['DIRERENCIA POR DÍAS CAMA', `$ `, ],
                        ['DIFERENCIA POR DERECHO PABELLÓN', `$ `, ],
                        ['DIFERENCIA POR INSUMOS O MATERIALES CLÍNICOS', `$ `, ],
                        ['DIREFENCIA POR MEDICAMENTOS', `$ `, ],
                        ['TOTAL', `$ `, ],

                    ]
                }
            },

            {
                margin: [0, 10,0,0],
                text: [
                  { text: `HONORARIOS PARTICULARES.\n`, bold: true,color: 'blue',  pageBreak:'before',
                  margin: [0, -70,0,0],},
                ],
              },

              {

                margin: [0, 2,0,0],
                color: 'blue',
                table: {
                    widths: ['*', '*'],
                    body: [
                        [{text: 'HONORARIOS (SÓLO PARA PROFESIONALES NO INSCRITOS EN EL FONDO NACIONAL DE SALUD)', style: 'tableHeader', colSpan: 2, alignment: 'center'},{}],
                        ['EQUIPO MÉDICO', `$ `, ],
                        ['ARSENALERA', `$`, ],
                        ['ANESTESISTA', `$`, ],
                        ['MATRONA', `$`, ],
                        ['PEDIATRA', `$ `, ],
                        ['TOTAL', `$`, ],

                    ]
                }
            },

            {
              pageBreak:'before',
              margin: [0, -70,0,0],
                text: [
                  { text: `FUNDAMENTO.\n`, bold: true ,color: 'blue',},
                ],
              },
              {
                margin: [0, 2,0,0],
                color: 'blue',
                table: {
                    widths: [ '*','*','*'],
                    heights: [20, 50,],
                    body: [
                        ['USO EXCLUSIVO MÉDICO TRATANTE',{text:'FIRMA Y TIMBRE MEDICO TRATANTE',  fontSize: 10,},{text:'FIRMA Y TIMBRE MÉDICO AUDITOR',  fontSize: 10,} ],
                        [` `,'','']
                    ]
                }
            },


            {
                pageBreak:'before',
                margin: [0, -70,0,0],
                text: [
                  { text: `INSTRUCCIONES DE LLENADO.\n`, bold: true,alignment: 'center',color: 'blue', },
                ],
              },

            {
                margin: [0, 10,0,0],
                text: [
                  { text: `PARA COMPLETAR ESTE FORMULARIO SE DEBE TENER PRESENTE LLENAR LA SIGUIENTE INFORMACIÓN EN LOS LUGARES SEÑALADOS.\n`,color: 'blue', },
                ],
              },

              {
                margin: [0, 10,0,0],
                text: [
                  { text: `1. PRIMERA SECCIÓN: \n`, bold:true,color: 'blue',},
                ],
              },
              {
                margin: [0, 10,0,0],
                text: [
                  { text: `- Modalidad: Marcar Libre Elección. \n`,color: 'blue',},
                  { text: `- Fecha: La fecha en que se llena el formulario. \n`,color: 'blue',},
                  { text: `- Programa complementario al N*: Indicar número de programa anterior, sólo si es complementario de otro. \n`,color: 'blue',},
                ],
              },

              {
                margin: [0, 10,0,0],
                text: [
                  { text: `2. SECCIÓN ASEGURADO: \n`, bold:true,color: 'blue',},
                ],
              },
                {
                margin: [0, 10,0,0],
                text: [
                  { text: `PRIMERA LINEA: Identificar con RUT, apellidos y nombres del afiliado. \n`,color: 'blue',},
                  { text: `SEGUNDA LINEA: Estado previsional y grupo de ingreso a llenar por personal de Fonasa \n`,color: 'blue',},
                  { text: `Teléfono del afiliado silo posee. \n`,color: 'blue',},
                  { text: `TERCERA LÍNEA: Identificar al paciente asegurado con RUT, apellidos, nombres, edad y sexo sea éste carga del afiliado o el mismo afiliado. \n`,color: 'blue',},
                ],
              },

              {
                margin: [0, 10,0,0],
                text: [
                  { text: `3. SECCIÓN ESTABLECIMIENTO: \n`, bold:true,color: 'blue',},
                ],
              },

              {
                margin: [0, 10,0,0],
                text: [
                  { text: `PRIMERA LINEA: Identificar con RUT, nivel y nombre (razón sociaD, el establecimiento donde se realizarán as prestaciones, aún cuando
                    no esté inscrito en Fonasa. \n`,color: 'blue',},
                  { text: `Solamente no llenar en atención ambulatoria.\n`,color: 'blue',},
                  { text: `SEGUNDA LINEA: Fecha de hospitalización o tratamiento, corresponde indicar día, mes y año en que se inicia y termina un tratamiento 0
                  período de hospitalización y el número de días que eso significa. Esta cantidad de días debe coincidir exactamente con el total \n`,color: 'blue',},
                  { text: `de días cama en caso de hospitalización, y en ningún caso puede ser superior a 31 días.. \n`,color: 'blue',},
                ],
              },

              {

                margin: [0, 10,0,0],
                text: [
                  { text: `4. SECCIÓN PROFESIONAL TRATANTE: \n`, bold:true,color: 'blue',},
                ],
              },
              {
                margin: [0, 10,0,0],
                text: [
                  { text: `PRIMERA LÍNEA: Identificar RUT, nivel, nombres y apellidos del profesional tratante, aún cuando no esté inscrito en Fonasa \n`,color: 'blue',},
                  { text: `SEGUNDA LINEA: Anotar diagnóstico y número de ficha clínica del paciente. \n`,color: 'blue',},
                  { text: `El profesional tratante debe firmar en el recuadro diagnosticado a tal fin. \n`,color: 'blue',},
                ],
              },

              {
                pageBreak:'before',
                margin: [0, -70,0,0],
                text: [
                  { text: `5. SECCIÓN INTERVENCIÓN QUIRÓRGICA: \n`, bold:true,color: 'blue',},
                ],
              },
              {
                margin: [0, 10,0,0],
                text: [
                  { text: `- Indicar si la intervención tiene o no recargo de horario, según o establece el artículo 29 de la Res. Ext. N* 1271/05 MINSAL. \n`,color: 'blue',},
                  { text: `- Indicar nivel del primer cirujano y del anestesista, salvo que la intervención sea otorgada por una institución acreditada, situación
                  ena cual indicará el nivel de dicha institución. \n`,color: 'blue',},
                  { text: `- En prestación, consignar códigos de las intervenciones realizadas. \n`,color: 'blue',},
                  { text: `- En descripción marcar integrantes del equipo quirúrgico y RUT de cada uno de los profesionales que efectivamente otorgan a atención.
                  En caso que a intervención sea otorgada por una institución acreditada para ese efecto, consignar RUT detal institución en todos los items. \n`,color: 'blue',},
                  { text: `- Marcar especialidad del programa. \n`,color: 'blue',},
                ],
              },

              {
                margin: [0, 10,0,0],
                text: [
                  { text: `6. SECCIÓN OTRAS PRESTACIONES: \n`, bold:true,color: 'blue',},
                ],
              },

              {
                margin: [0, 10,0,0],
                text: [
                  { text: `- Indicar código de as prestaciones, cantidad horario y descripción de cada una de ellas. \n`,color: 'blue',},
                  { text: `- Consignar nivel y RUT de los prestadores que otorgan cada una de las prestaciones, con excepción de las prestaciones que otorga la
                  institución acreditada para ese efecto, situación en la cual se indica el nivel y el RUT de la institución. \n`,color: 'blue',},
                ],
              },

              {
                margin: [0, 10,0,0],
                text: [
                  { text: `7. SECCIÓN COBRO DE DIFERENCIAS AUTORIZADAS:\n`, bold:true,color: 'blue',},
                ],
              },
              {

                margin: [0, 10,0,0],
                text: [
                  { text: `- Este cuadro será llenado por la institución y en ella deberá consignar as diferencias a cancelar por el beneficiario por concepto de días
                  cama y derecho de pabellón, según tarifas informadas al Fondo Nacional de Salud, de acuerdo a lo establecido por el DS. 369 Artículo
                  53 y Resol, Ext, 41/V9 346/92, Asimismo deberá registrar el diferencial por insumos o materiales cínicos y medicamentos \n`,color: 'blue',},
                ],
              },

              {
                margin: [0, 10,0,0],
                text: [
                  { text: `8. SECCIÓN COBRO DE HONORARIOS PARTICULARES:\n`, bold:true,color: 'blue',},
                ],
              },

              {
                margin: [0, 10,0,0],
                text: [
                  { text: `- Cuando en una intervención o procedimiento participan profesionales no inscritos en el Fondo Nacional de Salud podrán cobrar sus
                  honorarios en forma particular debiendo ser consignados en esta sección. \n`,color: 'blue',},
                ],
              },
              {
                pageBreak:'before',
                margin: [0, -70,0,0],
                text: [
                  { text: `9. SECCIÓN FUNDAMENTOS:\n`, bold:true,color: 'blue',},
                ],
              },
              {
                margin: [0, 10,0,0],
                text: [
                  { text: `- El recuadro destinado a fundamento será llenado por el médico tratante en todos los casos que la normativa lo exija. El médico
                  tratante y el médico auditor respaldarán este fundamento con su firma y timbre. \n`,color: 'blue',},
                ],
              },

              {
                margin: [0, 10,0,0],
                text: [
                  { text: `NOTAS ACLARATORIAS:\n`, bold:true,color: 'blue',},
                ],
              },

              {
                margin: [0, 10,0,0],
                text: [
                  { text: `* Las prestaciones valorizadas en un programa de atención de salud pueden ser otorgadas por profesionales, sociedades de
                  profesionales, otras instituciones inscritas en el rol de prestadores del Fondo Nacional de Salud, que estén acreditados
                  para realizarla prestación especificada. \n`,color: 'blue',},

                  { text: `* Se considera profesional que otorga la prestación, aquel que estando inscrito en el rol de prestadores, la realiza y
                  por lo tanto está en condiciones para cobrar los honorarios correspondientes según arancel. \n`,color: 'blue',},

                  { text: `* Institución que otorga la prestación es aquella que está inscrita en el rol de prestadores, cuenta con
                  profesionales idóneos contratados para realiza la prestación valorizada y por lo tanto está en
                  condiciones de cobrarla institucionalmente.\n`,color: 'blue',},
                ],
              },
            ],
            styles: {
              tableHeader: {
                bold: true,
                fontSize: 18,
                border: 'blue'
              }
            },
          };
          return [CONTENT];
      }
  );
};
