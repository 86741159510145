import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PCV2Request } from '@shared/interfaces/request.interface';
import { SignedDoc, SignDoc } from '@shared/interfaces/sign-interface';

import { API_URIS } from '@shared/constants/api-uris.const';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const BASE_API_PCV2: string = environment.baseApiPCv2;
const URI_SING: string = API_URIS.sing;
@Injectable({
  providedIn: 'root',
})
export class SignDocService {
  constructor(private httpClient: HttpClient) {}

  sign(body: SignDoc): Observable<SignedDoc> {
    const endpoint = `${BASE_API_PCV2}/${URI_SING}/firmar`;
    return this.httpClient.post<PCV2Request>(endpoint, body).pipe(map(({ data }: { data: SignedDoc }) => data));
  }
}
