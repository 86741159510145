export const docMetatada = (pdfParams) => {
  const { paperwork, group } = pdfParams;
  return {
    title: `${group.title.toUpperCase()}`,
    author: `${paperwork.professional.names} ${paperwork.professional.surnames} - ${paperwork.professional.rut}`,
    subject: `Emitido para: ${paperwork.patient.names} ${paperwork.patient.surnames} ${paperwork.patient.documentNumber}`,
    keywords: 'UC CHRISTUS, PortalClinicoV3',
    creator: 'Portal Clinico V3, UC CHRISTUS',
    producer: 'Portal Clinico V3',
  };
};
