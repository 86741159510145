import {Injectable} from '@angular/core';
import {AdmissionService} from '@clinical/admissions/services/admission.service';
import {ReportService} from '@components/records/services/report.service';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  DELETE_ADMISSION,
  DELETE_ADMISSION_ERR,
  DELETE_ADMISSION_OK,
  LOAD_ADMISSIONS,
  LOAD_ADMISSIONS_ERR,
  LOAD_ADMISSIONS_OK,
  PUT_ADMISSION,
  PUT_ADMISSION_ERR,
  PUT_ADMISSION_OK,
} from '@store/actions';

import {of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';

@Injectable()
export class AdmissionEffects {
  putAdmission$    = createEffect(() =>
      this.actions$.pipe(
          ofType(PUT_ADMISSION),
          mergeMap(({valuesToSave}) =>
              this.clinicalAdmitionHospitalService.saveAdmission({...valuesToSave}).pipe(
                  map((admission) => PUT_ADMISSION_OK({admission})),
                  catchError((err) => of(PUT_ADMISSION_ERR({payload: err}))),
              ),
          ),
      ),
  );
  loadAdmissions$  = createEffect(() =>
      this.actions$.pipe(
          ofType(LOAD_ADMISSIONS),
          mergeMap(({gsiMultiCondition1St}) =>
              this.reportService.findByGsi(gsiMultiCondition1St).pipe(
                  map((admissions) => LOAD_ADMISSIONS_OK({admissions})),
                  catchError((err) => of(LOAD_ADMISSIONS_ERR({payload: err}))),
              ),
          ),
      ),
  );
  deleteAdmission$ = createEffect(() =>
      this.actions$.pipe(
          ofType(DELETE_ADMISSION),
          mergeMap((admission) =>
              this.reportService.deleteByIds('hospitalizaciones', admission).pipe(
                  map((admissionI) => DELETE_ADMISSION_OK({admission: admissionI})),
                  catchError((err) => of(DELETE_ADMISSION_ERR({payload: err}))),
              ),
          ),
      ),
  );

  constructor(
      private actions$: Actions,
      private clinicalAdmitionHospitalService: AdmissionService,
      private reportService: ReportService,
  ) {
  }
}
