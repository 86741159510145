import {Doc} from '@clinical/educational/interfaces/educational.interface';
import {
  PUT_EDUCATIONAL,
  PUT_EDUCATIONAL_ERR,
  PUT_EDUCATIONAL_OK,
} from '@clinical/educational/store/clinical-educational.actions';
import {Action, createReducer, on} from '@ngrx/store';
import {Paperwork} from '@shared/interfaces/paperwork.interface';


export interface EducationalState {
  error: any;
  status: string;
  isLoaded: boolean;
  isLoading: boolean;
  doc: Paperwork<Doc>[];
  historical: Paperwork<Doc>[];
  selected: Paperwork<Doc>;
  lastDoc: Paperwork<Doc>;
  current: Paperwork;
}

export const docState: EducationalState = {
  error     : null,
  status    : '',
  isLoaded  : false,
  isLoading : false,
  doc       : [],
  historical: [],
  selected  : null,
  lastDoc   : null,
  current   : null,
};

const createReducerEducational = createReducer(
    docState,

    on(PUT_EDUCATIONAL, (state) => ({...state, isLoading: true, status: 'Guardando el registro.'})),
    on(PUT_EDUCATIONAL_OK, (state, {doc}) => {
      let docs    = state.doc.map((ans) => {
        if (ans.id === doc.id) return doc;
        else return ans;
      });
      let history = state.historical.map((ans) => {
        if (ans.id === doc.id) return doc;
        else return ans;
      });

      const index = docs.findIndex((draft) => draft.id === doc.id);
      if (index === -1) docs = [doc, ...docs];
      const indexHistory = history.findIndex((draft) => draft.id === doc.id);
      if (indexHistory === -1) history = [doc, ...history];

      return {
        ...state,
        isLoading : false,
        isLoaded  : true,
        status    : 'Registro guardado con éxito',
        docs      : [...docs],
        historical: [...history],
        lastDoc   : {...doc},
        current   : {...doc},
      };
    }),
    on(PUT_EDUCATIONAL_ERR, (state, {payload}) => ({
      ...state,
      isLoading: false,
      isLoaded : false,
      current  : null,
      status   : 'Ocurrio un error al intentar guardar el registro',
      error    : {...payload},
    })),
);

export const educationalReducer = (state: EducationalState, action: Action) => createReducerEducational(state, action);
