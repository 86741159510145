import {DOCUMENT_TYPE} from '@shared/constants/patient.constant';
import { format } from 'date-fns';

export const buildContentPdfPadMan = (paperwork, group) => {
  const {patient, professional} = paperwork;
  const {rows}                  = group;
  return rows.map((pad) => 
    {
        let diagnostics = pad.diagnostics;
      let hasEvaluation = pad.hasEvaluation;
      let spermiogram = pad.spermiogram;
      let observa = pad.observations;

      if (hasEvaluation) hasEvaluation = 'SI';
      if (!hasEvaluation) hasEvaluation = 'NO';

      if (spermiogram) spermiogram = 'SI';
      if (!spermiogram) spermiogram = 'NO';
      const note1 = [
        {
          marginTop: 15,
          fontSize: 10,
          alignment: 'left',
          text: `Nota 1:  Estimado paciente, todos los tratamientos de medicina reproductiva tienen riesgo de cancelación. En el caso que fuera candidato a cancelación de ciclo el valor del bono PAD Fonasa no considera reembolso..`,
        },
      ];

      const note2 = [
        {
          marginTop: 5,
          fontSize: 10,
          alignment: 'left',
          text: `Nota 2: Paciente declara aceptar y recibir programa PAD. `,
        },
      ];

      const medicoValue = professional.names + ' ' + professional.surnames;
      const patientNameValue = patient.names + ' ' + patient.surnames;
      const rutPatient = patient.documentNumber;
      let names;
      let medic;
      let rut;

      if (patientNameValue)
        names = [
          { fontSize: 12, text: ` Nombre: ` },
          {
            fontSize: 12,
            marginTop: -14,
            marginLeft: 220,
            alignment: 'justify',
            text: `${patientNameValue}`,
            bold: true,
          },
        ];
      if (rutPatient)
        rut = [
          { fontSize: 12, text: ` ${DOCUMENT_TYPE[patient.documentType.toLowerCase()].toUpperCase()}: ` },
          { fontSize: 12, marginTop: -14, marginLeft: 220, alignment: 'justify', text: `${rutPatient}`, bold: true },
        ];

      if (diagnostics.length)
        diagnostics = [
          { fontSize: 12, text: ` Diagnósticos Adicionales:` },
          { fontSize: 12, marginTop: -14, marginLeft: 220, alignment: 'justify', text: `${diagnostics}`, bold: false },
        ];

      if (hasEvaluation || !hasEvaluation)
        hasEvaluation = [
          {
            fontSize: 12,
            marginTop: 5,
            text: `¿Paciente tiene evaluación por médico del equipo de reproducción UC?: ${hasEvaluation}`,
          },
        ];
      if (!spermiogram || spermiogram)
        spermiogram = [
          { fontSize: 12, marginTop: 5, text: ` Espermiograma normal y/o TIM mayor a 1.000.000: ${spermiogram}` },
        ];

      if (observa) observa = [{ fontSize: 12, marginTop: 5, text: ` Observaciones: ${observa}` }];

      const DATE_PATIENT = {
        alignment: 'center',
        marginTop: 300,
        marginRight: 400,
        fontSize: 10,
        style: 'title',
        stack: [
          `${format(new Date(), 'dd-MM-yyyy')}`,
          { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 100, y2: 0, lineWidth: 1 }] },
          { text: `FECHA`, bold: true },
        ],
      };
      const DATE_DOCTOR = {
        alignment: 'center',
        marginTop: 60,
        fontSize: 10,
        marginRight: 360,

        style: 'center',
        stack: [
          `${format(new Date(), 'dd-MM-yyyy')}`,
          { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 100, y2: 0, lineWidth: 1 }] },
          { text: `FECHA`, bold: true },
        ],
      };

      const NAME_PATIENT = {
        alignment: 'center',
        marginTop: -24,
        fontSize: 10,

        style: 'title',
        stack: [
          `${patientNameValue}`,
          { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 200, y2: 0, lineWidth: 1 }] },
          { text: `NOMBRE PACIENTE`, bold: true },
        ],
      };

      const NAME_DOCTOR = {
        alignment: 'center',
        marginTop: -24,
        fontSize: 10,

        style: 'title',
        stack: [
          `${medicoValue}`,
          { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 200, y2: 0, lineWidth: 1 }] },
          { text: `NOMBRE MÉDICO`, bold: true },
        ],
      };
      const SIGN_PATIENT = {
        alignment: 'center',
        marginTop: -12,
        fontSize: 10,
        marginLeft: 400,
        style: 'title',
        stack: [
          { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 100, y2: 0, lineWidth: 1 }] },
          { text: `FIRMA PACIENTE`, bold: true },
        ],
      };

      const SIGN_DOCTOR = {
        alignment: 'center',
        marginTop: -12,
        fontSize: 10,
        marginLeft: 400,

        style: 'title',
        stack: [
          { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 100, y2: 0, lineWidth: 1 }] },
          { text: `FIRMA MÉDICO`, bold: true },
        ],
      };

      const FONASA_PAD_INSCRIPTION_CONTENT = {
        fontSize: 15,
        alignment: 'justify',
        stack: [names, rut, diagnostics, hasEvaluation, spermiogram, observa],
      };

      return [
        FONASA_PAD_INSCRIPTION_CONTENT,
        DATE_PATIENT,
        NAME_PATIENT,
        SIGN_PATIENT,
        note1,
        note2,
        // DATE_DOCTOR,
        // NAME_DOCTOR,
        // SIGN_DOCTOR,
      ];
      }
  );
};
