import { Pipe, PipeTransform } from '@angular/core';
import intervalToDuration from 'date-fns/intervalToDuration';
import { isDate } from 'date-fns';

@Pipe({
  name: 'birth',
})
export class BirthPipe implements PipeTransform {
  transform(birth: Date): string {
    if (!birth) return '';
    const { years, months, days } = intervalToDuration({ start: birth, end: new Date() });
    const age = `${years} años ${months} meses ${days} días `;
    return age;
  }
}
