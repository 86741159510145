/* eslint-disable no-debugger */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {Component, HostListener, OnDestroy} from '@angular/core';
import {Platform} from '@ionic/angular';
import {interval} from 'rxjs';
import {VersionManagementService} from '@shared/services/version-management.service';

const TIMER_TO_VERIFY_VERSION = 10 * 10000;

@Component({
  selector   : 'app-root',
  templateUrl: 'app.component.html',
  styleUrls  : ['app.component.scss'],
})
export class AppComponent {

  timerToVerifyVersion$ = interval(TIMER_TO_VERIFY_VERSION);

  constructor(
    private platform: Platform,
    private versionManagementService: VersionManagementService
  ) {
    this.initializeApp();
  }

  @HostListener('window:unload', ['$event'])
  onWindowClose(event: any): void {
    event.preventDefault();
    event.returnValue = 'false';
  }

  async initializeApp(): Promise<void> {
    this.timerToVerifyVersion$.subscribe(_ => this.versionManagementService.checkVersionIsChanged(false));
    //const isVersionChanged = await this.versionManagementService.checkVersionIsChanged(false);
    //if (isVersionChanged) await this.versionManagementService.clearCacheAndCookies();
    this.platform.ready().then(() => {
      // this.checkDarkMode();
    });
  }

  checkDarkMode(): void {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    if (prefersDark.matches) document.body.classList.toggle('dark');
  }
}
