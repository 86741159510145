import {Injectable} from '@angular/core';

import {ReportService} from '@components/records/services/report.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  DELETE_DISCHARGE_MEDICAL,
  DELETE_DISCHARGE_MEDICAL_ERR,
  DELETE_DISCHARGE_MEDICAL_OK,
  LOAD_DISCHARGE_MEDICAL_BY_PATIENT,
  LOAD_DISCHARGE_MEDICAL_BY_PATIENT_ERR,
  LOAD_DISCHARGE_MEDICAL_BY_PATIENT_OK,
  LOAD_DISCHARGE_MEDICAL_BY_PROFESSIONAL,
  LOAD_DISCHARGE_MEDICAL_BY_PROFESSIONAL_ERR,
  LOAD_DISCHARGE_MEDICAL_BY_PROFESSIONAL_OK,
  PUT_DISCHARGE_MEDICAL,
  PUT_DISCHARGE_MEDICAL_END,
  PUT_DISCHARGE_MEDICAL_ERR,
  PUT_DISCHARGE_MEDICAL_OK,
} from './clinical-discharge.action';

import {of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import { EpicrisisNursingService } from '../services/epicrisis-nursing.service';

@Injectable()
export class DischargeEffects {
  putDischarge$                 = createEffect(() =>
      this.actions$.pipe(
          ofType(PUT_DISCHARGE_MEDICAL),
          mergeMap(({valuesToSave}) =>
              this.epicrisisNursingService.putDischarge({...valuesToSave}).pipe(
                  map((discharge) => PUT_DISCHARGE_MEDICAL_OK({discharge})),
                  catchError((err) => of(PUT_DISCHARGE_MEDICAL_ERR({payload: err}))),
              ),
          ),
      ),
  );


  clear$ = createEffect(() => this.actions$.pipe(ofType(PUT_DISCHARGE_MEDICAL_OK), map(() => PUT_DISCHARGE_MEDICAL_END())));

  loadDischargeByPatient$      = createEffect(() =>
      this.actions$.pipe(
          ofType(LOAD_DISCHARGE_MEDICAL_BY_PATIENT),
          mergeMap(({params}) =>
              this.reportService.findByType2(params.patientId, params.type).pipe(
                  map((discharge) => LOAD_DISCHARGE_MEDICAL_BY_PATIENT_OK({discharge: discharge})),
                  catchError((err) => of(LOAD_DISCHARGE_MEDICAL_BY_PATIENT_ERR({payload: err}))),
              ),
          ),
      ),
  );
  loadDischargeByProfessional$ = createEffect(() =>
      this.actions$.pipe(
          ofType(LOAD_DISCHARGE_MEDICAL_BY_PROFESSIONAL),
          mergeMap(({gsiMultiCondition1St}) =>
              this.reportService.findByGsi(gsiMultiCondition1St).pipe(
                  map((discharge) => LOAD_DISCHARGE_MEDICAL_BY_PROFESSIONAL_OK({discharge: discharge})),
                  catchError((err) => of(LOAD_DISCHARGE_MEDICAL_BY_PROFESSIONAL_ERR({payload: err}))),
              ),
          ),
      ),
  );
  deleteDischarge$              = createEffect(() =>
      this.actions$.pipe(
          ofType(DELETE_DISCHARGE_MEDICAL),
          mergeMap((indication) =>
              this.reportService.deleteByIds2('altamedica', indication).pipe(
                  map((discharge) => DELETE_DISCHARGE_MEDICAL_OK({discharge: discharge})),
                  catchError((err) => of(DELETE_DISCHARGE_MEDICAL_ERR({payload: err}))),
              ),
          ),
      ),
  );

  constructor(
      private actions$: Actions,
      private epicrisisNursingService: EpicrisisNursingService,
      private reportService: ReportService) {
  }
}
