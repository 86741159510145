import { Pipe, PipeTransform } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'minValue',
})
export class MinValuePipe implements PipeTransform {
  transform(value: number): SafeResourceUrl {
    return value > 0 ? value : 0;
  }
}
