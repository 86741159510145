import { Injectable } from '@angular/core';
import { AuthService } from '@auth/services/auth.service';
import { ScheduleService } from '@components/agenda/services/agenda.service';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { ModalResolutionsComponent } from '@components/records/modal-resolutions/modal-resolutions.component';
import { UiService } from '@shared/services/ui.service';
import { AppState } from '@store/app.reducers';
import { Subject, throwError } from 'rxjs';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';
import { LOAD_SCHEDULE, LOAD_SCHEDULING } from '@store/actions';
import { KEY_PROFESSIONAL_STORE } from '@store/store-keys';
import { endOfMonth, startOfMonth } from 'date-fns';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ActionsResolutionsService {
  isLoading = false;
  professionalId;
  profesionalNames: string;
  sk;
  isLoadedProfessional: boolean;
  isSearchProfessional: boolean;
  error;

  destroy = new Subject();
  subModule: string;
  constructor(
    public authService: AuthService,
    public store: Store<AppState>,
    public modalController: ModalController,
    public scheduleService: ScheduleService,
    public toastController: ToastController,
    public uiService: UiService,
    public alertController: AlertController,
    public router: Router,
  ) {
    this.readStoreProfessional();
    this.listenRoute();
  }

  listenRoute() {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: any) => {
        const url = event.url.split('/');
        this.subModule = url[2];
      })
    ).subscribe();
  }
  dispatch() {
    this.store.select('professional').subscribe((professional) => {
      if (professional.isLoaded && professional.professional) {
        const dateFrom = startOfMonth(new Date()).toISOString().slice(0, 10);
        const dateTo = endOfMonth(new Date()).toISOString().slice(0, 10);
        const params = {
          professionalId: `${professional.professional.id}?dateFrom=${dateFrom}&dateTo=${dateTo}`,
        };

          this.store.dispatch(LOAD_SCHEDULE({...params}));

      }
    });
  }

  readStoreProfessional(): void {
    this.store.select(KEY_PROFESSIONAL_STORE).subscribe(({ professional, isLoading, isLoaded, error }) => {
      this.isLoadedProfessional = isLoading;
      this.isSearchProfessional = isLoaded;
      this.error = error;
      if (isLoaded && professional) {
        this.professionalId = professional.id;
        this.profesionalNames = professional.names + ' ' + professional.surnames;
      }
    });
  }

  async presentAlert(header, message) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header,
      message,
      buttons: ['OK'],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
  }

  changeState(row) {
    const idProfessional = row.data.professional.id;
    this.isLoading = true;
    const request = row.request;
    const center = row.data.center;
    const type = row.data.type;
    const professional = row.data.professional;
    const createdAt = row.createdAt;
    const specialty = row.specialty;
    const profesionalNames = this.profesionalNames;
    const box = row.box;
    const internalExclusionId = row?.internalExclusionId
    if (row.data.type === 'Offer' || row.data.type === 'Include' || row.data.type === 'Closure')
      if (
        (row.isTaken === false && row.data.status === 'pendiente') ||
        (row.isTaken === false && row.data.status === 'pending')
      ) {
        if (row.data.type === 'Include' || row.data.type === 'Offer')
          this.sk = `PRO#${idProfessional}#REQ#AVA#${row.id}`;
        if (row.data.type === 'Closure') this.sk = `PRO#${idProfessional}#REQ#CLO#${row.id}`;

        const body = {
          sk: this.sk,
          id: row.id,
          status: 'pre-approved',
          isTaken: false,
          professional,
          center,
          institute: row.institute,
          type,
          request,
          createdAt,
          specialty,
          box,
        };
        this.scheduleService
          .addRequest(body, row.type.toLowerCase())
          .pipe(
            takeUntil(this.destroy),
            catchError((err) => {
              const msgServiceResolutions = err?.error?.error?.errorMessage;
              this.presentAlert('ERROR', msgServiceResolutions);
              return throwError(() => err);
            }),
            map(async (resp) => {
              this.isLoading = false;
              const toast = await this.toastController.create({
                message: 'La solicitud ha sido pre-aprobada.',
                duration: 3000,
                position: 'middle',
                color: 'tertiary',
              });
              toast.present();
              this.dispatch();
            }),
          )
          .subscribe();
      }

    if (row.data.status === 'pre-approved' && row.data.type !== 'Exclude') {
      this.isLoading = true;
      if (row.data.type === 'Include' || row.data.type === 'Offer') this.sk = `PRO#${idProfessional}#REQ#AVA#${row.id}`;
      if (row.data.type === 'Closure') this.sk = `PRO#${idProfessional}#REQ#CLO#${row.id}`;
      const body = {
        sk: this.sk,
        id: row.id,
        status: 'managed',
        isTaken: false,
        professional,
        professionalManeged: profesionalNames,
        center,
        institute: row.institute,
        type,
        request,
        createdAt,
        specialty,
        box,
        internalExclusionId
      };
      this.scheduleService
        .addRequest(body, row.type.toLowerCase())
        .pipe(
          takeUntil(this.destroy),
          catchError((err) => {
            const msgServiceResolutions = err?.error?.error?.errorMessage;
            this.presentAlert('ERROR', msgServiceResolutions);
            return throwError(() => err);
          }),
          map(async (resp) => {
            const toast = await this.toastController.create({
              message: 'La solicitud ha sido Gestionada.',
              duration: 3000,
              position: 'middle',
              color: 'tertiary',
            });
            toast.present();
            this.dispatch();

            this.isLoading = false;
          }),
        )
        .subscribe();
    }

    if (row.type === 'Bloqueo') {
      if (row.data.status === 'pre-approved') {
        const messageAproved = `<p>¿Desea aprobar esta solicitud?</p>`;
        const buttonsAproved = [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            id: 'cancel-button',
          },
          {
            text: 'OK',
            handler: () => {
              this.isLoading = true;
              const body = {
                sk: `PRO#${idProfessional}#REQ#EXC#${row.id}`,
                pk: 'AGN#35182890-5485-11ec-bf63-0242ac130002',
                id: row.id,
                status: 'managed',
                isTaken: false,
                professionalManeged: profesionalNames,
                professional,
                center,
                institute: row.institute,
                type,
                request,
                createdAt,
                internalExclusionId,
                box,
              };

              this.scheduleService
                .addAproveBlockPRM(body)
                .pipe(
                  takeUntil(this.destroy),
                  catchError((err) => {
                    const msgServiceResolutions = err.error.data.message;
                    this.presentAlert('INFORMACIÓN', msgServiceResolutions);
                    return throwError(() => err);
                  }),
                  map(async (resp) => {
                    const toast = await this.toastController.create({
                      message: 'La solicitud ha sido aprobada.',
                      duration: 3000,
                      position: 'middle',
                      color: 'tertiary',
                    });
                    toast.present();
                    this.dispatch();
                  }),
                )
                .subscribe();
            },
          },
        ];
        this.uiService.presentAlert('info', messageAproved, buttonsAproved);
        this.isLoading = false;
        return;
      }

      const message = `<p>¿Desea pre-aprobar esta solicitud?</p>`;
      const buttons = [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
        },
        {
          text: 'OK',
          handler: () => {
            this.isLoading = true;
            const body = {
              sk: `PRO#${idProfessional}#REQ#EXC#${row.id}`,
              pk: 'AGN#35182890-5485-11ec-bf63-0242ac130002',
              id: row.id,
              status: 'pre-approved',
              isTaken: false,
              professional,
              professionalManeged: profesionalNames,
              center,
              institute: row.institute,
              type,
              request,
              createdAt,
              internalExclusionId,
              box,
            };

            this.scheduleService
              .addBlockPRM(body)
              .pipe(
                takeUntil(this.destroy),
                catchError((err) => {
                  const msgServiceResolutions =
                    err.error.data.errorMessage === undefined ? err.data.errorMessage : err.error.data.errorMessage;
                  this.presentAlert('ERROR', msgServiceResolutions);
                  return throwError(() => err);
                }),
                map(async () => {
                  const toast = await this.toastController.create({
                    message: 'La solicitud ha sido pre-aprobada.',
                    duration: 3000,
                    position: 'middle',
                    color: 'tertiary',
                  });
                  toast.present();
                  this.dispatch();
                }),
              )
              .subscribe();
          },
        },
      ];
      this.uiService.presentAlert('info', message, buttons);
      this.isLoading = false;
    }
  }
  async reject(row) {
    const idProfessional = row.data.professional.id;

    const modal = await this.modalController.create({
      component: ModalResolutionsComponent,
      cssClass: 'modal-md',
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data !== undefined) {
      this.isLoading = true;
      const request = row.request;
      const center = row.data.center;
      const professional = row.data.professional;
      const type = row.data.type;
      const reasonReject = data;
      const createdAt = row.createdAt;
      const specialty = row.specialty;
      const box = row.box;
      const profesionalNames = this.profesionalNames;
      if (row.data.type === 'Include' || row.data.type === 'Offer') this.sk = `PRO#${idProfessional}#REQ#AVA#${row.id}`;
      if (row.data.type === 'Closure') this.sk = `PRO#${idProfessional}#REQ#CLO#${row.id}`;
      if (row.data.type === 'Exclude') this.sk = `PRO#${idProfessional}#REQ#EXC#${row.id}`;
      const body = {
        sk: this.sk,
        id: row.id,
        status: 'rejected',
        isTaken: false,
        professional,
        center,
        institute: row.institute,
        type,
        reasonReject,
        professionalReject: profesionalNames,
        request,
        createdAt,
        specialty,
        box,
      };

      this.scheduleService
        .addRequest(body, row.type.toLowerCase())
        .pipe(
          takeUntil(this.destroy),
          catchError((err) => {
            const msgServiceResolutions = err?.error?.error?.errorMessage;
            this.presentAlert('ERROR', msgServiceResolutions);
            return throwError(() => err);
          }),
          map(async (resp) => {
            this.isLoading = false;
            const toast = await this.toastController.create({
              message: 'La solicitud ha sido rechazada.',
              duration: 3000,
              position: 'middle',
              color: 'danger',
            });
            toast.present();
            this.dispatch();
          }),
        )
        .subscribe();
    }
  }

  reserveRequest(row) {
    const idProfessional = row.data.professional.id;
    const request = row.request;
    const center = row.data.center;
    const professional = row.data.professional;
    const type = row.data.type;
    const isTaken = row.isTaken;
    const createdAt = row.createdAt;
    const specialty = row.specialty;
    const box = row.box;

    if (isTaken === false) {
      if (row.data.type === 'Include' || row.data.type === 'Offer') this.sk = `PRO#${idProfessional}#REQ#AVA#${row.id}`;
      if (row.data.type === 'Closure') this.sk = `PRO#${idProfessional}#REQ#CLO#${row.id}`;
      if (row.data.type === 'Exclude') this.sk = `PRO#${idProfessional}#REQ#EXC#${row.id}`;
      this.isLoading = true;
      const body = {
        sk: this.sk,
        id: row.id,
        status: 'pre-approved',
        isTaken: true,
        professional,
        center,
        institute: row.institute,
        type,
        specialty,
        request,
        createdAt,
        box,
      };

      this.scheduleService
        .addRequest(body, row.type.toLowerCase())
        .pipe(
          takeUntil(this.destroy),
          catchError((err) => {
            const msgServiceResolutions = err?.error?.error?.errorMessage;
            this.presentAlert('ERROR', msgServiceResolutions);
            return throwError(() => err);
          }),
          map(async (resp) => {
            this.isLoading = false;
            const toast = await this.toastController.create({
              message: 'La solicitud ha sido reservada.',
              duration: 3000,
              position: 'middle',
              color: 'success',
            });
            toast.present();
            this.dispatch();
          }),
        )
        .subscribe();
    } else {
      if (row.data.type === 'Include' || row.data.type === 'Offer') this.sk = `PRO#${idProfessional}#REQ#AVA#${row.id}`;
      if (row.data.type === 'Closure') this.sk = `PRO#${idProfessional}#REQ#CLO#${row.id}`;
      if (row.data.type === 'Exclude') this.sk = `PRO#${idProfessional}#REQ#EXC#${row.id}`;
      this.isLoading = true;
      const body = {
        sk: this.sk,
        id: row.id,
        status: 'pre-approved',
        isTaken: false,
        professional,
        center,
        institute: row.institute,
        type,
        specialty,
        request,
        createdAt,
        //  updateAt,
        box,
      };

      this.scheduleService
        .addRequest(body, row.type.toLowerCase())
        .pipe(
          takeUntil(this.destroy),
          catchError((err) => {
            const msgServiceResolutions = err?.error?.error?.errorMessage;
            this.presentAlert('ERROR', msgServiceResolutions);
            return throwError(() => err);
          }),
          map(async (resp) => {
            this.isLoading = false;
            const toast = await this.toastController.create({
              message: 'La solicitud ha sido liberada.',
              duration: 3000,
              position: 'middle',
              color: 'success',
            });
            toast.present();
            this.dispatch();
          }),
        )
        .subscribe();
    }
  }
}
