import { Injectable } from '@angular/core';
import { YEAR_MONTH_GSI } from '@shared/constants/format-dates.const';

import { PAPERWORKS } from '@shared/constants/paperworks.const';

import {
  AccessPatterns,
  Paperwork,
  PaperworkDefinition,
  PaperworkReference,
} from '@shared/interfaces/paperwork.interface';
import { FileToStorage } from '@shared/interfaces/storage.interface';
import { DateService } from '@shared/services/date.service';
import { PaperworkState } from '@store/reducers';
import format from 'date-fns/format';

@Injectable({
  providedIn: 'root',
})
export class PaperworkService {
  constructor() {}

  async getAccessPatternsPaperwork(paperwork: PaperworkReference): Promise<AccessPatterns> {
    const { unixtime, datetime } = await DateService.getTime();
    const control = Math.floor(Math.random() * 999);
    const paperworkApp = PAPERWORKS.find((app) => app.module === paperwork.module);
    const yearMonth = format(new Date(datetime), YEAR_MONTH_GSI);
    const prefix = paperworkApp.prefix.toUpperCase();
    const professionalId = paperwork.professionalId;
    const suffix = paperwork.submodule.suffix.toUpperCase();
    const shortId = suffix + unixtime + control;
    const gsiMultiCondition2Nd = shortId;
    const gsiMultiCondition1St = `${prefix}#DTE#${yearMonth}#PRO#${professionalId}#${suffix}`;

    return { prefix, suffix, shortId, datetime, gsiMultiCondition2Nd, gsiMultiCondition1St };
  }

  clearRecordForSave(paperworkState): { paperwork: Paperwork; definition: PaperworkDefinition } {
    const { paperwork, definition } = paperworkState;
    const professional = { ...paperwork.professional, base64: undefined };
    return { paperwork: { ...paperwork, professional }, definition };
  }

  isSignatureRequired(paperworkState: PaperworkState): boolean {
    const { isSigned } = paperworkState.definition.submodule.workflow;
    return isSigned;
  }

  getFilesToStore(paperworkState: PaperworkState): FileToStorage[] {
    const { isStored } = paperworkState.definition.submodule.workflow;
    const { files } = paperworkState;

    if (isStored || files.length) return files;
    return [];
  }

  isStorageRequired(paperworkState: PaperworkState): boolean {
    const { isStored } = paperworkState.definition.submodule.workflow;
    return isStored;
  }

  isPutRequired(paperworkState: PaperworkState): boolean {
    const { isSaving } = paperworkState.definition.submodule.workflow;
    return isSaving;
  }

  isRequiredSendEmail(paperworkState: PaperworkState): boolean {
    const { notifications } = paperworkState.definition.submodule.workflow;
    return notifications.length ? true : false;
  }

  isRequiredNextControl(paperworkState: PaperworkState) {
    const { hasNextControl } = paperworkState.definition.submodule.workflow;
    return hasNextControl;
  }
}
