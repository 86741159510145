import { DOCUMENT_TYPE } from '@shared/constants/patient.constant';
import { Paperwork } from '@shared/interfaces/paperwork.interface';
import { DateService } from '@shared/services/date.service';

export const patientTopCard = (paperwork: Paperwork, breakPage?) => {
  const {patient, appointment}                                                                    = paperwork;
  const {email, documentNumber, documentType, names, surnames, address, birth, phone, isTemporal} = patient;

  const fullname    = `${names ? names.toUpperCase() : ''} ${surnames ? surnames.toUpperCase() : ''}`;
  const fulladdress = getFullAddress(address);
  const date = getLocalDate();
  const center      = appointment ? appointment.center : undefined;

  const CENTER = [
    {text: 'Atención:'},
    { text: `${date}`, bold: true },
    {text: center ? `Centro:` : ''},
    {text: center ? `${appointment?.center}` : ''},
  ];

  const EMPTY_LINE    = ['', '', '', ''];
  const LAYOUT_CONFIG = {
    hLineWidth: (i: any, node: any) => (i === 0 || i === node.table.body.length ? 0.5 : 0),
    vLineWidth: (i: any, node: any) => (i === 0 || i === node.table.widths.length ? 0 : 0),
    hLineColor: (i: any, node: any) => (i === 0 || i === node.table.body.length ? 'gray' : 'gray'),
    vLineColor: (i: any, node: any) => (i === 0 || i === node.table.widths.length ? 'gray' : 'gray'),
    fillColor : (rowIndex: number) => (rowIndex % 2 === 0 ? '#FCFCFC' : '#FCFCFC'),
  };

  return [
    {
      pageBreak: breakPage ? 'before' : '',
      margin   : [0, 0, 0, 25],
      style    : 'content',
      table    : {
        widths: [50, '*', 35, 'auto'],
        body  : [
          CENTER,
          [
            'Paciente:',
            {text: `${fullname}`, bold: true},
            {text: isTemporal ? '' : 'Edad:'},
            {text: isTemporal ? '' : DateService.yearsOld(birth), bold: true},
          ],
          [
            `${DOCUMENT_TYPE[documentType.toLowerCase()]}:`,
            {text: `${documentNumber}`, bold: true},
            {text: 'Correo:'},
            {text: `${!email ? '' : email.toUpperCase()}`, bold: true},
          ],
          address && address.locality
              ? [
                'Dirección:',
                {text: `${fulladdress}`, bold: true},
                {text: `Teléfono:`, margin: [0, 0, -10, 0]},
                {text: `\t ${phone}`, bold: true},
              ]
              : EMPTY_LINE,
        ],
      },
      layout   : LAYOUT_CONFIG,
    },
  ];
};

const getFullAddress = (address): string => {
  if (!address) return '';
  const street       = address.street ? address.street.toLocaleUpperCase() : '';
  const streetNumber = address.streetNumber ? address.streetNumber.toLocaleUpperCase() : '';
  const locality     = address.locality ? address.locality.toLocaleUpperCase() : '';

  return `${street} ${streetNumber} ${locality}`;
};

const getLocalDate = (): string => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${day}/${month}/${year}`;
};
