import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ViewStatus } from '@clinical/schedule/enums/type.enum';

@Component({
  selector: 'app-modal-reason-reject',
  templateUrl: './modal-reason-reject.component.html',
  styleUrls: ['./modal-reason-reject.component.scss'],
})
export class ModalReasonRejectComponent implements OnInit {
  @Input() data;
  title: string;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    const { status } = this.data;
    this.title = ViewStatus[status];
  }

  dismiss() {
    this.modalController.dismiss();
    this.title = '';
  }
}
