import { buildContentPdfHospital } from '@clinical/hospital/utils/build-pdf-hospital';
import { DEFAULT_PDF_POSITION_SIGNATURE } from '@shared/constants/app.const';
import { PrintedOnPage, PrintMode } from '@shared/interfaces/paperwork.interface';
import { DELETE_ADMISSION, PUT_ADMISSION } from '@store/actions';
import { ADMISSIONS_ROUTES } from '@clinical/admissions/admissions-routes.module';

const workflow = {
  isSigned     : true,
  isStored     : true,
  isSaving      : true,
  hasNextControl: true,
  notifications: [
    {
      type    : 'email',
      entity  : 'patient',
      subject : 'Notificaciones UC CHRISTUS | Admisión Hospitalaria',
      template: 'generic',
    },
  ],
};

const pdf = {
  isPreview: true,
  header   : {logo: PrintedOnPage.last, title: PrintedOnPage.all, professional: PrintedOnPage.all},
  patient  : {type: PrintMode.full, printed: PrintedOnPage.all},
  footer   : {
    signature: {printed: PrintedOnPage.all, position: DEFAULT_PDF_POSITION_SIGNATURE},
    qr       : {right: {url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web'}},
  },
};

export const PAPERWORK_ADMISSION_DEFINITION = {
  prefix  : 'ADM',
  module  : 'admision',
  actions : {
    put   : PUT_ADMISSION,
    delete: DELETE_ADMISSION,
  },
  admission: {
    suffix           : 'FAH',
    group            : 'admisión hospitalaria',
    docTitle         : 'Admisión Hospitalaria',
    submodule: ADMISSIONS_ROUTES.admission,
    getBodyDefinition: buildContentPdfHospital,
    workflow,
    config           : pdf,
  },
};
