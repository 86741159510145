import { HttpErrorResponse } from '@angular/common/http';

import { ParamsToFindByPatient } from '@components/patient/interfaces/patient.interface';
import { createAction, props } from '@ngrx/store';
import { Paperwork } from '@shared/interfaces/paperwork.interface';
import { Recipe } from '@shared/interfaces/recipe.interface';
import { MagistralRecipe } from '@shared/interfaces/recipe-magistral.interface';

export const PUT_RECIPE     = createAction(
    '[medicamentos] PUT Receta Médica',
  props<{ valuesToSave: Paperwork<Recipe | MagistralRecipe> }>(),
);
export const PUT_RECIPE_OK  = createAction(
    '[medicamentos] OK PUT Receta Médica',
  props<{ recipe: Paperwork<Recipe | MagistralRecipe> }>(),
);
export const PUT_RECIPE_ERR = createAction(
    '[medicamentos] ERR PUT Receta Médica',
    props<{ payload: HttpErrorResponse }>(),
);

export const LOAD_RECIPES_BY_PROFESSIONAL     = createAction(
    '[medicamentos] Cargar medicamentos por paciente',
    props<{ gsiMultiCondition1St: string }>(),
);
export const LOAD_RECIPES_BY_PROFESSIONAL_OK  = createAction(
    '[medicamentos] OK medicamentos por paciente',
  props<{ recipes: Paperwork<Recipe | MagistralRecipe>[] }>(),
);
export const LOAD_RECIPES_BY_PROFESSIONAL_ERR = createAction(
    '[medicamentos] ERR medicamentos por paciente',
    props<{ payload: HttpErrorResponse }>(),
);

export const DELETE_RECIPE     = createAction(
    '[medicamentos] DELETE Receta Médica',
  props<{ recipe: Paperwork<Recipe | MagistralRecipe> }>(),
);
export const DELETE_RECIPE_OK  = createAction(
    '[medicamentos] OK DELETE Receta Médica',
  props<{ recipe: Paperwork<Recipe | MagistralRecipe> }>(),
);
export const DELETE_RECIPE_ERR = createAction(
    '[medicamentos] ERR DELETE Receta Médica',
    props<{ payload: HttpErrorResponse }>(),
);

export const PUT_RECIPE_END   = createAction('[medicamentos] Resetea el estado para crear una nueva solicitud');
export const CLEAR_RECIPES   = createAction('[medicamentos] Limpiar medicamentos por paciente');
export const SELECTED_RECIPE = createAction(
    '[medicamentos] SELECTED Receta Médica',
  props<{ selected: Paperwork<Recipe | MagistralRecipe>; action: string }>(),
);
export const UNSELECT_RECIPE = createAction('[medicamentos] UNSELECT Receta Médica');

export const LOAD_RECIPES_BY_PATIENT     = createAction(
    '[medicamentos] Cargar obtener medicamentos por paciente',
    props<{ params: ParamsToFindByPatient }>(),
);
export const LOAD_RECIPES_BY_PATIENT_OK  = createAction(
    '[medicamentos] OK obtener medicamentos por paciente',
  props<{ recipes: Paperwork<Recipe | MagistralRecipe>[] }>(),
);
export const LOAD_RECIPES_BY_PATIENT_ERR = createAction(
    '[medicamentos] ERR obtener medicamentos por paciente',
    props<{ payload: HttpErrorResponse }>(),
);
