import { buildContentPdfDerivation } from '@clinical/derivations/utils/build-pdf-derivation';
import { PUT_EMERGENCY } from '@clinical/emergencies/store/clinical-emergencies.actions';
import { DEFAULT_PDF_POSITION_SIGNATURE } from '@shared/constants/app.const';
import { PrintedOnPage, PrintMode } from '@shared/interfaces/paperwork.interface';
import { DAU_ROUTES } from '@clinical/emergencies/emergencies-routes.module';

export const PAPERWORK_EMERGENCIES_DEFINITION = {
  actions  : {
    put: PUT_EMERGENCY,
  },
  prefix   : 'URG',
  module   : 'emergencies',
  emergency: {
    suffix           : 'EMR',
    group            : 'detalle urgencia',
    docTitle         : 'detalle urgencia',
    submodule: DAU_ROUTES.dauDetails,
    getBodyDefinition: buildContentPdfDerivation, //TODO:cambair
    workflow         : {
      isSigned: true,
      isStored: true,
      isSaving: true,
      hasNextControl: true,
      notifications: [
        {
          type    : 'email',
          entity  : 'patient',
          subject : 'Notificaciones UC CHRISTUS | Derivación ó Interconsulta',
          template: 'generic',
        },
      ],
    },
    config           : {
      isPreview: true,
      header   : {logo: PrintedOnPage.last, title: PrintedOnPage.all, professional: PrintedOnPage.all},
      patient  : {type: PrintMode.full, printed: PrintedOnPage.all},
      footer   : {
        signature: {printed: PrintedOnPage.all, position: DEFAULT_PDF_POSITION_SIGNATURE},
        qr       : {right: {url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web'}},
      },
    },
  },
};
