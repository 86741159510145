import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'suffixToText'
})
export class SuffixToTextPipe implements PipeTransform {
  transform(suffix: string): string {
    if (suffix === 'EPI') return 'Enviar';
     else return 'Firmar y Enviar';
    
  }
}