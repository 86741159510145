import { Action, createReducer, on } from '@ngrx/store';
import { Paperwork } from '@shared/interfaces/paperwork.interface';
import { Recipe } from '@shared/interfaces/recipe.interface';
import { MagistralRecipe } from '@shared/interfaces/recipe-magistral.interface';

import {
  CLEAR_RECIPES,
  DELETE_RECIPE,
  DELETE_RECIPE_ERR,
  DELETE_RECIPE_OK,
  LOAD_RECIPES_BY_PATIENT,
  LOAD_RECIPES_BY_PATIENT_ERR,
  LOAD_RECIPES_BY_PATIENT_OK,
  LOAD_RECIPES_BY_PROFESSIONAL,
  LOAD_RECIPES_BY_PROFESSIONAL_ERR,
  LOAD_RECIPES_BY_PROFESSIONAL_OK,
  PUT_RECIPE,
  PUT_RECIPE_END,
  PUT_RECIPE_ERR,
  PUT_RECIPE_OK,
  SELECTED_RECIPE,
  UNSELECT_RECIPE,
} from '@store/actions';

export interface RecipesState {
  recipes: Paperwork<Recipe | MagistralRecipe>[];
  historical: Paperwork<Recipe | MagistralRecipe>[];
  isLoaded: boolean;
  isLoading: boolean;
  status: string;
  error: any;
  selected: Paperwork<Recipe | MagistralRecipe>;
  lastRecipe: Paperwork<Recipe | MagistralRecipe>;
  current: Paperwork;
  isSaving: boolean;
}

export const recipeState: RecipesState = {
  recipes: [],
  historical: [],
  isLoaded: false,
  isLoading: false,
  status: '',
  error: null,
  selected: null,
  lastRecipe: null,
  current: null,
  isSaving: false,
};

const createReducerRecipe = createReducer(
  recipeState,

  // Obtener las recetas de un profesional
  on(LOAD_RECIPES_BY_PROFESSIONAL, (state) => ({ ...state, isLoading: true })),
  on(LOAD_RECIPES_BY_PROFESSIONAL_OK, (state, { recipes: recipes }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    historical: [...recipes],
  })),
  on(LOAD_RECIPES_BY_PROFESSIONAL_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  // Obtener data de un paciente
  on(LOAD_RECIPES_BY_PATIENT, (state) => ({ ...state, isLoading: true })),
  on(LOAD_RECIPES_BY_PATIENT_OK, (state, { recipes: recipes }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    recipes: [...recipes],
  })),
  on(LOAD_RECIPES_BY_PATIENT_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(CLEAR_RECIPES, (state) => ({
    recipes: [...state.recipes],
    historical: [...state.historical],
    isLoading: false,
    isLoaded: false,
    error: null,
    status: '',
    selected: null,
    lastRecipe: null,
    current: null,
    isSaving: false,
  })),

  on(PUT_RECIPE_END, (state) => ({ ...state, isSaving: false })),
  on(PUT_RECIPE, (state) => ({ ...state, isLoading: true })),
  on(PUT_RECIPE_OK, (state, { recipe }) => {
    const recipes = [];
    const history = [];
    state.recipes.map((ans) => {
      if (ans.id !== recipe.id) recipes.push(ans);
    });
    state.historical.map((ans) => {
      if (ans.id !== recipe.id) history.push(ans);
    });

    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      isSaving: true,
      status: 'Receta guardada con éxito',
      recipes: [recipe, ...recipes],
      historical: [recipe, ...history],
      lastRecipe: { ...recipe },
      current: { ...recipe },
    };
  }),
  on(PUT_RECIPE_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    current: null,
    isSaving: false,
    status: 'Ocurrio un error al intentar guardar la receta',
    error: { ...payload },
  })),

  on(SELECTED_RECIPE, (state, { selected, action }) => ({ ...state, selected: { ...selected }, status: action })),
  on(UNSELECT_RECIPE, (state) => ({ ...state, selected: null, isLoaded: false, isLoading: false, status: '' })),

  on(DELETE_RECIPE, (state) => ({ ...state, isLoading: true })),
  on(DELETE_RECIPE_OK, (state, { recipe }) => {
    let recipes = [...state.recipes];
    let history = [...state.historical];
    const index = recipes.findIndex((draft) => draft.id === recipe.id);
    if (index !== -1) recipes = [...recipes.filter((draft) => draft.id !== recipe.id)];

    const indexHistory = history.findIndex((draft) => draft.id === recipe.id);
    if (indexHistory !== -1) history = [...history.filter((draft) => draft.id !== recipe.id)];

    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      status: 'Receta eliminada con éxito',
      recipes: [...recipes],
      historical: [...history],
    };
  }),
  on(DELETE_RECIPE_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    status: 'Ocurrio un error al intentar eliminar la receta',
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
);

export const recipeReducer = (state: RecipesState, action: Action) => createReducerRecipe(state, action);
