export const cleanString = (str: string) => {
  let clean = str.toLowerCase();
  clean = clean.replace(new RegExp(/[àáâãäå]/g), 'a');
  clean = clean.replace(new RegExp(/[èéêë]/g), 'e');
  clean = clean.replace(new RegExp(/[ìíîï]/g), 'i');
  clean = clean.replace(new RegExp(/ñ/g), 'n');
  clean = clean.replace(new RegExp(/[òóôõö]/g), 'o');
  clean = clean.replace(new RegExp(/[ùúûü]/g), 'u');
  clean = clean.replace(new RegExp(/[ñ]/g), 'ñ');

  return clean;
};
