import { Storage } from '@ionic/storage-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AlertController, ModalController } from '@ionic/angular';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { differenceInMinutes } from 'date-fns';
import { subYears, lightFormat } from 'date-fns/esm';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { UiService } from '@shared/services/ui.service';
import { AuthService } from 'src/app/auth/services/auth.service';

import { ReportModalComponent } from '../report-modal/report-modal.component';
import { PatientReportComponent } from '../patient-report/patient-report.component';
import { MONTH, TABLES_TEMPLATES, TableTemplate } from '@components/records/constants/table.const';
import { ReportService } from '../services/report.service';

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.scss'],
})
export class ReportTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource<any>();
  displayedColumns = ['createdAt', 'patient', 'type', 'actions'];
  currentDate: Date = new Date();
  professionalId: string;
  professionalRut: string;
  url: SafeResourceUrl;
  years: number[] = [];
  hideOption = false;
  dataTemplate: any;
  isLoading = true;
  dateFull: number;
  showPdf: boolean;
  months = MONTH;
  month: any;
  newData: any;
  year: any;
  path: string;
  yearDefault: number;
  monthDefault: number;

  constructor(
    private reportService: ReportService,
    private modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private storage: Storage,
    public alertController: AlertController,
    public uiService: UiService,
    public router: Router,
  ) {}
  ngOnInit(): void {
    const { path } = this.activatedRoute.snapshot.routeConfig;
    this.path = path;
    const dataTemplate: TableTemplate = TABLES_TEMPLATES.find((data) => path === data.path);
    this.dataTemplate = dataTemplate;
    this.professionalId = this.authService?.professionalRef?.id;
    this.professionalRut = this.authService?.professionalRef?.rut;
    this.captureId();
    this.displayedColumns.splice(3, 0, dataTemplate.colum);
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async captureId() {
    await this.storage.create();
    this.yearMonth(this.currentDate);
    this.year = this.currentDate.getUTCFullYear().toString();
    this.yearDefault = this.year;
    this.month = this.currentDate.getUTCMonth() + 1;
    if (this.month < 10) this.dateFull = Number(this.year + '0' + this.month.toString());
    this.getByGsi(this.professionalId, this.dataTemplate.code, this.dateFull);
  }

  capturaValor(date: string, typeDate: string) {
    if (typeDate === 'year') this.year = date.toString();
    if (typeDate === 'month') this.month = date;
    this.dateFull = Number(this.year + this.month);
    this.getByGsi(this.professionalId, this.dataTemplate.code, this.dateFull);
  }
  yearMonth(year: Date) {
    for (let i = 0; i < 5; i++) {
      const element = subYears(year, i);
      this.years.push(element.getFullYear());
    }
  }

  getByGsi(professionalId: string, codeReport: string, dateFull: number) {
    const gsiMultiCondition1St = `${codeReport}#DTE#${dateFull}#PRO#${professionalId}`;
    this.reportService.findByGsi(gsiMultiCondition1St).subscribe((data) => {
      const displayData = this.dataTemplate.displayData;
      const arrayData = this.dataTemplate.array;
      const dataArrayData = this.dataTemplate.otherField;
      const typeExamen = [
        { type: 'Exámenes', name: 'IMÁGENES', router: 'indicaciones/examenes' },
        { type: 'Exámenes', name: 'LABORATORIO', router: 'indicaciones/examenes' },
        { type: 'Exámenes', name: 'PROCEDIMIENTOS', router: 'indicaciones/examenes' },
        { type: 'Vacunas', name: 'orden de vacunas', router: 'indicaciones/examenes' },
        { type: 'Oftalmológico', name: 'receta de lentes', router: 'indicaciones/oftalmologica' },
        { type: 'Certificado', name: 'certificado médico', router: 'formulario/certificado' },
        {
          type: 'Riesgo ETE',
          name: 'formulario de clasificación de riesgo de enfermedad tromboembólica (ETE) para pacientes quirúrgicos',
          router: 'formulario/registro-ete',
        },
        {
          type: 'PAD Fonasa',
          name: 'formulario interno inscripción pad fonasa',
          router: 'formulario/inscripcion-pad-fonasa',
        },
        { type: 'GES', name: 'notificación de patología ges', router: 'formulario/ges' },
        { type: 'Consentimiento', name: 'consentimiento informado', router: 'formulario/consentimiento' },
        {
          type: 'Consentimiento Imágenes',
          name: 'consentimiento informado para uso de imágenes',
          router: 'formulario/consentimiento-imagenes',
        },
        { type: 'Hospitalización', name: 'orden de hospitalización', router: 'hospitalizacion/orden' },
        { type: 'Orden de Cirugia', name: 'orden de cirugía', router: 'cirugias/orden' },
        {
          type: 'Orden Prod. Dermatológicos',
          name: 'orden procedimientos dermatología',
          router: 'indicaciones/dermatologia',
        },
        {
          type: 'Examen Oftalmológico',
          name: 'orden de examen oftalmología',
          router: 'indicaciones/exam-oftalmologica',
        },
        {
          type: 'Ingreso Hospitalario',
          name: 'formulario de ingreso médico hospitalario',
          router: 'ingreso-hospitalario',
        },
        {
          type: 'Receta Magistral',
          name: 'receta magistral',
          router: 'medicamentos/receta-magistral',
        },
        {
          type: 'Receta Médica',
          name: 'receta médica',
          router: 'medicamentos/receta-medica',
        },
      ];

      const fixData = data.map((newData) => {
        let typex: any;
        typex = typeExamen.find((res) => res.name === newData[arrayData][0].group);
        typex = typex === undefined ? 'Error' : typex.type;

        const dif = differenceInMinutes(this.currentDate, new Date(newData.createdAt));
        if (dif < 30 && dif != null) this.hideOption = true;
        this.newData = newData;
        let dataColum = '';
        let formatDate = '';
        //TODO: borrar este if de fecha, ver tema de datos vacios en el array, boton eliminar y editar
        if (newData.createdAt) formatDate = lightFormat(new Date(newData.createdAt), 'dd/MM/yyyy HH:mm');
        if (dataArrayData)
          newData[arrayData].map((display) => (dataColum += display[displayData][dataArrayData] + ` `));
        else newData[arrayData].map((display) => (dataColum += display[displayData] + ` `));
        return {
          createdAt: formatDate,
          patient: newData.patient.names + ' ' + newData.patient.surnames,
          colum: dataColum,
          data: this.newData,
          hideOption: false,
          type: typex,
          // type: this.dataTemplate.typeForm,
        };
      });
      this.dataSource.data = fixData;
      this.isLoading = false;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
  }
  async viewPdf(showPdf: boolean) {
    const data = this.newData;
    const dataTemplate = this.dataTemplate;
    const displayedColumns = ['createdAt', 'professional', 'specialty', 'type', 'actions'];
    let component: any;
    let cssClass: string;
    let componentProps: any;
    if (showPdf) {
      componentProps = { data, dataTemplate };
      component = ReportModalComponent;
      cssClass = 'modal-xxl-float-left';
    } else {
      // displayedColumns.splice(4, 0, this.dataTemplate.colum);
      componentProps = { data, dataTemplate, displayedColumns };
      component = PatientReportComponent;
      cssClass = 'cmodal-xl';
    }
    const modal = await this.modalController.create({
      component,
      cssClass,
      componentProps,
    });
    await modal.present();
  }
  dismiss() {
    this.modalController.dismiss();
  }
  copyReport(colum) {
    localStorage.setItem(`${this.dataTemplate.array}`, JSON.stringify(colum.array));
    this.router.navigate([`${this.dataTemplate.router}`]);
  }
  async presentAlertConfirm() {
    const message = `<p>¿Está seguro que desea eliminar este registro?</p>`;
    const buttons = [
      {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',
        id: 'cancel-button',
      },
      {
        text: 'OK',
      },
    ];

    await this.uiService.presentAlert('warning', message, buttons);
  }
}
