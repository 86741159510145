import {environment} from '@environments/environment';

const RME_SUPPORT_EMAIL = environment.supportEmail; // soporterme@ucchristus.cl
const TO_DEV_TEAM = ['dmartinezbello@gmail.com', 'lerenas@ucchristus.cl'];
const TO_QA_TEAM = ['lerenas@ucchristus.cl'];

/**
 * Lista de emails para notificar según ambiente y trámite.
 * Datos estructurados de la forma
 *  `[environment][prefix][suffix][entity][action]`
 */
export const EMAILS = {
  dev: {
    ind: {exa: {toImagesTeam: {to: TO_DEV_TEAM}}},
    der: {itc: {patient: {cc: TO_DEV_TEAM}}},
    sur: {odc: {nursesByProfesional: {to: {['9999999-3']: TO_DEV_TEAM}}}},
    frm: {
      ges: {
        byCenter: {
          to: TO_DEV_TEAM,
          cc: {
            ['centro médico lira']: TO_DEV_TEAM,
            ['centro de cáncer (ceca)']: TO_DEV_TEAM,
            ['centro médico san joaquín']: TO_DEV_TEAM,
            ['clínica san carlos de apoquindo']: TO_DEV_TEAM,
            ['centro de especialidades médicas (cem)']: TO_DEV_TEAM,
          },
        },
      },
      pad: {
        budget: {
          to: TO_DEV_TEAM,
        },
      },
      fah: {
        budget: {
          to: TO_DEV_TEAM,
        },
      },
    },
    onc: {
      tac: {
        nurses: {
          to: TO_DEV_TEAM,
          cc: TO_DEV_TEAM,
        },
      },
      rtp: {
        byCenter: {
          to: {
            ['hospital marcoleta']: TO_DEV_TEAM,
            ['clínica san carlos']: TO_DEV_TEAM,
          },
        },
      },
      rpo: {
        byPath: {
          to: {
            ['quimioterapia-hoja-ruta-ceca/cem']: TO_DEV_TEAM,
            ['quimioterapia-hoja-ruta-ceca/csc']: TO_DEV_TEAM,
          },
        },
      },
    },
  },
  qa: {
    ind: {exa: {toImagesTeam: {to: TO_QA_TEAM}}},
    der: {itc: {toBySpecialtyName: TO_QA_TEAM}},
    sur: {odc: {nursesByProfesional: {to: {['9999999-3']: TO_QA_TEAM}}}},
    frm: {
      ges: {
        byCenter: {
          to: TO_QA_TEAM,
          cc: {
            ['clínica san carlos de apoquindo']: TO_QA_TEAM,
            ['centro de especialidades médicas (cem)']: TO_QA_TEAM,
            ['centro médico san joaquín']: TO_QA_TEAM,
            ['centro médico lira']: TO_QA_TEAM,
            ['centro de cáncer (ceca)']: TO_QA_TEAM,
          },
        },
      },
      pad: {
        budget: {
          to: TO_QA_TEAM,
        },
      },
      fah: {
        budget: {
          to: TO_QA_TEAM,
        },
      },
    },
    onc: {
      tac: {
        nurses: {
          to: TO_QA_TEAM,
          cc: TO_QA_TEAM,
        },
      },
      rtp: {
        byCenter: {
          to: {
            ['hospital marcoleta']: TO_QA_TEAM,
            ['clínica san carlos']: TO_QA_TEAM,
          },
        },
      },
      rpo: {
        byPath: {
          to: {
            ['quimioterapia-hoja-ruta-ceca/cem']: TO_QA_TEAM,
            ['quimioterapia-hoja-ruta-ceca/csc']: TO_QA_TEAM,
          },
        },
      },
    },
  },
  prd: {
    ind: {exa: {toImagesTeam: {to: ['imagenes@ucchristus.cl']}}},
    der: {
      itc: {
        patient: {
          cc: {
            ['kinesiología']: ['rehabilitacion@ucchristus.cl'],
            ['pre dialisis']: ['unidaddedialisis@ucchristus.cl'],
            ['fonoaudiología']: ['rehabilitacion@ucchristus.cl'],
            ['terapia ocupacional']: ['rehabilitacion@ucchristus.cl'],
            ['programa cefaleas uc']: ['cefaleas@ucchristus.cl'],
            ['urgencia clinica san carlos']: ['urgenciasancarlosdeapoquindo@gmail.com'],
            ['seguimiento orientacion quirúrgica']: ['teayudotelemedicina@ucchristus.cl'],
            ['programa de insuficiencia cardíaca']: ['insuficienciacardiaca@ucchristus.cl'],
            ['evaluación y kinesiología integral ambulatoria']: ['rehabilitacion@ucchristus.cl'],
            ['unidad salud mental integral y recepción (usmi-r)']: ['contacto.providencia@ucchristus.cl'],
          },
        },
      },
    },
    sur: {
      odc: {
        nursesByProfesional: {
          to: {
            ['16121447-7']: ['cperedo@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['15641783-1']: ['bneira@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['17607475-2']: ['enfermeracirugiaplastica@gmail.com'],
            ['15383585-3']: ['bneira@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['15581106-4']: ['bneira@ucchristus.cl', 'cirugiadiges@ucchristus.cl', 'bneira@ucchristus.cl'],
            ['12404406-5']: ['bneira@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['7819434-0']: ['bneira@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['7023699-0']: ['bneira@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['12246272-2']: ['pmgonza@ucchristus.cl', 'sabustamante@ucchristus.cl', 'accesodedicado@ucchristus.cl',
              'cirugiadiges@ucchristus.cl'],
            ['10783950-K']: ['pmgonza@ucchristus.cl', 'sabustamante@ucchristus.cl', 'accesodedicado@ucchristus.cl',
              'cirugiadiges@ucchristus.cl'],
            ['6368652-2']: ['pmgonza@ucchristus.cl', 'sabustamante@ucchristus.cl', 'accesodedicado@ucchristus.cl',
              'cirugiadiges@ucchristus.cl'],
            ['7182715-1']: ['magomezp@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['5329312-3']: ['acarod@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['17082969-7']: ['magomezp@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['15550437-4']: ['magomezp@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['10867718-K']: ['magomezp@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['14541871-2']: ['culloap@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['14145512-5']: ['acarod@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['10677734-9']: ['culloap@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['14588347-4']: ['culloap@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['16212619-9']: ['bneira@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['15513877-7']: ['acarod@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl', 'sabustamante@ucchristus.cl'],
            ['15635979-3']: ['cperedo@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['13035581-1']: ['cperedo@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['11782184-6']: ['cperedo@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['10285161-7']: ['cperedo@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['9029566-7']: ['cperedo@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['12263824-3']: ['enfermeracirugiaplastica@gmail.com'],
            ['10012643-5']: ['enfermeracirugiaplastica@gmail.com'],
            ['15366635-0']: ['enfermeracirugiaplastica@gmail.com'],
            ['16611700-3']: ['enfermeracirugiaplastica@gmail.com'],
            ['9352133-1']: ['enfermeracirugiaplastica@gmail.com'],
            ['16142070-0']: ['constanzar.vergara@gmail.com'],
            ['10034290-1']: ['mbaeza@ucchristus.cl', 'ldiazar@ucchristus.cl'],
            ['8791897-1']: ['mbaeza@ucchristus.cl', 'ldiazar@ucchristus.cl'],
            ['12181554-0']: ['mbaeza@ucchristus.cl', 'ldiazar@ucchristus.cl'],
            ['17958709-2']: ['magomezp@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['17405885-7']: ['pmgonza@ucchristus.cl', 'sabustamante@ucchristus.cl', 'accesodedicado@ucchristus.cl',
              'cirugiadiges@ucchristus.cl'],
            ['17680147-6']: ['cperedo@ucchristus.cl', 'accesodedicado@ucchristus.cl', 'cirugiadiges@ucchristus.cl'],
            ['9999999-3']: TO_QA_TEAM,
          },
        },
      },
    },
    frm: {
      ges: {
        byCenter: {
          to: [''],
          cc: {
            ['centro de diagnóstico bilbao']: ['sumartinezf@ucchristus.cl'],
            ['centro del cáncer (ceca)']: ['lmacaya@ucchristus.cl'],
            ['centro de especialidades médicas (cem)']: ['mespinozal@ucchristus.cl'],
            ['centro médico san francisco']: ['cmenav@ucchristus.cl'],
            ['centro médico alcántara']: ['sumartinezf@ucchristus.cl'],
            ['centro médico escuela militar']: ['mlarrea@ucchristus.cl'],
            ['centro médico irarrázaval']: ['sumartinezf@ucchristus.cl'],
            ['centro médico lira']: ['cberrios@ucchristus.cl'],
            ['centro médico peñuelas coquimbo']: ['cbarrab@ucchristus.cl'],
            ['centro médico providencia']: ['cberrocal@ucchristus.cl'],
            ['utm rancagua']: ['mamoreno@ucchristus.cl'],
            ['centro médico san joaquín']: ['tlizama@ucchristus.cl'],
            ['centro médico san jorge']: ['sumartinezf@ucchristus.cl'],
            ['centro médico santa lucía']: ['madasme@ucchristus.cl'],
            ['clínica montaña colorado']: ['kfloresc@ucchristus.cl'],
            ['clínica san carlos de apoquindo']: ['kfloresc@ucchristus.cl'],
            ['clínica uc']: ['nreyess@ucchristus.cl'],
            ['hospital clínico uc']: ['nreyess@ucchristus.cl'],
            ['utm antofagasta']: ['sdelgadoa@ucchristus.cl'],
            ['utm chillán']: ['ariquelmer@ucchristus.cl'],
            ['utm concepción']: ['dcastro@ucchristus.cl'],
            ['utm quillota']: ['smontiel@ucchristus.cl'],
            ['utm viña del mar']: ['ssilvaar@ucchristus.cl']
          },
        },
      },
      pad: {
        budget: {
          to: ['presupuestos.hospitalarios@ucchristus.cl', 'baravena@ucchristus.cl'],
        },
      },
      fah: {
        budget: {
          to: ['presupuestos.hospitalarios@ucchristus.cl'],
        },
      },
    },
    onc: {
      tac: {
        nurses: {
          to: ['enfermerasradioterapia@ucchristus.cl'],
          cc: [
            'adaza@ucchristus.cl',
            'elopezc@ucchristus.cl',
            'fgomezs@ucchristus.cl',
            'otorrejon@ucchristus.cl',
            'consultasceca1@ucchristus.cl',
            'simulacionesceca@ucchristus.cl',
            'tacsimulacion@ucchristus.cl',
            'marcea@ucchristus.cl'
          ],
        },
      },
      rtp: {
        byCenter: {
          to: {
            ['hospital marcoleta']: [
              'enfermerasradioterapia@ucchristus.cl',
              'enfermerascanceruc@ucchristus.cl',
              'admisionceca@ucchristus.cl',
              'tacsimulacion@ucchristus.cl',
            ],
            ['clínica san carlos']: [
              'adaza@ucchristus.cl',
              'elopezc@ucchristus.cl',
              'fgomezs@ucchristus.cl',
              'otorrejon@ucchristus.cl',
              'calarcona@ucchristus.cl',
              'dleonicio@ucchristus.cl',
              'vrosales@ucchristus.cl',
              'tacsimulacion@ucchristus.cl',
              'oncologiacsc@ucchristus.cl',
            ],
          },
        },
      },
      rpo: {
        byPath: {
          to: {
            ['quimioterapia-hoja-ruta-ceca/cem']: ['admisionceca@ucchristus.cl'],
            ['quimioterapia-hoja-ruta-ceca/csc']: [
              'pparedesh@ucchristus.cl',
              'mcasasdv@ucchristus.cl',
              'calarcona@ucchristus.cl',
              'adaza@ucchristus.cl',
              'otorrejon@ucchristus.cl',
            ],
          },
        },
      },
    },
  },
};
