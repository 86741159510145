import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import camelcaseKeys from 'camelcase-keys';
import {formatDuration} from 'date-fns';
import add from 'date-fns/add';
import intervalToDuration from 'date-fns/intervalToDuration';
import esLocale from 'date-fns/locale/es';
import {firstValueFrom} from 'rxjs';
import {ajax} from 'rxjs/ajax';

const {worldTimeApi, baseApiPCv2} = environment;

/**
 * ! Consideración sobre el uso de los formatos de tiempo
 * short y full: se debe usar para mostrar fechas en la interfaz o en los documentos
 * iso y isoShort: se debe usar para guardar en la base de datos
 * */

export type IntervalTime = 'dia' | 'mes' | 'año' | 'semana' | 'day' | 'month' | 'year' | 'week';
export type FormatTime = 'short' | 'full' | 'iso' | 'isoShort' | 'isoFirtsHourDate' | 'isoLastHourDate';

export type WorldTime =
  | 'abbreviation'
  | 'clientIp'
  | 'datetime'
  | 'dayOfWeek'
  | 'dayOfYear'
  | 'dst'
  | 'dstFrom'
  | 'dstOffset'
  | 'dstUntil'
  | 'rawOffset'
  | 'timezone'
  | 'unixtime'
  | 'utcDatetime'
  | 'utcOffset'
  | 'weekNumber';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {
  }

  static async getTime(property: WorldTime = null) {
    const url = `${baseApiPCv2}/analitica/v1/hora`;
    const options = {url, method: 'GET', crossDomain: true};

    const {response}: any = await firstValueFrom(ajax(options));
    const worldTime: any = camelcaseKeys(response?.data);

    if (!property) return worldTime;
    return worldTime[property];
  }

  static async datetimeCL(format: FormatTime, date: Date = null) {
    if (!date) {
      const datetimes = await DateService.getTime('datetime');
      date = new Date(datetimes);
    }

    const locales = 'es-CL';
    const timeZone = {timeZone: 'America/Santiago'};

    const localDatetime = date.toLocaleString(locales, timeZone);

    const datetime = {
      full: localDatetime,
      short: localDatetime.split(', ')[0],
      iso: this.toISOLocal(date),
      isoShort: this.toISOLocal(date).split('T')[0],
      isoFirstHourDate: this.toISOWithFullHourStartOrEndDay(date, 'start'),
      isoLastHourDate: this.toISOWithFullHourStartOrEndDay(date, 'end'),
    };

    return datetime[format];
  }

  static datetimeCLResolutions(format: FormatTime, date: Date = new Date()) {
    const locales = 'es-CL';
    const timeZone = {timeZone: 'America/Santiago'};

    const localDatetime = date.toLocaleString(locales, timeZone);

    const datetime = {
      full: localDatetime,
      short: localDatetime.split(', ')[0],
      iso: this.toISOLocal(date),
      isoShort: this.toISOLocal(date).split('T')[0],
      isoFirtsHourDate: this.toISOWithFullHourStartOrEndDay(date, 'start'),
      isoLastHourDate: this.toISOWithFullHourStartOrEndDay(date, 'end'),
    };

    return datetime[format];
  }

  static yearsOld(birth) {
    const birthDate: Date = new Date(birth);
    const duration = intervalToDuration({start: birthDate, end: new Date()});

    return formatDuration(duration, {format: ['years', 'months', 'days'], locale: esLocale});
  }

  static addTime(format: FormatTime, interval: IntervalTime, rangetime: number, date: Date = new Date()) {
    const intervalstr = interval.toLocaleLowerCase();
    const params: any = {};
    if (intervalstr === 'dia' || intervalstr === 'day') params.days = rangetime;
    if (intervalstr === 'mes' || intervalstr === 'month') params.months = rangetime;
    if (intervalstr === 'año' || intervalstr === 'year') params.years = rangetime;
    if (intervalstr === 'semana' || intervalstr === 'week') params.weeks = rangetime;

    const newdate = add(date, params);
    return this.datetimeCL(format, newdate);
  }

  private static toISOLocal(date: Date) {
    const timezone = (n) => ('0' + n).slice(-2);
    let off = date.getTimezoneOffset();
    const sign = off < 0 ? '+' : '-';
    off = Math.abs(off);

    return (
      new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, -1) +
      sign +
      timezone(off / 60 || 0) +
      ':' +
      timezone(off % 60)
    );
  }

  private static toISOWithFullHourStartOrEndDay(date: Date, period: 'start' | 'end' = 'start') {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDay();
    let newDate = new Date();

    if (period === 'end') newDate = new Date(year, month, day, 23, 59, 59, 999);
    if (period === 'start') newDate = new Date(year, month, day, 0, 0, 0, 0);

    return this.toISOLocal(newDate);
  }
}
