import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MIME_TYPES } from '@shared/constants/mime-types.const';
import { PageTableDef } from '@shared/interfaces/table.interface';
import { ReportService } from '../services/report.service';

@Component({
  selector: 'app-modal-views-images',
  templateUrl: './modal-views-images.component.html',
  styleUrls: ['./modal-views-images.component.scss'],
})
export class ModalViewsImagesComponent implements OnInit {
  @Input() pageTable: PageTableDef;
  @Input() data;

  isLoading = true;
  attachList: any[] = [];
  encodeList: any[] = [];
  docsList: any[] = [];

  constructor(private reportService: ReportService, private modalController: ModalController) {}

  ngOnInit() {
    //  this.listFiles = att.files;
    this.data.attachments.map((att) => {
      this.attachList.push({
        bucket: att.bucket,
        path: att.path,
        key: att.key,
        ext: att.ext,
      });
    });

    this.attachList
      .filter((att) => att.ext === 'jpg' || att.ext === 'png' || att.ext === 'jpeg')
      .map((att) => {
        this.reportService.getPdf(att.bucket, att.path, att.key).subscribe((res) => {
          const encode = `data:${MIME_TYPES[att.ext]};base64,${res}`;
          this.encodeList.push({ name: encode, id: `INF${Date.now()}` });

          this.isLoading = false;
        });
      });

    this.attachList
      .filter((att) => att.ext === 'doc' || att.ext === 'docx')
      .map((att) => {
        this.reportService.getPdf(att.bucket, att.path, att.key).subscribe((res) => {
          const encode = `data:${MIME_TYPES[att.ext]};base64,${res}`;
          this.docsList.push({ name: encode, id: `INF${Date.now()}` });

          this.isLoading = false;
        });
      });
  }
  dismiss() {
    this.modalController.dismiss();
  }
}
