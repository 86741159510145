import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ResetComponent } from './pages/reset/reset.component';
import { ChangeComponent } from './pages/change/change.component';
import { ROOT_ROUTES }     from '../app-routing.module';


export const AUTH_ROUTES =  {
  auth : ROOT_ROUTES.auth,
  login: 'login',
  resetPassword: 'restablecer',
  requestChangePassword: 'cambiar',
  confirmChangePasswordWithGsi: 'cambiar/:gsiOne',
  confirmChangePasswordWithGsiAndCode: 'cambiar/:gsiOne/:code'
};

const routes: Routes = [
  {
    path:'',
    redirectTo: '/' + AUTH_ROUTES.auth + '/' +  AUTH_ROUTES.login,
    pathMatch: 'full'
  },

  {
    path: AUTH_ROUTES.login,
    component: LoginComponent,
  },

  {
    path: AUTH_ROUTES.resetPassword,
    component: ResetComponent,
  },

  {
    path: AUTH_ROUTES.requestChangePassword,
    component: ChangeComponent,
  },

  {
    path: AUTH_ROUTES.confirmChangePasswordWithGsiAndCode,
    component: ChangeComponent,
  },

  {
    path: AUTH_ROUTES.confirmChangePasswordWithGsi,
    component: ChangeComponent,
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
