import { Injectable } from '@angular/core';
import { ObservableInput, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  constructor() {}

  catchError(err): ObservableInput<any> {
    return of({ status: 'error', request: err });
  }

  status(status: any, request: any) {
    return { status, request };
  }
}
