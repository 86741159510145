import { ModalController } from '@ionic/angular';
import { FormControl } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { Favorite } from '@shared/interfaces/favorite.interface';
import { PUT_FAVORITE } from '@store/actions';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';

@Component({
  selector: 'app-favorites-list',
  templateUrl: './add-favorites.component.html',
  styleUrls: ['./add-favorites.component.scss'],
})
export class AddfavoritesComponent implements OnInit {
  @Input() favorite: Favorite;
  @Input() content: any;
  name: FormControl = new FormControl();
  favorites: any;

  constructor(
    private modalController: ModalController,
    private store: Store<AppState>) {
  }

  ngOnInit() {
    const favName = this.favorite.name;
    if (favName) this.name.patchValue( favName );
  }

  dismiss() {
    this.modalController.dismiss();
  }

  createList() {
    const valuesToSave = {
      ...this.favorite,
      name: this.name.value,
      content: this.content
    };
    this.store.dispatch(PUT_FAVORITE({valuesToSave}));
    this.modalController.dismiss(valuesToSave.name);
  }
}
