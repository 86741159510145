import { Paperwork } from './../../../shared/interfaces/paperwork.interface';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import { Form } from '@clinical/forms/interfaces/form.interface';
import { PCV2Request } from '@shared/interfaces/request.interface';
import { API_URIS } from '@shared/constants/api-uris.const';

const BASE_API_PCV2: string = environment.baseApiPCv2;
const URI_CLINICAL_FORMS: string = API_URIS.clinical.forms;

@Injectable({
  providedIn: 'root',
})
export class ClinicalFormService {
  constructor(private httpClient: HttpClient) {}

  save(body: Paperwork): Observable<Paperwork> {
    return this.httpClient
      .post<PCV2Request>(`${BASE_API_PCV2}/${URI_CLINICAL_FORMS}`, body)
      .pipe(map(({ data }: { data: Paperwork }) => data));
  }
}
