import {Injectable} from '@angular/core';
import {AlertController} from '@ionic/angular';

import {NOTIFICATION_OPTIONS} from '@shared/constants/notifications.const';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private alertController: AlertController) {
  }

  /**
   * Presenta una alerta en la parte superior del contenido de la aplicación y el usuario debe descartarla manualmente
   * antes de que pueda reanudar la interacción con la aplicación.
   *
   * @param type: Tipo de alerta: info, danger, success, warning, reminder
   * @param message: Texto que se muestra en el cuerpo de la alerta
   * @param buttons?: Este parámetro es opcional, y puede recibir un arreglo de tipo alert.buttons
   * @returns
   */
  async presentAlert(type: string, message: string, buttons = undefined): Promise<any> {
    const {header, button, cssClass} = NOTIFICATION_OPTIONS.find((option) => option.type === type);


    const alert = await this.alertController.create({
      cssClass,
      header,
      backdropDismiss: false,
      message,
      buttons        : buttons || button,
    });

    await alert.present();
    return await alert.onDidDismiss();
  }

  async dismissAlert(): Promise<any> {
    return await this.alertController.dismiss();
  }
}
