import {Injectable} from '@angular/core';

import {of}                        from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  GET_EMERGENCIES_BY_PATIENT, GET_EMERGENCIES_BY_PATIENT_ERR, GET_EMERGENCIES_BY_PATIENT_OK,
  GET_EMERGENCIES_BY_PROFESSIONAL, GET_EMERGENCIES_BY_PROFESSIONAL_ERR, GET_EMERGENCIES_BY_PROFESSIONAL_OK,
  PUT_EMERGENCY, PUT_EMERGENCY_ERR, PUT_EMERGENCY_OK
}                                      from '@store/actions';
import {ClinicalEmergenciesService}    from '@clinical/emergencies/services/clinical-emergencies.service';

@Injectable()
export class EmergenciesEffects {
  putUrgency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PUT_EMERGENCY),
      mergeMap(({valuesToSave}) =>
        this.clinicalUrgencyService.saveUrgency({...valuesToSave}).pipe(
          map((urgency) => PUT_EMERGENCY_OK({emergency: urgency})),
          catchError((err) => of(PUT_EMERGENCY_ERR({payload: err}))),
        ),
      ),
    ),
  );
  loadUrgenciesByProfessional$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_EMERGENCIES_BY_PROFESSIONAL),
      mergeMap(({gsiMultiCondition1St}) =>
        this.clinicalUrgencyService.loadByProfessional(gsiMultiCondition1St).pipe(
          map((urgencies) => GET_EMERGENCIES_BY_PROFESSIONAL_OK({emergencies: urgencies})),
          catchError((err) => of(GET_EMERGENCIES_BY_PROFESSIONAL_ERR({payload: err}))),
        ),
      ),
    ),
  );
  loadUrgenciesByPacient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_EMERGENCIES_BY_PATIENT),
      mergeMap(({patientId}) =>
        this.clinicalUrgencyService.loadByPatient(patientId).pipe(
          map((urgencies) => GET_EMERGENCIES_BY_PATIENT_OK({emergencies: urgencies})),
          catchError((err) => of(GET_EMERGENCIES_BY_PATIENT_ERR({payload: err}))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private clinicalUrgencyService: ClinicalEmergenciesService) {}
}
