import {Pipe, PipeTransform} from '@angular/core';
import {differenceInDays} from "date-fns";
import {DateService} from "@shared/services/date.service";

const shortCurrentDatetime = DateService.datetimeCLResolutions('short');

@Pipe({
  name: 'dateToColor'
})
export class ColorDatePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    const [dd, mm, yyyy] = value.split('-');
    const [dd2, mm2, yyyy2] = shortCurrentDatetime.split('-');
    const dateFrom = new Date(`${mm}-${dd}-${yyyy}`);
    const dateTo = new Date(`${mm2}-${dd2}-${yyyy2}`);

    const result = differenceInDays(
      dateFrom,
      dateTo
    );
    if (result === 0 || result < 0) return 'rojo';
    if (result === 1) return 'amarillo';
    if (result === 2) return 'azul';
    if (result === 3 || result > 3) return 'morado';

    return null;
  }

}
