import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { MaterialModule } from 'src/app/material.module';

import { ReportModalComponent } from './report-modal/report-modal.component';
import { ReportTableComponent } from './report-table/report-table.component';
import { PatientReportComponent } from './patient-report/patient-report.component';
import { VisualizeDocGoogleDocsComponent } from './visualize-doc-google-docs/visualize-doc-google-docs.component';
import { TableComponent } from './components/table/table.component';
import { ViewTableComponent } from './pages/view-table/view-table.component';
import { paginatorToSpanish } from './services/paginator-translator.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { ModalResolutionsComponent } from './modal-resolutions/modal-resolutions.component';
import { ModalReasonRejectComponent } from './modal-reason-reject/modal-reason-reject.component';
import { ModalViewsImagesComponent } from './modal-views-images/modal-views-images.component';
import { NgPipesModule } from 'ngx-pipes';
import { PipesModule } from '@shared/pipes/pipes.module';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportModalMultyComponent } from '@components/records/report-modal-multy/report-modal-multy.component';

const docketsComponents = [
    TableComponent,
    ViewTableComponent,
    ReportModalComponent,
    ReportTableComponent,
    PatientReportComponent,
    VisualizeDocGoogleDocsComponent,
    ModalResolutionsComponent,
    ModalReasonRejectComponent,
    ModalViewsImagesComponent,
    ReportModalMultyComponent
];

@NgModule({
    exports     : [...docketsComponents],
    declarations: [...docketsComponents],
    imports     : [SharedModule, MaterialModule, NgxDocViewerModule, PipesModule, NgPipesModule, IonicModule,
                   CommonModule, FormsModule,
                   ReactiveFormsModule],
    providers   : [{provide: MatPaginatorIntl, useValue: paginatorToSpanish()}],
})
export class RecordsModule {
}
