import { SpecialtyDerivation } from '@shared/interfaces/derivation.interface';

export const REHABILITATION_SPECIALTIES = ['kinesiología', 'terapia ocupacional', 'fonoaudiología'];

export const SPECIALTY: SpecialtyDerivation[] = [
  {
    group   : 'Kinesiología',
    category: [
      {
        name       : 'KINESIOTERAPIA MOTRIZ',
        indications: [
          {
            id           : '1',
            name         : 'EVALUACIÓN Y KINESIOLOGÍA INTEGRAL AMBULATORIA',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
        ],
      },
      {
        name       : 'KINESIOTERAPIA RESPIRATORIA',
        indications: [
          {
            id           : '25',
            name         : 'KINESIOTERAPIA RESPIRATORIA',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
        ],
      },
    ],
  },

  {
    group   : 'Fonoaudiología',
    category: [
      {
        name       : 'DIAGNÓSTICO',
        indications: [
          {
            id           : '26',
            name         : 'DIAGNÓSTICO FONAUDIOLÓGICO',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
        ],
      },
      {
        name       : 'EVALUACIÓN',
        indications: [
          {
            id           : '32',
            name         : 'EVALUACIÓN DE LA VOZ',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
          {
            id           : '33',
            name         : 'EVALUACIÓN DE DEGLUCIÓN',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
          {
            id           : '34',
            name         : 'EVALUACIÓN DEL LENGUAJE',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
          {
            id           : '35',
            name         : 'EVALUACIÓN DEL HABLA',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
        ],
      },
      {
        name       : 'REHABILITACIÓN',
        indications: [
          {
            id           : '28',
            name         : 'REHABILITACIÓN DE DEGLUCIÓN',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
          {
            id           : '29',
            name         : 'REHABILITACIÓN DE LA VOZ',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
          {
            id           : '30',
            name         : 'REHABILITACIÓN DEL LENGUAJE',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
          {
            id           : '31',
            name         : 'REHABILITACIÓN DEL HABLA',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
        ],
      },
    ],
  },
  {
    group   : 'Terapia Ocupacional',
    category: [
      {
        name       : 'EVALUACIÓN',
        indications: [
          {
            id           : '39',
            name         : 'EVALUACIÓN TO',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
        ],
      },
      {
        name       : 'HABILITACIÓN INFANTIL',
        indications: [
          {
            id           : '47',
            name         : 'HABILITACIÓN INFANTIL',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
        ],
      },
      {
        name       : 'CONFECCIÓN DE ÓRTESIS',
        indications: [
          {
            id           : '48',
            name         : 'CONFECCIÓN DE ÓRTESIS',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
        ],
      },
      {
        name       : 'REHABILITACIÓN',
        indications: [
          {
            id           : '40',
            name         : 'ESTIMULACIÓN COGNITIVA',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
          {
            id           : '41',
            name         : 'ENTRENAMIENTO DE AVD',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
          {
            id           : '42',
            name         : 'ENTRENAMIENTO DE EESS',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
          {
            id           : '43',
            name         : 'EVALUACIÓN DOMICILIARIA',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
          {
            id           : '44',
            name         : 'ENTRENAMIENTO DEL CUIDADOR',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
          {
            id           : '45',
            name         : 'ERGOTERAPIA',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
          {
            id           : '46',
            name         : 'INTEGRACIÓN SENSORIAL',
            observations : '',
            numberMeeting: '',
            isChecked    : false,
          },
        ],
      },
    ],
  },
];
