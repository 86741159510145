import { Action, createReducer, on } from '@ngrx/store';
import { Derivation } from '@shared/interfaces/derivation.interface';
import { Paperwork } from '@shared/interfaces/paperwork.interface';
import {
  LOAD_DERIVATIONS_BY_PATIENT,
  LOAD_DERIVATIONS_BY_PATIENT_ERR,
  LOAD_DERIVATIONS_BY_PATIENT_OK,
  LOAD_DERIVATIONS_BY_PROFESSIONAL,
  LOAD_DERIVATIONS_BY_PROFESSIONAL_ERR,
  LOAD_DERIVATIONS_BY_PROFESSIONAL_OK,
  PUT_DERIVATION,
  PUT_DERIVATION_ERR,
  PUT_DERIVATION_OK,
} from '@store/actions';

import {
  CLEAR_DERIVATION,
  DELETE_DERIVATION,
  DELETE_DERIVATION_ERR,
  DELETE_DERIVATION_OK,
  PUT_DERIVATION_END,
  SELECTED_DERIVATION,
  UNSELECT_DERIVATION,
} from './clinical-derivation.actions';

export interface DerivationState {
  error: any;
  status: string;
  isLoaded: boolean;
  isLoading: boolean;
  derivations: Paperwork<Derivation>[];
  historical: Paperwork<Derivation>[];
  selected: Paperwork<Derivation>;
  lastDerivation: Paperwork<Derivation>;
  current: Paperwork;
  isSaving: boolean;
}

export const derivationState: DerivationState = {
  error: null,
  status: '',
  isLoaded: false,
  isLoading: false,
  derivations: [],
  historical: [],
  selected: null,
  lastDerivation: null,
  current: null,
  isSaving: false,
};

const createReducerDerivation = createReducer(
  derivationState,

  // Obtener las recetas de un paciente
  on(LOAD_DERIVATIONS_BY_PATIENT, (state) => ({ ...state, isLoading: true })),
  on(LOAD_DERIVATIONS_BY_PATIENT_OK, (state, { derivations: derivations }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    derivations: [...derivations],
  })),
  on(LOAD_DERIVATIONS_BY_PATIENT_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  // Obtener las recetas de un profesional
  on(LOAD_DERIVATIONS_BY_PROFESSIONAL, (state) => ({ ...state, isLoading: true })),
  on(LOAD_DERIVATIONS_BY_PROFESSIONAL_OK, (state, { derivations: derivations }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    historical: [...derivations],
  })),
  on(LOAD_DERIVATIONS_BY_PROFESSIONAL_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(PUT_DERIVATION, (state) => ({ ...state, isLoading: true, status: 'Guardando el registro.' })),
  on(PUT_DERIVATION_OK, (state, { derivation }) => {
    let derivations = state.derivations.map((ans) => {
      if (ans.id === derivation.id) return derivation;
      else return ans;
    });
    let history = state.historical.map((ans) => {
      if (ans.id === derivation.id) return derivation;
      else return ans;
    });

    const index = derivations.findIndex((draft) => draft.id === derivation.id);
    if (index === -1) derivations = [derivation, ...derivations];
    const indexHistory = history.findIndex((draft) => draft.id === derivation.id);
    if (indexHistory === -1) history = [derivation, ...history];

    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      isSaving: true,
      status: 'Registro guardado con éxito',
      derivations: [...derivations],
      historical: [...history],
      lastDerivation: { ...derivation },
      current: { ...derivation },
    };
  }),
  on(PUT_DERIVATION_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    current: null,
    isSaving: false,
    status: 'Ocurrio un error al intentar guardar el registro',
    error: { ...payload },
  })),

  on(SELECTED_DERIVATION, (state, { selected, action }) => ({ ...state, selected: { ...selected }, status: action })),
  on(UNSELECT_DERIVATION, (state) => ({
    ...state,
    isLoaded: false,
    isLoading: false,
    status: '',
    selected: null,
  })),

  on(PUT_DERIVATION_END, (state) => ({ ...state, isSaving: false })),
  on(DELETE_DERIVATION, (state) => ({ ...state, isLoading: true })),
  on(DELETE_DERIVATION_OK, (state, { derivation }) => {
    let derivations = [...state.derivations];
    let history = [...state.historical];
    const index = derivations.findIndex((draft) => draft.id === derivation.id);
    const indexHistory = history.findIndex((draft) => draft.id === derivation.id);
    if (index !== -1) derivations = [...derivations.filter((draft) => draft.id !== derivation.id)];
    if (indexHistory !== -1) history = [...history.filter((draft) => draft.id !== derivation.id)];

    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      status: 'Interconsulta eliminada con éxito',
      derivations: [...derivations],
      historical: [...history],
    };
  }),
  on(DELETE_DERIVATION_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    status: 'Ocurrio un error al intentar eliminar la interconsulta',
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(CLEAR_DERIVATION, (state) => ({
    derivations: [...state.derivations],
    historical: [...state.historical],
    isLoading: false,
    isLoaded: false,
    error: null,
    status: '',
    selected: null,
    lastDerivation: null,
    current: null,
    isSaving: false,
  })),
);

export const derivationReducer = (state: DerivationState, action: Action) => createReducerDerivation(state, action);
