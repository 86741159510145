import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PUT_EXPERTISE, PUT_EXPERTISE_OK, PUT_EXPERTISE_ERR } from '@store/actions';
import { DELETE_EXPERTISE, DELETE_EXPERTISE_OK, DELETE_EXPERTISE_ERR } from '@store/actions';
import { LOAD_EXPERTISES, LOAD_EXPERTISES_OK, LOAD_EXPERTISES_ERR } from '@store/actions';
import { ClinicalExpertiseService } from '@clinical/expertise/services/clinical-expertise.service';
import { ReportService } from '@components/records/services/report.service';
import {
  LOAD_EXPERTISES_BY_PROFESSIONAL,
  LOAD_EXPERTISES_BY_PROFESSIONAL_OK,
  LOAD_EXPERTISES_BY_PROFESSIONAL_ERR,
} from '@store/actions';

@Injectable()
export class ExpertiseEffects {
  putExpertise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PUT_EXPERTISE),
      mergeMap(({ valuesToSave }) =>
        this.clinicalExpertiseService.save({ ...valuesToSave }).pipe(
          map((expertise) => PUT_EXPERTISE_OK({ expertise })),
          catchError((err) => of(PUT_EXPERTISE_ERR({ payload: err }))),
        ),
      ),
    ),
  );
  loadExpertise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_EXPERTISES),
      mergeMap(({ gsiMultiCondition1St }) =>
        this.clinicalExpertiseService.getListExpertise(gsiMultiCondition1St).pipe(
          map((expertiseItem) => LOAD_EXPERTISES_OK({ expertises: expertiseItem })),
          catchError((err) => of(LOAD_EXPERTISES_ERR({ payload: err }))),
        ),
      ),
    ),
  );
  loadExpertiseByProfessional$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_EXPERTISES_BY_PROFESSIONAL),
      mergeMap(({ gsiMultiCondition1St }) =>
        this.reportService.findByGsi(gsiMultiCondition1St).pipe(
          map((expertise) => LOAD_EXPERTISES_BY_PROFESSIONAL_OK({ expertises: expertise })),
          catchError((err) => of(LOAD_EXPERTISES_BY_PROFESSIONAL_ERR({ payload: err }))),
        ),
      ),
    ),
  );
  deleteExpertise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DELETE_EXPERTISE),
      mergeMap((expertise) =>
        this.reportService.deleteByIds('peritajes', expertise).pipe(
          map((expertiseItem) => DELETE_EXPERTISE_OK({ expertise: expertiseItem })),
          catchError((err) => of(DELETE_EXPERTISE_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private clinicalExpertiseService: ClinicalExpertiseService,
    private reportService: ReportService,
  ) {}
}
