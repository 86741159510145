/* eslint-disable max-len */
import { format } from 'date-fns';

export const buildContentPdfAnesthesiaConsent = (paperwork, group) => {
  const { patient, professional } = paperwork;
  const { rows } = group;
  const EMPTY_LINE = '\n\n';
  const SEDACION = [
    { text: `SEDACIÓN`, margin: [0, 10, 0, 0] },
    { text: `OBJETIVO Y CARACTERÍSTICAS DEL PROCEDIMIENTO DE SEDACIÓN`, margin: [0, 10, 0, 0] },
    {
      text: [
        { text: `Sedación:`, bold: true },
        {
          text: ` consiste en la administración de medicamentos por vía venosa. `
            + `El propósito es que el paciente pueda colaborar en la intervención que se le realiza, `
            + `con las menores molestias posibles.`,
        },
      ],
    },

    { text: `POTENCIALES RIESGOS DE LA SEDACIÓN`, margin: [0, 10, 0, 0] },
    {
      text: [
        { text: `Reacciones alérgicas a medicamentos, dolor, "moretones" o irritación de la vena, náuseas y/o vómitos, posibilidad que su respiración sea ` },
        { text: `muy lenta o se detenga (puede ser necesario colocar un tubo en la tráquea para ayudarle a respirar), paso de contenido gástrico a los ` },
        { text: `pulmones ("aspiración"), accidentes vasculares, infarto del miocardio, daño cerebral y muerte. ` },
      ],
    },

    {
      text: `NOTA posterior a procedimientos ambulatorios con sedación, no debe manejar automóviles o similares, `
        + `operar máquinas o aparatos complicados ni tomar decisiones importantes durante las siguientes 24 hrs.`,
      bold: true,
      margin: [0, 10, 0, 0],
    },
  ];
  const GENERAL = [
    { text: `ANESTESIA GENERAL Y/O LOCORREGIONAL`, margin: [0, 10, 0, 0] },
    { text: `OBJETIVOS Y CARACTERÍSTICAS DE LAS TÉCNICAS DE ANESTESIA `, margin: [0, 10, 0, 0] },
    {
      text: 'La anestesia se administra para evitar el dolor durante la intervención, '
        + 'conseguir inconsciencia y olvido del suceso, inmovilidad y relajación muscular; '
        + 'y manejo del dolor postoperatorio inmediato, elementos que facilitan el desarrollo de la cirugía/ procedimiento ',
      margin: [0, 1, 0, 0],
    },

    {
      text: [{ text: `Anestesia General:`, bold: true },
        { text: `actúa a nivel del cerebro, provocando un estado reversible de pérdida de la consciencia. Este estado se consigue, y ` },
        { text: 'se mantiene, por la administración continua de medicamentos por vía inhalatoria y/o intravenosa, y se recupera con el cese de éstos. ' },
        { text: ' Durante la anestesia general, al estar dormido y relajado, es requisito apoyar la respiración con un tubo a través de la boca o la nariz, que ' },
        { text: 'llega a la faringe o a la tráquea. Este dispositivo debe conectarse a un aparato de respiración artificial. ' },
      ],
    },

    {
      text: [
        { text: `Anestesia Locoregional:`, bold: true },
        { text: `consiste en la inyección, con agujas especiales y diferentes técnicas, de medicamentos llamados anestésicos ` },
        { text: 'locales, en la proximidad de un nervio o de la columna vertebral. Esto permite reducir el movimiento y la sensibilidad de la región donde ' },
        { text: 'se realizará la intervención quirúrgica. En algunos casos, se instala un catéter perineural (cerca de un nervio) por el cual se administran ' },
        { text: 'anestésicos en forma continua (bomba infusión desechable) manteniendo analgesia por uno o más días. ' },
      ],
    },

    { text: `POTENCIALES RIESGOS DE LA ANESTESIA GENERAL O LOCORREGIONAL`, margin: [0, 10, 0, 0] },
    {
      text: [
        { text: 'La anestesia es capaz de originar lesiones agudas, secuelas crónicas, complicaciones anestésicas graves e incluso la muerte. ' },
        { text: 'La frecuencia de complicaciones graves es muy baja, alrededor de 1 por cada 10.000 pacientes anestesiados. ' },
        { text: 'El riesgo tiene relación con el estado de salud previo, edad del paciente, como del tipo, complejidad y duración de la intervención quirúrgica. ' },
        { text: 'Algunos de los riesgos más frecuentes o importantes, propios de cada técnica son: ' },
      ],
    },

    {
      text: [{ text: `Anestesia General:`, bold: true },
        { text: `dolor de garganta, ronquera, daño dental o vías respiratorias, neumonía u otros problemas pulmonares, daño a los ` },
        { text: 'nervios, daño a las arterias o venas, reacción adversa a los medicamentos, estar consciente bajo anestesia, daños cerebrales y muerte. ' },
      ],
    },

    {
      text: [{ text: `Anestesia Locorregional:`, bold: true },
        { text: ` dolores o incomodidad durante la punción, moretones en el sitio de inyección, daño a los nervios que afectan  ` },
        { text: 'los brazos o de las piernas, entumecimiento o debilidad prolongada, dolor de cabeza, infección, reacción adversa a los medicamentos,  ' },
        { text: 'complicaciones pulmonares y necesidad de utilizar anestesia general. En los casos en que se instale un catéter perineural y se utilice una   ' },
        { text: 'bomba de infusión desechable, se agregan los siguientes potenciales riesgos: retiro accidental, analgesia inadecuada, riesgo de caídas  ' },
        { text: 'sangrado del sitio de punción, infección, daño neurológico, intoxicación por anestésicos locales y muerte.  ' },
      ],
    },
  ];

  return rows.map((itemForm) => [
    {
      fontSize: 10,
      margin: [0, 1, 0, 0],
      stack: [

        { text: `Todas las técnicas anestésicas son realizadas por un médico anestesiólogo, quien controla todo el proceso y trata las posibles complicaciones que pudieran surgir. `, margin: [0, 10, 0, 0] },

        { text: `DESCRIPCIÓN DEL PROCEDIMIENTO`, margin: [0, 10, 0, 0] },
        { text: `Entiendo que para la adecuada realización en mi persona o en mi representado de un(a):`, margin: [0, 3, 0, 0] },
        { text: `${itemForm.description}`, margin: [0, 1, 0, 0] },

        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }], margin: [0, 0, 0, 0] },

        { text: `debo consentir en la administración de:`, margin: [0, 10, 0, 0] },

        {
          style: 'tableExample',
          table: {
            headerRows: 0,
            body: [
              [
                [
                  {
                    marginTop: 0,
                    marginLeft: 10,
                    canvas: [{ type: 'rect', x: 1, y: 1, w: 10, h: 10 }],
                  },
                ],
                { marginTop: 0, marginLeft: -22, bold: true, text: `x ` },
                ` ${itemForm.allowed}`,
                `${itemForm.allowed === 'Anestesia Locorregional'
                  ? `Anestesia regional continua domiciliaria: ${itemForm.continuous}`
                  : ''
                }`,
              ],
            ],
          },
          layout: 'headerLineOnly',
        },

        itemForm.allowed === 'Sedacion' ? SEDACION : GENERAL,

        { text: `ELECCIÓN DE LA TÉCNICA ANESTÉSICA `, margin: [0, 10, 0, 0] },
        { text: `Se me han explicado las diferentes opciones de sedación/anestesia que pudieran ser aconsejables tomando en cuenta mi condición de  salud y el procedimiento al que seré sometido(a). Yo estoy de acuerdo con el plan de anestesia propuesto, que consiste en: `, margin: [0, 1, 0, 0] },
        { text: `${itemForm?.anesthesia?.technique} `, margin: [0, 1, 0, 0] },

        { text: `Otros riesgos específicos de la opción anestésica señalada son:  ${itemForm?.anesthesia?.risks}`, margin: [0, 5, 0, 0] },
        {
          margin: [0, 10, 0, 0],
          ul: [
            ' Tuve la oportunidad de hacer preguntas y de hablar con el médico acerca del plan de anestesia y me encuentro satisfecho(a) con las respuestas y la información que he recibido',
            ' Se me ha explicado que el anestesista a cargo del procedimiento puede ir cambiando de acuerdo a circunstancias de buen servicio, como intervenciones prolongadas o en cambios de turno. ',
            ' Entiendo que puedo retirar mi consentimiento en cualquier momento antes de la administración de la sedación/anestesia, lo que implicaría retirar también el consentimiento de la intervención/procedimiento por el cual se indicó. ',
          ],
        },

        { text: `Por todo lo anterior:  `, bold: true, margin: [0, 5, 0, 0] },

        { marginTop: 10, bold: true, text: `Consiento la realización del procedimiento ` },
        {
          alignment: 'justify',
          fontSize: 10,
          style: 'center',

          stack: [
            {
              marginTop: -12,
              marginLeft: 430,
              canvas: [{ type: 'rect', x: 1, y: 1, w: 10, h: 10 }],
            },
          ],
        },
        { marginTop: -10, marginLeft: 220, bold: true, text: `No Consiento la realización del procedimiento ` },
        {
          alignment: 'justify',
          style: 'center',

          stack: [
            {
              marginTop: -12,
              marginLeft: 200,
              canvas: [{ type: 'rect', x: 1, y: 1, w: 10, h: 10 }],
            },
          ],
        },

        {
          columns: [
            { text: `${patient.names} ${patient.surnames}`, width: '*' },
            { text: `${professional.rut}`, width: 80 },
            { text: ``, width: 80 },
            { text: `${format(new Date(), 'dd/MM/yyyy')}`, width: 80, alignment: 'center' },
          ],
          margin: [0, 5, 0, 0],
          bold: true,
          fontSize: 12,
        },
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }], margin: [0, 0, 0, 0] },
        {
          columns: [
            { text: `Nombre Paciente`, width: '*' },
            { text: `RUN`, width: 80 },
            { text: `Firma`, width: 80 },
            { text: `Fecha `, width: 80, alignment: 'center' },
          ],
        },

        {
          columns: [
            { text: `Dr.(a) ${professional.names} ${professional.surnames}`, width: '*' },
            { text: `${format(new Date(), 'dd/MM/yyyy')}`, width: 80, alignment: 'center' },
            { text: `${professional.rut}`, width: 80 },
          ],
          margin: [0, 5, 0, 0],
          bold: true,
          fontSize: 12,
        },
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }], margin: [0, 0, 0, 0] },
        {
          columns: [
            { text: `Médico Anestesista Informante `, width: '*' },
            { text: `Fecha`, width: 80, alignment: 'center' },
            { text: `R.U.N.`, width: 80, alignment: 'center' },
          ],
        },


        { text: '', margin: [0, 0, 0, 20] },
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },

        { marginTop: 10, text: `Teniendo yo menos de 18 años, asiento en que se me realice el procedimiento: ` },
        {
          alignment: 'justify',
          fontSize: 10,
          style: 'center',

          stack: [
            {
              marginTop: -12,
              marginLeft: 390,
              canvas: [{ type: 'rect', x: 1, y: 1, w: 10, h: 10 }],
            },
          ],
        },
        { marginTop: -10, marginLeft: 370, bold: true, text: `Si ` },
        {
          alignment: 'justify',
          style: 'center',

          stack: [
            {
              marginTop: -12,
              marginLeft: 355,
              canvas: [{ type: 'rect', x: 1, y: 1, w: 10, h: 10 }],
            },
          ],
        },
        {
          marginTop: -10,
          marginLeft: 405,
          bold: true,
          alignment: 'justify',

          text: `No`,
        },
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },
        { text: '', margin: [0, 10, 0, 0] },

        { marginTop: 10, bold: true, text: `En representación del paciente: Consiento la realización del procedimiento ` },


        {
          alignment: 'justify',
          style: 'center',

          stack: [
            {
              marginTop: -12,
              marginLeft: 340,
              canvas: [{ type: 'rect', x: 1, y: 1, w: 10, h: 10 }],
            },
          ],
        },


        { marginTop: 10, bold: true, text: `No Consiento la realización del procedimiento ` },


        {
          alignment: 'justify',
          style: 'center',

          stack: [
            {
              marginTop: -12,
              marginLeft: 340,
              canvas: [{ type: 'rect', x: 1, y: 1, w: 10, h: 10 }],
            },
          ],
        },

        EMPTY_LINE,

        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }], margin: [0, 25, 0, 0] },
        {
          columns: [
            { text: `Nombre Representante`, width: '*' },
            { text: `RUN`, width: 80 },
            { text: `Firma`, width: 80 },
            { text: `Fecha `, width: 80, alignment: 'center' },
          ],
        },


        {
          text: `Causa de la representación: ___________________________________________________________________________________`,
          margin: [0, 10, 0, 10],
        },

        {
          text: `REVOCACIÓN: En forma voluntaria, he decidido revocar el consentimiento arriba especificado`,
          bold: true,
          margin: [0, 10, 0, 0],
        },

        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }], margin: [0, 10, 0, 0] },
        {
          columns: [
            { text: `Nombre PACIENTE o REPRESENTANTE`, width: '*' },
            { text: `R.U.N.`, width: 80 },
            { text: `Firma`, width: 80 },
            { text: `Fecha`, width: 80, alignment: 'center' },
          ],
        },
      ],
    },
  ]);
};
