import {NgModule} from '@angular/core';
import {SpecialtyPipe} from '@shared/pipes/specialty.pipe';
import {QuotePipe} from './appointment.pipe';

import {BirthPipe} from './birth.pipe';
import {DataUrlToBase64Pipe} from './dataUrlToBase64.pipe';
import {FirstWordPipe} from './first-word.pipe';
import {KeyValuePipe} from './key-value.pipe';
import {MinValuePipe} from './minValue.pipe';
import {NpiPipe} from './npi.pipe';
import {SanitizerPipe} from './sanitizer.pipe';
import { SuffixToTextPipe } from './suffix-to-text.pipe';
import { ColorDatePipe } from './color-date.pipe';
import {AppointmentAgendaPipe} from "@shared/pipes/appointment-agenda.pipe";



const pipesModule = [BirthPipe, KeyValuePipe, FirstWordPipe, SanitizerPipe, MinValuePipe, NpiPipe, DataUrlToBase64Pipe,ColorDatePipe,
                     QuotePipe, SpecialtyPipe,SuffixToTextPipe, AppointmentAgendaPipe ];

@NgModule({
  declarations: [...pipesModule],
  exports     : [...pipesModule],
})
export class PipesModule {
}
