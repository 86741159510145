import { patientTopCard } from '@shared/helpers/pdf-patient-card.helper';
import { Nurse, OncologyRoadmap } from '@clinical/oncology/interfaces/roadmap.interface';
import { ROADMAP_RECOMMENDATIONS } from '@clinical/oncology/constants/oncology-constant';

const booleanToText = (booleanAssert: boolean): string => booleanAssert ? 'Sí' : 'No';

const getCenterFromRouteParam = (): string => {
  const path = window.location.href.split('/');
  if (Array.isArray(path)) return path.pop();
};

export const buildContentPdfRoadmap = (paperwork, group) => {
  const { rows } = group;  

  const centerId = getCenterFromRouteParam() || '';
  const roadmapBuildPdf = rows.map((roadmap: OncologyRoadmap) => {
    const pdfStack = [];
    if (roadmap.diagnosis) pdfStack.push([
      { fontSize: 12, text: ` Diagnóstico: ` },
      {
        fontSize: 12,
        marginTop: -14,
        marginLeft: 220,
        alignment: 'justify',
        text: `${roadmap.diagnosis}`,
        bold: true,
      },
    ]);
    if (roadmap.isGes !== undefined) pdfStack.push([
      { fontSize: 12, text: ` Patología GES: ` },
      {
        fontSize: 12,
        marginTop: -14,
        marginLeft: 220,
        alignment: 'justify',
        text: `${booleanToText(roadmap.isGes)}`,
        bold: true,
      },
    ]);
    if (roadmap.insurance?.name) pdfStack.push([
      { fontSize: 12, text: ` Convenio: ` },
      {
        fontSize: 12,
        marginTop: -14,
        marginLeft: 220,
        alignment: 'justify',
        text: `${roadmap.insurance.name}`,
        bold: true,
      },
      { fontSize: 12, text: ` Plan del convenio: ` },
      {
        fontSize: 12,
        marginTop: -14,
        marginLeft: 220,
        alignment: 'justify',
        text: `${roadmap.insurance?.plan?.name || '-'}`,
        bold: true,
      },
    ]);
    if (roadmap.scheduled) pdfStack.push([
      { fontSize: 12, text: ` Quimioterapia programada: ` },
      {
        fontSize: 12,
        marginTop: -14,
        marginLeft: 220,
        alignment: 'justify',
        text: `${roadmap.scheduled}`,
        bold: true,
      },
    ]);
    if (roadmap.modality) pdfStack.push([
      { fontSize: 12, text: ` Modalidad: ` },
      {
        fontSize: 12,
        marginTop: -14,
        marginLeft: 220,
        alignment: 'justify',
        text: `${roadmap.modality}`,
        bold: true,
      },
    ]);
    if (roadmap.cycles) pdfStack.push([
      { fontSize: 12, text: ` Número de ciclos programados: ` },
      {
        fontSize: 12,
        marginTop: -14,
        marginLeft: 220,
        alignment: 'justify',
        text: `${roadmap.cycles}`,
        bold: true,
      },
    ]);
    if (roadmap.weight) pdfStack.push([
      { fontSize: 12, text: ` Peso del paciente: ` },
      {
        fontSize: 12,
        marginTop: -14,
        marginLeft: 220,
        alignment: 'justify',
        text: `${roadmap.weight} kg.`,
        bold: true,
      },
    ]);
    if (roadmap.size) pdfStack.push([
      { fontSize: 12, text: ` Talla del paciente: ` },
      {
        fontSize: 12,
        marginTop: -14,
        marginLeft: 220,
        alignment: 'justify',
        text: `${roadmap.size} mts.`,
        bold: true,
      },
    ]);
    if (roadmap.catheter) pdfStack.push([
      { fontSize: 12, text: ` Instalación de cateter: ` },
      {
        fontSize: 12,
        marginTop: -14,
        marginLeft: 220,
        alignment: 'justify',
        text: `${booleanToText(roadmap.catheter?.install)} ${roadmap.catheter.install ? `(${roadmap.catheter.type})` : ''}`,
        bold: true,
      },
    ]);
    if (roadmap.center) pdfStack.push([
      { fontSize: 12, text: ` Lugar de tratamiento: ` },
      {
        fontSize: 12,
        marginTop: -14,
        marginLeft: 220,
        alignment: 'justify',
        text: `${roadmap.center.name}`,
        bold: true,
      },
    ]);
    if (roadmap.nurses.length) {
      pdfStack.push([{ fontSize: 12, text: ` Enfermera coordinadora: ` }]);
      const nursesList = roadmap.nurses.map((nurse: Nurse) => ([
        { text: `${nurse.fullName}`, bold: true },
        { text: `Correo: ${nurse.email}` },
        { text: `Teléfono: ${nurse.phone}` },
      ]));
      pdfStack.push([{ fontSize: 12, marginLeft: 15, ul: nursesList }]);
    }
    if (roadmap.observations) pdfStack.push([
      { fontSize: 12, text: ` Observaciones: ` },
      {
        fontSize: 12,
        marginTop: -14,
        marginLeft: 220,
        alignment: 'justify',
        text: `${roadmap.observations}`,
        bold: true,
      },
    ]);

    const countLineBreaks = (text: string): number => {
      return (text.match(/\n/g) || []).length;
    };

    const nursesLineBreaks = roadmap.nurses.length * 3; // Saltos de línea por cada enfermera

    const observationLineBreaks = countLineBreaks(roadmap.observations || ''); // Saltos de línea en observaciones

    const totalLengthObervations = roadmap.observations.length;
    let maxLengthObservations = 0;

    if (centerId === 'cem') {
      maxLengthObservations = 49;
    } else if (centerId === 'csc') {
      maxLengthObservations = 84;
    }

    if ((nursesLineBreaks + observationLineBreaks) > 5 || totalLengthObervations >= maxLengthObservations) {
      pdfStack.push({ text: '', pageBreak: 'after' });
    }

    if (ROADMAP_RECOMMENDATIONS[centerId]) {
      pdfStack.push([
      {
        fontSize: 10,
        marginTop: 15,
        text: `Estimado paciente, a modo de ayuda le recomendamos seguir los siguientes pasos:`,
      },
      { fontSize: 10, ol: ROADMAP_RECOMMENDATIONS[centerId] },
      ]);
    }

    return [{ fontSize: 15, alignment: 'justify', stack: pdfStack }];
  });
  return [...patientTopCard(paperwork), roadmapBuildPdf];
};
