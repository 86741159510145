import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { EMAILS } from '@shared/constants/notification-emails.const';
import { PaperworkState } from '@store/reducers';

const STAGE: string = environment.stage || 'dev';

@Injectable({
  providedIn: 'root',
})
export class NotificationEmailsService {

  constructor() {
  }

  getEntityEmails(entity: string, paperworkState: PaperworkState): { to?: any; cc?: any; co?: any } {
    const { prefix, suffix } = paperworkState.paperwork;
    if (!prefix || !suffix) return null;

    return EMAILS[STAGE]?.[prefix.toLowerCase()]?.[suffix.toLowerCase()]?.[entity] || null;
  }
}
