import { Component, OnInit } from '@angular/core';
import { ANAMNESIS_TEMPLATES } from '@components/anamnesis/constants/anamnesis.const';
import { PopoverController } from '@ionic/angular';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-popover-anamnesis-selector',
  templateUrl: './popover-anamnesis-selector.component.html',
  styleUrls: ['./popover-anamnesis-selector.component.scss'],
})
export class PopoverAnamnesisSelectorComponent implements OnInit {
  anamnesis = ANAMNESIS_TEMPLATES;

  constructor(private popoverController: PopoverController, private authService: AuthService) {}

  ngOnInit() {
    this.reduceTemplatesByAccessUser();
  }

  chooseAnamnesis(anamnesis: string) {
    this.popoverController.dismiss(anamnesis);
  }

  reduceTemplatesByAccessUser() {
    const { accessTemplatesAnamnesis } = this.authService.professional;
    const anamnesisTemplate = this.anamnesis;

    this.anamnesis = anamnesisTemplate.filter((component) => accessTemplatesAnamnesis.includes(component.selector));
  }
}
