import { HMTL_MAIL_BUTTON } from './link-button';
import { HTML_FOOTER_MAIL } from './footer';
import { HTML_MAIL_HEAD } from './head';
import { HTML_MAIL_HEADER } from './header';

export const MAIL_TEMPLATE_GENERIC = `
<!DOCTYPE html>
<html lang="es">
  ${HTML_MAIL_HEAD}
  <body
    paddingwidth="0"
    paddingheight="0"
    style="
      padding-top: 0;
      padding-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      background-repeat: repeat;
      width: 100% !important;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
      -webkit-font-smoothing: antialiased;
    "
    offset="0"
    toppadding="0"
    leftpadding="0"
  >
    <table
      bgcolor="#ffffff"
      width="100%"
      border="0"
      cellspacing="0"
      cellpadding="0"
      class="tableContent"
      align="center"
      style="font-family: Helvetica, Arial, serif"
    >
      <tbody>
        <tr>
          <td>
            <table
              width="90%"
              border="0"
              cellspacing="0"
              cellpadding="0"
              align="center"
              bgcolor="#ffffff"
              class="MainContainer"
            >
              <tbody>
                <tr>
                  <td>
                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tbody>
                        <tr>
                          <td valign="top" width="40">&nbsp;</td>
                          <td>
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                              <tbody>
                                <tr>
                                  <td height="15" class="spechide"></td>
                                </tr>
                                <tr>
                                  <td class="movableContentContainer" valign="top">
                                    <div
                                      class="movableContent"
                                      style="border: 0px; padding-top: 0px; position: relative"
                                    >
                                      <!-- ----------------------------------------------------------------------- -->
                                      <!--                           Cabecera del correo                           -->
                                      <!-- ----------------------------------------------------------------------- -->
                                      ${HTML_MAIL_HEADER}
                                    </div>

                                    <div
                                      class="movableContent"
                                      style="border: 0px; padding-top: 0px; position: relative"
                                    >
                                      <table width="100%" border="0" cellspacing="0" cellpadding="0" align="center">
                                        <!-- ----------------------------------------------------------------------- -->
                                        <!--                      Saludo al receptor del correo                      -->
                                        <!-- ----------------------------------------------------------------------- -->
                                        <tr>
                                          <td height="55"></td>
                                        </tr>
                                        <tr>
                                          <td align="left">
                                            <div class="contentEditableContainer contentTextEditable">
                                              <div class="contentEditable" align="center">
                                                <h2>{{greeting}},</h2>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>

                                        <!-- ----------------------------------------------------------------------- -->
                                        <!--                           Mensaje del correo                            -->
                                        <!-- ----------------------------------------------------------------------- -->
                                        <tr>
                                          <td align="left">
                                            <div class="mail-content">
                                              <div>
                                                {{message}}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </table>

                                      <!-- ----------------------------------------------------------------------- -->
                                      <!--                  Boton Adaptado a versiones de Outlook                  -->
                                      <!-- ----------------------------------------------------------------------- -->

                                      <table class="tg">
                                        <thead>
                                          <tr>
                                            {{buttons}}
                                          </tr>
                                        </thead>
                                      </table>
                                    </div>

                                    <!-- ----------------------------------------------------------------------- -->
                                    <!--                             pie del correo                              -->
                                    <!-- ----------------------------------------------------------------------- -->
                                            ${HTML_FOOTER_MAIL}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td height="88"></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </body>
</html>
`;
