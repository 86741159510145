import {patientTopCard} from '@shared/helpers/pdf-patient-card.helper';

export const buildContentPdfEte = (paperwork, group) => {
  const {rows}      = group;
  const buildPdfEte = rows.map((eteForm) => {
    const DIAGNOSTICS_TITLE   = {
      fontSize: 12,
      margin  : [0, 5],
      stack   : [{text: `Resultado de los factores de Riesgo de ETE`, bold: true}],
    };
    const DIAGNOSTICS_CONTENT = {
      table: {
        widths: ['auto', '*', 'auto'],
        body  : [
          [
            {text: 'Riesgo', bold: true, fillColor: 'lightgray'},
            {text: 'Profilaxis recomendada', bold: true, fillColor: 'lightgray'},
            {text: 'Duración', bold: true, fillColor: 'lightgray'},
          ],
          [
            {text: eteForm.recommendation.group, bold: true, fillColor: eteForm.recommendation.color},
            {text: eteForm.recommendation.name, bold: true, fillColor: eteForm.recommendation.color},
            {text: 'Hasta la movilización', bold: true, fillColor: eteForm.recommendation.color},
          ],
        ],
      },
    };
    return [DIAGNOSTICS_TITLE, DIAGNOSTICS_CONTENT];
  });
  return [...patientTopCard(paperwork), buildPdfEte];
};
