import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on } from '@ngrx/store';
import { Appointment } from '@shared/interfaces/appointment.interface';

import { SELECTED_AGENDA, UNSELECT_AGENDA } from './agenda.actions';
import { LOAD_AGENDA, LOAD_AGENDA_OK, LOAD_AGENDA_ERR } from './agenda.actions';

export interface AppointmentState {
  appointments: Appointment[];
  isLoaded: boolean;
  isLoading: boolean;
  error: HttpErrorResponse;
  selected: Appointment;
}

export const appointmentState: AppointmentState = {
  appointments: [],
  isLoaded: false,
  isLoading: false,
  error: null,
  selected: null,
};

const createReducerAppointment = createReducer(
  appointmentState,

  on(LOAD_AGENDA, (state) => ({ ...state, isLoading: true })),

  on(LOAD_AGENDA_OK, (state, { appointments }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    appointments: [...appointments],
  })),

  on(LOAD_AGENDA_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    appointments: null,
    error: { ...payload },
  })),

  on(SELECTED_AGENDA, (state, { patientDocNumber }) => {
    const selected =
      state.appointments ?
      state.appointments.find((appointment) => appointment.patient.documentNumber === patientDocNumber) :
      null;
    return { ...state, selected: selected || null};
  }),

  on(UNSELECT_AGENDA, (state) => ({
    ...state,
    selected: null,
  })),
);

export const appointmentReducer = (state: AppointmentState, action: Action) => createReducerAppointment(state, action);
