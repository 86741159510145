import { DELETE_EXPERTISE, PUT_EXPERTISE } from '@clinical/expertise/store/clinical-expertise.actions';
import { EXPERTISE_ROUTES } from '@clinical/expertise/expertise-routes.module';
import { PrintedOnPage, PrintMode, Workflow } from '@shared/interfaces/paperwork.interface';
import { DEFAULT_PDF_POSITION_SIGNATURE } from '@shared/constants/app.const';

const workflow: Workflow = {
  isSigned: true,
  isStored: true,
  isSaving: true,
  hasNextControl: true,
  notifications: [
    {
      type: 'email',
      entity: 'patient',
      subject: 'Notificaciones UC CHRISTUS | Informe pericial',
      template: 'generic',
    },
  ],
};
const pdf = {
  isPreview: true,
  header: { logo: PrintedOnPage.all, title: PrintedOnPage.all, professional: PrintedOnPage.all },
  patient: { type: PrintMode.full, printed: PrintedOnPage.all },
  footer: {
    signature: { printed: PrintedOnPage.all, position: DEFAULT_PDF_POSITION_SIGNATURE },
    qr: { right: { url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web' } },
  },
};

export const PAPERWORK_EXPERTISE_DEFINITION = {
  actions: {
    put: PUT_EXPERTISE,
    delete: DELETE_EXPERTISE,
  },
  prefix: 'PRT',
  module: 'peritajes',
  legalReport: {
    suffix: 'ABS',
    group: 'informe médico legal',
    docTitle: 'Informe Médico Legal',
    submodule: EXPERTISE_ROUTES.expertiseForm,
  },
  workflow,
  config: pdf,
};
