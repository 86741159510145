import {HttpErrorResponse} from '@angular/common/http';
import {createAction, props} from '@ngrx/store';

import {ParamsRequest, VoucherRequest, YappOrder} from '../interfaces/voucher.interface';

export const CLEAR_RECIPES_PHARM = createAction('[medicamentos] Limpiar medicamentos por paciente');
export const SELECTED_RECIPE_PHARM = createAction(
  '[medicamentos] SELECTED Receta Médica',
  props<{ selected: YappOrder; action: string }>(),
);
export const UNSELECT_RECIPE_PHARM = createAction('[medicamentos] UNSELECT Receta Médica');

export const LOAD_DELIVERY_BY_RECIPE = createAction(
  '[comprobante] Cargar obtener comprobante por paciente',
  props<{ params: ParamsRequest }>(),
);
export const LOAD_DELIVERY_BY_RECIPE_OK = createAction(
  '[comprobante] OK obtener comprobante por paciente',
  props<{ requestData: VoucherRequest }>(),
);
export const LOAD_DELIVERY_BY_RECIPE_ERR = createAction(
  '[comprobante] ERR obtener comprobante por paciente',
  props<{ payload: HttpErrorResponse }>(),
);
