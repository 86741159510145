import {SpecialVaccine, Vaccine} from '@clinical/forms/interfaces/special-vaccines.interface';
import {SPECIAL_VACCINES} from '@clinical/forms/constants/special-vaccines.const';
import {DateService} from '@shared/services/date.service';
import {format} from 'date-fns';

const vaccinesContent = (row: any) => SPECIAL_VACCINES.map((vaccine: Vaccine) => {
  const vaccineSelected: Vaccine = !row.vaccines
    ? false
    : row.vaccines.find((selectVaccine: Vaccine) => selectVaccine.id === vaccine.id);
  return [
    {text: vaccineSelected ? 'X' : '', alignment: 'center'},
    {text: [{text: vaccine?.id === 19 ? ` ${vaccineSelected?.name || 'Otras'}` : vaccine?.name}]},
    {text: vaccineSelected ? vaccineSelected.quantity : '', alignment: 'center'},
    {text: vaccineSelected ? vaccineSelected.observations : ''},
  ];
});

const formattedDate = (date: string): string => {
  if (!date) return '';
  return format(new Date(date), 'dd-MM-yyyy');
};

export const buildContentPdfSpecialVaccines = (paperwork, group) => {
  const {rows} = group;
  const {patient} = paperwork;

  const vacBuildPdf = rows.map((item: SpecialVaccine) => {
    const thTextColor = '#fff';
    const thFillColor = '#4f81ba';
    const {region, commune, establishment, pathologicalCondition, pharmaceuticalTreatment, date} = item;

    return [
      {
        table : {
          widths    : ['*', '*'],
          headerRows: 2,
          body      : [
            [{
              text     : 'DATOS DEL ESTABLECIMIENTO',
              color    : thTextColor,
              fillColor: thFillColor,
              bold     : true,
              colSpan  : 2,
              alignment: 'center',
            }, {}],
            [
              {
                text: [{text: `Región: `, bold: true}, {text: `${!region ? '' : region}`}],
              },
              {
                text: [{text: `Comuna: `, bold: true}, {text: `${commune}`}],
              },
            ],
            [
              {
                text   : [{text: `Establecimiento: `, bold: true}, {text: `${establishment}`}],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text   : [{text: `Fecha solicitud: `, bold: true}, {text: formattedDate(date)}],
                colSpan: 2,
              },
              {},
            ],
          ],
        },
        margin: [0, 5, 0, 0],
      },
      {
        table : {
          widths    : ['*', '*'],
          headerRows: 2,
          body      : [
            [{
              text     : 'DATOS DEL PACIENTE',
              color    : thTextColor,
              fillColor: thFillColor,
              bold     : true,
              colSpan  : 2,
              alignment: 'center',
            }, {}],
            [
              {
                text   : [
                  {text: `Nombre completo: `, bold: true},
                  {text: `${!patient ? '' : patient.names} ${!patient ? '' : patient.surnames}`},
                ],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: [{
                  text: `Rut /DNI /Pasaporte: `,
                  bold: true,
                }, {text: `${!patient ? '' : patient.documentNumber}`}],
              },
              {
                text: [
                  {text: `Fecha de Nacimiento: `, bold: true},
                  {text: `${!patient ? '' : formattedDate(patient.birth)}`},
                ],
              },
            ],
            [
              {
                text: [{text: `Edad: `, bold: true},
                  {text: `${!patient ? '' : DateService.yearsOld(patient.birth)}`}],
              },
              {
                text: [{text: `Sexo Biológico: `, bold: true},
                  {text: `${!patient ? 'M ( )	F ( )	Otro ( )' : (patient.gender || '').slice(0, 1)}`}],
              },
            ],
            [
              {
                text   : [
                  {text: `Diagnóstico Clínico: `, bold: true},
                  {text: `${pathologicalCondition || ''}`},
                ],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text   : [
                  {
                    text: [{
                      text: `Medicamentos Inmunosupresores de Uso Frecuente:`,
                      bold: true,
                    }, {text: `\n(nombre de medicamento y dosis) `}],
                  },
                  {text: `${pharmaceuticalTreatment || ''}`}],
                colSpan: 2,
              },
              {},
            ],
          ],
        },
        margin: [0, 10, 0, 10],
      },
      {
        table: {
          widths: [60, 100, 70, '*'],
          body  : [
            [
              {
                text     : 'Marcar Vacuna (X)',
                color    : thTextColor,
                fillColor: thFillColor,
                bold     : true,
                style    : 'tableHeader',
              },
              {
                text     : 'Vacuna (s) Solicitada(s)',
                color    : thTextColor,
                fillColor: thFillColor,
                bold     : true,
                style    : 'tableHeader',
              },
              {
                text     : 'Nº dosis Solicitadas',
                color    : thTextColor,
                fillColor: thFillColor,
                bold     : true,
                style    : 'tableHeader',
              },
              {text: 'Observaciones', color: thTextColor, fillColor: thFillColor, bold: true, style: 'tableHeader'},
            ],
            ...(vaccinesContent(item)),
          ],
        },
      },
      {
        text     : [
          {text: 'IMPORTANTE:', bold: true},
          {text: ' Recuerde adjuntar Orden médica, Epicrisis o Historia clínica y Notificación ESAVI si corresponde.'},
        ],
        alignment: 'justify',
        margin   : [0, 20],
      },
    ];
  });

  return [vacBuildPdf];
};
