import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {mergeMap, Observable} from 'rxjs';
import {AuthService} from 'src/app/auth/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getToken().pipe(
      mergeMap(token => {

        if (!token) return next.handle(req);

        const isPCv2API = req.url.includes(environment.baseApiPCv2);
        if (isPCv2API) {
          const setAuthHeader = {headers: req.headers.set('Authorization', `${token}`)};
          const headers = req.clone(setAuthHeader);
          return next.handle(headers);
        }

        return next.handle(req);
      })
    );


  }
}
