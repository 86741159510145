import { patientTopCard } from '@shared/helpers/pdf-patient-card.helper';
import { Recipe } from '@shared/interfaces/recipe.interface';
import { getUnit } from '../constants/posologies.const';

export const buildContentPdfRecipes = (paperwork, group) => {
  const row = group.rows;
  const prescriptionsByMedicine = row.reduce((acc, prescription) => {
    const { salesTermRecept } = prescription;
    if (!acc[salesTermRecept]) acc[salesTermRecept] = [];
    acc[salesTermRecept].push(prescription);
    return acc;
  }, {} as { [key: string]: Recipe[] });

  let existRR = false;
  if (prescriptionsByMedicine.RR) existRR = true;

  //transform prescriptionsByMedicine to array
  const prescriptionsByMedicineArray = Object.keys(prescriptionsByMedicine).map((key) => ({
    salesTermRecept: key,
    prescriptions: prescriptionsByMedicine[key],
  }));
  //sort prescriptionsByMedicineArray by salesTermRecept RR first
  prescriptionsByMedicineArray.sort((a, b) => {
    if (a.salesTermRecept === 'RR') return -1;
    if (b.salesTermRecept === 'RR') return 1;
    return 0;
  });

  //transform prescriptionsByMedicineArray to array of prescriptions
  const prescriptionsArray = prescriptionsByMedicineArray.reduce((acc, prescriptionByMedicine) => {
    const temp = prescriptionByMedicine.prescriptions;
    temp.forEach((prescription) => {
      acc.push(prescription);
    });
    return acc;
  }, [] as Recipe[]);

  const recipeBuildPdf = prescriptionsArray.map((itemForm: Recipe, index) => {
    const { observations, recommendation, start } = itemForm;
    const { duration, each, medicine, posology } = itemForm;
    const { name, salesTermRecept } = medicine;

    const NAME = {
      text: `${name?.toUpperCase()}`,
      bold: true,
      fontSize: 10,
      alignment: 'justify',
    };
    if (!name) NAME.text = '';

    const RECOMENDED = { text: `Recomendado: ${(recommendation?.name ? recommendation.name : recommendation)?.toUpperCase()}` };
    if (!recommendation) RECOMENDED.text = '';

    const hasDurationQuantity = !['permanente', 'sos'].includes(duration.unit.toLocaleLowerCase());
    const DURATION = {
      text:
        `Aplicación: ${posology.quantity} ${getUnit(posology.unit, posology.quantity)} ` +
        `cada ${each.quantity} ${getUnit(each.unit, each.quantity)} ` +
        `por ${hasDurationQuantity ? duration.quantity : ''} ${getUnit(duration.unit, duration.quantity)}`,
    };

    const START_TREATMENT = {
      text: `Inicio del Tratamiento: ${(start || '').toString().split('-').reverse().join('-')}`,
    };

    const OBSERVATIONS = observations
      ? { text: `Observaciones: ${observations}`, margin: [0, 0, 0, 10], alignment: 'justify' }
      : { text: '' };

    const breakPage = existRR && salesTermRecept !== 'RR';
    const optionalPatientTopCard = index === 0 || breakPage ? [...patientTopCard(paperwork, breakPage)] : null;

    return [
      optionalPatientTopCard,
      {
        ul: [NAME],
        margin: [0, 10, 0, 0],
      },
      {
        fontSize: 10,
        type: 'none',
        ol: [RECOMENDED, DURATION, START_TREATMENT, OBSERVATIONS],
      },
    ];
  });
  return [recipeBuildPdf];
};
