import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

import { API_URIS } from '@shared/constants/api-uris.const';
import { Paperwork } from '@shared/interfaces/paperwork.interface';
import { Medicine, Recipe } from '@shared/interfaces/recipe.interface';
import { MagistralRecipe } from '@shared/interfaces/recipe-magistral.interface';

import { PCV2Request } from '@shared/interfaces/request.interface';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StatusService } from '@shared/services/status.service';

const BASE_API_RME: string = environment.baseApiRME;
const BASE_API_PCV2: string = environment.baseApiPCv2;
const URI_CLINICAL_RECIPES: string = API_URIS.clinical.recipes;

@Injectable({
    providedIn: 'root',
})
export class RecipeService {

    constructor(private httpClient: HttpClient, private statusService: StatusService) {
    }

    /**
     * @param query palabra llave para buscar medicamentos ingredienteActivo o nombreComercial
     * @param text texto a buscar
     * @returns Medicamentos que coinciden con la busqueda
     */
    getPrescriptions(query: string, text: string): Observable<{ status: string; request: any }> {
        if (text === '') return of({status: 'void', request: []});
        return this.httpClient.get<Medicine[]>(`${BASE_API_RME}/v1/medicamentos?${query}=${text}`).pipe(
            map((data: any) => {
                if (data.length === 0) return this.statusService.status('void', []);
                if (data.errorType === 'ValueError') return this.statusService.status('void', []);
                return this.statusService.status('ok', data);
            }),
            catchError(this.statusService.catchError),
        );
    }

    /**
     * @param body objeto de tipo Paperwork<Recipe> a guardar
     * @returns la data de tipo Paperwork<Recipe> del objeto guardado
     */
    putRecipe(body: Paperwork<Recipe | MagistralRecipe>): Observable<Paperwork<Recipe | MagistralRecipe>> {
        return this.httpClient.post<PCV2Request>(`${BASE_API_PCV2}/${URI_CLINICAL_RECIPES}`, body).pipe(
          map(({ data }: { data: Paperwork<Recipe | MagistralRecipe> }) => data),
        );
    }

}
