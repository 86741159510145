import { SignedDoc, SignDoc } from '@shared/interfaces/sign-interface';
import { createAction, props } from '@ngrx/store';

/* Firma de Documentos Electronicos */
export const DIGITAL_SIGNATURE = createAction( '[firma] Firma Electronica', props<{ formData: SignDoc; title: string }>());
export const DIGITAL_SIGNATURE_OK = createAction( '[firma] OK Firma Electronica', props<{ signedDoc: SignedDoc; title: string }>() );
export const DIGITAL_SIGNATURE_ERR = createAction('[firma] ERR Firma Electronica', props<{ payload: any }>());

export const CLEAR_DIGITAL_SIGNATURE = createAction('[firma] Limpiar Firma Electronica');

export const PASTE_PDF_SIGNATURE = createAction( '[firma] Pegar firma profesional pdf');
export const PASTE_PDF_SIGNATURE_OK = createAction( '[firma] OK Pegar firma profesional pdf',
    props<{ signedDoc: SignedDoc; title: string }>() );
export const PASTE_PDF_SIGNATURE_ERR = createAction('[firma] ERR Pegar firma profesional pdf',
    props<{ payload: any }>());
