import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, of, Subject, throwError} from 'rxjs';
import {catchError, map, pluck, retry, takeUntil, tap} from 'rxjs/operators';

import {PCV2Request} from '@shared/interfaces/request.interface';
import {Appointment} from '@shared/interfaces/appointment.interface';
import {CurrentOffer, DataOfferPRM, ServiceParams} from '@clinical/schedule/interfaces/offers.interface';
import {Cita, Cupo, RequestSchedule, ScheduleResponseAPI} from '@clinical/schedule/interfaces/schedule.interface';
import {environment} from '@environments/environment';
import {API_URIS} from '@shared/constants/api-uris.const';
import camelcaseKeys from 'camelcase-keys';
import {format} from 'date-fns';
import {SELECTED_AGENDA} from '@store/actions';
import {Store} from '@ngrx/store';
import {AppState} from '@store/app.reducers';
import {MedicalcareService} from '@clinical/medicalcare/services/medicalcare.service';
import {UiService} from '@shared/services/ui.service';
import {NotificationService} from '@shared/services/notification.service';

const BASE_API: string = environment.baseApiAmbulatory;
const BASE_API_PCV2: string = environment.baseApiPCv2;
const URI_SCHEDULE_REQUEST: string = API_URIS.schedule.request;

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  agendaToday = new Subject();
  public isLoading: boolean;

  public totalCited = 0;
  private currentDatetimeSchedule: Date = new Date();

  private destroy = new Subject();

  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>,
    public medicalcareService: MedicalcareService,
    private uiService: UiService,
    private notificationService: NotificationService,
  ) {
  }

  public get getCurrentDatetimeSchedule() {
    return this.currentDatetimeSchedule;
  }

  public set setCurrentDatetimeSchedule(value: Date) {
    this.currentDatetimeSchedule = value;
  }

  getBoxAndFloor(keiron: any): Observable<any> {
    return this.httpClient.post<PCV2Request>(`${BASE_API_PCV2}/keiron/v1/pacientes/llamar`, keiron).pipe(
      catchError((err) => throwError(err)),
      map(({data}: { data }) => data),
    );
  }

  getProfessionalCare(professionalId: string): Observable<PCV2Request> {
    const shortDate = format(new Date(), 'yyyyMM');
    const date = format(new Date(), 'yyyy-MM-dd');
    const gsiMultiCondition1St = encodeURIComponent(`ATN#DTE#${shortDate}#PRO#${professionalId}`);

    return this.httpClient.get<PCV2Request>(
      `${BASE_API_PCV2}/clinico/v1/indices/1/buscar/${gsiMultiCondition1St}?createdAt=["begins_with","${date}"]`,
    );
  }

  getSchedule(params: RequestSchedule): Observable<Appointment[]> {
    this.isLoading = true;
    const {fechaCitas, rutProfesional} = params;
    if (!rutProfesional) {
      this.isLoading = false;
      return of([]);
    }
    return this.httpClient
      .get<ScheduleResponseAPI>(`${BASE_API}/Agenda/Prof/Dia?fechaCitas=${fechaCitas}&rutProfesional=${rutProfesional}`)
      .pipe(
        map(({errorMessage, cupos}) => {
          if (errorMessage) {
            this.isLoading = false;
            throw new Error(errorMessage);
          } else return cupos;
        }),
        map((quotes) => this.getAppointments(quotes)),
        tap(() => (this.isLoading = false)),
      );
  }

  getAppointments(quote: Cupo[]): Appointment[] {
    const appointments: Appointment[] = [];
    this.totalCited = 0;

    quote.forEach((center) => {
      const quotesAppointments = [...center.cupos.citas, ...center.cupos.cuposLibres];

      quotesAppointments.forEach((app, i) => {
        if (app.tipo === 'Appointment' || app.tipo === 'Overcapacity') this.totalCited++;
        app.centro = center.nombre;
        this.formartAppointment(app);
      });

      quotesAppointments.map((appointment) => {
        const {tipo} = appointment;
        if (tipo === 'Appointment') appointment.centro = center.nombre;
        appointments.push(this.formartAppointment(appointment));
      });
    });

    return appointments;
  }

  formartAppointment(quote: Cita): Appointment {
    const patient = {
      id: quote.idPaciente,
      documentNumber: quote.citaSAP?.rutpac || null,
      documentType: quote.citaSAP !== undefined && quote.citaSAP?.rutpac ? 'NationalId' : '',
      fullname: quote.nombrePaciente,
      phoneNoumber: quote.numeroTelefonoPrincipal,
      healthInsurance: {
        id: quote.idPlanCobertura,
        coveragePlan: quote.nombrePlanCobertura,
      },
    };

    const specialty = {
      id: quote.idEspecialidad,
      name: quote.nombreEspecialidad,
    };

    const pay = {
      id: quote.citaSAP?.idcita,
      amount: quote.citaSAP?.monpag.trim(),
      time: quote.citaSAP?.horpag,
    };

    const box = {id: null, name: null};
    const professional = {id: null, fullname: null};

    if (quote.Recursos)
      quote.Recursos.forEach((resource) => {
        if (resource.esProfesional) {
          professional.id = resource.id;
          professional.fullname = resource.nombre;
        }

        if (!resource.recursoPrincipal) {
          box.id = resource.id;
          box.name = resource.nombre;
        }
      });

    const appointment = {
      id: quote.id,
      center: quote.centro,
      duration: quote.duracion,
      fromDatetime: quote.fechaHoraDesde,
      toDatetime: quote.fechaHoraHasta,
      isCanceled: quote.cancelada,
      service: quote.nombreServicio,
      state: quote.citaSAP?.estado,
      statePRM: quote.estado,
      type: quote.tipo,
      pay,
      box,
      patient,
      specialty,
      professional,
    };

    return appointment;
  }

  findCurrentOffersByEntitiesId(params: ServiceParams): Observable<CurrentOffer[]> {
    const {professionalId} = params;
    return this.httpClient
      .get<PCV2Request>(`${BASE_API_PCV2}/agenda/v1/ofertas/profesionales/${professionalId}`)
      .pipe(retry(10), pluck('data'), map(this.formatOffers));
  }

  formatOffers(offerPRM: DataOfferPRM[]): CurrentOffer[] {
    const offers = offerPRM.map((offer) => ({
      validFrom: offer.ValidFrom,
      validTo: offer.ValidTo,
      repetition: offer.Duration,
      id: offer.Id,
      createdOn: offer.CreatedOn,
      center: offer.Center,
      timeSlots: offer.TimeSlots,
      duration: offer.Duration,
      services: offer.Services,
      status: offer.Status,
    }));

    return offers;
  }

  addTelemedicineOffer(body): Observable<PCV2Request> {
    const camel = camelcaseKeys(body, {deep: true, pascalCase: true});
    return this.httpClient
      .post<PCV2Request>(`${BASE_API_PCV2}/agenda/v1/solicitudes/telemedicina`, camel)
      .pipe(catchError((err) => throwError(() => err)));
  }

  addRequest(body, type: string) {
    return this.httpClient.post<PCV2Request>(`${BASE_API_PCV2}/agenda/v1/solicitudes/${type}s`, body).pipe(
      catchError((err) => throwError(err)),
      map(({data}: { data: any }) => data),
    );
  }

  addBlockPRM(body) {
    return this.httpClient.post<PCV2Request>(`${BASE_API_PCV2}/agenda/v1/solicitudes/pre-aprobacion`, body).pipe(
      catchError((err) => throwError(err)),
      map(({data}: { data }) => data),
    );
  }

  addAproveBlockPRM(body) {
    return this.httpClient.post<PCV2Request>(`${BASE_API_PCV2}/agenda/v1/solicitudes/aprobacion`, body).pipe(
      catchError((err) => throwError(err)),
      map(({data}: { data }) => data),
    );
  }

  getRequestOffert(professionalId: string): Observable<any> {
    const URL = `${BASE_API_PCV2}/${URI_SCHEDULE_REQUEST}/profesionales/${professionalId}`;
    return this.httpClient.get<PCV2Request>(`${URL}`).pipe(
      retry(3),
      map(({data}: { data: any[] }) => data),
    );
  }

  //metodos para el llamado al paciente desde la agenda
  getMeetingUrl(appointment: Appointment) {
    if (appointment.center && appointment.center.toLowerCase() === 'telemedicina')
      this.medicalcareService.getAppointmentMeeting(appointment.id).subscribe(
        (response) => {
          window.open(response.url, '_blank');
        },
        () => {
          const messageE = `<p>Ocurrio un error mientras ubicabamos el link de acceso a su cita de zoom con su paciente</p>`;
          const buttons = [
            {
              text: 'OK',
            },
          ];
          this.uiService.presentAlert('danger', messageE, buttons);
        },
      );
  }

  startMeeting(appointment: Appointment, modal: any) {
    this.getMeetingUrl(appointment);
    if (modal) modal.dismiss();

    const professionalId = appointment.professional.id;
    const shorDate = format(new Date(), 'yyyyMM');
    const gsiMultiCondition1St = `ATN#DTE#${shorDate}#PRO#${professionalId}`;

    const valuesToSave = {start: new Date().toISOString(), gsiMultiCondition1St, appointment};

    this.notifications(appointment);

    this.medicalcareService.save(valuesToSave).pipe(takeUntil(this.destroy)).subscribe();
    this.store.dispatch(SELECTED_AGENDA({patientDocNumber: appointment.patient.documentNumber}));
  }

  notifications(appointment: Appointment) {
    const {patient, fromDatetime, box, pay, center} = appointment;
    const phone = appointment?.patient?.phoneNoumber;

    if (center === 'Telemedicina') return;

    const keiron = {
      sapId: pay.id,
      rut: patient.documentNumber,
      date: format(new Date(fromDatetime), 'yyyyMMdd'),
      box: box.name,
    };

    this.getBoxAndFloor(keiron)
      .pipe()
      .subscribe((data) => {
          const messageCallBox = `<p>Se avisó al paciente para que se acerque al box.</p>`;
          const buttons = [{text: 'OK'}];
          this.uiService.presentAlert('success', messageCallBox, buttons);

          if (center === 'Clínica San Carlos') {
            const message = `Por favor acérquese al box ${data.box} en el piso ${data.floor}. UC CHRISTUS.`;
            this.notificationService.sendSMS({message, phone: phone});
          }
        },
        (error) => {
          const messageCallBox = `<p>No se envió notificación por pantalla.</p>`;
          const buttons = [
            {
              text: 'OK',
            },
          ];
          this.uiService.presentAlert('danger', messageCallBox, buttons);
        },
      );
  }

  startMetting(appointment: Appointment, modal: any) {
    if (appointment.center !== 'Telemedicina') return;
    this.getMeetingUrl(appointment);
    if (modal) modal.dismiss();

    const professionalId = appointment.professional.id;
    const shorDate = format(new Date(), 'yyyyMM');
    const gsiMultiCondition1St = `ATN#DTE#${shorDate}#PRO#${professionalId}`;

    const valuesToSave = {start: new Date().toISOString(), gsiMultiCondition1St, appointment};

    // this.notifications(appointment);

    this.medicalcareService.save(valuesToSave).pipe(takeUntil(this.destroy)).subscribe();
    this.store.dispatch(SELECTED_AGENDA({patientDocNumber: appointment.patient.documentNumber}));
  }
}
