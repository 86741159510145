export const HTML_FOOTER_MAIL = `
<table
  width="100%"
  border="0"
  cellspacing="0"
  cellpadding="0"
  style="padding-top: 12px"
>
  <tbody>
  <tr>
  <td> &nbsp;</td>
  </tr>
  <tr>
  <td> &nbsp;</td>
  </tr>
  <tr>
  <td> <p style="font-size:11px; background:#D6D7FC; padding:10px;border-radius:5px;"> <b>Este es un correo automatico, por favor no responder</b>. En caso de dudas con su documento electrónico por favor contáctese directamente con su médico, a través del correo que se encuentra en los datos del médico, en la esquina superior derecha del documento enviado. </p> </td>
</tr>
<tr>
<td> &nbsp;</td>
</tr>
    <tr>
      <td style="border-bottom: 1px solid #dddddd"></td>
    </tr>
    <tr>
      <td height="25"></td>
    </tr>
    <tr>
      <td>
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td valign="top" class="specbundle">
                <div class="contentEditableContainer contentTextEditable">
                  <div class="contentEditable" align="center">
                    <p
                      style="
                        text-align: left;
                        color: #5a5a5a;
                        font-size: 12px;
                        font-weight: normal;
                        line-height: 20px;
                      "
                    >
                      <span style="font-weight: bold">
                        Red de Salud UC CHRISTUS.
                      </span>
                      <br />
                      Mesa Central Hospital Cl&iacute;nico: 22 354 3000
                      <br />
                      Mesa Central Cl&iacute;nica San Carlos: 22 754 8700
                      <br />
                      Rescate Ambulancia: 800 265 265
                      <br />
                      Reserva de Horas: 22 676 7000
                    </p>
                  </div>
                </div>
              </td>
              <td valign="top" width="30" class="specbundle">&nbsp;</td>
              <td valign="top" class="specbundle">
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td valign="top" width="16">&nbsp;</td>
                      <td valign="top" width="1">
                        <div
                          class="contentEditableContainer contentTwitterEditable"
                        >
                          <div class="contentEditable">
                            <a
                              target="_blank"
                              href="https://www.facebook.com/UCCHRISTUS"
                            >
                              <img
                                src="https://www.ucchristus.cl/assets/images/rs1.png"
                                width="20"
                                alt="Facebook"
                                data-default="placeholder"
                                data-max-width="52"
                                data-customIcon="true"
                              />
                            </a>
                          </div>
                        </div>
                      </td>
                      <td valign="top" width="1">
                        <div
                          class="contentEditableContainer contentTwitterEditable"
                        >
                          <div class="contentEditable">
                            <a
                              target="_blank"
                              href="https://www.instagram.com/ucchristus/?hl=es-la"
                            >
                              <img
                                src="https://www.ucchristus.cl/assets/images/rs4.png"
                                width="20"
                                alt="Instagram"
                                data-default="placeholder"
                                data-max-width="52"
                                data-customIcon="true"
                              />
                            </a>
                          </div>
                        </div>
                      </td>
                      <td valign="top" width="1">
                        <div
                          class="contentEditableContainer contentTwitterEditable"
                        >
                          <div class="contentEditable">
                            <a
                              target="_blank"
                              href="https://twitter.com/ucchristus"
                            >
                              <img
                                src="https://www.ucchristus.cl/assets/images/rs2.png"
                                width="20"
                                alt="Twitter"
                                data-default="placeholder"
                                data-max-width="52"
                                data-customIcon="true"
                              />
                            </a>
                          </div>
                        </div>
                      </td>
                      <td valign="top" width="1">
                        <div
                          class="contentEditableContainer contentTwitterEditable"
                        >
                          <div class="contentEditable">
                            <a
                              target="_blank"
                              href="https://cl.linkedin.com/company/ucchristus"
                            >
                              <img
                                src="https://www.ucchristus.cl/assets/images/rs5.png"
                                width="20"
                                alt="linkedin"
                                data-default="placeholder"
                                data-max-width="52"
                                data-customIcon="true"
                              />
                            </a>
                          </div>
                        </div>
                      </td>
                      <td valign="top" width="1">
                        <div
                          class="contentEditableContainer contentTwitterEditable"
                        >
                          <div class="contentEditable">
                            <a
                              target="_blank"
                              href="https://www.youtube.com/user/UCCHRISTUS"
                            >
                              <img
                                src="https://www.ucchristus.cl/assets/images/rs3.png"
                                width="20"
                                alt="youtube"
                                data-default="placeholder"
                                data-max-width="52"
                                data-customIcon="true"
                              />
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr>
      <td height="88"></td>
    </tr>
  </tbody>
</table>`;
