/* eslint-disable @typescript-eslint/naming-convention */
import {Component, Input, OnInit} from '@angular/core';
import {MultiService}             from '@clinical/carerounds/services/multi.service';
import {FormFieldsValidate}       from '@core/reactive-forms/form-fields-validate.helper';
import {FormGroup} from '@core/reactive-forms/form-group';
import {ModelForm} from '@core/reactive-forms/types';

import {ModalController} from '@ionic/angular';
import {PageTableDef} from '@shared/interfaces/table.interface';
import {format} from 'date-fns';
import {map} from 'rxjs/operators';

@Component({
  selector   : 'app-report-modal-multy',
  templateUrl: './report-modal-multy.component.html',
  styleUrls  : ['./report-modal-multy.component.scss'],
})
export class ReportModalMultyComponent implements OnInit {
  @Input() pageTable: PageTableDef;
  @Input() data;
  @Input() hideButtons;
  @Input() index;
  currentDate = format(new Date(), 'yyyy-MM-dd');
  inProcess = false;
  multidisciplinary: FormGroup<ModelForm<any>>;

  constructor(
    private modalController: ModalController,
    private multiService: MultiService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.multidisciplinary.patchValue(this.data);
  }

  dismiss() {
    this.modalController.dismiss();
  }

  save() {
    this.inProcess = true;
    this.data = {
      data     : {...this.multidisciplinary.value},
      updatedAt: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    };
    this.multiService.save(this.data).pipe(map((a) => {
      this.inProcess = false;
    })).subscribe();
    this.modalController.dismiss();
  }

  buildForm(): void {
    this.multidisciplinary = new FormGroup<ModelForm<any>>({
      patient: FormFieldsValidate.requiredMinLength(),
      age    : FormFieldsValidate.requiredMinLength(),
      data   : new FormGroup<ModelForm<any>>({
        record_id                : FormFieldsValidate.requiredMinLength(),
        episodio                 : FormFieldsValidate.requiredMinLength(),
        nombres                  : FormFieldsValidate.requiredMinLength(),
        apellidos                : FormFieldsValidate.requiredMinLength(),
        fecha_de_nacimiento      : FormFieldsValidate.requiredMinLength(),
        edad_year                : FormFieldsValidate.requiredMinLength(),
        edad_mm                  : FormFieldsValidate.requiredMinLength(),
        edad_dd                  : FormFieldsValidate.requiredMinLength(),
        sexo                     : FormFieldsValidate.requiredMinLength(),
        rut                      : FormFieldsValidate.requiredMinLength(),
        fecha_de_admision        : FormFieldsValidate.requiredMinLength(),
        medical_team             : FormFieldsValidate.requiredMinLength(),
        nurses_team              : FormFieldsValidate.requiredMinLength(),
        nutrition                : FormFieldsValidate.requiredMinLength(),
        destination_departure    : FormFieldsValidate.requiredMinLength(1, 'Traslado a otro'),
        kinesiology              : FormFieldsValidate.requiredMinLength(),
        c3                       : FormFieldsValidate.requiredMinLength(),
        estimated_date           : FormFieldsValidate.requiredMinLength(1, this.currentDate),
        other                    : FormFieldsValidate.requiredMinLength(),
        observations_egress      : FormFieldsValidate.requiredMinLength(),
        observations_interconsult: FormFieldsValidate.requiredMinLength(),
        exams_result             : FormFieldsValidate.requiredMinLength(),
        support                  : FormFieldsValidate.requiredMinLength(1, false),
        GES                      : FormFieldsValidate.requiredMinLength(1, false),
        exams                    : FormFieldsValidate.requiredMinLength(1, false),
        interconsult             : FormFieldsValidate.requiredMinLength(1, false),
        egress                   : FormFieldsValidate.requiredMinLength(1, false),
        PatGes                   : FormFieldsValidate.requiredMinLength(),
        rut_medico               : FormFieldsValidate.requiredMinLength(),
        epicrisis_status         : FormFieldsValidate.requiredMinLength(),
        centro_admision          : FormFieldsValidate.requiredMinLength(),
        localizacion             : FormFieldsValidate.requiredMinLength(),
        servicio                 : FormFieldsValidate.requiredMinLength(),
        descripcion_localizacion : FormFieldsValidate.requiredMinLength(),
        seguro_de_salud          : FormFieldsValidate.requiredMinLength(),
        admisiones_complete      : FormFieldsValidate.requiredMinLength(),
        fecha_de_traslado        : FormFieldsValidate.requiredMinLength(),
        fecha_de_admision2       : FormFieldsValidate.requiredMinLength(),
        usuario_realiza_evento   : FormFieldsValidate.requiredMinLength(),
        punto_de_atencion        : FormFieldsValidate.requiredMinLength(),
        sala                     : FormFieldsValidate.requiredMinLength(),
        cama                     : FormFieldsValidate.requiredMinLength(),
        centro                   : FormFieldsValidate.requiredMinLength(),
        servicio_hospital        : FormFieldsValidate.requiredMinLength(),
        dias_hospital            : FormFieldsValidate.requiredMinLength(),
        dias_servicio            : FormFieldsValidate.requiredMinLength(),
        traslado_complete        : FormFieldsValidate.requiredMinLength(),
        usuario_alta             : FormFieldsValidate.requiredMinLength(),
        nombre_usuario           : FormFieldsValidate.requiredMinLength(),
        rut_usuario              : FormFieldsValidate.requiredMinLength(),
        medico_alta              : FormFieldsValidate.requiredMinLength(),
        servicio_alta            : FormFieldsValidate.requiredMinLength(),
        fecha_de_admision3       : FormFieldsValidate.requiredMinLength(),
        fecha_de_alta            : FormFieldsValidate.requiredMinLength(),
        alta_medica              : FormFieldsValidate.requiredMinLength(),
        fecha_alta_medica        : FormFieldsValidate.requiredMinLength(),
        alta_complete            : FormFieldsValidate.requiredMinLength(),
        entra_a_grd              : FormFieldsValidate.requiredMinLength(),
        entra_a_grd_complete     : FormFieldsValidate.requiredMinLength(),
        codigo_cie_10            : FormFieldsValidate.requiredMinLength(),
        diagnostico              : FormFieldsValidate.requiredMinLength(),
        centro_grd               : FormFieldsValidate.requiredMinLength(),
        descripcion_del_centro   : FormFieldsValidate.requiredMinLength(),
        codificacin_grd_complete : FormFieldsValidate.requiredMinLength(),
      }),
      status : FormFieldsValidate.requiredMinLength(1, 'modificado'),
      actions: FormFieldsValidate.requiredMinLength(1, ['Editar', 'Closed', 'Open']),
    });
  }
}
