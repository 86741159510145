import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SignatureComponent} from '@components/signature/signature.component';
import {AlertController, ModalController, ToastController} from '@ionic/angular';
import {ROOT_ROUTES} from '../../app-routing.module';
import {CLINICAL_ROUTES} from '@clinical/clinical-routes.module';

export const HEADER_INFO = 'info';
export const HEADER_DANGER = 'danger';
export const HEADER_DEFAULT = 'default';
export const HEADER_SUCCESS = 'success';
export const HEADER_WARNING = 'warning';
export const HEADER_REMINDER = 'reminder';

const ALERT_OPTIONS = [
  {alert: HEADER_DEFAULT, icon: '', cssClass: ''},
  {alert: HEADER_DANGER, header: 'Error', icon: 'close-sharp', cssClass: 'app-prompt-danger'},
  {alert: HEADER_SUCCESS, header: 'Éxito', icon: 'checkmark-sharp', cssClass: 'app-prompt-success'},
  {alert: HEADER_INFO, header: 'Información', icon: 'information-sharp', cssClass: 'app-prompt-info'},
  {alert: HEADER_WARNING, header: 'Advertencia', icon: 'alert-sharp', cssClass: 'app-prompt-warning'},
  {alert: HEADER_REMINDER, header: 'Recordatorio', icon: 'alert-sharp', cssClass: 'app-prompt-warning'},
];

@Injectable({
  providedIn: 'root',
})
export class UiService {
  isLoading: boolean;
  currentDatetime = new Date();

  constructor(
    private alertController: AlertController,
    private toastController: ToastController,
    private router: Router,
    public modalController: ModalController,
  ) {
    this.clock();
  }

  clock() {
    setInterval(() => {
      this.currentDatetime = new Date();
    }, 1000);
  }

  async presentAlert(alertHeader: string, message: string, buttons: any[] = []): Promise<void> {
    const {header, cssClass} = ALERT_OPTIONS.find((option) => option.alert === alertHeader);
    const alert = await this.alertController.create({
      header,
      message,
      buttons,
      cssClass,
    });
    await alert.present();
  }

  async alertTemporalPatient() {
    const message = `<p>Paciente temporal no tiene permitido realizar esta acción.</p>`;
    const buttons = [
      {
        text: 'Cerrar',
        role: 'cancel',
        cssClass: 'secondary',
        id: 'cancel-button',
      },
    ];
    this.presentAlert('warning', message, buttons);
  }

  async infoPatientNotSet() {
    const message = 'Favor ingresar el rut de un paciente.';
    const {header} = ALERT_OPTIONS.find((option) => option.alert === 'info');
    const alert = await this.alertController.create({
      header,
      message,
      buttons: [{text: 'OK'}],
    });
    await alert.present();
  }

  async toast(message: string, alert: string = 'default', color: string, duration: number = 0): Promise<void> {
    const {icon, cssClass} = ALERT_OPTIONS.find((option) => option.alert === alert);

    const toast = await this.toastController.create({
      message,
      cssClass,
      color,
      position: 'middle',
      duration,
      buttons: [
        {
          side: 'start',
          icon,
        },
        {
          text: 'Volver',
          role: 'cancel',
        },
      ],
    });
    toast.present();
  }

  async presentToast(message: string): Promise<void> {


    const toast = await this.toastController.create({
      message,
      cssClass: 'app-prompt-success-toast',
      translucent: true,
      color: "light",
      header: 'Se han actualizado los datos del paciente.',
      position: 'middle',
      duration: 5000,
      icon: 'information-outline',
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
        },
      ],
    });
    toast.present();
  }

  dismiss() {
    this.modalController.dismiss();
  }

  noSignModal(signature): void {
    const message = `<p>No tiene registrada una firma, ¿desea registrarla ahora?</p>`;
    const buttons = [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
        id: 'cancel-button',
        handler: () => {
          this.router.navigateByUrl(`/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.agenda}`);
        },
      },
      {
        text: 'Confirmar',
        cssClass: 'primary',
        handler: () => {
          this.modalController
            .create({component: SignatureComponent, cssClass: 'modal-md'})
            .then((modal) => modal.present());
        },
      },
    ];
    // if (!signature || !signature.isActive) this.presentAlert('reminder', message, buttons);
  }
}
