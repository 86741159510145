import {HttpErrorResponse} from '@angular/common/http';
import {Admission} from '@clinical/admissions/interfaces/admission.interface';
import {createAction, props} from '@ngrx/store';

/* Firma de Documentos Electronicos */
export const PUT_ADMISSION     = createAction(
    '[admision] PUT Formularios de Admision',
    props<{ valuesToSave: Admission }>(),
);
export const PUT_ADMISSION_OK  = createAction(
    '[admision] OK PUT Formularios de Admision',
    props<{ admission: Admission }>(),
);
export const PUT_ADMISSION_ERR = createAction(
    '[admision] ERR PUT Formularios de Admision',
    props<{ payload: HttpErrorResponse }>(),
);

export const LOAD_ADMISSIONS     = createAction(
    '[admision] Cargar Formularios de Admision',
    props<{ gsiMultiCondition1St: string }>(),
);
export const LOAD_ADMISSIONS_OK  = createAction(
    '[admision] OK Formularios de Admision',
    props<{ admissions: Admission[] }>(),
);
export const LOAD_ADMISSIONS_ERR = createAction(
    '[admision] ERR Formularios de Admision',
    props<{ payload: HttpErrorResponse }>(),
);

export const DELETE_ADMISSION     = createAction('[admisiones] DELETE Admision', props<{ admitionForms: Admission }>());
export const DELETE_ADMISSION_OK  = createAction(
    '[admision] OK DELETE Formulario de Admision',
    props<{ admission: Admission }>(),
);
export const DELETE_ADMISSION_ERR = createAction(
    '[admision] ERR DELETE Formulario de Admision',
    props<{ payload: HttpErrorResponse }>(),
);

export const SELECTED_ADMISSION = createAction(
    '[admision] SELECTED Formulario de Admision',
    props<{ selected: Admission; action: string }>(),
);
export const UNSELECT_ADMISSION = createAction('[admisiones] UNSELECT Admision');
export const CLEAR_ADMISSION    = createAction('[admisiones] CLEAR Admision');
