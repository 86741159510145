export const makeSignature = (info) => {
  const {currentPage, pageCount, paperwork, config} = info;
  const {footer, isPreview} = config;

  const SIGNATURE = 'data:image/png;base64,' + paperwork.professional.base64;
  const EMPTY_IMAGE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=';

  if (footer.signature.position === 'default') footer.signature.position = {x: 320, y: -10};
  const signatureDefinition = [
    {
      fontSize        : 10,
      absolutePosition: footer.signature.position,
      stack           : [
        {
          image    : isPreview ? EMPTY_IMAGE : SIGNATURE,
          alignment: 'center',
          maxHeight: isPreview ? 0 : 75,
          maxWidth : isPreview ? 0 : 150,
          width    : isPreview ? 0 : 150,
          // width           : !isPreview ? 1 : 200,

          absolutePosition: {
            x: footer.signature.position.x + 1,
            y: footer.signature.position.y - 65
          }
        },
        {canvas: [{type: 'line', x1: 40, y1: -12, x2: 230, y2: -12, lineWidth: 0.75}]},
        {text: `${paperwork.professional.names.toUpperCase()}`, alignment: 'center', margin: [0, 5, 0, 0]},
        {text: `${paperwork.professional.surnames.toUpperCase()}`, alignment: 'center'},
        {text: `${paperwork.professional.rut}`, alignment: 'center'},
      ],
    },
  ];
  const signatureDefinitionSpecialtyGesOnlyFirstPage = [
    {
      fontSize        : 10,
      absolutePosition: footer.signature.position,
      stack           : [
        {
          image           : isPreview ? EMPTY_IMAGE : SIGNATURE,
          alignment       : 'center',
          maxHeight       : isPreview ? 0 : 70,
          maxWidth        : isPreview ? 0 : 150,
          width           : isPreview ? 0 : 150,
          absolutePosition: {
            x: footer.signature.position.x + 1,
            y: footer.signature.position.y - 40
          }
        },
        {canvas: [{type: 'line', x1: 360, y1: 10, x2: 570, y2: 10, lineWidth: 1}]},
        {text: `INFORMÉ PROBLEMA SALUD GES`, alignment: 'center', margin: [0, 5, 0, 0], bold: true},
        {text: `(Firma de persona que notifica)`, alignment: 'center', fontSize: 7},
      ],
    },
    {
      fontSize        : 10,
      absolutePosition: {x: 300, y: -220},
      stack           : [
        {canvas: [{type: 'line', x1: 40, y1: 10, x2: 250, y2: 10, lineWidth: 1}]},
        {text: `TOMÉ CONOCIMIENTO*`, alignment: 'center', margin: [0, 5, 0, 0], bold: true},
        {text: `(Firma o huella digital del paciente o representante)`, alignment: 'center', fontSize: 7},
      ],
    },

  ];

  if (footer.signature.printed === 'all') return signatureDefinition;
  if (footer.signature.printed === 'first' && currentPage === 1) return signatureDefinition;
  if (footer.signature.printed === 'specialty_ges_only_first_page' && currentPage === 1) return signatureDefinitionSpecialtyGesOnlyFirstPage;
  if (footer.signature.printed === 'last' && currentPage === pageCount) return signatureDefinition;

  return [];
};
