export * from '@auth/store/auth.reducer';
export * from '@clinical/schedule/store/schedule.reducer';
export * from '@clinical/forms/store/clinical-form.reducer';
export * from '@clinical/indications/store/indication.reducer';
export * from '@clinical/medicines/store/clinical-recipe.reducer';
export * from '@clinical/admissions/store/clinical-admission.reducer';
export * from '@clinical/derivations/store/clinical-derivation.reducer';
export * from '@clinical/hospital/store/clinical-hospitalization.reducer';
export * from '@clinical/emergencies/store/clinical-emergencies.reducer';
export * from '@clinical/expertise/store/clinical-expertise.reducer';
export * from '@clinical/oncology/store/clinical-oncology.reducer';
export * from '@clinical/surgeries/store/surgery.reducer';
export * from '@clinical/educational/store/clinical-educational.reducer';
export * from '@clinical/reproductive/store/reproductive.reducer';
export * from '@clinical/medical-discharge/store/clinical-discharge.reducer';

export * from './storage.reducer';
export * from './sign.reducer';
export * from './notification.reducer';
export * from './ui.reducer';
export * from './paperwork.reducer';

export * from '@components/patient/store/patient.reducer';
export * from '@components/favorites/store/favorites.reducer';
export * from '@components/anamnesis/store/anamnesis.reducer';
export * from '@components/agenda/store/agenda.reducer';
export * from '../../pharmacyportal/store/recipe-pharm.reducer';
