/* eslint-disable max-len */
import { buildContentPdfEte } from '@clinical/forms/utils/build-pdf-ete';
import { buildContentPdfGes } from '@clinical/forms/utils/build-pdf-ges';
import { buildContentPdfPad } from '@clinical/forms/utils/build-pdf-pad';
import { buildContentPdfPnac } from '@clinical/forms/utils/build-pdf-pnac';
import { buildContentPdfSpecialVaccines } from '@clinical/forms/utils/build-pdf-special-vaccines';
import { DEFAULT_PDF_POSITION_SIGNATURE } from '@shared/constants/app.const';
import { PrintedOnPage, PrintMode } from '@shared/interfaces/paperwork.interface';
import { DELETE_FORM, PUT_FORM } from '@store/actions';
import { buildContentPdfCertificate } from '../utils/build-pdf-certificate';
import { buildContentPdfConsent } from '../utils/build-pdf-consent';
import { buildContentPdfHospital } from '../utils/build-pdf-hospital';
import { buildContentPdfImageConsent } from '../utils/build-pdf-image-consent';
import { buildContentPdfAnesthesiaConsent } from '../utils/build-pdf-anesthesia-consent';
import { buildContentPdfBariatricSurgeryBonus } from '@clinical/surgeries/utils/build-pdf-bariatric-surgery-bonus';
import { FORMS_ROUTES } from '@clinical/forms/forms-routes.module';
import {buildContentPdfGes461} from '@clinical/forms/utils/build-pdf-ges-v461';

const workflow = {
  isSigned      : true,
  isStored      : true,
  isSaving      : true,
  hasNextControl: true,
  notifications: [
    {
      type    : 'email',
      entity  : 'patient',
      subject : 'Notificaciones UC CHRISTUS | Formulario Médico',
      template: 'generic',
    },
  ],
};

const pdf = {
  isPreview: true,
  header   : {logo: PrintedOnPage.all, title: PrintedOnPage.all, professional: PrintedOnPage.all},
  patient  : {type: PrintMode.full, printed: PrintedOnPage.all},
  footer   : {
    signature: {printed: PrintedOnPage.all, position: DEFAULT_PDF_POSITION_SIGNATURE},
    qr       : {right: {url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web'}},
  },
};

export const PAPERWORK_FORMS_DEFINITION = {
  prefix: 'FRM',
  module     : 'formularios',
  actions    : {
    put   : PUT_FORM,
    delete: DELETE_FORM,
  },
  certificate: {
    suffix           : 'FCM',
    group            : 'formulario certificado médico',
    docTitle         : 'Certificado Médico',
    submodule: FORMS_ROUTES.certificate,
    getBodyDefinition: buildContentPdfCertificate,
    workflow,
    config           : pdf,
  },
  consent: {
    suffix           : 'FDC',
    group            : 'formulario consentimiento informado',
    docTitle         :
        'Formulario Universal para Consentimiento Informado en Procedimientos ' +
        'Invasivos, Diagnósticos, Médicos, Odontológicos y Quirúrgicos',
    submodule: FORMS_ROUTES.consent,
    getBodyDefinition: buildContentPdfConsent,
    workflow,
    config           : {
      isPreview: true,
      header   : {logo: PrintedOnPage.all, title: PrintedOnPage.all, professional: PrintedOnPage.all},
      patient  : {type: PrintMode.full, printed: PrintedOnPage.all},
      footer   : {
        signature: {printed: PrintedOnPage.last, position: DEFAULT_PDF_POSITION_SIGNATURE},
        qr       : {right: {url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web'}},
      },
    },
  },

  ges: {
    suffix           : 'GES',
    group: 'notificación de patología ges',
    docTitle         : '\n\n\nFORMULARIO DE CONSTANCIA INFORMACIÓN AL PACIENTE GES \n (Artículo 24°, Ley 19.966)',
    submodule: FORMS_ROUTES.ges,
    getBodyDefinition: buildContentPdfGes461,
    workflow: {
      isSigned: true,
      isStored: true,
      isSaving: true,
      hasNextControl: true,
      notifications: [
        {
          type: 'email',
          entity: 'patient',
          subject: 'Notificaciones UC CHRISTUS | Formulario Médico',
          template: 'generic',
        },
        {
          type: 'email',
          entity: 'byCenter',
          subject: 'Notificaciones UC CHRISTUS | Formulario Médico',
          template: 'generic',
        },
      ],
    },
    config           : {
      isPreview: true,
      header   : {logo: PrintedOnPage.none, title: PrintedOnPage.none, professional: PrintedOnPage.none},
      patient  : {type: PrintMode.full, printed: PrintedOnPage.all},
      footer   : {
        signature: {printed: PrintedOnPage.specialtyGesOnlyFirstPage, position: {x:-300, y:-220}},
        qr       : null,
      },
    },
  },

  hospital: {
    suffix           : 'FAH',
    group            : 'formulario de ingreso médico hospitalario',
    docTitle         : 'Formulario de Ingreso Médico Hospitalario',
    submodule: FORMS_ROUTES.hospitalization,
    getBodyDefinition: buildContentPdfHospital,
    workflow: {
      isSigned: true,
      isStored: true,
      isSaving: true,
      hasNextControl: true,
      notifications: [
        {
          type: 'email',
          entity: 'patient',
          subject: 'Notificaciones UC CHRISTUS | Formulario Médico',
          template: 'generic',
        },
        {
          type: 'email',
          entity: 'budget',
          subject: 'Notificaciones UC CHRISTUS | Formulario Médico',
          template: 'generic',
        },
      ],
    },
    config           : pdf,
  },

  pad: {
    suffix           : 'PAD',
    group            : 'formulario interno inscripción pad fonasa',
    docTitle         : 'Formulario Interno Inscripción PAD Fonasa',
    submodule: FORMS_ROUTES.padFonasaRegistration,
    getBodyDefinition: buildContentPdfPad,
    workflow: {
      isSigned: true,
      isStored: true,
      isSaving: true,
      hasNextControl: true,
      notifications: [
        {
          type: 'email',
          entity: 'patient',
          subject: 'Notificaciones UC CHRISTUS | Formulario Médico',
          template: 'generic',
        },
        {
          type: 'email',
          entity: 'budget',
          subject: 'Notificaciones UC CHRISTUS | Formulario Médico',
          template: 'generic',
        },
      ],
    },
    config           : {
      isPreview: true,
      header   : {logo: PrintedOnPage.all, title: PrintedOnPage.all, professional: PrintedOnPage.none},
      patient  : {type: PrintMode.full, printed: PrintedOnPage.none},
      footer   : {
        signature: {printed: PrintedOnPage.all, position: DEFAULT_PDF_POSITION_SIGNATURE},
        qr       : {right: {url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web'}},
      },
    },
  },

  bariatricSurgeriesBonus: {
    suffix: 'BCB',
    group: 'PAD cirugía bariátrica',
    docTitle: 'bono pad cirugía bariátrica',
    submodule: FORMS_ROUTES.bariatric,
    getBodyDefinition: buildContentPdfBariatricSurgeryBonus,
    workflow: {
      isSigned: true,
      isStored: true,
      isSaving: true,
      hasNextControl: true,
      notifications: [
        {
          type: 'email',
          entity: 'patient',
          subject: 'Notificaciones UC CHRISTUS | PAD Cirugía Bariátrica',
          template: 'generic',
        },
      ],
    },
    config: {
      isPreview: true,
      header: { logo: PrintedOnPage.all, title: PrintedOnPage.all, professional: PrintedOnPage.all },
      patient: { type: PrintMode.full, printed: PrintedOnPage.all },
      footer: {
        signature: { printed: PrintedOnPage.last, position: DEFAULT_PDF_POSITION_SIGNATURE },
        qr: { right: { url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web' } },
      },
    },
  },

  imageconsent: {
    suffix           : 'FCI',
    group            : 'formulario de consentimiento informado para uso de imágenes',
    docTitle         : 'Consentimiento Informado para Uso de Imágenes',
    submodule: FORMS_ROUTES.imagesConsent,
    getBodyDefinition: buildContentPdfImageConsent,
    workflow,
    config: {
      ...pdf,
      footer: {
        signature: { printed: PrintedOnPage.last, position: DEFAULT_PDF_POSITION_SIGNATURE },
        qr: { right: { url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web' } },
      },
    },
  },

  anesthesiaconsent: {
    suffix: 'FCA',
    group: 'consentimiento anestesia',
    docTitle: 'formulario universal de consentimiento informado para actos anestésicos (sedación / anestesia)',
    submodule: FORMS_ROUTES.anesthesiaConsent,
    getBodyDefinition: buildContentPdfAnesthesiaConsent,
    workflow,
    config: {
      ...pdf,
      footer: {
        signature: { printed: PrintedOnPage.last, position: DEFAULT_PDF_POSITION_SIGNATURE },
        qr: { right: { url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web' } },
      },
    },
  },

  ete : {
    suffix           : 'ETE',
    group            : 'formulario de clasificación de riesgo de enfermedad tromboembólica (ete) para pacientes quirúrgicos',
    docTitle         : 'formulario de clasificación de riesgo de enfermedad tromboembólica (ete) para pacientes quirúrgicos',
    submodule: FORMS_ROUTES.ete,
    getBodyDefinition: buildContentPdfEte,
    workflow,
    config           : pdf,
  },

  pnac: {
    suffix           : 'PNC',
    group            : 'formulario de evaluación nutricional para beneficiarios de instituciones de salud previsional (niños con estado nutricional normal)',
    docTitle         : 'formulario de evaluación nutricional para beneficiarios de instituciones de salud previsional (niños con estado nutricional normal)',
    submodule: FORMS_ROUTES.pnac,
    getBodyDefinition: buildContentPdfPnac,
    workflow,
    config           : pdf,
  },
  specialVaccines: {
    suffix: 'VES',
    group: 'formulario de solicitud de vacunación especial',
    docTitle: 'formulario de solicitud de vacunación especial',
    submodule: FORMS_ROUTES.specialVaccines,
    getBodyDefinition: buildContentPdfSpecialVaccines,
    workflow,
    config: {
      ...pdf,
      footer: {
        signature: { printed: PrintedOnPage.last, position: DEFAULT_PDF_POSITION_SIGNATURE },
        qr: { right: { url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web' } },
      },
    },
  },
};
