import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ParamsToFindByPatient } from '@components/patient/interfaces/patient.interface';
import { Paperwork } from '@shared/interfaces/paperwork.interface';

/* Firma de Documentos Electronicos */
export const PUT_DISCHARGE_MEDICAL_END   = createAction('[epicrisis] Resetea el estado para crear una nueva solicitud');
export const PUT_DISCHARGE_MEDICAL = createAction('[epicrisis] PUT epicrisis', props<{ valuesToSave:  Paperwork<any> }>());//TODO: definir interface
export const PUT_DISCHARGE_MEDICAL_OK = createAction('[epicrisis] OK PUT epicrisis', props<{ discharge: any }>());
export const PUT_DISCHARGE_MEDICAL_ERR = createAction('[epicrisis] ERR PUT epicrisis', props<{ payload: HttpErrorResponse }>());

export const LOAD_DISCHARGE_MEDICAL_BY_PROFESSIONAL = createAction(
  '[epicrisis] Cargar documentos al alta por profesional',
  props<{ gsiMultiCondition1St: string }>(),
);
export const LOAD_DISCHARGE_MEDICAL_BY_PROFESSIONAL_OK = createAction(
  '[epicrisis] OK documentos al alta por profesional',
  props<{ discharge: Paperwork<any>[] }>(),
);
export const LOAD_DISCHARGE_MEDICAL_BY_PROFESSIONAL_ERR = createAction(
  '[epicrisis] ERR documentos al alta por profesional',
  props<{ payload: HttpErrorResponse }>(),
);

export const LOAD_DISCHARGE_MEDICAL_BY_PATIENT = createAction(
  '[epicrisis] documentos al alta por paciente',
  props<{ params: ParamsToFindByPatient }>(),
);
export const LOAD_DISCHARGE_MEDICAL_BY_PATIENT_OK = createAction(
  '[epicrisis] OK documentos al alta por paciente',
  props<{ discharge: Paperwork<any>[] }>(),
);
export const LOAD_DISCHARGE_MEDICAL_BY_PATIENT_ERR = createAction(
  '[epicrisis] ERR documentos al alta por paciente',
  props<{ payload: HttpErrorResponse }>(),
);

export const DELETE_DISCHARGE_MEDICAL = createAction('[epicrisis] DELETE documentos al alta', props<{ discharge: Paperwork<any> }>());
export const DELETE_DISCHARGE_MEDICAL_OK = createAction('[epicrisis] OK DELETE documentos al alta', props<{ discharge: Paperwork<any> }>());
export const DELETE_DISCHARGE_MEDICAL_ERR = createAction(
  '[epicrisis] ERR DELETE documentos al alta',
  props<{ payload: HttpErrorResponse }>(),
);

export const SELECTED_DISCHARGE_MEDICAL = createAction(
  '[epicrisis] SELECTED documentos al alta',
  props<{ selected: Paperwork<any>; action: string }>(),
);
export const UNSELECT_DISCHARGE_MEDICAL = createAction('[epicrisis] UNSELECT documentos al alta');
export const CLEAR_DISCHARGE_MEDICAL = createAction('[epicrisis] CLEAR documentos al alta');
