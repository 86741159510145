/* eslint-disable arrow-body-style */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {RequesScheduleService} from '@clinical/schedule/services/requestSchedule.service';
import {ActionsService} from '@components/records/services/actions.service';

import {Store} from '@ngrx/store';
import {ActionTableDef, PageTableDef, TableDefinition} from '@shared/interfaces/table.interface';
import {
  GET_EMERGENCIES_BY_PROFESSIONAL,
  LOAD_ADMISSIONS,
  LOAD_DERIVATIONS_BY_PROFESSIONAL,
  LOAD_FORMS_BY_PROFESSIONAL,
  LOAD_HOSPITALIZATIONS_BY_PROFESSIONAL,
  LOAD_INDICATIONS_BY_PROFESSIONAL,
  LOAD_ONCOLOGY_BY_PROFESSIONAL,
  LOAD_RECIPES_BY_PROFESSIONAL,
  LOAD_REPRODUCTIVE_BY_PROFESSIONAL,
  LOAD_SCHEDULE,
  LOAD_SCHEDULING,
  LOAD_SURGERIES_BY_PROFESSIONAL,
} from '@store/actions';
import {
  KEY_DERIVATIONS_STORE,
  KEY_EMERGENCIES_STORE,
  KEY_FORMS_STORE,
  KEY_HOSPITALIZATIONS_STORE,
  KEY_INDICATIONS_STORE,
  KEY_ONCOLOGY_STORE,
  KEY_RECIPES_STORE,
  KEY_REPRODUCTIVE_STORE,
  KEY_SURGERIES_STORE,
} from '@store/store-keys';

import {AppState} from '@store/app.reducers';

import {addDays, endOfMonth, format, getMonth, lastDayOfMonth, startOfMonth} from 'date-fns';
import {Subject, filter, map, mergeMap, takeUntil, tap} from 'rxjs';

import * as XLSX from 'xlsx';
import {Professional} from '@auth/interfaces/professional.interface';
import {SurgeryService} from '@clinical/surgeries/services/surgery.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() isLoading: boolean;
  @Input() source: any[];
  @Input() period: boolean;
  @Input() twoPeriod: boolean;
  @Input() export: any;
  @Input() actions: ActionTableDef[] = [];
  @Input() pageTable: PageTableDef;
  @Input() tableDef: TableDefinition[];
  @Input() sortState: Sort;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('TABLE') table: ElementRef;

  displayedColumns: string[];
  dataSource: MatTableDataSource<any>;
  monthYear = format(new Date(), 'yyyy-MM');
  dayMonthYear = format(new Date(), 'yyyy-MM-dd');
  destroy = new Subject();

  rangeDate = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
    profesional: new FormControl(),
  });

  constructor(
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    public actionsService: ActionsService,
    public requesScheduleService: RequesScheduleService,
    private clinicalSurgeryService: SurgeryService,
  ) {
  }

  ngOnInit() {
    this.buildRangeDateForm();
    this.displayedColumns = this.getDisplayedColumns(this.tableDef);
    this.dataSource = new MatTableDataSource(this.source);
    this.dataSource.filterPredicate = (data: any, filter: string): boolean => {
      const dataStr = Object.keys(data)
        .reduce((currentTerm: string, key: string) => {
          return currentTerm + (data as { [key: string]: any })[key];
        }, '')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      const transformedFilter = filter
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
    this.cdr.detectChanges();
  }

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource(this.source);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.sortState && this.sort) {
      this.dataSource.sort.active = this.sortState.active;
      this.dataSource.sort.direction = this.sortState.direction;
      this.dataSource.sort.sortChange.emit(this.sortState);
    }
  }

  ionViewDidLeave() {
    this.destroy.next('next');
    this.destroy.complete();
    localStorage.removeItem('resolutions');
  }

  ngOnDestroy(): void {
    this.destroy.next('next');
    this.destroy.complete();
    localStorage.removeItem('resolutions');
  }

  getDisplayedColumns(tableDef: TableDefinition[]): string[] {
    if (!tableDef) return [];
    const displayedColumns = tableDef.map(({td}) => td);
    if (this.actions.length) displayedColumns.push('actions');
    return displayedColumns;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.cdr.detectChanges();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
  }


  changePeriod(event: any = null) {
    this.isLoading = true;
    const params = {gsiMultiCondition1St: ''};
    let filterValue;

    if (this.pageTable.area !== 'QUI') {
      filterValue = (event.target as HTMLInputElement).value;

      params.gsiMultiCondition1St = `${this.pageTable.area}#DTE#${filterValue.replace('-', '')}#PRO#${
        this.pageTable.professionalId
      }`;
    }
    switch (this.pageTable.area) {
      case 'FAH':
        this.store.dispatch(
          LOAD_ADMISSIONS({
            gsiMultiCondition1St: `HOS#DTE#${filterValue.replace('-', '')}#PRO#${
              this.pageTable.professionalId === undefined ? '' : this.pageTable.professionalId
            }`,
          }),
        );
        this.store
          .select('admissions')
          .pipe(takeUntil(this.destroy))
          .subscribe(({isLoading}) => (this.isLoading = isLoading));
        break;
      case 'AGN':
        const formatFirstDate = 'yyyy-MM-01';
        const today = new Date(filterValue + '-10');
        const dateFrom = format(today, formatFirstDate);
        const dateTo = format(lastDayOfMonth(today), 'yyyy-MM-dd');
        localStorage.setItem('resolutions', JSON.stringify({dateFrom, dateTo}))
        const param = {professionalId: `${this.pageTable.professionalId}?dateFrom=${dateFrom}&dateTo=${dateTo}`};
        this.store.dispatch(LOAD_SCHEDULING({...param}));
        this.store.dispatch(LOAD_SCHEDULE({...param}));
        this.store
          .select('schedule')
          .pipe(takeUntil(this.destroy))
          .subscribe(({isLoading}) => (this.isLoading = isLoading));
        break;
      case 'FRM':
        this.store.dispatch(LOAD_FORMS_BY_PROFESSIONAL({...params}));
        this.store
          .select('forms')
          .pipe(takeUntil(this.destroy))
          .subscribe(({isLoading}) => (this.isLoading = isLoading));
        break;
      case 'SUR':
        this.store.dispatch(LOAD_SURGERIES_BY_PROFESSIONAL({...params}));
        this.store
          .select('surgeries')
          .pipe(takeUntil(this.destroy))
          .subscribe(({isLoading}) => (this.isLoading = isLoading));
        break;
      case 'IND':
        this.store.dispatch(LOAD_INDICATIONS_BY_PROFESSIONAL({...params}));
        this.store
          .select('indications')
          .pipe(takeUntil(this.destroy))
          .subscribe(({isLoading}) => (this.isLoading = isLoading));
        break;
      case 'DER':
        this.store.dispatch(LOAD_DERIVATIONS_BY_PROFESSIONAL({...params}));
        this.store
          .select('derivations')
          .pipe(takeUntil(this.destroy))
          .subscribe(({isLoading}) => (this.isLoading = isLoading));
        break;
      case 'HOS':
        this.store.dispatch(LOAD_HOSPITALIZATIONS_BY_PROFESSIONAL({...params}));
        this.store
          .select('hospitalizations')
          .pipe(takeUntil(this.destroy))
          .subscribe(({isLoading}) => (this.isLoading = isLoading));
        break;
      case 'URG':
        this.store.dispatch(
          GET_EMERGENCIES_BY_PROFESSIONAL({
            gsiMultiCondition1St: `${this.pageTable.area}#DTE#${filterValue.replace('-', '')}`,
          }),
        );
        this.store
          .select('emergencies')
          .pipe(takeUntil(this.destroy))
          .subscribe(({isLoading}) => (this.isLoading = isLoading));
        break;
      case 'RME':
        this.store.dispatch(LOAD_RECIPES_BY_PROFESSIONAL({...params}));
        this.store
          .select('recipes')
          .pipe(takeUntil(this.destroy))
          .subscribe(({isLoading}) => (this.isLoading = isLoading));
        break;
      case 'ONC':
        this.store.dispatch(LOAD_ONCOLOGY_BY_PROFESSIONAL({...params}));
        this.store
          .select('oncology')
          .pipe(takeUntil(this.destroy))
          .subscribe(({isLoading}) => (this.isLoading = isLoading));
        break;
      case 'MRE':
        this.store.dispatch(LOAD_REPRODUCTIVE_BY_PROFESSIONAL({...params}));
        this.store
          .select('reproductive')
          .pipe(takeUntil(this.destroy))
          .subscribe(({isLoading}) => (this.isLoading = isLoading));
        break;
      case 'QUI':
        this.store
          .select('professional')
          .pipe(takeUntil(this.destroy),
            filter(({isLoaded}) => isLoaded),
            map(({professional}) => this.setParamsToFindSurgicalData(professional)),
            mergeMap((body) => this.clinicalSurgeryService.findDataTable(body)),
            tap((res) => this.dataSource.data = res),
            tap(() => this.isLoading = false),
          ).subscribe();
        break;
      default:
        break;
    }
  }

  setParamsToFindSurgicalData(professional: Professional) {
    return {
      start: format(addDays(new Date(this.rangeDate.value.start), 1), 'dd/MM/yyyy'),
      end: format(addDays(new Date(this.rangeDate.value.end), 1), 'dd/MM/yyyy'),
      rut: professional.rut,
    };
  }

  scrollUp() {
    const objDiv = document.getElementById('scroll');
    objDiv.scrollTop = objDiv.scrollTop - 360;
  }

  scrollDown() {
    const objDiv = document.getElementById('scroll');
    objDiv.scrollTop = objDiv.scrollTop + 360;
  }

  getSpecialty(specialties) {
    if (!specialties) return;
    if (specialties.name) return specialties.name;
    if (specialties.length > 0) return specialties.filter(Boolean);
  }

  exportAsExcel() {
    const {newData, name} = this.requesScheduleService.formatStatusExportExcel(this.export);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newData); //converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `${name}`);
    XLSX.writeFile(wb, `${name}-${this.dayMonthYear}.xlsx`);
  }

  buildRangeDateForm() {
    this.rangeDate = this.formBuilder.group({
      start: new FormControl(format(startOfMonth(new Date()), 'yyyy-MM-dd')),
      end: new FormControl(format(endOfMonth(new Date()), 'yyyy-MM-dd')),
      profesional: new FormControl(),
    });
  }
}
