import {patientTopCard} from '@shared/helpers/pdf-patient-card.helper';

export const buildContentPdfHospital = (paperwork, group) => {
  const {rows} = group;
  return rows.map((hospitalAdmition) => {
    const content = {
      ...patientTopCard(paperwork),
      fontSize: 10,
      margin: [0, 5, 0, 0],
      stack: [
        {text: `Ingreso Médico Hospitalario`, bold: true, fontSize: 12},
        {canvas: [{type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1}], margin: [0, 0, 0, 10]},
        {
          columns: [
            {text: `Anamnesia:`, width: '50%'},
            {text: `${hospitalAdmition.anamnesis || ''}`, width: '50%'},
          ],
        },
        {
          columns: [
            {text: `Revisión por sistemas:`, width: '50%'},
            {text: `${hospitalAdmition.reviewSystems || ''}`, width: '50%'},
          ],
        },
        {text: `Antecendentes Personales`, bold: true, fontSize: 12, margin: [0, 10, 0, 0]},
        {canvas: [{type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1}], margin: [0, 0, 0, 10]},
        {
          columns: [
            {text: `Médicos:`, width: '50%'},
            {text: `${hospitalAdmition.personalHistory.doctors || ''}`, width: '50%'},
          ],
        },
        {
          columns: [
            {text: `Quirúrgicos:`, width: '50%'},
            {text: `${hospitalAdmition.personalHistory.surgical || ''}`, width: '50%'},
          ],
        },
        {
          columns: [
            {text: `Gíneco-obstétricos:`, width: '50%'},
            {text: `${hospitalAdmition.personalHistory.obstetrics || ''}`, width: '50%'},
          ],
        },
        {
          columns: [
            {text: `Hábitos:`, width: '50%'},
            {
              table: {
                headerRows: 1,
                widths: ['*', 30, 30],
                body: [
                  [
                    '',
                    {text: 'SI', alignment: 'center', bold: true},
                    {text: 'NO', alignment: 'center', bold: true},
                  ],
                  [
                    'Tabaco',
                    {
                      text: `${hospitalAdmition.personalHistory?.habits && hospitalAdmition.personalHistory?.habits?.includes('tabaco') ? 'X' : ''}`,
                      alignment: 'center',
                      bold: true,
                    },
                    {
                      text: `${hospitalAdmition.personalHistory?.habits && hospitalAdmition.personalHistory?.habits.includes('tabaco') ? '' : 'X'}`,
                      alignment: 'center',
                      bold: true,
                    },
                  ],
                  [
                    'Drogas',
                    {
                      text: `${hospitalAdmition.personalHistory?.habits && hospitalAdmition.personalHistory?.habits.includes('drogas') ? 'X' : ''}`,
                      alignment: 'center',
                      bold: true,
                    },
                    {
                      text: `${hospitalAdmition.personalHistory?.habits && hospitalAdmition.personalHistory?.habits.includes('drogas') ? '' : 'X'}`,
                      alignment: 'center',
                      bold: true,
                    },
                  ],
                  [
                    'OH',
                    {
                      text: `${hospitalAdmition.personalHistory?.habits && hospitalAdmition.personalHistory?.habits.includes('oh') ? 'X' : ''}`,
                      alignment: 'center',
                      bold: true,
                    },
                    {
                      text: `${hospitalAdmition.personalHistory?.habits && hospitalAdmition.personalHistory?.habits.includes('oh') ? '' : 'X'}`,
                      alignment: 'center',
                      bold: true,
                    },
                  ],
                  [
                    'Actividad Física',
                    {
                      text: `${hospitalAdmition.personalHistory?.habits && hospitalAdmition.personalHistory?.habits.includes('actividad') ? 'X' : ''}`,
                      alignment: 'center',
                      bold: true,
                    },
                    {
                      text: `${hospitalAdmition.personalHistory?.habits && hospitalAdmition.personalHistory?.habits.includes('actividad') ? '' : 'X'}`,
                      alignment: 'center',
                      bold: true,
                    },
                  ],
                ],
              },
            },
            // { text: `${hospitalAdmition.personalHistory.habits}`, width: '50%' },
          ],
          margin: [0, 0, 0, 5],
        },
        {
          columns: [
            {text: `Medicamentos:`, width: '50%'},
            {
              text: `${hospitalAdmition.personalHistory.medicines || ''}`,
              width: '50%'
            },
          ],
        },
        {
          columns: [
            {text: `Alergias:`, width: '50%'},
            {
              text: `${hospitalAdmition.personalHistory.allergies || ''}`,
              width: '50%'
            },
          ],
        },
        {
          columns: [
            {text: `Sociales:`, width: '50%'},
            {text: `${hospitalAdmition.personalHistory.social || ''}`, width: '50%'},
          ],
        },
        {text: `Antecedentes Familiares`, bold: true, fontSize: 12, margin: [0, 10, 0, 0]},
        {canvas: [{type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1}], margin: [0, 0, 0, 10]},

        {
          columns: [
            {text: `Transfondo Familiar:`, width: '50%'},
            {text: `${hospitalAdmition.personalHistory.familyBackground || ''}`, width: '50%'},
          ],
        },
        {text: `Físico`, bold: true, fontSize: 12, pageBreak: 'before', margin: [0, 10, 0, 0]},
        {canvas: [{type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1}], margin: [0, 0, 0, 10]},
        {text: `EXAMEN FÍSICO GENERAL:`, margin: [0, 0, 0, 5]},
        {
          columns: [
            {text: `Aspecto General:`, width: '50%'},
            {text: `${hospitalAdmition.physicalExam.generalAspect || ''}`, width: '50%'},
          ],
        },
        {
          columns: [
            {text: `Posición y decúbito:`, width: '50%'},
            {text: `${hospitalAdmition.physicalExam.positionDecubitus || ''}`, width: '50%'},
          ],
        },
        {
          columns: [
            {text: `Conciencia y estado psíquico:`, width: '50%'},
            {text: `${hospitalAdmition.physicalExam.psychicState || ''}`, width: '50%'},
          ],
        },
        {
          columns: [
            {text: `FC (x'):`, width: '7%'},
            {text: `${hospitalAdmition.physicalExam.heartRate || ''}`, width: '3%', bold: true},
            {text: `PA (mmHg):`, width: '12%'},
            {
              text: `${hospitalAdmition.physicalExam.bloodPressureDiastolic || ''}/${hospitalAdmition.physicalExam.bloodPressureSystolic || ''}`,
              width: '3%',
              bold: true,
            },
            {text: `FR (x'):`, width: '7%'},
            {text: `${hospitalAdmition.physicalExam.breathingFrequency || ''}`, width: '3%', bold: true},
            {text: `Saturación O2 (%):`, width: '16%'},
            {text: `${hospitalAdmition.physicalExam.saturation || ''}`, width: '3%', bold: true},
            {text: `T (°C):`, width: '7%'},
            {text: `${hospitalAdmition.physicalExam.temperature || ''}`, width: '3%', bold: true},
          ],
        },
        {
          columns: [
            {text: `Peso (kg):`, width: '10%'},
            {text: `${hospitalAdmition.physicalExam.weight || ''}`, width: '3%', bold: true},
            {text: `Talla (cm):`, width: '10%'},
            {text: `${hospitalAdmition.physicalExam.size || ''}`, width: '3%', bold: true},
            {text: `IMC:`, width: '7%'},
            {text: `${hospitalAdmition.physicalExam.bmi || ''}`, width: '3%', bold: true},
            {text: `Dolor (0-10):`, width: '14%'},
            {text: `${hospitalAdmition.physicalExam.pain || ''}`, width: '3%', bold: true},
          ],
        },
        {
          columns: [
            {text: `Piel y mucosas:`, width: '50%'},
            {text: `${hospitalAdmition.physicalExam.skinMucous || ''}`, width: '50%'},
          ],
        },
        {
          columns: [
            {text: `Adenopatías:`, width: '50%'},
            {text: `${hospitalAdmition.physicalExam.lymphadenopathy || ''}`, width: '50%'},
          ],
        },
        {text: `EXAMEN FÍSICO SEGMENTARIO:`, margin: [0, 10, 0, 5]},
        {
          columns: [
            {text: `Cabeza:`, width: '50%'},
            {text: `${hospitalAdmition.segmentalPhysicalExam.head || ''}`, width: '50%'},
          ],
        },
        {
          columns: [
            {text: `Cuello:`, width: '50%'},
            {text: `${hospitalAdmition.segmentalPhysicalExam.neck || ''}`, width: '50%'},
          ],
        },
        {
          columns: [
            {text: `Tórax:`, width: '50%'},
            {text: `${hospitalAdmition.segmentalPhysicalExam.chest || ''}`, width: '50%'},
          ],
        },
        {
          columns: [
            {text: `Abdomen y Pelvis:`, width: '50%'},
            {text: `${hospitalAdmition.segmentalPhysicalExam.abdomenPelvis || ''}`, width: '50%'},
          ],
        },
        {
          columns: [
            {text: `Columna y Extremidades:`, width: '50%'},
            {text: `${hospitalAdmition.segmentalPhysicalExam.spineLimbs || ''}`, width: '50%'},
          ],
        },
        {
          columns: [
            {text: `Neurológico:`, width: '50%'},
            {text: `${hospitalAdmition.segmentalPhysicalExam.neurological || ''}`, width: '50%'},
          ],
        },
        {
          columns: [
            {text: `Otros:`, width: '50%'},
            {text: `${hospitalAdmition.others || ''}`, width: '50%'},
          ],
        },
        {text: `DIAGNÓSTICO DE INGRESO:`, margin: [0, 5, 0, 5]},
      ],
    };
    for (const item of hospitalAdmition.diagnosis) content.stack.push({text: `${item || ''}`, margin: [5, 0, 0, 0]});

    content.stack.push({
      columns: [
        {text: `PLAN DE TRATAMIENTO:`, width: '50%'},
        {text: `${hospitalAdmition.studyPlan || ''}`, width: '50%'},
      ],
      margin: [0, 5, 0, 0],
    });
    return [...patientTopCard(paperwork), content];
  });
};
