import { Injectable } from '@angular/core';
import format from 'date-fns/format';
import { environment } from '@environments/environment';
import { Paperwork } from '@shared/interfaces/paperwork.interface';

@Injectable({
  providedIn: 'root',
})
export class BuildMailNextControlService {

  nextControlBaseSchedule: string = environment.nextControlBaseSchedule;

  constructor() {
  }

  nextControlRawMail(params: Paperwork<any>) {
    const {professional} = params;
    const nextControl = params.nextControl?.nextControl;
    if (!nextControl) return;

    const patient = { ...params?.patient, ...params.nextControl?.patient };
    const title       = 'Próximo control';
    const template    = 'generic';
    const to          = [patient.email];
    const dateControl = new Date(nextControl.date);
    const subject     = 'Notificaciones UC CHRISTUS | ' + title;
    const greeting    = `Estimado(a) ${patient.names.toUpperCase()} ${patient.surnames.toUpperCase()}`;

    const url = `${this.nextControlBaseSchedule}/${professional.id}/${
      nextControl.area.id
    }/${format(dateControl, 'yyyy-MM-dd')}T00:00:00.000Z/${patient.id}/${patient.documentNumber}/${
      nextControl.specialty.id
    }/${patient.documentCountry}/${patient.documentType}`;
    const message     = `
  <p> Su médico tratante <b>${professional.names.toUpperCase()} ${professional.surnames.toUpperCase()}</b>,
  le ha indicado un control de <b>${nextControl.specialty.name}</b>, para el
  <b>${format(dateControl, 'dd-MM-yyyy')}</b>. Para reservar, haga clic en Agende su Control</p>
  <br/>
  <a style=" width: 200px; font-weight:bold; text-align:center; text-decoration:none; margin-top:30px;
  margin-bottom:50px; margin-left:auto; margin-right:auto; background-color:#307fe2; color:#ffffff;
  padding:14px; font-size: 14px; border-radius:4px; display:block" target="_blank"
  href="${url}">Agende su Control</a>`;

    const rawMail = {subject, to, greeting, message, template, attachments: []};
    return rawMail;
  }
}
