import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChatbotService {
  constructor() {}

  loadScript({ id, url }) {
    return new Promise((resolve, reject) => {
      if (id && document.getElementById(id)) resolve({ id, loaded: true, status: 'Already Loaded' });

      const body = document.body;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = '';
      script.src = url;
      script.id = id;
      script.onload = () => {
        resolve({ id, loaded: true, status: 'Loaded' });
      };
      script.onerror = (error: any) => resolve({ id, loaded: false, status: 'Loaded' });
      script.async = true;
      script.defer = true;
      body.appendChild(script);
    });
  }

  removeScript(id: string) {
    const script = document.getElementById(id);
    if (script) script.remove();
  }
}
