export const PHYSICAL_EXAMINATION = {
  names: [
    'Piel',
    'Mucosas',
    'Cabeza',
    'Faringe',
    'Otoscopia',
    'Cuello',
    'Extremidades',
    'Tórax',
    'Pulmones',
    'Corazón',
    'Abdomen',
    'Genitales',
    'Columna',
    'Neurológico',
    'Otro',
  ],
};
