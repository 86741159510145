import {HttpErrorResponse} from '@angular/common/http';

import {Professional} from '@auth/interfaces/professional.interface';
import {Action, createReducer, on} from '@ngrx/store';
import * as authActions from '../../store/actions';


export interface AuthState {
  professional: Professional;
  subprofessional: Professional;
  isLoaded: boolean;
  isLoading: boolean;
  error: HttpErrorResponse;
}

export const authState: AuthState = {
  error          : null,
  isLoaded       : false,
  isLoading      : false,
  professional   : null,
  subprofessional: null,
};

const createAuthReducer = createReducer(
  authState,

  on(authActions.AUTH_LOGIN, (state) => ({...state, isLoading: true})),

  on(authActions.AUTH_LOGIN_OK, (state, {professional}) => ({
    ...state,
    professional: {...professional},
  })),

  on(authActions.AUTH_LOGIN_ERR, (state, {payload}) => ({
    ...state,
    isLoading      : false,
    isLoaded       : false,
    professional   : null,
    subprofessional: null,
    error          : payload,
  })),

  on(authActions.AUTH_SET_TOKEN, (state) => ({...state, isLoading: true})),

  on(authActions.AUTH_SET_TOKEN_OK, (state) => ({
    ...state,
    isLoading: false,
    isLoaded : true,
  })),

  on(authActions.AUTH_SET_TOKEN_ERR, (state, {payload}) => ({
    ...state,
    isLoading      : false,
    isLoaded       : false,
    professional   : null,
    subprofessional: null,
    error          : payload,
  })),

  on(authActions.AUTH_REFRESH, (state) => ({...state, isLoading: true})),

  on(authActions.AUTH_REFRESH_OK, (state, {professional}) => ({
    ...state,
    isLoading   : false,
    isLoaded    : true,
    professional: {...professional},
  })),

  on(authActions.AUTH_REFRESH_ERR, (state, {payload}) => ({
    ...state,
    isLoading      : false,
    isLoaded       : false,
    professional   : null,
    subprofessional: null,
    error          : payload,
  })),

  on(authActions.AUTH_LOGOUT, (state) => ({...state})),
  on(authActions.AUTH_LOGOUT_OK, (state) => ({
    ...state,
    isLoading   : false,
    isLoaded    : false,
    professional: null,
    error       : null,
  })),

  on(authActions.AUTHL_LOGOUT_ERR, (state, {payload}) => ({
    ...state,
    isLoaded : false,
    isLoading: false,
    error    : payload,
  })),

  on(authActions.LOAD_SIGNATURE_OK, (state, {base64}) => {

    const signature = {...state?.professional?.signature, base64};
    return {
      ...state,
      professional: {
        ...state.professional,
        signature
      },
      isLoading   : false,
    };
  }),

  on(authActions.LOAD_SIGNATURE_ERR, (state, {payload}) => ({
    ...state,
    isLoaded : false,
    isLoading: false,
    error    : payload,
  })),

  on(authActions.PUT_PROFESSIONAL, (state) => ({...state, isLoading: true})),
  on(authActions.PUT_PROFESSIONAL_OK, (state, {professional}) => ({
    ...state,
    isLoading   : false,
    isLoaded    : true,
    error       : null,
    professional: {...professional},
  })),

  on(authActions.PUT_PROFESSIONAL_ERR, (state, {payload}) => ({
    ...state,
    isLoading   : false,
    isLoaded    : false,
    professional: {...state.professional},
    error       : payload,
  })),

  on(authActions.PUT_PROFESSIONAL_BY_TENS, (state, {professional}) => {
    const access = [...state.professional.access];
    const prfsnl = {...professional, access};
    return {
      ...state,
      isLoading      : false,
      isLoaded       : true,
      error          : null,
      professional   : {...prfsnl},
      subprofessional: {...state.professional},
    };
  }),
);

export const authReducer = (state: AuthState, action: Action) => createAuthReducer(state, action);
