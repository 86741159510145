import { Component, Input, OnInit } from '@angular/core';
import { Pdf } from '@shared/helpers/pdf-wrapper.helper';
import { ModalController } from '@ionic/angular';
import { StorageService } from '@shared/services/storage.service';
@Component({
  selector: 'app-visualize-doc-google-docs',
  templateUrl: './visualize-doc-google-docs.component.html',
  styleUrls: ['./visualize-doc-google-docs.component.scss'],
})
export class VisualizeDocGoogleDocsComponent implements OnInit {
  @Input() data: any;
  type: string;
  isReady: boolean;
  constructor(private pdf: Pdf, private modalController: ModalController, public storageService: StorageService) {}

  ngOnInit() {}
  dismiss() {
    this.modalController.dismiss();
    this.isReady = false;
  }
}
