/* eslint-disable max-len */
import {Injectable} from '@angular/core';

import {of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {QuotationService} from '../services/quotation.service';
import {
  LOAD_DELIVERY_BY_RECIPE,
  LOAD_DELIVERY_BY_RECIPE_ERR,
  LOAD_DELIVERY_BY_RECIPE_OK,
} from './recipe-pharm.actions';

@Injectable()
export class DeliveryEffects {
  loadRecipesPharmByPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_DELIVERY_BY_RECIPE),
      mergeMap(({ params }) =>
        this.quotationService.getComprobante(params).pipe(
          map((voucherRequest) => LOAD_DELIVERY_BY_RECIPE_OK({ requestData: voucherRequest })),
          catchError((err) => of(LOAD_DELIVERY_BY_RECIPE_ERR({ payload: err }))),
        ),
      ),
    ),
  );
  constructor(private actions$: Actions, private quotationService: QuotationService) {}
}
