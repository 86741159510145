import {HttpErrorResponse} from '@angular/common/http';
import {UserCredential} from '@auth/interfaces/credentials.interface';
import {Professional, Signature} from '@auth/interfaces/professional.interface';
import {createAction, props} from '@ngrx/store';

export const AUTH_LOGIN = createAction('[auth] Autenticación', props<{ credentials: UserCredential }>());
export const AUTH_LOGIN_OK = createAction('[auth] OK Autenticación', props<{ professional: Professional }>());
export const AUTH_LOGIN_ERR = createAction('[auth] ERR Autenticación', props<{ payload: HttpErrorResponse }>());

export const AUTH_SET_TOKEN = createAction('[auth] Almacenar Token', props<{ professional: Professional }>());
export const AUTH_SET_TOKEN_OK = createAction('[auth] OK Almacenar Token');
export const AUTH_SET_TOKEN_ERR = createAction('[auth] ERR Almacenar Token', props<{ payload: HttpErrorResponse }>());


export const AUTH_REFRESH = createAction('[auth] Refrescar Sesión');
export const AUTH_REFRESH_OK = createAction('[auth] OK Refrescar Sesión', props<{ professional: Professional }>());
export const AUTH_REFRESH_ERR = createAction('[auth] ERR Refrescar Sesión', props<{ payload: HttpErrorResponse }>());

export const LOAD_SIGNATURE = createAction('[auth] Cargar firma del profesional', props<{ signature: Signature }>());
export const LOAD_SIGNATURE_OK = createAction('[auth] OK Cargar firma del profesional', props<{ base64: string }>());
export const LOAD_SIGNATURE_ERR = createAction('[auth] ERR Cargar firma del profesional', props<{ payload: HttpErrorResponse }>());

export const AUTH_LOGOUT = createAction('[auth] Cerrar Sesión');
export const AUTH_LOGOUT_OK = createAction('[auth] OK Cerrar Sesión');
export const AUTHL_LOGOUT_ERR = createAction('[auth] ERR Cerrar Sesión', props<{ payload: HttpErrorResponse }>());

/* Cargar Datos del Professional */
export const PUT_PROFESSIONAL = createAction('[auth] Guarda Professional', props<{ valuesToSave: Professional }>());
export const PUT_PROFESSIONAL_OK = createAction(
  '[auth] OK Guarda Professional',
  props<{ professional: Professional }>(),
);
export const PUT_PROFESSIONAL_ERR = createAction('[auth] ERR Guarda Professional', props<{ payload: any }>());

export const PUT_PROFESSIONAL_BY_TENS = createAction(
  '[auth] Cargar Professional desde perfil TENS',
  props<{ professional: Professional }>());
