import { LOGO_FONASA1, LOGO_FONASA2, LOGO_PDF_BASE64, LOGO_MINSAL_BASE64 } from '@shared/constants/app.const';

const logo = (info) => {
  const {currentPage, pageCount, config} = info;
  const {header}                         = config;

  const LOGO                             = {image: LOGO_PDF_BASE64, width: 170, style: 'logo'};
  const LOGO_MINSAL                      = {image: LOGO_MINSAL_BASE64, width: 150, style: 'logo'};
  const LOGO_FONASA                      = {image: LOGO_FONASA1 + LOGO_FONASA2, width: 530, style: 'logo'};

  if (header.logo === 'all') return LOGO;
  if (header.logo === 'first' && currentPage === 1) return LOGO;
  if (header.logo === 'last' && currentPage === pageCount) return LOGO;
  if (header.logo === 'specialty' && currentPage === 1) return LOGO_FONASA;
  if (header.logo === 'specialtyVaccine' && currentPage === 1) return LOGO_MINSAL;

  return '';
};

const title = (info) => {
  const { currentPage, pageCount, config, docTitle, group } = info;
  const { header } = config;
  const text = docTitle && docTitle.trim() !== '' ? docTitle : group?.title;
  const TITLE = {
    style : 'title',
    noWrap: true,
    stack: [`${(text || '').toUpperCase()}`],
  };

  if (header.title === 'all') return TITLE;
  if (header.title === 'first' && currentPage === 1) return TITLE;
  if (header.title === 'last' && currentPage === pageCount) return TITLE;

  return '';
};

const professionalInfo = (info) => {
  const {currentPage, pageCount, paperwork, config} = info;

  const {professional}                              = paperwork;
  const {header}                                    = config;

  const PROFESSIONAL_INFO                           = {
    width    : '*',
    alignment: 'right',
    style    : 'headerText',
    stack    : [
      {text: `${professional.names.toUpperCase()} ${professional.surnames.toUpperCase()}`},
      {text: `${professional.rut}`},
      {text: `${professional.specialty ? professional.specialty.name.toUpperCase() : ''}`},
      {text: `${professional.email}`},
    ],
  };

  if (header.professional === 'all') return PROFESSIONAL_INFO;
  if (header.professional === 'first' && currentPage === 1) return PROFESSIONAL_INFO;
  if (header.professional === 'last' && currentPage === pageCount) return PROFESSIONAL_INFO;

  return '';
};

export const getHeader = (pdfParams) => {
  const { paperwork, config, docTitle, group } = pdfParams;
  return (currentPage: number, pageCount: number) => {
    const info = { currentPage, pageCount, paperwork, config, docTitle, group };

    const LOGO              = logo(info);
    const TITLE             = title(info);
    const PROFESSIONAL_INFO = professionalInfo(info);

    return [{columns: [LOGO, PROFESSIONAL_INFO]}, TITLE];
  };
};
