import { HttpErrorResponse } from '@angular/common/http';
import { Briefing, Reproductive } from '@shared/interfaces/reproductive.interface';
import { createAction, props } from '@ngrx/store';
import { ParamsToFindByPatient } from '@components/patient/interfaces/patient.interface';
import { Paperwork } from '@shared/interfaces/paperwork.interface';

export const PUT_REPRODUCTIVE_END = createAction('[reproductive] Resetea el estado para crear una nueva solicitud');

export const PUT_REPRODUCTIVE = createAction(
  '[reproductive] PUT Instrucciones Médicina Reproductiva',
  props<{ valuesToSave: Paperwork<Briefing> }>(),
);
export const PUT_REPRODUCTIVE_OK = createAction(
  '[reproductive] OK PUT Instrucciones Médicina Reproductiva',
  props<{ reproductive: Paperwork<Briefing> }>(),
);
export const PUT_REPRODUCTIVE_ERR = createAction(
  '[reproductive] ERR PUT Instrucciones Médicina Reproductiva',
  props<{ payload: HttpErrorResponse }>(),
);

export const CLEAR_REPRODUCTIVE = createAction('[reproductive] Limpiar medicina reproductiva por paciente');
export const SELECTED_REPRODUCTIVE = createAction(
  '[reproductive] SELECTED medicina reproductiva',
  props<{ selected: Paperwork<Briefing>; action: string }>(),
);
export const UNSELECT_REPRODUCTIVE = createAction('[reproductive] UNSELECT medicina reproductiva');

export const LOAD_REPRODUCTIVE_BY_PROFESSIONAL = createAction(
  '[reproductive] Cargar Formulario por profesional',
  props<{ gsiMultiCondition1St: string }>(),
);
export const LOAD_REPRODUCTIVE_BY_PROFESSIONAL_OK = createAction(
  '[reproductive] OK Formulario por profesional',
  props<{ brief: Paperwork<Briefing>[] }>(),
);
export const LOAD_REPRODUCTIVE_BY_PROFESSIONAL_ERR = createAction(
  '[reproductive] ERR Formulario por profesional',
  props<{ payload: HttpErrorResponse }>(),
);

export const LOAD_REPRODUCTIVE_BY_PATIENT = createAction(
  '[reproductive] Cargar Formulario por Paciente',
  props<{ params: ParamsToFindByPatient }>(),
);
export const LOAD_REPRODUCTIVE_BY_PATIENT_OK = createAction(
  '[reproductive] OK Formulario por Paciente',
  props<{ brief: Paperwork<Briefing>[] }>(),
);
export const LOAD_REPRODUCTIVE_BY_PATIENT_ERR = createAction(
  '[reproductive] ERR Formulario por Paciente',
  props<{ payload: HttpErrorResponse }>(),
);

export const DELETE_REPRODUCTIVE = createAction(
  '[reproductive] DELETE Formulario',
  props<{ brief: Paperwork<Briefing> }>(),
);
export const DELETE_REPRODUCTIVE_OK = createAction(
  '[reproductive] OK DELETE Formulario',
  props<{ brief: Paperwork<Briefing> }>(),
);
export const DELETE_REPRODUCTIVE_ERR = createAction(
  '[reproductive] ERR DELETE Formulario',
  props<{ payload: HttpErrorResponse }>(),
);
