import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  DELETE_RECIPE,
  DELETE_RECIPE_ERR,
  DELETE_RECIPE_OK,
  LOAD_RECIPES_BY_PATIENT,
  LOAD_RECIPES_BY_PATIENT_ERR,
  LOAD_RECIPES_BY_PATIENT_OK,
  LOAD_RECIPES_BY_PROFESSIONAL,
  LOAD_RECIPES_BY_PROFESSIONAL_ERR,
  LOAD_RECIPES_BY_PROFESSIONAL_OK,
  PUT_RECIPE,
  PUT_RECIPE_END,
  PUT_RECIPE_ERR,
  PUT_RECIPE_OK,
} from '@store/actions';

import { RecipeService } from '@clinical/medicines/services/recipe.service';
import { ReportService } from '@components/records/services/report.service';

@Injectable()
export class RecipeEffects {
  putRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PUT_RECIPE),
      mergeMap(({ valuesToSave }) =>
        this.recipeService.putRecipe({ ...valuesToSave }).pipe(
          map((recipe) => PUT_RECIPE_OK({ recipe })),
          catchError((err) => of(PUT_RECIPE_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  clear$ = createEffect(() => this.actions$.pipe(ofType(PUT_RECIPE_OK), map(() => PUT_RECIPE_END())));

  loadRecipesByProfessional$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_RECIPES_BY_PROFESSIONAL),
      mergeMap(({ gsiMultiCondition1St }) =>
        this.reportService.findByGsi(gsiMultiCondition1St).pipe(
          map((recipeItem) => LOAD_RECIPES_BY_PROFESSIONAL_OK({ recipes: recipeItem })),
          catchError((err) => of(LOAD_RECIPES_BY_PROFESSIONAL_ERR({ payload: err }))),
        ),
      ),
    ),
  );
  loadRecipesByPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_RECIPES_BY_PATIENT),
      mergeMap(({ params }) =>
        this.reportService.findByType(params.patientId, params.type).pipe(
          map((recipeItem) => LOAD_RECIPES_BY_PATIENT_OK({ recipes: recipeItem })),
          catchError((err) => of(LOAD_RECIPES_BY_PATIENT_ERR({ payload: err }))),
        ),
      ),
    ),
  );
  deleteRecipes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DELETE_RECIPE),
      mergeMap((recipe) =>
        this.reportService.deleteByIds('recetas', recipe).pipe(
          map((recipeItem) => DELETE_RECIPE_OK({ recipe: recipeItem })),
          catchError((err) => of(DELETE_RECIPE_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private recipeService: RecipeService, private reportService: ReportService) {
  }
}
