import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { API_URIS } from '@shared/constants/api-uris.const';
import { Paperwork } from '@shared/interfaces/paperwork.interface';
import { PCV2Request } from '@shared/interfaces/request.interface';
import { map, Observable, retry } from 'rxjs';

const BASE_API_PCV2: string = environment.baseApiPCv2;
const URI_CLINICAL_RECIPES: string = API_URIS.clinical.discharge;
const BASE_API_EPICRISIS = 'https://api.isuc.ucchristus.io';
const BASE_API_FILMAKER = 'https://api.filemaker.ucchristus.io/epicrisis';


@Injectable({
  providedIn: 'root'
})
export class EpicrisisNursingService {

  constructor(private httpClient: HttpClient) {}

  epicrisis(patientRun: string): Observable<any[]> {
    return this.httpClient
      .get<any>(`${BASE_API_EPICRISIS}/isuc/epicrisis/paciente/${patientRun}`).pipe(
        retry(8),
      )
  }
getEpicrisisIsuc(id: string):Observable<any[]> {
  return this.httpClient
  .get<any>(`${BASE_API_EPICRISIS}/isuc/epicrisis/${id}`).pipe(
    retry(8),
  )
}

getEpicrisisFilmaker(rut: string):Observable<any[]> {
  return this.httpClient
  .get<any>(`${BASE_API_FILMAKER}/${rut}`).pipe(
    retry(8),
  )
}

  putDischarge(body: Paperwork<any>): Observable<Paperwork<any>> {
    return this.httpClient.post<PCV2Request>(`${BASE_API_PCV2}/${URI_CLINICAL_RECIPES}`, body).pipe(
        retry(3), map(({data}: { data: Paperwork<any> }) => data)
    );
}
}

