import { Action, createReducer, on } from '@ngrx/store';

import { CLEAR_EXPERTISE, SELECTED_EXPERTISE, UNSELECT_EXPERTISE } from '@store/actions';
import { DELETE_EXPERTISE, DELETE_EXPERTISE_ERR, DELETE_EXPERTISE_OK } from '@store/actions';
import { PUT_EXPERTISE, PUT_EXPERTISE_ERR, PUT_EXPERTISE_OK } from '@store/actions';
import { LOAD_EXPERTISES, LOAD_EXPERTISES_ERR, LOAD_EXPERTISES_OK } from '@store/actions';
import { Expertise } from '../interfaces/expertise.interface';
import {
  LOAD_EXPERTISES_BY_PROFESSIONAL,
  LOAD_EXPERTISES_BY_PROFESSIONAL_OK,
  LOAD_EXPERTISES_BY_PROFESSIONAL_ERR,
} from '@store/actions';

export interface ExpertiseState {
  error: any;
  status: string;
  isLoaded: boolean;
  isLoading: boolean;
  expertises: Expertise[];
  historical: Expertise[];
  selected: Expertise;
  lastExpertise: Expertise;
}

export const expertiseState: ExpertiseState = {
  error: null,
  status: '',
  isLoaded: false,
  isLoading: false,
  expertises: [],
  historical: [],
  selected: null,
  lastExpertise: null,
};

const createReducerExpertise = createReducer(
  expertiseState,

  // Obtener los pertitajes
  on(LOAD_EXPERTISES, (state) => ({ ...state, isLoading: true })),
  on(LOAD_EXPERTISES_OK, (state, { expertises: expertises }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    expertises: [...expertises],
  })),
  on(LOAD_EXPERTISES_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  // Obtener las indicaciones de un profesional
  on(LOAD_EXPERTISES_BY_PROFESSIONAL, (state) => ({ ...state, isLoading: true })),
  on(LOAD_EXPERTISES_BY_PROFESSIONAL_OK, (state, { expertises: expertises }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    historical: [...expertises],
  })),
  on(LOAD_EXPERTISES_BY_PROFESSIONAL_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(PUT_EXPERTISE, (state) => ({ ...state, isLoading: true, status: 'Guardando el registro.' })),
  on(PUT_EXPERTISE_OK, (state, { expertise }) => {
    let expertises = state.expertises.map((ans) => {
      if (ans.id === expertise.id) return expertise;
      else return ans;
    });

    const index = expertises.findIndex((draft) => draft.id === expertise.id);
    if (index === -1) expertises = [expertise, ...expertises];

    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      status: 'Registro guardado con éxito',
      expertises: [...expertises],
      lastExpertise: { ...expertise },
    };
  }),
  on(PUT_EXPERTISE_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    status: 'Ocurrio un error al intentar guardar el registro',
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(SELECTED_EXPERTISE, (state, { selected, action }) => ({ ...state, selected, status: action })),
  on(UNSELECT_EXPERTISE, (state) => ({
    ...state,
    isLoaded: false,
    isLoading: false,
    status: '',
    selected: null,
  })),

  on(DELETE_EXPERTISE, (state) => ({ ...state, isLoading: true })),
  on(DELETE_EXPERTISE_OK, (state, { expertise }) => {
    let expertises = [...state.expertises];
    const index = expertises.findIndex((draft) => draft.id === expertise.id);
    if (index !== -1) expertises = [...expertises.filter((draft) => draft.id !== expertise.id)];

    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      status: 'Peritaje eliminado con éxito',
      expertises: [...expertises],
    };
  }),
  on(DELETE_EXPERTISE_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    status: 'Ocurrio un error al intentar eliminar el peritaje',
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(CLEAR_EXPERTISE, (state) => ({
    expertises: [...state.expertises],
    historical: [...state.historical],
    isLoading: false,
    isLoaded: false,
    error: null,
    status: '',
    selected: null,
    lastExpertise: null,
  })),
);

export const expertiseReducer = (state: ExpertiseState, action: Action) => createReducerExpertise(state, action);
