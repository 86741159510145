import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { FileExtension } from '@shared/constants/mime-types.const';

import { PdfService } from '@shared/helpers/pdf.helper';
import { ATTACH_FILE, SEND_FORM } from '@store/actions';

import { AppState } from '@store/app.reducers';
import { PaperworkState } from '@store/reducers';
import { KEY_PAPERWORK_STORE } from '@store/store-keys';
import { Subject } from 'rxjs';
import { filter, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { Control } from 'src/app/_clinical/indications/interfaces/indication.interface';
import { Paperwork } from '@shared/interfaces/paperwork.interface';

interface GroupedForms {
  title: string;
  rows: [];
}

@Component({
  selector: 'app-pdf-preview',
  templateUrl: './preview-pdf.component.html',
  styleUrls: ['./preview-pdf.component.scss'],
})
export class PreviewPdfComponent implements OnInit, OnDestroy {
  @Input() paperwork: Paperwork;

  docBase64: string;
  frmControl: Control;

  currentPath: string;

  destroy: Subject<string> = new Subject();
  docs: { pdfs: any[]; paperwork: Paperwork } = { pdfs: [], paperwork: {} as Paperwork };

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private pdfService: PdfService) {}

  ngOnInit() {
    this.store
      .select(KEY_PAPERWORK_STORE)
      .pipe(
        takeUntil(this.destroy),
        filter(({ paperwork }) => paperwork !== null),
        take(1),
        mergeMap((paperwork$) => this.getPDFs(paperwork$)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy.next('next');
    this.destroy.complete();
  }

  listenRoute() {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: any) => (!this.currentPath) ? this.currentPath = event.url : this.currentPath)
    );
  }

  async getPDFs(paperworkState$: PaperworkState) {
    this.docBase64 = null;
    const { definition } = paperworkState$;
    const paperworkState = { ...paperworkState$, paperwork: this.paperwork };
    const groups = this.groupedRows(paperworkState);
    this.docs = { pdfs: [], paperwork: {} as Paperwork };
    const { paperwork } = paperworkState;

    for await (const group of groups) {
      const { title } = group;
      const { config, docTitle } = definition.submodule;
      const body = definition.submodule.getBodyDefinition(paperwork, group);
      const pdfParams = { config, paperwork, group, title, body, docTitle };
      const base64 = await this.pdfService.createPdf(pdfParams);
      const pdf = { base64, title, isAttached: true };

      this.docBase64 = base64;
      this.docs.pdfs = [pdf, ...this.docs.pdfs];
    }

    if (definition.submodule.config.isPreview) {
      this.docs.paperwork = paperwork;
      return;
    }

    const extension: FileExtension = 'pdf';
    const documents = this.docs.pdfs.map((pdf) => ({
      ...pdf,
      extension,
      path: definition.module,
      file: this.pdfService.base64ToBlob(pdf.base64),
    }));
    this.store.dispatch(ATTACH_FILE({ documents }));
    this.store.dispatch(SEND_FORM());
  }

  groupedRows({ paperwork }): GroupedForms[] {
    const groups: string[] = [...new Set<string>(paperwork.content.map((form) => form?.group))];
    return groups.map<GroupedForms>((group) => ({
      title: group ? group.toLowerCase() : '',
      rows: paperwork.content.filter((form) => form.group === group),
    }));
  }

  onSegmentChanged(segmentName: string) {
    const current = this.docs.pdfs.find((pdf) => pdf.title === segmentName);
    this.docBase64 = current.base64;
  }
}
