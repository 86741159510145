import {IntegralHealt} from './../interfaces/family-doctor.interface';
import {Patient, MarkOnAgenda} from '@components/patient/interfaces/patient.interface';
import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

/* Cargar Datos personales del Paciente */
export const GET_PATIENT = createAction('[paciente] Cargar Paciente', props<{ docNumber: string; docType: string }>());
export const GET_PATIENT_OK = createAction('[paciente] OK Cargar Paciente', props<{ patient: Patient }>());
export const GET_PATIENT_ERR = createAction('[paciente] ERR Cargar Paciente', props<{ payload: HttpErrorResponse }>());

export const UPDATE_PATIENT = createAction('[paciente] OK Actualizar Paciente', props<{ patient: Patient }>());

export const GET_PATIENT_INTEGRAL_HEALTH = createAction('[paciente] Carga datos salud integral', props<{
  docNumber: string
}>());
export const GET_PATIENT_INTEGRAL_HEALTH_OK = createAction('[paciente] OK Paciente salud integral', props<{
  integralHealth: IntegralHealt
}>());
export const GET_PATIENT_INTEGRAL_HEALTH_ERR = createAction('[paciente] ERR Cargar datos paciente salud integral', props<{
  payload: HttpErrorResponse
}>());

export const GET_PATIENT_MARK_ON_AGENDA = createAction('[paciente] Carga marcas de agenda', props<{
  docNumber: string
}>());
export const GET_PATIENT_MARK_ON_AGENDA_OK = createAction('[paciente] OK Carga marcas de agenda', props<{
  marksOnAgenda: MarkOnAgenda[]
}>());
export const GET_PATIENT_MARK_ON_AGENDA_ERR = createAction('[paciente] ERR Carga marcas de agenda', props<{
  payload: HttpErrorResponse
}>());

export const CLEAR_PATIENT = createAction('[paciente] Limpia paciente');
