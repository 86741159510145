import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { FormFieldsValidate } from '@core/reactive-forms/form-fields-validate.helper';
import { Anamnesis, Diagnostic } from '@shared/interfaces/anamnesis.interface';
import { AnamnesisTemplate } from '@components/anamnesis/constants/anamnesis.const';

import { PHYSICAL_EXAMINATION } from '@components/anamnesis/constants/pediatrics-frm.const';
import { IonSegment, IonSlides, ModalController } from '@ionic/angular';
import { DiagnosticFinderComponent } from '../diagnostics-finder/diagnostic-finder.component';

@Component({
  selector: 'app-ananmnesis-pediatrics-frm',
  templateUrl: './ananmnesis-pediatric-frm.component.html',
  styleUrls: ['./ananmnesis-pediatric-frm.component.scss'],
})
export class AnanmnesisPediatricsFrmComponent implements AfterViewInit, OnInit {
  @Input() template: AnamnesisTemplate;
  @Input() diagnostics: Diagnostic[] = [];
  @Input() isWriteMode: boolean;
  @ViewChild('slides') slides: IonSlides;
  @ViewChild(IonSegment) segment: IonSegment;

  anamnesisFrm: Anamnesis;
  physicalExamination: string[] = PHYSICAL_EXAMINATION.names;
  detailPhysicalSelected: any[] = [];
  frmPediatrics: UntypedFormGroup;
  complaintType: string;
  canMoveSlide: boolean;
  sections = ['Paso 1', 'Paso 2', 'Paso 3'];
  currentSegment = this.sections[0];

  constructor(public modalController: ModalController, private formBuilder: UntypedFormBuilder) {
    this.buildForm();
  }
  ngOnInit(): void {}

  ngAfterViewInit() {
    this.segment.value = this.currentSegment;
  }

  segmentChanged(event: any) {
    this.currentSegment = event.detail.value;
  }

  // moveSlide(moveTo: string) {
  //   this.slides.lockSwipes(false);
  //   if (moveTo === 'next' && this.currentSlideIndex < 5) this.currentSlideIndex++;
  //   if (moveTo === 'last' && this.currentSlideIndex > 0) this.currentSlideIndex--;
  //   if (moveTo === 'segment') this.currentSlideIndex = Number(this.segment.value);
  //   this.slides.slideTo(this.currentSlideIndex);
  //   this.segment.value = this.currentSlideIndex.toString();
  //   this.canMoveSlide = false;
  //   this.slides.lockSwipes(true);
  // }

  async openDiagnosticFinder() {
    const modal = await this.modalController.create({
      component: DiagnosticFinderComponent,
      cssClass: 'cmodal-xl',
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    const diagnostic: Diagnostic[] = data;
    if (diagnostic) this.diagnostics = [...diagnostic];
  }

  deleteDiagnostic(index: number) {
    this.diagnostics.splice(index, 1);
  }
  dismiss() {
    this.modalController.dismiss();
  }

  selectedExam($event, item: any) {
    const selected = item;
    if ($event.target.checked === false) this.detailPhysicalSelected.push(selected);
    else {
      const index = this.detailPhysicalSelected.indexOf(selected);
      this.detailPhysicalSelected.splice(index, 1);
    }
    this.frmPediatrics.controls.physicalDetail.patchValue(this.detailPhysicalSelected);
  }

  buildForm(): void {
    this.frmPediatrics = this.formBuilder.group({
      hour: '',
      rectal: '',
      weight: '',
      cardiac: '',
      servedBy: '',
      axillary: '',
      procedure: '',
      companion: 'padre',
      patientCM: '',
      saturation: '',
      healthCare: '',
      indications: '',
      respiratory: '',
      bloodPressure: '',
      hourProcedure: '',
      physicalDetail: '',
      generalAppearance: '',
      reasonForConsultation: FormFieldsValidate.requiredMinLength(1),
      anamnesisDescription: FormFieldsValidate.requiredMinLength(10),
      medicalDescription: FormFieldsValidate.requiredMinLength(10),
      decriptiveDetail: FormFieldsValidate.requiredMinLength(10),
    });
  }
}
