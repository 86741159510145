import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MedicalCare} from '@shared/interfaces/medicalcare.interface';
import {PCV2Request} from '@shared/interfaces/request.interface';
import {API_URIS} from '@shared/constants/api-uris.const';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Meeting} from '../interfaces/meeting.interface';

const BASE_API_PCV2: string = environment.baseApiPCv2;
const URI_CLINICAL_MEDICALCARE: string = API_URIS.clinical.medicalcare;
const URI_PROXY_CMV: string = API_URIS.proxy.cmv;

@Injectable({
  providedIn: 'root',
})
export class MedicalcareService {
  private _isTelemedicine: boolean;

  public get isTelemedicine(): boolean {
    return this._isTelemedicine;
  }

  public set isTelemedicine(value: boolean) {
    this._isTelemedicine = value;
  }

  constructor(private httpClient: HttpClient) {
  }

  save(body: MedicalCare): Observable<MedicalCare> {
    const professionalId = body.appointment.professional.id;
    const endpoint = `profesionales/${professionalId}`;

    return this.httpClient.post<PCV2Request>(`${BASE_API_PCV2}/${URI_CLINICAL_MEDICALCARE}/${endpoint}`, body).pipe(
      map(({data}: { data: MedicalCare }) => data),
    );
  }

  getAppointmentMeeting(appointmentId: string): Observable<Meeting> {
    const endpoint = `zoom/v2/citas/${appointmentId}`;
    return this.httpClient
               .get<PCV2Request>(`${BASE_API_PCV2}/${endpoint}`)
               .pipe(map(({data}) => data?.url), map(this.transformToMeeting));
  }

  /**
   * Convertir URL de una videollamada de Zoom a formato Meeting
   *
   * @param url {string} - url de una videollamada de zoom
   * @returns Datos de una videollamada de zoom
   */
  transformToMeeting(url: string): Meeting {
    const [meeting] = url.split('/').slice(4);
    const [id, passcode] = meeting.split('?');
    return {id, url, passcode};
  }
}
