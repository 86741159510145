import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import {
  LOAD_SURGERIES_BY_PATIENT,
  LOAD_SURGERIES_BY_PATIENT_ERR,
  LOAD_SURGERIES_BY_PATIENT_OK,
  LOAD_SURGERIES_BY_PROFESSIONAL,
  LOAD_SURGERIES_BY_PROFESSIONAL_ERR,
  LOAD_SURGERIES_BY_PROFESSIONAL_OK,
  NOTIFY_KEIRON,
  NOTIFY_KEIRON_OK,
  PUT_SURGERY,
  PUT_SURGERY_END,
  PUT_SURGERY_ERR,
  PUT_SURGERY_OK,
} from '@store/actions';

import { SurgeryService } from '@clinical/surgeries/services/surgery.service';
import { ReportService } from '@components/records/services/report.service';
import { KEY_PAPERWORK_STORE } from '@store/store-keys';
import { AppState } from '@store/app.reducers';

const CONCAT_RESULT_ACTION_INDEX = 1;

@Injectable()
export class SurgeryEffects {
  putSurgery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PUT_SURGERY),
      mergeMap(({ valuesToSave }) =>
        this.clinicalSurgeryService.save({ ...valuesToSave }).pipe(
          map((surgery) => PUT_SURGERY_OK({ surgery })),
          catchError((err) => of(PUT_SURGERY_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  notify$ = createEffect(() => this.actions$.pipe(
    ofType(PUT_SURGERY_OK),
    map(({ surgery }) => NOTIFY_KEIRON({ surgery }))),
  );

  notifyKeiron$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NOTIFY_KEIRON),
      concatLatestFrom(() => this.store.select(KEY_PAPERWORK_STORE)),
      map((contactResult) => contactResult[CONCAT_RESULT_ACTION_INDEX]),
      mergeMap((paperwork) => this.clinicalSurgeryService.buildNotifyKeironFormsData(paperwork)),
      mergeMap((formsData) =>
        this.clinicalSurgeryService.notifyAllKeiron(formsData).pipe(
          map((_) => NOTIFY_KEIRON_OK()),
          catchError((err) => of()),
        ),
      ),
    ),
  );

  clear$ = createEffect(() => this.actions$.pipe(ofType(NOTIFY_KEIRON_OK), map(() => PUT_SURGERY_END())));

  loadSurgeriesByPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_SURGERIES_BY_PATIENT),
      mergeMap(({ params }) =>
        this.reportService.findByType(params.patientId, params.type).pipe(
          map((order) => LOAD_SURGERIES_BY_PATIENT_OK({ orders: order })),
          catchError((err) => of(LOAD_SURGERIES_BY_PATIENT_ERR({ payload: err }))),
        ),
      ),
    ),
  );
  loadSurgeriesByProfessional$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_SURGERIES_BY_PROFESSIONAL),
      mergeMap(({ gsiMultiCondition1St }) =>
        this.reportService.findByGsi(gsiMultiCondition1St).pipe(
          map((order) => LOAD_SURGERIES_BY_PROFESSIONAL_OK({ orders: order })),
          catchError((err) => of(LOAD_SURGERIES_BY_PROFESSIONAL_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private clinicalSurgeryService: SurgeryService,
    private reportService: ReportService,
  ) {
  }
}
