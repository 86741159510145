export const FORMS_ROUTES = {
  ete                     : 'ete',
  ges                     : 'ges',
  pnac                    : 'pnac',
  certificate             : 'certificado',
  lumbarPain              : 'dolor-lumbar',
  consent                 : 'consentimiento',
  specialVaccines         : 'vacunas/especiales',
  padFonasaRegistration   : 'registro/pad/fonasa',
  bariatric               : 'registro/pad/cirugia-bariatrica',
  hospitalization         : 'ingreso/hospitalario',
  imagesConsent           : 'consentimiento/imagenes',
  anesthesiaConsent       : 'consentimiento/anestesia',
  historyPatientForms     : 'historial/recetas/paciente',
  historyProfessionalForms: 'historial/recetas/profesional',
};
