import { DEFAULT_PDF_POSITION_SIGNATURE } from '@shared/constants/app.const';
import { PrintedOnPage, PrintMode } from '@shared/interfaces/paperwork.interface';
import { PUT_SURGERY } from '@clinical/surgeries/store/surgery.actions';
import { buildContentPdfSurgery } from '@clinical/surgeries/utils/build-pdf-surgery';
import { SURGERIES_ROUTES } from '@clinical/surgeries/surgeries-routes.module';

export const PAPERWORK_SURGERY_DEFINITION = {
  actions  : {
    put: PUT_SURGERY,
  },
  prefix   : 'SUR',
  module   : 'surgeries',
  surgeries: {
    suffix           : 'ODC',
    group            : 'orden de cirugía',
    docTitle         : 'orden de cirugía',
    submodule: SURGERIES_ROUTES.order,
    getBodyDefinition: buildContentPdfSurgery,
    workflow         : {
      isSigned     : true,
      isStored     : true,
      isSaving      : true,
      hasNextControl      : true,
      notifications: [
        {
          type    : 'email',
          entity  : 'patient',
          subject: 'Notificaciones UC CHRISTUS | Orden de Cirugía',
          template: 'generic',
        },
      ],
    },
    config: {
      isPreview: true,
      header: { logo: PrintedOnPage.all, title: PrintedOnPage.all, professional: PrintedOnPage.all },
      patient: { type: PrintMode.full, printed: PrintedOnPage.all },
      footer: {
        signature: { printed: PrintedOnPage.last, position: DEFAULT_PDF_POSITION_SIGNATURE },
        qr: { right: { url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web' } },
      },
    },
  },
};
