import { Injectable } from '@angular/core';
import { addDays, addMonths, addWeeks } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class DateServiceSchedule {
  constructor() {}

  static calculateInterval(desde, hasta) {
    const hF = desde.split(':', 2);
    const hT = hasta.split(':', 2);
    const hFrom = parseInt(hF[0], 10) * 60 + parseInt(hF[1], 10);
    const hTo = parseInt(hT[0], 10) * 60 + parseInt(hT[1], 10);
    const obj = {
      HourFrom: hFrom,
      HourTo: hTo,
      TimeFrom: desde,
      TimeTo: hasta,
    };
    return obj;
  }

  static dateDiffInDays(a: any, b: any) {
    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    const aDate = new Date(a);
    const bDate = new Date(b);

    const utc1 = Date.UTC(aDate.getFullYear(), aDate.getMonth(), aDate.getDate());
    const utc2 = Date.UTC(bDate.getFullYear(), bDate.getMonth(), bDate.getDate());
    return Math.floor((utc2 - utc1) / MS_PER_DAY);
  }

  static addDate(control: number, range: string) {
    const date = new Date();
    switch (range) {
      case 'dia':
        return addDays(date, control);
      case 'semana':
        return addWeeks(date, control);
      case 'mes':
        return addMonths(date, control);
      default:
        return date;
    }
  }
}
