export const SPECIAL_VACCINES = [
  {
    id: 1,
    name: 'BCG',
    observations: '',
    quantity: 0,
    isCheck: false,
  },
  {
    id: 9,
    name: 'Bexsero',
    observations: '',
    quantity: 0,
    isCheck: false,
  },
  {
    id: 10,
    name: 'dT',
    observations: '',
    quantity: 0,
    isCheck: false,
  },
  {
    id: 11,
    name: 'dTpa',
    observations: '',
    quantity: 0,
    isCheck: false,
  },
  {
    id: 2,
    name: 'Hepatitis A',
    observations: '',
    quantity: 0,
    isCheck: false,
  },
  {
    id: 3,
    name: 'Hepatitis B',
    observations: '',
    quantity: 0,
    isCheck: false,
  },
  {
    id: 4,
    name: 'Hexavalente',
    observations: '',
    quantity: 0,
    isCheck: false,
  },
  {
    id: 12,
    name: 'Hib',
    observations: '',
    quantity: 0,
    isCheck: false,
  },
  {
    id: 5,
    name: 'Influenza',
    observations: '',
    quantity: 0,
    isCheck: false,
  },
  {
    id: 6,
    name: 'Neumo 13 valente',
    observations: '',
    quantity: 0,
    isCheck: false,
  },
  {
    id: 7,
    name: 'Neumo 23 valente',
    observations: '',
    quantity: 0,
    isCheck: false,
  },
  {
    id: 14,
    name: 'Nimenrix',
    observations: '',
    quantity: 0,
    isCheck: false,
  },
  {
    id: 8,
    name: 'Polio Inyectable',
    observations: '',
    quantity: 0,
    isCheck: false,
  },
  {
    id: 17,
    name: 'Rotavirus',
    observations: '',
    quantity: 0,
    isCheck: false,
  },
  {
    id: 15,
    name: 'SRP',
    observations: '',
    quantity: 0,
    isCheck: false,
  },
  {
    id: 18,
    name: 'Varicela',
    observations: '',
    quantity: 0,
    isCheck: false,
  },
  {
    id: 16,
    name: 'VPH',
    observations: '',
    quantity: 0,
    isCheck: false,
  },
  {
    id: 19,
    name: 'Otras',
    observations: '',
    quantity: 0,
    isCheck: false,
  },
];

export const SPECIALVACCINES = [
  'BCG',
  'Bexsero',
  'dT',
  'dTpa',
  'Hepatitis A',
  'Hepatitis B',
  'Hexavalente',
  'Hib',
  'Influenza',
  'Neumo 13 valente',
  'Neumo 23 valente',
  'Nimenrix',
  'Polio inyectable',
  'Rotavirus',
  'SRP',
  'Varicela',
  'VPH',
  'Otras',
];
