export const DEFAULT_SIGNATURE = 'iVBORw0KGgoAAAANSUhEUgAAA0MAAADWCAYAAAATg1mCAAAACXBIWXMAABcRAAAXEQHKJvM/AAAYKklEQVR4' +
'nO3dT3ITSdoH4GJi9vTsPq1gTgBzAlsngDkBTIT2MCdo+gTtXo8ixpzgc59A5gSDTzCw0vKDE/iL6kkxSXVm' +
'VclS/X+eiA5oDLasckn5y8z3zUf39/cFAADA0vzBFQcAAJZIGAIAABZJGAIAABZJGAIAABZJGAIAABZJGAIA' +
'ABZJGAIAABZJGAIAABZJGAIAABZJGAIAABZJGAIAABZJGAIAABZJGAIAABZJGAIAABZJGAIAABbpjy47AH1b' +
'rbc/FEVxUxTFbVEUV/vd5ouLAEDfrAwBMITroiguiqL4sSiKT6v19q2rAEDfhCEAhnAbfc3HRVH8vFpvr10J' +
'APokDAEwhDL4fK183VdWiADokzAEQO9CjdBV4uuWK0SXrggAfRCGABjKVWJ1qHSzWm+fuioAdE0YAmAQNatD' +
'j0OnOQDo1KP7+3vPMACDWa23H4uieJb4+u/3u81rVwaArlgZAmBorzPb5cqGCsIQAJ0RhqBDZd2DYnCot99t' +
'ypWhd5m/9M/VevvcUwhAF4Qh6FY5wNut1ttboQjy9rtNWTv0a+YvlA0VfvD0AXBuwhB0JAzeXoXPfhFCUapY' +
'HPiPckvc58Rz8URDBQC6IAxBd1K1Dm+csg9pobvcy8yHL0wmAHBuwhB0J7ctriwKzw34YNFC/dDfM8/BG/cO' +
'AOckDEF36mocrtVAQFpD/dC1hgoAnIswBMN4XNM9C8jXDz02mQDAuQhD0J2m2es3ZrghraF+qDygVe0dACcT' +
'hqA7j1t8ZgXhkNFQP/Ritd6+9dwBcAphCIZ14YR9yGuoH/rZ+V0AnEIYgn7dJeog1A5BvVz9UOFAVgBOIQxB' +
'j/a7zfNQS/Q1+qpPrA5BXkP9ULkd9dbTB8BDCEPQszCwq9Y6WB2CGg31Q88cZgzAQwhD0KNDfcN+t7kOW+YO' +
'rA5Bg1A/9CHzt165hwA4ljAEw6muDhnIQbOXlW2msSvt6gE4hjAE/fpW6L3fbW4rs9wXBnJQr0X9kIYKALQm' +
'DEG/qmGnWivk3BRoECYSfsr8rScOZAWgLWEIBhQGdXHL4JdmtaHZfrd5V1M/5EBWAFoRhqBfqaATrw49rtkC' +
'BHyvrn7IgawANBKGoF+pmqCbyoBOGIIWGuqHCvVDADQRhqA7uRPzvxMGdDfRn71wTaCdhvohB7ICUEsYgu58' +
'Snzm3Cx1HIbK84isDkFLDfVD5YGsV55LAFKEIejXs9RX2+821a1yah3gOHX1Q29MMACQIgzBeMSrQ8IQHKFF' +
'/dD1ar196jkFICYMQc9qBmRxbUNyBQnIa1E/dOPpAyAmDEF3PmY+cy4MVeuGrA7BkdQPAXAMYQi68yXzmZNN' +
'FMI2n7voj1JtuIFmTfVDrz2HABTCEAyiLuTEq0nCEDxAi/qhq9V66/4CQBiCDqVaaxc17bWLSt2QYm94oBb1' +
'Q9cOZAVAGILu5MJQ25UhYQhO0FQ/VBTFO88vwLIJQ9C/bMjZ7zZxGHri2sDJnD8EQJYwBB0J23RSmkLOXcPH' +
'gZacPwRAHWEIBtDQNvvb9jrtteF0zh8CIEcYgm7l6hXa1g0BZ+D8IQBShCHoVu6sobowlPs3wGma6oesxAIs' +
'jDAE3cqt8tQNuqwMQQda1A/daLcNsCzCEHQr1177iUEX9E/9EAAxYQi6lQtDRW51qKYLHXAGDfVDF6v19q3n' +
'GWAZhCHoUEOwUZ8Aw3ldUz/082q9ravrA2AmhCHoXu7coLowdBikqR+CDux3m08hEOWoHwJYAGEIupcLNM9q' +
'vvJv/yYUfAMd2O82ZX3QL5nPXB6OrN02wMwJQ9C97OqOVr4wrP1u87Zm9fbVar2t6z4HwMQJQ9C9uq1udWEo' +
'V+ANnFfd+UPXq/X2qecbYJ6EIejYA5sofHH4KvSjoX5Iu22AGROGoB/ZNr6ZP/+oeQL0J9QPvc98wWer9fad' +
'ywEwP8IQ9CO7OlRTNyQMQb/q6od+VOMHMD/CEPTjIVvlhCHoUejeWHf+0LV22wDzIgxBD0LdUG6AlQpDX0Id' +
'A9Cj/W5TTkLktsSV7bavXQ+A+RCGoD+51aGLxGyzARcMZL/blOcL/Zr56i9W623dYa0ATIgwBP2p60j13eqQ' +
'w1ZhcGXg+Zx5EFfabQPMgzAE/WkdhoBhRfVDKdptA8yEMAQ9CYOrXKcqYQhGJtT6/ZR5VNptA8yAMAT9ytUC' +
'PbPtBsZnv9u8qzknTLttgIkThqBfdY0RDKpgnLTbBpgpYQh6FLbK5bpUvXQtYHxCm/tc/ZB22wATJgxB/3KF' +
'11aGYKT2u015377PPDrttgEmShiC/uXC0GP1BzBqb2uaoGi3DTBBwhD0LGyVy80w2yoHI9Wi3bbtcgATIwzB' +
'MGyVgwna7zYfi6L4e+aRX2i3DTAtj+7v710yGMBqvf0SZpOr/hwKtoGRWq235RlEF5lH95cQmgAYOStDMJzc' +
'lhpb5WD86tpt32i3DTANwhAMJxeGbJWDkWvRbtt2OYAJsE0OBrRabz+FgVPVn0KxNjBiq/W2nNR4lXmEfw0t' +
'uQEYKStDMKyrzFe3VQ6moWy3/TnzSK9tlwMYN2EIhpWbNRaGYAK02waYNmEIBhTqDn5NPIIXZpRhGva7TdlZ' +
'7pfMgy3v5VxYAmBgwhAMz+oQTNx+tym3y91lvour1Xr71DUGGB9hCAa2322uMy16hSGYFtvlACZGGIJxSK0O' +
'2SoHExIOWv0p84gvVuvtW9cTYFyEIRgHXeVgBva7TXm+0IfMd/Lzar197joDjIcwBCMQZpRT7XmFIZie15mt' +
'r4XtcgDjIgzBeKRWh2yVg4kJXSLfZR71s9V6m/sYAD0ThmA8cjPG2vLCxOx3m6ua7XI/2i4HMA7CEIxEOLwx' +
'deaQMATTZLscwMgJQzAuqQHSM2eUwPTYLgcwfsIQjMh+t7nJNFLQkhcmqMV2uUvXFWA4whCMT+rMIVvlYLpq' +
't8tpkgIwHGEIxifVVe7xar0ViGCCGrbLPan5GAAdE4ZgZMLAKbWtRhiCiWrYLvfGdjmAYQhDME6pRgoXGinA' +
'pNkuBzAywhCM0H63uc4MmjRSgImyXQ5gfIQhGK/U6pCtcjBhtssBjIswBOOlkQLMU909nLrvAeiIMAQjVdNI' +
'wVY5mLBwb/+U+Q4cxgrQI2EIxi21Ve6ZrTQwbfvdpgw8d5lvojyM9blLDNA9YQhGTCMFmLW67XKpiRAAzkwY' +
'gvFLDYpeaLMN07bfbT4WRfFL5puwXQ6gB8IQjF+uoNpACaavvI8/Z76LtyY9ALolDMHI1TRSeGWgBNO2322+' +
'1Gx7fWy7HEC3hCGYhtyASO0QTNx+t7kpiuLXzHdxsVpv3ecAHXl0f3/vuYUJWK23X8JMcaxsrvA0zC4DExVW' +
'eT8m7vHCfQ7QHStDMB2p1aHHVodg+sJ22Fx9oO1yAB0RhmA6cgOlssj6B9cRpq3h7KGyg+RLlxjgvIQhmIgw' +
'c5yqK7A6BPNRdy9fmfgAOC9hCKYlt1XmR53lYPr2u81tURTvM9/IEy31Ac5LGIIJCV2ncmeSGCTBPLwNTRNS' +
'3qzW20vXGeA8hCGYntzqkHOHYAZC17i6yY1c/SAARxKGYHrqBkI6TsEM7Hebq5pmCs9W662VYIAzEIZgYsKs' +
'ca6m4MIWGpiNumYKb60EA5xOGIJpqlsdsoUGZiA0U0h1kCycPQRwHsIQTNB+tylPqv+QeeTlFprXrivMQl0z' +
'hQtnDwGcRhiC6aotsHYeCUxfOF+sdiXYvQ7wcMIQTFTYQpMrsHYQK8zHVU1L/SfudYCHE4Zg2upmjBVYwwy0' +
'aLXtXgd4IGEIJmy/21zXzBg/dhArzEO413N1gu51gAcShmD66gZB5UGsz11jmIWme11bfYAjCUMwcQ2rQ4VW' +
'2zAPoU4wd8ZYYXUI4HjCEMxD3SDIQawwH+51gDMShmAGWqwOOZwRZiC02v6l5juxOgRwBGEI5qPuoNUnq/VW' +
' +12Yh3cNB7FaHQJoSRiCmQj1BLluU6V3DmeE6QuttutqAa0OAbQkDMG81A2CtN+F+ag7iPXCuUMA7QhDMCMt' +
'VofeaLUN09fiIFYTHwAtCEMwP02DIK22YQYaGqe8tC0WoJkwBDPTYnWo3EJT12wBmI7c5Ee5Lfal6whQTxiC' +
'eWpcHTJrDNPXsDpk0gOggTAEM9TipPrHzh6C2ciFHo0UABoIQzBfTatDL1brrSJrmLgw+XGX+S5slQOoIQzB' +
'TIWT6utWh0o/rtbbW4c0wuTlGqO4twFqPLq/v/f8wEyFNtr/avndlTPL5Qzzl46fjfLzf+z4a3QizMDD6IQa' +
'wP9LPa79bvPIFQNIE4aYpDDIP3b7R/lv5t40oPz+no3gcdCsruNfk0/hv17+nRA4Dav19qbc/pp4sGvXECDt' +
'j54XpiQUA19l3vBhSi5OeKyn/NujrdbbY//J5yND17Eh7eMRK5ifwpbRJciFoZdh1ReACitDTEpZ39L3QBBY' +
'jGNCXNtwURfczhrUwla5T6FbZOxrGYisDgH8njDE5KzW27ehU1r1DR9gDu4SAaptrV3ZZvtJ5mNlQ5V3C1op' +
'A2gkDDFJYQb0daiROXRLei4gATQ6hK1qwKpuV1zSFkNgoYQhZifUFR0OGoyD0g8jbTDw03636ey8n6ht9g9h' +
'Ra3u+//Q4nwi+nWlKQYjUd1GmFqtqm7F+7jfbbruUAnwYMIQixU60h0C0vPwPAwRmsoAch0GGV/2u01nbafD' +
'itptzfdW1hY8NXg5TvSzlBKH86r4Zy9lrvVxp3TSO4bV4vGpBqrfrUZV/l+YAjolDEGDAUPTYcB4KMA+DBJO' +
'2rrSIhB1ulLVt8yBsrkQUhdODKz/Ix7MVlcGqs0CJrXNqiHUxtocZNr2c2kI097Xmp+3734WNYsA2hKG4Ewa' +
'QtPTmqLmh4oHBreVX2tnU1scxvq3/W5z3efPRghp1SCSCyepwWgXz/ESxMX68eAynqFXOzKARJCvrjJWA5fA' +
'ntY2wPs5hwUShqBn0QAnFZ7OPUt8GAR8id70P4ZzR940/Nuy89RV3ba9zEx6dQCXCjSCy/lUO4811XF0uhWT' +
'4YXXmF3mgbxPtA+v3qMOb/6PeDtnbqLA/QQTJwzBCGVWmYbsmvc1vPkbIJ1H6jyb1Hk01a0+Zq5ptFpvy1Xd' +
'V5W/V97Dlw8duLdYpSoSK1VLDFV3udBkGx+MkzAEExXOW/rZ9etFKrxUC72zfybA0JfQTfPfiS83ilrAxGpy' +
'alWqupI897qqNgHKChR0RBiCEUnMvlYHDofZ2LkMDqoF0QcP3UaXOqyyyISU3CGWulcxWav19iqzBfYvcxlM' +
'JwJVapUq9Vo6p3qq3Ba+737vtQyaCUNwJpXzjYrEG/QSZzybfNjvNm06cwENQhOST6lB/363eeT5+6/E63Xq' +
'9bn62jTl1+t4dftT7vdWsVkiYQiCxKpM0yqNzk2nK1dyXnoDhtOt1ttyG9yPiU9k0uHMEt0vq+HqsvKxqTWM' +
'EZ5YDGGIWThie9mBbknHe7/fbV5HW1Quo9nUcwTDD9Eb7a03WjjOar39lBl0/7Lfbd56OoeXCFGXmd9PbRUq' +
'Dk+27TEpwhCDE2Q6dZc7lDAKHs/DG/KLhgfy1/1uc1P3F8K1PFyvw3U89U39Q/TYP4ZCYp2YIFKzKlTMqV5o' +
'iaL3yDhIxb+f4spTXPN0eD3XMIJBCEM8WMtWq6m/43yZ41UbDcRh4LtT2E9onfs8fN7cCs+Dt9pE+/OfV349' +
'5WfhMBN5+P5vvYGyRHW1QuV9st9tqq/LzFSluURq1WmKk4m5VadvIcrrPqcQhhYmEWAKh2T2rm615ruQ0/eW' +
'gobZ5aKLGebw5h0HpXN0yxOUWIzVentbc8/8bb/bXPtpoKrSRCIVoqZe63R4/Y/fZ22/5neEoRFKdLk5yP15' +
'dVtZYStZL+pCzVlWa/rWMMNcHOqG+nhY0d76w8/95Zl+rgUlZiNzwOqBVSHOIrPiFE+cTrGh0GGrXvz+/S1A' +
'eU9YDmGoQU0wOUitosRSQeVAN7JxqB6oGa/OfHc+zRJqVVqsDv156Jm1Dps4xEHp269mEhmjFgcvr9XX0bdM' +
'jVM8Fppac4i7xArT4b7y/jADvwtD0WzsmNpwNgWOhxBE5qUaaKqHbE5ypWYILVaHRnGSfU54I44D0jmaOBTR' +
'G+Jt9KbojZBBNKwIFWO/T6Ey2TyH1abDStNhvPFtHGJSYty+haHVevuyPO+j4cUVuvAh8TmrLxxxmCkMQrvV' +
'sDo0ya03lSYOh1Wlc+2Jj4PS4Q1QG1nOLkxWXDd0f+xtOyt0rdKOPBWgplTbdBdNpsWByZhmQI/+5/Ifl+GF' +
'VXE8Te4qgeQgNeNRXZn5jdmRaWixOjSr7TeZJg7nmpWMz086rCZZmeRoYdLyuuHnUhBisaIterlVp7HXUlfD' +
'kprWHvy2MhQGPm/Df7aOLUfZrvkqfLff1cmYoaBhdWgRA64OmzgU0dbO27CKVHuGE8sVBnjvWmz3/Pt+t7la' +
'8nMFbUR1p6m6prGGplTzH+O1M/iuZkgoItqydhv/akVneRpWh8og/XTJ28CibXfnauJwmJy4sr2O4r8rQW9b' +
'hKByNvm12WM4r4bQNKbteXeV5j+fjNvaS3aTC4Og16GGaGpdP+iWsLQgDUXazi/JOLGJw9ewFerKjN/yhMHX' +
'4f23aaD1NfycaJQAA0psz4vD05Dj6FSHVPWsFa1ba7c41bjoKCWniuuLxLkusWqxffLfN82indhWu+5jc+5k' +
'V93v+u1XN9/0hHvg35kH/ut+t3m59OfoGA9o4vDtkNvK65GtETMR3lsvw8/Ey5bvDVYRYWKicXS8svR0oBWm' +
'r/Eq0tK33PVyzlAmVHgzr4hmFmKp5y4VtKbSTUVYmpiG1aHBzxyai0wTh5fxfRENnONV+7ixSbxCe/i94tsR' +
'iOrP4ut87IyxEAQzlZgsO+fREMeIt9x9DO8hs97949DVGaus5hWJYFUNVWMoGkyGJdvwhhNC+i7zABRsDyhc' +
'm8tokN1mW9UhGMUdH+PVdBMTJ6gMaJ6eoZasCDskrm1LheWqbL8+d9fTNmbbwEEYIiuxolfdEhl/rK+ZCzVL' +
'A1itt7eZa3y3323OfSAyD1RZffghumcfen/G25S/rTTFgWqJK4OZ7Y7nnkz6HFaBbqy+AnVGGpQmMzYThji7' +
'yopU6vddrUB9rawoOczsTFbrbVnQ/c/MZ7NVbiIqExx19+lD3kA/Z1aavr0ZTmnSInodOwTKLgJPVfkc3oRV' +
'IFsbgZOd2NDnHKr1SaM7b08YYlBRnVQfe2QdZnaC1Xr7KbMNy1a5mWoxsVE8MDzF2/W+5Lbudb1dLzOb2nf9' +
'5YcQgKwAAb3paEvvse7CWOy3/4baoi0MMWo9zmh8jYsFBaXfqzmE1VY5ftNheEo1iYhD1FH36mq9fRsOMR2i' +
'q+co3vwBUs7U7OWhfg2TQ7d9Tg4JQ0xS5mbtakbXYWbNbbb/ZFDHsSrhKd7CF//+2K1p8Xa9ZJOI8v4NX/tf' +
'HV+0z9Eky63aRmCqKgeNH8ZcXW4bfr/fbV738XQJQ8xOWE2Kb9quQtLnuD//EkLSar0tZ2xeJD7kAFY6F02C' +
'HMRNXc5R9/RQcWMXp78DixGNuZ6feaudMATn1mNIqvboH1Wh4ClW6215vs3/Jj5Fby9acIxK04jUGW11B2Sn' +
'DvB2fhNAjcoq0iEoHbuKtO5rUkkYYvEqh1123cBh8iFptd6mXjQ+73eb6uHAAACH8UN1vJXbatfrBKswBBk9' +
'dlqZVEjKdZXb7zaPhnlEAMBURc2ynob6yl7HQMIQHCmqWzhl+beNuCbpSxSUBm2/m1kZEoYAgMkRhuBMwvLv' +
'80q3la6KuKtnJvVyPktoSfxz6mPCEAAwNcIQdChaRYqLCYsee/bHbYaLREH4p8rHk0XhIeiV+3ffZL7Oh/1u' +
'c5n5GADAKAlDMKDKOStNYSI+ZDK5+hN9vufRVr4+TtPvresLAMC5CEMwc9GqzusOtu19LT/vfre58XMEAEyN' +
'MAQLEs4JOvx3SjAqt9+Vh6xedVWfBADQNWEIFiqsGF1W2oenttQdmjUcTte/HbqjHQDAOQhDAADAIv3BZQcA' +
'AJZIGAIAABZJGAIAABZJGAIAABZJGAIAABZJGAIAABZJGAIAABZJGAIAABZJGAIAABZJGAIAABZJGAIAABZJ' +
'GAIAABZJGAIAABZJGAIAABZJGAIAABZJGAIAABZJGAIAABZJGAIAABZJGAIAABZJGAIAABZJGAIAABZJGAIA' +
'AJanKIr/B/VRCopnaz3lAAAAAElFTkSuQmCC';
