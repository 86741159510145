import { Vaccine } from '@shared/interfaces/vaccines.interface';
import { patientTopCard } from '@shared/helpers/pdf-patient-card.helper';

export const buildContentPdfVaccines = (paperwork, group) => {
  const {rows}   = group;

  const VACCINES = rows[0].vaccines
      ? rows[0].vaccines.filter(vaccine => vaccine.isChecked === true).map((vaccine: Vaccine) => [
        {ul: [`${vaccine.name}`], bold: true},
        {text: ` ${vaccine.glosa}`, margin: [13, 0]},
        {text: ` ${vaccine.category}`, margin: [13, 0]},
        {text: ` ${vaccine.subcategory}`, margin: [13, 0, 0, 5]},
        {text: ` Dosis: ${vaccine.dose ? vaccine.dose : '1'}`, margin: [13, 0, 0, 5]},
      ])
      : [];

  const vacBuildPdf = rows.map((item) => {
        const {diagnosis, observations} = item;
        const DIAGNOSIS                 =
        diagnosis !== null && diagnosis[0] !== null && diagnosis[0] !== ''
        ? [{text: `Diagnóstico:`, bold: true, margin: [0, 5]}, {text: [{text: `   ${diagnosis} `}]}, '\n']
        : [];
        const OBSERVATIONS              =
                  observations !== null && observations !== ''
                      ? [
                        {
                          text: ['\n', {text: `Observaciones: `, bold: true, margin: [0, 5]}, {text: `${observations}`}],
                        },
                      ]
                      : '';
        return [
          {
            ul: [VACCINES],
          },
          DIAGNOSIS,
          OBSERVATIONS,
        ];
      },
  );

  return [...patientTopCard(paperwork), vacBuildPdf];
};
