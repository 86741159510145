import { environment } from '@environments/environment';

export const HEADER_INFO = 'info';
export const HEADER_ERROR = 'danger';
export const HEADER_SUCCESS = 'success';
export const HEADER_WARNING = 'warning';
export const HEADER_REMINDER = 'reminder';

const OK_BUTTON = [{text: 'OK', role: 'confirm'}];

export const NOTIFICATION_OPTIONS = [
  {
    type    : HEADER_ERROR,
    header  : 'Error',
    icon    : 'close-sharp',
    cssClass: 'app-prompt-danger',
    button  : OK_BUTTON,
  },
  {
    type    : HEADER_SUCCESS,
    header  : 'Éxito',
    icon    : 'checkmark-sharp',
    cssClass: 'app-prompt-success',
    button  : OK_BUTTON,
  },

  {
    type    : HEADER_INFO,
    header  : 'Información',
    icon    : 'information-sharp',
    cssClass: 'app-prompt-info',
    button  : OK_BUTTON,
  },

  {
    type    : HEADER_WARNING,
    header  : 'Advertencia',
    icon    : 'alert-sharp',
    cssClass: 'app-prompt-warning',
    button  : OK_BUTTON,
  },

  {
    type    : HEADER_REMINDER,
    header  : 'Recordatorio',
    icon    : 'alert-sharp',
    cssClass: 'app-prompt-reminder',
    button  : OK_BUTTON,
  },
];

export const MESSAGES = {
  error: {
    icon          : 'close-circle-outline',
    color         : 'danger',
    label         : 'Error',
    ui            : 'Ocurrió un error',
    end           : 'Ocurrió un error, durante la ejecución del proceso.',
    login         : 'Ocurrió un error, credenciales inválidas',
    sendMail      : 'Ocurrió un error al enviar correo electrónico.',
    signPDF       : 'Ocurrió un error al intentar firmar el documento.',
    storageS3     : 'Ocurrió un error al intentar almacenar el documento.',
    saveInDB      : 'Ocurrió un error al guardar el registro en la base de datos.',
    presignedURL  : 'Ocurrió un error al obtener permisos para almacenar documentos.',
    expiredToken  : 'Ocurrió un error, el código de verificación expiró o no es válido.',
    changePassword: 'Ocurrió un error mientras se buscaban datos del profesional con este número de documento.',
    disabledUser  : 'Ocurrió un error, sus accesos están temporalmente bloqueados o no tiene un perfil asociado.',
    notFound      : 'Ocurrió un error, el servicio no devolvió resultados.',
    nextControl   : 'Ocurrió un error al enviar correo de proximo control.',
  },

  success: {
    icon        : 'checkmark-circle-outline',
    color       : 'success',
    label       : 'Exito',
    ui          : 'Cargado...',
    end         : 'El proceso se completó con éxito.',
    signPDF     : 'Documento firmado con éxito.',
    saveInDB    : 'Registro guardado con éxito.',
    storageS3   : 'Documento almacenado con éxito.',
    sendMail    : 'Correo electrónico enviado con éxito.',
    sendMailEmpty : 'Correo electrónico procesado.',
    presignedURL  : 'Se obtuvieron permisos para almacenar documentos con éxito.',
    nextControl   : 'Correo de próximo control enviado correctamente.',
    updatedPatient: 'Paciente actualizado con éxito',
  },

  warning: {
    icon : 'alert-circle-outline',
    color: 'warning',
    label: 'Alerta',
  },

  reminder: {
    icon : 'notifications-circle-outline',
    color: 'secondary',
    label: 'Recordatorio',

  },

  info: {
    icon          : 'information-circle-outline',
    color         : 'secondary',
    label         : 'Información',
    ui            : 'Cargando...',
    logout        : 'Su sesión expiró, acceda nuevamente con sus credenciales.',
    unsetPatient  : 'Debe seleccionar un paciente para continuar.',
    unsetSubprofessional: 'Debe seleccionar un profesional para continuar.',
    presignedURL  : 'Estamos solicitando permisos para almacenar documentos.',
    contactSupport: `Contacte al equipo de soporte: ${environment.supportEmail}.`,
    empty         : 'No se encontraron registros.',
    timeout       : 'Su sesión esta por expirar, ¿quiere mantenerla activa?.'
  },
};
