import { Form } from '@clinical/forms/interfaces/form.interface';
import { Action, createReducer, on } from '@ngrx/store';

import {
  CLEAR_FORM,
  DELETE_FORM,
  DELETE_FORM_ERR,
  DELETE_FORM_OK,
  LOAD_FORMS_BY_PATIENT,
  LOAD_FORMS_BY_PATIENT_ERR,
  LOAD_FORMS_BY_PATIENT_OK,
  LOAD_FORMS_BY_PROFESSIONAL,
  LOAD_FORMS_BY_PROFESSIONAL_ERR,
  LOAD_FORMS_BY_PROFESSIONAL_OK,
  PUT_FORM,
  PUT_FORM_END,
  PUT_FORM_ERR,
  PUT_FORM_OK,
  SELECTED_FORM,
  UNSELECT_FORM,
} from '@store/actions';
import { Paperwork } from '@shared/interfaces/paperwork.interface';

export interface FormState {
  error: any;
  status: string;
  isLoaded: boolean;
  isLoading: boolean;
  forms: Paperwork[];
  historical: Paperwork[];
  selected: Paperwork;
  lastForm: Paperwork;
  current: Paperwork;
  isSaving: boolean;
}

export const formState: FormState = {
  error: null,
  status: '',
  isLoaded: false,
  isLoading: false,
  forms: [],
  historical: [],
  selected: null,
  lastForm: null,
  current: null,
  isSaving: false,
};

const createReducerForm = createReducer(
  formState,

  // Obtener los formularios por paciente
  on(LOAD_FORMS_BY_PROFESSIONAL, (state) => ({ ...state, isLoading: true })),
  on(LOAD_FORMS_BY_PROFESSIONAL_OK, (state, { forms: forms }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    historical: [...forms],
  })),
  on(LOAD_FORMS_BY_PROFESSIONAL_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  // Obtener los formularios por profesional
  on(LOAD_FORMS_BY_PATIENT, (state) => ({ ...state, isLoading: true })),
  on(LOAD_FORMS_BY_PATIENT_OK, (state, { forms: forms }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    forms: [...forms],
  })),
  on(LOAD_FORMS_BY_PATIENT_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(PUT_FORM_END, (state) => ({ ...state, isSaving: false })),
  on(PUT_FORM, (state) => ({ ...state, isLoading: true, status: 'Guardando el registro.' })),
  on(PUT_FORM_OK, (state, { form }) => {
    let forms = state.forms.map((ans) => {
      if (ans.id === form.id) return form;
      else return ans;
    });
    let history = state.historical.map((ans) => {
      if (ans.id === form.id) return form;
      else return ans;
    });

    const index = forms.findIndex((draft) => draft.id === form.id);
    if (index === -1) forms = [form, ...forms];
    const indexHistory = history.findIndex((draft) => draft.id === form.id);
    if (indexHistory === -1) history = [form, ...history];

    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      isSaving: true,
      status: 'Registro guardado con éxito',
      forms: [...forms],
      historical: [...history],
      lastForm: { ...form },
      current: { ...form },
    };
  }),
  on(PUT_FORM_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    current: null,
    isSaving: false,
    status: 'Ocurrió un error al intentar guardar el registro',
    error: { ...payload },
  })),

  on(SELECTED_FORM, (state, { selected, action }) => ({ ...state, selected: { ...selected }, status: action })),
  on(UNSELECT_FORM, (state) => ({
    ...state,
    isLoaded: false,
    isLoading: false,
    status: '',
    selected: null,
  })),

  on(DELETE_FORM, (state) => ({ ...state, isLoading: true })),
  on(DELETE_FORM_OK, (state, { form }) => {
    let forms = [...state.forms];
    let history = [...state.historical];
    const index = forms.findIndex((draft) => draft.id === form.id);
    const indexHistory = history.findIndex((draft) => draft.id === form.id);
    if (index !== -1) forms = [...forms.filter((draft) => draft.id !== form.id)];
    if (indexHistory !== -1) history = [...history.filter((draft) => draft.id !== form.id)];

    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      status: 'Formulario eliminado con éxito',
      forms: [...forms],
      historical: [...history],
    };
  }),
  on(DELETE_FORM_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    status: 'Ocurrio un error al intentar eliminar el formulario',
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(CLEAR_FORM, (state) => ({
    forms: [...state.forms],
    historical: [...state.historical],
    isLoading: false,
    isLoaded: false,
    error: null,
    status: '',
    selected: null,
    lastForm: null,
    current: null,
    isSaving: false,
  })),
);

export const formReducer = (state: FormState, action: Action) => createReducerForm(state, action);
