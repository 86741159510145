import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstWord',
})
export class FirstWordPipe implements PipeTransform {
  transform(value: string): string {
    if (value && value.length > 0) return value.split(' ').slice(0, 1).join();
    return value;
  }
}
