import { patientTopCard } from '@shared/helpers/pdf-patient-card.helper';
import htmlToPdfmake from 'html-to-pdfmake';


export const buildContentPdfEpicrisisPatient = (paperwork, group) => {
  const { rows } = group;

  return rows.map((itemForm) => [
    ...patientTopCard(paperwork),

    { text: `Condición Alta:  ${itemForm?.condicion_alta['#text']}`, bold: true, fontSize: 14, margin: [0, 0, 0, 0] },


    {
      table: {
        widths: [100, 'auto'],
        body: [
          [{ text: 'Fecha Ingreso:' }, { text: `${itemForm?.estadia.fecha_ingreso}`, margin: [100, 0] }],
          [{ text: 'Fecha Egreso:' }, { text: `${itemForm?.estadia.fecha_egreso}`, margin: [100, 0] }],
          [{ text: 'Unidad de Ingreso:' }, { text: `${itemForm?.estadia.unidad_ingreso}`, margin: [100, 0] }],
          [{ text: 'Unidad de Egreso:' }, { text: `${itemForm?.estadia.unidad_egreso}`, margin: [100, 0] }],
        ],
      },
      layout: 'noBorders'
    },

    { text: `Diagnostico:`, bold: true, fontSize: 14, margin: [0, 10, 0, 0] },
    htmlToPdfmake(` ${itemForm?.diag} `, { ignoreStyles : [ 'font-family' ] }),

    { text: `Diagnostico Alta:`, bold: true, fontSize: 14, margin: [0, 10, 0, 0] },
    htmlToPdfmake(` ${itemForm?.diagnostico_alta} `, { ignoreStyles : [ 'font-family' ] }),

    { text: `Evolución:`, bold: true, fontSize: 14, margin: [0, 10, 0, 0] },
    htmlToPdfmake(` <p style="text-align:justify;"> ${itemForm?.evolucion} </p>`, { ignoreStyles : [ 'font-family' ] }),

    { text: `Indicaciones Alta:`, bold: true, fontSize: 14, margin: [0, 10, 0, 0] },
    htmlToPdfmake(` ${itemForm?.indicaciones_alta} `, { ignoreStyles : [ 'font-family' ] }),

    { text: `Motivo:`, bold: true, fontSize: 14, margin: [0, 10, 0, 0] },
    htmlToPdfmake(`<p style="text-align:justify;"> ${itemForm?.motivo}  </p>`, { ignoreStyles : [ 'font-family' ]}),



    //{ text: `Doctor:  ${itemForm?.doctor}`, bold: true, fontSize: 14, margin: [0, 10, 0, 0] },
    //{ text: `Medico:  ${itemForm?.medico?.nombre_completo}`, bold: true, fontSize: 14, margin: [0, 10, 0, 0] },
    //{ text: `Medico Aprueba:  ${itemForm?.medicoaprueba}`, bold: true, fontSize: 14, margin: [0, 10, 0, 0] },

  ]);
};
