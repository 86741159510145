export const TAGS_PRM = {
  drm: '6b4771f8-4f3a-46da-a6f6-af1800f088ef',
  otr: [
    {
      name: 'Evaluación avanzada de voz y laringe - Estroboscopía laríngea',
      tag : 'bac31273-6932-4449-b5f0-b05101512c5d',
    },
    {
      name: 'Comité de oído',
      tag : '49d0eaf9-16e2-4e07-8f48-b0510151422f',
    },
    {
      name: 'Comité de nariz',
      tag : '6b4771f8-4f3a-46da-a6f6-af1800f088ef',
    },
    {
      name: 'Evaluación Avanzada Rinosinusal - Videonasolaringoscopía',
      tag : '14746f9b-9e13-471d-b8ad-b08a015de66c',
    },
    {
      name: 'Comité de ronquido - Videonasolaringoscopía',
      tag : 'db615596-380d-46f0-89f2-b053013cee1a',
    },
  ],
};

export const EXAM_AREAS = {
  imagenes      : 'RIS_IMAGENES',
  laboratorio   : '1a2ec3c8-90f6-4b07-82ba-abbb017226bd',
  procedimientos: 'b2461ac2-9c50-4724-9676-a91a00a91a02',
  examen        : 'b2461ac2-9c50-4724-9676-a91a00a91a02',
  path          : 'prod/agenda/ordenesTmp',
  regionId      : '6bad9b25-d5df-4565-b5fe-a6f701444053'
};
