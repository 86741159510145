import {Injectable} from '@angular/core';
import {Appointment} from '@shared/interfaces/appointment.interface';
import {Email} from '@shared/interfaces/notification.interface';
import {Patient} from '@components/patient/interfaces/patient.interface';
import {PDF, PDFWrapper} from '@shared/interfaces/pdf.interface';
import {Professional} from '@auth/interfaces/professional.interface';
import {Attachment, PresignedUrlResponse, PresignedUrlRequest} from '@shared/interfaces/storage.interface';

import {Folders} from '@core/types/folders.type';
import {environment} from '@environments/environment';
import {FileService} from './file.helper';
import {SignDoc} from '@shared/interfaces/sign-interface';

const APP_DEFAULT_BUCKET = environment.defaultBucketS3;

@Injectable({
  providedIn: 'root',
})
export class Pdf {
  currentDate = new Date().toISOString();

  constructor(private fileService: FileService) {
  }

  patient(patient: Patient) {
    if (!patient) return undefined;
    return {
      id            : patient.id,
      names         : patient.names,
      surnames      : patient.surnames,
      documentNumber: patient.documentNumber,
      documentType  : patient.documentType,
      email         : patient.email,
      birth         : patient.birth,
      address       : patient.address,
      age           : '',
      gender        : patient.gender,
      phone         : patient.phone,
    };
  }

  professional(professional: Professional) {
    if (!professional) return undefined;
    const {id, names, surnames, rut, specialty, email, signCode} = professional;
    return {id, names, surnames, rut, specialty, email, signCode};
  }

  //TODO: Esta interfaz corresponde a un shortAppoint
  appointment(appointment: Appointment) {
    if (!appointment) return undefined;
    const {id, type, fromDatetime, toDatetime, pay, center} = appointment;
    return {id, type, fromDatetime, toDatetime, pay, center};
  }

  // TODO: luego de terminar y probar la funcion getValuesToSave hacer los cambios en recipe y !eliminar este metodo
  recipe(pdfWrapper: PDFWrapper, presignedUrl: PresignedUrlResponse) {
    const {metadata}  = pdfWrapper;
    const {title}     = metadata;
    const attachments = {...presignedUrl, ext: 'pdf', filename: title, uploadDate: this.currentDate, category: 'rme'};
    return {
      patient      : this.patient(pdfWrapper.metadata.patient),
      professional : this.professional(pdfWrapper.metadata.professional),
      appointment  : this.appointment(pdfWrapper.metadata.appointment),
      prescriptions: [...metadata.valuesToSave],
      attachments  : [attachments],
    };
  }

  getValuesToSave(pdfs: PDF[], domain: any, path: Folders) {
    const attachments: Attachment[] = this.getAttachments(pdfs, path);
    return {
      ...domain,
      attachments,
      patient     : this.patient(domain.patient),
      professional: this.professional(domain.professional),
      appointment : this.appointment(domain.appointment),
    };
  }

  getAttachments(pdfs: PDF[], path: Folders): Attachment[] {
    return pdfs.map((pdf) => ({
      path,
      ext       : 'pdf',
      key       : pdf.key,
      bucket    : APP_DEFAULT_BUCKET,
      uploadDate: this.currentDate,
      category  : path,
      isAttached: true,
    }));
  }

  formDataToDocSign(pdfWrapper: PDFWrapper, file: string, signType: string, path: Folders): SignDoc {
    const {professional, shortId}                 = pdfWrapper.metadata.valuesToSave;
    const {names, surnames, email, rut, signCode} = professional;
    const base64                                  = file.split(',')[1];
    return {
      file            : base64,
      firstNames      : names,
      lastNames       : surnames,
      email,
      rut,
      professionalCode: signCode,
      type            : signType,
      path,
      prefix          : 'rme',
      bucket          : APP_DEFAULT_BUCKET,
      shortId,
    };
  }

  // TODO: Eliminar cuando se cree el metodo generico para enviar notificaciones
  getParamsToSendMail(pdfWrapper: PDFWrapper, subject: string, template: string) {
    const {email} = this.patient(pdfWrapper.metadata.patient);

    const paramsToSendMail: Email = {
      subject,
      to     : [email],
      content: template,
    };

    return paramsToSendMail;
  }

  getParamsToPresignedUrl(ext: string, path: string) {
    const bodyMail: PresignedUrlRequest = {
      ext,
      path,
      bucket: APP_DEFAULT_BUCKET,
    };
    return bodyMail;
  }

  // async getFormDataToS3Upload(pdfWrapper: PDFWrapper, objectKey: string, docSingDataUrl: string) {
  //   const { title } = pdfWrapper.metadata;

  //   const file: Blob = await this.fileService.dataUrlToFile(docSingDataUrl, title);
  //   const formData = new FormData();
  //   formData.append('body', file, objectKey);

  //   return formData;
  // }

  async getFormDataToS3Upload(pdfWrapper: PDFWrapper, objectKey: string, docSingDataUrl: string) {
    const {title}    = pdfWrapper.metadata;
    const file: Blob = await this.fileService.dataUrlToFile(docSingDataUrl, title);
    return file;
  }

  async visualize(dataUrl: string): Promise<boolean> {
    const targetElement             = document.querySelector('#iframeContainer');
    const NOT_CREATE_AND_LOADED_PDF = false;
    targetElement.innerHTML         = '';
    if (!targetElement) return false;
    const object  = document.createElement('object');
    object.data   = dataUrl + '#navpanes=0';
    object.width  = '100%';
    object.height = '100%';

    const h5 = document.createElement('h6');
    h5.setAttribute('class', 'ion-padding');
    object.setAttribute('class', 'ion-text-center');
    const contentInnerObject = document.createTextNode(`Su navegador web no tiene un complemento para abrir PDF.`);

    const button       = document.createElement('ion-button');
    const fileName     = 'uc-christus.pdf';
    button.href        = dataUrl;
    button.target      = '_blank';
    button.download    = fileName;
    button.textContent = 'Descargar PDF';
    button.setAttribute('class', 'ion-margin');
    button.setAttribute('expand', 'block');

    h5.appendChild(contentInnerObject);
    object.appendChild(h5);
    object.appendChild(button);
    targetElement.appendChild(object);

    const isLoadPdf = await this.isLoaded(object, button);
    if (isLoadPdf) return isLoadPdf;
    else return NOT_CREATE_AND_LOADED_PDF;
  }

  isLoaded(iframe: any, button: any): Promise<boolean> {
    return new Promise((resolve) => {
      try {
        const isSafari =
                navigator.vendor &&
                navigator.vendor.indexOf('Apple') > -1 &&
                navigator.userAgent &&
                navigator.userAgent.indexOf('CriOS') === -1 &&
                navigator.userAgent.indexOf('FxiOS') === -1;

        const handleLoad = (ev) => resolve(true);
        iframe.addEventListener('load', handleLoad, false);
        button.addEventListener('click', handleLoad);
      } catch (error) {
        resolve(false);
      }
    });
  }
}
