import {DEFAULT_PDF_POSITION_SIGNATURE} from '@shared/constants/app.const';
import {PrintedOnPage, PrintMode} from '@shared/interfaces/paperwork.interface';
import {DELETE_FORM, PUT_FORM} from '@store/actions';
import { DELETE_DISCHARGE_MEDICAL, PUT_DISCHARGE_MEDICAL } from '../store/clinical-discharge.action';
import { buildContentPdfEpicrisisFilmaker } from '../utils/buil-pdf-epicrisis-isuc';
import { buildContentPdfEpicrisisPatient } from '../utils/build-pdf-epicrisis-patient';
import {buildContentPdfLightRegime} from '../utils/build-pdf-light-regime';
import {buildContentPdfNurseEpicrisis} from '../utils/build-pdf-nurse-epicrisis';
import {buildContentPdfPapillaryRegime} from '../utils/build-pdf-papillary-regime';
import {buildContentPdfResidueFreeRegimen} from '../utils/build-pdf-residue-free-regimen';

const NACIONAL = 'n';

const workflow = {
  isSigned     : true,
  isStored     : true,
  isSaving      : true,
  hasNextControl: true,
  notifications: [
    {
      type    : 'email',
      entity  : 'patient',
      subject : 'Notificaciones UC CHRISTUS | Formulario Médico',
      template: 'generic',
    },
  ],
};

const pdf = {
  isPreview: true,
  header   : {logo: PrintedOnPage.all, title: PrintedOnPage.all, professional: PrintedOnPage.all},
  patient  : {type: PrintMode.full, printed: PrintedOnPage.all},
  footer   : {
    signature: {printed: PrintedOnPage.all, position: DEFAULT_PDF_POSITION_SIGNATURE},
    qr       : {right: {url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web'}},
  },
};

export const PAPERWORK_MEDICAL_DISCHARGE_DEFINITION = {
  prefix     : 'rem',
  module     : 'alta',
  actions    : {
    put   : PUT_DISCHARGE_MEDICAL,
    delete: DELETE_DISCHARGE_MEDICAL,
  },
  lightRegime: {
    suffix           : 'FRL',
    group            : 'formulario regimen liviano',
    docTitle         : 'Régimen Liviano',
    submodule        : 'regimen-liviano',
    getBodyDefinition: buildContentPdfLightRegime,
    workflow,
    config           : pdf,
  },

  residueFreeRegimen: {
    suffix           : 'RSR',
    group            : 'formulario regimen sin residuos',
    docTitle         : 'Régimen Sin Residuos',
    submodule        : 'regimen-sin-residuos',
    getBodyDefinition: buildContentPdfResidueFreeRegimen,
    workflow,
    config           : pdf,
  },

  papilaryRegime: {
    suffix           : 'FRP',
    group            : 'formulario regimen papilla',
    docTitle         : 'Régimen Papilla',
    submodule        : 'regimen-papilla',
    getBodyDefinition: buildContentPdfPapillaryRegime,
    workflow,
    config           : pdf,
  },


  nursingCare: {
    suffix           : 'CDE',
    group            : 'formulario cuidados de enfermeria',
    docTitle         : 'cuidados de enterfería posteriores al alta',
    submodule        : 'epicrisis-enfermeria',
    getBodyDefinition: buildContentPdfNurseEpicrisis,
    workflow,
    config           : pdf,
  },

  epicrisisIsuc: {
    suffix           : 'epi',
    group            : 'documento epicrisis',
    docTitle         : 'epicrisis',
    submodule        : 'n',
    getBodyDefinition: buildContentPdfEpicrisisPatient,
    workflow: {
      isSigned     : true,
      isStored     : true,
      isSaving      : true,
      hasNextControl      : true,
      notifications: [
        {
          type    : 'email',
          entity  : 'patient',
          subject : 'Notificaciones UC CHRISTUS | Formulario Médico',
          template: 'generic',
        },
      ],
    },
    config           : {
      isPreview: true,
      header   : {logo: PrintedOnPage.all, title: PrintedOnPage.all, professional: PrintedOnPage.all},
      patient  : {type: PrintMode.full, printed: PrintedOnPage.all},
      footer   : {
        signature: {printed: PrintedOnPage.none, position: DEFAULT_PDF_POSITION_SIGNATURE},
        qr       : {right: {url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web'}},
      },
    },
  },

  epicrisisFilmaker: {
    suffix           : 'epi',
    group            : 'documento epicrisis',
    docTitle         : 'epicrisis',
    submodule        : 'n',
    getBodyDefinition: buildContentPdfEpicrisisFilmaker,
    workflow: {
      isSigned     : true,
      isStored     : true,
      isSaving      : true,
      hasNextControl      : true,
      notifications: [
        {
          type    : 'email',
          entity  : 'patient',
          subject : 'Notificaciones UC CHRISTUS | Formulario Médico',
          template: 'generic',
        },
      ],
    },
    config           : {
      isPreview: true,
      header   : {logo: PrintedOnPage.all, title: PrintedOnPage.all, professional: PrintedOnPage.all},
      patient  : {type: PrintMode.full, printed: PrintedOnPage.all},
      footer   : {
        signature: {printed: PrintedOnPage.none, position: DEFAULT_PDF_POSITION_SIGNATURE},
        qr       : {right: {url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web'}},
      },
    },
  },


};
