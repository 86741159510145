import { patientTopCard } from '@shared/helpers/pdf-patient-card.helper';

export const buildContentPdfEyeExam = (paperwork, group) => {
  const {rows} = group;

  const eyeExaBuildPdf = rows.map((exam) => {
    const {od, os, diagnosis, exams, observations} = exam;
    const DIAGNOSIS = diagnosis.map((diag) => (diag ? { text: `${diag}`, bold: true } : null));
    const EXAMS                                    = exams.map((exm) => {
      //validaciones en los campos booleanos
      let ODI = `${exm.odi}`;
      if (exm.odi === false) ODI = 'NO';
      else ODI = 'SI';

      let SS24 = `${exm['ss24-2']}`;
      if (exm['ss24-2'] === false) SS24 = 'NO';
      else SS24 = 'SI';

      let SS10 = `${exm['ss10-2']}`;
      if (exm['ss10-2'] === false) SS10 = 'NO';
      else SS10 = 'SI';

      let OTHER = `${exm.otro}`;
      if (exm.otro === false) OTHER = 'NO';
      else OTHER = 'SI';

      let ECOBIO = `${exm.ecobiometria}`;
      if (exm.ecobiometria === false) ECOBIO = 'NO';
      else ECOBIO = 'SI';

      let IOL = `${exm['iol-master']}`;
      if (exm['iol-master'] === false) IOL = 'NO';
      else IOL = 'SI';

      let MACULA = `${exm.macula}`;
      if (exm.macula === false) MACULA = 'NO';
      else MACULA = 'SI';

      let OPTICO = `${exm.optico}`;
      if (exm.optico === false) OPTICO = 'NO';
      else OPTICO = 'SI';

      let AUTO_FLUO = `${exm['auto-fluo']}`;
      if (exm['auto-fluo'] === false) AUTO_FLUO = 'NO';
      else AUTO_FLUO = 'SI';

      let ONE_DAY = `${exm['one-day']}`;
      if (exm['one-day'] === false) ONE_DAY = 'NO';
      else ONE_DAY = 'SI';

      let TWO_DAYS = `${exm['two-days']}`;
      if (exm['two-days'] === false) TWO_DAYS = 'NO';
      else TWO_DAYS = 'SI';

      let PENTACAM = `${exm.pentacam}`;
      if (exm.pentacam === false) PENTACAM = 'NO';
      else PENTACAM = 'SI';

      let TOPOLYZER = `${exm.topolyzer}`;
      if (exm.topolyzer === false) TOPOLYZER = 'NO';
      else TOPOLYZER = 'SI';

      let EST_LAGRIMA = `${exm['est-lagrima']}`;
      if (exm['est-lagrima'] === false) EST_LAGRIMA = 'NO';
      else EST_LAGRIMA = 'SI';

      let D = `${exm.d}`;
      if (exm.d === false) D = 'NO';
      else D = 'SI';

      let I = `${exm.i}`;
      if (exm.i === false) I = 'NO';
      else I = 'SI';

      let FLUOR = `${exm.fluorescein}`;
      if (exm.fluorescein === false) FLUOR = 'NO';
      else FLUOR = 'SI';

      let INDOCAININE = `${exm.indocainine}`;
      if (exm.indocainine === false) INDOCAININE = 'NO';
      else INDOCAININE = 'SI';

      let MACULAR = `${exm.macular}`;
      if (exm.macular === false) MACULAR = 'NO';
      else MACULAR = 'SI';

      let OPT_NERVE = `${exm['optic-nerve']}`;
      if (exm['optic-nerve'] === false) OPT_NERVE = 'NO';
      else OPT_NERVE = 'SI';

      let FRONT_POLE = `${exm['front-pole']}`;
      if (exm['front-pole'] === false) FRONT_POLE = 'NO';
      else FRONT_POLE = 'SI';
      let subFrm = {};

      if (exm.name === 'CAMPO VISUAL GOLDMAN') subFrm = [{text: ` ODI: ${ODI} - Solo: ${exm.solo}`}];

      if (exm.name === 'CAMPIMETRÍA COMPUTARIZADA')
        subFrm = [
          {
            text: ` ODI: ${ODI} Solo: ${exm.solo} - SS24-2: ${SS24} - SS10-2: ${SS10} - OTRO: ${OTHER}`,
          },
        ];

      if (exm.name === 'BIOMETRÍA CON CÁLCULO DE LENTE INTRAOCULAR, AMBOS OJOS')
        subFrm = [{text: ` IOL MASTER: ${IOL} - ECOBIOMETRÍA ULTRASÓNICA: ${ECOBIO}`}];

      if (exm.name === 'RETINOGRAFÍA')
        subFrm = [{text: ` MÁCULA: ${MACULA} - N. ÓPTICO: ${OPTICO} - AUTO FLUO (BAF): ${AUTO_FLUO}`}];

      if (exm.name === 'CURVA DE TENSIÓN APLANATICA') subFrm = [{text: ` 1 DÍA: ${ONE_DAY} - 2 DÍAS: ${TWO_DAYS}`}];

      if (exm.name === 'TOPOGRAFÍA CORNEAL COMPUTARIZADA C/OJO')
        subFrm = [{text: ` PENTACAM: ${PENTACAM} - TOPOLYZER: ${TOPOLYZER} - EST. LÁGRIMA: ${EST_LAGRIMA}`}];

      if (exm.name === 'ANGIOGRAFÍA DE RETINA')
        subFrm = [
          {
            text: ` PRIMERO: ${exm.first} - D: ${D} - I: ${I} - FLUORESCEINA: ${FLUOR} - INDOCIANINA: ${INDOCAININE}`,
          },
        ];

      if (exm.name === 'OCT: TOMOGRAFÍA DE COHERENCIA ÓPTICA')
        subFrm = [{text: ` MACULAR: ${MACULAR} - NERVIO ÓPTICO: ${OPT_NERVE} - POLO ANTERIOR: ${FRONT_POLE}`}];

      const fields = {...exm};

      delete fields.id;
      delete fields.name;

      return [exm.id, exm.name, subFrm];
    });
    let EXAMS_TABLE                                = {
      table: {
        headerRows: 1,
        widths    : [70, 'auto', '*'],

        body: [['Cod. Fonasa', 'Examen', ''], ...EXAMS],
      },
    };

    if (!exams.length) EXAMS_TABLE = undefined;

    return [
      {text: `LENTES P/C:`, bold: true, alignment: 'justify', fontSize: 16},
      {canvas: [{type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1}]},
      {text: '\n'},

      {
        layout: 'noBorders',
        table : {headerRows: 1, widths: [60, '*', 60, '*'], body: [['OD:', `${od}`, 'OI:', `${os}`]]},
      },
      {text: `Diagnóstico:`},
      ...DIAGNOSIS,
      {text: '\n\n'},
      EXAMS_TABLE,
      {text: '\n\n'},
      {text: `Observaciones:`},
      { text: `${observations || ''}`, bold: true, alignment: 'justify' },
    ];
  });

  return [...patientTopCard(paperwork), eyeExaBuildPdf];
};
