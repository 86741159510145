import { HttpErrorResponse } from '@angular/common/http';
import { Component }         from '@angular/core';

import { AuthService }    from 'src/app/auth/services/auth.service';
import { UserCredential } from '../../interfaces/credentials.interface';
import { IonicStorageService } from '@shared/services/ionic-storage.service';
import { AUTH_ROUTES } from '@auth/auth-routing.module';

@Component({
  selector   : 'app-login',
  templateUrl: './login.component.html',
  styleUrls  : ['./login.component.scss'],
})
export class LoginComponent {
  isLoading: boolean;
  error: HttpErrorResponse;
  routePageToResetPassword = `/${AUTH_ROUTES.auth}/${AUTH_ROUTES.resetPassword}`;

  typeInput = 'password';
  iconInput = 'eye';

  credentials: UserCredential = {
    username: '',
    password: '',
  };

  constructor(public authService: AuthService, private ionicStorageService: IonicStorageService) {}

  async ionViewWillEnter() {
    await this.clearSession();
  }

  /**
   * Verificar si se ingresó el usuario y contraseña en el formulario de login.
   */
  isValid(): boolean {
    const { username, password } = this.credentials;
    const isLoading = !this.authService.isLoading;
    return !!(username && password && isLoading);
  }

  viewPass() {
    this.typeInput = this.typeInput === 'password' ? 'text' : 'password';
    this.iconInput = this.iconInput === 'eye' ? 'eye-off' : 'eye';
  }

  private clearSession() {
    return this.ionicStorageService.clear().toPromise();
  }
}
