import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

// Cambiar locale de la aplicación
import localeEsCL from '@angular/common/locales/es-CL';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouteReuseStrategy} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import {RecordsModule} from '@components/records/records.module';
import {AuthInterceptor} from '@core/interceptors/auth-interceptor.service';
import {environment} from '@environments/environment';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {Drivers} from '@ionic/storage';
import {IonicStorageModule} from '@ionic/storage-angular';
import {LoaderComponent} from '@layout/loader/loader.component';
import {EffectsModule} from '@ngrx/effects';

/* Modulos de NGRX */
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';

// Establecer configuración para Redux
import {appReducers} from '@store/app.reducers';
import {appStoreDevtoolsConfig} from '@store/config-devtools';
import {appEffects} from '@store/effects';

const configIonicStorageModule = {
  name: 'PCv3',
  driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage],
};

const optionsServiceWorkerModule = {
  enabled: environment.production,
  navigationRequestStrategy: 'freshness',
  registrationStrategy: 'registerWhenStable:30000',
};
registerLocaleData(localeEsCL);

@NgModule({
  declarations: [AppComponent, LoaderComponent],
  imports: [
    RecordsModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    BrowserAnimationsModule,
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot(appEffects),
    IonicStorageModule.forRoot(configIonicStorageModule),
    StoreDevtoolsModule.instrument(appStoreDevtoolsConfig),
    ServiceWorkerModule.register('ngsw-worker.js', optionsServiceWorkerModule),
    AppRoutingModule,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'es-CL'},
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
