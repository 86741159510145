export const TENS_PROFILES = [
    'ACC#TENS#Tens',
    'ACC#TENS#TensSaludIntegral',
];

export const TENS_PROFILES_NO_PATIENT_MAIL = [
    'ACC#TENS#TensSaludIntegral',
];

export const EXCLUDE_PROFILES = [
    'ACC#TENS#Tens',
    'ACC#TENS#TensSaludIntegral',
    'ACC#ADMISSION#admission',
    'ACC#ADMISSION#admissionSimple',
];
