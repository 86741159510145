import {patientTopCard} from '@shared/helpers/pdf-patient-card.helper';
import {addHours, format} from 'date-fns';
import {ProcedurePRM} from '../../../shared/interfaces/surgery.interface';

export const buildContentPdfSurgeryKeiron = (paperwork, group, showObservationForBudgets: boolean = false) => {
  const {rows} = group;

  const surgeryBuildPdf = rows.map((item) => {
    const IMPLANTS = {text: `Implante:`};
    if (!item.implants) IMPLANTS.text = '';

    const PROVIDER = {text: `Proveedor:`};
    if (!item.provider) PROVIDER.text = '';

    const OBS_PAT_TEXT = {text: `Observaciones a Paciente: `};
    if (!item.observations.patient) OBS_PAT_TEXT.text = '';
    const OBS_PAT_VAL = {text: `${item.observations.patient}`};
    if (!item.observations.patient) OBS_PAT_VAL.text = '';

    const OBS_INT_TEXT = {text: `Observaciones Internas: `};
    if (!item.observations.others) OBS_INT_TEXT.text = '';
    const OBS_INT_VAL = {text: `${item.observations.others}`};
    if (!item.observations.others) OBS_INT_VAL.text = '';

    const OBS_PRE_TEXT = {text: `Observaciones Para Presupuestos: `};
    if (!item.observations.budget) OBS_PRE_TEXT.text = '';
    const OBS_PRE_VAL = {text: `${item.observations.budget}`};
    if (!item.observations.budget) OBS_PRE_VAL.text = '';

    const ANESTHESIA_TXT = {text: `Anestesia: `};
    if (!item.anesthesia) ANESTHESIA_TXT.text = '';
    const ANESTHESIA_TXT_VAL = {text: `${item.anesthesia}`};
    if (!item.anesthesia) ANESTHESIA_TXT_VAL.text = '';

    const ROBOTIC_TEXT = {text: `Cirugía Robótica: `};
    const ROBOTIC_VAL = {text: `${item.roboticSurgery}`};
    if (item.roboticSurgery === false) ROBOTIC_VAL.text = 'No';
    else ROBOTIC_VAL.text = 'Si';

    const DONOR_VAL = {text: `${item.donorBlood}`};
    if (item.donorBlood === false) DONOR_VAL.text = 'No';
    else DONOR_VAL.text = `Si (${item.totalDonorBlood})`;

    const ALLERGY_VAL = {text: `${item.hasAllergy}`};
    if (item.hasAllergy === false) ALLERGY_VAL.text = 'No';
    else ALLERGY_VAL.text = `${item.allergy}`;

    const PRECAUTION_TEXT = {text: `Precauciones Adicionales: `};
    if (!item.additionalPrecautions) PRECAUTION_TEXT.text = '';
    const PRECAUTION_VAL = {text: `${item.additionalPrecautions}`};
    if (!item.additionalPrecautions) PRECAUTION_VAL.text = '';

    const DATE_TEXT = {text: `${item.dateLabel}`};
    if (!item.dateLabel) DATE_TEXT.text = '';
    let DATE_VAL = {text: ``};
    if (!item.dateHospitalization) DATE_VAL.text = '';
    else if (item.dateHospitalization === 'Fecha será evaluada por paciente') DATE_VAL = {text: ''};
    else if (item.dateHospitalization === 'Acordada con Paciente') DATE_VAL = {text: ''};
    else if (item.dateHospitalization === 'Por definir') DATE_VAL = {text: ''};
    else DATE_VAL = {text: `${format(addHours(new Date(item.dateHospitalization), 6), 'dd-MM-yyyy')}`};

    const DATETO_TEXT = {text: ` - `};
    if (!item.dateHospitalizationTo) DATETO_TEXT.text = '';
    let DATETO_VAL = {text: ``};
    if (!item.dateHospitalizationTo) DATETO_VAL.text = '';
    else DATETO_VAL = {text: `${format(addHours(new Date(item.dateHospitalizationTo), 6), 'dd-MM-yyyy')}`};

    const PROCEDURES = item.procedures.map((procedure: ProcedurePRM) => [
      {text: `${procedure.glosa}`, bold: true},
      {text: `Código Fonasa: ${procedure.fonasaId === 'nan' ? 'Sin Código' : procedure.fonasaId}`},
      {text: `Observaciones: ${procedure.observations || ''}`, alignment: 'justify'},
    ]);

    const VAL_AGREEMENTS = {text: ` `};
    if (item.agreements === 'Otro') VAL_AGREEMENTS.text = ` ${item.other}`;
    else VAL_AGREEMENTS.text = ` ${item.agreements}`;

    const CENTERS = {text: `${item.center.join(', ')}\n`, margin: [100, 0]};

    const observationForBudgets = [
      {text: 'Observaciones para Presupuestos:', margin: [0, 10, 0, 0], bold: true, fontSize: 12},
      {
        table: {
          widths: [350, 'auto'],
          body: [[{text: `${item.observationForBudgets}`, fontSize: 12, alignment: 'justify'}]],
        },
        layout: 'noBitems',
      },
    ];

    return [
      {
        table: {
          widths: [100, 'auto'],
          body: [
            [{text: 'Beneficiario:'}, VAL_AGREEMENTS],
            [{text: 'Diagnóstico(s):'}, {text: `${item.diagnosis}`, alignment: 'justify'}],
            [{text: 'Procedimiento:'}, PROCEDURES],
          ],
        },
        layout: 'noBorders',
      },

      {text: `Datos de Hopitalización`, bold: true, margin: [0, 10]},

      {
        table: {
          widths: [100, 'auto'],
          body: [
            [{text: 'Centro:'}, CENTERS],
            [{text: ROBOTIC_TEXT}, {text: ROBOTIC_VAL, margin: [100, 0]}],
            [
              {text: DATE_TEXT},
              {
                text: [DATE_VAL, DATETO_TEXT, DATETO_VAL],
                margin: [100, 0],
              },
            ],
            [{text: 'Tipo de estadia:'}, {text: `${item.stayType}`, margin: [100, 0]}],
            [{text: 'Prioridad:'}, {text: `${item.priority}`, margin: [100, 0]}],
            [{text: PRECAUTION_TEXT}, {text: PRECAUTION_VAL, margin: [100, 0]}],
            [{text: 'Alergias:'}, {text: ALLERGY_VAL, margin: [100, 0]}],
            [{text: PROVIDER}, {text: `${item.provider || ''}`, margin: [100, 0]}],
            [{text: IMPLANTS}, {text: `${item.implants || ''}`, margin: [100, 0], unbreakable: true}],
          ],
        },

        layout: 'noBorders',
      },
      {
        table: {
          widths: [300, 'auto'],
          body: [
            [
              {
                stack: [`Complejidad y días de estadía probable:`],
              },
              [
                {
                  table: {
                    body: [
                      ['Tipo', 'Días'],
                      [` UCI`, ` ${item.complexity.uci}`],
                      [` Intermedio`, ` ${item.complexity.intermediate}`],
                      [` Básica`, ` ${item.complexity.basic}`],
                    ],
                  },
                },
              ],
            ],
          ],
        },
        layout: 'noBorders',
      },

      {
        table: {
          widths: [200, 'auto'],
          body: [[{text: 'Requiere donante de sangre:'}, {text: DONOR_VAL, margin: [100, 0]}]],
        },
        layout: 'noBorders',
      },
      {
        text: 'Para agendar su hora, pinche aquí',
        link: 'https://agenda.ucchristus.cl/?area=7822cd9e-4552-4cb8-8313-abd00148111c&tipo=especialidad&especialidad=2ae24ffb-8c57-4943-adcd-abd400f8271c&servicio=02560c8e-0c28-4e88-9eb4-abd400f8b282&centro=abc810e8-4d24-4e13-aa8b-aa73016bed76',
      },
      {
        text: 'Para mayor información, pinche aquí',
        link: 'https://www.ucchristus.cl/especialidades-y-servicios/servicios/banco-de-sangre',
      },
      [
        {text: ANESTHESIA_TXT, margin: [0, 10, 0, 0], bold: true, fontSize: 12},
        {
          table: {
            widths: [350, 'auto'],
            body: [[{text: ANESTHESIA_TXT_VAL, fontSize: 12, alignment: 'justify'}]],
          },
          layout: 'noBorders',
        },
        {text: OBS_PAT_TEXT, margin: [0, 10, 0, 0], bold: true, fontSize: 12},
        {
          table: {
            widths: [350, 'auto'],
            body: [[{text: OBS_PAT_VAL, fontSize: 12, alignment: 'justify'}]],
          },
          layout: 'noBorders',
        },
        {text: OBS_INT_TEXT, margin: [0, 10, 0, 0], bold: true, fontSize: 12},
        {
          table: {
            widths: [350, 'auto'],
            body: [[{text: OBS_INT_VAL, fontSize: 12, alignment: 'justify'}]],
          },
          layout: 'noBorders',
        },

        {text: OBS_PRE_TEXT, margin: [0, 10, 0, 0], bold: true, fontSize: 12},
        {
          table: {
            widths: [350, 'auto'],
            body: [[{text: OBS_PRE_VAL, fontSize: 12, alignment: 'justify'}]],
          },
          layout: 'noBorders',
        },
      ],
      showObservationForBudgets ? observationForBudgets : '',

    ];

  });

  return [...patientTopCard(paperwork), surgeryBuildPdf];
};
