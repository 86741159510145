import { buildContentPdfRecipesMegistral } from '@clinical/medicines/utils/build-pdf-recipes-magistral';
import { MEDICINES_ROUTES } from '@clinical/medicines/medicines-routes.module';
import { DEFAULT_PDF_POSITION_SIGNATURE } from '@shared/constants/app.const';
import { PrintedOnPage, PrintMode, Workflow } from '@shared/interfaces/paperwork.interface';

import { DELETE_RECIPE, PUT_RECIPE } from '../store/clinical-recipe.actions';
import { buildContentPdfRecipes } from '../utils/build-pdf-recipes';

const workflow: Workflow = {
  isSigned: true,
  isStored: true,
  isSaving: true,
  hasNextControl: true,
  notifications: [
    {
      type    : 'email',
      entity  : 'patient',
      subject : 'Notificaciones UC CHRISTUS | Receta Médica',
      template: 'recipe',
    },
  ],
};
const pdf                = {
  isPreview: true,
  header   : {logo: PrintedOnPage.all, title: PrintedOnPage.all, professional: PrintedOnPage.all},
  patient  : {type: PrintMode.full, printed: PrintedOnPage.all},
  footer   : {
    signature: {printed: PrintedOnPage.all, position: DEFAULT_PDF_POSITION_SIGNATURE},
    qr: {
      right: {
        title: 'Portal Farmacia',
        url: 'https://portalfarmacias.ucchristus.cl/portal/block-portal/$shortId$',
      },
    },
  },
};

export const PAPERWORK_RECIPES_DEFINITION = {
  actions: {
    put   : PUT_RECIPE,
    delete: DELETE_RECIPE
  },
  prefix : 'RME',
  module : 'recetas',
  receta: {
    suffix           : 'RMG',
    group            : 'receta médica',
    docTitle         : 'Receta Médica',
    submodule: MEDICINES_ROUTES.generalRecipe,
    getBodyDefinition: buildContentPdfRecipes,
    workflow,
    config           : pdf,
  },

  diabetes: {
    suffix           : 'RMD',
    group            : 'receta diabetes',
    docTitle         : 'Receta Médica',
    submodule: MEDICINES_ROUTES.diabetesRecipe,
    getBodyDefinition: buildContentPdfRecipes,
    workflow,
    config           : pdf,
  },

  magistral: {
    suffix           : 'RMM',
    group            : 'receta magistral',
    docTitle         : 'receta magistral',
    submodule: MEDICINES_ROUTES.masterRecipe,
    getBodyDefinition: buildContentPdfRecipesMegistral,
    workflow,
    config           : pdf,
  },
};
