import { patientTopCard } from '@shared/helpers/pdf-patient-card.helper';


export const buildContentPdfEpicrisisFilmaker = (paperwork, group) => {

    const { rows } = group;
    return rows.map((itemForm) => [
        ...patientTopCard(paperwork),

    { text: `DIAGNÓSTICOS INGRESO:`, bold: true, fontSize: 14, margin: [0, 5, 0, 5] },
    { text: `Diagnóstico 1:  ${itemForm?.DIAGINGRESO1}`, fontSize: 14, margin: [0, 5, 0, 5] },
    { text: `Especificación:  ${itemForm?.ESPECDIAG1}`, fontSize: 14, margin: [0, 5, 0, 5] },

    { text: `Diagnóstico 2:  ${itemForm?.DIAGINGRESO2}`, fontSize: 14, margin: [0, 5, 0, 5] },
    { text: `Especificación:  ${itemForm?.ESPECDIAG2}`, fontSize: 14, margin: [0, 5, 0, 5] },

    { text: `PATOLOGÍAS ASOCIACIÓN:`, bold: true, fontSize: 14, margin: [0, 5, 0, 5] },
    { text: `Diagnóstico 2:  ${itemForm?.PATOLOGIA1}`, fontSize: 14, margin: [0, 5, 0, 5] },
    { text: `Especificación:  ${itemForm?.PATOLOGIA2}`, fontSize: 14, margin: [0, 5, 0, 5] },

    { text: `PROTOCOLOS OPERATORIOS:`, bold: true, fontSize: 14, margin: [0, 5, 0, 5] },
    { text: `Cirugía 1:  ${itemForm?.CIRUGIA1}`, fontSize: 14, margin: [0, 5, 0, 5] },
    { text: `Cirugía 2:  ${itemForm?.CIRUGIA2}`, fontSize: 14, margin: [0, 5, 0, 5] },
    { text: `Cirugía 3:  ${itemForm?.CIRUGIA3}`, fontSize: 14, margin: [0, 5, 0, 5] },


    { text: `COMPLICACIONES POSTOP:`, bold: true, fontSize: 14, margin: [0, 5, 0, 5] },
    { text: `Complicación 1:  ${itemForm?.COMPLICPOST1}`, fontSize: 14, margin: [0, 5, 0, 5] },
    { text: `Especificación:  ${itemForm?.ESPECOMPLICPOS1}`, fontSize: 14, margin: [0, 5, 0, 5] },

    { text: `Complicación 2:  ${itemForm?.COMPLICPOST2}`, fontSize: 14, margin: [0, 5, 0, 5] },
    { text: `Especificación:  ${itemForm?.ESPECOMPLICPOS2}`, fontSize: 14, margin: [0, 5, 0, 5] },


    { text: `DIAGNÓSTICOS EGRESO:`, bold: true, fontSize: 14, margin: [0, 5, 0, 5] },
    { text: `Diagnóstico 1:    :  ${itemForm?.DIAGEGRE1}`, fontSize: 14, margin: [0, 5, 0, 5] },
    { text: `Especificación:  ${itemForm?.ESPECEGRE1}`, fontSize: 14, margin: [0, 5, 0, 5] },
    { text: `Diagnóstico 2:    :  ${itemForm?.DIAGEGRE2}`, fontSize: 14, margin: [0, 5, 0, 5] },
    { text: `Especificación:  ${itemForm?.ESPECEGRE2}`, fontSize: 14, margin: [0, 5, 0, 5] },

    { text: `EPICRISIS:`, bold: true, fontSize: 14, margin: [0, 5, 0, 5] },
    { text: ` ${itemForm?.EPICRISIS}`, fontSize: 14, margin: [0, 5, 0, 5] },

    { text: `Responsable alta:`, bold: true, fontSize: 14, margin: [0, 5, 0, 5] },
    { text: ` ${itemForm?.CIRUJANOALTA}`, fontSize: 14, margin: [0, 5, 0, 5] },




    ]);
    };
