import {patientTopCard} from '@shared/helpers/pdf-patient-card.helper';

export const buildContentPdfTechinicalAssist = (paperwork, group) => {
  const {rows}  = group;
  const listOrt = [];
  const lisAyu  = [];
  const lisOtro = [];
  rows.filter((supplie) => {
    if (supplie.category === 'órtesis') listOrt.push(supplie);
    if (supplie.category === 'ayudas técnicas') lisAyu.push(supplie);
    if (supplie.category === 'otros') lisOtro.push(supplie);
  });
  const LISTAO       = listOrt.map((supplie) => {
    const OBSERVATIONS = {text: `Observaciones: ${supplie.observation}`};
    if (!supplie.observation) OBSERVATIONS.text = '';
    return [
      {
        ul: [
          {
            text     : `${supplie.name.toUpperCase()}`,
            bold     : true,
            fontSize : 10,
            margin   : [0, 5],
            alignment: 'justify',
          },
        ],
      },
      {
        fontSize: 10,
        type    : 'none',
        ol      : [OBSERVATIONS],
      },
      '\n',
    ];
  });
  const LISTAOT      = lisOtro.map((supplie) => {
    const OBSERVATIONS = {text: `Observaciones: ${supplie.observation}`};
    if (!supplie.observation) OBSERVATIONS.text = '';
    return [
      {
        ul: [
          {
            text     : `${supplie.name.toUpperCase()}`,
            bold     : true,
            fontSize : 10,
            margin   : [0, 5],
            alignment: 'justify',
          },
        ],
      },
      {
        fontSize: 10,
        type    : 'none',
        ol      : [OBSERVATIONS],
      },
      '\n',
    ];
  });
  const LISTAA       = lisAyu.map((supplie) => {
    const OBSERVATIONS = {text: `Observaciones: ${supplie.observation}`};
    if (!supplie.observation) OBSERVATIONS.text = '';
    return [
      {
        ul: [
          {
            text     : `${supplie.name.toUpperCase()}`,
            bold     : true,
            fontSize : 10,
            margin   : [0, 5],
            alignment: 'justify',
          },
        ],
      },
      {
        fontSize: 10,
        type    : 'none',
        ol      : [OBSERVATIONS],
      },
      '\n',
    ];
  });
  const AYUDA        = LISTAA.length > 0 ? [{text: `Ayudas Técnicas: `, bold: true}, {ul: [LISTAA]}, '\n'] : [];
  const OTRO         = LISTAOT.length > 0 ? [{text: `Otros: `, bold: true}, {ul: [LISTAOT]}, '\n'] : [];
  const ORTESIS      = LISTAO.length > 0 ? [{text: `Órtesis: `, bold: true}, {ul: [LISTAO]}, '\n'] : [];
  const techBuildPdf = [AYUDA, ORTESIS, OTRO];
  return [...patientTopCard(paperwork), techBuildPdf];
};
