import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Anamnesis, AnamnesisStandard } from '@shared/interfaces/anamnesis.interface';
import { PCV2Request } from '@shared/interfaces/request.interface';
import { API_URIS } from '@shared/constants/api-uris.const';
import { environment } from '@environments/environment';
import { Storage } from '@ionic/storage-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const BASE_API_PCV2: string = environment.baseApiPCv2;
const URI_CLINICAL_ANAMNESIS: string = API_URIS.clinical.anamnesis;

@Injectable({
  providedIn: 'root',
})
export class AnamnesisService {
  private storageTest: Storage | null = null;
  constructor(private httpClient: HttpClient, private storage: Storage) {}

  getPatientAnamnesis(patientId: string): Observable<AnamnesisStandard[]> {
    const ENDPOINT = `pacientes/${patientId}`;
    return this.httpClient
      .get<PCV2Request>(`${BASE_API_PCV2}/${URI_CLINICAL_ANAMNESIS}/${ENDPOINT}`)
      .pipe(map(({ data }: { data: AnamnesisStandard[] }) => data));
  }

  getProfessionalAnamnesisDraft(professionalId: string): Observable<AnamnesisStandard[]> {
    const gsiOne = encodeURIComponent(`PRO#${professionalId}#isDraft#true`);
    return this.httpClient
      .get<PCV2Request>(`${BASE_API_PCV2}/${URI_CLINICAL_ANAMNESIS}/busqueda/${gsiOne}`)
      .pipe(map(({ data }: { data: AnamnesisStandard[] }) => data));
  }

  deletePatientAnamnesis(anamnesis: AnamnesisStandard): Observable<AnamnesisStandard> {
    const anamnesisId = anamnesis.id;
    const patientId = anamnesis.patient.id;
    const professionalId = anamnesis.professional.id;

    const URI_PARAMS = `${anamnesisId}/pacientes/${patientId}/profesionales/${professionalId}`;

    return this.httpClient
      .delete<PCV2Request>(`${BASE_API_PCV2}/${URI_CLINICAL_ANAMNESIS}/${URI_PARAMS}`)
      .pipe(map(({ data }: { data: AnamnesisStandard }) => data));
  }

  putPatientAnamnesis(body: AnamnesisStandard): Observable<AnamnesisStandard> {
    const patientId = body.patient.id;
    const professionalId = body.professional.id;
    const ENPOINT = `pacientes/${patientId}/profesionales/${professionalId}`;
    return this.httpClient
      .post<PCV2Request>(`${BASE_API_PCV2}/${URI_CLINICAL_ANAMNESIS}/${ENPOINT}`, body)
      .pipe(map(({ data }: { data: AnamnesisStandard }) => data));
  }

  async saveOnLocaStorage(anamnesis: AnamnesisStandard, frm: AnamnesisStandard) {
    let draftAnamnesis: typeof anamnesis[] = (await this.storage.get('draftAnamnesis')) || [];

    if (draftAnamnesis) {
      const index = draftAnamnesis.findIndex((draft) => draft.patient.id === frm.patient.id);
      if (index >= 0) draftAnamnesis.splice(index, 1);
    }

    draftAnamnesis = [frm, ...draftAnamnesis];
    await this.storage.set('draftAnamnesis', draftAnamnesis);
  }

  public set(key: string, value: any) {
    this.storageTest?.set(key, value);
  }
}
