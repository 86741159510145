import { Component, Input, OnInit } from '@angular/core';
import { FileService } from '@shared/helpers/file.helper';
import { Pdf } from '@shared/helpers/pdf-wrapper.helper';
import { AnamnesisStandard } from '@shared/interfaces/anamnesis.interface';
import { PDFWrapper } from '@shared/interfaces/pdf.interface';
import { Attachment, PresignedUrlRequest, PresignedUrlResponse } from '@shared/interfaces/storage.interface';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';

import { CREATE_PRESIGNED_URL, DIGITAL_SIGNATURE, PUT_ANAMNESIS, SEND_EMAIL } from 'src/app/store/actions';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { AppState } from 'src/app/store/app.reducers';
import { KEY_NOTIFICATION_STORE, KEY_RECIPES_STORE, KEY_STORAGE_STORE } from '@store/store-keys';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector   : 'app-pdf-anamnesis-preview',
  templateUrl: './pdf-anamnesis-preview.component.html',
  styleUrls  : ['./pdf-anamnesis-preview.component.scss'],
})
export class PdfAnamnesisPreviewComponent implements OnInit {
  @Input() isPreview: boolean;
  @Input() pdfWrapper: PDFWrapper;
  @Input() anamnesisToSave: AnamnesisStandard;

  attachments: Attachment[];
  docDataUrl: string;
  isLoading: boolean;
  putObjectProgress;
  status: string;
  error;
  isReady: boolean;

  constructor(
    private store: Store<AppState>,
    private fileService: FileService,
    private modalController: ModalController,
    private pdf: Pdf,
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.showPdf();
  }

  showPdf() {
    const {docDefinition} = this.pdfWrapper;
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getDataUrl((dataUrl: string) => {
      this.docDataUrl = dataUrl;
      this.pdf.visualize(dataUrl);
      this.isLoading = false;
    });
  }

  onSubmit() {
    const anamnesisToSave = {...this.anamnesisToSave};
    delete anamnesisToSave.gsiOne;
    anamnesisToSave.isDraft = false;
    const frm               = {...anamnesisToSave};
    this.store.dispatch(PUT_ANAMNESIS({frm}));
    this.dismiss(true);
  }

  dismiss(isSaving: boolean) {
    this.modalController.dismiss(isSaving);
    this.isReady = false;
  }

  async docSing() {
    const docFile  = await this.fileService.dataUrlToFile(this.docDataUrl);
    const formData = this.pdf.formDataToDocSign(this.pdfWrapper, 'docFile', 'simple', 'anamnesis');
    const {title}  = this.pdfWrapper.metadata;
    this.store.dispatch(DIGITAL_SIGNATURE({formData, title}));
  }

  subscribeToDocSignStore() {
    this.store.select('docSign').subscribe(({base64, isLoaded, isLoading, error}) => {
      this.error     = error;
      this.isLoading = isLoading;

      if (isLoading) this.status = 'Firmando receta médica electrónica';
      if (isLoaded) {
        const dataUrl   = `data:application/pdf;base64,${base64}`;
        this.docDataUrl = dataUrl;
        this.pdf.visualize(dataUrl);
        this.status = 'Receta médica electrónica firmada';
        this.createPresignedUrl();
      }
    });
  }

  createPresignedUrl() {
    const EXTENSION                   = 'pdf';
    const SAVE_PATH                   = 'anamnesis';
    const params: PresignedUrlRequest = this.pdf.getParamsToPresignedUrl(EXTENSION, SAVE_PATH);
    this.store.dispatch(CREATE_PRESIGNED_URL({params}));
  }

  subscribeToStorageStore() {
    this.store.select(KEY_STORAGE_STORE).subscribe(({ authorizer, isLoaded, isLoading, error, progress }) => {
      this.error             = error;
      this.isLoading         = isLoading;
      this.putObjectProgress = progress;

      if (isLoading) this.status = 'Solicitando permisos para almacenar indicaciones con firma electrónica';

      if (isLoaded && progress === 0) {
        this.status = 'Permisos obtenidos para respaldar indicaciones con firma electrónica';
        this.saveToS3(authorizer);
      }

      if (progress > 0) this.status = `Respaldando indicaciones, ${progress * 100}% completado`;
      if (isLoaded && progress === 100) {
        this.status = 'Completado el respaldando receta médica con firma electrónica';
        this.saveToDatabase(authorizer);
      }
    });
  }

  saveToDatabase(presignedUrl: PresignedUrlResponse) {
    const valuesToSave = this.pdf.recipe(this.pdfWrapper, presignedUrl);
    // this.store.dispatch(PUT_RECIPE({ valuesToSave }));
  }

  subscribeToRecipeStore() {
    this.store.select(KEY_RECIPES_STORE).subscribe(({ recipes, isLoaded, isLoading, error }) => {
      this.error     = error;
      this.isLoading = isLoading;

      if (isLoading) this.status = 'Guardando la receta médica';
      if (isLoaded) {
        this.status   = 'Receta médica electrónica guardada';
        const subject = 'Notificación UC CHRITUS | Receta Médica Electronica';
        this.sendMail(subject);
      }
    });
  }

  sendMail(subject: string) {
    const paramsToSendMail = this.pdf.getParamsToSendMail(this.pdfWrapper, subject, 'rme');
    this.store.dispatch(SEND_EMAIL({paramsToSendMail}));
  }

  subscribeToNotificationStore() {
    this.store.select(KEY_NOTIFICATION_STORE).subscribe(({ isLoaded, isLoading, error }) => {
      this.error     = error;
      this.isLoading = isLoading;

      if (isLoading) this.status = 'Enviando receta al paciente';
      if (isLoaded) {
        this.status = 'El proceso se completo con éxito';
        this.dismiss(true);
      }
    });
  }

  async saveToS3(presignedUrl: PresignedUrlResponse) {
    const {key, url} = presignedUrl;
    const docFile    = await this.pdf.getFormDataToS3Upload(this.pdfWrapper, key, this.docDataUrl);
    // this.store.dispatch(PUT_OBJECT_S3({ url, docFile }));
  }
}
