import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';
import { AmbulatoryAgendaResponse } from '../interfaces/ambulatory-agenda.interface';
import { ExamIndication } from '../interfaces/indication.interface';
import { catchError, retry } from 'rxjs/operators';
import { StatusService } from '@shared/services/status.service';
import { API_URIS } from '@shared/constants/api-uris.const';

const BASE_API: string = environment.baseApiSchedule;
const URI_SCHEDULE_SPECIALTIES: string = API_URIS.schedule.specialties;

const EXAM_AREAS: { imagenes: string; laboratorio: string; procedimientos: string } = environment.examAreasIndications;
const IMAGE_GROUP = 'IMAGENES';

@Injectable({
  providedIn: 'root',
})
export class AmbulatoryAgendaService {

  constructor(private httpClient: HttpClient, private statusService: StatusService) {
  }

  getAmbulatoryAgenda(exam: ExamIndication): Observable<AmbulatoryAgendaResponse> {
    const { group, id, prmId } = exam;
    const queryParams = {
      idArea: this.getAreaId(group, prmId),
      idServicio: this.getServiceId(group, id, prmId),
    };
    const url = `${BASE_API}/${URI_SCHEDULE_SPECIALTIES}?${new URLSearchParams(queryParams).toString()}`;
    return this.httpClient.get<AmbulatoryAgendaResponse>(url).pipe(
      retry(1),
      catchError(err => of(null)),
    );
  }

  getServiceId(group: string, id: string, prmId?: string | undefined) {
    if (group === IMAGE_GROUP && prmId) return prmId;
    return group === IMAGE_GROUP ? id : prmId;
  }

  getAreaId(group: string, prmId?: string | undefined): string {
    if (group === IMAGE_GROUP && prmId) return EXAM_AREAS.procedimientos;
    return EXAM_AREAS[group.toLowerCase()];
  }
}
