import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';
import { Expertise } from '../interfaces/expertise.interface';

/* Firma de Documentos Electronicos */
export const PUT_EXPERTISE = createAction('[peritaje] PUT Peritaje', props<{ valuesToSave: Expertise }>());
export const PUT_EXPERTISE_OK = createAction('[peritaje] OK PUT Peritaje', props<{ expertise: Expertise }>());
export const PUT_EXPERTISE_ERR = createAction('[peritaje] ERR PUT Peritaje', props<{ payload: HttpErrorResponse }>());
export const LOAD_EXPERTISES = createAction(
  '[peritaje] Cargar peritaje por paciente',
  props<{ gsiMultiCondition1St: string }>(),
);
export const LOAD_EXPERTISES_OK = createAction(
  '[peritaje] OK cargar peritaje por paciente',
  props<{ expertises: Expertise[] }>(),
);
export const LOAD_EXPERTISES_ERR = createAction(
  '[peritaje] ERR cargar peritaje por paciente',
  props<{ payload: HttpErrorResponse }>(),
);

export const LOAD_EXPERTISES_BY_PROFESSIONAL = createAction(
  '[medicamentos] Cargar peritaje por profesional',
  props<{ gsiMultiCondition1St: string }>(),
);
export const LOAD_EXPERTISES_BY_PROFESSIONAL_OK = createAction(
  '[medicamentos] OK peritaje por profesional',
  props<{ expertises: Expertise[] }>(),
);
export const LOAD_EXPERTISES_BY_PROFESSIONAL_ERR = createAction(
  '[medicamentos] ERR peritaje por profesional',
  props<{ payload: HttpErrorResponse }>(),
);

export const DELETE_EXPERTISE = createAction('[peritaje] DELETE Peritaje', props<{ expertise: Expertise }>());
export const DELETE_EXPERTISE_OK = createAction('[peritaje] OK DELETE Peritaje', props<{ expertise: Expertise }>());
export const DELETE_EXPERTISE_ERR = createAction(
  '[peritaje] ERR DELETE Peritaje',
  props<{ payload: HttpErrorResponse }>(),
);

export const SELECTED_EXPERTISE = createAction(
  '[peritaje] SELECTED Peritaje',
  props<{ selected: Expertise; action: string }>(),
);
export const UNSELECT_EXPERTISE = createAction('[peritaje] UNSELECT Peritaje');
export const CLEAR_EXPERTISE = createAction('[peritaje] CLEAR Peritaje');
