import {patientTopCard} from '@shared/helpers/pdf-patient-card.helper';
import {format} from 'date-fns';

export const buildContentPdfImageConsent = (paperwork, group) => {
  const {patient, professional} = paperwork;
  let creationDay               = format(new Date(), 'dd/MM/yyyy');
  creationDay                   = creationDay.toString();
  const paragraph1              = [
    {
      marginTop: 10,
      alignment: 'left',
      text     : `La Red de Salud UC-Christus respeta el derecho a la privacidad en el uso de datos personales de sus pacientes,
según lo establecido en la legislación vigente.
 `,
    },
    {
      marginTop: -20,
      alignment: 'left',
      text     : `
      Con el objetivo de realizar un mejor seguimiento clínico de su enfermedad es útil disponer de imágenes
      (fotografías y/o videos) que representen las alteraciones características de su condición. Adicionalmente,
      al ser este un centro docente, estas imágenes podrían ser utilizadas en el proceso educativo o en
      comunicaciones científicas, manteniendo en reserva su identidad y evitando, hasta donde sea posible,
      que usted sea reconocido a través de estas imágenes.
      El presente documento tiene como objetivo solicitar su consentimiento para la obtención y posterior utilización de
      imágenes suyas y/o de su hijo(a) y/o representado(a).`,
    },
  ];
  const text1                   = [
    {marginTop: 10, fontSize: 10, text: `Autorizo SÍ `},
    {
      alignment: 'left',
      fontSize : 10,
      style    : 'center',

      stack: [
        {
          marginTop : -12,
          marginLeft: 50,
          canvas    : [
            {
              type: 'rect',
              x   : 1,
              y   : 1,
              w   : 10,
              h   : 10,
            },
          ],
        },
      ],
    },
    {marginTop: -10, marginLeft: 65, fontSize: 10, text: `NO `},
    {
      alignment: 'left',
      fontSize : 10,
      style    : 'center',

      stack: [
        {
          marginTop : -12,
          marginLeft: 80,
          canvas    : [
            {
              type: 'rect',
              x   : 1,
              y   : 1,
              w   : 10,
              h   : 10,
            },
          ],
        },
      ],
    },
    {
      marginTop : -10,
      marginLeft: 95,
      fontSize  : 10,
      alignment : 'justify',

      text: `que se obtengan FOTOGRAFÍAS VIDEOS mías o de mi hijo(a) o representado (a), para el `,
    },
    {
      marginTop : 5,
      marginLeft: 0,
      fontSize  : 10,

      text: `seguimiento clínico.`,
    },
  ];
  const text2                   = [
    {marginTop: 10, fontSize: 10, text: `Adicionalmente, SI `},
    {
      alignment: 'left',
      fontSize : 10,
      style    : 'center',

      stack: [
        {
          marginTop : -12,
          marginLeft: 85,
          canvas    : [
            {
              type: 'rect',
              x   : 1,
              y   : 1,
              w   : 10,
              h   : 10,
            },
          ],
        },
      ],
    },
    {marginTop: -10, marginLeft: 100, fontSize: 10, text: `NO `},
    {
      alignment: 'left',
      fontSize : 10,
      style    : 'center',

      stack: [
        {
          marginTop : -12,
          marginLeft: 115,
          canvas    : [
            {
              type: 'rect',
              x   : 1,
              y   : 1,
              w   : 10,
              h   : 10,
            },
          ],
        },
      ],
    },
    {
      marginTop : -10,
      marginLeft: 130,
      fontSize  : 10,
      alignment : 'justify',

      text: `autorizo para que estas imágenes sean utilizadas en clases y/o reuniones clínicas.`,
    },
  ];
  const text3                   = [
    {marginTop: 10, fontSize: 10, text: `Además, SI `},
    {
      alignment: 'left',
      fontSize : 10,
      style    : 'center',

      stack: [
        {
          marginTop : -12,
          marginLeft: 55,
          canvas    : [
            {
              type: 'rect',
              x   : 1,
              y   : 1,
              w   : 10,
              h   : 10,
            },
          ],
        },
      ],
    },
    {marginTop: -10, marginLeft: 70, fontSize: 10, text: `NO `},
    {
      alignment: 'left',
      fontSize : 10,
      style    : 'center',

      stack: [
        {
          marginTop : -12,
          marginLeft: 85,
          canvas    : [
            {
              type: 'rect',
              x   : 1,
              y   : 1,
              w   : 10,
              h   : 10,
            },
          ],
        },
      ],
    },
    {
      marginTop : -10,
      marginLeft: 100,
      fontSize  : 10,
      alignment : 'justify',

      text: `autorizo para que estas imágenes sean utilizadas en publicaciones científicas.`,
    },
  ];
  const text4                   = [
    {
      marginTop: 10,
      fontSize : 10,
      text     : `Entiendo que puedo revocar este consentimiento en cualquier momento, sin que esto signifique un menoscabo en mi atención de salud o en la de mi hijo(a) o representado(a).`,
    },
  ];
  const NAME_PATIENT            = {
    alignment : 'center',
    marginTop : 160,
    marginLeft: -230,
    fontSize  : 10,

    style: 'title',
    stack: [
      `${patient.names} ${patient.surnames}`,
      {canvas: [{type: 'line', x1: 0, y1: 0, x2: 250, y2: 0, lineWidth: 1}]},
      {text: `Nombre PACIENTE`, bold: true},
    ],
  };
  const NAME_REPRESENT          = {
    alignment : 'center',
    marginTop : 40,
    marginLeft: -230,
    fontSize  : 10,

    style: 'title',
    stack: [
      ``,
      {canvas: [{type: 'line', x1: 0, y1: 0, x2: 250, y2: 0, lineWidth: 1}]},
      {text: `Nombre REPRESENTANTE`, bold: true},
    ],
  };
  const NAME_DOCTOR             = {
    alignment : 'center',
    marginTop : 40,
    marginLeft: -230,
    fontSize  : 10,

    style: 'title',
    stack: [
      `${professional.names} ${professional.surnames}`,
      {canvas: [{type: 'line', x1: 0, y1: 0, x2: 250, y2: 0, lineWidth: 1}]},
      {text: `Nombre MÉDICO/ODONTÓLOGO INFORMANTE`, bold: true},
    ],
  };
  const RUT_PATIENT             = {
    alignment : 'center',
    marginTop : -139,
    fontSize  : 10,
    marginLeft: 150,
    style     : 'title',
    stack     : [
      `${patient.documentNumber}`,
      {canvas: [{type: 'line', x1: 0, y1: 0, x2: 100, y2: 0, lineWidth: 1}]},
      {text: `R.U.N`, bold: true},
    ],
  };
  const RUT_REPRESENT           = {
    alignment : 'center',
    marginTop : 40,
    fontSize  : 10,
    marginLeft: 150,
    style     : 'title',
    stack     : [
      ``,
      {canvas: [{type: 'line', x1: 0, y1: 0, x2: 100, y2: 0, lineWidth: 1}]},
      {text: `R.U.N`, bold: true},
    ],
  };
  const DATE                    = {
    alignment : 'center',
    marginTop : 40,
    fontSize  : 10,
    marginLeft: 150,
    style     : 'title',
    stack     : [
      `${creationDay}`,
      {canvas: [{type: 'line', x1: 0, y1: 0, x2: 100, y2: 0, lineWidth: 1}]},
      {text: `Fecha`, bold: true},
    ],
  };
  const SIGN_PATIENT            = {
    alignment : 'center',
    marginTop : -128,
    fontSize  : 10,
    marginLeft: 420,
    style     : 'title',
    stack     : [
      ``,
      {canvas: [{type: 'line', x1: 0, y1: 0, x2: 140, y2: 0, lineWidth: 1}]},
      {text: `Firma`, bold: true},
    ],
  };
  const SIGN_REPRESENT          = {
    alignment : 'center',
    marginTop : 40,
    fontSize  : 10,
    marginLeft: 420,
    style     : 'title',
    stack     : [
      ``,
      {canvas: [{type: 'line', x1: 0, y1: 0, x2: 140, y2: 0, lineWidth: 1}]},
      {text: `Firma`, bold: true},
    ],
  };
  const RUT_DOCTOR              = {
    alignment : 'center',
    marginTop : 40,
    fontSize  : 10,
    marginLeft: 420,
    style     : 'title',
    stack     : [
      `${professional.rut}`,
      {canvas: [{type: 'line', x1: 0, y1: 0, x2: 140, y2: 0, lineWidth: 1}]},
      {text: `R.U.N`, bold: true},
    ],
  };
  const {rows}                  = group;
  return rows.map((frm) => {
    let procedure = frm.procedure;
    if (procedure)
      procedure = [
        {marginTop: 10, fontSize: 12, text: `PROCEDIMIENTO: `},
        {
          fontSize  : 12,
          marginTop : -15,
          marginLeft: 100,
          text      : `${procedure.toUpperCase()}`,
          bold      : true,
        },
      ];
    return [
      ...patientTopCard(paperwork),
      {
        fontSize : 10,
        alignment: 'justify',
        stack    : [
          paragraph1,
          procedure,
          text1,
          text2,
          text3,
          text4,
          NAME_PATIENT,
          NAME_REPRESENT,
          NAME_DOCTOR,
          RUT_PATIENT,
          RUT_REPRESENT,
          DATE,
          SIGN_PATIENT,
          SIGN_REPRESENT,
          RUT_DOCTOR,
        ],
      },
    ];
  });
};
