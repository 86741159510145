import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { FormFieldsValidate } from '@core/reactive-forms/form-fields-validate.helper';
import { AppState } from '@store/app.reducers';
import { KEY_PROFESSIONAL_STORE } from '@store/store-keys';

@Component({
  selector: 'app-modal-resolutions',
  templateUrl: './modal-resolutions.component.html',
  styleUrls: ['./modal-resolutions.component.scss'],
})
export class ModalResolutionsComponent implements OnInit {
  frmModalResolutions: UntypedFormGroup;
  isLoadedPatient: boolean;
  isSearchPatient: boolean;
  error;
  professional;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalController: ModalController,
    private store: Store<AppState>,
  ) {
    this.buildForm();
  }

  ngOnInit() {
    this.readStoreProfessional();
  }

  dismiss() {
    this.modalController.dismiss();
  }

  readStoreProfessional(): void {
    this.store.select(KEY_PROFESSIONAL_STORE).subscribe(({ professional, isLoading, isLoaded, error }) => {
      this.professional = professional?.names + ' ' + professional.surnames;
      this.isSearchPatient = isLoading;
      this.isLoadedPatient = isLoaded;
      this.error = error;
    });
  }

  createList() {
    const reasonRejected = this.frmModalResolutions.controls.reason.value;
    this.modalController.dismiss(reasonRejected);
  }

  buildForm(): void {
    this.frmModalResolutions = this.formBuilder.group({
      reason: FormFieldsValidate.requiredMinLength(1),
    });
  }
}
