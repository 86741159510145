import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import { PaperworkState } from '@store/reducers';
import { KEY_PAPERWORK_STORE } from '@store/store-keys';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  paperwork$: Observable<PaperworkState>;

  constructor( private store: Store<AppState>, ) { }

  ngOnInit() {
    this.paperwork$ = this.store.select(KEY_PAPERWORK_STORE);
  }

}
