import { HttpErrorResponse } from '@angular/common/http';
import { Doc } from '@clinical/educational/interfaces/educational.interface';

import { createAction, props } from '@ngrx/store';
import { Paperwork } from '@shared/interfaces/paperwork.interface';

export const PUT_EDUCATIONAL = createAction(
  '[educacional] PUT Documento Educacional',
  props<{ valuesToSave: Paperwork<Doc> }>(),
);
export const PUT_EDUCATIONAL_OK = createAction(
  '[educacional] OK PUT Documento Educacional',
  props<{ doc: Paperwork<Doc> }>(),
);
export const PUT_EDUCATIONAL_ERR = createAction(
  '[educacional] ERR PUT Documento Educacional',
  props<{ payload: HttpErrorResponse }>(),
);
