import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as patientActions from './patient.actions';
import {PatientService} from 'src/app/components/patient/services/patient.service';

import {of} from 'rxjs';
import {catchError, filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {UNSELECT_AGENDA} from '@components/agenda/store/agenda.actions';
import {IntegralHealt} from '@components/patient/interfaces/family-doctor.interface';
import {FamilyDoctorService} from '@components/patient/services/family-doctor.service';
import {GET_PATIENT_MARK_ON_AGENDA_ERR, GET_PATIENT_MARK_ON_AGENDA_OK} from "./patient.actions";

@Injectable()
export class PatientEffects {
  getPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.GET_PATIENT),
      mergeMap(({docNumber, docType}) =>
        this.patientService.getPatient(docNumber, docType).pipe(
          map((patient) => patientActions.GET_PATIENT_OK({patient})),
          catchError((err) => of(patientActions.GET_PATIENT_ERR({payload: err}))),
        ),
      ),
    ),
  );

  getIntegralHealth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.GET_PATIENT_OK),
      filter(({patient}) => Boolean(patient)),
      filter(({patient}) => patient?.integralHealth === undefined),
      map(({patient}) => patientActions.GET_PATIENT_INTEGRAL_HEALTH({docNumber: patient.documentNumber})),
      catchError((err) => of(patientActions.GET_PATIENT_INTEGRAL_HEALTH_ERR({payload: err}))),
    ),
  );

  getMarkOnAgenda$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.GET_PATIENT_OK),
      filter(({patient}) => Boolean(patient)),
      filter(({patient}) => patient?.marksOnAgenda === undefined),
      mergeMap(({patient}) =>
        this.patientService.getMarkOnAgenda(patient.id).pipe(
          map((marksOnAgenda) => patientActions.GET_PATIENT_MARK_ON_AGENDA_OK({marksOnAgenda})),
          catchError((err) => of(patientActions.GET_PATIENT_MARK_ON_AGENDA_ERR({payload: err}))),
        ),
      ),
    ),
  );

  setIntegralHealth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.GET_PATIENT_INTEGRAL_HEALTH),
      switchMap(({docNumber}) => this.familyDoctorService.getPatientIH(docNumber)),
      map((integralHealth: IntegralHealt) => patientActions.GET_PATIENT_INTEGRAL_HEALTH_OK({integralHealth})),
      catchError((err) => of(patientActions.GET_PATIENT_INTEGRAL_HEALTH_ERR({payload: err}))),
    ),
  );

  clearPatientAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.CLEAR_PATIENT),
      map(() => UNSELECT_AGENDA()),
    ),
  );

  constructor(
    private actions$: Actions,
    private patientService: PatientService,
    private familyDoctorService: FamilyDoctorService,
  ) {
  }
}
