import { Favorite, FavoriteSelected } from '@shared/interfaces/favorite.interface';
import { Action, createReducer, on } from '@ngrx/store';

import {
  DEL_FAVORITE,
  DEL_FAVORITE_ERR,
  DEL_FAVORITE_OK,
  GET_FAVORITES,
  GET_FAVORITES_ERR,
  GET_FAVORITES_OK,
  PUT_FAVORITE,
  PUT_FAVORITE_ERR,
  PUT_FAVORITE_OK,
  SELECT_FAVORITE,
  SELECTED_FAVORITE,
  UNSELECT_FAVORITE,
} from '@store/actions';

export interface FavoriteState {
  error: any;
  status: string;
  isLoaded: boolean;
  isLoading: boolean;
  favorites: Favorite[];
  selected: FavoriteSelected;
}

export const favoriteState: FavoriteState = {
  error: null,
  status: '',
  isLoaded: false,
  isLoading: false,
  favorites: [],
  selected: null,
};

const createReducerFavorite = createReducer(
  favoriteState,

  // Obtener las urgencias de un profesional
  on(GET_FAVORITES, (state) => ({ ...state, isLoading: true, isLoaded: false, status: 'obteniendo favoritos' })),
  on(GET_FAVORITES_OK, (state, { favorites }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    status: 'favoritos cargados',
    error: null,
    favorites: [...favorites],
  })),
  on(GET_FAVORITES_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    status: 'Ocurrio un error al obtener favoritos',
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(PUT_FAVORITE, (state) => ({ ...state, isLoading: true, status: 'Guardando el registro.' })),
  on(PUT_FAVORITE_OK, (state, { favorite }) => {
    let favorites = state.favorites.map((ans) => {
      if (ans.id === favorite.id) return favorite;
      else return ans;
    });

    const index = favorites.findIndex((draft) => draft.id === favorite.id);
    if (index === -1) favorites = [favorite, ...favorites];

    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      status: 'Registro guardado con éxito',
      favorites: [...favorites],
    };
  }),
  on(PUT_FAVORITE_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    status: 'Ocurrio un error al intentar guardar el registro',
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(SELECT_FAVORITE, (state, { favorite }) => ({
    ...state,
    isLoading: true,
    isLoaded: false,
    status: 'Favorito seleccionado',
    selected: { ...favorite },
  })),

  on(SELECTED_FAVORITE, (state, { favorite }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    status: 'Favorito aplicado correctamente',
    selected: { ...favorite },
  })),

  on(UNSELECT_FAVORITE, (state) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    status: '',
    favorite: null,
    selected: null,
  })),

  on(DEL_FAVORITE, (state) => ({ ...state, isLoading: true })),
  on(DEL_FAVORITE_OK, (state, { sk }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    status: 'Favorito eliminado con éxito',
    favorites: [...state.favorites.filter((ans) => ans.sk !== sk)],
  })),
  on(DEL_FAVORITE_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    status: 'Ocurrio un error al intentar eliminar el registro',
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
);

export const favoriteReducer = (state: FavoriteState, action: Action) => createReducerFavorite(state, action);
