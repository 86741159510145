import { environment } from '@environments/environment';

export const qrs = (info) => {
  const { paperwork, config } = info;
  if (config.isPreview) return;
  if (!config?.footer?.qr) return {};
  return {
    columns: [qrLeft(paperwork.shortId), dateIssued(), qrRight(config.footer.qr.right, paperwork.shortId)],
  };
};

const qrLeft = (shortId: string) => {
  const { domain, appDomain } = environment;
  const URL_PCV2_VALIDATION = `https://${appDomain}${domain}/validar/${shortId}`;
  return {
    width: '20%',
    alignment: 'left',
    stack: [
      { qr: URL_PCV2_VALIDATION, fit: 80, eccLevel: 'Q', version: 7 },
      { text: 'Código de Verificación', fontSize: 8, margin: [0, 0, 0, 1] },
    ],
  };
};

const dateIssued = () => ({
  width: '60%',
  alignment: 'center',
  margin: [0, 45, 0, 0],
  text: 'Fecha de Emisión:',
});

const qrRight = (params, shortId: string) => {
  if (!params) return { text: '' };
  const URL = params.url.replace('$shortId$', shortId);
  return {
    width: '20%',
    stack: [
      { qr: URL, alignment: 'right', fit: 80, version: 7, eccLevel: 'Q' },
      { fontSize: 8, alignment: 'right', margin: [0, 0, 0, 1], text: params.title },
    ],
  };
};
