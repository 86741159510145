import { Component, Input, OnInit } from '@angular/core';
import { Diagnostic } from '@shared/interfaces/anamnesis.interface';
import { GES_PATOLOGIES } from '@clinical/forms/constants/ges-pathologies.constant';
import { ModalController } from '@ionic/angular';
import { FrmDiagnosticComponent } from '../diagnostic-add/frm-diagnostic.component';

@Component({
  selector: 'app-diagnostic-finder',
  templateUrl: './diagnostic-finder.component.html',
  styleUrls: ['./diagnostic-finder.component.scss'],
})
export class DiagnosticFinderComponent implements OnInit {
  @Input() diagnostics: Diagnostic[];

  gesPatologies = GES_PATOLOGIES;
  patologicGroupSelected;

  constructor(private modalController: ModalController) {
  }

  ngOnInit() {
  }

  async openDiagnosticFrm(selected) {
    selected.tag = 'CIAP-2';
    const modal = await this.modalController.create({
      component: FrmDiagnosticComponent,
      cssClass: 'modal-md',
      componentProps: { selected },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    const diagnostic: Diagnostic = data;
    if (!this.diagnostics) this.diagnostics = [];
    if (diagnostic) this.diagnostics = [...this.diagnostics, diagnostic];
  }

  segmentChanged(event: any) {
    const selected = event.detail.value;
  }

  deleteDiagnostic(index: number) {
    this.diagnostics.splice(index, 1);
  }

  deleteAllDiagnostic() {
    this.diagnostics = [];
  }

  onSelectPatologicGroup(patologicGroup) {
    this.patologicGroupSelected = patologicGroup.pathologic;
  }

  addDiagnostics() {
    const diagnostics = this.diagnostics;
    this.dismiss(diagnostics);
  }

  dismiss(value: any) {
    if (!value) this.modalController.dismiss();
    this.modalController.dismiss(value);
  }
}
