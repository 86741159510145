import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-frm-two-section-title',
    templateUrl: './frm-two-section-title.component.html',
    styleUrls: [ './frm-two-section-title.component.css' ]
})
export class FrmTwoSectionTitleComponent implements OnInit{
    @Input() title: string;
    @Input() buttonTitle: string;
    @Input() data: [];
    @Input() isDisabled: boolean = false;
    @Output() buttonClicked = new EventEmitter<void>();
    
    constructor() {}

    ngOnInit(): void {}

    handleClick() {
        this.buttonClicked.emit();
    }
}
