import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LOAD_SCHEDULE, LOAD_SCHEDULE_ERR, LOAD_SCHEDULE_OK } from '@store/actions';

import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ScheduleService } from '@components/agenda/services/agenda.service';

@Injectable()
export class ScheduleEffects {
  loadSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_SCHEDULE),
      mergeMap(({ professionalId }) =>
        this.scheduleService.getRequestOffert(professionalId).pipe(
          map((offers) => LOAD_SCHEDULE_OK({ offers })),
          catchError((err) => of(LOAD_SCHEDULE_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private scheduleService: ScheduleService) {
  }
}
