import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  MeetingNotFoundComponent
} from 'src/app/components/telemedicine/pages/meeting-not-found/meeting-not-found.component';
import {MeetingOutComponent} from '@components/telemedicine/pages/meeting-out/meeting-out.component';
import {AuthGuard} from '@core/guards/auth.guard';

export const ROOT_ROUTES = {
  meeting            : 'zoom',
  auth               : 'autenticar',
  pharmacy           : 'farmacia',
  clinical           : 'app-pcs',
  docsValidate       : 'validar',
  docsValidateV1     : 'validacion',
  telemedicine       : 'telemedicina',
  meetingFinished    : 'videollamada-terminada',
  hospitalAdmission  : 'admisiones-hospitalaria',
  meetingUnauthorized: 'videollamada-no-autorizada',
};

const routes: Routes = [
  {
    path      : '',
    pathMatch : 'full',
    redirectTo: ROOT_ROUTES.auth,
  },
  {
    path     : ROOT_ROUTES.meetingFinished,
    component: MeetingOutComponent,
  },
  {
    path     : ROOT_ROUTES.meetingUnauthorized,
    component: MeetingNotFoundComponent,
  },
  {
    path        : ROOT_ROUTES.auth,
    loadChildren: () => import('@auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path        : ROOT_ROUTES.docsValidate,
    loadChildren: () => import('./_validation/validation.module').then((m) => m.ValidationModule),
  }, {
    path        : ROOT_ROUTES.docsValidateV1,
    loadChildren: () => import('./_validation/validation.module').then((m) => m.ValidationModule),
  },
  {
    path        : ROOT_ROUTES.meeting,
    loadChildren: () => import('./components/zoom/zoom.module').then((m) => m.ZoomModule),
  },
  {
    path            : ROOT_ROUTES.clinical,
    loadChildren    : () => import('./_clinical/clinical.module').then((m) => m.ClinicalModule),
    canLoad         : [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path        : ROOT_ROUTES.hospitalAdmission,
    loadChildren: () => import('./_admition/admition.module').then((m) => m.AdmissionModule),
  },

  {
    path        : ROOT_ROUTES.pharmacy,
    loadChildren: () => import('./pharmacyportal/pharmacy.module').then((m) => m.PharmacyModule),
  },
  {
    path        : ROOT_ROUTES.telemedicine,
    loadChildren: () => import('./components/telemedicine/telemedicine.module').then((m) => m.TelemedicineModule),
  },
  {
    path      : '**',
    pathMatch : 'full',
    redirectTo: ROOT_ROUTES.auth,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
