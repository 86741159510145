import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-meeting-out',
  templateUrl: './meeting-out.component.html',
  styleUrls: ['./meeting-out.component.scss'],
})
export class MeetingOutComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    localStorage.removeItem('meeting');
    const loader = document.getElementById('app-loader');
    if (this.router.url === '/reunion-terminada') loader.classList.add('ion-hide');
  }
}
