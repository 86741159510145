import { Action, createReducer, on } from '@ngrx/store';
import { DIGITAL_SIGNATURE, DIGITAL_SIGNATURE_ERR, DIGITAL_SIGNATURE_OK } from '../actions';
import { CLEAR_DIGITAL_SIGNATURE } from '../actions';

export interface SignState {
  error: any;
  title: string;
  base64: string;
  key: string;
  status: string;
  isLoaded: boolean;
  isLoading: boolean;
  shortId: string;
}

export const signState: SignState = {
  title: '',
  base64: '',
  key: '',
  status: '',
  isLoaded: false,
  isLoading: false,
  error: null,
  shortId: '',
};

const createReducerSign = createReducer(
  signState,

  on(DIGITAL_SIGNATURE, (state) => ({
    ...state,
    isLoading: true,
    isLoaded: false,
    status: 'Firma digital en proceso.',
  })),

  on(DIGITAL_SIGNATURE_OK, (state, { signedDoc, title }) => ({
    ...state,
    title,
    error: null,
    isLoaded: true,
    isLoading: false,
    key: signedDoc.key,
    base64: signedDoc.base64,
    shortId: signedDoc.shortId,
    status: 'Firma Digital Completa.',
  })),

  on(DIGITAL_SIGNATURE_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    status: 'Ocurrio un error al intentar firmar documento.',
    error: payload,
  })),

  on(CLEAR_DIGITAL_SIGNATURE, (state) => ({
    ...state,
    title: '',
    base64: '',
    key: '',
    status: '',
    isLoaded: false,
    isLoading: false,
    error: null,
    shortId: '',
  })),
);

export const singReducer = (state: SignState, action: Action) => createReducerSign(state, action);
