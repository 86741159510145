export enum FormsOncology {
  'tac'          = 'orden medica tac de simulación',
  'radioterapia' = 'indicación de radioterapia para presupuesto',
  'roadmap' = 'ruta para pacientes oncológicos',
}

export enum FormsNamesToPDF {
  'orden medica tac de simulación'              = 'orden medica tac de simulación',
  'indicación de radioterapia para presupuesto' = 'indicación de radioterapia para presupuesto',
}


