import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quotePipe',
})
export class QuotePipe implements PipeTransform {
  transform(quote: any) {
  if(!quote.state) {
    const states = 'Cupo Diponible'
   return states;
}
if(quote.state){
    const states = 'Reservado'
    return states;
}
if(!quote.pay.id && quote.statePRM === 'Blocked'){
    const states = 'Bloqueado'
    return states;

}
  }
}
