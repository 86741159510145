import {DOCUMENT_TYPE} from '@shared/constants/patient.constant';
import {patientTopCard} from '@shared/helpers/pdf-patient-card.helper';
import {ContentPadFonasa} from '@shared/interfaces/fonasa-pad-inscription.interface';
import {addHours, format} from 'date-fns';

export const buildContentPdfPad = (paperwork, group) => {
  const {patient, professional} = paperwork;
  const {rows} = group;

  const buildPdfPad = rows.map((fonasaPadInscription) => {
    let edd = fonasaPadInscription.edd;
    let formObst = fonasaPadInscription.formula;
    let gesAge = fonasaPadInscription.gestation;
    let controlAmount = fonasaPadInscription.totalControls;
    let imc = fonasaPadInscription.imc;
    const diagnostic = fonasaPadInscription.diagnostic;
    let numberBirths = fonasaPadInscription.totalChildbirths;
    let numberCaesareanSections = fonasaPadInscription.totalCesareans;
    let observa = fonasaPadInscription.observations;
    let diagnostics = [];

    if (controlAmount === false) controlAmount = 'No'
    if (controlAmount === true) controlAmount = 'Si'

    const note1 = [
      {
        marginTop: 15,
        fontSize: 10,
        alignment: 'left',
        text: `Nota 1: Paciente debe contactarse con Ejecutiva Maternidad Unidad de Presupuesto antes de comprar bono PAD en Fonasa`,
      },
    ];
    const dataContact = [
      {
        marginTop: 5,
        alignment: 'left',
        fontSize: 8,
        text: `Datos de contacto:
          Correo Electrónico: baravena@ucchristus.cl
          Número de contacto: +56 9 44396743
          Horario de Atención: Lunes a Viernes de 8:30 a 17:30 horas.`,
      },
    ];

    const note2 = [
      {
        marginTop: 5,
        fontSize: 10,
        alignment: 'left',
        text: `Nota 2: Paciente declara aceptar y recibir programa PAD `,
      },
    ];

    const medicoValue = professional.names + ' ' + professional.surnames;
    const patientNameValue = patient.names + ' ' + patient.surnames;
    const rutPatient = patient.documentNumber;
    let names;
    let medic;
    let rut;
    const fpp = addHours(new Date(fonasaPadInscription.edd), 6);
    const date = format(new Date(), 'dd-MM-yyyy');
    const fppValue = format(fpp, 'dd-MM-yyyy');
    if (medicoValue)
      medic = [
        {fontSize: 12, text: ` Médico autoriza: `},
        {
          fontSize: 12,
          marginTop: -14,
          marginLeft: 240,
          alignment: 'justify',
          text: `${medicoValue}`,
          bold: true,
        },
      ];
    if (patientNameValue)
      names = [
        {fontSize: 12, text: ` Nombre: `},
        {
          fontSize: 12,
          marginTop: -14,
          marginLeft: 240,
          alignment: 'justify',
          text: `${patientNameValue}`,
          bold: true,
        },
      ];
    if (rutPatient)
      rut = [
        {fontSize: 12, text: ` ${DOCUMENT_TYPE[patient.documentType.toLowerCase()].toUpperCase()}: `},
        {fontSize: 12, marginTop: -14, marginLeft: 240, alignment: 'justify', text: `${rutPatient}`, bold: true},
      ];
    if (fppValue)
      edd = [
        {fontSize: 12, text: ` FPP: `},
        {fontSize: 12, marginTop: -14, marginLeft: 240, alignment: 'justify', text: `${fppValue}`, bold: true},
      ];
    if (formObst)
      formObst = [
        {fontSize: 12, text: ` Fórmula Obstétrica: `},
        {fontSize: 12, marginTop: -14, marginLeft: 240, alignment: 'justify', text: `${formObst}`, bold: true},
      ];
    if (gesAge => 0)
      gesAge = [
        {fontSize: 12, text: ` Edad Gestacional: `},
        {
          fontSize: 12,
          marginTop: -14,
          marginLeft: 240,
          alignment: 'justify',
          text: `${gesAge} semanas`,
          bold: true,
        },
      ];

    controlAmount = [
      {fontSize: 12, text: `¿Paciente tiene los 3 controles médicos?: `},
      {fontSize: 12, marginTop: -14, marginLeft: 240, alignment: 'justify', text: `${controlAmount}`, bold: true},
    ];
    if (imc => 0)
      imc = [
        {fontSize: 12, text: ` IMC :`},
        {fontSize: 12, marginTop: -14, marginLeft: 240, alignment: 'justify', text: `${imc}`, bold: true},
      ];

    if (diagnostic)
      diagnostics = [
        {fontSize: 12, text: ` Diagnósticos:`},
        {
          fontSize: 12,
          text: `${diagnostic}\n`,
          marginTop: -14,
          marginLeft: 240,
          bold: true,
          alignment: 'justify',
        },
      ];

    if (numberBirths => 0)
      numberBirths = [
        {fontSize: 12, text: ` N° Partos anteriores: `},
        {fontSize: 12, marginTop: -14, marginLeft: 240, alignment: 'justify', text: `${numberBirths}`, bold: true},
      ];
    if (numberCaesareanSections => 0)
      numberCaesareanSections = [
        {fontSize: 12, text: ` N° Césareas anteriores: `},
        {
          fontSize: 12,
          marginTop: -14,
          marginLeft: 240,
          alignment: 'justify',
          text: `${numberCaesareanSections}`,
          bold: true,
        },
      ];
    if (observa)
      observa = [
        {fontSize: 12, text: ` Observaciones: `},
        {fontSize: 12, marginTop: -14, marginLeft: 240, alignment: 'justify', text: `${observa}`, bold: true},
      ];

    const DATE_PATIENT = {
      alignment: 'center',
      marginTop: 100,
      marginRight: 400,
      fontSize: 10,
      style: 'title',
      stack: [
        `${date}`,
        {canvas: [{type: 'line', x1: 0, y1: 0, x2: 100, y2: 0, lineWidth: 1}]},
        {text: `FECHA`, bold: true},
      ],
    };

    const NAME_PATIENT = {
      alignment: 'center',
      marginTop: -24,
      fontSize: 10,

      style: 'title',
      stack: [
        `${patientNameValue}`,
        {canvas: [{type: 'line', x1: 0, y1: 0, x2: 200, y2: 0, lineWidth: 1}]},
        {text: `NOMBRE PACIENTE`, bold: true},
      ],
    };

    const SIGN_PATIENT = {
      alignment: 'center',
      marginTop: -12,
      fontSize: 10,
      marginLeft: 400,
      style: 'title',
      stack: [
        {canvas: [{type: 'line', x1: 0, y1: 0, x2: 100, y2: 0, lineWidth: 1}]},
        {text: `FIRMA PACIENTE`, bold: true},
      ],
    };

    const FONASA_PAD_INSCRIPTION_CONTENT = {
      fontSize: 15,
      alignment: 'justify',
      stack: [
        names,
        rut,
        edd,
        formObst,
        gesAge,
        controlAmount,
        imc,
        diagnostics,
        numberBirths,
        numberCaesareanSections,
        medic,
        observa,
      ],
    };

    return [FONASA_PAD_INSCRIPTION_CONTENT, DATE_PATIENT, NAME_PATIENT, SIGN_PATIENT, note1, dataContact, note2];
  });
  return [buildPdfPad];
};
