import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { SurgeryOrder } from '@shared/interfaces/surgery.interface';
import { ParamsToFindByPatient } from '@components/patient/interfaces/patient.interface';
import { Paperwork } from '@shared/interfaces/paperwork.interface';

/* Firma de Documentos Electronicos */
export const PUT_SURGERY_END = createAction('[cirugias] Resetea el estado para crear una nueva solicitud');

export const PUT_SURGERY = createAction('[cirugias] PUT Orden de Cirugía', props<{ valuesToSave: Paperwork<SurgeryOrder> }>());
export const PUT_SURGERY_OK = createAction('[cirugias] OK PUT Orden de Cirugía', props<{ surgery: Paperwork<SurgeryOrder> }>());
export const PUT_SURGERY_ERR = createAction('[cirugias] ERR PUT Orden de Cirugía', props<{ payload: HttpErrorResponse }>());

export const NOTIFY_KEIRON = createAction('[cirugias] NOTIFY keiron Orden de Cirugía', props<{
  surgery: Paperwork<SurgeryOrder>;
}>());
export const NOTIFY_KEIRON_OK = createAction('[cirugias] OK NOTIFY keiron Orden de Cirugía');
export const NOTIFY_KEIRON_ERR = createAction('[cirugias] ERR NOTIFY keiron Orden de Cirugía', props<{
  payload: HttpErrorResponse;
}>());

export const LOAD_SURGERIES_BY_PATIENT = createAction('[cirugias] Cargar Cirugía por paciente',
  props<{ params: ParamsToFindByPatient }>());
export const LOAD_SURGERIES_BY_PATIENT_OK =
  createAction('[cirugias] OK Cirugía por paciente', props<{ orders: Paperwork<SurgeryOrder>[] }>());
export const LOAD_SURGERIES_BY_PATIENT_ERR = createAction('[cirugias] ERR Cirugía por paciente', props<{ payload: HttpErrorResponse }>());

export const SELECTED_SURGERY = createAction('[cirugias] SELECTED Orden de Cirugía', props<{ selected: Paperwork<SurgeryOrder> }>());
export const UNSELECT_SURGERY = createAction('[cirugias] UNSELECT Orden de Cirugía');
export const CLEAR_SURGERY = createAction('[cirugias] CLEAR Orden de Cirugía');

export const LOAD_SURGERIES_BY_PROFESSIONAL = createAction('[cirugias] Cargar Cirugía por profesional',
  props<{ gsiMultiCondition1St: string }>());
export const LOAD_SURGERIES_BY_PROFESSIONAL_OK = createAction('[cirugias] OK Cirugía por profesional', props<{ orders: Paperwork<SurgeryOrder>[] }>());
export const LOAD_SURGERIES_BY_PROFESSIONAL_ERR = createAction('[cirugias] ERR Cirugía por profesional',
  props<{ payload: HttpErrorResponse }>());
