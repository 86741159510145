import { AnamnesisStandard } from '@shared/interfaces/anamnesis.interface';
import { Action, createReducer, on } from '@ngrx/store';

import { GET_ANAMNESIS, GET_ANAMNESIS_ERR, GET_ANAMNESIS_OK } from '@store/actions';
import { DEL_ANAMNESIS, DEL_ANAMNESIS_ERR, DEL_ANAMNESIS_OK } from '@store/actions';
import { PUT_ANAMNESIS, PUT_ANAMNESIS_ERR, PUT_ANAMNESIS_OK } from '@store/actions';
import { GET_ANAMNESIS_DRAFT, GET_ANAMNESIS_DRAFT_ERR, GET_ANAMNESIS_DRAFT_OK } from '@store/actions';

export interface AnamnesisState {
  anamnesis: AnamnesisStandard[];
  draft: AnamnesisStandard[];
  isLoaded: boolean;
  isLoading: boolean;
  error: any;
}

export const anamnesisState: AnamnesisState = {
  anamnesis: [],
  draft: [],
  isLoaded: false,
  isLoading: false,
  error: null,
};

const createReducerAnamnesis = createReducer(
  anamnesisState,

  // Obtener las anamnesis de un paciente
  on(GET_ANAMNESIS, (state) => ({ ...state, isLoading: true })),
  on(GET_ANAMNESIS_OK, (state, { anamnesis }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    anamnesis: [...anamnesis],
  })),
  on(GET_ANAMNESIS_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  // Obtener las anamnesis que aún no han sido completadas por un profesional
  on(GET_ANAMNESIS_DRAFT, (state) => ({ ...state, isLoading: true })),
  on(GET_ANAMNESIS_DRAFT_OK, (state, { anamnesis }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    draft: [...anamnesis],
  })),
  on(GET_ANAMNESIS_DRAFT_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  // Guardar nuevas anamnesis o actualizar si el pk y sk ya estan registrados
  on(PUT_ANAMNESIS, (state) => ({ ...state, isLoading: true })),
  on(PUT_ANAMNESIS_OK, (state, { anamnesis }) => {
    let storeAnamnesis = [...state.anamnesis];
    const index = state.anamnesis.findIndex((ans) => ans.id === anamnesis.id);

    if (index === -1) storeAnamnesis = [...state.anamnesis, anamnesis];
    else
      storeAnamnesis = state.anamnesis.map((ans) => {
        if (ans.id === anamnesis.id) return anamnesis;
        return ans;
      });

    const draftAnamnesis = storeAnamnesis.filter((draft) => draft.isDraft === true);
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      error: null,
      anamnesis: [...storeAnamnesis],
      draft: [...draftAnamnesis],
    };
  }),
  on(PUT_ANAMNESIS_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  // Eliminar una amannesis de un paciente que haya sido creada por el profesional con sesión activa
  on(DEL_ANAMNESIS, (state) => ({ ...state, isLoading: true })),
  on(DEL_ANAMNESIS_OK, (state, { anamnesis }) => {
    const anamnesisId = anamnesis.id;
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      error: null,
      anamnesis: [...state.anamnesis.filter((ans) => ans.id !== anamnesisId)],
      draft: [...state.draft.filter((draft) => draft.id !== anamnesisId)],
    };
  }),
  on(DEL_ANAMNESIS_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
);

export const anamnesisReducer = (state: AnamnesisState, action: Action) => createReducerAnamnesis(state, action);
