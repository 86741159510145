import {PAPERWORK_FORMS_DEFINITION}       from '@clinical/forms/constants/paperwork-forms.const';
import {PAPERWORK_INDICATIONS_DEFINITION} from '@clinical/indications/constants/paperwork-indications.const';
import {PAPERWORK_RECIPES_DEFINITION}     from '@clinical/medicines/constants/paperwork-recipes.const';
import {PAPERWORK_SURGERY_DEFINITION}     from '@clinical/surgeries/constants/papework-surgery.const';
import {ROOT_ROUTES}                      from '../../../app-routing.module';
import {CLINICAL_ROUTES}                  from '@clinical/clinical-routes.module';
import {FORMS_ROUTES}                     from '@clinical/forms/forms-routes.module';
import {MEDICINES_ROUTES}                 from '@clinical/medicines/medicines-routes.module';
import {INDICATIONS_ROUTES}               from '@clinical/indications/indications-routes.module';
import {SURGERIES_ROUTES}                 from '@clinical/surgeries/surgeries-routes.module';

export const FAVORITES_ROUTES = [
  {
    group : PAPERWORK_RECIPES_DEFINITION.receta.group,
    suffix: PAPERWORK_RECIPES_DEFINITION.receta.suffix,
    route : `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.medicines}/${MEDICINES_ROUTES.generalRecipe}`,
  },
  {
    group : PAPERWORK_RECIPES_DEFINITION.diabetes.group,
    suffix: PAPERWORK_RECIPES_DEFINITION.diabetes.suffix,
    route : `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.medicines}/${MEDICINES_ROUTES.diabetesRecipe}`,
  },
  {
    group : PAPERWORK_RECIPES_DEFINITION.magistral.group,
    suffix: PAPERWORK_RECIPES_DEFINITION.magistral.suffix,
    route : `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.medicines}/${MEDICINES_ROUTES.masterRecipe}`,
  },
  {
    group : PAPERWORK_FORMS_DEFINITION.certificate.group,
    suffix: PAPERWORK_FORMS_DEFINITION.certificate.suffix,
    route : `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.forms}/${FORMS_ROUTES.certificate}`,
  },
  {
    group : PAPERWORK_INDICATIONS_DEFINITION.exams.group,
    suffix: PAPERWORK_INDICATIONS_DEFINITION.exams.suffix,
    route : `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.indications}/${INDICATIONS_ROUTES.exams}`,
  },
  {
    group : PAPERWORK_INDICATIONS_DEFINITION.technicalAssistance.group,
    suffix: PAPERWORK_INDICATIONS_DEFINITION.technicalAssistance.suffix,
    route : `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.indications}/${INDICATIONS_ROUTES.techHelpOrthosis}`,
  },
  {
    group : PAPERWORK_SURGERY_DEFINITION.surgeries.group,
    suffix: PAPERWORK_SURGERY_DEFINITION.surgeries.suffix,
    route : `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.surgeries}/${SURGERIES_ROUTES.order}`,
  },
  // {
  //   route: '/derivaciones/interconsultas',
  //   suffix: PAPERWORK_DERIVATIONS_DEFINITION.interconsultas.suffix,
  //   group: PAPERWORK_DERIVATIONS_DEFINITION.interconsultas.group,
  // },
  // {
  //   route: '/formulario/ges',
  //   suffix: PAPERWORK_FORMS_DEFINITION.ges.suffix,
  //   group: PAPERWORK_FORMS_DEFINITION.ges.group,
  // },
  // {
  //   route: '/formulario/consentimiento',
  //   suffix: PAPERWORK_FORMS_DEFINITION.consent.suffix,
  //   group: PAPERWORK_FORMS_DEFINITION.consent.group,
  // },
  // {
  //   route: '/formulario/consentimiento-imagenes',
  //   suffix: PAPERWORK_FORMS_DEFINITION.imageconsent.suffix,
  //   group: PAPERWORK_FORMS_DEFINITION.imageconsent.group,
  // },
  // {
  //   route: '/formulario/inscripcion-pad-fonasa',
  //   suffix: PAPERWORK_FORMS_DEFINITION.pad.suffix,
  //   group: PAPERWORK_FORMS_DEFINITION.pad.group,
  // },
  // {
  //   route: '/formulario/ingreso-hospitalario',
  //   suffix: PAPERWORK_FORMS_DEFINITION.hospital.suffix,
  //   group: PAPERWORK_FORMS_DEFINITION.hospital.group,
  // },
  // {
  //   route: '/formulario/registro-ete',
  //   suffix: PAPERWORK_FORMS_DEFINITION.ete.suffix,
  //   group: PAPERWORK_FORMS_DEFINITION.ete.group,
  // },
  // {
  //   route: '/formulario/pnac',
  //   suffix: PAPERWORK_FORMS_DEFINITION.pnac.suffix,
  //   group: PAPERWORK_FORMS_DEFINITION.pnac.group,
  // },
  // {
  //   route: '/hospitalizacion/orden',
  //   suffix: PAPERWORK_HOSPITAL_DEFINITION.hospitalization.suffix,
  //   group: PAPERWORK_HOSPITAL_DEFINITION.hospitalization.group,
  // },

  // {
  //   route: '/material-educativo/tutoriales',
  //   suffix: PAPERWORK_EDUCATIONAL_DEFINITION.educational.suffix,
  //   group: PAPERWORK_EDUCATIONAL_DEFINITION.educational.group,
  // },

];
