import { Injectable } from '@angular/core';

import { Control } from '@clinical/indications/interfaces/indication.interface';

import { PDFDefinition, PDFWatermark, PDFWrapper } from '@shared/interfaces/pdf.interface';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { makeFooter } from './pdf-footer.helper';
import { getHeader } from './pdf-header.helper';
import { docMetatada } from './pdf-metadata.helper';
import { DauService } from '@clinical/emergencies/services/dau.service';
import {PAPERWORK_FORMS_DEFINITION} from '@clinical/forms/constants/paperwork-forms.const';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  constructor(public dauService: DauService) {}

  async createPdf(pdfParams) {
    const docDefinition = await this.getDocDefinition(pdfParams);
    const pdfDocGenerator = await pdfMake.createPdf(docDefinition);
    return await this.promiseToGetDataUrl(pdfDocGenerator, '');
  }

  base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    const filename = Date.now() + '.pdf';
    for (let i = 0; i < len; ++i) bytes[i] = binaryString.charCodeAt(i);

    return new Blob([bytes], { type: 'application/pdf' });
  }

  async getDocDefinition(params) {
    const { config, body } = params;
    const { isPreview } = config;

    const formsNoneFooter = [
      PAPERWORK_FORMS_DEFINITION.ges.config.footer.signature.printed
    ];
    const isFooterPrinted =  !formsNoneFooter.includes(config?.footer?.signature?.printed);

    let watermark: PDFWatermark = { text: 'BORRADOR', color: 'gray', opacity: 0.05 };
    const pageMargins = isFooterPrinted ? [40, 140, 40, 160]: [40, 140, 40, 40];

    const pageSize = 'LETTER';
    const styles = {
      logo: { margin: [40, 30, 0, 0] },
      headerText: { margin: [0, 35, 60, 0], fontSize: 10, bold: true },
      title: { margin: [40, 25, 40, 0], fontSize: 13, alignment: 'center', bold: true },
      singProfessional: { fontSize: 9, alignment: 'center', bold: true },
      content: { fontSize: 10, alignment: 'justify' },
      boldRight: { bold: true, alignment: 'right' },
      label: { bold: true, margin: [0, 10] },
      lineInnerTable: {fontSize: 9, lineHeight: 0.75, margin:[0,0,0,0]},
    };

    if (params?.title === 'documento epicrisis filmaker' || params.paperwork.suffix === 'EPI') watermark = undefined;
    if (!isPreview) watermark = undefined;

    return {
      styles,
      pageSize,
      watermark,
      pageMargins,
      ownerPassword: 'b8gUgQgsN3xhA8NuKTNTuJ7LUho6xveGzajvCd7R39vtRPBhLI',
      permissions: {
        printing: 'highResolution',
        modifying: false,
        copying: true,
        annotating: false,
        fillingForms: false,
        contentAccessibility: true,
        documentAssembly: false,
      },
      content: body,
      info: docMetatada(params),
      header: getHeader(params),
      footer: makeFooter(params),
    };
  }

  async getPdfDataUrl(pdfWrapper: PDFWrapper): Promise<string> {
    const dau =  pdfWrapper.metadata.title;
    const { docDefinition } = pdfWrapper;
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    const base64 = await this.promiseToGetDataUrl(pdfDocGenerator, dau);
    return base64;
  }

  async getPdfWrapper(docDefinition: PDFDefinition, control: Control): Promise<PDFWrapper> {
    const { patient } = docDefinition.valuesToSave;
    if (control)
      docDefinition.valuesToSave.patient = {
        ...patient,
        email: control.email,
        phone: control.phone,
        address: control.address,
      };
    const pdfWrapper: PDFWrapper = {
      metadata: docDefinition,
      docDefinition: await this.buildDocDefinition(docDefinition),
    };

    return pdfWrapper;
  }

  promiseToGetDataUrl(pdfDocGenerator: any, dau: string): Promise<string> {
    if(dau === 'detalle urgencia')
      return new Promise((resolve) => {
        pdfDocGenerator.getDataUrl((dataUrl: string) => {
          resolve(dataUrl);
        });
      });

    else
    return new Promise((resolve) => {
      pdfDocGenerator.getBase64((base64: string) => {
        resolve(base64);
      });
    });
  }

  ////// DAU //////

  async buildDocDefinition(params: PDFDefinition) {
    const { content, title, isPreview, isSigned, valuesToSave, containSigns, ignoreSigns, layoutDef } = params;
    const { professional, patient, center, shortId } = valuesToSave;

    const header = await this.dauService.header(professional, title, layoutDef);
    const footer = await this.dauService.footer(valuesToSave, isSigned, containSigns, ignoreSigns, layoutDef);
    const contentTop = this.dauService.cardContentTop(patient, center);
   let watermark: PDFWatermark = { text: 'BORRADOR', color: 'gray', opacity: 0.05 };
    const pageMargins = [40, 155, 40, 160];
    const pageSize = 'A4';
    const styles = {
      logo: { margin: [40, 30, 0, 0] },
      headerText: { margin: [0, 35, 60, 0], fontSize: 10, bold: true },
      title: { margin: [40, 25, 40, 0], fontSize: 13, alignment: 'center', bold: true },
      singProfessional: { fontSize: 9, alignment: 'center', bold: true },
      content: { fontSize: 10, alignment: 'justify' },
      boldRight: { bold: true, alignment: 'right' },
      label: { bold: true, margin: [0, 10] },
    };

    const info = {
      title: `${title} - ${patient?.documentNumber}`,
      author: 'UC CHRISTUS - Portal Clínico',
    };

    if (!isPreview) watermark = undefined;

    return {
      pageSize,
      watermark,
      pageMargins,
      header,
      content: layoutDef.hideContentTop ? [content] : [contentTop, ' ', content],
      footer: (currentPage: any, pageCount: any) => {
        if (!layoutDef.footerOnLastPage ) return footer;
        else if (layoutDef.footerOnLastPage && currentPage === pageCount) return footer;
      },
      styles,
      info,
    };
  }
}
