import { HttpErrorResponse } from '@angular/common/http';
import { Favorite, FavoriteSelected } from '@shared/interfaces/favorite.interface';

import { createAction, props } from '@ngrx/store';

export const GET_FAVORITES = createAction('[favoritos] Cargar Favoritos', props<{ gsiMultiCondition1St: string }>());
export const GET_FAVORITES_OK = createAction('[favoritos] OK Cargar Favoritos', props<{ favorites: Favorite[] }>());
export const GET_FAVORITES_ERR = createAction('[favoritos] ERR Cargar Favoritos', props<{ payload: any }>());

export const PUT_FAVORITE = createAction('[favoritos] PUT Favoritos', props<{ valuesToSave: Favorite }>());
export const PUT_FAVORITE_OK = createAction('[favoritos] OK PUT Favoritos', props<{ favorite: Favorite }>());
export const PUT_FAVORITE_ERR = createAction('[favoritos] ERR PUT Favoritos', props<{ payload: HttpErrorResponse }>());

export const SELECT_FAVORITE = createAction('[favoritos] SELECT Favorito', props<{ favorite: FavoriteSelected }>());
export const SELECTED_FAVORITE = createAction('[favoritos] SELECTED Favorito', props<{ favorite: FavoriteSelected }>());
export const UNSELECT_FAVORITE = createAction('[favoritos] UNSELECT Favoritos');

export const DEL_FAVORITE = createAction('[favoritos] DELETE Favoritos', props<{ sk: string }>());
export const DEL_FAVORITE_OK = createAction('[favoritos] OK DELETE Favoritos', props<{ sk: string }>());
export const DEL_FAVORITE_ERR = createAction(
  '[favoritos] ERR DELETE Favoritos',
  props<{ payload: HttpErrorResponse }>(),
);
