import { HttpErrorResponse } from '@angular/common/http';
import { ParamsToFindByPatient } from '@components/patient/interfaces/patient.interface';

import { createAction, props } from '@ngrx/store';
import { Derivation } from '@shared/interfaces/derivation.interface';
import { Paperwork } from '@shared/interfaces/paperwork.interface';

export const PUT_DERIVATION = createAction(
  '[derivaciones] PUT Derivación Médica',
  props<{ valuesToSave: Paperwork<Derivation> }>(),
);
export const PUT_DERIVATION_OK = createAction(
  '[derivaciones] OK PUT Derivación Médica',
  props<{ derivation: Paperwork<Derivation> }>(),
);
export const PUT_DERIVATION_ERR = createAction(
  '[derivaciones] ERR PUT Derivación Médica',
  props<{ payload: HttpErrorResponse }>(),
);

export const LOAD_DERIVATIONS_BY_PATIENT = createAction(
  '[derivaciones] Cargar Derivación por paciente',
  props<{ params: ParamsToFindByPatient }>(),
);
export const LOAD_DERIVATIONS_BY_PATIENT_OK = createAction(
  '[derivaciones] OK Derivación por paciente',
  props<{ derivations: Paperwork<Derivation>[] }>(),
);
export const LOAD_DERIVATIONS_BY_PATIENT_ERR = createAction(
  '[derivaciones] ERR Derivación por paciente',
  props<{ payload: HttpErrorResponse }>(),
);

export const LOAD_DERIVATIONS_BY_PROFESSIONAL = createAction(
  '[derivaciones] Cargar Derivación por profesional',
  props<{ gsiMultiCondition1St: string }>(),
);
export const LOAD_DERIVATIONS_BY_PROFESSIONAL_OK = createAction(
  '[derivaciones] OK Derivación por profesional',
  props<{ derivations: Paperwork<Derivation>[] }>(),
);

export const LOAD_DERIVATIONS_BY_PROFESSIONAL_ERR = createAction(
  '[derivaciones] ERR Derivación por profesional',
  props<{ payload: HttpErrorResponse }>(),
);

export const PUT_DERIVATION_END = createAction('[derivaciones] Resetea el estado para crear una nueva solicitud');

export const DELETE_DERIVATION = createAction(
  '[derivaciones] DELETE Derivación',
  props<{ derivation: Paperwork<Derivation> }>(),
);
export const DELETE_DERIVATION_OK = createAction(
  '[derivaciones] OK DELETE Derivación',
  props<{ derivation: Paperwork<Derivation> }>(),
);
export const DELETE_DERIVATION_ERR = createAction(
  '[derivaciones] ERR DELETE Derivación',
  props<{ payload: HttpErrorResponse }>(),
);

export const SELECTED_DERIVATION = createAction(
  '[derivaciones] SELECTED Derivación',
  props<{ selected: Paperwork<Derivation>; action: string }>(),
);
export const UNSELECT_DERIVATION = createAction('[derivaciones] UNSELECT Derivación');
export const CLEAR_DERIVATION = createAction('[derivaciones] CLEAR Derivación');
