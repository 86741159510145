import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-meeting-not-found',
  templateUrl: './meeting-not-found.component.html',
  styleUrls: ['./meeting-not-found.component.scss'],
})
export class MeetingNotFoundComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    const loader = document.getElementById('app-loader');
    if (this.router.url === '/telemedicina-no-autorizada') loader.classList.add('ion-hide');
  }
}
