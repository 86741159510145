import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { REGEX_CLEAN_RUT } from '@shared/constants/regex.const';
import { UiService } from '@shared/services/ui.service';
import { AuthService } from '../../services/auth.service';
import { AUTH_ROUTES } from '@auth/auth-routing.module';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
})
export class ResetComponent {
  frmResetPass: UntypedFormGroup;
  isLoading = false;
  error: HttpErrorResponse;
  message: string;

  constructor(
    private router: Router,
    public uiService: UiService,
    public authService: AuthService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.buildForm();
    this.message = undefined;
  }


  redirectToChangePasswordPage() {
    const routePageToChangePassword =[
      AUTH_ROUTES.auth,
      AUTH_ROUTES.requestChangePassword,
      this.frmResetPass.controls.run.value
    ];
    this.router.navigate(routePageToChangePassword);
  }

  resetPassword() {
    this.isLoading = true;
    const run = this.frmResetPass.controls.run.value.replace(REGEX_CLEAN_RUT, '');
    this.authService.resetPassword(run).subscribe(
      (resp) => {
        const message = `<p>Hemos enviado un correo electónico con las instrucciones para restablecer su contraseña.</p>`;
        this.uiService.presentAlert('info', message);
        this.isLoading = false;
        this.frmResetPass.reset();
        const routePageToChangePassword =[AUTH_ROUTES.auth, AUTH_ROUTES.requestChangePassword, run];
        this.router.navigate(routePageToChangePassword);
      },
      (error) => {
        this.message = 'Ocurrió un error mientras se validaban sus datos.';
        this.isLoading = false;
      },
    );
    this.isLoading = false;
  }

  buildForm(): void {
    this.frmResetPass = this.formBuilder.group({
      run: '',
    });
  }
}
