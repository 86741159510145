import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AnamnesisStandard } from '@shared/interfaces/anamnesis.interface';
import { Patient } from '@components/patient/interfaces/patient.interface';
import { Professional } from '@auth/interfaces/professional.interface';
import { ANAMNESIS_TEMPLATES, AnamnesisTemplate } from '@components/anamnesis/constants/anamnesis.const';
import { ModalController, PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/auth/services/auth.service';
import { AppState } from 'src/app/store/app.reducers';
import { PopoverAnamnesisSelectorComponent } from '../anamnesis-selector/popover-anamnesis-selector.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KEY_ANAMNESIS_STORE, KEY_PATIENT_STORE } from '@store/store-keys';

@Component({
  selector: 'app-btn-anamnesis-selector',
  templateUrl: './btn-anamnesis-selector.component.html',
  styleUrls: ['./btn-anamnesis-selector.component.scss'],
})
export class BtnAnamnesisSelectorComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() haveIcon: boolean;

  patient: Patient;
  draft: AnamnesisStandard[];
  professional: Professional;
  anamnesisDraft: AnamnesisStandard;
  namePatientDraftAnamnesis: string;

  isLoading: boolean;
  isLoaded: boolean;
  error: string;
  destroy = new Subject();

  constructor(
    private store: Store<AppState>,
    private popoverController: PopoverController,
    private modalController: ModalController,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.readStorePatient();
    this.readStoreAnamnesis();
  }

  ngOnDestroy() {
    this.destroy.next('next');
    this.destroy.complete();
}

  async selectorAnamnesisPopover(ev: any) {
    if (this.namePatientDraftAnamnesis) {
      const draft = ANAMNESIS_TEMPLATES.find((anamnesi) => anamnesi.name === this.namePatientDraftAnamnesis);
      this.presentAnamnesisModal(draft);
      return;
    }

    const popover = await this.popoverController.create({
      component: PopoverAnamnesisSelectorComponent,
      event: ev,
      mode: 'ios',
    });
    await popover.present();

    const { data } = await popover.onWillDismiss();
    if (!data) return;

    const anamnesisSelected = ANAMNESIS_TEMPLATES.find((anamnesi) => anamnesi.selector === data);
    if (anamnesisSelected) this.presentAnamnesisModal(anamnesisSelected);
  }

  async presentAnamnesisModal(template: AnamnesisTemplate) {
    const modal = await this.modalController.create({
      component: template.component,
      cssClass: 'modal-xxl-float-left',
      componentProps: {
        isWriteMode: true,
        template,
        anamnesis: { ...this.anamnesisDraft },
      },
    });
    return await modal.present();
  }

  readStorePatient(): void {
    this.store.select(KEY_PATIENT_STORE).pipe(takeUntil(this.destroy)).subscribe(({ patient, isLoading, isLoaded, error }) => {
      this.patient = patient;
      this.isLoading = isLoading;
      this.isLoaded = isLoaded;
      this.error = error;
    });
  }

  readStoreAnamnesis(): void {
    this.store.select(KEY_ANAMNESIS_STORE).pipe(takeUntil(this.destroy)).subscribe(async ({ draft, isLoading, isLoaded, error }) => {
      this.draft = draft;
      this.havePatientAnamnesisDraftByProfessional();
      this.isLoading = isLoading;
      this.isLoaded = isLoaded;
      this.error = error;
    });
  }

  havePatientAnamnesisDraftByProfessional() {
    const patientId = this.patient.id;

    if (this.isLoaded) {
      this.anamnesisDraft = this.draft.find((anamnesis) => anamnesis.patient.id === patientId);
      this.namePatientDraftAnamnesis = this.anamnesisDraft?.template;
    }
  }
}
