export const COD_STUDY = [
  {
    name    : 'Estudio histopatológico corriente de biopsia diferida (por cada órgano)',
    code: '0801008',
    quantity: 1,
  },
  {
    name    : 'Estudio histoquímico, cada uno',
    code: '0801005',
    quantity: 1,

  },
  {
    name    : 'Estudio inmunohistoquímico, cada uno',
    code: '0801004',
    quantity: 1,

  },
];

export const TIMES = ['10', '20','30', '40', '60'];

export const INDICATIONS_PROCEDURES_DERMATOLOGY = [
  '1601110',
  '1601111',
  '1601112',
  '1601113',
  '1601115',
  '1601116',
  '1601117',
  '1601118',
  '1601119',
  '1601120',
  '1601121',
  '1601122',
  '1601124',
  '1601126',
  '1602201',
  '1602202',
  '1602203',
  '1602204',
  '1602205',
  '1602206',
  '1602207',
  '1602211',
  '1602212',
  '1602213',
  '1602214',
  '1602215',
  '1602216',
  '1602221',
  '1602222',
  '1602223',
  '1602224',
  '1602225',
  '1602231',
  '1602232',
  '1602233',
  '1602240',
  '1602241',
  '1602242',
];
