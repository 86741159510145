export const HTML_MAIL_HEADER = `
<table width="100%" border="0" cellspacing="0" cellpadding="0">
  <tbody>
    <tr>
      <td height="35"></td>
    </tr>
    <tr>
      <td>
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td valign="top" align="left" class="specbundle">
                <div class="contentEditableContainer contentTextEditable">
                  <div class="contentEditable">
                    <img
                      src="https://www.ucchristus.cl/assets/images/logo.png"
                      alt="Red de Salud UC CHRISTUS"
                      width="250"
                    />
                  </div>
                </div>
              </td>
              <td valign="top" class="specbundle">
                <div class="contentEditableContainer contentTextEditable"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>
`;
