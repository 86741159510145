import {Action, createReducer, on} from '@ngrx/store';
import {CLEAR_EMAIL, NOT_SENDING_EMAIL, SEND_EMAIL, SEND_EMAIL_ERR, SEND_EMAIL_OK} from '../actions';

export interface NotificationState {
  sendMailStatus: number;
  status: string;
  isLoaded: boolean;
  isLoading: boolean;
  error: any;
}

export const notificationState: NotificationState = {
  status        : '',
  isLoaded      : false,
  isLoading     : false,
  error         : null,
  sendMailStatus: 0,
};

const createReducerNotification = createReducer(
    notificationState,

    on(SEND_EMAIL, (state) => ({...state, isLoading: true, isLoaded: false, status: 'Enviando correo electrónico.'})),

    on(SEND_EMAIL_OK, (state, {metadata}) => ({
      ...state,
      isLoading : false,
      status    : 'Se notificó vía correo electrónico.',
      isLoaded  : true,
      error     : null,
      statusCode: metadata.statusCode,
    })),

    on(NOT_SENDING_EMAIL, (state, {message}) => ({
      ...state,
      isLoading : false,
      status    : message,
      isLoaded  : true,
      error     : null,
      statusCode: 0,
    })),

    on(SEND_EMAIL_ERR, (state, {payload}) => ({
      ...state,
      isLoading: false,
      isLoaded : false,
      status   : 'Ocurrio un error al intentar enviar correo electrónico.',
      error    : {
        url    : payload.url,
        name   : payload.name,
        message: payload.message,
      },
    })),

    on(CLEAR_EMAIL, (state) => ({
      isLoading     : false,
      isLoaded      : false,
      error         : null,
      sendMailStatus: 0,
      status        : '',
    })),
);

export const notificationReducer = (state: NotificationState, action: Action) =>
    createReducerNotification(state, action);
