import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as storageActions from '../actions';

import { of, tap } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { StorageService } from '@shared/services/storage.service';

@Injectable()
export class StorageEffects {
  presignedUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(storageActions.CREATE_PRESIGNED_URL),
      mergeMap(({ params }) =>
        this.storageService.createPresignedUrl({ ...params }).pipe(
          map((signedUrl) => storageActions.CREATE_PRESIGNED_URL_OK({ signedUrl })),
          catchError((err) => of(storageActions.CREATE_PRESIGNED_URL_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  presignedUrlList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(storageActions.CREATE_LIST_PRESIGNED_URL),
      mergeMap(() => {
        const params: any = {};
        return this.storageService.createListUrls({ ...params }).pipe(
          map((signedUrls) => storageActions.CREATE_LIST_PRESIGNED_URL_OK({ signedUrls })),
          catchError((err) => of(storageActions.CREATE_LIST_PRESIGNED_URL_ERR({ payload: err }))),
        );
      }
        ,
      ),
    ),
  );

  putObjectToS3$ = createEffect(() =>
    this.actions$.pipe(
      ofType(storageActions.PUT_OBJECT_S3),
      mergeMap(({ url, docFile }) =>
        this.storageService.putObjectToS3(url, docFile).pipe(
          map((progress) => storageActions.PUT_OBJECT_S3_OK({ progress })),
          catchError((err) => of(storageActions.PUT_OBJECT_S3_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private storageService: StorageService) {}
}
