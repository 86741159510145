import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Favorite, FavoriteSelected } from '@shared/interfaces/favorite.interface';
import { PCV2Request } from '@shared/interfaces/request.interface';
import { environment } from '@environments/environment';

import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AppState } from '@store/app.reducers';
import { KEY_FAVORITES_STORE } from '@store/store-keys';
import { UNSELECT_FAVORITE } from '../store/favorites.actions';

import { PAPERWORK_INDICATIONS_DEFINITION } from '@clinical/indications/constants/paperwork-indications.const';
import { ClinicalIndicationService } from '@clinical/indications/services/indication.service';
import { ExamIndication } from '@clinical/indications/interfaces/indication.interface';
import {NavigationEnd, Router} from '@angular/router';
import {FAVORITES_ROUTES} from '@components/favorites/constants/favorites.const';

const BASE_API: string = environment.baseApiPCv2;
const EXAMS_GROUP: string = PAPERWORK_INDICATIONS_DEFINITION.exams.group;

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>,
    private router: Router,
    private clinicalIndicationService: ClinicalIndicationService,
  ) {
  }

  putFavorites(favorite: Favorite): Observable<Favorite> {
    return this.httpClient
      .post<PCV2Request>(`${BASE_API}/clinico/v1/favoritos`, favorite)
      .pipe(map(({ data }: { data: Favorite }) => data));
  }

  getFavoritesByProfessional(gsiMultiCondition1St: string): Observable<Favorite[]> {
    const gsiEncoded = encodeURIComponent(gsiMultiCondition1St);
    const endpoint = `${BASE_API}/clinico/v1/indices/1/buscar/${gsiEncoded}`;
    return this.httpClient.get<PCV2Request>(endpoint).pipe(map(({ data }: { data: Favorite[] }) => data));
  }

  deleteFavorite(sk: string): Observable<boolean> {
    const [prefix, professionalId, favoriteId] = sk.split('#');
    const url = `${BASE_API}/clinico/v1/favoritos/${favoriteId}/profesionales/${professionalId}`;
    return this.httpClient.delete<PCV2Request>(url).pipe(map(({ state }) => state));
  }

  favoritesStoreObservable(group: string): Observable<any> {
    return this.store.select(KEY_FAVORITES_STORE)
      .pipe(
        filter(({ selected, isLoaded }) => selected && isLoaded && group.includes(selected.group)),
        tap(() => this.store.dispatch(UNSELECT_FAVORITE())),
        map(({selected}) => (selected))
      );
  }

  findFavoriteInStoreByName(name: string, professional?): Observable<any> {
    return this.store.select(KEY_FAVORITES_STORE).pipe(
      map(favoritesState => favoritesState.favorites),
      map(favorites => favorites.find(
        favorite => favorite.name === name && favorite.professional?.id === professional?.id),
      ),
    );
  }

  /**
   * Actualiza contenido registrado en favorito consultando nuevamente la fuente de datos.
   *
   * @param favoriteSelected
   */
  getUpdatedFavoritesContent(favoriteSelected: FavoriteSelected): Observable<FavoriteSelected> {
    const { group, favorite } = favoriteSelected;
    if (group === EXAMS_GROUP)
      return this.clinicalIndicationService.getUpdatedExams(favorite.content)
        .pipe(
          map((updatedIndications: ExamIndication[]) => ({
            group,
            favorite: { ...favoriteSelected.favorite, content: updatedIndications },
          })),
        );
    return of(favoriteSelected);
  }

  isFavoritesVisible(): Observable<boolean> {
     return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: any) => !FAVORITES_ROUTES.find(fav => fav.route === event.url)),
    );
  }

}
