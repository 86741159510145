import { createAction, props } from '@ngrx/store';
import { AnamnesisStandard } from '@shared/interfaces/anamnesis.interface';
import { HttpErrorResponse } from '@angular/common/http';

/* Obtener las anamnesis de un paciente */
export const GET_ANAMNESIS = createAction('[medicalcare] GET Anamnesis', props<{ patientId: string }>());
export const GET_ANAMNESIS_OK = createAction(
  '[medicalcare] OK GET Anamnesis',
  props<{ anamnesis: AnamnesisStandard[] }>(),
);
export const GET_ANAMNESIS_ERR = createAction('[medicalcare] ERR GET Anamnesis ', props<{ payload: any }>());

/* Obtener las anamnesis que aún no han sido completadas por un profesional */
export const GET_ANAMNESIS_DRAFT = createAction(
  '[medicalcare] GET Borradores Anamnesis',
  props<{ professionalId: string }>(),
);
export const GET_ANAMNESIS_DRAFT_OK = createAction(
  '[medicalcare] OK GET Borradores Anamnesis',
  props<{ anamnesis: AnamnesisStandard[] }>(),
);
export const GET_ANAMNESIS_DRAFT_ERR = createAction(
  '[medicalcare] ERR GET  Borradores Anamnesis ',
  props<{ payload: HttpErrorResponse }>(),
);

/* Crear registro nuevo o reemplaza un registro antiguo por uno nuevo */
export const PUT_ANAMNESIS = createAction('[medicalcare] PUT Anamnesis', props<{ frm: AnamnesisStandard }>());
export const PUT_ANAMNESIS_OK = createAction(
  '[medicalcare] OK PUT Anamnesis',
  props<{ anamnesis: AnamnesisStandard }>(),
);
export const PUT_ANAMNESIS_ERR = createAction('[medicalcare] ERR PUT Anamnesis', props<{ payload: any }>());

/* Crear registro nuevo o reemplaza un registro antiguo por uno nuevo */
export const DEL_ANAMNESIS = createAction(
  '[medicalcare] DEL Anamnesis',
  props<{ anamnesisToDelete: AnamnesisStandard }>(),
);
export const DEL_ANAMNESIS_OK = createAction(
  '[medicalcare] OK DEL Anamnesis',
  props<{ anamnesis: AnamnesisStandard }>(),
);
export const DEL_ANAMNESIS_ERR = createAction('[medicalcare] ERR DEL Anamnesis', props<{ payload: any }>());
