import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducers';
import { DEL_ANAMNESIS, PUT_ANAMNESIS } from '@components/anamnesis/store/anamnesis.actions';

import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { DiagnosticFinderComponent } from '../diagnostics-finder/diagnostic-finder.component';
import { PdfAnamnesisPreviewComponent } from '../anamnesis-pdf-preview/pdf-anamnesis-preview.component';

import { Patient } from '@components/patient/interfaces/patient.interface';
import { PDFDefinition, PDFWrapper } from '@shared/interfaces/pdf.interface';
import { Anamnesis, AnamnesisStandard, Diagnostic } from '@shared/interfaces/anamnesis.interface';

import { PdfService } from '@shared/helpers/pdf.helper';
import { FormFieldsValidate } from '@core/reactive-forms/form-fields-validate.helper';
import { Professional } from '@auth/interfaces/professional.interface';
import { AnamnesisTemplate } from '@components/anamnesis/constants/anamnesis.const';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-frm-anamnesis-standard',
  templateUrl: './frm-anamnesis-standard.component.html',
  styleUrls: ['./frm-anamnesis-standard.component.scss'],
})
export class FrmAnamnesisStandardComponent implements OnInit, OnDestroy {
  @Input() anamnesis: AnamnesisStandard;
  @Input() template: AnamnesisTemplate;
  @Input() isWriteMode: boolean;
  @Input() diagnostics: Diagnostic[] = [];

  frmAnamnesisStandard: UntypedFormGroup;

  error: string;
  isLoaded: boolean;
  isLoading: boolean;
  destroy = new Subject();
  patient: Patient;
  professional: Professional;

  constructor(
    private store: Store<AppState>,
    private pdfService: PdfService,
    private formBuilder: UntypedFormBuilder,
    public modalController: ModalController,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    const anamnesis = { ...this.anamnesis };

    this.readStoreAnamnesis();
    this.readStorePatient();
    this.buildForm(anamnesis);
    this.saveOnChange();
  }

  ngOnDestroy() {
    this.destroy.next('next');
    this.destroy.complete();
  }

  buildForm(anamnesis: AnamnesisStandard): void {
    const { name } = this.template;

    this.frmAnamnesisStandard = this.formBuilder.group({
      isDraft: true,
      reason: FormFieldsValidate.requiredMinLength(10),
      anamnesis: FormFieldsValidate.requiredMinLength(10),
      examination: FormFieldsValidate.requiredMinLength(10),
      indications: FormFieldsValidate.requiredMinLength(10),
      notes: [],
      diagnostics: [],
      template: name,
      center: {
        id: '',
        name: 'Nombre del Centro',
      },
    });

    if (anamnesis) {
      this.diagnostics = anamnesis.diagnostics;
      this.frmAnamnesisStandard.patchValue({ ...anamnesis });
    }
  }

  readStorePatient(): void {
    this.store
      .select('patient')
      .pipe(takeUntil(this.destroy))
      .subscribe(({ patient, isLoading, isLoaded, error }) => {
        this.patient = patient;
        this.isLoading = isLoading;
        this.isLoaded = isLoaded;
        this.error = error;
      });
  }

  readStoreAnamnesis(): void {
    this.store
      .select('anamnesis')
      .pipe(takeUntil(this.destroy))
      .subscribe(async ({ anamnesis, isLoading, isLoaded, error }) => {
        this.anamnesis = anamnesis.find((ans) => ans.isDraft === true);
        this.isLoading = isLoading;
        this.isLoaded = isLoaded;
        this.error = error;
      });
  }

  deleteDiagnostic(index: number) {
    const diagnostics = [...this.diagnostics];
    diagnostics.splice(index, 1);
    this.diagnostics = diagnostics;
    this.frmAnamnesisStandard.patchValue({ diagnostics });
  }

  async openDiagnosticFinder() {
    const modal = await this.modalController.create({
      component: DiagnosticFinderComponent,
      cssClass: 'cmodal-xl',
      componentProps: { diagnostics: this.diagnostics },
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();
    const diagnostics: Diagnostic[] = data;
    if (diagnostics) {
      this.diagnostics = [...diagnostics];
      this.frmAnamnesisStandard.patchValue({ diagnostics: [...diagnostics] });
    }
  }

  saveOnChange() {
    this.frmAnamnesisStandard.valueChanges
      .pipe(takeUntil(this.destroy), debounceTime(1000))
      .subscribe(async (frm: AnamnesisStandard) => {
        if (this.anamnesis) frm = { ...this.anamnesis, ...frm };

        if (this.patient) {
          const { id, names, surnames, documentNumber, email } = this.patient;
          frm.patient = { id, names, surnames, documentNumber, email };
        }

        if (this.professional) {
          const { id, names, surnames, rut, specialty } = this.authService.professionalRef;
          frm.professional = { id, names, surnames, rut, specialty };
        }

        const profesionalId = this.professional.id;
        frm.gsiOne = `PRO#${profesionalId}#isDraft#true`;

        if (!frm.isDraft) delete frm.gsiOne;
        this.anamnesis = { ...frm };
        this.store.dispatch(PUT_ANAMNESIS({ frm }));
      });
  }

  duplicate(anamnesis: AnamnesisStandard) {
    this.isWriteMode = true;

    const { id, names, surnames } = this.authService.professionalRef;
    anamnesis.professional = {
      id,
      names,
      surnames,
      rut: '',
      email: '',
    };
    anamnesis.center = {
      id: '',
      name: 'Nombre del Centro',
    };

    const profesionalId = this.professional.id;
    anamnesis.gsiOne = `PRO#${profesionalId}#isDraft#true`;
    anamnesis.isDraft = true;
    anamnesis.notes = [];
    this.frmAnamnesisStandard.patchValue(anamnesis);
  }

  async presentPDF(isPreview: boolean) {
    const isFormValid = this.frmAnamnesisStandard.valid;
    if (!isFormValid) return;

    const title = this.template.name;
    const pdfWrapper = await this.pdfDefinition(isPreview, title);
    const modal = await this.modalController.create({
      component: PdfAnamnesisPreviewComponent,
      cssClass: 'modal-xxl-float-left',
      componentProps: { pdfWrapper, isPreview, anamnesisToSave: this.anamnesis },
      backdropDismiss: false,
    });
    await modal.present();

    const { data: isSaving } = await modal.onDidDismiss();
    if (isSaving) this.dismiss();
  }

  async pdfDefinition(isPreview: boolean, title: string) {
    const docDefinition: PDFDefinition = {
      title,
      isPreview,
      valuesToSave: null,
      patient: this.patient,
      professional: this.professional,
      content: this.buildContentPdf(),
      isSigned: false,
      layoutDef: {
        footerOnLastPage: true,
      },
    };

    const pdfWrapper: PDFWrapper = {
      metadata: docDefinition,
      docDefinition: 'await this.pdfService.buildDocDefinition(docDefinition)',
    };
    return pdfWrapper;
  }

  buildContentPdf() {
    const DIAGNOSTICS_TITLE = {
      fontSize: 12,
      margin: [0, 5],
      stack: [
        { text: `DIAGNÓSTICO:`, bold: true },
        { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1, color: 'gray' }] },
      ],
    };

    if (!this.diagnostics) {
      this.diagnostics = [];
      DIAGNOSTICS_TITLE.stack = [];
      delete DIAGNOSTICS_TITLE.margin;
    }

    const DIAGNOSTICS = this.diagnostics.map((diagnostic) => {
      const { code, descriptor, name, observations } = diagnostic;

      let OBSERVATIONS: any = { text: [{ text: `OBSERVACIONES: `, bold: true }, `${observations}`] };
      if (!observations) OBSERVATIONS = [];

      return [
        {
          fontSize: 9,
          alignment: 'justify',
          stack: [
            { text: [{ text: `DIAGNÓSTICO CIE-10  [${code}] - `, bold: true }, `${descriptor}`] },
            { text: [{ text: `PATOLOGÍA GES  : `, bold: true }, `${name}`] },
            OBSERVATIONS,
            { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.5, color: 'gray' }] },
          ],
        },
      ];
    });

    let { reason, anamnesis, examination, indications } = this.frmAnamnesisStandard.value;
    if (reason) reason = [{ text: `MOTIVO DE CONSULTA:`, style: 'label' }, { text: `${reason}` }];
    if (anamnesis) anamnesis = [{ text: `ANAMNESIS:`, style: 'label' }, { text: `${anamnesis}` }];
    if (examination) examination = [{ text: `EXAMEN FÍSICO:`, style: 'label' }, { text: `${examination}` }];
    if (indications) indications = [{ text: `INDICACIONES:`, style: 'label' }, { text: `${indications}` }];
    const ANAMNESIS_FIELDS = {
      fontSize: 10,
      alignment: 'justify',
      stack: [reason, anamnesis, examination, indications],
    };

    return [DIAGNOSTICS_TITLE, DIAGNOSTICS, ANAMNESIS_FIELDS];
  }

  dismiss() {
    this.modalController.dismiss();
  }

  delete() {
    const anamnesisToDelete = this.anamnesis;
    this.store.dispatch(DEL_ANAMNESIS({ anamnesisToDelete }));
    if (this.isLoaded) this.dismiss();
  }

  onSubmit() {
    const anamnesis = { ...this.anamnesis };
    delete anamnesis.gsiOne;
    anamnesis.isDraft = false;
    const frm = { ...anamnesis };
    this.store.dispatch(PUT_ANAMNESIS({ frm }));
    this.dismiss();
  }
}
