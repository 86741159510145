import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Paperwork, PaperworkDefinition } from '@shared/interfaces/paperwork.interface';
import { UntypedFormGroup } from '@angular/forms';
import { Attachment, FileToStorage } from '@shared/interfaces/storage.interface';
import { FormNextControl } from '@components/control/interfaces/next-control.interface';
import { Patient } from '@components/patient/interfaces/patient.interface';

export type Actions = 'sign' | 'storage' | 'put' | 'notify';

export const paperwork     = '[tramite] Tramitar Documento a Paciente';
export const PAPERWORK     = createAction(paperwork, props<{ content: UntypedFormGroup; action: string }>());
export const paperworkOk   = '[tramite] OK Tramitar Documento a Paciente';
export const PAPERWORK_OK  = createAction(paperworkOk, props<{ paperwork: Paperwork; definition: PaperworkDefinition }>());
export const paperworkErr  = '[tramite] ERR Tramitar Documento a Paciente';
export const PAPERWORK_ERR = createAction(paperworkErr, props<{ error: HttpErrorResponse }>());

export const CLEAR_PAPERWORK = createAction('[tramite] Limpiar tramite');

export const attachFile  = '[tramite] OK Adjuntar archivo';
export const ATTACH_FILE = createAction(attachFile, props<{ documents: FileToStorage[] }>());
export const detachFile  = '[tramite] OK Remover archivo';
export const DETACH_FILE = createAction(detachFile, props<{ index: number }>());

export const sendForm  = '[tramite] Confirmar envio formulario';
export const SEND_FORM = createAction(sendForm);

export const skipSignature  = '[tramite] Omitir Firmar tramite del paciente';
export const SKIP_SIGNATURE = createAction(skipSignature);
export const signatureOk    = '[tramite] OK Firmar tramite del paciente';
export const SIGNATURE_OK   = createAction(signatureOk);
export const signatureErr   = '[tramite] ERR Firmar tramite del paciente';
export const SIGNATURE_ERR  = createAction(signatureErr);

export const getPresignedUrlOk     = '[tramite] OK Obtener URLs Pre firmadas';
export const GET_PRESIGNED_URL_OK  = createAction(getPresignedUrlOk, props<{ documents: any[] }>());
export const getPresignedUrlErr    = '[tramite] ERR Obtener URLs Pre firmadas';
export const GET_PRESIGNED_URL_ERR = createAction(getPresignedUrlErr, props<{ error: HttpErrorResponse }>());

export const skipStorage  = '[tramite] Omitir Almacenar archivos';
export const SKIP_STORAGE = createAction(skipStorage);
export const storageOk    = '[tramite] OK Almacenar archivos';
export const STORAGE_OK   = createAction(storageOk, props<{ attachments: Attachment[] }>());
export const storageErr   = '[tramite] ERR Almacenar archivos';
export const STORAGE_ERR  = createAction(storageErr, props<{ error: HttpErrorResponse }>());

export const skipSaveRecord   = '[tramite] Omitir Guardar registro';
export const SKIP_SAVE_RECORD = createAction(skipSaveRecord);
export const saveRecordOk     = '[tramite] OK Guardar registro';
export const SAVE_RECORD_OK   = createAction(saveRecordOk, props<{ paperwork: Paperwork }>());
export const saveRecordErr    = '[tramite] ERR Guardar registro';
export const SAVE_RECORD_ERR  = createAction(saveRecordErr, props<{ error: HttpErrorResponse }>());

export const skipSendEmail        = '[tramite] Omitir Enviar correo electrónico';
export const SKIP_SEND_EMAIL      = createAction(skipSendEmail);
export const sendEmailOk          = '[tramite] OK Enviar correo';
export const PPW_SEND_EMAIL_OK    = createAction(sendEmailOk);
export const sendEmailEmpty       = '[tramite] OK Enviar correo vacio';
export const PPW_SEND_EMAIL_EMPTY = createAction(sendEmailOk);
export const ppwSendEmailErr      = '[tramite] ERR Enviar correo';
export const PPW_SEND_EMAIL_ERR   = createAction(ppwSendEmailErr, props<{ error: HttpErrorResponse }>());

export const setNextControl    = '[tramite] Set Proximo control';
export const SET_NEXT_CONTROL = createAction(setNextControl, props<{ nextControl: FormNextControl }>());

export const skipNextControl  = '[tramite] Omitir Proximo control';
export const SKIP_NEXT_CONTROL = createAction(skipNextControl);
export const nextControlOk    = '[tramite] OK Proximo control';
export const NEXT_CONTROL_OK   = createAction(nextControlOk);
export const nextControlErr   = '[tramite] ERR Proximo control';
export const NEXT_CONTROL_ERR  = createAction(nextControlErr, props<{ error: HttpErrorResponse }>());

export const updatePaperworkPatient = '[tramite] UPDATE paciente';
export const UPDATE_PAPERWORK_PATIENT = createAction(updatePaperworkPatient, props<{
  patient: Patient;
  paperwork: Paperwork;
}>());
