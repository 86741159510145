import { patientTopCard } from '@shared/helpers/pdf-patient-card.helper';
import { Hospitalization } from '@shared/interfaces/hospitalization.interface';

export const buildContentPdfHospital = (paperwork, group) => {
  const {rows}           = group;
  const buildPdfHospital = rows.map((order: Hospitalization) => {
    const PATHOLOGIES = order.pathologies.map(pathologic => [
      [{ text: `Nombre: ${pathologic.name}` }],
      [{text: `Observaciones: ${pathologic.observations}`}],
      [{ text: `Código GES: ${pathologic.pathologic?.code}` }],
    ]);

    const { date, isGES, diagnostics, medicalAreas, observations } = order;

    const TEXT = {text: `Diagnósticos :`, style: 'label'};

    const DIAGNOSIS = diagnostics.map((diag) => ({text: `- ${diag}\n`, bold: true}));

    const IS_GES = isGES ?
      [
        { text: `Patología GES: Si`, style: 'label' },
        { text: `Lista de Patologías GES: `, style: 'label' },
        PATHOLOGIES,
      ] : [{ text: `Patología GES: No`, style: 'label' }];

    const DATE          = [{text: `Fecha probable de Hospitalización: `, style: 'label'}, {text: ` ${date}`}];
    const MEDICAL_AREAS = [
      {text: `Servicio de Hospitalización Propuesta: `, style: 'label'},
      { text: ` ${medicalAreas || ''}` },
    ];
    const OBSERVATIONS  = [
      {text: `Observaciones de Hospitalización: `, style: 'label'},
      { text: ` ${observations?.hospitalization || ''}` },
    ];

    return {
      fontSize: 12,
      stack: [TEXT, DIAGNOSIS, IS_GES, DATE, MEDICAL_AREAS, OBSERVATIONS],
    };
  });

  return [...patientTopCard(paperwork), buildPdfHospital];
};
