import {patientTopCard} from '@shared/helpers/pdf-patient-card.helper';
import {PDF_HIDDEN_FONASA_IDS} from '@clinical/indications/constants/exams.constant';

export const buildContentPdfExams = (paperwork, group) => {
  const {rows} = group;
  const rows2 = JSON.parse(JSON.stringify(rows));
  const examsList = [];
  const lab1 = ['ELECTROCARDIOGRAMA (ECG) DE REPOSO', 'TEST DE ESFUERZO'];
  const lab2 = ['BIOIMPEDANCIOMETRIA', 'CALORIMETRIA INDIRECTA'];
  const lab3 = ['ENDOSCOPIA DIGESTIVA ALTA', 'COLONOSCOPIA TOTAL'];
  const lab1Exams = rows2.filter((exam) => lab1.includes(exam.glosa));
  const lab2Exams = rows2.filter((exam) => lab2.includes(exam.glosa));
  const lab3Exams = rows2.filter((exam) => lab3.includes(exam.glosa));

  if (lab1Exams.length > 0) lab1Exams[lab1Exams.length - 1].brakePage = true;
  if (lab2Exams.length > 0) lab2Exams[lab2Exams.length - 1].brakePage = true;
  if (lab3Exams.length > 0) lab3Exams[lab3Exams.length - 1].brakePage = true;

  const examsWithoutLab1 = rows.filter((exam) => !lab1.includes(exam.glosa));
  const examsWithoutLab2 = examsWithoutLab1.filter((exam) => !lab2.includes(exam.glosa));
  const examsWithoutLab3 = examsWithoutLab2.filter((exam) => !lab3.includes(exam.glosa));
  if (examsWithoutLab3.length === 0) {
    if (lab3Exams.length > 0) lab3Exams[lab3Exams.length - 1].brakePage = false;
    if (lab2Exams.length > 0 && lab3Exams.length === 0) lab2Exams[lab2Exams.length - 1].brakePage = false;
    if (lab1Exams.length > 0 && lab3Exams.length === 0) lab1Exams[lab1Exams.length - 1].brakePage = false;
    if (lab1Exams.length > 0 && lab2Exams.length === 0 && lab3Exams.length === 0)
      lab1Exams[lab1Exams.length - 1].brakePage = false;
  }
  if (examsWithoutLab3.length > 1 && lab3Exams.length > 0) lab3Exams[lab3Exams.length - 1].brakePage = true;

  examsList.push(...lab1Exams, ...lab2Exams, ...lab3Exams, ...examsWithoutLab3);

  const examBuildPdf = examsList.map((item, index) => {
    const {glosa, date, observations, fonasaId, id, brakePage} = item;

    // filtra algunos códigos de FONASA que no se deben mostrar en el PDF
    const fonasaIds: string[] = !fonasaId || fonasaId === 'nan' ? [] : fonasaId.toString().split('/');
    const nonWritableFonasaIds = PDF_HIDDEN_FONASA_IDS[item.group.toLowerCase()]?.[id];
    const writableFonasaId = nonWritableFonasaIds ? fonasaIds.filter(fid => !nonWritableFonasaIds.includes(fid)) : fonasaIds;

    const OBSERVATIONS = {text: observations ? `Observaciones: ${observations}` : ''};
    const CODFONASA = {text: `Código Fonasa: ${!fonasaId ? 'Sin Código' : writableFonasaId.join('/')}`};

    return [

      {

        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        function(currentPage, pageCount: string) {
          return [{text: 'Page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'center'}];
        },

        ul: [
          {
            text     : `${glosa.toUpperCase()}`,
            bold     : true,
            fontSize : 10,
            margin   : [0, 5],
            alignment: 'justify',
          },
        ],
      },
      {
        fontSize : 10,
        type     : 'none',
        ol       : [OBSERVATIONS, {
          text: `Fecha: ${(date || '').toString().split('-').reverse().join('-')}`,
          bold: true,
        }, CODFONASA],
        pageBreak: brakePage ? 'after' : '',

      },
      brakePage ? patientTopCard(paperwork) : [],
    ];
  });
  return [patientTopCard(paperwork), examBuildPdf];
};
