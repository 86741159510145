import { AnamnesisIntegralFrmComponent } from '../components/form-integral/anamnesis-integral-frm.component';
import { FrmAnamnesisStandardComponent } from '../components/form-standard/frm-anamnesis-standard.component';
import { AnamnesisOncologicalComponent } from '../components/form-oncological/anamnesis-oncological-frm.component';
import { AnanmnesisPediatricsFrmComponent } from '../components/form-pediatric/ananmnesis-pediatric-frm.component';

export interface AnamnesisTemplate {
  name: string;
  selector: string;
  component: any;
}

export const ANAMNESIS_TEMPLATES: AnamnesisTemplate[] = [
  { name: 'Anamnesis General', selector: 'general', component: FrmAnamnesisStandardComponent },
  { name: 'Anamnesis Universal', selector: 'universal', component: FrmAnamnesisStandardComponent },
  { name: 'Consulta Salud Mental', selector: 'mental', component: FrmAnamnesisStandardComponent },
  { name: 'Alta Radioterapia', selector: 'radioterapia', component: AnamnesisOncologicalComponent },
  { name: 'Alta Quimioterapia', selector: 'quimioterapia', component: AnamnesisOncologicalComponent },
  { name: '* Salud Integral', selector: 'integral', component: AnamnesisIntegralFrmComponent },
  { name: '* Atención Niño Enfermo', selector: 'niño-enfermo', component: AnanmnesisPediatricsFrmComponent },
  { name: '* Supervisión de Salud', selector: 'supervision-salud', component: AnanmnesisPediatricsFrmComponent },
];

export const EXAMS = {
  names: ['COLONOSCOPÍA', 'TEST DE EMORRAGIAS OCULTAS', 'MAMOGRAFÍA', 'PAP', 'OTRO'],
};

export const DESTINATION = {
  names: ['DOMICILIO', 'URGENCIA', 'HOSPITAL UC', 'OTRO HOSPITAL', 'OTRO'],
};

export const REASON = {
  names: [
    'Concluye tratamiento / Completo',
    'Concluye tratamiento / Cambio de tratamiento o Protocolo',
    'No concluye tratamiento / Completo',
    'No concluye tratamiento / Recurrencia',
    'No concluye tratamiento / Fallecimiento',
  ],
};
