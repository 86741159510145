import {Action, createReducer, on} from '@ngrx/store';
import {VoucherRequest, YappOrder} from '../interfaces/voucher.interface';
import {
  LOAD_DELIVERY_BY_RECIPE,
  LOAD_DELIVERY_BY_RECIPE_ERR,
  LOAD_DELIVERY_BY_RECIPE_OK,
  SELECTED_RECIPE_PHARM,
  UNSELECT_RECIPE_PHARM,
} from './recipe-pharm.actions';

export interface DeliveryState {
  requestData: VoucherRequest[];
  isLoaded: boolean;
  isLoading: boolean;
  status: string;
  error: any;
  selected: YappOrder;
}

export const deliveryState: DeliveryState = {
  requestData: [],
  isLoaded: false,
  isLoading: false,
  status: '',
  error: null,
  selected: null,
};

const createReducerdelivery = createReducer(
  deliveryState,

  // Obtener data de un paciente
  on(LOAD_DELIVERY_BY_RECIPE, (state) => ({ ...state, isLoading: true })),
  on(LOAD_DELIVERY_BY_RECIPE_OK, (state, { requestData: voucherRequest }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    requestData: [{ ...voucherRequest }],
    selected: voucherRequest.yappOrder,
  })),
  on(LOAD_DELIVERY_BY_RECIPE_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(SELECTED_RECIPE_PHARM, (state, { selected, action }) => ({ ...state, selected: { ...selected }, status: action })),
  on(UNSELECT_RECIPE_PHARM, (state) => ({ ...state, selected: null, isLoaded: false, isLoading: false, status: '' })),
);

export const deliveryReducer = (state: DeliveryState, action: Action) => createReducerdelivery(state, action);
