import { Hospitalization } from '@shared/interfaces/hospitalization.interface';
import { Action, createReducer, on } from '@ngrx/store';
import { Paperwork } from '@shared/interfaces/paperwork.interface';

import {
  CLEAR_HOSPITALIZATION,
  DELETE_HOSPITALIZATION,
  DELETE_HOSPITALIZATION_ERR,
  DELETE_HOSPITALIZATION_OK,
  LOAD_HOSPITALIZATIONS_BY_PATIENT,
  LOAD_HOSPITALIZATIONS_BY_PATIENT_ERR,
  LOAD_HOSPITALIZATIONS_BY_PATIENT_OK,
  LOAD_HOSPITALIZATIONS_BY_PROFESSIONAL,
  LOAD_HOSPITALIZATIONS_BY_PROFESSIONAL_ERR,
  LOAD_HOSPITALIZATIONS_BY_PROFESSIONAL_OK,
  PUT_HOSPITALIZATION,
  PUT_HOSPITALIZATION_ERR,
  PUT_HOSPITALIZATION_OK,
  SELECTED_HOSPITALIZATION,
  UNSELECT_HOSPITALIZATION,
} from '@store/actions';

export interface HospitalizationState {
  error: any;
  status: string;
  isLoaded: boolean;
  isLoading: boolean;
  orders: Paperwork<Hospitalization>[];
  historical: Paperwork<Hospitalization>[];
  selected: Paperwork<Hospitalization>;
  lastOrder: Paperwork<Hospitalization>;
  current: Paperwork;
}

export const hospitalizationState: HospitalizationState = {
  error     : null,
  status    : '',
  isLoaded  : false,
  isLoading : false,
  orders    : [],
  historical: [],
  selected  : null,
  lastOrder : null,
  current   : null,
};

const createReducerHospitalization = createReducer(
    hospitalizationState,

    // Obtener las cirugias de un paciente
    on(LOAD_HOSPITALIZATIONS_BY_PATIENT, (state) => ({...state, isLoading: true})),
    on(LOAD_HOSPITALIZATIONS_BY_PATIENT_OK, (state, {orders: orders}) => ({
      ...state,
      isLoading: false,
      isLoaded : true,
      error    : null,
      orders   : [...orders],
    })),
    on(LOAD_HOSPITALIZATIONS_BY_PATIENT_ERR, (state, {payload}) => ({
      ...state,
      isLoading: false,
      isLoaded : false,
      error    : {
        url    : payload.url,
        name   : payload.name,
        message: payload.message,
      },
    })),

    // Obtener las cirugias de un profesional
    on(LOAD_HOSPITALIZATIONS_BY_PROFESSIONAL, (state) => ({...state, isLoading: true})),
    on(LOAD_HOSPITALIZATIONS_BY_PROFESSIONAL_OK, (state, {orders: orders}) => ({
      ...state,
      isLoading : false,
      isLoaded  : true,
      error     : null,
      historical: [...orders],
    })),
    on(LOAD_HOSPITALIZATIONS_BY_PROFESSIONAL_ERR, (state, {payload}) => ({
      ...state,
      isLoading: false,
      isLoaded : false,
      error    : {
        url    : payload.url,
        name   : payload.name,
        message: payload.message,
      },
    })),

    on(PUT_HOSPITALIZATION, (state) => ({...state, isLoading: true, status: 'Guardando el registro.'})),
    on(PUT_HOSPITALIZATION_OK, (state, {hospitalization}) => {
      let orders  = state.orders.map((ans) => {
        if (ans.id === hospitalization.id) return hospitalization;
        else return ans;
      });
      let history = state.historical.map((ans) => {
        if (ans.id === hospitalization.id) return hospitalization;
        else return ans;
      });

      const index = orders.findIndex((draft) => draft.id === hospitalization.id);
      if (index === -1) orders = [hospitalization, ...orders];
      const indexHistory = history.findIndex((draft) => draft.id === hospitalization.id);
      if (indexHistory === -1) history = [hospitalization, ...history];

      return {
        ...state,
        isLoading : false,
        isLoaded  : true,
        isSaving: true,
        status    : 'Registro guardado con éxito',
        orders    : [...orders],
        historical: [...history],
        lastOrder : {...hospitalization},
        current   : {...hospitalization},
      };
    }),
    on(PUT_HOSPITALIZATION_ERR, (state, {payload}) => ({
      ...state,
      isLoading: false,
      isLoaded : false,
      current: null,
      isSaving: false,
      status   : 'Ocurrio un error al intentar guardar el registro',
      error    : {...payload},
    })),

    on(SELECTED_HOSPITALIZATION, (state, {selected, action}) => ({
      ...state,
      current : null,
      selected: {...selected},
      status  : action,
    })),
    on(UNSELECT_HOSPITALIZATION, (state) => ({
      ...state,
      isLoaded : false,
      isLoading: false,
      status   : '',
      selected : null,
    })),
    on(CLEAR_HOSPITALIZATION, (state) => ({
      orders    : [...state.orders],
      historical: [...state.historical],
      isLoading : false,
      isLoaded  : false,
      error     : null,
      status    : '',
      selected  : null,
      lastOrder : null,
      current   : null,
    })),

    on(DELETE_HOSPITALIZATION, (state) => ({...state, isLoading: true})),
    on(DELETE_HOSPITALIZATION_OK, (state, {hospitalization}) => {
      let orders         = [...state.orders];
      let history        = [...state.historical];
      const index        = orders.findIndex((draft) => draft.id === hospitalization.id);
      const indexHistory = history.findIndex((draft) => draft.id === hospitalization.id);
      if (index !== -1) orders = [...orders.filter((draft) => draft.id !== hospitalization.id)];
      if (indexHistory !== -1) history = [...history.filter((draft) => draft.id !== hospitalization.id)];

      return {
        ...state,
        isLoading : false,
        isLoaded  : true,
        status    : 'Hospitalizacion eliminada con éxito',
        orders    : [...orders],
        historical: [...history],
      };
    }),
    on(DELETE_HOSPITALIZATION_ERR, (state, {payload}) => ({
      ...state,
      isLoading: false,
      isLoaded : false,
      status   : 'Ocurrio un error al intentar eliminar la hospitalizacion',
      error    : {
        url    : payload.url,
        name   : payload.name,
        message: payload.message,
      },
    })),
);

export const hospitalizationReducer = (state: HospitalizationState, action: Action) =>
    createReducerHospitalization(state, action);
