import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as anamnesisActions from './anamnesis.actions';
import { AnamnesisService } from '@components/anamnesis/services/anamnesis.service';

import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

@Injectable()
export class AnamnesisEffects {
  loadAnamnesis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(anamnesisActions.GET_ANAMNESIS),
      mergeMap(({ patientId }) =>
        this.anamnesisService.getPatientAnamnesis(patientId).pipe(
          map((anamnesis) => anamnesisActions.GET_ANAMNESIS_OK({ anamnesis })),
          catchError((err) => of(anamnesisActions.GET_ANAMNESIS_ERR({ payload: err }))),
        ),
      ),
    ),
  );
  loadAnamnesisDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(anamnesisActions.GET_ANAMNESIS_DRAFT),
      mergeMap(({ professionalId }) =>
        this.anamnesisService.getProfessionalAnamnesisDraft(professionalId).pipe(
          map((anamnesis) => anamnesisActions.GET_ANAMNESIS_DRAFT_OK({ anamnesis })),
          catchError((err) => of(anamnesisActions.GET_ANAMNESIS_DRAFT_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  putAnamnesis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(anamnesisActions.PUT_ANAMNESIS),
      mergeMap(({ frm }) =>
        this.anamnesisService.putPatientAnamnesis(frm).pipe(
          map((anamnesis) => anamnesisActions.PUT_ANAMNESIS_OK({ anamnesis })),
          catchError((err) => of(anamnesisActions.PUT_ANAMNESIS_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  deleteAnamnesis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(anamnesisActions.DEL_ANAMNESIS),
      mergeMap(({ anamnesisToDelete }) =>
        this.anamnesisService.deletePatientAnamnesis(anamnesisToDelete).pipe(
          map((anamnesis) => anamnesisActions.DEL_ANAMNESIS_OK({ anamnesis })),
          catchError((err) => of(anamnesisActions.DEL_ANAMNESIS_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private anamnesisService: AnamnesisService) {}
}
