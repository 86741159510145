import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as docSignActions from '../actions';

import { of } from 'rxjs';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { SignDocService } from '@shared/services/sign.service';

@Injectable()
export class DocSignEffects {
  docSign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(docSignActions.DIGITAL_SIGNATURE),
      mergeMap(({ formData, title }) =>
        this.signDocService.sign(formData).pipe(
          map((signedDoc) => docSignActions.DIGITAL_SIGNATURE_OK({ signedDoc, title })),
          catchError((err) => of(docSignActions.DIGITAL_SIGNATURE_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private signDocService: SignDocService) {}
}
