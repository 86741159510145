import { patientTopCard } from '@shared/helpers/pdf-patient-card.helper';
import { IndicationOtorhinolaryngologist } from '@shared/interfaces/otorhinolaryngologist.interface';

export const buildContentPdfOtorrinolaringologist = (paperwork, group) => {
  const {rows}        = group;
  const othorBuildPdf = rows.map((item: IndicationOtorhinolaryngologist) => {
    const {diagnostics, listExam, observations} = item;

    const DIAGNOSIS =
              diagnostics.length > 0
                  ? [
                    '\n',
                    {text: `Diagnóstico(s):`, bold: true, margin: [0, 5]},
                  diagnostics.map((exam) => [{ ul: exam ? [`${exam}`] : [] }]),
                    '\n',
                  ]
                  : [];

    const LISTEXAM = listExam.filter((exam) => exam.category === 'Exámenes');
    const LISTA = LISTEXAM.map((exam) => [{ ul: exam ? [`${exam.name}`] : [] }]);
    const EXAMENES =
              LISTA.length > 0
                  ? [
                    {text: `Exámenes: `, bold: true},
                    {
                      ul: [LISTA],
                    },
                    '\n',
                  ]
                  : [];
    const LISTCOM  = listExam.filter((exam) => exam.category === 'Comités');
    const LIST = LISTCOM.map((exam) => [{ ul: exam ? [`${exam.name}`] : [] }]);
    const COMITES  =
              LIST.length > 0
                  ? [
                    {text: `Comités: `, bold: true},
                    {
                      ul: [LIST],
                    },
                  ]
                  : [];

    const OBSERVATIONS =
      observations && observations !== ''
                  ? [
                    '\n',
                    {text: `Otros Exámenes y/u Observacion(es):`, bold: true, margin: [0, 5]},
                    {text: [{text: `${observations}`}]},
                  ]
                  : '';

    return [DIAGNOSIS, EXAMENES, COMITES, OBSERVATIONS];
  });

  return [...patientTopCard(paperwork), othorBuildPdf];
};
