import {Injectable} from '@angular/core';
import {ClinicalIndicationService} from '@clinical/indications/services/indication.service';

import {ReportService} from '@components/records/services/report.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  DELETE_INDICATION,
  DELETE_INDICATION_ERR,
  DELETE_INDICATION_OK,
  LOAD_INDICATIONS_BY_PATIENT,
  LOAD_INDICATIONS_BY_PATIENT_ERR,
  LOAD_INDICATIONS_BY_PATIENT_OK,
  LOAD_INDICATIONS_BY_PROFESSIONAL,
  LOAD_INDICATIONS_BY_PROFESSIONAL_ERR,
  LOAD_INDICATIONS_BY_PROFESSIONAL_OK,
  PUT_INDICATION,
  PUT_INDICATION_END,
  PUT_INDICATION_ERR,
  PUT_INDICATION_OK,
  PUT_MARKS,
  PUT_MARKS_ERR,
  PUT_MARKS_OK,
  SELECT_INDICATION,
  SELECTED_INDICATION,
} from '@store/actions';

import {of} from 'rxjs';
import {catchError, filter, map, mergeMap, tap} from 'rxjs/operators';

import {environment} from '@environments/environment';
import {IndicationOtorhinolaryngologist} from '@shared/interfaces/otorhinolaryngologist.interface';
import {INDICATIONS_PROCEDURES_DERMATOLOGY} from '@clinical/indications/constants/dermatology.constant';
import {PAPERWORK_INDICATIONS_DEFINITION} from '@clinical/indications/constants/paperwork-indications.const';

const TAGS_PRM = environment.tagsPRMIndications;

@Injectable()
export class IndicationEffects {
  putIndication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PUT_INDICATION),
      mergeMap(({valuesToSave}) =>
        this.clinicalIndicationService.putIndication({...valuesToSave}).pipe(
          map((indication) => PUT_INDICATION_OK({indication})),
          catchError((err) => of(PUT_INDICATION_ERR({payload: err}))),
        ),
      ),
    ),
  );
  putMarks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PUT_INDICATION_OK),
      filter(({indication}) => indication.suffix === 'OTR' || indication.suffix === 'DRM'),
      map(({indication}) => PUT_MARKS({indication})),
    ),
  );
  putMarksOtorhinolaryngologist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PUT_MARKS),
      filter(({indication}) => indication.suffix === 'OTR'),
      map(({indication}) => {
        const {patient, content} = indication;
        const [firstExams] = content as IndicationOtorhinolaryngologist[];
        const marksCommittee = TAGS_PRM.otr
                                       .filter(otr => (firstExams).listExam.some(exam => exam.name === otr.name));
        const marksIds = marksCommittee.map(exam => exam.tag);
        return {patient, marksIds};
      }),
      filter(({marksIds}) => marksIds.length > 0),
      mergeMap(({patient, marksIds}) =>
        this.clinicalIndicationService.addMarksByPatient(patient.id, marksIds).pipe(
          map((_) => PUT_MARKS_OK),
          catchError((err) => of(PUT_MARKS_ERR({payload: err}))),
        ),
      ),
    ),
  );
  putMarksDermatology$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PUT_MARKS),
      filter(({indication}) => indication.suffix === 'DRM'),
      map(({indication}) => {
        const {patient} = indication;
        const marksIds = [];
        let hasDermatologyProcedure = false;

        indication.content[0].procedures.map((exam) => {
          if (INDICATIONS_PROCEDURES_DERMATOLOGY.includes(exam.code)) hasDermatologyProcedure = true;
        });
        if (hasDermatologyProcedure) marksIds.push(TAGS_PRM.drm);
        return {patient, marksIds};
      }),
      filter(({marksIds}) => marksIds.length > 0),
      mergeMap(({patient, marksIds}) =>
        this.clinicalIndicationService.addMarksByPatient(patient.id, marksIds).pipe(
          map((_) => PUT_MARKS_OK),
          catchError((err) => of(PUT_MARKS_ERR({payload: err}))),
        ),
      ),
    ),
  );
  clear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PUT_INDICATION_OK),
      map(() => PUT_INDICATION_END()),
    ),
  );
  loadIndicationsByPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_INDICATIONS_BY_PATIENT),
      mergeMap(({params}) =>
        this.reportService.findByType(params.patientId, params.type).pipe(
          map((indication) => LOAD_INDICATIONS_BY_PATIENT_OK({indications: indication})),
          catchError((err) => of(LOAD_INDICATIONS_BY_PATIENT_ERR({payload: err}))),
        ),
      ),
    ),
  );
  loadIndicationsByProfessional$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_INDICATIONS_BY_PROFESSIONAL),
      mergeMap(({gsiMultiCondition1St}) =>
        this.reportService.findByGsi(gsiMultiCondition1St).pipe(
          map((indication) => LOAD_INDICATIONS_BY_PROFESSIONAL_OK({indications: indication})),
          catchError((err) => of(LOAD_INDICATIONS_BY_PROFESSIONAL_ERR({payload: err}))),
        ),
      ),
    ),
  );
  deleteIndication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DELETE_INDICATION),
      mergeMap((indication) =>
        this.reportService.deleteByIds('indicaciones', indication).pipe(
          map((indicationItem) => DELETE_INDICATION_OK({indication: indicationItem})),
          catchError((err) => of(DELETE_INDICATION_ERR({payload: err}))),
        ),
      ),
    ),
  );
  selectIndication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SELECT_INDICATION),
      mergeMap(({selected, action}) => {
        const {content} = selected;
        const [indication] = content;
        if (PAPERWORK_INDICATIONS_DEFINITION.exams.group.includes(indication.group))
          return this.clinicalIndicationService.getUpdatedExams(content).pipe(
            map((updatedIndications) => SELECTED_INDICATION({
              selected: {...selected, content: updatedIndications},
              action,
            })),
          );

        return of(SELECTED_INDICATION({selected, action}));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private clinicalIndicationService: ClinicalIndicationService,
    private reportService: ReportService,
  ) {}
}
