import { HttpErrorResponse } from '@angular/common/http';
import { Hospitalization } from '@shared/interfaces/hospitalization.interface';
import { ParamsToFindByPatient } from '@components/patient/interfaces/patient.interface';
import { createAction, props } from '@ngrx/store';
import { Paperwork } from '@shared/interfaces/paperwork.interface';

/* Firma de Documentos Electronicos */
export const PUT_HOSPITALIZATION     = createAction(
    '[hospitalizacion] PUT Orden de Hospitalizacion',
    props<{ valuesToSave: Paperwork<Hospitalization> }>(),
);
export const PUT_HOSPITALIZATION_OK  = createAction(
    '[hospitalizacion] OK PUT Orden de Hospitalizacion',
    props<{ hospitalization: Paperwork<Hospitalization> }>(),
);
export const PUT_HOSPITALIZATION_ERR = createAction(
    '[hospitalizacion] ERR PUT Orden de Hospitalizacion',
    props<{ payload: HttpErrorResponse }>(),
);

export const LOAD_HOSPITALIZATIONS_BY_PATIENT     = createAction(
    '[hospitalizacion] Cargar Hospitalizaciones por paciente',
    props<{ params: ParamsToFindByPatient }>(),
);
export const LOAD_HOSPITALIZATIONS_BY_PATIENT_OK  = createAction(
    '[hospitalizacion] OK Hospitalizaciones por paciente',
    props<{ orders: Paperwork<Hospitalization>[] }>(),
);
export const LOAD_HOSPITALIZATIONS_BY_PATIENT_ERR = createAction(
    '[hospitalizacion] ERR Hospitalizaciones por paciente',
    props<{ payload: HttpErrorResponse }>(),
);

export const LOAD_HOSPITALIZATIONS_BY_PROFESSIONAL     = createAction(
    '[hospitalizacion] Cargar Hospitalizaciones por profesional',
    props<{ gsiMultiCondition1St: string }>(),
);
export const LOAD_HOSPITALIZATIONS_BY_PROFESSIONAL_OK  = createAction(
    '[hospitalizacion] OK Hospitalizaciones por profesional',
    props<{ orders: Paperwork<Hospitalization>[] }>(),
);
export const LOAD_HOSPITALIZATIONS_BY_PROFESSIONAL_ERR = createAction(
    '[hospitalizacion] ERR Hospitalizaciones por profesional',
    props<{ payload: HttpErrorResponse }>(),
);

export const DELETE_HOSPITALIZATION     = createAction(
    '[hospitalizacion] DELETE Hospitalizacion',
    props<{ hospitalization: Paperwork<Hospitalization> }>(),
);
export const DELETE_HOSPITALIZATION_OK  = createAction(
    '[hospitalizacion] OK DELETE Hospitalizacion',
    props<{ hospitalization: Paperwork<Hospitalization> }>(),
);
export const DELETE_HOSPITALIZATION_ERR = createAction(
    '[hospitalizacion] ERR DELETE Hospitalizacion',
    props<{ payload: HttpErrorResponse }>(),
);

export const SELECTED_HOSPITALIZATION = createAction(
    '[hospitalizacion] SELECTED Hospitalizacion',
    props<{ selected: Paperwork<Hospitalization>; action: string }>(),
);
export const UNSELECT_HOSPITALIZATION = createAction('[hospitalizacion] UNSELECT Hospitalizacion');
export const CLEAR_HOSPITALIZATION    = createAction('[hospitalizacion] CLEAR Hospitalizacion');
