import {Doc} from '@clinical/educational/interfaces/educational.interface';
import {patientTopCard} from '@shared/helpers/pdf-patient-card.helper';

export const buildContentPdfEducational = (paperwork, group) => {
    const {rows} = group;
    const DOCS = rows[0].documents ? rows[0].documents.map((doc: Doc) => [
            {
                ul      : [{
                    text           : `${doc.name}`,
                    link           : `${doc.url}`,
                    decoration     : 'underline',
                    decorationColor: 'blue'
                }], bold: true
            },
        ])
        : [];

    const docBuildPdf = [{text: `El siguiente Material Educativo ha sido enviado a al paciente:`, style: 'title', bold: true},
                         '\n',
                         {ul: [DOCS]}];

    return [...patientTopCard(paperwork), docBuildPdf];
};
