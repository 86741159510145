import { DEFAULT_PDF_POSITION_SIGNATURE } from '@shared/constants/app.const';
import { PrintedOnPage, PrintMode } from '@shared/interfaces/paperwork.interface';
import { DERIVATIONS_ROUTES } from '@clinical/derivations/derivations-routes.module';
import { DELETE_DERIVATION, PUT_DERIVATION } from '../store/clinical-derivation.actions';
import { buildContentPdfDerivation } from '../utils/build-pdf-derivation';

export const PAPERWORK_DERIVATIONS_DEFINITION = {
  prefix: 'DER',
  module: 'derivacion',
  actions       : {
    put   : PUT_DERIVATION,
    delete: DELETE_DERIVATION,
  },
  interconsultas: {
    suffix           : 'ITC',
    group            : 'interconsulta o derivación',
    docTitle         : 'Interconsulta o Derivación',
    submodule: DERIVATIONS_ROUTES.interconsultations,
    getBodyDefinition: buildContentPdfDerivation,
    workflow         : {
      isSigned: true,
      isStored: true,
      isSaving: true,
      hasNextControl: true,
      notifications: [
        {
          type    : 'email',
          entity  : 'patient',
          subject : 'Notificaciones UC CHRISTUS | Derivación ó Interconsulta',
          template: 'derivations',
        },
      ],
    },
    config           : {
      isPreview: true,
      header   : {logo: PrintedOnPage.last, title: PrintedOnPage.all, professional: PrintedOnPage.all},
      patient  : {type: PrintMode.full, printed: PrintedOnPage.all},
      footer   : {
        signature: {printed: PrintedOnPage.all, position: DEFAULT_PDF_POSITION_SIGNATURE},
        qr       : {right: {url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web'}},
      },
    },
  },
};
