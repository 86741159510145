import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TENS_PROFILES } from '@auth/constants/profiles.const';
import { Professional, ProfessionalRef, Specialty } from '@auth/interfaces/professional.interface';
import { environment } from '@environments/environment';

import { Store } from '@ngrx/store';

import { API_URIS } from '@shared/constants/api-uris.const';
import { CHATBOT_ID } from '@shared/constants/app.const';
import { BAD_GATEWAY, FORBIDDEN, INTERNAL_SERVER_ERROR, UNAUTHORIZED } from '@shared/constants/http-codes.const';
import { HEADER_INFO, MESSAGES } from '@shared/constants/notifications.const';
import { REGEX_CLEAN_RUT } from '@shared/constants/regex.const';

import { PCV2Request } from '@shared/interfaces/request.interface';
import { AlertService } from '@shared/services/alert.service';

import { ChatbotService } from '@shared/services/chatbot.service';
import { ACTIVE_SESSION_KEY, IonicStorageService, TOKEN_KEY } from '@shared/services/ionic-storage.service';
import { KEY_PROFESSIONAL_STORE } from '@store/store-keys';

import { BehaviorSubject, exhaustMap, from, fromEvent, interval, Observable, of, race, timeout, timer } from 'rxjs';
import { combineLatestWith, distinctUntilChanged, filter, map, repeat, take, tap } from 'rxjs/operators';
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_REFRESH, LOAD_SIGNATURE } from 'src/app/auth/store/auth.actions';
import { AppState } from 'src/app/store/app.reducers';
import { UserCredential } from '../interfaces/credentials.interface';

import { Storage } from '@ionic/storage';

const BASE_API_PCV2: string = environment.baseApiPCv2;
const BASE_API_PROFESSIONAL_IMAGE: string = environment.baseApiProfessionalImage;
const URI_AUTH: string = API_URIS.auth;
const URI_PROFESSIONAL: string = API_URIS.professional;
const SESSION_TIMEOUT =  55 * 1000 * 60;
const TIME_AWAIT_TO_CLICK_KEEP_SESSION = 1000 * 60;

@Injectable({
  providedIn: 'root',
})
export class AuthService {


  hasPhoto: boolean;
  failed: string;
  isLoading: boolean;
  isLoaded: boolean;
  isSubprofessional$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _timeout$ = interval(SESSION_TIMEOUT);
  private _click$ = fromEvent<MouseEvent>(document, 'click');
  private _keyup$ = fromEvent<KeyboardEvent>(document, 'keyup');
  private _professional: Professional;

  private _professionalRef: ProfessionalRef;
  private _subprofessional: ProfessionalRef;
  private _specialty: Specialty;

  constructor(
    private store: Store<AppState>,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private chatbotService: ChatbotService,
    private _ionicStorageService: IonicStorageService,
  ) {

    this.isLogged()
        .pipe(
          filter(isLogged => isLogged),
          tap(() => this.store.dispatch(AUTH_REFRESH())),
        )
        .subscribe();
    this.session();
    this.sessionTimeout();
    this.sessionActivated();
    this.setSubprofessional();
  }

  public get professional(): Professional {
    return this._professional;
  }

  public get professionalRef(): ProfessionalRef {
    return this._professionalRef;
  }

  public set professionalRef(value: ProfessionalRef) {
    this._professionalRef = value;
  }

  public get professionalRefSlim(): ProfessionalRef {
    return { ...this._professionalRef, base64: undefined };
  }

  public get subprofessional(): ProfessionalRef {
    return this._subprofessional;
  }

  public set subprofessional(value: ProfessionalRef) {
    this._subprofessional = value;
  }

  public get specialty(): Specialty {
    return this._specialty;
  }

  public set specialty(value: Specialty) {
    this._specialty = value;
  }

  login(credentials: UserCredential) {
    this.isLoading = true;

    credentials = {
      ...credentials,
      username: credentials.username.replace(REGEX_CLEAN_RUT, '')
                           .toUpperCase(),
    };

    this.setChatBot();
    this.store.dispatch(AUTH_LOGIN({ credentials }));
  }

  isLogged(): Observable<boolean> {
    return this._ionicStorageService.getData(TOKEN_KEY).pipe(map((token) => !!token));
  }

  getToken(): Observable<string> {
    return this._ionicStorageService.getData(TOKEN_KEY)
               .pipe(
                 map((token) => token));

  }

  logout() {
    this.isLoading = true;
    this._professional = null;
    this.chatbotService.removeScript(CHATBOT_ID);
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem('meeting');
    this.store.dispatch(AUTH_LOGOUT());
  }

  setSubprofessional() {
    this.store
        .select('professional')
        .pipe(distinctUntilChanged())
        .subscribe(async ({ subprofessional }) => {
          if (subprofessional) {
            const { id, rut, names, surnames, email } = subprofessional;
            this.subprofessional = { id, rut, names, surnames, email };
          }
        });
  }

  session() {
    this.store
        .select(KEY_PROFESSIONAL_STORE)
        .pipe(distinctUntilChanged())
        .subscribe(({ professional, subprofessional, isLoaded, error, isLoading }) => {
          this.isLoaded = isLoaded;
          this.isLoading = isLoading;

          const INVALID_CREDENTIALS = 'Las credenciales ingresadas no coinciden con nuestros registros.';
          const DISABLED_USER = `Se encuentra temporalmente bloqueado o no tiene asignado un perfil, contacte al equipo de soporte: `;
          const EXPIRED = `Se ha cerrado la sesión, intente acceder nuevamente.`;
          const INTERNAL_ERROR = 'No fue posible validar sus credenciales';
          if (error) this.failed = 'Ocurrió un error, detectamos que su conexión de internet es inestable.';
          if (error && error.status === UNAUTHORIZED) this.failed = INVALID_CREDENTIALS;
          if (error && error.status === INTERNAL_SERVER_ERROR) this.failed = INTERNAL_ERROR;
          if (error && error.status === FORBIDDEN) this.failed = DISABLED_USER + environment.supportEmail;
          if (error && error.status === BAD_GATEWAY) {
            this.failed = EXPIRED;
            this.chatbotService.removeScript(CHATBOT_ID);
            this.store.dispatch(AUTH_LOGOUT());
          }

          if (isLoaded && professional) {
            this.setSession(professional);
            if (professional.id.slice(0, 3) !== 'AGN' || subprofessional) this.getProfesionalPhoto(professional.id);
          }
        });
  }

  /**
   * Iniciar la Sesión con los datos del usuario
   *
   * @returns Datos de un profesional
   * @param professional
   */
  setSession(professional: Professional) {
    this._professionalRef = this.getProfessionalRef(professional);
    this._professional = professional;

    if (!professional.signature || !professional.signature.base64)
      this.store.dispatch(LOAD_SIGNATURE({ signature: professional.signature }));

    this.isSubprofessional$.next(this.isTens(professional));
  }

  async setToken(token: string, redirect: boolean = true): Promise<void> {
    // el orden altera el resultado
    await this._ionicStorageService.addData(TOKEN_KEY, token).toPromise();
    await this._ionicStorageService.addData(ACTIVE_SESSION_KEY, true).toPromise();
  }

  isTens(professional: Professional): boolean {
    return TENS_PROFILES.some((profile: string) => professional.profiles.includes(profile));
  }

  /**
   * Obtener fotografía del profesional
   *
   * @param profesionalId Identificador único del Profesional
   */
  getProfesionalPhoto(profesionalId: string): void {
    const image = new Image();
    image.src = `${BASE_API_PROFESSIONAL_IMAGE}/${profesionalId}.jpg`;
    image.alt = 'foto perfil profesional';
    image.onerror = () => image.src = 'assets/images/avatar-profile.png';
    timer(1000).subscribe((_) => {
      const tagImageProfile = document.getElementById('image-profile');
      if (tagImageProfile && tagImageProfile.getElementsByTagName('img').length < 1) {
        tagImageProfile.appendChild(image);
        this.hasPhoto = true;
      }
    });
  }

  setChatBot() {
    const chatElement = document.getElementById(CHATBOT_ID);

    if (!chatElement) {
      const chatbotHtmlElement = document.createElement('div');
      document.body.appendChild(chatbotHtmlElement);
      chatbotHtmlElement.setAttribute('id', CHATBOT_ID);
    }
  }

  validateCredentials(body: UserCredential): Observable<Professional> {
    if (body) body.username = body.username.replace(REGEX_CLEAN_RUT, '');
    return this.httpClient
               .post<PCV2Request>(`${BASE_API_PCV2}/${URI_AUTH}/iniciar-sesion`, body)
               .pipe(map(({ data }: { data: Professional }) => data));
  }

  refreshToken(): Observable<Professional> {
    return this.httpClient.post<PCV2Request>(`${BASE_API_PCV2}/${URI_AUTH}/renovar-sesion`, {})
               .pipe(
                 map(({ data }: { data: Professional }) => data),
               );
  }

  putProfessional(professional: Professional): Observable<Professional> {
    return this.httpClient
               .post<PCV2Request>(`${BASE_API_PCV2}/${URI_PROFESSIONAL}`, professional)
               .pipe(map(({ data }: { data: Professional }) => data));
  }

  changePassword(body) {
    body.gsiOne = body.gsiOne.replace(REGEX_CLEAN_RUT, '');

    const endpoint = `${BASE_API_PCV2}/${URI_PROFESSIONAL}/cambiar-clave`;
    return this.httpClient.post<PCV2Request>(endpoint, body);
  }

  resetPassword(rut: string) {
    const endpoint = `${BASE_API_PCV2}/${URI_PROFESSIONAL}/resetear-clave`;
    return this.httpClient.post<PCV2Request>(endpoint, { gsiOne: rut });
  }

  getProfessionalRef(professional: Professional): ProfessionalRef {
    const { id, names, surnames, rut, specialties, email, signCode } = professional;
    const specialty = professional?.specialty || this.specialty || specialties[0];
    const signature = professional.signature;
    const base64 = signature ? signature.base64 : undefined;
    if (!id) return undefined;
    return { id, names, surnames, rut, specialty, email, signCode, base64 };
  }

  sessionTimeout() {
    this._timeout$.pipe(
          exhaustMap(() => this._ionicStorageService.getData(ACTIVE_SESSION_KEY)),
          combineLatestWith(this._ionicStorageService.getData(TOKEN_KEY)
                                .pipe(take(1))),
          tap(([isActive, token]) => {
            if (isActive && token) this.store.dispatch(AUTH_REFRESH());
          }),
          take(1),
          repeat(),
          filter(([isActive, token]) => token?.length && !isActive),
          exhaustMap(() => race([from(this.alertService.presentAlert(HEADER_INFO, MESSAGES.info.timeout)),
                                 timer(TIME_AWAIT_TO_CLICK_KEEP_SESSION)
                                   .pipe(map(() => ({ role: 'timeout' })))],
          )),
          map(({ role }) => role),
          tap((role) => role === 'confirm' || role === 'backdrop' && this.store.dispatch(AUTH_REFRESH())),
          tap((role) => {
            if (role === 'timeout') {
              this.store.dispatch(AUTH_LOGOUT());
              this.alertService.dismissAlert();
              this.alertService.presentAlert(HEADER_INFO, MESSAGES.info.logout);
            }

          }),
          take(1),
          repeat(),
        )
        .subscribe();
  }

  sessionActivated() {
    this._click$.pipe(
          exhaustMap((isActive) => this._ionicStorageService.getData(ACTIVE_SESSION_KEY)),
          exhaustMap((isActive) => isActive ? of(true) : this._ionicStorageService.addData(ACTIVE_SESSION_KEY, true)),
          timeout({
            each: SESSION_TIMEOUT - 3000,
            with: () => this._ionicStorageService.addData(ACTIVE_SESSION_KEY, false),
          }),
          take(1),
          repeat(),
        )
        .subscribe();

  }

  validAccess(allow: string) {
    if (!this._professional) return;
    const access = this._professional?.access || this._professional?.profiles[0].access;
    return access.find((item) => item.option === allow);
  }

}
