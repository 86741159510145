import { patientTopCard } from '@shared/helpers/pdf-patient-card.helper';
import { MagistralRecipe } from '@shared/interfaces/recipe-magistral.interface';
import { getUnit } from '../constants/posologies.const';

export const buildContentPdfRecipesMegistral = (paperwork, group) => {

  const row = group.rows;
  const recipeBuildPdf = row.map((itemForm: MagistralRecipe) => {
    const { observations, start } = itemForm;
        const {duration, each, posology} = itemForm;
        const {csp, compounds} = itemForm;


        const COMPOSITION = {text: `${compounds}`, bold: true, fontSize: 10, alignment: 'justify'};
        if (!compounds) COMPOSITION.text = '';

      const CSP = csp
        ? {
            text: `C.S.P.: ${csp.quantity} ${getUnit(csp.unit, csp.quantity)}`,
            bold     : true,
            fontSize : 10,
            alignment: 'justify',
        } : { text: '' };

      const hasDurationQuantity = !['permanente', 'sos'].includes(duration.unit.toLocaleLowerCase());
        const DURATION = {
            text:
              `Aplicación: ${posology.quantity} ${getUnit(posology.unit, posology.quantity)} ` +
              `cada ${each.quantity} ${getUnit(each.unit, each.quantity)} ` +
              `por ${hasDurationQuantity ? duration.quantity : ''} ${getUnit(duration.unit, duration.quantity)}`,
        };
        const START_TREATMENT = {
          text: `Inicio del Tratamiento: ${(start || '').toString().split('-').reverse().join('-')}`,
        };

      const OBSERVATIONS = observations
        ? { text: `Observaciones: ${observations}`, margin: [0, 0, 0, 10], alignment: 'justify' }
        : { text: '' };

        return [
            {
                ul    : [COMPOSITION],
                margin: [0, 10, 0, 0],
            },
            {
                fontSize: 10,
                type    : 'none',
                ol      : [CSP, DURATION, START_TREATMENT, OBSERVATIONS],
            },
            '\n',
        ];
    });
    return [...patientTopCard(paperwork), recipeBuildPdf];
};
