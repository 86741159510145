import {patientTopCard} from '@shared/helpers/pdf-patient-card.helper';

export const buildContentPdfPapillaryRegime = (paperwork, group) => {
  const {rows} = group;
  return rows.map((itemForm) => [
    ...patientTopCard(paperwork),

    {
      text: `Estimado/a paciente: En beneficio de su salud, se le ha indicado este régimen que modifica la consistencia de su alimentación, cubriendo los requerimientos nutricionales que usted necesita para su pronta recuperación. El régimen papilla es de consistencia semisólida, proveniente de preparaciones que en su forma natural tienen esa consistencia u obtenida a través de operaciones culinarias de cocción y molienda.
     `, bold: false, fontSize: 12, margin: [0, 0, 0, 0], alignment: 'justify',
    },

    {text: `ALIMENTOS PERMITIDOS Y RECOMENDACIONES:`, bold: true, fontSize: 14, margin: [0, 5, 0, 10]},
    {
      alignment: 'justify',
      ul: [
        'Cereales y Farináceos: Arroz, papas, fideos, sémola, maicena, chuño, avena. Pueden ser incorporadas en papillas o postres',
        'Verduras: Cocidas, para ser incluidas en las papillas. Evitar verduras meteorizantes o fibrosas (choclo, brócoli, coliflor, espárragos, apio, cebolla).',
        'Frutas: Cocidas. Solo de deben evitar aquellas con pepas o difíciles de licuar como piña, frutilla, frambuesas etc. o de lo contrario luego de licuar, pasar por cedazo',
        'Carnes: Cocidas para ser incluidas en las papillas. Puede incluir carne de vacuno, pollo, pavo o pescado (sin espinas). 1 porción es el equivalente al tamaño de la palma de la mano.',
        'Legumbres: Deben ser pasadas por cedazo luego de ser licuadas, para eliminar el hollejo. Se recomienda su consumo al menos dos veces por semana en remplazo de las carnes.',
        'Lácteos: Leche o yogurt, quesillo/queso fresco molido.',
        'Hidratación: Ingerir entre 1,5-2 litros de agua, jugos, infusiones, agua de hierbas, sopas, etc. No bebidas gaseosas',

    ]
    },

    {text: `RECOMENDACIONES GENERALES:`, bold: true, fontSize: 14, pageBreak: 'before',  margin: [0, 5, 0, 10]},
    {
      alignment: 'justify',
      ul: [
        'Este régimen debe seguirlo por el tiempo indicado por su médico o Nutricionista.',
        'Cocine los alimentos en agua, al vapor, al horno, a la plancha, evite que sean fritos antes de pasar por licuadora.',
        'Debe usar el aceite crudo, agregado al momento de servi',
    ]
    },


    {text: `EJEMPLO DE MINUTA`, bold: true, fontSize: 14,  margin: [0, 15, 0, 10]},
    {
      style: 'tableExample',
      table: {
        body: [
          [{text: 'TIEMPO DE COMIDAS', style: 'tableHeader', alignment: 'center', bold: true}, {
            text     : 'ALIMENTOS',
            style    : 'tableHeader',
            alignment: 'center',
            bold     : true,
          }],
          ['Desayuno', 'Te puro o chuño en agua con azúcar o endulzante. \n Pan marraqueta tostado c/quesillo y pechuga de pavo cocida.'],
          ['Colación media mañana ', 'Jalea'],
          ['Almuerzo', 'Sopa de sémola c/clara \n Pavo a la plancha con fideos blancos \n Compota de manzana o membrillo con canela'],
          ['Once', 'Te puro o agua de hierbas con azúcar o endulzante Galletas soda o agua \n c/ Miel y Quesillo sin lactosa'],
          ['Cena', 'Sopa de fideos c/clara y zanahoria \n Pechuga de pollo desmenuzada cocinada al vapor con \n arroz cocinado al vapor con zanahoria \n Jalea o maicena en agua.'],
        ],
      },
    },


    {text: `OBSERVACIONES:`, bold: true, fontSize: 14,  margin: [0, 15, 0, 10]},
    {text: `${itemForm.observations}`, bold: false, fontSize: 12},


  ]);
};
