export const TAGS_PRM = {
    drm: '6b4771f8-4f3a-46da-a6f6-af1800f088ef',
    otr: [
      {
        name: 'Evaluación avanzada de voz y laringe - Estroboscopía laríngea',
        tag: 'bac31273-6932-4449-b5f0-b05101512c5d',
      },
      {
        name: 'Comité de oído',
        tag: '49d0eaf9-16e2-4e07-8f48-b0510151422f',
      },
      {
        name: 'Comité de nariz',
        tag: '6b4771f8-4f3a-46da-a6f6-af1800f088ef',
      },
      {
        name: 'Comité de cavidades paranasales - Videonasolaringoscopía',
        tag: '',
      },
      {
        name: 'Comité de ronquido - Videonasolaringoscopía',
        tag: 'db615596-380d-46f0-89f2-b053013cee1a',
      },
    ],
};

export const EXAM_AREAS = {
  imagenes: 'RIS_IMAGENES',
  laboratorio: '1a2ec3c8-90f6-4b07-82ba-abbb017226bd',
  procedimientos: 'b2461ac2-9c50-4724-9676-a91a00a91a02',
};