import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'npiPipe',
})
export class NpiPipe implements PipeTransform {
  transform(value: string): string {
    const regex = /Pni/ig;
    return value.replace(regex, 'PNI');
  }
}
