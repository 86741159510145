import { PUT_EDUCATIONAL } from '@clinical/educational/store/clinical-educational.actions';
import { buildContentPdfEducational } from '@clinical/educational/utils/build-pdf-educational';
import { DEFAULT_PDF_POSITION_SIGNATURE } from '@shared/constants/app.const';
import { PrintedOnPage, PrintMode } from '@shared/interfaces/paperwork.interface';
import { EDUCATIONAL_ROUTES } from '@clinical/educational/educational-routes.module';

export const PAPERWORK_EDUCATIONAL_DEFINITION = {
    actions    : {
        put: PUT_EDUCATIONAL,
    },
    prefix     : 'DOC',
    module     : 'educational',
    educational: {
        suffix           : 'EDU',
        group: 'material educativo',
        docTitle         : 'Material Educativo',
        submodule: EDUCATIONAL_ROUTES.tutorials,
        getBodyDefinition: buildContentPdfEducational,
        workflow         : {
            isSigned     : false,
            isStored     : false,
            isSaving: false,
            hasNextControl: true,
            notifications: [
                {
                    type    : 'email',
                    entity  : 'patient',
                  subject: 'Notificaciones UC CHRISTUS | Material Educativo',
                  template: 'educational',
                },
            ],
        },
        config           : {
            isPreview: true,
            header   : {logo: PrintedOnPage.all, title: PrintedOnPage.all, professional: PrintedOnPage.all},
            patient  : {type: PrintMode.full, printed: PrintedOnPage.all},
            footer   : {
                signature: {printed: PrintedOnPage.none, position: DEFAULT_PDF_POSITION_SIGNATURE},
                qr       : {right: {url: 'https://www.ucchristus.cl/', title: 'Visita Nuestra Web'}},
            },
        },
    },
};
